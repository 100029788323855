// import { FC } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCakeCandles, faCircleUser, faLocationDot, faMobile } from "@fortawesome/pro-regular-svg-icons"
import { format, parseISO } from "date-fns"
import { getAddress, getFirstPhone, humanNameAsString } from "fhir"
// import { Button } from "primereact/button"
// import { faGrid2, faMobileButton } from "@fortawesome/pro-duotone-svg-icons"
// import { faCheck, IconDefinition } from "@fortawesome/pro-solid-svg-icons"
// import { classNames } from "primereact/utils"

import { AvatarImage, AzureImage } from "commons"
import { calculateAge } from "utils"
import { useOrganizationContext } from "organization"
import { useLoginContext } from "security"

import { PractitionerInformation } from "./PractitionerInformation"
// import { useMfaMethods } from "../hooks"
import banner from "assets/banner.webp"

const PractitionerView = () => {
  const { branding } = useOrganizationContext()
  const { loggedInPractitioner } = useLoginContext()
  const { name, photo, birthDate, gender, telecom, address } = loggedInPractitioner
  const phone = getFirstPhone(telecom)
  // const { setupTOTP, setupSMS } = useAuth()
  // const { methods, isLoading } = useMfaMethods(loggedInPractitioner.id)
  // const isTotpAlreadyConfigured = !!methods?.totp
  // const isSmsAlreadyConfigured = !!methods?.sms

  const defaultBanner = <img src={banner} className="absolute top-0 left-0 w-full h-full" alt="" />

  return (
    <>
      <div className="banner-container border-b drop-shadow-sm border-gray-200">
        <div className="flex relative h-24">
          <div className="banner absolute top-0 left-0 w-full h-full">
            <AzureImage
              url={branding.banner as string}
              className="absolute top-0 left-0 w-full h-full"
              fallbackIconNode={defaultBanner}
            />
          </div>
          <div className="flex flex-1 justify-between p-3 z-10">
            <div className="flex items-center">
              <AvatarImage
                name={humanNameAsString(name?.[0])}
                photoUrl={photo?.[0]?.url}
                className="w-16 h-16 rounded-full ring-2 ring-white bg-white"
              />
              <div className="pl-5">
                <p className="text-2xl font-bold" title="Name">
                  {humanNameAsString(name?.[0])}
                </p>
                <p className="text-slate-600 text-sm font-semibold pt-2">
                  <span>
                    <FontAwesomeIcon icon={faCircleUser} />{" "}
                    <span className="px-1" title="Age">
                      {calculateAge(birthDate)}
                    </span>
                    <span className="capitalize" title="Biological Sex">
                      {gender}
                    </span>
                  </span>

                  <span className="pl-4" title="Address">
                    <FontAwesomeIcon icon={faLocationDot} /> {getAddress(address)}
                  </span>

                  {birthDate && (
                    <span className="pl-4" title="Birthdate">
                      <FontAwesomeIcon icon={faCakeCandles} /> {format(parseISO(birthDate), "MM-dd-yy")}
                    </span>
                  )}

                  {phone && (
                    <span className="pl-4" title="Phone">
                      <FontAwesomeIcon icon={faMobile} /> {phone}
                    </span>
                  )}
                </p>
              </div>
            </div>
            {/* <div className="flex items-center justify-end">
              <MFAButton
                title="2FA by app has been already configured"
                icon={faGrid2}
                label="Setup app 2FA"
                disabled={isTotpAlreadyConfigured}
                loading={isLoading}
                onClick={setupTOTP}
              />
              <MFAButton
                title="2FA by phone has been already configured"
                icon={faMobileButton}
                label="Setup phone 2FA"
                disabled={isSmsAlreadyConfigured}
                loading={isLoading}
                onClick={setupSMS}
              />
            </div> */}
          </div>
        </div>
      </div>
      <PractitionerInformation />
    </>
  )
}

// const MFAButton: FC<Props> = ({ title, icon, label, disabled, loading, onClick }) => (
//   <div className="relative" title={disabled ? title : ""}>
//     <Button
//       icon={<FontAwesomeIcon icon={icon} className="mr-1" />}
//       label={label}
//       className="button-banner ml-3 overflow-visible font-normal"
//       onClick={onClick}
//       disabled={disabled}
//       loading={loading}
//     />
//     {disabled && (
//       <span
//         className={classNames(
//           "border border-green-600 bg-green-600 rounded-full h-5 w-5 flex justify-center items-center absolute -top-3 right-2 z-10 text-[12px] text-white font-bold",
//         )}
//       >
//         <FontAwesomeIcon icon={faCheck} />
//       </span>
//     )}
//   </div>
// )

// type Props = {
//   title: string
//   icon: IconDefinition
//   label: string
//   disabled?: boolean
//   loading?: boolean
//   onClick(): void
// }

export { PractitionerView }
