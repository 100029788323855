import { faIdCard } from "@fortawesome/pro-regular-svg-icons"

import { AppModule, ModulesId } from "commons/Module"

class PatientModule extends AppModule {
  constructor(workspaceElement: JSX.Element, kpElement: JSX.Element) {
    super()
    this.id = ModulesId.PATIENT
    this.display = "Patient Information"
    this.icon = faIdCard
    this.kpElement = kpElement
    this.workspaceElement = workspaceElement
  }
}

export { PatientModule }
