import { useSuspenseQuery } from "@tanstack/react-query"
import { useClient } from "api"
import { compareAsc } from "date-fns"
import { Bundle, getResources, getResourcesByTypeAsIndex, Parameters, Setting, SettingDefinition } from "fhir"

import { settingsQueryKeys } from "../../query-keys"
import { SettingDefinitionItem } from "../../types"

const useSettingsDefinition = (organizationId: string) => {
  const { operationRequest } = useClient()
  const queryKey = settingsQueryKeys.definition

  const { data, isLoading } = useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      const parameters = {
        resourceType: "Parameters",
        parameter: [
          {
            name: "owner",
            value: { Reference: { id: organizationId, resourceType: "Organization" } },
          },
          {
            name: "_sort",
            value: { string: "createdAt" },
          },
        ],
      } as Parameters
      const bundle = await operationRequest<Bundle>({
        endpoint: "Setting",
        method: "POST",
        operation: "resolve",
        parameters,
      })
      const settings = getResourcesByTypeAsIndex<Setting>(bundle, "Setting", (s) => s.name ?? s.code?.code)
      const settingDefinition = getResources<SettingDefinition>(bundle, "SettingDefinition")

      settingDefinition.sort((s1, s2) => compareAsc(s1.meta?.createdAt as Date, s2.meta?.createdAt as Date))

      const settingsDefinition = settingDefinition.reduce<SettingDefinitionItem[]>((acc, sd) => {
        const status = settings[sd.name as string]?.status
        return sd.status !== "missing" && status !== "missing"
          ? [
              ...acc,
              {
                label: sd.code.display ?? "No code",
                settingDefinitionId: sd.name ?? "",
              },
            ]
          : acc
      }, [])

      return settingsDefinition
    },
    meta: { context: { queryKey } },
  })

  return { settingsDefinition: data ?? [], isLoading }
}

export { useSettingsDefinition }
