import { useSearchParams } from "react-router-dom"

export const useParamsModule = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const showModule = (module: string, moduleParams: { [name: string]: string } = {}) => {
    const params = new URLSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
      view: module,
    })
    Object.entries(moduleParams).forEach(([name, value]) => {
      params.set(name, value)
    })
    setSearchParams(params)
  }

  return { showModule }
}
