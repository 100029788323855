import { MedicationKnowledge, MedicationRequest } from "fhir"
import { FC } from "react"

import { MedicationKnowledgeDetails } from "commons"

import { MedicationRequestDetails } from "./MedicationRequestDetails"

const MedicationDetails: FC<Props> = ({ medication, onHide }) => {
  return (
    <MedicationKnowledgeDetails
      showImgFallback={false}
      selectedMK={medication?.medicationKnowledge}
      mr={medication}
      onHide={onHide}
    >
      <MedicationRequestDetails
        medicationRequest={medication}
        titleClassName="font-medium uppercase"
        textClassName="text-sm"
        containerClassName="space-y-3"
        showAsList
      />
    </MedicationKnowledgeDetails>
  )
}

type Props = { medication?: MedicationRequest & { medicationKnowledge?: MedicationKnowledge }; onHide(): void }

export { MedicationDetails }
