import { faCartShopping } from "@fortawesome/pro-regular-svg-icons"

import { AppModule, ModuleRenderSideType, ModulesId } from "commons/Module"

class CheckoutModule extends AppModule {
  constructor(workSpaceElement: JSX.Element) {
    super()
    this.id = ModulesId.CHECKOUT
    this.display = "Checkout"
    this.icon = faCartShopping
    this.hideBySetting = true
    this.workspaceElement = workSpaceElement
    this.renderSideType = ModuleRenderSideType.DYNAMIC_WORKSPACE
  }
}

export { CheckoutModule }
