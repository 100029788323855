import { useCallback, useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { CardMenu } from "commons"
import { useAppModuleContext } from "internals"

import "./Intake.css"

const Intake: React.FC = () => {
  const { isModuleActive, appSubModules } = useAppModuleContext()

  if (!isModuleActive("intake"))
    throw new Error("Not found", {
      cause: { name: "404", message: `Resource not available` },
    })

  const { setActiveSubModule: setSubModule, activeSubModule } = useChartContext()
  const [params, setParams] = useSearchParams()

  const updateSubview = useCallback(
    (subviewId?: string) => {
      if (subviewId) {
        params.append("subview", subviewId)
      } else {
        params.delete("subview")
      }

      setParams(params)
    },
    [params, setParams],
  )

  useEffect(() => {
    let subview = null
    const subviewParam = params.get("subview")

    if (subviewParam) {
      subview = appSubModules["intake"][subviewParam]

      if (!subview) {
        updateSubview()
      }
    }

    setSubModule(subview)
  }, [params, setSubModule, updateSubview, appSubModules])

  return !activeSubModule ? (
    <div className="px-3 pt-3 overflow-y-auto">
      <CardMenu
        menuItems={Object.values(appSubModules["intake"])}
        itemCommand={(item) => updateSubview(item?.getId() as string)}
      />
    </div>
  ) : (
    <div className="pt-3 flex flex-col h-full overflow-hidden">{activeSubModule.renderContent()}</div>
  )
}

export { Intake }
