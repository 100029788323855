import { faHistory } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { SocialHistoryContainer } from "../../../social-history/components/SocialHistoryContainer"

class SocialHistorySubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.SOCIAL_HISTORY
    this.display = "Social History"
    this.parent = ModulesId.INTAKE
    this.icon = faHistory
    this.content = <SocialHistoryContainer />
  }
}

export { SocialHistorySubModule }
