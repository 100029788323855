import { Bundle, Organization } from "fhir"
import { useMutation } from "@tanstack/react-query"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

const useCreateCarrier = (onSuccess?: (carrier: Organization) => void, onSettled?: () => void) => {
  const { transaction } = useClient()

  const createFn = async (org: Organization) => {
    const params = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "name",
          value: {
            string: org.name,
          },
        },
        {
          name: "address",
          value: {
            address: org.address?.[0],
          },
        },
      ],
    }

    const reqBundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: [
        {
          request: {
            method: "POST",
            url: "$insurance-setup",
          },
          resource: params,
        },
        {
          request: {
            method: "POST",
            url: "$insurance-setup-temp-quest",
          },
          resource: params,
        },
      ],
    }

    const respBundle = await transaction<Bundle>(reqBundle)

    const newCarrier = (respBundle as Bundle).entry?.[0]?.resource as Organization

    return newCarrier
  }

  const { mutate: createCarrier, isPending: isAdding } = useMutation({
    mutationFn: createFn,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (carrier) => {
      displayNotificationSuccess("Carrier created successfully!")
      onSuccess?.(carrier)
    },
    onSettled,
  })

  return { createCarrier, isAdding }
}

export { useCreateCarrier }
