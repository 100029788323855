import { faCalendar, faCalendarDay, faEdit, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns/parseISO"

import { formatsByTypes } from "data"
import { formatDate, getBadgeColor } from "utils"

import { StackedListItemProps } from "../../components/StackedListItem"
import { MenuStyles } from "../../types"
import { PlanData } from "../types"

const planItemModel = ({
  plan,
  isLoading,
  viewPlan,
  editPlan,
  cancelPlan,
  contextLabel,
  showConfig = true,
  lineItemClassname,
}: PlanItemModelArgs): StackedListItemProps => ({
  isLoading,
  leftData: [
    {
      lineItems: [
        { name: "Title", value: plan.carePlan.title ?? plan.planDefinition?.title ?? `Unnamed ${contextLabel}` },
      ],
    },
    {
      lineItems: [
        {
          name: "Created",
          value: plan.carePlan.created
            ? formatDate(parseISO(plan.carePlan.created), formatsByTypes.LONG_DATETIME)
            : "No date",
          icon: faCalendar,
        },
        //...(plan.carePlan?.author ? [{ name: "Author", value: plan.carePlan?.author.display, icon: faUser }] : []),
        ...(plan.appointment?.start
          ? [
              {
                name: "Appointment",
                icon: faCalendarDay,
                value: formatDate(
                  parseISO(plan.appointment.start.toString()),
                  formatsByTypes.LONG_DATETIME_WITH_TIMEZONE,
                ),
              },
            ]
          : []),
      ],
    },
  ],
  badge: getBadgeColor(
    plan.carePlan.status === "revoked"
      ? "cancelled"
      : contextLabel === "order" && plan.carePlan.status === "active"
        ? "pending"
        : plan.carePlan.status,
  ),
  onClick: plan.carePlan.status !== "draft" ? viewPlan : () => {},
  menu: [
    ...(showConfig && plan.carePlan.status === "draft" && editPlan
      ? [
          {
            label: `Edit ${contextLabel}`,
            icon: <FontAwesomeIcon icon={faEdit} />,
            command: editPlan,
            disabled: plan.carePlan.status !== "draft",
          },
        ]
      : []),
    ...(showConfig && cancelPlan
      ? [
          {
            label: "Cancel",
            icon: <FontAwesomeIcon icon={faTrashCan} />,
            command: cancelPlan,
            disabled: !["active", "draft"].includes(plan.carePlan.status),
          },
        ]
      : []),
  ],
  menuStyle: !editPlan && !cancelPlan ? MenuStyles.ExternalAction : undefined,
  lineItemClassname,
})

interface PlanItemModelArgs {
  plan: PlanData
  isLoading: boolean
  lineItemClassname?: string
  viewPlan: () => void
  editPlan?: () => void
  cancelPlan?: () => void
  contextLabel?: string
  showConfig?: boolean
}

export { planItemModel }
