import { faEye, faPencil, faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns"
import { Questionnaire, QuestionnaireResponse } from "fhir"
import { FieldProps, FormikValues, useFormikContext } from "formik"
import { Dialog } from "primereact/dialog"
import { Dropdown, DropdownProps } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { FC, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { formatsByTypes } from "data"
import { SurveyContainer } from "surveys"
import { formatDate, strCapitalize } from "utils"

import { Badge } from "../components/Badge"
import { Button } from "../components/Buttons"
import { FormField, FormFieldBaseProps } from "./FormField"

const QRDropdownField: FC<Props> = ({
  questionnaire,
  options,
  onAssignSurvey,
  onHideSurvey,
  handleChange,
  label,
  field,
  isLoading,
  optionLabel,
  optionValue,
  disabled,
  ...fieldProps
}) => {
  const [questPreview, setQuestPreview] = useState<string>()
  const [assingSurvey, setAssingSurvey] = useState(false)
  const [params, setParams] = useSearchParams()
  const { values, setFieldValue } = useFormikContext<FormikValues>()

  useEffect(() => {
    if (options?.[0]?.id && !values[field]) {
      setFieldValue(field, options[0])
      handleChange?.(options[0])
    }
  }, [])

  const itemTemplate = (item?: QuestionnaireResponse) => (
    <div
      className={classNames(
        "flex justify-between items-center guiding-questionnare-item text-sm",
        `guiding-questionnaire-status-${item?.status}`,
      )}
    >
      <div className="flex flex-1 grow">
        {item?.authored && formatDate(parseISO(item.authored), formatsByTypes.LONG_DATE)}
        {item?.status && (
          <Badge
            className="ml-4"
            text={strCapitalize(item.status.replace("-", " "))}
            colorStyle={item.status === "completed" ? "green" : "yellow"}
          />
        )}
      </div>
      <FontAwesomeIcon
        icon={item?.status === "completed" ? faEye : faPencil}
        title={item?.status === "completed" ? "Preview" : "Edit"}
        onClick={(e) => {
          e.stopPropagation()
          previewQuest(item?.id)
        }}
      />
    </div>
  )

  const previewQuest = (questId?: string) => {
    setQuestPreview(questId)
    questId ? params.set("qrId", questId) : params.delete("qrId")
    setParams(params)
  }

  const assignNewSurvey = () => {
    onAssignSurvey?.()
    setAssingSurvey(true)
  }

  const hideDialog = () => {
    previewQuest(undefined)
    onHideSurvey()
    setAssingSurvey(false)
  }

  const hideDialogWithDelay = () => {
    setTimeout(hideDialog, 1000)
  }

  const isSurveyAssigned = options?.some((qr) => qr.status === "in-progress")

  return (
    <>
      <FormField
        {...{ field, ...fieldProps }}
        label={label ?? `Questionnaire${questionnaire ? `: ${questionnaire.title}` : ""}`}
      >
        {({ field: { name, value, onChange }, meta: { touched, error } }: FieldProps) => (
          <div className="flex justify-between space-x-4">
            <Dropdown
              id={name}
              name={name}
              options={options}
              optionLabel={optionLabel}
              optionValue={optionValue}
              itemTemplate={itemTemplate}
              valueTemplate={itemTemplate}
              onChange={(e) => {
                onChange(e)
                handleChange?.(e.value)
              }}
              value={value}
              className={classNames("flex flex-1 p-inputtext-sm", {
                "p-invalid": touched && error,
                horizontal: fieldProps.horizontal,
              })}
              dataKey="id"
              loading={isLoading}
              appendTo={"self"}
              disabled={disabled}
            />
            {!isSurveyAssigned && questionnaire?.id && !!onAssignSurvey && (
              <Button
                buttonStyle="default"
                icon={faPlus}
                label="New survey"
                onClick={assignNewSurvey}
                loading={assingSurvey}
                disabled={assingSurvey || disabled}
              />
            )}
          </div>
        )}
      </FormField>
      <Dialog
        visible={!!questPreview}
        onHide={hideDialog}
        draggable={false}
        resizable={false}
        className="w-2/3 h-4/5"
        header="Preview questionnaire"
      >
        <SurveyContainer onFinish={hideDialogWithDelay} />
      </Dialog>
    </>
  )
}

type Props = FormFieldBaseProps<string> & {
  isLoading?: boolean
  questionnaire?: Questionnaire
  options: QuestionnaireResponse[]
  onAssignSurvey?(): void
  onHideSurvey(): void
  handleChange?(qr?: QuestionnaireResponse): void
} & Pick<DropdownProps, "optionLabel" | "optionValue" | "readOnly" | "disabled">

export { QRDropdownField }
