import { faExternalLink } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useId, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { GroupedList, InfiniteScroll, ModulesId, SearchInput, SkeletonLoader, StackedListItem } from "commons"
import { labObservationModelBuilder } from "commons/labs"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import { useLabObservations } from "../hooks"

const LaboratoryKnowledge = () => {
  const { appModules } = useAppModuleContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const { patientId } = usePatientContext()
  const [searchText, setSearchText] = useState<string | undefined>()

  const { observations, isLoading, hasNextPage, fetchNextPage } = useLabObservations(patientId, searchText)

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const showOrder = (orderId: string) => {
    setSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
      view: ModulesId.LABSORDER,
      order: orderId,
    })
  }

  return (
    <>
      <SearchInput search={(value) => setSearchText(value)} className="min-w-1/2 p-2" isLoading={isLoading} />
      {isLoading ? (
        <SkeletonLoader repeats={4} loaderType="two-lines" />
      ) : (
        <div className="overflow-y-auto bg-white mt-6">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
            <GroupedList
              className="grow"
              groups={observations}
              renderItem={(observation) => (
                <StackedListItem
                  modelData={labObservationModelBuilder(observation)}
                  key={observation.id}
                  allowExpandItems
                />
              )}
              renderDivider={(key, extraProps) => (
                <div className="sticky top-0 z-10 border-t border-b border-gray-200 bg-gray-50 p-1 text-sm font-medium text-gray-500 flex justify-between">
                  <h3>{key}</h3>

                  {extraProps?.order && (
                    <FontAwesomeIcon
                      icon={faExternalLink}
                      title="See order"
                      className="cursor-pointer"
                      onClick={() => showOrder(extraProps.order)}
                    />
                  )}
                </div>
              )}
              renderEmptyState={() => (
                <div className="text-center m-auto mt-20">
                  <FontAwesomeIcon
                    icon={appModules[ModulesId.LABSORDER].getIcon()}
                    size="2x"
                    className="text-slate-400"
                  />
                  <h3 className="mt-2 text-sm font-semibold text-gray-900">No labs found</h3>
                </div>
              )}
            />
          </InfiniteScroll>
        </div>
      )}
    </>
  )
}

export { LaboratoryKnowledge }
