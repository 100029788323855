import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Reference } from "fhir"
import { Form, Formik, FormikProps } from "formik"
import { Button } from "primereact/button"
import { useMemo } from "react"

import { AutoCompletePatientField, DateField, MultiSelectField, ReferenceDropdownField, ValueSetIds } from "commons"
import { useValueSet } from "value-set"

import { FilterProps } from "../types"

const EventsFilters = ({ initialValues, practitioners, onSearch, onClearFilters }: Props) => {
  const { codes: types } = useValueSet({ valueSetId: ValueSetIds.AE_SUBTYPE })

  const eventTypes = useMemo(() => {
    return (
      types?.map((type) => ({
        code: type.code,
        label: type.display,
      })) ?? []
    )
  }, [types])

  const renderForm = ({ isSubmitting }: FormikProps<FilterProps>) => (
    <Form className="flex flex-col h-full bg-white">
      <MultiSelectField
        label="Type"
        field="type"
        options={eventTypes}
        horizontal
        className="items-end"
        inputClassName="slashed"
        showClear
      />
      <DateField
        label="Date"
        field="dates"
        maxDate={new Date()}
        selectionMode="range"
        readOnlyInput
        horizontal
        className="items-end"
        inputClassName="slashed"
      />
      <AutoCompletePatientField
        field="patient"
        label="Patient"
        horizontal
        className="items-end"
        inputClassName="slashed"
      />
      <ReferenceDropdownField
        label="Practitioner"
        field="practitioner"
        options={practitioners}
        horizontal
        className="items-end"
        inputClassName="slashed"
        isClearable
      />

      <div className="flex w-full justify-end pt-3 lg:pt-5 h-12 lg:h-16 bg-white">
        <Button
          type="button"
          label="Clear"
          disabled={
            isSubmitting ||
            (!initialValues.type && !initialValues.dates && !initialValues.patient && !initialValues.practitioner)
          }
          icon={<FontAwesomeIcon icon={faFilterSlash} />}
          onClick={onClearFilters}
          className="p-button-sm mr-3 button-default"
        />
        <Button type="submit" label="Search" loading={isSubmitting} className="p-button-sm button-primary" />
      </div>
    </Form>
  )

  return (
    <Formik initialValues={initialValues} onSubmit={onSearch}>
      {renderForm}
    </Formik>
  )
}

type Props = {
  initialValues: FilterProps
  practitioners?: Reference[]
  onSearch(filters: FilterProps): void
  onClearFilters(): void
}

export { EventsFilters }
