import { ModulesId, SubModule } from "commons/Module"

import { ProcedureDetails } from "procedures"

class ProcedureDetailsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.PROCEDURES_DETAILS
    this.parent = ModulesId.PROCEDURES
    this.display = "Procedure details"
    this.content = <ProcedureDetails />
    this.searchParam = "procedureId"
  }
}

export { ProcedureDetailsSubModule }
