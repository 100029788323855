import { FC } from "react"
import { faBarsFilter } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MultiSelect } from "primereact/multiselect"

import { ChatStatus, chatStatusOptions } from "../data"

import "./ChatFiltersMenu.css"

const ChatFiltersMenu: FC<Props> = ({ selectedItems, onSelect }) => (
  <div className="chat-filters-dropdown flex items-center justify-center">
    <MultiSelect
      value={selectedItems}
      options={chatStatusOptions}
      optionLabel="display"
      scrollHeight="300px"
      optionValue="value"
      filter={false}
      showSelectAll={false}
      onChange={(e) => {
        onSelect(e.value)
      }}
      panelClassName="hidden-header min-w-44"
      className="hidden-labels custom-trigger"
      dropdownIcon={<FontAwesomeIcon icon={faBarsFilter} size="lg" />}
      title="Filter chats by"
    />
  </div>
)

type Props = {
  selectedItems: ChatStatus[]
  onSelect(_: ChatStatus[]): void
}

export { ChatFiltersMenu }
