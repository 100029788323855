import { EventInput } from "@fullcalendar/core"
import { codeableConceptAsString, humanNameAsString } from "fhir"

import { CalendarAppointment } from "appointments"

const useEvents = (appointments: CalendarAppointment[]) =>
  appointments.reduce<EventInput[]>((events, { appointment, patient }) => {
    const event = {
      id: appointment.id,
      title: appointment.description,
      start: appointment.start,
      status: appointment.status,
      color: getEventColorByType(codeableConceptAsString(appointment.appointmentType)),
      extendedProps: {
        patientId: patient?.id,
        patientName: humanNameAsString(patient?.name?.[0]),
        appointment: appointment,
      },
    }

    return [...events, event]
  }, [])

const getEventColorByType = (type: string) =>
  ({
    "Check-up": "#22C55E",
    Emergency: "#F59E0B",
    "Follow-up": "#3B82F6",
    Routine: "#2DD4BF",
    "Walk-in": "#A855F7",
  })[type]

export { useEvents }
