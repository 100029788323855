import { ClientProvider, QuestionnaireResponseContainer } from "@chartedhealth/survey-components"
import "@chartedhealth/survey-components/dist/index.css"
import { faClipboard, faFilePdf } from "@fortawesome/pro-light-svg-icons"

import { useClient } from "api"
import { Button, EmptyMessage } from "commons"
import { datadogLogs } from "logger"
import { useAuth } from "security"
import { openLinkInNewTab } from "utils"

import { usePrintQuestionnaire } from "../../hooks"

const QuestionnaireView = ({ qrId }: Props) => {
  const { user } = useAuth()
  const { search, update, operationRequest, read, patch } = useClient()
  const { printQuestionnaire, isLoadingPrint } = usePrintQuestionnaire(openLinkInNewTab)

  return (
    <div className="flex flex-col flex-1 p-6 overflow-hidden">
      {qrId ? (
        <>
          <div className="flex justify-end m-4">
            <Button
              onClick={() => printQuestionnaire(qrId)}
              label="Download"
              disabled={isLoadingPrint}
              loading={isLoadingPrint}
              icon={faFilePdf}
            ></Button>
          </div>
          <div className="overflow-y-auto">
            <ClientProvider
              apm={datadogLogs.logger}
              loggedInUserResource={user?.linkedResource}
              search={search}
              operationRequest={operationRequest}
              read={read}
              patch={patch}
              update={update}
            >
              <QuestionnaireResponseContainer questionnaireId={qrId} />
            </ClientProvider>
          </div>
        </>
      ) : (
        <EmptyMessage icon={faClipboard} message="No questionnaires in plan" subMessage={false} />
      )}
    </div>
  )
}

type Props = {
  qrId: string | undefined
}

export { QuestionnaireView }
