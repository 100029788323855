import { Form, Formik, FormikProps } from "formik"
import { FC } from "react"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"

import { Encounter, Patient } from "fhir"

import { getInitialValues, sanitize, validationSchema } from "./validations"
import { EncounterForm } from "./EncounterForm"
import { useCreateEncounter } from "../hooks/useCreateEncounter"

const EncounterFormContainer: FC<Props> = ({ patient, startEncounter, closeForm }) => {
  const { createEncounter } = useCreateEncounter(patient.id as string, closeForm)

  const onSubmit = (encounter: Encounter) => {
    createEncounter(sanitize(encounter))
  }

  const renderForm = ({ isSubmitting }: FormikProps<Encounter>) => (
    <Dialog
      header="Start new Encounter"
      draggable={false}
      resizable={false}
      visible={startEncounter}
      style={{ width: "25vw" }}
      onHide={closeForm}
      footer={<></>}
      contentStyle={{ paddingBottom: "0px" }}
    >
      <Form>
        <EncounterForm />
        <div className="text-right mt-2">
          <Button type="submit" label="Start" className="button-primary" loading={isSubmitting} />
        </div>
      </Form>
    </Dialog>
  )

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={getInitialValues(patient)}
      onSubmit={onSubmit}
    >
      {renderForm}
    </Formik>
  )
}

type Props = { patient: Patient; closeForm(): void; startEncounter: boolean }

export { EncounterFormContainer }
