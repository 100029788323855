import { faWarning } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { useSearchParams } from "react-router-dom"

import { AppModule, LoadingView, TextEllipsis } from "commons"
import { useAppModuleContext } from "internals"
// import { EncounterBanner } from "encounter"

const ModuleHub = ({ onItemClick, useSideBarStyle, disabledWarningMessages }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { activeWorkspaceModules } = useAppModuleContext()

  const updateView = (moduleId: string) => {
    const params = new URLSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
      view: moduleId,
    })
    setSearchParams(params)
  }

  return (
    <div className={classNames("py-7 px-3 overflow-y-auto", { "flex justify-center": useSideBarStyle })}>
      {/* {!useSideBarStyle && <EncounterBanner />} */}
      <div
        className={classNames({
          "flex flex-row flex-wrap gap-8": !useSideBarStyle,
          "flex flex-1 flex-col gap-2": useSideBarStyle,
        })}
      >
        {activeWorkspaceModules.map((item) => (
          <HubItem
            key={item.getId()}
            onItemClick={() => {
              onItemClick?.()
              updateView(item.getId())
            }}
            module={item}
            useSideBarStyle={useSideBarStyle}
            disabledWarningMessage={disabledWarningMessages[item.getId()]}
            className={`module-${item.getId()}`}
          />
        ))}
      </div>
    </div>
  )
}

const HubItem = ({
  module,
  onItemClick,
  useSideBarStyle,
  moduleNotificationCount,
  disabledWarningMessage,
  className,
}: HubItemProps) => {
  const isLoadingNotifications = moduleNotificationCount?.isLoading ?? disabledWarningMessage?.isLoading ?? false
  const count = moduleNotificationCount?.count ?? 0
  const isLoadingWarningMessage = disabledWarningMessage?.isLoading ?? false
  const warningMessage = disabledWarningMessage?.message ?? ""
  const isLoading = isLoadingNotifications || isLoadingWarningMessage

  const loading = (
    <div className="w-4 h-4">
      <LoadingView />
    </div>
  )

  return (
    <div
      className={classNames(
        "relative flex items-center rounded-lg",
        {
          "border hover:bg-gray-50": useSideBarStyle,
          "flex-col": !useSideBarStyle,
          "cursor-pointer": !isLoading && !warningMessage,
          shadow: !warningMessage && useSideBarStyle,
          "bg-gray-50": warningMessage && useSideBarStyle,
        },
        className,
      )}
      onClick={isLoading || warningMessage ? undefined : onItemClick}
    >
      <div className="flex absolute -top-2 right-2 z-1 text-[12px] space-x-1">
        {isLoadingNotifications ? (
          loading
        ) : (
          <span
            className={classNames(
              "border border-cyan-400 bg-white rounded-full h-5 w-5 justify-center items-center text-red-400 font-bold",
              count === 0 ? "hidden" : "flex",
            )}
          >
            {count}
          </span>
        )}
        {isLoadingWarningMessage ? (
          loading
        ) : (
          <span
            className={classNames(
              "disabled-module-warning border border-cyan-400 bg-white rounded-full h-5 w-5 justify-center items-center text-yellow-700 font-bold",
              module.getId(),
              !warningMessage ? "hidden" : "flex",
            )}
          >
            <Tooltip
              target={`.disabled-module-warning.${module.getId()}`}
              content={warningMessage}
              position="left"
              event="hover"
              className="text-xs p-0 m-0"
            />
            <FontAwesomeIcon icon={faWarning} />
          </span>
        )}
      </div>
      <div
        className={classNames("flex justify-center items-center text-primary", {
          "p-8 border rounded-lg text-xl hover:bg-gray-50": !useSideBarStyle,
          "w-20 h-full rounded-l-lg bg-slate-100": useSideBarStyle,
          "bg-gray-50 text-slate-400": !isLoadingWarningMessage && warningMessage,
          "shadow-sm": !isLoadingWarningMessage && !warningMessage && !useSideBarStyle,
        })}
      >
        <FontAwesomeIcon icon={module.getIcon()} size="2x" className="fa-fw" />
      </div>
      <div
        className={classNames("font-normal text-sm", {
          "py-6 px-4": useSideBarStyle,
          "py-3 text-center": !useSideBarStyle,
          "text-slate-400": !isLoadingWarningMessage && warningMessage,
        })}
      >
        <TextEllipsis text={module.getDisplay()} charNumber={14} />
      </div>
    </div>
  )
}

type HubItemProps = Omit<Props, "disabledWarningMessages"> & {
  module: AppModule
  moduleNotificationCount?: ModuleNotification
  disabledWarningMessage?: ModuleWarningMessage
  className?: string
}

type ModuleNotification = { isLoading: boolean; count: number }

export type ModuleWarningMessage = { isLoading: boolean; message: string }

type Props = {
  onItemClick?: () => void
  useSideBarStyle?: boolean
  disabledWarningMessages: Record<string, ModuleWarningMessage>
}

export { ModuleHub }
