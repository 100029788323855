import { isCarePlan, isMedication, Medication, MedicationKnowledge, MedicationRequest } from "fhir"
import { ConfirmDialog } from "primereact/confirmdialog"
import { useId, useMemo, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"

import {
  EmptyMessage,
  FooterActions,
  InfiniteScroll,
  MedicationKnowledgeDetails,
  ModulesId,
  SkeletonLoader,
  Slideover,
  StackedListContainer,
  useChargeItemDefinitions,
} from "commons"
import { MedicationRequestData, MedicationRequestDetails, useMedicationRequests } from "commons/meds"
import { BILLING_TYPES_CODES } from "data"
import { useAppModuleContext } from "internals"
import { useOrganizationContext } from "organization"
import { getBillingTypeCode, getMedCodes, getPriceByCode } from "utils"

import {
  useCompleteMrOrder,
  useCreateMedicationRequest,
  useDeleteMedicationRequest,
  useMedicationRequestDataBind,
  useStopMrOrder,
} from "../../hooks"
import { MedicationRequestFormData, MedicationRequestInfo } from "../../types"
import { discardMR, renewMR } from "../helpers"
import { MedicationDetails } from "../MedicationDetails"
import { prescriptionItemModel } from "./prescriptionItemModel"

const PrescriptionList = ({ searchFilter, statusFilter, onEdit }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { patientId } = useParams()
  const { appModules } = useAppModuleContext()
  const { currentOrganizationId } = useOrganizationContext()
  const { medicationRequests, medicationKnowledges, medicationDispenses, isLoading, hasNextPage, fetchNextPage } =
    useMedicationRequests({
      patientId: patientId as string,
      category: "medication",
      statusFilter: statusFilter ?? [],
      perPage: 100,
      page: 1,
      searchText: searchFilter,
    })

  const [selectedMK, setSelectedMK] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()
  const [selectedMed, setSelectedMed] = useState<Medication>()

  const { billToPracticeOrInsuranceMrs, billToPatientMrs } = medicationRequests?.reduce(
    (acc, mr) => {
      const billingTypeCode = getBillingTypeCode(mr)

      return billingTypeCode === undefined
        ? {
            billToPracticeOrInsuranceMrs: [...acc.billToPracticeOrInsuranceMrs, mr],
            billToPatientMrs: [...acc.billToPatientMrs, mr],
          }
        : billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
          ? {
              billToPracticeOrInsuranceMrs: acc.billToPracticeOrInsuranceMrs,
              billToPatientMrs: [...acc.billToPatientMrs, mr],
            }
          : {
              billToPracticeOrInsuranceMrs: [...acc.billToPracticeOrInsuranceMrs, mr],
              billToPatientMrs: acc.billToPatientMrs,
            }
    },
    { billToPracticeOrInsuranceMrs: [] as MedicationRequest[], billToPatientMrs: [] as MedicationRequest[] },
  ) ?? { billToPracticeOrInsuranceMrs: [], billToPatientMrs: [] }

  const billToPracticeOrInsuranceMedicationKnowledgeCodes = billToPracticeOrInsuranceMrs
    ? getMedCodes({ meds: billToPracticeOrInsuranceMrs, withQty: true })
    : undefined
  const billToPatientMedicationKnowledgeCodes = billToPatientMrs
    ? getMedCodes({ meds: billToPatientMrs, withQty: true })
    : undefined

  const { chargeItemDefinitions: cids } = useChargeItemDefinitions({
    organizationId: currentOrganizationId,
    codes: {
      billToPracticeOrInsuranceCIDs: billToPracticeOrInsuranceMedicationKnowledgeCodes,
      billToPatientCIDs: billToPatientMedicationKnowledgeCodes,
    },
  })

  const { medicationRequestData: mrDataWithoutPrices } = useMedicationRequestDataBind({
    medicationRequests,
    medicationKnowledges,
    medicationDispenses,
  })

  const { deleteMedicationRequest } = useDeleteMedicationRequest()
  const { stopMedicationRequest } = useStopMrOrder()
  const { completeMedicationRequest } = useCompleteMrOrder()
  const { createMedicationRequest } = useCreateMedicationRequest()

  const medicationRequestData = mrDataWithoutPrices.map((item) => {
    const billingTypeCode = getBillingTypeCode(item?.medicationRequestInfo)
    const patientPrice = getPriceByCode({
      chargeItemDefinitions: cids?.billToPatientCIDs ?? {},
      medCoding: item.medicationKnowledge?.code?.coding,
      factor: item.medicationRequestInfo?.dispenseRequest?.quantity?.value,
      includePatientFee: true,
    })
    const practicePrice = getPriceByCode({
      chargeItemDefinitions: cids?.billToPracticeOrInsuranceCIDs ?? {},
      medCoding: item.medicationKnowledge?.code?.coding,
      factor: item.medicationRequestInfo?.dispenseRequest?.quantity?.value,
    })

    const priceInfo =
      billingTypeCode === undefined
        ? { patientPrice, practicePrice }
        : billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
          ? { patientPrice }
          : { practicePrice }

    return {
      ...item,
      ...priceInfo,
    }
  })

  const selectedMedicationRequestData = useMemo(() => {
    const mrId = searchParams.get("mrId")
    return medicationRequestData.find(({ medicationRequestInfo }) => medicationRequestInfo.id === mrId)
  }, [searchParams, medicationRequestData])

  const navToAddPrescriptions = () => {
    searchParams.set("subview", ModulesId.EPRESCRIBE_NEW)
    setSearchParams(searchParams)
  }

  const navToPrescriptionsDraft = () => {
    searchParams.set("subview", ModulesId.EPRESCRIBE_DRAFT)
    setSearchParams(searchParams)
  }

  const hasOutidePlanDraftMr = medicationRequestData.some(
    ({ medicationRequestInfo }) =>
      medicationRequestInfo.status === "draft" && !medicationRequestInfo?.basedOn?.some(isCarePlan),
  )

  const addOptions = useMemo(
    () => [
      {
        label: "Finish Drafts",
        style: "default",
        command: navToPrescriptionsDraft,
        disabled: !hasOutidePlanDraftMr,
      },
      {
        label: "Create Prescription",
        command: navToAddPrescriptions,
      },
    ],
    [appModules, hasOutidePlanDraftMr],
  )

  const discard = (mrId: string) => {
    discardMR(mrId, () => {
      deleteMedicationRequest(mrId)
    })
  }
  const stop = (mrId: string) => {
    stopMedicationRequest(mrId)
  }
  const complete = (mrId: string) => {
    completeMedicationRequest(mrId)
  }

  const previewMedOrMK = (mrData: MedicationRequestData) => {
    if (mrData.medicationRequestInfo.contained?.[0] && isMedication(mrData.medicationRequestInfo.contained[0])) {
      setSelectedMed(mrData.medicationRequestInfo.contained[0])
      return
    }

    setSelectedMK({ mk: mrData.medicationKnowledge, mr: mrData.medicationRequestInfo })
  }

  const showMRDetails = (mr: MedicationRequestInfo) => {
    if (mr.id) {
      searchParams.set("mrId", mr.id)
      setSearchParams(searchParams)
    }
  }

  const hideMRDetails = () => {
    searchParams.delete("mrId")
    setSearchParams(searchParams)
  }

  const renew = (medicationRequest: MedicationRequestInfo) => {
    renewMR(medicationRequest, (mr) => {
      createMedicationRequest(mr)
    })
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) {
    return loader()
  }

  if (medicationRequestData.length === 0) {
    return (
      <EmptyMessage
        icon={appModules[ModulesId.EPRESCRIBE].getIcon()}
        itemTitle="Prescription"
        actionText="Create Prescription"
        action={navToAddPrescriptions}
      />
    )
  }

  return (
    <>
      <div className="h-full overflow-auto">
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <StackedListContainer
            data={medicationRequestData}
            keyGenerator={({ medicationRequestInfo }) => medicationRequestInfo.id}
            itemModelBuilder={(item) =>
              prescriptionItemModel({
                mrData: item,
                edit: onEdit,
                discard,
                stop,
                complete,
                renew,
                preview: previewMedOrMK,
                showDetails: showMRDetails,
                hiddenPrices: true,
              })
            }
          />
        </InfiniteScroll>
      </div>
      <FooterActions actions={addOptions} />
      <ConfirmDialog />
      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.mk}
        onHide={() => setSelectedMK(undefined)}
        showImgFallback={false}
        mr={selectedMK?.mr}
      />
      <MedicationDetails selectedMed={selectedMed} setSelectedMed={setSelectedMed} />
      <Slideover
        showSlide={!!selectedMedicationRequestData}
        onHide={hideMRDetails}
        title="Medication Request Details"
        showCancel
        cancelLabel="Close"
        dismissable
        showCloseIcon
      >
        {selectedMedicationRequestData && (
          <MedicationRequestDetails
            medicationRequest={selectedMedicationRequestData.medicationRequestInfo}
            medicationDispense={selectedMedicationRequestData.medicationDispense}
          />
        )}
      </Slideover>
    </>
  )
}

type Props = { statusFilter?: string[]; searchFilter?: string; onEdit(mrData: MedicationRequestFormData): void }

export { PrescriptionList }
