import {
  faAllergies,
  faCalendarDays,
  faCommentsAlt,
  faCreditCard,
  faDiagnoses,
  faFlask,
  faHandshake,
  faTablets,
} from "@fortawesome/pro-thin-svg-icons"
import pluralize from "pluralize"

import { useActiveAllergies } from "allergies"
import { useScheduledAppointment } from "appointments"
import { useUnreadedCommunications } from "communication"
import { useActiveConditions } from "conditions"
import { usePatientContext } from "patients"

import { PatientPreferedPaymentMethod } from "./PatientPreferedPaymentMethod"
import { PatientSummaryKnowledgeItem } from "./PatientSummaryKnowledgeItem"

const PatientSummary: React.FC = () => {
  const { patientId, preferedCreditCard } = usePatientContext()

  const { activeConditions, conditionsCount, conditionsLoading } = useActiveConditions(patientId)
  const { activeAllergies, allergiesCount, allergiesLoading } = useActiveAllergies(patientId)
  const { scheduledAppointment, appointmentsLoading } = useScheduledAppointment(patientId)
  const { count: unreadCount, isLoading: loadingMessages } = useUnreadedCommunications(patientId)

  return (
    <div className="h-full relative overflow-hidden mt-1">
      <div className="h-full overflow-auto">
        <PatientSummaryKnowledgeItem
          icon={faCommentsAlt}
          content={unreadCount ? pluralize(`${unreadCount} new message`, unreadCount) : "No new messages found"}
          view="communication"
          externalAction="View messages"
          italic={unreadCount === 0}
          isLoading={loadingMessages}
          label="Message"
        />
        <PatientSummaryKnowledgeItem
          icon={faCalendarDays}
          content={scheduledAppointment ?? "No appointment scheduled"}
          view="calendar"
          externalAction="View appointments"
          italic={scheduledAppointment === undefined}
          isLoading={appointmentsLoading}
          label="Appointment"
        />

        <PatientSummaryKnowledgeItem
          icon={faTablets}
          content={"No upcoming dispenses"}
          italic
          label="Upcoming dispenses"
        />

        <PatientSummaryKnowledgeItem icon={faFlask} content={"No active lab orders"} italic label="Lab orders" />

        <PatientSummaryKnowledgeItem
          icon={faAllergies}
          content={allergiesCount ? activeAllergies : "No allergies"}
          view="intake"
          subView="allergies"
          externalAction="View allergies"
          italic={allergiesCount === 0}
          isLoading={allergiesLoading}
          label="Active allergies"
        />

        <PatientSummaryKnowledgeItem
          icon={faDiagnoses}
          content={conditionsCount ? activeConditions : "No conditions"}
          view="intake"
          subView="conditions"
          externalAction="View conditions"
          italic={conditionsCount === 0}
          isLoading={conditionsLoading}
          label="Active conditions"
        />

        <PatientSummaryKnowledgeItem
          icon={faCreditCard}
          content={<PatientPreferedPaymentMethod defaultCreditCard={preferedCreditCard} />}
          italic
          label="Prefered payment method"
        />

        <PatientSummaryKnowledgeItem icon={faHandshake} content={"No enrolled plans"} italic label="Enrolled plans" />
      </div>
    </div>
  )
}

export { PatientSummary }
