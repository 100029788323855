import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { useAppModuleContext } from "internals"
import { ModulesId } from "commons"

import { LaboratoryOrderList } from "./LaboratoryOrderList"
import { LaboratoryOrderDetails } from "./LaboratoryOrderDetails"

const LaboratoryOrderContainer = () => {
  const [searchParams] = useSearchParams()
  const { activeSubModule, setActiveSubModule } = useChartContext()
  const { appSubModules } = useAppModuleContext()

  useEffect(() => {
    let subModule = null

    if (searchParams.get("order")) {
      subModule = appSubModules["laboratory"][ModulesId.LABORATORY_ORDER_DETAILS]
    }

    setActiveSubModule(subModule)
  }, [appSubModules, searchParams, setActiveSubModule])

  return !activeSubModule ? <LaboratoryOrderList /> : <LaboratoryOrderDetails />
}

export { LaboratoryOrderContainer }
