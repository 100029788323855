import { TDicc, TVonageSignalPatient } from "./types"

export const handleF5KeyFunc = ({ listen, action }: { listen: boolean; action: () => void }) => {
  const handlePreventF5 = (e: globalThis.KeyboardEvent) => {
    if (e.key === "F5") {
      e.preventDefault()
      action()
    }
  }
  listen
    ? document.addEventListener("keydown", (e) => handlePreventF5(e))
    : document.removeEventListener("keydown", (e) => handlePreventF5(e))
}

export const signal = (signalType?: TVonageSignalPatient) => `signal${signalType && `:${signalType}`}`

export const Dicc: TDicc = (toSort) => toSort.reduce((acc, cur) => ({ ...acc, [cur.name]: cur }), {})
