import { faCalendarDays, faUser } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns"
import { humanNameAsString, isPatient, isPractitioner } from "fhir"

import { ListItem } from "commons"
import { formatsByTypes } from "data"
import { formatDate, strCapitalize } from "utils"

import { AuditEventData } from "../types"

const AuditEventListItem = ({ auditEvent, toggleSidePanel, selectEvent }: Props) => {
  const requestor = auditEvent.agent.find(({ requestor, who }) => requestor && (isPractitioner(who) || isPatient(who)))

  const viewDetails = () => {
    selectEvent(auditEvent?.id as string)
    toggleSidePanel(true)
  }

  const head = (
    <>
      {requestor && (
        <p title="Requestor" className="truncate font-medium">
          {strCapitalize(requestor.name as string)} -
        </p>
      )}
      <p className="truncate font-medium capitalize">{auditEvent.subtype?.[0].display}</p>
    </>
  )

  const details = (
    <>
      {auditEvent.participants.map((p) => {
        return p?.id ? (
          <span title="Participant" className="flex items-center" key={p.id}>
            <FontAwesomeIcon icon={faUser} className="mr-1.5 text-gray-400" />
            {humanNameAsString(p?.name?.[0])}
          </span>
        ) : null
      })}
      <>
        <span title="Date" className="flex items-center pl-2">
          <FontAwesomeIcon icon={faCalendarDays} className="mr-1.5 text-gray-400" />
          {formatDate(parseISO(auditEvent.period?.start as string), formatsByTypes.LONG_DATETIME)}
        </span>
      </>
    </>
  )

  return <ListItem header={head} subHeader={details} onContentClick={viewDetails} itemPadding />
}

type Props = {
  auditEvent: AuditEventData
  toggleSidePanel(show: boolean): void
  selectEvent(id: string): void
}

export { AuditEventListItem }
