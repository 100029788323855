import { ModulesId, SubModule } from "commons/Module"

import { SurveyContainer } from "../../../surveys/components/SurveyContainer"

class SurveyDetailsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.SURVEY_DETAILS
    this.parent = ModulesId.SURVEY
    this.display = "Questionnaire details"
    this.content = <SurveyContainer />
  }
}

export { SurveyDetailsSubModule }
