import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Appointment } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { useLoginContext } from "security"
import { displayNotificationSuccess } from "utils"

import { patientAptsQueryKeys, practitionerAptsQueryKeys } from "../query-keys"

const useCreateAppointment = (onSuccess: () => void) => {
  const { create } = useClient()
  const queryClient = useQueryClient()
  const { loggedInPractitionerId } = useLoginContext()

  const newAppointment = async (appointment: Appointment) => {
    return create("Appointment", appointment)
  }

  const { mutate: createAppointment, isPending: isAdding } = useMutation({
    mutationFn: newAppointment,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: patientAptsQueryKeys.all })
      queryClient.refetchQueries({ queryKey: practitionerAptsQueryKeys.all(loggedInPractitionerId), type: "all" })
      displayNotificationSuccess("Appointment booked successfully!")
      // datadogLogs.logger.info(`Appointment of ${loggedInPractitionerId} booked  successfully! `)
      onSuccess()
    },
  })

  return { createAppointment, isAdding }
}

export { useCreateAppointment }
