import { faPlus, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { isAfter, isValid, parse } from "date-fns"
import { Location, LocationHoursOfOperationArray } from "fhir"
import { FieldArray, FieldArrayRenderProps } from "formik"
import { Checkbox } from "primereact/checkbox"
import { classNames } from "primereact/utils"

import { Button, DateField, FormContainer, RoundedStyles } from "commons"
import { dayOfWeek } from "data"
import { useOrganizationContext } from "organization"

import { useUpdateLocation } from "../hooks"

const OperatingHours = () => {
  const { location } = useOrganizationContext()

  const { updateLocation } = useUpdateLocation()

  const onSubmit = (data: Location) => updateLocation(data)

  return (
    <FormContainer
      initialValue={location as Location}
      enableReinitialize
      onSubmit={onSubmit}
      saveLabel="Update"
      showCancel={false}
    >
      <LocationForm />
    </FormContainer>
  )
}

const LocationForm = () => (
  <div className="flex flex-col space-y-4">
    <span className="self-start">What days/times during a week is this location usually open?</span>
    <FieldArray name="hoursOfOperation">
      {({ name, push, remove, form: { getFieldMeta } }: FieldArrayRenderProps) => {
        const hoursOfOperation = getFieldMeta<LocationHoursOfOperationArray[]>(name).value ?? []

        const indexedHOP = hoursOfOperation.reduce<Map<string, number[]>>((acc, hop, index) => {
          const day = hop.daysOfWeek?.[0] as string
          const dayHOP = acc.get(day)
          if (dayHOP) dayHOP.push(index)
          else acc.set(day, [index])

          return acc
        }, new Map<string, number[]>())

        const isClosed = (day: string) => !hoursOfOperation.some((h) => h.daysOfWeek?.includes(day))
        const handleCheckChange = (day: string, checked: boolean) => {
          if (checked) removeDay(day)
          else addHOP(day)
        }

        const addHOP = (day: string) =>
          push<LocationHoursOfOperationArray>({
            daysOfWeek: [day],
            openingTime: "08:00:00",
            closingTime: "20:00:00",
          })

        const removeDay = (day: string) => {
          const indexes = hoursOfOperation.reduceRight<number[]>(
            (acc, h, index) => (h.daysOfWeek?.includes(day) ? [...acc, index] : acc),
            [],
          )
          indexes.forEach((index) => remove(index))
        }

        const validate = ({ value, dayHOP, hopIndex, isOpeningTime = false }: ValidateArgs): boolean => {
          if (!value) return false
          const date = parse(value, "HH:mm:ss", new Date())
          const startTime = isOpeningTime ? date : parse(hoursOfOperation[hopIndex]?.openingTime ?? "", "HH:mm:ss", new Date())
          const endTime = !isOpeningTime
            ? date
            : parse(hoursOfOperation[hopIndex]?.closingTime ?? "", "HH:mm:ss", new Date())
          if (isValid(startTime) && isValid(endTime) && isAfter(endTime, startTime)) {
            const _index = dayHOP.findIndex((d) => d === hopIndex)
            if (isOpeningTime && _index > 0) {
              const _prevClose = parse(hoursOfOperation[dayHOP[_index - 1]]?.closingTime ?? "", "HH:mm:ss", new Date())
              return !isValid(_prevClose) || isAfter(startTime, _prevClose)
            } else if (!isOpeningTime && _index < dayHOP.length - 1) {
              const _nextOpen = parse(hoursOfOperation[dayHOP[_index + 1]]?.openingTime ?? "", "HH:mm:ss", new Date())
              return !isValid(_nextOpen) || isAfter(_nextOpen, endTime)
            }
            return true
          }
          return false
        }

        return dayOfWeek.map((day) => {
          const dayHOP = indexedHOP.get(day.code as string) as number[]

          return (
            <div key={day.code} className="flex justify-between gap-x-6 w-1/2">
              <div className="flex flex-col gap-4 w-24 mt-1 text-sm">
                <span className="font-medium text-gray-900">{day.label}</span>
                <div className="flex items-center">
                  <Checkbox
                    inputId={`chk_${day.code}`}
                    onChange={(e) => handleCheckChange(day.code, e.checked ?? false)}
                    checked={isClosed(day.code)}
                  />
                  <label htmlFor={`chk_${day.code}`} className="text-sm font-medium text-gray-700 cursor-pointer">
                    Closed
                  </label>
                </div>
              </div>
              <div className="flex flex-col mt-1 min-h-24">
                {dayHOP?.map((hopIndex, index) => (
                  <div key={`${day.code}_${hopIndex}`} className="flex gap-4">
                    <DateField
                      label={index === 0 ? "Opening time" : undefined}
                      field={`hoursOfOperation[${hopIndex}].openingTime`}
                      className="text-xs"
                      timeOnly
                      validation={(value) =>
                        !validate({ value, dayHOP, hopIndex, isOpeningTime: true }) && "Invalid value"
                      }
                    />
                    <DateField
                      label={index === 0 ? "Closing time" : undefined}
                      field={`hoursOfOperation[${hopIndex}].closingTime`}
                      className="text-xs"
                      timeOnly
                      validation={(value) => !validate({ value, dayHOP, hopIndex }) && "Invalid value"}
                    />
                    <div className={classNames("flex flex-col gap-2", { "pt-7": index === 0 })}>
                      {dayHOP.length > 1 && (
                        <Button
                          icon={faTrashCan}
                          buttonStyle="outlined"
                          roundedStyle={RoundedStyles.Full}
                          className="h-10 w-10 justify-center"
                          iconClassName="h-4 w-4"
                          onClick={() => remove(hopIndex)}
                        />
                      )}
                      {index === dayHOP.length - 1 && (
                        <Button
                          icon={faPlus}
                          buttonStyle="outlined"
                          roundedStyle={RoundedStyles.Full}
                          className="h-10 w-10"
                          onClick={() => addHOP(day.code)}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
        })
      }}
    </FieldArray>
  </div>
)

interface ValidateArgs {
  value: string
  dayHOP: number[]
  hopIndex: number
  isOpeningTime?: boolean
}

export { OperatingHours }
