import { ModulesId, SubModule } from "commons/Module"
import { MedicationOrderEditContainer } from "eprescribe/components/orders"

class PrescriptionOrderEditSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.EPRESCRIBE_ORDER_EDIT
    this.parent = ModulesId.EPRESCRIBE
    this.display = "Edit Order"
    this.content = <MedicationOrderEditContainer />
  }
}

export { PrescriptionOrderEditSubModule }
