import { Dialog } from "primereact/dialog"
import { PropsWithChildren } from "react"

import { Button } from "commons"
import { usePatientContext } from "patients"

import { Patient360SearchDetails } from "./Patient360SearchDetails"

const Patient360ImportDataDialog = ({
  children,
  header,
  showDialog,
  actionDisabled,
  isLoading,
  totalRecords,
  newRecords,
  organizationsSearched,
  renderFooter,
  handleCloseDialog,
  handleDialogAction,
}: PropsWithChildren<Props>) => {
  const { patient } = usePatientContext()

  const defaultFooter = () => (
    <>
      <Button label="Close" className="p-button button-default" onClick={handleCloseDialog} disabled={isLoading} />

      <Button
        label="Confirm Import"
        className="p-button button-primary"
        disabled={actionDisabled}
        loading={isLoading}
        onClick={handleDialogAction}
      />
    </>
  )

  return (
    <Dialog
      visible={showDialog}
      onHide={handleCloseDialog}
      draggable={false}
      className="w-full h-full md:w-[85%] lg:w-4/5 2xl:w-3/4 md:h-3/4"
      contentClassName="overflow-y-hidden"
      header={header}
      footer={
        <div className="mt-2">
          <>{renderFooter ? renderFooter() : defaultFooter()}</>
        </div>
      }
    >
      <div className="flex flex-row h-full">
        <Patient360SearchDetails
          name={patient.name}
          address={patient.address}
          birthDate={patient.birthDate}
          gender={patient.gender}
          totalRecords={totalRecords}
          newRecords={newRecords}
          organizationsSearched={organizationsSearched}
        />
        {children}
      </div>
    </Dialog>
  )
}

type Props = {
  header: string
  renderFooter?: () => JSX.Element
  showDialog: boolean
  actionDisabled?: boolean
  isLoading?: boolean
  totalRecords: number
  newRecords?: number
  organizationsSearched?: number
  handleCloseDialog: () => void
  handleDialogAction: () => void
}

export { Patient360ImportDataDialog }
