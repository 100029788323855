import { humanNameAsString } from "fhir"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import { useMemo, useState } from "react"

import { useOrganizationContext, useOrganizationPractitioners } from "organization"

const DosespotPractitionerSelect = ({ setPractitionerRoleIdSelected }: IProps) => {
  const { currentOrganizationId } = useOrganizationContext()
  const { organizationPractitionersInfo, isLoading } = useOrganizationPractitioners({
    organizationId: currentOrganizationId,
  })
  const [practitionerRoleId, setPractitionerRoleId] = useState<string>()

  const practitioners = useMemo(
    () =>
      organizationPractitionersInfo.reduce<{ label: string; value: string }[]>(
        (prev, { hasDosespotSetup, practitioner, practitionerRoleRef }) => {
          if (hasDosespotSetup && practitionerRoleRef?.id) {
            return [...prev, { label: humanNameAsString(practitioner.name?.[0]), value: practitionerRoleRef.id }]
          }

          return prev
        },
        [],
      ),
    [organizationPractitionersInfo],
  )

  return (
    <div className="flex items-center justify-center w-full h-full bg-white">
      <div className="flex flex-col justify-center absolute bg-white min-w-[25%] xl:min-w-[30%] rounded-xl shadow-sm shadow-gray-600">
        <div className="font-bold bg-gray-50 rounded-t-xl h-16 flex items-center justify-center">
          Select a practitioner
        </div>
        <div className="px-20 pb-20 pt-10">
          <div className="mt-8 xl:mt-14 w-full">
            <Dropdown
              className="w-full"
              options={practitioners}
              optionLabel="label"
              optionValue="value"
              value={practitionerRoleId}
              filterBy="label"
              resetFilterOnHide
              showFilterClear
              filter
              onChange={(e) => {
                if (e.value) {
                  setPractitionerRoleId(e.value)
                }
              }}
            />
          </div>
          <Button
            className="w-full button-primary text-center mt-10"
            label="Accept"
            onClick={practitionerRoleId ? () => setPractitionerRoleIdSelected(practitionerRoleId) : undefined}
            loading={isLoading}
            disabled={!practitionerRoleId}
          />
        </div>
      </div>
    </div>
  )
}

type IProps = {
  setPractitionerRoleIdSelected: React.Dispatch<React.SetStateAction<string | undefined>>
}

export { DosespotPractitionerSelect }
