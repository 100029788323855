import { createContext, PropsWithChildren } from "react"

import { ChartContextProps } from "../types"

const createChartContext = <T,>() => createContext<ChartContextProps<T> | undefined>(undefined)

const ChartContext = createChartContext()
ChartContext.displayName = "ChartContext"

const ChartProvider = <T,>({ children, ...rest }: PropsWithChildren<ChartContextProps<T>>) => {
  return <ChartContext.Provider value={{ ...rest }}>{children}</ChartContext.Provider>
}

export { ChartContext, ChartProvider }
