const tasksQueryKeys = {
  all: ["organization/tasks"] as const,
  list: (organizationId: string, filter?: string, code?: string, assignedTo?: string, status?: string) => [
    ...tasksQueryKeys.all,
    organizationId,
    filter,
    code,
    assignedTo,
    status,
  ],
  details: (taskId: string) => ["task", taskId],
}

export { tasksQueryKeys }
