import { faInfoCircle } from "@fortawesome/pro-light-svg-icons"
import { faPencil } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Address } from "fhir"
import { FormikHelpers, FormikValues, useFormikContext } from "formik"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { FC, useMemo, useState } from "react"
import { AnyObject } from "yup/lib/object"

import {
  AddFieldArrayItemButton,
  AddressField,
  addressTypes,
  AddressVerificationFeedback,
  CardListItem,
  DialogFormContainer,
  FormField,
  sanitizeCustomAddress,
} from "commons"

import { useSmartyAddressVerification, useSmartyAddressVerificationContext } from "commons/hooks"
import { emptyAddress } from "data"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { areAddressesEquals, getAddressSchema, getStringAddress, isPoBoxAddress } from "utils"

import { MedicationRequestFormData } from "../../types"

const PrescriptionShippingAddressField = ({ fieldName, disabled, showAdd = true, onChange }: Props) => {
  const { values, setFieldValue } = useFormikContext<MedicationRequestFormData>()
  const { patient } = usePatientContext()
  const { currentOrganizationBillingAddress: organizationAddress } = useOrganizationContext()

  const [showFormDialog, setShowFormDialog] = useState(false)

  const patientPermanentAddress = patient.address && patient.address.filter((address) => address.use !== "temp")

  const shippingAddress = values.dispenseRequest?.shippingAddress
  const isPatientAddress = useMemo(
    () =>
      shippingAddress &&
      patientPermanentAddress &&
      patientPermanentAddress.some((address) => areAddressesEquals(shippingAddress, address as Address)),
    [shippingAddress, patientPermanentAddress],
  )
  const isOrganizationAddress = useMemo(
    () => shippingAddress && organizationAddress && areAddressesEquals(shippingAddress, organizationAddress),
    [shippingAddress, organizationAddress],
  )
  const isCustomAddress = !isPatientAddress && !isOrganizationAddress && !!shippingAddress
  const [customAddress, setCustomAddress] = useState(isCustomAddress ? shippingAddress : undefined)
  const showAddCustomAddressButton = !customAddress && showAdd
  const postalAddressType = addressTypes.find(({ code }) => code === "postal")
  const addressFormInitialValue = isCustomAddress ? shippingAddress : { ...emptyAddress, type: postalAddressType }

  const handleAddressSelection = (address: Address) => {
    if (!areAddressesEquals(shippingAddress ?? {}, address)) {
      setFieldValue(fieldName, address)

      onChange?.(address)
    }
  }

  const showDialog = () => {
    setShowFormDialog(true)
  }

  const hideDialog = () => {
    setShowFormDialog(false)
  }

  const { checkAddress, clearVerificationInfo } = useSmartyAddressVerification()

  const handleSubmit = (address?: Address, formikHelpers?: FormikHelpers<FormikValues>) => {
    if (address) {
      const sanitizedAddress = sanitizeCustomAddress(address)

      checkAddress(sanitizedAddress, formikHelpers, () => {
        setCustomAddress(sanitizedAddress)
        handleAddressSelection(sanitizedAddress)

        clearVerificationInfo()
        hideDialog()
      })
    }
  }

  const renderAddressItem = (address: Address) => (
    <div className="font-semibold mx-4 w-full items-center">
      {isPoBoxAddress(address) && (
        <>
          <Tooltip
            target=".tooltiped"
            event="hover"
            position="left"
            content="It looks like you're trying to set up a P.O. Box address. Please note that P.O. Boxes may not be suitable for faster deliveries."
          />
          <span className="cursor-pointer tooltiped mr-4">
            <FontAwesomeIcon icon={faInfoCircle} className="text-orange-500" />
          </span>
        </>
      )}
      {getStringAddress(address)}
    </div>
  )

  return (
    <div className="relative flex flex-col pb-4 grow">
      <FormField field={fieldName} label="Shipping address" containerClassName="flex flex-col gap-2">
        {patientPermanentAddress &&
          patientPermanentAddress.map((patientAddress, index) => (
            <CardListItem
              className={classNames("cursor-pointer border border-gray-100 rounded-md hover:bg-gray-100", {
                "bg-slate-100 border-slate-400":
                  !!shippingAddress && areAddressesEquals(shippingAddress, patientAddress),
              })}
              lastElementClass=""
              contentHeader={renderAddressItem(patientAddress)}
              key={patientAddress.id ?? index}
              onContentCliked={() => {
                handleAddressSelection(patientAddress)
              }}
              disabled={disabled}
            />
          ))}
        {organizationAddress && (
          <CardListItem
            className={classNames("cursor-pointer border border-gray-100 rounded-md hover:bg-gray-100", {
              "bg-slate-100 border-slate-400": isOrganizationAddress,
            })}
            lastElementClass=""
            contentHeader={renderAddressItem(organizationAddress)}
            onContentCliked={() => {
              handleAddressSelection(organizationAddress)
            }}
            disabled={disabled}
          />
        )}
        {customAddress && (
          <CardListItem
            className={classNames("cursor-pointer border border-gray-100 rounded-md hover:bg-gray-100", {
              "bg-slate-100 border-slate-400": isCustomAddress,
            })}
            lastElementClass=""
            contentHeader={renderAddressItem(customAddress)}
            onContentCliked={() => {
              handleAddressSelection(customAddress)
            }}
            actionItem={{
              icon: <FontAwesomeIcon icon={faPencil} size="sm" />,
              command: () => {
                handleAddressSelection(customAddress)
                showDialog()
              },
            }}
            disabled={disabled}
          />
        )}
      </FormField>
      {showAddCustomAddressButton && (
        <AddFieldArrayItemButton
          className="border-none"
          label="Add custom address"
          disabled={disabled}
          onClick={showDialog}
        />
      )}
      <DialogFormContainer
        initialValue={addressFormInitialValue}
        title="Custom Address"
        showForm={showFormDialog}
        onCancel={() => {
          clearVerificationInfo()
          hideDialog()
        }}
        useFormik
        onSubmit={handleSubmit}
        validationSchema={getAddressSchema()}
      >
        <AddressFieldWithVerification parentFieldName={fieldName} />
      </DialogFormContainer>
    </div>
  )
}

const AddressFieldWithVerification: FC<AddressFieldWithVerificationProps> = ({ parentFieldName }) => {
  const { setFieldValue } = useFormikContext()
  const { addressVerificationInfo, autoCompleteRecommendedAddress } = useSmartyAddressVerificationContext()

  return (
    <div className="flex flex-col justify-between divide-y divide-gray-300">
      <div className="relative p-fluid grid gap-4">
        <fieldset className="relative p-fluid grid grid-cols-2 gap-4">
          <AddressField showTypeUseField={false} />
        </fieldset>
        <AddressVerificationFeedback
          addressVerificationInfo={addressVerificationInfo}
          handleAutoCompleteRecommendedAddress={() => autoCompleteRecommendedAddress?.(setFieldValue, parentFieldName)}
        />
      </div>
    </div>
  )
}

type AddressFieldWithVerificationProps = {
  parentFieldName?: string
}

type Props = {
  fieldName: string
  showAdd?: boolean
  disabled?: boolean
  onChange?(value: AnyObject): void
}

export { PrescriptionShippingAddressField }
