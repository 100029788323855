import { useQuery } from "@tanstack/react-query"
import { Account, AccountBETACreditCardArray, Patient, getFirstEmail, getResources, humanNameAsString } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { creditCardQueryKeys } from "../query-keys"
import { useDecryptAccount } from "./useDecryptAccount"

const useAccountCreditCards = (patientId: string) => {
  const { search } = useClient()
  const { decrypt } = useDecryptAccount()
  const queryKey = creditCardQueryKeys.withPatientId(patientId)

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "active",
        _include: "subject",
      })
      const bundle = await search({ endpoint: `Patient/${patientId}/Account`, filters, signal })
      const accounts = getResources<Account>(bundle, "Account")
      const patient = getResources<Patient>(bundle, "Patient")
      const patientName = humanNameAsString(patient?.[0]?.name?.[0])
      const patientEmail = getFirstEmail(patient?.[0]?.telecom)

      if (!accounts?.[0]?.id)
        throw new Error("Not found", {
          cause: {
            name: "404",
            message: `Not found an account linked to patient ${patientName} ${
              patient?.[0]?.telecom ? `with email ${patientEmail}` : ""
            }`,
          },
        })

      if (accounts.length > 1)
        throw new Error("Multiple accounts found", {
          cause: {
            name: "422",
            message: `Should be only one account linked to patient ${patientName} ${
              patient?.[0]?.telecom ? `with email ${patientEmail}` : ""
            }`,
          },
        })

      const decryptedAccount = await decrypt({ accountId: accounts[0].id ?? "" })

      return { account: decryptedAccount ?? accounts[0] }
    },
    meta: { context: { queryKey, patientId } },
  })

  const { creditCards, account, count, defaultCreditCard } = useMemo(() => {
    const account = data?.account

    const creditCards =
      account?.creditCard?.sort((a, b) =>
        b.expirationYear + String(b.expirationMonth).padStart(2, "0") <
        a.expirationYear + String(a.expirationMonth).padStart(2, "0")
          ? -1
          : 1,
      ) ?? []

    let defaultCreditCard: AccountBETACreditCardArray | undefined
    if (account?.defaultCreditCard) {
      const [defaultCcType, defaultCcLast4Digits] = (account?.defaultCreditCard as string).split("|")
      defaultCreditCard = creditCards.find((cc) => cc.type === defaultCcType && cc.last4Digits === defaultCcLast4Digits)
    }

    return {
      creditCards,
      account,
      count: creditCards.length,
      defaultCreditCard,
    }
  }, [data?.account])

  return {
    creditCards,
    accountId: account?.id,
    account,
    defaultCreditCard,
    isLoading,
    isFetching,
    count,
    total: creditCards?.length ?? 0,
  }
}

export { useAccountCreditCards }
