import { Bundle, getResources, ServiceRequest } from "fhir"
import { useQuery } from "@tanstack/react-query"

import { useClient } from "api"

import { medicationRequestsQueryKeys } from "../query-keys"

const useMrOrders = (patientId: string, medRequestIds?: string[]) => {
  const { transaction } = useClient()
  const queryKey = medicationRequestsQueryKeys.orders(patientId, medRequestIds)

  const { data, isLoading, isError, error } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        _category: "medication-order",
        ...(medRequestIds && { "based-on": medRequestIds.join(",") }),
        _count: "20",
        _sort: "-authored",
      })

      const bundleRequest: Bundle = {
        resourceType: "Bundle",
        type: "transaction",
        entry: [
          {
            request: {
              method: "GET",
              url: `Patient/${patientId}/ServiceRequest?${filters}`,
            },
          },
        ],
      }

      const bundle = (await transaction(bundleRequest)) as Bundle

      const serviceRequests = getResources<ServiceRequest>(bundle.entry?.[0]?.resource as Bundle)

      return {
        serviceRequests,
      }
    },

    enabled: medRequestIds !== undefined,
    meta: { context: { queryKey, patientId, medRequestIds } },
  })

  if (isError) {
    throw error
  }

  return {
    serviceRequests: data?.serviceRequests,
    isLoading,
  }
}

export { useMrOrders }
