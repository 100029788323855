import { DiagnosticReport, Money, PlanDefinition } from "fhir"
import { classNames } from "primereact/utils"
import { FC, ReactNode, useMemo, useRef } from "react"

import { getMoneyCurrencyAlt, sumPrice } from "utils"

import { LaboratoryOrderCombo, LaboratoryOrderPanel } from "../types"
import { getPanelDetails } from "../utils"
import { LabComboTitle } from "./LabComboTitle"
import { LaboratoryOrderPreviousResults } from "./LaboratoryOrderPreviousResults"
import { LabPanelDetails } from "./LabPanelDetails"

const LaboratoryOrderResults: FC<Props> = ({
  panels,
  combo,
  previousResults,
  isInsuranceLab,
  isOrgExemptLabPayment,
  orderId,
  header,
}) => {
  const posRef = useRef<HTMLSpanElement>()

  const scrollToPosRef = (name: string) => {
    posRef.current = window.document.getElementById(name) as HTMLSpanElement
    posRef.current.scrollIntoView(true)
  }

  const laboratoryPanelDetails = useMemo(() => getPanelDetails(panels ?? [], scrollToPosRef), [getPanelDetails, panels])
  const comboPanelDetails = useMemo(
    () => getPanelDetails(combo?.panels ?? [], scrollToPosRef),
    [getPanelDetails, combo?.panels],
  )

  const hasCombo = !!combo
  const panelsPrice = useMemo(() => {
    return panels?.reduce((acc, panel) => {
      const price = panel.price?.value ?? 0
      return { currency: panel.price?.currency ?? acc.currency, value: sumPrice(acc.value ?? 0, price).sum.toNumber() }
    }, {} as Money)
  }, [panels])

  if (!combo && !laboratoryPanelDetails?.panels?.length && !previousResults?.length) return null

  return (
    <div className="flex flex-col divide-y">
      {(!!combo || !!laboratoryPanelDetails?.panels?.length) && header}
      {!!combo && (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col">
            <div className="flex justify-between">
              <LabComboTitle
                combo={combo.planDefinition as PlanDefinition}
                logoClassName="w-6 h-6"
                titleClassName="text-primary font-semibold text-sm"
              />
              {!!combo.price && !isOrgExemptLabPayment && !isInsuranceLab && (
                <span className="text-gray-300 text-xs" title="Combo total">
                  {`${getMoneyCurrencyAlt(combo.price?.currency)}${combo.price?.value?.toFixed(2)}`}
                </span>
              )}
            </div>
            <span className="text-gray-300 text-xs">Combo</span>
          </div>
          <div className="flex flex-col gap-6">
            {comboPanelDetails?.panels?.map((panel, index) => (
              <LabPanelDetails key={panel.key?.coding?.[0].code ?? index} panel={panel} hidePrice />
            ))}
          </div>
        </div>
      )}
      {!!laboratoryPanelDetails?.panels?.length && (
        <div className={classNames("flex flex-col gap-4", { "pt-8 mt-6": hasCombo })}>
          <div className="flex flex-col">
            <div className="flex justify-between">
              <span className="text-primary font-semibold text-sm">{hasCombo ? "Additional Testing" : "Tests"}</span>
              {!isOrgExemptLabPayment && !isInsuranceLab && (
                <span className="text-gray-300 text-xs" title="Tests total">
                  {`${getMoneyCurrencyAlt(panelsPrice?.currency)}${(panelsPrice?.value ?? 0).toFixed(2)}`}
                </span>
              )}
            </div>
            {hasCombo && <span className="text-gray-300 text-xs">Tests that doesn't belong to combo</span>}
          </div>
          <div className="flex flex-col gap-6">
            {laboratoryPanelDetails?.panels?.map((panel, index) => (
              <LabPanelDetails
                key={panel.key?.coding?.[0].code ?? index}
                panel={panel}
                hidePrice={isOrgExemptLabPayment || isInsuranceLab}
              />
            ))}
          </div>
        </div>
      )}
      {!!previousResults?.length && (
        <LaboratoryOrderPreviousResults orderId={orderId} previousResults={previousResults} />
      )}
    </div>
  )
}

type Props = {
  panels?: LaboratoryOrderPanel[]
  combo?: LaboratoryOrderCombo
  previousResults?: DiagnosticReport[]
  isInsuranceLab?: boolean
  isOrgExemptLabPayment?: boolean
  orderId: string
  header?: ReactNode
}

export { LaboratoryOrderResults }
