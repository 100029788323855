import { faSend, faSpinner } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { InputTextarea } from "primereact/inputtextarea"
import { useState } from "react"
import { classNames } from "primereact/utils"

import "./ChatInput.css"

const ChatInput = ({ isLoading, className, sendMessage }: Props) => {
  const [currentMessage, setCurrentMessage] = useState<string | undefined>("")

  const handleChangeMessage = (mssg?: string) => {
    if (!mssg?.endsWith("\n") && !mssg?.endsWith("\r")) {
      setCurrentMessage(mssg)
    }
  }

  const handleSendMessage = () => {
    if (currentMessage) sendMessage(currentMessage.trim())
    setCurrentMessage("")
  }

  return (
    <div className={classNames("chat-input w-full", className)}>
      <div className="flex w-full">
        <InputTextarea
          className="w-full"
          value={currentMessage}
          rows={currentMessage && currentMessage?.length > 500 ? 3 : 1}
          cols={30}
          placeholder="Type your message here..."
          onInput={(e) => handleChangeMessage(e.currentTarget?.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
        />
        <span
          className="mx-2 my-1 text-gray-400 flex rounded-full w-10 h-10 items-center justify-center hover:bg-gray-400 hover:text-white cursor-pointer"
          role="button"
          onClick={handleSendMessage}
        >
          {isLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin size="lg" />
          ) : currentMessage ? (
            <FontAwesomeIcon icon={faSend} size="lg" />
          ) : (
            <FontAwesomeIcon icon={faSend} size="lg" />
          )}
        </span>
      </div>
    </div>
  )
}

type Props = {
  isLoading: boolean
  className?: string
  sendMessage(mssg: string): void
}

export { ChatInput }
