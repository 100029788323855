import { ModulesId, SubModule } from "commons/Module"

import { MedicationOrderDetails } from "../../../eprescribe/components/orders"

class PrescriptionOrderDetailsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.EPRESCRIBE_ORDER
    this.parent = ModulesId.EPRESCRIBE
    this.display = "Order Details"
    this.content = <MedicationOrderDetails />
  }
}

export { PrescriptionOrderDetailsSubModule }
