import { SelectButton } from "primereact/selectbutton"
import { FC, useReducer, useState } from "react"

import { AdvanceFilter, LABS_SECTION_TYPE } from "../../types"
import { LabsCombos } from "./combos/LabsCombos"
import { LabsFilters } from "./LabsFilters"
import { LabsTests } from "./panels/LabsTests"

const LabsContainer: FC = () => {
  const [activeTab, setActiveSection] = useState(LABS_SECTION_TYPE.LABS_COMBOS_SECTION)

  const { orgs, archived, performerLabs, searchText, hidden, search, filter, reset } = useReducerState()

  return (
    <div className="flex flex-col w-full h-full px-1.5 pt-1.5 overflow-hidden">
      <SelectButton
        value={activeTab}
        options={[
          { name: "Combos", value: LABS_SECTION_TYPE.LABS_COMBOS_SECTION },
          { name: "Tests", value: LABS_SECTION_TYPE.LABS_TESTS_SECTION },
        ]}
        optionValue="value"
        optionLabel="name"
        onChange={(e) => setActiveSection(e.value)}
        className="mb-5"
        allowEmpty={false}
      />
      <LabsFilters
        activeTab={activeTab}
        filters={{ orgs: orgs ?? [], archived, performerLabs: performerLabs ?? [], hidden }}
        searchText={searchText}
        onFilter={filter}
        onSearch={search}
        onReset={reset}
      />
      <div className="flex flex-col overflow-y-auto grow mt-6">
        {activeTab === LABS_SECTION_TYPE.LABS_COMBOS_SECTION ? (
          <LabsCombos filters={{ orgs, performerLabs, archived, searchText, hidden }} filter={filter} />
        ) : (
          <LabsTests filters={{ orgs, performerLabs, archived, searchText }} filter={filter} search={search} />
        )}
      </div>
    </div>
  )
}

const initialState = {
  searchText: "",
  orgs: [],
  performerLabs: [],
  archived: false,
  hidden: false,
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "reset" | "search" | "filter"
    payload?: string | AdvanceFilter
  },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "search":
      return { ...state, searchText: payload as string }
    case "filter":
      return (payload as AdvanceFilter)?.orgs !== undefined
        ? { ...state, orgs: (payload as AdvanceFilter)?.orgs }
        : (payload as AdvanceFilter)?.performerLabs !== undefined
          ? { ...state, performerLabs: (payload as AdvanceFilter)?.performerLabs }
          : (payload as AdvanceFilter)?.archived !== undefined
            ? { ...state, archived: (payload as AdvanceFilter)?.archived }
            : { ...state, hidden: (payload as AdvanceFilter)?.hidden }
    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ searchText, orgs, archived, performerLabs, hidden }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const search = (searchText: string) => {
    dispatch({ type: "search", payload: searchText })
  }

  const filter = (filters: AdvanceFilter) => {
    dispatch({ type: "filter", payload: filters })
  }

  return {
    searchText,
    orgs,
    performerLabs,
    archived,
    hidden,
    filter,
    search,
    reset,
  }
}

type State = {
  searchText?: string
} & AdvanceFilter

export { LabsContainer }
