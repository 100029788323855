import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormikHelpers } from "formik"
import isEqual from "lodash/isEqual"
import { FC } from "react"

import { MenuStyles, SkeletonLoader, StackedListContainer, StackedListItemProps, useCrudReducer } from "commons"
import { MedicationOrderDetailHeader, MedicationRequestData } from "commons/meds"
import { useOpenEncounter } from "encounter"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { useMrOrderContext } from "../../hooks"
import { MedicationRequestFormData } from "../../types"
import { getInitialValues, sanitize } from "../../utils"
import { PrescriptionFormContainer } from "../form"
import { prescriptionItemModel } from "../prescriptions/prescriptionItemModel"
import { MedicationOrderEditFooter } from "./MedicationOrderEditFooter"

const MedicationOrderEditConfig: FC = () => {
  const { patientId, patient } = usePatientContext()
  const { openEncounterRef } = useOpenEncounter(patientId)
  const { loggedInPractitionerRole, organizationPractitionersInfo } = useOrganizationContext()
  const {
    medicationRequestData,
    invoice,
    serviceRequest,
    medicationRequestDataWithPrice,
    isLoading,
    addEditedMedicationRequest,
    editedMedicationRequests,
  } = useMrOrderContext()

  const {
    initialValue,
    showSlide: showForm,
    reset,
    edit,
  } = useCrudReducer({
    defaultEntity: getInitialValues({
      patient,
      loggedInPractitionerRole: loggedInPractitionerRole,
      practitionersInfo: organizationPractitionersInfo,
      encounter: openEncounterRef,
    }),
  })

  const handleSubmit = (
    mrData: MedicationRequestFormData,
    formikHelpers?: FormikHelpers<MedicationRequestFormData>,
  ) => {
    const { medicationField: _, ...mrInitialValue } = initialValue

    if (!isEqual(mrInitialValue, sanitize({ mr: mrData }))) {
      addEditedMedicationRequest(sanitize({ mr: mrData, resetRefillDate: true }))
    }
    formikHelpers?.setSubmitting(false)
    reset()
  }

  const handleCancel = () => {
    reset()
  }

  return (
    <div className="flex flex-col h-full overflow-hidden">
      {isLoading ? (
        <SkeletonLoader loaderType="two-lines" repeats={3} extraLine />
      ) : (
        <div className="@container flex flex-col gap-4 overflow-y-hidden grow text-gray-600">
          <MedicationOrderDetailHeader serviceRequest={serviceRequest} invoices={invoice && [invoice]} hiddenActions />
          {showForm ? (
            <PrescriptionFormContainer
              initialValues={initialValue}
              isEditing
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              editAddressDisabled
            />
          ) : (
            <div className="grow min-h-0 flex flex-col p-2">
              <label className="font-semibold mb-2 pr-1 p-4 ">Medications:</label>
              {!medicationRequestData?.length ? (
                <div className="flex flex-col items-center justify-center w-full h-full">
                  <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
                  <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">No medications requested</p>
                </div>
              ) : (
                <div className="flex flex-col h-full grow min-h-0 space-y-4">
                  <div className="flex-1 pb-12 overflow-auto px-4">
                    <StackedListContainer
                      data={medicationRequestDataWithPrice}
                      keyGenerator={({ medicationRequestInfo }) => medicationRequestInfo.id}
                      withoutDivider
                      itemModelBuilder={(item) => {
                        const editedMedReqInfo = editedMedicationRequests.get(item.medicationRequestInfo.id ?? "")
                        const updatedData = editedMedReqInfo
                          ? {
                              ...item,
                              medicationRequestInfo: { ...item.medicationRequestInfo, ...editedMedReqInfo },
                            }
                          : item

                        return itemModel({
                          mrData: updatedData,
                          edit,
                          edited: !!editedMedReqInfo,
                        })
                      }}
                    />
                  </div>
                  <MedicationOrderEditFooter />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const itemModel = ({
  mrData,
  edited,
  edit,
}: {
  mrData: MedicationRequestData
  edited?: boolean
  edit?: (mrData: MedicationRequestFormData) => void
}): StackedListItemProps => ({
  ...prescriptionItemModel({
    mrData: { ...mrData, ...(edited ? { patientPrice: undefined, practicePrice: undefined } : {}) },
    edit,
    showStatusBadge: false,
    allowEdit: true,
  }),
  menuStyle: MenuStyles.ActionItems,
  itemClassName: "border rounded-xl px-4",
  ...(edited
    ? {
        badge: { text: "Edited", colorStyle: "yellow" },
      }
    : {}),
})

export { MedicationOrderEditConfig }
