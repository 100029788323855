import { useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { AnyObject } from "yup/lib/types"

import { AppSubModules } from "app-modules"
import { ModulesId, SubModule, SubModulesIndexTypes } from "commons/Module"

import { ModuleData, ModuleOrigin } from "../types"

const useModulesNavigation = (setActiveSubModule: (value: React.SetStateAction<SubModule | null>) => void) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentModuleData, setCurrentModuleData] = useState<ModuleData<AnyObject> | undefined>(undefined)
  const [currentModuleOrigin, setCurrentModuleOrigin] = useState<ModuleOrigin | undefined>(undefined)
  const savingModuleDataRef = useRef(false)

  const saveModuleOrigin = (keepOrigin?: boolean) => {
    if (!keepOrigin) {
      setCurrentModuleOrigin(undefined)
      return
    }
    const { view, subview, ...params } = Object.fromEntries(searchParams.entries())
    setCurrentModuleOrigin({
      view: view as ModulesId,
      ...(subview ? { subview: subview as ModulesId } : {}),
      searchParams: params,
    })
  }

  const saveModuleData = <T extends AnyObject>({
    moduleData,
    keepOrigin,
  }: {
    moduleData?: ModuleData<T>
    keepOrigin?: boolean
  }) => {
    setCurrentModuleData(moduleData)
    saveModuleOrigin(keepOrigin)

    if (moduleData || keepOrigin) savingModuleDataRef.current = true
  }

  const showModule = <T extends AnyObject>({
    module,
    moduleParams = {},
    moduleData,
    keepOrigin,
  }: {
    module: string
    moduleParams?: { [name: string]: string }
    moduleData?: ModuleData<T>
    keepOrigin?: boolean
  }) => {
    saveModuleData({ moduleData, keepOrigin })

    const params = new URLSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
      view: module,
    })

    let subModule: SubModule | null = null

    Object.entries(moduleParams).forEach(([name, value]) => {
      params.set(name, value)
      if (name === "subview" && value) {
        subModule = AppSubModules[module as SubModulesIndexTypes]?.[value] ?? null
      }
    })

    setActiveSubModule(subModule)
    setSearchParams(params)
  }

  const showSubModule = <T extends AnyObject>({
    subModule,
    subModulesData,
    moduleData,
    keepOrigin,
  }: {
    subModule: SubModule
    subModulesData?: string
    moduleData?: ModuleData<T>
    keepOrigin?: boolean
  }) => {
    saveModuleData({ moduleData, keepOrigin })

    const params = new URLSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
      view: subModule.getParent(),
      [subModule.getSearchParam()]: subModulesData ?? subModule.getId(),
    })

    if (subModule?.getId()) setActiveSubModule(subModule)

    setSearchParams(params)
  }

  const clearModuleData = () => {
    setCurrentModuleData(undefined)
    setCurrentModuleOrigin(undefined)
  }

  const navigateToOriginModule = () => {
    if (currentModuleOrigin) {
      const { view, subview, searchParams } = currentModuleOrigin
      showModule({
        module: view,
        moduleParams: {
          ...(subview ? { subview } : {}),
          ...(searchParams ?? {}),
        },
      })
    }
  }

  useEffect(() => {
    const { view, subview } = Object.fromEntries(searchParams.entries()) ?? {}
    const hasModuleData = currentModuleOrigin || currentModuleData
    const viewHasChanged = view !== currentModuleOrigin?.view || subview !== currentModuleOrigin?.subview
    if (hasModuleData && viewHasChanged && !savingModuleDataRef.current) {
      clearModuleData()
    }
    savingModuleDataRef.current = false
  }, [searchParams, currentModuleData, currentModuleOrigin])

  return { currentModuleData, currentModuleOrigin, showModule, showSubModule, clearModuleData, navigateToOriginModule }
}

export { useModulesNavigation }
