import { useQuery } from "@tanstack/react-query"
import { getResourcesGrouped, PlanDefinition } from "fhir"

import { useClient } from "api"

import { mcQueryKeys } from "../query-keys"

const useMCPlanDefinitions = (gender?: string) => {
  const { search } = useClient()
  const queryKey = mcQueryKeys.definitions

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        type: "mc",
        status: "active",
      })

      const bundle = await search({ endpoint: "PlanDefinition", filters, signal })
      const planDefinitions = getResourcesGrouped<PlanDefinition>(
        bundle,
        "PlanDefinition",
        (pd) => pd.useContext?.[0]?.value?.CodeableConcept?.coding?.[0]?.code as string,
      )

      return {
        planDefinitions: gender?.includes("male") ? planDefinitions[gender] : Object.values(planDefinitions).flat(),
      }
    },
    meta: { context: { queryKey } },
  })

  return {
    planDefinitions: data?.planDefinitions,
    isLoading,
  }
}

export { useMCPlanDefinitions }
