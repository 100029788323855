import { useMutation } from "@tanstack/react-query"
import { getResources, Identifier, Practitioner } from "fhir"
import { FormikHelpers } from "formik"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { SYSTEM_VALUES } from "system-values"

const useCheckNPIIdentifier = ({
  onSuccess,
  onSettled,
}: {
  onSuccess?: (args: {
    npiExists: boolean
    identifier?: Identifier
    formikHelpers: FormikHelpers<Partial<Identifier>>
  }) => void
  onSettled?: () => void
}) => {
  const { search } = useClient()

  const checkNPI = async ({
    identifier,
  }: {
    identifier?: Identifier
    formikHelpers: FormikHelpers<Partial<Identifier>>
  }) => {
    const filters = new URLSearchParams({
      identifier: identifier?.value ?? "",
    })

    const bundle = await search({ endpoint: "Practitioner", filters })
    const practitioner = getResources<Practitioner>(bundle, "Practitioner").filter((pat) =>
      pat.identifier?.some((id) => id.system === SYSTEM_VALUES.NPI),
    )
    return practitioner.length ? true : false
  }

  const {
    mutate: checkNPIExists,
    isPending: isLoading,
    data,
  } = useMutation({
    mutationFn: checkNPI,
    onError: (error: CustomError, npi) => {
      displayNotificationError(registerErrorTrace(error, npi))
    },
    onSuccess: (npiExists, { formikHelpers, identifier }) => {
      onSuccess?.({ npiExists, identifier, formikHelpers })
    },
    onSettled: onSettled,
  })

  return { checkNPIExists, isCheckingNPI: isLoading, pract: data, exists: data ?? false }
}

export { useCheckNPIIdentifier }
