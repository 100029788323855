import { useQuery } from "@tanstack/react-query"
import { getResources, PlanDefinition } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { convertIdentifiersToCodings, getBasePrice, getCidIdentifier, getCommonCode } from "utils"

import { useCIDQueryFunction } from "../../hooks"
import { LaboratoryTest } from "../../types"
import { plansQueryKeys } from "../query-keys"

const usePlanDefinitionTests = (organizationId: string, pdCanonicals?: string[]) => {
  const { search } = useClient()
  const queryKey = plansQueryKeys.testsDefinitions(pdCanonicals)

  const getChargeItemDefinitions = useCIDQueryFunction()

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        canonical: pdCanonicals?.join(",") ?? "",
      })

      const bundle = await search({ endpoint: "PlanDefinition", filters, signal })
      const planDefinitions = getResources<PlanDefinition>(bundle, "PlanDefinition")

      const codes = convertIdentifiersToCodings(planDefinitions)
      const { billToPracticeOrInsuranceCIDs: chargeItemDefinitions } = await getChargeItemDefinitions(organizationId, {
        billToPracticeOrInsuranceCIDs: codes,
      })

      return {
        planDefinitions,
        chargeItemDefinitions,
      }
    },
    enabled: !!pdCanonicals?.length,
    meta: { context: { queryKey, organizationId, pdCanonicals } },
  })

  const labTests = useMemo(() => {
    const result = data?.planDefinitions?.reduce((acc, pd) => {
      if (data?.chargeItemDefinitions) {
        const codes = convertIdentifiersToCodings(pd ? [pd] : [])
        const cid = data.chargeItemDefinitions[getCidIdentifier(getCommonCode({ codes }))]

        return [
          ...acc,
          {
            planDefinition: pd,
            price: getBasePrice(cid?.propertyGroup?.[0].priceComponent)?.amount,
            display: pd.title ?? pd.name ?? "Unknown",
          },
        ]
      }
      return acc
    }, new Array<LaboratoryTest>())

    return result
  }, [data])

  return {
    labTests,
    isLoading,
  }
}

export { usePlanDefinitionTests }
