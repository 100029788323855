const vitalsQueryKeys = {
  all: ["vitals"] as const,
  list: (patientId: string, encounter?: string) => [
    ...vitalsQueryKeys.all,
    patientId,
    ...(encounter ? [encounter] : []),
  ],
  knowledge: (patientId: string) => ["vitals-knowledge", patientId],
}

export { vitalsQueryKeys }
