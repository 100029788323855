import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, AppModule, ModuleRenderSideType } from "commons/Module"

class CalendarModule extends AppModule {
  constructor(workspaceElement: JSX.Element) {
    super()
    this.id = ModulesId.CALENDAR
    this.display = "Appointments"
    this.icon = faCalendarAlt
    this.hideBySetting = true
    this.workspaceElement = workspaceElement
    this.renderSideType = ModuleRenderSideType.WORKSPACE
  }
}

export { CalendarModule }
