import {
  ActivityDefinition,
  Dosage,
  MedicationKnowledge,
  MedicationRequestMedication,
  PlanDefinitionActionArrayActionArray,
} from "fhir"
import { useMemo } from "react"

import { getMedCommonCode } from "commons/meds"
import { getCommonCodeForAllSku } from "utils"

const useMedsConfigData = <T>(
  medAction: PlanDefinitionActionArrayActionArray,
  requiredMeds: string[],
  medicationRequestData: (T & {
    medicationKnowledge?: MedicationKnowledge
    medication?: MedicationRequestMedication
  })[],
  planActivityDefinitions?: Record<string, ActivityDefinition>,
) => {
  const configuredMedCodes = useMemo(
    () =>
      medicationRequestData.reduce(
        (acc, med) => {
          return {
            ...acc,
            [getMedCommonCode({ medication: med.medication, medicationKnowledge: med.medicationKnowledge })]: med,
          }
        },
        {} as Record<string, T & { medicationKnowledge?: MedicationKnowledge }>,
      ),

    [medicationRequestData],
  )
  const minToConfig =
    medAction.selectionBehavior && ["exactly-one", "one-or-more"].includes(medAction.selectionBehavior) ? 1 : 0

  const allRequiredConfigured = useMemo(() => {
    const configCodes = Object.keys(configuredMedCodes)
    if (!requiredMeds.length && !minToConfig) return true
    if (configCodes.length >= minToConfig && !requiredMeds.length) return true
    if (requiredMeds.length !== configCodes.length) return false
    if (configCodes.length < minToConfig) return false

    return requiredMeds.every((medCode) => configCodes.some((code) => medCode === code))
  }, [configuredMedCodes, requiredMeds, minToConfig])

  const medRecommendedDosage = medAction.action?.reduce(
    (acc, action) => {
      const code = [...(action.output?.flatMap(({ codeFilter }) => codeFilter?.[0]?.code ?? []) ?? [])]
      const sku = getCommonCodeForAllSku({ codes: code })
      const definitionAd = action.definition?.canonical
      const dosage = planActivityDefinitions?.[definitionAd as string]?.dosage
      // Add dosage for each sku
      const newDosage =
        dosage &&
        sku.reduce((acc, code) => {
          return { ...acc, [code]: dosage }
        }, {})

      return newDosage
        ? {
            ...acc,
            ...newDosage,
          }
        : { ...acc }
    },
    {} as Record<string, Dosage[]>,
  )
  const allowMultiple: boolean | number =
    !medAction.selectionBehavior ||
    ["any", "all", "one-or-more"].includes(medAction.selectionBehavior) ||
    (medAction.selectionBehavior === "at-most-one" && 1)

  return { allRequiredConfigured, medAction, medRecommendedDosage, configuredMedCodes, allowMultiple, minToConfig }
}

export { useMedsConfigData }
