const mcQueryKeys = {
  all: ["patient/mc-plan"] as const,
  list: (patientId: string, searchText?: string, statusFilter?: string[]) => [
    ...mcQueryKeys.all,
    patientId,
    searchText,
    statusFilter,
  ],
  details: (patientId: string, planId: string) => [...mcQueryKeys.all, patientId, planId],
  definitions: ["mcPD"],
}
export { mcQueryKeys }
