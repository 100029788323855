import { FC } from "react"

import { useOpenEncounter } from "encounter"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { usePatientContext } from "patients"
import { getPatientDefaultPractitioner } from "utils"

import { useDefaultOrgData } from "../../hooks"
import { CalculatorOutput, ProcedureData, ProcedureFormKind } from "../types"
import { getInitialValues } from "../utils/transformers"
import { ProcedureFormContainer } from "./form/ProcedureFormContainer"

const ProcedureCalculator: FC<Props> = ({
  procedurePdCanonical,
  carePlanId,
  generatedCalculatorResult,
  onCancel,
  hideCloseButton,
  onCurrentFormChanged,
}) => {
  const { isAdmin, currentOrganizationId, loggedInPractitionerRole } = useOrganizationContext()
  const { organizationPractitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })
  const {
    defaultData: { defaultProcedureIcd10 },
  } = useDefaultOrgData(currentOrganizationId)

  const { patientId, patient } = usePatientContext()

  const { openEncounterRef } = useOpenEncounter(patientId)

  const defaultRequester = getPatientDefaultPractitioner(
    organizationPractitionersInfo,
    patient,
    isAdmin ? {} : loggedInPractitionerRole,
  )

  return (
    <ProcedureFormContainer
      calcProcedure
      practitionersInfo={organizationPractitionersInfo}
      isEditing={false}
      carePlanId={carePlanId}
      onCancel={onCancel}
      defaultRequester={defaultRequester}
      initialValues={getInitialValues(
        patient,
        defaultProcedureIcd10[patient.gender ?? ""],
        defaultRequester,
        openEncounterRef,
        procedurePdCanonical,
        carePlanId,
      )}
      procedurePdCanonical={procedurePdCanonical}
      hideCloseIcon
      hideCloseButton={hideCloseButton}
      generatedCalcResult={generatedCalculatorResult}
      onCurrentFormChanged={onCurrentFormChanged}
    />
  )
}

type Props = {
  carePlanId: string
  procedurePdCanonical?: string
  questionnaireResponseId?: string
  generatedCalculatorResult?: CalculatorOutput
  hideCloseButton?: boolean
  onFinish(procedure: ProcedureData): void
  onCancel?(): void
  onCurrentFormChanged?(_: ProcedureFormKind): void
}

export { ProcedureCalculator }
