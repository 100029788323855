import { faExternalLink, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { classNames } from "primereact/utils"

const KpListItem = ({
  label,
  content,
  externalActionLabel,
  onExternalLinkClick,
  grayed = false,
  isLoading = false,
}: Props) => {
  return (
    <div className="py-4 px-5 grid xl:grid-cols-12 sm:gap-4 sm:py-5 items-center">
      <dt className="text-sm font-medium text-gray-500 xl:col-span-4 truncate">{label ?? ""}</dt>
      <div className="xl:col-span-8 mt-0 md:mt-1 flex justify-between w-full overflow-x-hidden space-x-1">
        <dd
          className={classNames("text-sm truncate", {
            "sm:col-start-5": !label,
            "text-gray-500": grayed,
            "text-gray-900": !grayed,
          })}
          title={typeof content === "string" ? content : undefined}
        >
          {content}
        </dd>
        {isLoading ? (
          <span className="text-sm text-right pr-1">
            <FontAwesomeIcon icon={faSpinner} className="text-gray-500" spin />
          </span>
        ) : (
          <>
            {externalActionLabel && (
              <span className="text-sm text-right pr-1">
                <FontAwesomeIcon
                  icon={faExternalLink}
                  className="text-slate-400 cursor-pointer"
                  title={externalActionLabel}
                  onClick={onExternalLinkClick}
                />
              </span>
            )}
          </>
        )}
      </div>
    </div>
  )
}

type Props = {
  content: string | JSX.Element
  label?: string
  externalActionLabel?: string
  onExternalLinkClick?(): void
  grayed?: boolean
  isLoading?: boolean
}

export { KpListItem }
