import { useQuery } from "@tanstack/react-query"
import { getResources, PlanDefinition, PlanDefinitionActionArrayActionArray } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { plansQueryKeys } from "../query-keys"
import { ComboDefinition, PanelDefinition, Plan, PLAN_ACTION_CODES, PLAN_TYPES } from "../types"
import { getActionCode, getActionMedCodes, getComboDefinition, getPDPerformer, isValidGenderContext } from "../utils"

const usePlanDefinitions = ({
  patientGender,
  orgPartOfId,
  organizationId,
  planId,
  enabled = true,
  billingType,
}: {
  planId?: string
  patientGender?: string
  organizationId?: string
  orgPartOfId?: string
  enabled?: boolean
  billingType?: string
}) => {
  const { search } = useClient()
  const queryKey = plansQueryKeys.definitions(patientGender, organizationId, planId, billingType)

  const { data, isLoading } = useQuery({
    queryKey,
    enabled: enabled,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        ...(planId ? { _id: planId } : {}),
        type: PLAN_TYPES.CLINICAL_PROTOCOL,
        status: "active",
        /* ...(patientGender ? { context: patientGender } : {}), */
        _include: "children-definition:PlanDefinition,definition:PlanDefinition",
      })

      const bundle = await search({ endpoint: "PlanDefinition", filters, signal })
      const bundlePDs = getResources<PlanDefinition>(bundle, "PlanDefinition").filter(
        (pd) =>
          (!pd.useContext?.some((context) => context.code.code === "organization") ||
            pd.useContext?.some(
              (context) => context.code.code === "organization" && context.value?.Reference?.id === orgPartOfId,
            )) &&
          isValidGenderContext(patientGender as string, pd.useContext),
      )

      return { planDefinitions: bundlePDs }
    },
    meta: { context: { queryKey } },
  })

  const { plans, medCodes } = useMemo(() => {
    const { canonicalPDs, planDefinitions } = data?.planDefinitions.reduce(
      (acc, pd) => {
        if ([PLAN_TYPES.CLINICAL_PROTOCOL].includes(pd.type?.coding?.[0]?.code as string))
          return { ...acc, planDefinitions: [...acc.planDefinitions, pd] }
        else return { ...acc, canonicalPDs: { ...acc.canonicalPDs, [`${pd.url}|${pd.version}`]: pd } }
      },
      { canonicalPDs: {}, planDefinitions: [] } as {
        canonicalPDs: Record<string, PlanDefinition>
        planDefinitions: PlanDefinition[]
      },
    ) ?? { canonicalPDs: {}, planDefinitions: [] }

    const catalogs = {}
    const organizations = {}
    const medCodes = Array<string>()

    const plans = planDefinitions.reduce<Array<Plan>>((acc, pd) => {
      const actions =
        pd.action?.reduce(
          (acc, action) => {
            const code = getActionCode(action.code?.[0])
            if (code) return { ...acc, [code]: action }
            else return { ...acc }
          },
          {} as Record<string, PlanDefinitionActionArrayActionArray>,
        ) ?? {}

      const actionLab: PlanDefinitionActionArrayActionArray | undefined = actions[PLAN_ACTION_CODES.CONFIGURE_LABS]
      const actionSendMails: PlanDefinitionActionArrayActionArray | undefined =
        actions[PLAN_ACTION_CODES.CONFIGURE_EMAILS]
      const daysBeforeToMail = actionSendMails?.dynamicValue?.find((v) => v.path === "daysBeforeAppointment")
        ?.expression?.expression

      const panels =
        actionLab?.action
          ?.find(
            (a) =>
              a.code?.some(({ coding }) => coding?.some(({ code }) => code === PLAN_ACTION_CODES.CONFIGURE_PANELS)) ||
              a.title === "Configure Panels",
          )
          ?.action?.reduce<Array<PanelDefinition>>((tests, pdRef) => {
            const panel = canonicalPDs[pdRef.definition?.canonical as string]

            return panel
              ? [...tests, { definition: panel, performer: getPDPerformer(panel, catalogs, organizations) }]
              : tests
          }, []) ?? []

      const combos =
        actionLab?.action
          ?.find(
            (a) =>
              a.code?.some(({ coding }) => coding?.some(({ code }) => code === PLAN_ACTION_CODES.CONFIGURE_COMBO)) ||
              a.title === "Configure Combo",
          )
          ?.action?.reduce<Array<ComboDefinition>>((combos, pdRef) => {
            const comboPanel = canonicalPDs[pdRef.definition?.canonical as string]

            return comboPanel
              ? [
                  ...combos,
                  getComboDefinition({
                    comboPanel,
                    panelDefinitions: canonicalPDs,
                    catalogs,
                    cids: {},
                    organizations,
                  }),
                ]
              : combos
          }, []) ?? []

      const rxAction = actions[PLAN_ACTION_CODES.CONFIGURE_RXS]
      const rxCodes = rxAction ? getActionMedCodes(rxAction)?.sku : undefined
      const nutraAction = actions[PLAN_ACTION_CODES.CONFIGURE_NUTRAS]
      const nutraCodes = nutraAction ? getActionMedCodes(nutraAction)?.sku : undefined

      medCodes.push(...(rxCodes ?? []), ...(nutraCodes ?? []))

      const newPlan: Plan = {
        definitionToken: `${pd.url}|${pd.version}`,
        definition: pd,
        panels,
        combos,
        daysBeforeToMail: daysBeforeToMail ? parseInt(daysBeforeToMail) : undefined,
        configureActions: { ...actions },
        rxCodes,
        nutraCodes,
      }

      return [...acc, newPlan]
    }, [])

    return {
      plans,
      medCodes,
    }
  }, [data])

  return {
    planDefinitions: plans,
    medCodes,
    isLoading,
  }
}

export { usePlanDefinitions }
