import { faFiles } from "@fortawesome/pro-regular-svg-icons"

import { SubModule, ModulesId } from "commons/Module"

import { PatientDocuments } from "../../../patients/components/PatientDocuments"

class DocumentsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.PATIENT_DOCUMENTS
    this.display = "Documents"
    this.parent = ModulesId.PATIENT
    this.icon = faFiles
    this.content = <PatientDocuments />
  }
}

export { DocumentsSubModule }
