import { faCapsules } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SelectButton } from "primereact/selectbutton"
import { startTransition, useEffect, useId, useMemo, useReducer } from "react"

import { InfiniteScroll, SearchWithStatus, SectionContainer, SkeletonLoader } from "commons"
import { useMrOrdersResources } from "commons/meds"
import { medicationRequestOrdersStatus } from "data"
import { useCPOEContext } from "orders"
import { usePatientContext } from "patients"

import { medicationViewOptions, MedicationViews } from "../data"
import { Contents } from "../types"
import { MedicationOrderHistoryList } from "./history/MedicationOrderHistoryList"
import { MedicationOrdersList } from "./orders/MedicationOrdersList"

const MedicationOrdersContainer = ({ activeContent, onUpdateContent }: Props) => {
  const { patientId } = usePatientContext()
  const cpoeCtx = useCPOEContext()
  const isHistoryView = useMemo(() => activeContent === MedicationViews.history, [activeContent])

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const { statusFilter, updateFilter, updateSearchFilter, searchFilter } = useReducerState(activeContent)

  const { mrOrderData, isLoading, hasNextPage, fetchNextPage, reloadOrders } = useMrOrdersResources(
    patientId,
    "nutraceutical-order",
    statusFilter,
    searchFilter,
  )

  const availableStatus = isHistoryView
    ? medicationRequestOrdersStatus.filter((s) => s.code !== "active")
    : medicationRequestOrdersStatus.filter((s) => s.code === "active")

  useEffect(() => {
    updateFilter(initialState(activeContent).statusFilter)
  }, [activeContent])

  useEffect(() => {
    if (statusFilter.includes("active")) {
      reloadOrders()
    }
  }, [cpoeCtx.ordersCount, statusFilter])

  return (
    <SectionContainer>
      <div className="inline-flex justify-between h-12 w-full my-3 px-3">
        <SelectButton
          value={activeContent}
          options={medicationViewOptions}
          optionLabel="name"
          optionValue="value"
          allowEmpty={false}
          onChange={(e) => {
            onUpdateContent(e.value as Contents)
          }}
        />
        <SearchWithStatus
          placeholder="Search orders"
          options={availableStatus}
          selectedItems={statusFilter}
          onStatusCheck={updateFilter}
          onSearch={(filter) => {
            startTransition(() => {
              updateSearchFilter(filter ?? "")
            })
          }}
        />
      </div>
      {isLoading ? (
        loader()
      ) : (
        <>
          {!mrOrderData?.length ? (
            <div className="flex flex-col items-center justify-center h-full">
              <FontAwesomeIcon icon={faCapsules} size="2x" className="text-slate-400" />
              <h3 className="mt-2 text-sm font-semibold text-gray-900">No Orders Found</h3>
            </div>
          ) : (
            <div className="bg-white flex flex-col overflow-auto">
              <InfiniteScroll
                loadMore={() => fetchNextPage()}
                hasMore={hasNextPage}
                initialLoad={false}
                loader={loader()}
              >
                {isHistoryView ? (
                  <MedicationOrdersList mrOrderData={mrOrderData} reloadOrders={reloadOrders} />
                ) : (
                  <MedicationOrderHistoryList mrOrderData={mrOrderData} />
                )}
              </InfiniteScroll>
            </div>
          )}
        </>
      )}
    </SectionContainer>
  )
}

const initialState = (activeContent: Contents) =>
  ({
    statusFilter: activeContent === "orders" ? ["active"] : ["revoked", "completed"],
    searchFilter: undefined,
  }) as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "update-status-filter" | "update-search-filter"
    payload?: string[] | string | boolean
  },
) => {
  switch (type) {
    case "update-status-filter":
      return {
        ...state,
        statusFilter: payload as string[],
      }
    case "update-search-filter":
      return {
        ...state,
        searchFilter: payload as string,
      }
    default:
      return state
  }
}

const useReducerState = (activeContent: Contents) => {
  const [{ statusFilter, searchFilter }, dispatch] = useReducer(reducer, initialState(activeContent))

  const updateFilter = (statusArray: string[]) => {
    dispatch({
      type: "update-status-filter",
      payload: statusArray.length > 0 ? statusArray : initialState(activeContent).statusFilter,
    })
  }

  const updateSearchFilter = (searchText: string) => {
    dispatch({ type: "update-search-filter", payload: searchText })
  }

  return {
    statusFilter,
    searchFilter,
    updateFilter,
    updateSearchFilter,
  }
}

type State = {
  statusFilter: Array<string>
  searchFilter: string | undefined
}

type Props = {
  activeContent: Contents
  onUpdateContent(activeContent: Contents): void
}

export { MedicationOrdersContainer }
