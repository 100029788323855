import { faPeopleRoof } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { FamilyHistoryContainer } from "../../../family-history/components/FamilyHistoryContainer"

class FamilyHistorySubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.FAMILY_HISTORY
    this.display = "Family History"
    this.parent = ModulesId.INTAKE
    this.icon = faPeopleRoof
    this.content = <FamilyHistoryContainer />
  }
}

export { FamilyHistorySubModule }
