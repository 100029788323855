import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Bundle, Coding, getResource, Reference } from "fhir"
import { Form, Formik, FormikHelpers } from "formik"
import { useMountEffect } from "primereact/hooks"
import { useEffect, useMemo, useState } from "react"

import { useChartContext } from "chart-view"
import { Button, PractitionerInfo, SkeletonLoader } from "commons"
import { CPOE_STEPS, useCPOEContext, useUpdateAllCpoeRequests } from "orders"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { ProcedureForms } from "../../data"
import {
  useCreateProcedure,
  useEvalCalculator,
  useProcedurePDAndQRFromPlan,
  useProcedurePlanDefinitions,
} from "../../hooks"
import {
  CalculatorOutput,
  PROCEDURE_CONFIG,
  ProcedureData,
  ProcedureFormKind,
  ProcedureFormTypes,
  ProcedureTypeData,
} from "../../types"
import { sanitizeProcedureData } from "../../utils/formatters"
import { Calculator } from "../calculator/Calculator"
import { SuggestedMeds } from "../calculator/SuggestedMeds"
import { MedConfiguration } from "./MedConfiguration"
import { ProcedureForm } from "./ProcedureForm"
import { ProcedureNotes } from "./ProcedureNotes"

const ProcedureFormContainer = ({
  initialValues,
  isEditing,
  calcProcedure,
  carePlanId,
  practitionersInfo,
  defaultRequester,
  procedurePdCanonical,
  hideCloseIcon,
  hideCloseButton,
  generatedCalcResult,
  onCancel,
  onCurrentFormChanged,
}: Props) => {
  const { patient, patientRef, patientId } = usePatientContext()
  const { loggedInPractitionerRoleRef, isStripeSetupComplete, hasRootStripeType, showStripeWarning } =
    useOrganizationContext()
  const chartContext = useChartContext()
  const cpoeCtx = useCPOEContext()

  const { procedureTypes, isLoading: isLoadingTypes } = useProcedurePlanDefinitions(
    patient.gender,
    procedurePdCanonical,
  )
  const procedureTypesValues = Object.values(procedureTypes)

  const { procedurePlanDefinition, procedureQR, isLoading } = useProcedurePDAndQRFromPlan(carePlanId)

  const planProcedureType = useMemo(
    () => procedureTypes?.[`${procedurePlanDefinition?.url}|${procedurePlanDefinition?.version}`],
    [procedurePlanDefinition, procedureTypes],
  )

  const [currentForm, setCurrentForm] = useState<ProcedureFormTypes>(
    generatedCalcResult
      ? ProcedureForms[ProcedureFormKind.suggestedMeds]
      : calcProcedure
        ? ProcedureForms[ProcedureFormKind.calculator]
        : ProcedureForms[ProcedureFormKind.main],
  )
  const showCloseButton = !hideCloseButton || currentForm?.type !== ProcedureFormKind.suggestedMeds

  const [calcResult, setCalcResult] = useState<CalculatorOutput | undefined>(generatedCalcResult)
  const [disableSave, setDisableSave] = useState(false)
  const [editMedIndex, setEditMedIndex] = useState<number>()

  const { evalCalculator } = useEvalCalculator()
  const { createProcedure } = useCreateProcedure(patientId, onCancel)

  useMountEffect(() => {
    chartContext.setSearchData({ showSearch: false })
  })

  const calculatorPDs = useMemo(
    () => procedureTypesValues.filter(({ isAlgorithm }) => isAlgorithm),
    [procedureTypesValues],
  )

  const { updateAllCpoeRequests, isUpdatingAllRequests } = useUpdateAllCpoeRequests(patientId, () =>
    cpoeCtx.showCpoeOverlay(CPOE_STEPS.FINISH),
  )

  const onSubmit = async (data: ProcedureData, helper: FormikHelpers<ProcedureData>) => {
    switch (currentForm.type) {
      case ProcedureFormKind.calculator:
        {
          const result = await evalCalculator({
            qrId: data.qrId as string,
            observations: data.calcData?.observations ?? [],
            patientRef,
            requester: loggedInPractitionerRoleRef,
            runAlgorithmCode: data.calcData?.runAlgorithmCode as Coding,
            icd10: data.calcData?.icd10,
          })
          helper.setSubmitting(false)
          setCalcResult(result)
          setCurrentForm(ProcedureForms[ProcedureFormKind.suggestedMeds])
        }
        break
      case ProcedureFormKind.suggestedMeds:
        setCurrentForm(ProcedureForms[ProcedureFormKind.medication])
        setEditMedIndex(0)
        break
      case ProcedureFormKind.main: {
        const performerPract = data.procedure.performer?.[0].actor?.id as string
        const performerPR = practitionersInfo.find((prInfo) => prInfo.practitioner.id === performerPract)
          ?.practitionerRoleRef as Reference

        const newProcedureTransactionBundle = (await createProcedure(
          sanitizeProcedureData(
            data,
            loggedInPractitionerRoleRef,
            defaultRequester ?? performerPR,
            performerPR,
            carePlanId as string,
          ),
        )) as Bundle
        const newProcedure = getResource(newProcedureTransactionBundle, "Procedure")

        if (carePlanId) {
          if (isStripeSetupComplete || hasRootStripeType) {
            updateAllCpoeRequests([newProcedure])
          } else showStripeWarning()
        }
        break
      }
      default:
        setCurrentForm(ProcedureForms[ProcedureFormKind.main])
    }
  }

  const handleCancel = () => {
    switch (currentForm.type) {
      case ProcedureFormKind.medication:
      case ProcedureFormKind.massage:
      case ProcedureFormKind.notes:
        setCurrentForm(ProcedureForms[ProcedureFormKind.main])
        break
      default:
        if (generatedCalcResult) {
          setCurrentForm(ProcedureForms[ProcedureFormKind.suggestedMeds])
          return
        }
        onCancel?.()
    }
  }

  const renderForm = (selectedProcedureType?: ProcedureTypeData) => {
    const isMassage =
      selectedProcedureType?.action?.[0]?.code?.[0]?.coding?.[0]?.code === PROCEDURE_CONFIG.BODY_SITE_COORDINATE
    switch (currentForm?.type) {
      case ProcedureFormKind.calculator:
        return (
          <Calculator
            procTypes={calculatorPDs}
            selectedProcedureType={selectedProcedureType}
            selectedQR={procedureQR}
            setDisableSave={setDisableSave}
          />
        )
      case ProcedureFormKind.suggestedMeds:
        return (
          <SuggestedMeds
            {...(calcResult as CalculatorOutput)}
            setDisableSave={setDisableSave}
            carePlanId={carePlanId as string}
          />
        )
      case ProcedureFormKind.medication:
      case ProcedureFormKind.massage:
        return (
          <MedConfiguration
            currentMedIndex={editMedIndex as number}
            setCurrentMedIndex={setEditMedIndex}
            configActions={selectedProcedureType?.action ?? []}
            configCodes={selectedProcedureType?.configCodes ?? []}
            isMassage={isMassage}
          />
        )
      case ProcedureFormKind.notes:
        return <ProcedureNotes />
      default:
        return (
          <ProcedureForm
            isEditing={isEditing}
            procedureTypes={procedureTypes}
            carePlanId={carePlanId}
            planProcedure={planProcedureType}
            onEditMed={(medIndex) => {
              setEditMedIndex(medIndex)
              isMassage
                ? setCurrentForm(ProcedureForms[ProcedureFormKind.massage])
                : setCurrentForm(ProcedureForms[ProcedureFormKind.medication])
            }}
            onEditNotes={() => setCurrentForm(ProcedureForms[ProcedureFormKind.notes])}
          />
        )
    }
  }

  useEffect(() => {
    onCurrentFormChanged?.(currentForm.type)
  }, [currentForm])

  if ((carePlanId && isLoading) || isLoadingTypes) return <SkeletonLoader loaderType="form-two-cols" repeats={1} />

  return (
    <Formik initialValues={initialValues} validationSchema={currentForm.validationSchema} onSubmit={onSubmit}>
      {({ values: { procedure }, isSubmitting }) => {
        const selectedProcedureType = procedureTypesValues.find(
          ({ canonical }) => canonical?.[0] === procedure.instantiatesCanonical?.[0],
        )
        return (
          <Form
            className="divide-gray-200 flex flex-col h-full grow divide-y @container"
            aria-autocomplete="none"
            autoComplete="off"
          >
            <div className="flex flex-1 flex-col overflow-hidden">
              <div className="flex justify-between px-4 py-3">
                <div className="flex items-baseline gap-2">
                  {!hideCloseIcon && (
                    <span onClick={handleCancel} className="cursor-pointer text-gray-900 h-6 w-6 text-center">
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </span>
                  )}
                  <h6 className="font-semibold leading-6">{currentForm.title}</h6>
                </div>
              </div>
              <div className="flex flex-1 flex-col overflow-y-auto px-4 space-y-6 pb-6">
                {renderForm(selectedProcedureType)}
              </div>
            </div>
            <div className="flex flex-shrink-0 justify-end gap-3 px-4 py-4">
              {showCloseButton && (
                <Button
                  label={currentForm.closeLabel ?? "Close"}
                  buttonStyle="default"
                  size="lg"
                  disabled={isSubmitting}
                  onClick={handleCancel}
                />
              )}
              <Button
                label={currentForm.saveLabel ?? "Save"}
                size="lg"
                loading={carePlanId ? isSubmitting || isUpdatingAllRequests : isSubmitting}
                disabled={
                  carePlanId ? isSubmitting || isUpdatingAllRequests || disableSave : isSubmitting || disableSave
                }
                type="submit"
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

type Props = {
  initialValues: ProcedureData
  practitionersInfo: PractitionerInfo[]
  defaultRequester?: Reference
  isEditing: boolean
  calcProcedure: boolean
  carePlanId?: string | null
  procedurePdCanonical?: string
  hideCloseIcon?: boolean
  hideCloseButton?: boolean
  generatedCalcResult?: CalculatorOutput
  onCancel?(): void
  onCurrentFormChanged?(_: ProcedureFormKind): void
}

export { ProcedureFormContainer }
