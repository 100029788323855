import { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { FormikValues } from "formik"
import { Chip } from "primereact/chip"
import { useId, useMemo, useState } from "react"

import { Accordion } from "../components/Accordion"
import { EmptyMessage } from "../components/EmptyMessage"
import { SearchWithFilters, SearchWithFiltersProps } from "../components/SearchWithFilters"
import { SkeletonLoader } from "../components/SkeletonLoader"
import { Slideover, SlideoverProps } from "../components/Slideover"
import { StackedListItemProps } from "../components/StackedListItem"
import { StackedListItemCheckeable } from "../components/StackedListItemCheckeable"
import { InfiniteScroll } from "../infinitescroll/InfiniteScroll.cjs"
import { SuggestionProps } from "../types"

const CheckMultipleWithSlideoverFilter = <T extends FormikValues>({
  isVisible,
  initialCheckedValues,
  initialCheckedIds,
  icon,
  queryProps: { data, isFetching, hasNextPage, fetchNextPage },
  filterProps,
  verifyExistInData,
  suggestionProps,
  title,
  emptyLabel,
  width = "35%",
  height = "calc(100% - 9.5rem)",
  position = "right",
  dismissable = false,
  childrenContainerClassName = "flex flex-col flex-1 overflow-hidden",
  cancelLabel = "Close",
  acceptLabel = "Accept",
  getItemId,
  getItemText,
  onHide,
  onSave,
  itemModel,
  evaluateExtraCheckedCondition,
  evaluateIsDisabled,
  maxSelectedItemsAllowed,
  ...slideProps
}: Props<T>) => {
  const loaderKey = useId()
  const [newCheckedItems, setNewCheckedItems] = useState<T[]>([])
  const [deletedItems, setDeletedItems] = useState<{ id: string; index: number }[]>([])

  const initialCheckedItems = useMemo(
    () =>
      initialCheckedValues?.length
        ? initialCheckedValues
        : data?.filter((item) => initialCheckedIds?.includes(getItemId?.(item) ?? item.id)) ?? [],
    [initialCheckedIds, initialCheckedValues, data, getItemId],
  )

  const chipItems = useMemo(
    () =>
      [...initialCheckedItems, ...newCheckedItems.reverse()].filter(
        (item) => !deletedItems.some((deletedItem) => (getItemId?.(item) ?? item.id) === deletedItem.id),
      ),
    [newCheckedItems, initialCheckedItems, deletedItems, getItemId],
  )
  const hasReachedMaximumSelectedValues = maxSelectedItemsAllowed === chipItems.length
  const hasSurpassedMaximumSelectedValues = maxSelectedItemsAllowed && maxSelectedItemsAllowed < chipItems.length

  const onCheckTriggered = ({ item, isChecked }: { item: T; isChecked: boolean }) => {
    const id: string = getItemId?.(item) ?? item.id
    const itemIndex = initialCheckedItems.findIndex((p) => (getItemId?.(p) ?? p.id) === id)

    if (isChecked) {
      if (itemIndex === -1) setNewCheckedItems([...newCheckedItems, item])

      if (verifyExistInData) {
        // Check if item is in initialCheckedItems before delete
        if (itemIndex !== -1) setDeletedItems(deletedItems.filter((p) => p.id !== id))
      } else {
        // Remove item from items billToPatientCIDs to delete
        setDeletedItems(deletedItems.filter((p) => p.id !== id))
      }
    } else {
      setNewCheckedItems((items) => items.filter((i) => (getItemId?.(i) ?? i.id) !== id))
      if (verifyExistInData) {
        // If items is in initialCheckedItems add it to delete
        if (itemIndex !== -1) setDeletedItems([...deletedItems, { id, index: itemIndex }])
      } else {
        // Update checked and unchecked items
        setDeletedItems([...deletedItems, { id, index: itemIndex }])
      }
    }
  }

  const renderSelectedItems = (
    <>
      {chipItems.length ? (
        <div className="flex flex-row flex-wrap space-x-2 h-full">
          {chipItems.reverse().map((item, index) => (
            <Chip
              key={getItemId?.(item) ?? item.id ?? index}
              label={getItemText?.(item) ?? item.display}
              className="text-xs m-2 text-center text-white bg-primary whitespace-nowrap max-h-[2rem]"
              removable={!evaluateIsDisabled?.(item, deletedItems)}
              onRemove={() => onCheckTriggered({ item, isChecked: false })}
            />
          ))}
        </div>
      ) : (
        <small className="text-slate-500 h-10 text-center font-semibold">{emptyLabel ?? "No items selected yet"}</small>
      )}
    </>
  )
  return (
    <Slideover
      {...{ width, height, position, dismissable, childrenContainerClassName, cancelLabel, acceptLabel }}
      {...slideProps}
      title={title}
      onHide={() => onHide?.()}
      onAccept={() => {
        onSave(newCheckedItems, deletedItems)
        onHide?.()
      }}
      showSlide={isVisible}
      showButtons
    >
      <div className="flex flex-col flex-1 divide-y divide-gray-200 overflow-hidden">
        <div className="px-6 pb-6 pt-1 flex flex-col space-y-5">
          {filterProps && <SearchWithFilters {...filterProps} />}
          {renderSelectedItems}
          {(hasReachedMaximumSelectedValues || hasSurpassedMaximumSelectedValues) && (
            <div className="text-yellow-600 text-sm font-semibold">
              Maximum allowed items {hasReachedMaximumSelectedValues ? "reached" : "surpassed delete some"} (
              {hasReachedMaximumSelectedValues
                ? maxSelectedItemsAllowed
                : `${chipItems.length} / ${maxSelectedItemsAllowed}`}
              )
            </div>
          )}
        </div>
        <div className="overflow-y-auto grow pb-3">
          {!!suggestionProps && (
            <div className="h-max divide-y divide-gray-200">
              {suggestionProps.isLoading ? (
                <SkeletonLoader repeats={suggestionProps.data?.length ?? 1} loaderType="one-line" />
              ) : (
                <Accordion
                  data={suggestionProps.data ?? []}
                  headerTemplate={({ category }) => <h3>{category}</h3>}
                  contentTemplate={({ items }) => (
                    <div className="flex flex-col">
                      {items.map((item, index) => {
                        const id: string = getItemId?.(item) ?? item.id
                        const isChecked = chipItems.some((item) => (getItemId?.(item) ?? item.id) === id)
                        const disabledByMaximumAllowed = hasReachedMaximumSelectedValues && !isChecked

                        return (
                          <StackedListItemCheckeable
                            key={id ?? index}
                            checked={isChecked || (evaluateExtraCheckedCondition?.(item, deletedItems) ?? false)}
                            modelData={itemModel(item)}
                            onCheck={(checked) => onCheckTriggered({ item, isChecked: checked })}
                            disabled={
                              isFetching ||
                              disabledByMaximumAllowed ||
                              (evaluateIsDisabled?.(item, deletedItems) ?? false)
                            }
                            modeAuto={false}
                          />
                        )
                      })}
                    </div>
                  )}
                ></Accordion>
              )}
            </div>
          )}
          {filterProps?.isLoading ? (
            <div className="divide-y divide-gray-200 px-5">
              <SkeletonLoader repeats={4} loaderType="two-lines" />
            </div>
          ) : data?.length ? (
            <InfiniteScroll
              hasMore={hasNextPage}
              loadMore={() => fetchNextPage?.()}
              loader={<SkeletonLoader repeats={4} loaderType="two-lines" containerClassName="px-5" key={loaderKey} />}
            >
              <div className="divide-y divide-gray-200 px-5">
                {!!suggestionProps?.data?.length && <h4 className="text-gray-900 mb-3">Search results</h4>}
                {data.map((item) => {
                  const id: string = getItemId?.(item) ?? item.id
                  const isChecked = chipItems.some((item) => (getItemId?.(item) ?? item.id) === id)
                  const disabledByMaximumAllowed = hasReachedMaximumSelectedValues && !isChecked

                  return (
                    <StackedListItemCheckeable
                      key={id}
                      checked={isChecked || (evaluateExtraCheckedCondition?.(item, deletedItems) ?? false)}
                      modelData={itemModel(item)}
                      onCheck={(checked) => onCheckTriggered({ item, isChecked: checked })}
                      disabled={
                        isFetching || disabledByMaximumAllowed || (evaluateIsDisabled?.(item, deletedItems) ?? false)
                      }
                      modeAuto={false}
                    />
                  )
                })}
              </div>
            </InfiniteScroll>
          ) : (
            <div className="flex h-full items-center my-5">
              <EmptyMessage icon={icon} message="No items found" subMessage="Plase type your search criteria" />
            </div>
          )}
        </div>
      </div>
    </Slideover>
  )
}

type Props<T> = {
  initialCheckedIds?: string[]
  initialCheckedValues?: Array<T>
  onSave(newCheckedItems: Array<T>, deletedItems: { id: string; index: number }[]): void
  itemModel(item: T): StackedListItemProps
  onHide?(): void
  getItemId?(item: T): string
  getItemText?(item: T): string
  icon: IconDefinition
  queryProps: {
    data?: Array<T>
    isFetching: boolean
    hasNextPage?: boolean
    fetchNextPage?(): void
  }
  emptyLabel?: string
  evaluateExtraCheckedCondition?(item: T, deletedItems: { id: string; index: number }[]): boolean
  evaluateIsDisabled?(item: T, deletedItems: { id: string; index: number }[]): boolean
  filterProps: SearchWithFiltersProps<FormikValues>
  isVisible: boolean
  verifyExistInData?: boolean
  suggestionProps?: SuggestionProps<T>
  title: string
  maxSelectedItemsAllowed?: number
} & Omit<SlideoverProps, "showSlide">

export { CheckMultipleWithSlideoverFilter }
