import { useQuery } from "@tanstack/react-query"
import { asReference, getResources, Patient } from "fhir"

import { useClient } from "api"
import { useOrganizationContext } from "organization"

import { patientsQueryKeys } from "../query-keys"

const usePatientsRefs = (filter?: string) => {
  const { search } = useClient()
  const { currentOrganizationId } = useOrganizationContext()
  const queryKey = patientsQueryKeys.refs(currentOrganizationId, filter)

  const { data, isLoading, isPending, isError, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _count: "100",
        _elements: "id,identifier,name",
        _sort: "name",
      })

      if (filter !== "") {
        const splittedFilter = filter?.split(" ")
        splittedFilter?.forEach((name) => {
          filters.append("name", name)
        })
      }

      const bundle = await search({ endpoint: `Organization/${currentOrganizationId}/Patient`, filters, signal })

      const patients = getResources(bundle) as Patient[]

      return patients.map((p) => asReference(p))
    },
    meta: { context: { queryKey, filter } },
    enabled: !!filter,
  })

  return { patientRefs: data, isLoading: isLoading || isPending, isError, refetch }
}

export { usePatientsRefs }
