import { useMutation } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { CustomError } from "commons"
import { registerErrorTrace } from "logger"
import { openLinkInNewTab, sanitizeURL } from "utils"

const useShowPatientDocument = (onSettled: () => void) => {
  const { getSignedUrl } = useClient()

  const getDocumentSignedUrl = async ({ url }: { url: string }) => {
    return getSignedUrl(sanitizeURL(url))
  }

  const { mutate: showDocument, isPending: isLoading } = useMutation({
    mutationFn: getDocumentSignedUrl,
    onSuccess: (signedUrl) => openLinkInNewTab(signedUrl.url),
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSettled,
  })

  return { showDocument, isLoading }
}

export { useShowPatientDocument }
