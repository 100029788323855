import { MedicationRequest } from "fhir"
import { FC, ReactNode, useCallback, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChargeItemDefinitions } from "commons"
import { useMedicationRequestDataBind, useMrOrderDetails } from "commons/meds"
import { BILLING_TYPES_CODES } from "data"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { getPriceByCode } from "utils"

import { MrOrderContext } from "../../../hooks/useMrOrderContext"
import { EDIT_ORDER_STEPS, InvoiceData } from "../../../types"

const MrOrderProvider: FC<Props> = ({ children }) => {
  const { patientId, patient } = usePatientContext()
  const { currentOrganizationId, currentOrganization } = useOrganizationContext()

  const [editedMedicationRequests, setEditedMedicationRequests] = useState(new Map<string, MedicationRequest>())
  const [currentStep, setCurrentStep] = useState<EDIT_ORDER_STEPS>(EDIT_ORDER_STEPS.CONFIG)
  const [invoiceData, setInvoiceData] = useState<InvoiceData>()

  const [params] = useSearchParams()
  const orderId = params.get("edit-order")

  const {
    serviceRequest,
    medicationKnowledges,
    medicationRequests,
    medCodes,
    medicationDispenses,
    invoice,
    isLoading,
    billingTypeCode,
    isEditable,
  } = useMrOrderDetails(patientId, orderId as string)
  const { chargeItemDefinitions } = useChargeItemDefinitions({
    organizationId: currentOrganizationId,
    codes: {
      ...(billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
        ? { billToPatientCIDs: medCodes }
        : { billToPracticeOrInsuranceCIDs: medCodes }),
    },
  })

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests,
    medicationKnowledges,
    medicationsCIDs:
      billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
        ? chargeItemDefinitions?.billToPatientCIDs ?? {}
        : chargeItemDefinitions?.billToPracticeOrInsuranceCIDs ?? {},
    medicationDispenses,
  })

  const medicationRequestDataWithPrice = medicationRequestData.map((item) => ({
    ...item,
    ...(billingTypeCode === BILLING_TYPES_CODES.BILL_PATIENT
      ? {
          patientPrice: getPriceByCode({
            chargeItemDefinitions: chargeItemDefinitions?.billToPatientCIDs ?? {},
            medCoding: item.medicationKnowledge?.code?.coding,
            factor: item.medicationRequestInfo?.dispenseRequest?.quantity?.value,
            includePatientFee: true,
          }),
        }
      : {
          practicePrice: getPriceByCode({
            chargeItemDefinitions: chargeItemDefinitions?.billToPracticeOrInsuranceCIDs ?? {},
            medCoding: item.medicationKnowledge?.code?.coding,
            factor: item.medicationRequestInfo?.dispenseRequest?.quantity?.value,
          }),
        }),
  }))

  const addEditedMedicationRequest = (mr: MedicationRequest) => {
    setEditedMedicationRequests((editedMRs) => {
      editedMRs.set(mr.id ?? "", mr)
      return editedMRs
    })
  }

  const moveStep = useCallback(
    (arg?: { previous: boolean; invoiceData?: InvoiceData }) => {
      switch (currentStep) {
        case EDIT_ORDER_STEPS.CONFIG:
          if (!arg?.previous) setCurrentStep(EDIT_ORDER_STEPS.PREVIEW)

          break
        case EDIT_ORDER_STEPS.PREVIEW:
          if (arg?.previous) setCurrentStep(EDIT_ORDER_STEPS.CONFIG)
          break
      }

      setInvoiceData(arg?.invoiceData)
    },
    [currentStep],
  )

  return (
    <MrOrderContext.Provider
      value={{
        serviceRequest,
        medicationRequestData,
        invoice,
        isLoading,
        editedMedicationRequests,
        addEditedMedicationRequest,
        currentStep,
        moveStep,
        medicationRequestDataWithPrice,
        invoiceData,
        patient,
        currentOrganization,
        isEditable,
      }}
    >
      {children(currentStep)}
    </MrOrderContext.Provider>
  )
}

type Props = {
  children(_: EDIT_ORDER_STEPS): ReactNode
}

export { MrOrderContext, MrOrderProvider }
