import { Coverage, codeableConceptAsString } from "fhir"
import { FC } from "react"

import { formatsByTypes } from "data"
import { parseISO } from "date-fns"
import { formatDate } from "utils"

import { getCoverageDisplay } from "../utils"

const CoverageDetail: FC<Props> = ({ coverage }) => (
  <div className="flex flex-col space-y-3 divide-y">
    <span title="Name" className="flex flex-1 space-x-3">
      <strong className="font-semibold mr-3">Coverage:</strong> {getCoverageDisplay(coverage)}
    </span>
    <span title="Status" className="flex flex-1 space-x-3">
      <strong className="font-semibold mr-3">Status:</strong> {coverage.status}
    </span>
    {coverage.subscriber?.display && (
      <span title="Subscriber" className="flex flex-1 space-x-3">
        <strong className="font-semibold mr-3">Subscriber:</strong> {coverage.subscriber.display}
      </span>
    )}
    {coverage.beneficiary?.display && (
      <span title="Beneficiary" className="flex flex-1 space-x-3">
        <strong className="font-semibold mr-3">Beneficiary:</strong>
        {coverage.beneficiary.display}
      </span>
    )}
    <span title="Relationship with Beneficiary" className="flex flex-1 space-x-3">
      <strong className="font-semibold mr-3">Relationship:</strong> {codeableConceptAsString(coverage.relationship)}
    </span>
    {coverage.period?.start && (
      <span title="Effective date" className="flex flex-1 space-x-3">
        <strong className="font-semibold mr-3">Effective:</strong>{" "}
        {formatDate(parseISO(coverage.period.start), formatsByTypes.LONG_DATE)}
      </span>
    )}
  </div>
)

type Props = {
  coverage: Coverage
}

export { CoverageDetail }
