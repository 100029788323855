import { SubModule, ModulesId } from "commons/Module"

import { PrescriptionsDraftContainer } from "../../../eprescribe/components/PrescriptionsDraftContainer"

class PrescriptionsDraftSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.EPRESCRIBE_DRAFT
    this.parent = ModulesId.EPRESCRIBE
    this.display = "Prescriptions Draft"
    this.content = <PrescriptionsDraftContainer />
  }
}

export { PrescriptionsDraftSubModule }
