import { Procedure } from "fhir"
import { FC } from "react"

import { FormContainer } from "commons"
import { ProcedureNotes } from "commons/procedures"

import { usePatchProcedure } from "../hooks"

const ProcedureNotesContainer: FC<Props> = ({ procedure, handleClose }) => {
  const { patchProcedure } = usePatchProcedure(handleClose)

  return (
    <FormContainer
      initialValue={{ procedure }}
      enableReinitialize
      showCloseIcon
      title="Notes"
      onSubmit={(data) => {
        patchProcedure(data.procedure)
      }}
      onCancel={handleClose}
    >
      <ProcedureNotes />
    </FormContainer>
  )
}

type Props = {
  procedure: Procedure
  handleClose(): void
}

export { ProcedureNotesContainer }
