import { faCartShopping } from "@fortawesome/pro-regular-svg-icons"
import { MedicationRequest, PractitionerRole, Reference } from "fhir"
import { classNames } from "primereact/utils"
import { FC, ReactNode, useState } from "react"

import { usePatientContext } from "patients"

import { Button } from "../../../../components/Buttons"
import { getMRInitialValues, sanitizeMR, useCreateMRs } from "../../../../meds"
import { PractitionerInfo } from "../../../../types"
import { Supplement } from "../../../types"
import { SupplementRecomendations } from "./SupplementRecomendations"

const RecommendationsContainer: FC<Props> = ({
  supplements,
  origanizationId,
  openEncounter,
  practitionerRole,
  practitionersInfo,
  defaultPerformer,
  className,
  header,
  triads,
  footer,
  onCreateSettled,
  recommendationClassName,
  recomendationContainerClassName,
}) => {
  const { patientId, patient } = usePatientContext()
  const [selectedSupplements, setSelectedSupplements] = useState<Supplement[]>([])

  const { createMRs, isCreating } = useCreateMRs(() => {
    onCreateSettled?.(selectedSupplements)
    setSelectedSupplements([])
  })

  const onCreateMRs = () => {
    const mrs = selectedSupplements.reduce<MedicationRequest[]>((acc, suplement) => {
      const mr = getMRInitialValues(
        suplement.mk,
        1,
        patient,
        practitionerRole,
        practitionersInfo,
        suplement.mr?.dispenseRequest?.performer ?? defaultPerformer,
        openEncounter,
      )
      mr.dosageInstruction = suplement.mr.dosageInstruction

      return [...acc, sanitizeMR(mr, true)]
    }, [])
    createMRs(mrs)
  }

  return (
    <div className={classNames("flex flex-col", className)}>
      {header}
      <SupplementRecomendations
        organizationId={origanizationId}
        patientId={patientId}
        supplements={supplements}
        selectedSupplements={selectedSupplements}
        handleSelectSupplement={setSelectedSupplements}
        triads={triads}
        itemClassName={recommendationClassName}
        containerClassName={recomendationContainerClassName}
      />
      {footer?.({ isCreating, selectedSupplements, onClick: onCreateMRs }) ?? (
        <div className="flex items-center justify-end">
          <Button
            buttonStyle="default"
            loading={isCreating}
            disabled={!selectedSupplements.length}
            type="button"
            label="Add to cart"
            icon={faCartShopping}
            onClick={onCreateMRs}
            title="When clicked all selected supplements will be prescribed"
          />
        </div>
      )}
    </div>
  )
}

type Props = {
  supplements: Supplement[]
  origanizationId: string
  openEncounter?: Reference
  practitionerRole: PractitionerRole
  practitionersInfo: PractitionerInfo[]
  defaultPerformer: Reference
  onCreateSettled?(selectedSupplements: Supplement[]): void
  className?: string
  header?: ReactNode
  footer?(props: { isCreating: boolean; selectedSupplements: Supplement[]; onClick: () => void }): JSX.Element
  triads?: Record<string, { img: string; borderColor: string; bgColor: string }>
  recommendationClassName?: string
  recomendationContainerClassName?: string
}

export { RecommendationsContainer }
