import { useMutation, useQueryClient } from "@tanstack/react-query"
import { MedicationRequest } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { medsQueryKeys } from "commons/meds"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { ordersQueryKeys } from "orders"
import { usePatientContext } from "patients"
import { displayConfirmNotification, displayNotificationSuccess } from "utils"

const useCreateMedicationRequest = ({
  hideForm,
  onSuccess,
  onError,
  queryKey = medsQueryKeys.all,
}: CreateMedicationRequestArgs) => {
  const { create } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const newMedicationReq = async (medicationReq: MedicationRequest) => create("MedicationRequest", medicationReq)

  const { mutate: createMedicationReq, isPending: isAdding } = useMutation({
    mutationFn: newMedicationReq,
    onError: (error: CustomError, context) => {
      onError?.()

      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: () => {
      if (onSuccess) displayConfirmNotification("Success", "Prescription renewed. Want to see it?", onSuccess)
      else displayNotificationSuccess("Prescription added successfully!")
      queryClient.refetchQueries({ queryKey: ordersQueryKeys.count.withPatientId(patientId), type: "all" })
      queryClient.invalidateQueries({ queryKey })
    },
    onSettled: hideForm,
  })

  return { createMedicationReq, isAdding }
}

interface CreateMedicationRequestArgs {
  hideForm: () => void
  onSuccess?: () => void
  onError?: () => void
  queryKey?: string[] | readonly string[]
}

export { useCreateMedicationRequest }
