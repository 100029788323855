import { SubModule, ModulesId } from "commons/Module"

import { MedicationRequestsRescheduleView } from "../../../medication-requests/components/MedicationRequestsRescheduleView"

class MRRescheduleSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.MR_RESCHEDULE
    this.parent = ModulesId.MEDICATIONR
    this.display = "Reschedule prescriptions"
    this.content = <MedicationRequestsRescheduleView />
  }
}

export { MRRescheduleSubModule }
