import { ChargeItemDefinition } from "fhir"
import { RadioButton } from "primereact/radiobutton"

import { SYSTEM_VALUES } from "system-values"

import { useCPOERequestsContext } from "../hooks"

const OrderDiscountListItem = ({ discount, selected, selectCid }: Props) => {
  const { isProcessingActions } = useCPOERequestsContext()
  const feeDisplay = discount.code?.coding?.find((coding) => coding.system === SYSTEM_VALUES.DISCOUNT)?.display

  return (
    <div className="w-full text-sm flex items-center my-4 px-4">
      <RadioButton
        inputId={`discount${discount.id}`}
        name="discount"
        value={discount}
        checked={selected}
        onChange={selectCid}
        disabled={isProcessingActions}
      />
      <label htmlFor={`discount${discount.id}`} className="flex w-full cursor-pointer">
        <div className="flex-1 flex flex-col space-y-1 text-sm">
          <div className="flex justify-between items-center w-full mx-2">
            <span title="Code" className="font-bold">
              {feeDisplay}
            </span>
          </div>
        </div>
      </label>
    </div>
  )
}

type Props = {
  discount: ChargeItemDefinition
  selected: boolean
  selectCid(): void
}

export { OrderDiscountListItem }
