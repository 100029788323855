import { faHeartPulse } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { VitalsContainer } from "../../../vitals/components/VitalsContainer"

class VitalsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.VITALS
    this.display = "Vitals"
    this.parent = ModulesId.INTAKE
    this.icon = faHeartPulse
    this.content = <VitalsContainer />
  }
}

export { VitalsSubModule }
