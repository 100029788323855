import { Calendar } from "primereact/calendar"
import { Dialog } from "primereact/dialog"
import { FC } from "react"

import { Button } from "../../components/Buttons"

const MedicationRescheduleDialog: FC<Props> = ({
  visible,
  rescheduleDate,
  updateRescheduleDate,
  onHide,
  onReschedule,
}) => {
  return (
    <Dialog
      closable={true}
      header="Reschedule order"
      visible={visible}
      draggable={false}
      dismissableMask={true}
      style={{ width: "35vw" }}
      onHide={onHide}
      footer={
        <div className="mt-2">
          <Button label="Cancel" className="button-default" onClick={onHide} />
          <Button label="Reschedule" className="button-primary" loading={false} onClick={onReschedule} />
        </div>
      }
    >
      <label>New date</label>
      <Calendar
        className="w-full mt-2"
        showIcon
        value={rescheduleDate}
        minDate={new Date()}
        dateFormat={"M d, yy"}
        onChange={(e) => updateRescheduleDate(e.target.value as Date)}
      />
    </Dialog>
  )
}

type Props = {
  visible: boolean
  onHide(): void
  onReschedule(): void
  rescheduleDate?: Date
  updateRescheduleDate(date: Date): void
}

export { MedicationRescheduleDialog }
