const eprescribeQueryKeys = {
  all: ["eprescribe/medication-requests"] as const,
  detectedIssue: (filter = "") => ["eprescribe/detectedIssue", filter],
  draft: {
    all: ["eprescribe/draft-medication-requests"] as const,
    list: (patientId: string) => [...eprescribeQueryKeys.draft.all, patientId],
  },
  medicationKnowledge: (catalogId: string | undefined, filter = "", medCodes = "") => [
    "eprescribe/medication-knowledge",
    catalogId,
    filter,
    medCodes,
  ],
  orderDetails: {
    all: ["eprescribe/medication-order-details"] as const,
    details: (orderId: string) => [...eprescribeQueryKeys.orderDetails.all, orderId],
  },
  ordersResources: (patientId: string, statusFilter?: string[], searchText?: string) => [
    "eprescribe/patient-medication-orders-resources",
    patientId,
    statusFilter,
    searchText,
  ],
  monograph: (monoraphID: string | undefined) => ["eprescribe/monograph", monoraphID],
  sigSuggestions: (input: string) => ["eprescribe/sig-suggestions", input],
}

export { eprescribeQueryKeys }
