import { useMutation } from "@tanstack/react-query"
import { Bundle, getResources, MedicationRequest } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { getRenewedMR } from "../components/validations"

const useStopAndRenewMedicationRequest = (
  refreshMedications: (meds: MedicationRequest[]) => void,
  onSettled?: () => void,
) => {
  const { operationRequest, create } = useClient()

  const newMedicationReq = async (medicationReq: MedicationRequest) => create("MedicationRequest", medicationReq)

  const stopAndRenewMedicationReq = async (medicationRequestId: string) =>
    operationRequest<Bundle>({
      endpoint: "MedicationRequest",
      method: "POST",
      operation: "stop",
      id: medicationRequestId,
    }).then((mrBundle) => {
      const med = getResources<MedicationRequest>(mrBundle)

      return newMedicationReq(getRenewedMR(med[0]))
    })

  const { mutate: stopAndRenewMedicationRequest, isPending } = useMutation({
    mutationFn: stopAndRenewMedicationReq,
    onError: (error: CustomError, medicationRequestId) => {
      displayNotificationError(registerErrorTrace(error, { medicationRequestId }))
    },
    onSuccess: (mr) => {
      displayNotificationSuccess("Prescription stopped and renewed successfully!")
      // datadogLogs.logger.info(`Prescription ${mr.id} stopped and renewed successfully!`, { mr })
      refreshMedications([mr])
    },
    onSettled,
  })

  return { stopAndRenewMedicationRequest, isLoading: isPending }
}

export { useStopAndRenewMedicationRequest }
