import { faCubes, faHouseBuilding, faHouseMedical } from "@fortawesome/pro-regular-svg-icons"
import { faBarcode, faEdit } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Reference } from "fhir"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { FC, useId, useMemo, useReducer } from "react"

import { Badge, DropdownMenu, InfiniteScroll, SkeletonLoader } from "commons"
import { useOrganizationContext } from "organization"
import { getBadgeColor, sumPrice } from "utils"

import { useLabPanels } from "../../../hooks"
import { AdvanceFilter, LabPanel, PDData } from "../../../types"
import { mapPdUseContext } from "../utils"
import { LabPanelFormContainer } from "./LabPanelFormContainer"

const LabsTests: FC<Props> = ({ filters, filter, search }) => {
  const { currentOrganizationId } = useOrganizationContext()
  const { labPanels, isLoadingLabPanels, hasNextPage, fetchNextPage } = useLabPanels(currentOrganizationId, filters)

  const { edit, showForm, reset, panel } = useReducerState()

  const labTestsData = useMemo(
    () =>
      labPanels.map((labTest) => {
        const status = labTest.planDefinition.status
        const performerLab = labTest.performerLab
        const { num1: price, num2: fee, sum: total } = sumPrice(labTest.price, labTest.fee)
        const sku = labTest.sku
        const { organization, practice, practiceId, rootId } = mapPdUseContext(labTest.planDefinition.useContext)

        return {
          testData: { display: labTest.display, organization, practice, sku },
          price: price.toNumber(),
          fee: fee.toNumber(),
          total: total.toFixed(2),
          performerLab,
          status,
          externalAction: [
            ...(practiceId === currentOrganizationId || rootId === currentOrganizationId || (!rootId && !practiceId)
              ? [
                  {
                    label: "Edit",
                    icon: <FontAwesomeIcon icon={faEdit} size="sm" />,
                    command: () => edit(labTest),
                  },
                ]
              : []),
          ],
        }
      }),
    [labPanels],
  )

  const testDetailsBodyTemplate = ({ testData: { display, organization, practice, sku } }: { testData: PDData }) => {
    const header = (
      <p
        title="Test name"
        className="mr-2 overflow-hidden text-ellipsis line-clamp-1 whitespace-normal break-words font-medium max-w-sm lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl 3xl:max-w-full"
      >
        {display}
      </p>
    )

    const details = (
      <>
        {organization ? (
          <span title="Organization root" className="flex items-center">
            <FontAwesomeIcon icon={faHouseBuilding} className="mr-1.5 text-gray-400" />
            {organization}
          </span>
        ) : (
          <span title="No organization root" className="flex items-center">
            <FontAwesomeIcon icon={faCubes} className="mr-1.5 text-gray-400" />
            <span>General Test</span>
          </span>
        )}
        {practice && (
          <span title="Practice" className="flex items-center">
            <FontAwesomeIcon icon={faHouseMedical} className="mr-1.5 ml-1.5 text-gray-400" />
            {practice}
          </span>
        )}
        <span
          title="Sku code"
          className="flex items-center cursor-pointer"
          onClick={() => {
            search(sku)
          }}
        >
          <FontAwesomeIcon icon={faBarcode} className="mr-1.5 ml-1.5 text-gray-400" />
          {sku}
        </span>
      </>
    )

    return (
      <span className="block">
        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex items-center w-full">
            <div className="flex">
              <div className="truncate">
                <div className="flex text-sm">{header}</div>
                <div className="mt-2 flex">
                  <div className="flex items-center text-xs text-gray-400 divide-x divide-gray-400 gap-2">
                    {details}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }

  const statusBodyTemplate = ({ status }: { status: string }) => {
    return <Badge className="@md:inline-flex" {...getBadgeColor(status)} />
  }

  const laboratoryBodyTemplate = ({ performerLab }: { performerLab: Reference }) => {
    const display = performerLab.display ?? "laboratory"
    const id = performerLab.id ?? ""

    return (
      <div className="cursor-pointer" onClick={() => filter({ performerLabs: [id] })}>
        {display}
      </div>
    )
  }

  const externalActionBodyTemplate = ({
    externalAction,
  }: {
    externalAction: { title: string; icon: JSX.Element; command: () => void }[]
  }) => {
    return (
      <div className="flex items-center justify-between">
        {externalAction.length > 1 ? (
          <DropdownMenu dropdownMenuItems={externalAction} />
        ) : (
          <button
            key={1}
            title={externalAction?.[0]?.title}
            onClick={externalAction?.[0]?.command}
            className="flex items-center justify-center p-1 text-gray-400 rounded-md cursor-pointer"
          >
            {externalAction?.[0]?.icon}
          </button>
        )}
      </div>
    )
  }

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <>
      <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
        <DataTable value={labTestsData} responsiveLayout="scroll" loading={isLoadingLabPanels}>
          <Column field="testData" body={testDetailsBodyTemplate} rowSpan={6} headerClassName="bg-transparent" />
          <Column
            field="price"
            header="Price"
            bodyClassName="text-gray-400"
            headerClassName="bg-transparent text-gray-400 font-semibold"
          />
          <Column
            field="fee"
            header="Fee"
            bodyClassName="text-gray-400"
            headerClassName="bg-transparent text-gray-400 font-semibold"
          />
          <Column
            field="total"
            header="Total"
            bodyClassName="text-gray-400"
            headerClassName="bg-transparent text-gray-400 font-semibold"
          />
          <Column
            field="performerLab"
            header="Laboratory"
            bodyClassName="text-gray-400 underline cursor-pointer"
            headerClassName="bg-transparent text-gray-400 font-semibold"
            body={laboratoryBodyTemplate}
          />

          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            headerClassName="bg-transparent text-gray-400 font-semibold"
          />
          <Column field="external_action" headerClassName="bg-transparent" body={externalActionBodyTemplate} />
        </DataTable>
      </InfiniteScroll>
      {showForm && <LabPanelFormContainer labPanel={panel} onHide={() => reset()} />}
    </>
  )
}

const initialState = {
  showForm: false,
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "reset" | "edit"
    payload?: LabPanel | string | undefined
  },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }
    case "edit":
      return { ...state, showForm: true, panel: payload as LabPanel }
    default:
      return state
  }
}

const useReducerState = () => {
  const state = initialState
  const [{ showForm, panel }, dispatch] = useReducer(reducer, state)

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const edit = (payload: LabPanel) => {
    dispatch({ type: "edit", payload })
  }

  return {
    showForm,
    panel,
    reset,
    edit,
  }
}

type State = {
  panel?: LabPanel
  showForm: boolean
  isClone: boolean
}

type Props = {
  filters: AdvanceFilter & { searchText?: string }
  filter(filters: AdvanceFilter): void
  search(searchText: string): void
}

export { LabsTests }
