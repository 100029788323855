import { add } from "date-fns/add"
import {
  Dosage,
  Duration,
  MedicationRequestDispenseRequest,
  Quantity,
  asReference,
  codeableConceptAsString,
} from "fhir"

import { MedicationRequestInfo } from "commons/meds"
import { unitOfTime } from "data"
import { unitToDays } from "utils"

const DEFAULT_TREATMENT_DURATION = {
  value: 1,
  system: unitOfTime[4].system,
  code: unitOfTime[4].code,
  unit: unitOfTime[4].display.toLowerCase(),
}

const getDraftValues = (medicationRequest: MedicationRequestInfo) => {
  medicationRequest.treatmentFrequency = "oncePerDay"
  if (
    medicationRequest.dosageInstruction?.[0].timing?.repeat?.period === 1 &&
    medicationRequest.dosageInstruction?.[0].timing?.repeat?.periodUnit === "d"
  ) {
    switch (medicationRequest.dosageInstruction?.[0].timing?.repeat.frequency) {
      case 2:
        medicationRequest.treatmentFrequency = "twicePerDay"
        break
      case 3:
        medicationRequest.treatmentFrequency = "threePerDay"
        break
      case 4:
        medicationRequest.treatmentFrequency = "fourPerDay"
        break
    }
    switch (medicationRequest.dosageInstruction?.[0].timing?.repeat.when?.[0]) {
      case "MORN":
        medicationRequest.treatmentFrequency = "everyMorning"
        break
      case "NIGHT":
        medicationRequest.treatmentFrequency = "everyNight"
        break
    }
  }

  if (medicationRequest.dosageInstruction?.[0].doseAndRate?.[0]?.dose?.Range)
    medicationRequest.doseQuantity = `${medicationRequest.dosageInstruction?.[0].doseAndRate?.[0].dose?.Range.low?.value}-${medicationRequest.dosageInstruction?.[0].doseAndRate?.[0].dose?.Range.high?.value}`
  else
    medicationRequest.doseQuantity = `${
      medicationRequest.dosageInstruction?.[0].doseAndRate?.[0]?.dose?.Quantity?.value ?? 1
    }`

  const mkInstructions = medicationRequest.dosageInstruction?.[0].additionalInstruction?.map((instruction) =>
    codeableConceptAsString(instruction),
  )
  if (!medicationRequest.note?.[0].text) {
    medicationRequest.note = [{ text: mkInstructions?.join(", ") ?? "" }]
  }
  if (!medicationRequest.dosageInstruction?.[0]?.text) {
    medicationRequest.dosageInstruction = [{ text: mkInstructions?.join(", ") }]
  }

  return medicationRequest
}

const sanitizeDispenseRequest = (mrDispenseRequest: MedicationRequestDispenseRequest) => {
  const quantity = mrDispenseRequest?.initialFill?.quantity as Quantity
  const interval = mrDispenseRequest.dispenseInterval?.value
  const repeats = mrDispenseRequest?.numberOfRepeatsAllowed

  const duration = {
    ...mrDispenseRequest.dispenseInterval,
    value: Math.max(interval!, 1) * (repeats ?? 1),
  } as Duration

  mrDispenseRequest = {
    ...mrDispenseRequest,
    initialFill: {
      quantity: mrDispenseRequest.quantity,
      duration: duration,
    },
    quantity,
    ...(repeats !== undefined ? { numberOfRepeatsAllowed: repeats } : undefined),
    expectedSupplyDuration: duration,
    validityPeriod: {
      start: mrDispenseRequest?.validityPeriod?.start ?? new Date().toISOString(),
      ...(!interval
        ? {
            end: add(
              mrDispenseRequest?.validityPeriod?.start ? new Date(mrDispenseRequest.validityPeriod.start) : new Date(),
              { [`${duration.unit ?? "second"}s`]: duration.value! },
            ).toISOString(),
          }
        : undefined),
    },
  }
  return mrDispenseRequest
}

const getRecommendedUnitsForTreatment = (
  unitsByRecipient: number,
  orientedDosage?: Dosage,
  treatmentDuration?: Duration,
) => {
  const doseRangeMax = orientedDosage?.doseAndRate?.[0]?.dose?.Range?.high?.value
  const doseQuantity = orientedDosage?.doseAndRate?.[0]?.dose?.Quantity?.value
  const dosageRepeats = orientedDosage?.timing?.repeat?.frequencyMax ?? orientedDosage?.timing?.repeat?.frequency ?? 1
  const dosagePeriod = orientedDosage?.timing?.repeat?.periodMax ?? orientedDosage?.timing?.repeat?.period ?? 1
  const dosagePeriodUnit = orientedDosage?.timing?.repeat?.periodUnit ?? "d"

  const units =
    (doseRangeMax ?? doseQuantity ?? 1) *
    dosageRepeats *
    (dosagePeriod / unitToDays(dosagePeriodUnit)) *
    (treatmentDuration?.value ?? DEFAULT_TREATMENT_DURATION.value) *
    unitToDays(treatmentDuration?.code ?? DEFAULT_TREATMENT_DURATION.code)

  return Math.ceil(units / unitsByRecipient)
}

const getRenewedMR = ({ ...medicationRequest }: MedicationRequestInfo) => {
  medicationRequest.priorPrescription = asReference(medicationRequest)
  const newDate = new Date().toISOString()
  medicationRequest.authoredOn = newDate
  medicationRequest.status = "draft"

  const duration = medicationRequest.dispenseRequest?.initialFill?.duration
  const interval = medicationRequest.dispenseRequest?.dispenseInterval?.value

  medicationRequest.dispenseRequest = {
    ...medicationRequest.dispenseRequest,
    nextRefillDate: newDate,
    validityPeriod: {
      start: newDate,
      ...(!interval
        ? {
            end: add(new Date(newDate), {
              [`${duration?.unit ?? "second"}s`]:
                (duration?.value ?? 0) * ((medicationRequest.dispenseRequest?.numberOfRepeatsAllowed ?? 0) + 1),
            }).toISOString(),
          }
        : {}),
    },
  }

  delete medicationRequest.id
  delete medicationRequest.unitsByRecipient
  delete medicationRequest.medicationUnit
  delete medicationRequest.treatmentFrequency
  delete medicationRequest.doseQuantity

  return medicationRequest
}

export { getDraftValues, getRecommendedUnitsForTreatment, getRenewedMR, sanitizeDispenseRequest }
