import { useContext } from "react"

import { OrganizationContext } from "../context/OrganizationProvider"

const useOrganizationContext = () => {
  const context = useContext(OrganizationContext)

  if (context === undefined) {
    throw new Error("useOrganizationContext must be used within a OrganizationProvider")
  }

  return context
}

export { useOrganizationContext }
