import { useQuery } from "@tanstack/react-query"

import { useClient } from "api"
import {
  Appointment,
  isAppointment,
  isLocation,
  isPatient,
  isPractitioner,
  Location,
  Patient,
  Practitioner,
} from "fhir"

import { useLoginContext } from "security"

import { practitionerAptsQueryKeys } from "../query-keys"
import { CalendarAppointment } from "../types"

const usePractitionerAppointments = () => {
  const { search } = useClient()
  const { loggedInPractitionerId } = useLoginContext()
  const queryKey = practitionerAptsQueryKeys.all(loggedInPractitionerId)

  const {
    isSuccess,
    data: appointments,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "booked",
        _include: "actor",
        practitioner: loggedInPractitionerId,
        _sort: "-date",
      })

      const { entry } = await search({ endpoint: "Appointment", filters, signal })

      const { appointments, practitioners, patients, locations } =
        entry?.reduce<{
          appointments: Appointment[]
          practitioners: Record<string, Practitioner>
          patients: Record<string, Patient>
          locations: Record<string, Location>
        }>(
          (prev, { resource }) => {
            if (isAppointment(resource) && resource.id) {
              return {
                ...prev,
                appointments: [
                  ...prev.appointments,
                  { ...resource, start: resource.start && new Date(resource.start) },
                ],
              }
            }

            if (isPractitioner(resource) && resource.id) {
              return { ...prev, practitioners: { ...prev.practitioners, [resource.id]: resource } }
            }

            if (isPatient(resource) && resource.id) {
              return { ...prev, patients: { ...prev.patients, [resource.id]: resource } }
            }

            if (isLocation(resource) && resource.id) {
              return { ...prev, locations: { ...prev.locations, [resource.id]: resource } }
            }

            return prev
          },
          { appointments: [], practitioners: {}, patients: {}, locations: {} },
        ) ?? {}

      return (
        appointments?.map<CalendarAppointment>((appointment) => {
          const { practitioner, patient, location } = appointment.participant.reduce<{
            practitioner?: Practitioner
            patient?: Patient
            location?: Location
          }>((prev, { actor }) => {
            if (isPractitioner(actor) && actor.id) {
              return { ...prev, practitioner: practitioners?.[actor.id] }
            }

            if (isPatient(actor) && actor.id) {
              return { ...prev, patient: patients?.[actor.id] }
            }

            if (isLocation(actor) && actor.id) {
              return { ...prev, location: locations?.[actor.id] }
            }

            return prev
          }, {})

          return {
            appointment,
            practitioner,
            patient,
            location,
          }
        }) ?? []
      )
    },
    meta: { context: { queryKey, loggedInPractitionerId } },
  })

  if (isError) {
    throw error
  }

  return { appointments, isLoading, isSuccess }
}

export { usePractitionerAppointments }
