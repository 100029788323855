import { useMutation, useQueryClient } from "@tanstack/react-query"
import { ChargeItemDefinition, MedicationDispense, MedicationKnowledge, MedicationRequest } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { ordersQueryKeys } from "orders"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

const useUpdateMedicationRequest = (onSettled: () => void, queryKey: string[], onSuccess?: () => void) => {
  const queryClient = useQueryClient()
  const { update } = useClient()
  const { patientId } = usePatientContext()

  const newMR = async (medicationRequest: MedicationRequest) =>
    update("MedicationRequest", medicationRequest.id as string, medicationRequest)

  const { mutate: updateMedicationRequest, isPending: isUpdating } = useMutation({
    mutationFn: newMR,
    onMutate: (newMR) => {
      queryClient.cancelQueries({ queryKey, fetchStatus: "fetching" })
      const previousData = queryClient.getQueryData(queryKey)
      queryClient.setQueryData<DraftMedData>(queryKey, (old) => {
        const index = old?.medicationRequests?.findIndex((mr) => mr.id === newMR.id)
        if (index !== undefined && index !== -1) {
          return {
            ...old,
            medicationRequests: [
              ...(old?.medicationRequests?.slice(0, index) as MedicationRequest[]),
              newMR,
              ...(old?.medicationRequests?.slice(index + 1) as MedicationRequest[]),
            ],
          } as DraftMedData
        }
        return old as DraftMedData
      })

      return previousData
    },
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        await queryClient.invalidateQueries({ queryKey })
      } else {
        queryClient.setQueryData(queryKey, context)
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
      queryClient.refetchQueries({ queryKey: ordersQueryKeys.count.withPatientId(patientId), type: "all" })
      onSuccess?.()
      displayNotificationSuccess("Prescription updated successfully!")
      // datadogLogs.logger.info(`Prescription ${mr.id} updated successfully!`, { mr })
    },
    onSettled: () => {
      onSettled()
    },
  })

  return { updateMedicationRequest, isUpdating }
}

type DraftMedData = {
  medicationRequests: MedicationRequest[] | undefined
  medicationDispenses: MedicationDispense[] | undefined
  medicationKnowledges: MedicationKnowledge[] | undefined
  chargeItemDefinitions: ChargeItemDefinition[] | undefined
  medCodes: string
  isLoading: boolean
  draftMedicationRequestsCount: number
  total: number
  reloadMedications: () => void
}

export { useUpdateMedicationRequest }
