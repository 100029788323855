import { useQuery } from "@tanstack/react-query"
import { CodeableConcept, PlanDefinition, getResource } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { plansQueryKeys } from "../query-keys"

const useAlgorithmAssessmentPlanDetails = (algorithmId?: string, enabled: boolean = true) => {
  const { search } = useClient()
  const queryKey = plansQueryKeys.assessmentDefinition(algorithmId as string)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "active",
        _elements: "name,title,url,version,identifier,useContext,action",
        _id: algorithmId as string,
      })

      const bundle = await search({ endpoint: "PlanDefinition", filters, signal })
      const planDefinition = getResource<PlanDefinition>(bundle, "PlanDefinition")

      return { planDefinition }
    },
    meta: { context: { queryKey } },
    enabled: enabled && !!algorithmId,
  })

  const { runAlgorithmAction, runAlgorithmCode, icd10 } = useMemo(() => {
    const runAlgorithmAction = data?.planDefinition.action?.find(({ code }) =>
      code?.[0]?.coding?.some(({ code }) => code === "run-algorithm"),
    )

    const runAlgorithmCode = runAlgorithmAction?.input?.find(({ type }) => type === "ServiceRequest")?.codeFilter?.[0]
      ?.code?.[0]

    const actionLab = data?.planDefinition.action?.find(({ code }) =>
      code?.[0]?.coding?.some(({ code }) => code === "request-lab-order"),
    )

    const planReasonCodes = actionLab?.dynamicValue?.find((v) => v.path === "reasonCode")?.expression?.expression
    let icd10: CodeableConcept | undefined
    if (planReasonCodes) {
      try {
        icd10 = JSON.parse(planReasonCodes) as CodeableConcept
      } catch (_) {
        icd10 = undefined
      }
    }

    return { runAlgorithmAction, runAlgorithmCode, icd10 }
  }, [data?.planDefinition])

  return { runAlgorithmAction, runAlgorithmCode, isLoading, icd10 }
}

export { useAlgorithmAssessmentPlanDetails }
