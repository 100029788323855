enum SurveyStatus {
  "progress" = "in-progress",
  "completed" = "completed",
}

const surveyStatusCodes = [
  { display: "In progress", code: SurveyStatus.progress },
  { display: "Completed", code: SurveyStatus.completed },
]

export { surveyStatusCodes, SurveyStatus }
