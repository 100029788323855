import { faPencil, faPlus } from "@fortawesome/pro-solid-svg-icons"
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog"
import { SelectButton } from "primereact/selectbutton"
import { classNames } from "primereact/utils"
import { startTransition, useEffect } from "react"
import { useParams } from "react-router-dom"

import { FooterActions, SearchWithStatus, SkeletonLoader, useChargeItemDefinitions } from "commons"
import { MedicationRequestInfo, useMedicationRequestDataBind, useMedicationRequests } from "commons/meds"
import { medicationRequestStatus } from "data"
import { useCPOEContext } from "orders"
import { useOrganizationContext } from "organization"

import { completeMR, discardMR, medicationViewOptions, refreshMR, stopAndRenewMR, stopMR } from "../../data"
import {
  useCompleteMedicationRequest,
  useCreateMedicationRequest,
  useDeleteMedicationRequest,
  useMedReducerState,
  useStopAndRenewMedicationRequest,
  useStopMedicationRequest,
} from "../../hooks"
import { Contents } from "../../types"
import { getRenewedMR } from "../validations"
import { MedicationRequestList } from "./MedicationRequestList"
import "./MedicationRequestViewContainer.css"

const MedicationRequestViewContainer = ({ activeContent, onUpdateContent, navToDraft, hasGP }: Props) => {
  const { patientId } = useParams()
  const cpoeCtx = useCPOEContext()
  const { currentOrganizationId } = useOrganizationContext()

  const { statusFilter, searchFilter, itemActionClicked, onActionCliked, updateFilters, updateSearchText } =
    useMedReducerState()

  const {
    medicationRequests,
    medicationDispenses,
    medicationKnowledges,
    isLoading: isLoadingMR,
    reloadMedications,
    total: totalMR,
    draftMedicationRequestsCount,
    medCodes,
    fetchNextPage,
    hasNextPage,
  } = useMedicationRequests({
    patientId: patientId as string,
    category: "nutraceutical",
    statusFilter,
    searchText: searchFilter,
  })
  const { chargeItemDefinitions } = useChargeItemDefinitions({
    organizationId: currentOrganizationId,
    codes: {
      billToPracticeOrInsuranceCIDs: medCodes,
    },
  })

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests,
    medicationKnowledges,
    medicationsCIDs: chargeItemDefinitions?.billToPracticeOrInsuranceCIDs ?? {},
    medicationDispenses,
  })

  useEffect(() => {
    if (cpoeCtx.ordersCount >= 0) reloadMedications()
  }, [cpoeCtx.ordersCount])

  const { deleteMedicationRequest, isDeleting } = useDeleteMedicationRequest(() => onActionCliked(""))
  const { createMedicationReq, isAdding } = useCreateMedicationRequest({
    hideForm: () => onActionCliked(""),
    onSuccess: navToDraft,
  })
  const { stopMedicationRequest, isLoading: isStopping } = useStopMedicationRequest(
    () => onActionCliked(""),
    (meds) => refreshMR(meds[0], medicationRequestData ?? []),
  )
  const { completeMedicationRequest, isLoading: isCompleting } = useCompleteMedicationRequest(
    () => onActionCliked(""),
    (meds) => refreshMR(meds[0], medicationRequestData ?? []),
  )
  const { stopAndRenewMedicationRequest, isLoading: isStoppinAndAdding } = useStopAndRenewMedicationRequest(
    (meds) => refreshMR(meds[0], medicationRequestData ?? []),
    () => onActionCliked(""),
  )

  const stopAndRenew = (medReqId: string) => {
    stopAndRenewMR(medReqId, () => {
      onActionCliked(medReqId)
      stopAndRenewMedicationRequest(medReqId)
    })
  }

  const renew = (medicationRequest: MedicationRequestInfo) => {
    confirmDialog({
      message: "Are you sure you want to reorder this prescription?",
      header: "Confirmation",
      acceptLabel: "Reorder",
      rejectLabel: "Cancel",
      rejectClassName: "button-default p-button-sm",
      acceptClassName: "button-primary p-button-sm",
      accept: () => {
        onActionCliked(medicationRequest.id as string)
        createMedicationReq(getRenewedMR(medicationRequest))
      },
    })
  }

  const discard = (medReqId: string) => {
    discardMR(medReqId, () => {
      onActionCliked(medReqId)
      deleteMedicationRequest(medReqId)
    })
  }

  const stop = (medReqId: string) => {
    stopMR(medReqId, () => {
      onActionCliked(medReqId)
      stopMedicationRequest([medReqId])
    })
  }

  const complete = (medReqId: string) => {
    completeMR(medReqId, () => {
      onActionCliked(medReqId)
      completeMedicationRequest(medReqId)
    })
  }

  const loader = () => (
    <div className="flex flex-col overflow-auto">
      <SkeletonLoader
        repeats={3}
        containerClassName="p-5 overflow-auto"
        loaderType="list"
        skeletonShape="rectangle"
        skeletonSize="6rem"
        extraLine
      />
    </div>
  )

  const addOptions = [
    {
      label: `${draftMedicationRequestsCount ? "Resume" : "Create"} Prescription`,
      icon: draftMedicationRequestsCount > 0 ? faPencil : faPlus,
      command: () => navToDraft(),
      title: `${draftMedicationRequestsCount ? "Resume" : "Create"} Nutraceutical Prescription`,
      disabled: !hasGP,
    },
  ]

  return (
    <>
      <div className="inline-flex justify-between border-gray-100 border-b-2 h-16 w-full p-3">
        <SelectButton
          value={activeContent}
          options={medicationViewOptions}
          optionLabel="name"
          optionValue="value"
          allowEmpty={false}
          onChange={(e) => onUpdateContent(e.value)}
        />
        <SearchWithStatus
          placeholder="Search products"
          options={medicationRequestStatus}
          selectedItems={statusFilter}
          onStatusCheck={updateFilters}
          onSearch={(filter) => {
            startTransition(() => {
              updateSearchText(filter ?? "")
            })
          }}
        />
      </div>
      <>
        {isLoadingMR && loader()}
        {activeContent === "medications" && (
          <MedicationRequestList
            data={medicationRequestData}
            totalMR={totalMR}
            itemWaitingForAction={
              isStoppinAndAdding || isAdding || isStopping || isCompleting || isDeleting ? itemActionClicked : undefined
            }
            className={classNames("data-container", {
              empty: !totalMR,
              hidden: isLoadingMR,
            })}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            onAddMR={navToDraft}
            medicationRequestActions={{ discard, renew, stop, complete, stopAndRenew }}
          />
        )}
      </>
      {totalMR > 0 && <FooterActions actions={addOptions} />}

      <ConfirmDialog />
    </>
  )
}

type Props = {
  activeContent: Contents
  onUpdateContent(activeContent: Contents): void
  navToDraft(): void
  navToReschedule(): void
  hasGP: boolean
}

export { MedicationRequestViewContainer }
