import { faDiagnoses } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, AppModule, ModuleRenderSideType } from "commons/Module"

class ConditionsModule extends AppModule {
  constructor(kpElement: JSX.Element) {
    super()
    this.id = ModulesId.CONDITIONS
    this.display = "Conditions"
    this.parent = ModulesId.INTAKE
    this.hideBySetting = true
    this.icon = faDiagnoses
    this.kpElement = kpElement
    this.renderSideType = ModuleRenderSideType.KP
  }
}

export { ConditionsModule }
