import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { codeableConceptAsString, Medication } from "fhir"

const MedicationDetails = ({ selectedMed, setSelectedMed }: Props) => {
  const renderModalFooter = () => (
    <div className="mt-2">
      <Button label="Close" className="button-default" onClick={() => setSelectedMed(undefined)} />
    </div>
  )

  return (
    <Dialog
      header={selectedMed?.code?.text}
      visible={selectedMed !== undefined}
      draggable={false}
      dismissableMask={true}
      style={{ width: "30vw" }}
      onHide={() => setSelectedMed(undefined)}
      footer={renderModalFooter}
    >
      <div className="flex flex-col">
        {selectedMed?.form && (
          <div className="flex space-x-2 mb-4">
            <span className="font-semibold">Dose form:</span>
            <span>{codeableConceptAsString(selectedMed.form)}</span>
          </div>
        )}
        {selectedMed?.ingredient && (
          <div className="my-2">
            <h3 className="uppercase font-bold mb-2">Ingredients:</h3>
            <ul className="flex flex-col space-y-2 list-disc">
              {selectedMed.ingredient.map(({ item, strength }, index) => (
                <li key={index} className="flex space-x-2">
                  <span>{item?.CodeableConcept?.text}</span>
                  <span>
                    {strength?.numerator?.value} {strength?.numerator?.unit}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Dialog>
  )
}

type Props = {
  selectedMed?: Medication
  setSelectedMed(medItem?: Medication): void
}

export { MedicationDetails }
