import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Bundle, getResources, MedicationRequest } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { medsQueryKeys } from "commons/meds"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

const useCompleteMedicationRequest = (
  onSettled?: () => void,
  refreshMedications?: (meds: MedicationRequest[]) => void,
) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const completeMedicationReq = async (medicationRequestId: string) =>
    operationRequest<Bundle>({
      endpoint: "MedicationRequest",
      method: "POST",
      operation: "complete",
      id: medicationRequestId,
    })

  const { mutate: completeMedicationRequest, isPending } = useMutation({
    mutationFn: completeMedicationReq,
    onError: (error: CustomError, medicationRequestId) => {
      displayNotificationError(registerErrorTrace(error, { medicationRequestId }))
    },
    onSuccess: async (meds) => {
      const medications = getResources<MedicationRequest>(meds)
      refreshMedications?.(medications)
      await queryClient.invalidateQueries({
        queryKey: medsQueryKeys.medicationRequestList(patientId, "nutraceutical", ["active"]),
        refetchType: "all",
      })
      displayNotificationSuccess("Prescription completed successfully!")
      // datadogLogs.logger.info("Prescription completed successfully!", { patientId, medications })
    },
    onSettled: onSettled,
  })

  return { completeMedicationRequest, isLoading: isPending }
}

export { useCompleteMedicationRequest }
