import { Route, Routes } from "react-router-dom"

import { SmartyAddressVerificationProvider } from "commons/context"
import { NotFoundView } from "errors"

import { PatientListView } from "./PatientListView"
import { PatientView } from "./PatientView"

const Router: React.FC = () => (
  <SmartyAddressVerificationProvider>
    <Routes>
      <Route path="/">
        <Route index element={<PatientListView />} />

        <Route path=":patientId" element={<PatientView />} />
        <Route path="*" element={<NotFoundView />} />
      </Route>
    </Routes>
  </SmartyAddressVerificationProvider>
)

export { Router }
