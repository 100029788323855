import { parseISO } from "date-fns"
import { ServiceRequest } from "fhir"
import { Chip } from "primereact/chip"
import { classNames } from "primereact/utils"
import { useId } from "react"
import { useNavigate } from "react-router-dom"

import { ModulesId, SkeletonLoader } from "commons"
import { MedicationOrderTaskListItem } from "commons/meds"
import { formatsByTypes } from "data"
import { InvoiceListItem } from "invoices"
import { useOrganizationContext } from "organization"
import { TaskOrderListItem } from "tasks"
import { formatDate } from "utils"

import { useAuditEvent } from "../hooks"
import { MedicationDispenseListItem } from "./MedicationDispenseListItem"
import { MedicationRequestListItem } from "./MedicationRequestListItem"
import { PaymentReconciliationListItem } from "./PaymentReconciliationListItem"

const AuditEventDetail = ({ auditEventId }: Props) => {
  const navigate = useNavigate()
  const { currentOrganizationId } = useOrganizationContext()
  const {
    auditEvent,
    participants,
    tasks,
    invoices,
    paymentReconciliations,
    serviceRequests,
    medicationRequests,
    medicationDispenses,
    isLoading,
  } = useAuditEvent(currentOrganizationId, auditEventId)

  const loaderKey = useId()
  const loader = () => <SkeletonLoader key={loaderKey} repeats={6} loaderType="one-line" />

  if (isLoading) return loader()

  return (
    <>
      <DetailItem label="Event type" value={auditEvent?.subtype?.[0].display} className="capitalize" />

      <div className="py-3 pl-2 text-sm">
        <div className="font-semibold border-b">Agents</div>
        {participants?.map((p, index) => (
          <div key={index} className="ml-1 mt-2 text-slate-600">
            <span className="capitalize">{p.name}</span>
            {p.type && (
              <>
                <span className="mx-1">|</span>
                {p.type}
              </>
            )}
            {p.requestor && <Chip label="Requestor" className="ml-2 custom-chip is-info" />}
          </div>
        ))}
      </div>

      <DetailItem
        label="Date"
        value={formatDate(parseISO(String(auditEvent?.period?.start)), formatsByTypes.LONG_DATETIME)}
      />

      {medicationRequests && medicationRequests.length > 0 && (
        <div className="py-3 pl-2 text-sm">
          <div className="font-semibold border-b">Medication request</div>
          {medicationRequests.map((mr, idx) => (
            <ul key={mr.id ?? idx} className="h-16 -ml-1">
              <MedicationRequestListItem medicationRequest={mr} />
            </ul>
          ))}
        </div>
      )}

      {medicationDispenses && medicationDispenses.length > 0 && (
        <div className="py-3 pl-2 text-sm">
          <div className="font-semibold border-b">Medication dispense</div>
          {medicationDispenses.map((md, idx) => (
            <ul key={md.id ?? idx} className="h-16 -ml-2">
              <MedicationDispenseListItem medicationRequest={md} />
            </ul>
          ))}
        </div>
      )}

      {serviceRequests && serviceRequests.length > 0 && (
        <div className="py-3 pl-2 text-sm">
          <div className="font-semibold border-b">Order</div>
          {serviceRequests.map((sr, idx) => (
            <ul key={sr.id ?? idx} className="h-16 -ml-2">
              <TaskOrderListItem
                key={sr.id}
                serviceRequest={sr}
                onClick={() =>
                  navigate(`/orgs/${currentOrganizationId}/patients/${sr.subject.id}?${getUrlBasedOnCode(sr)}`)
                }
              />
            </ul>
          ))}
        </div>
      )}

      {paymentReconciliations && paymentReconciliations.length > 0 && (
        <div className="py-3 pl-2 text-sm">
          <div className="font-semibold border-b">Payment reconciliation</div>
          {paymentReconciliations.map((pr, idx) => (
            <ul key={pr.id ?? idx} className="h-16 -ml-2">
              <PaymentReconciliationListItem key={pr.id} paymentReconciliation={pr} />
            </ul>
          ))}
        </div>
      )}

      {invoices && invoices.length > 0 && (
        <div className="py-3 pl-2 text-sm">
          <div className="font-semibold border-b">Invoice</div>
          {invoices.map((invoice, idx) => (
            <ul key={invoice.id ?? idx} className="h-16 -ml-2">
              <InvoiceListItem
                key={invoice.id}
                invoice={invoice}
                onClick={() =>
                  navigate(
                    `/orgs/${currentOrganizationId}/patients/${invoice?.subject?.id}?view=invoices&invoiceId=${invoice.id}`,
                  )
                }
              />
            </ul>
          ))}
        </div>
      )}

      {tasks && tasks.length > 0 && (
        <div className="py-3 pl-2 text-sm">
          <div className="font-semibold border-b">Task</div>
          {tasks.map((task, idx) => (
            <ul key={task.id ?? idx} className="h-16  -ml-2">
              <MedicationOrderTaskListItem key={task.id} task={task} />
            </ul>
          ))}
        </div>
      )}
    </>
  )
}

const DetailItem = ({ label, value, className }: { label: string; value?: string; className?: string }) => (
  <div className="py-3 pl-2 text-sm">
    <div className="font-semibold border-b">{label}</div>
    <div className={classNames("ml-1 mt-2 text-slate-600", className)}>{value ?? "Not specified"}</div>
  </div>
)

function getUrlBasedOnCode(sr: ServiceRequest): string {
  const code = sr.category?.[0]?.coding?.[0]?.code

  if (code === "medication-order") {
    return `view=${ModulesId.MEDICATIONR}&subview=${sr.status === "active" ? "order" : "history"}&order=${sr.id}`
  } else if (code === "lab-order") {
    return `view=labs&order=${sr.id}`
  }

  return ""
}

type Props = {
  auditEventId: string
}

export { AuditEventDetail }
