import { CodeableConcept, PlanDefinitionActionArrayActionArray, Reference } from "fhir"
import { useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { FC, useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import { BILLING_TYPES_CODES } from "data"
import { useOrganizationContext } from "organization"

import { ReferenceDropdownField } from "../../../forms"
import { ICD10CodesField } from "../../../forms/ICD10Codes"
import { BillingField, BloodDrawField } from "../../../forms/labFields"
import { useLoadProductsPrice } from "../../../hooks"
import { getRestrictedLabPerformer, useAvailableLabs, useLaboratoryLocations } from "../../../labs"
import { PractitionerInfo } from "../../../types"
import { isCanonicalOfDrawFee } from "../../../utils"
import { PLAN_ACTION_CODES, PlanContext, PlanData } from "../../types"
import { ComboSelection } from "./ComboSelection"
import { ExtraPanelField } from "./ExtraPanelField"

const LabsFormSection: FC<Props> = ({ configureActions, practitionersInfo, contentClassName }) => {
  const [params] = useSearchParams()
  const {
    laboratoryLocation,
    currentOrganizationId,
    isExemptLabPayment,
    performerLabsEnabled,
    organizationAnIdentifier,
  } = useOrganizationContext()
  const {
    values: { billingType, combos, performer, performers, combo, requester },
    setFieldTouched,
    setFieldValue,
    registerField,
  } = useFormikContext<PlanData>()

  const labAction = configureActions[PLAN_ACTION_CODES.CONFIGURE_LABS]

  const { requiredLabOder } = useMemo(() => {
    const subActionsRequiredBehavior = labAction.action?.reduce(
      (acc, act) => {
        const code = act.code?.[0]?.coding?.[0]?.code
        const required = act.requiredBehavior === "must"
        return { ...acc, [code as string]: required }
      },
      {} as Record<string, boolean>,
    )

    return { requiredLabOder: labAction?.requiredBehavior === "must", subActionsRequiredBehavior }
  }, [labAction])

  useEffect(() => {
    const validateRefs = (value?: Reference, message: string = "This field is required") => (!value?.id ? message : "")
    if (requiredLabOder) {
      registerField("performer", { validate: (v) => validateRefs(v, "Lab is required") })
      registerField("panels", {
        validate: (v?: string[]) => {
          const noDrawPanels = v?.filter((pdCanonical) => !isCanonicalOfDrawFee(pdCanonical))

          return !noDrawPanels?.length && !combo ? "At least one panel is required" : ""
        },
      })
    }
  }, [requiredLabOder, combo])

  const { availableLabs } = useAvailableLabs(
    practitionersInfo,
    performerLabsEnabled.filter(({ ref }) => performers?.some(({ id }) => id === ref.id)),
    requester,
    organizationAnIdentifier,
  )

  const { mapProductsPrice, isPending } = useLoadProductsPrice(
    currentOrganizationId,
    billingType ?? BILLING_TYPES_CODES.BILL_PATIENT,
    "combo",
    (data) => setFieldValue("combos", data),
  )

  const { updateLabLocation } = useLaboratoryLocations(laboratoryLocation, (locationRefs) =>
    setFieldValue("order.locationReference", locationRefs),
  )

  useEffect(() => {
    setFieldTouched("order.reasonCode", true)
    if (!isExemptLabPayment) mapProductsPrice({ products: combos })
  }, [billingType, setFieldTouched])

  const isMCView = useMemo(() => params.get("view") === PlanContext.MC, [params])

  return (
    <>
      <div className={contentClassName?.half}>
        <ReferenceDropdownField
          field="performer"
          label="Lab"
          placeholder="Select Laboratory"
          options={availableLabs?.map(({ ref }) => ref)}
          showFilter={false}
          handleChange={(newPerRef) => updateLabLocation(newPerRef?.id)}
          assignDefaultValue={requiredLabOder && !isMCView}
          isClearable={!requiredLabOder}
          labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 !text-sm !text-gray-500 !font-medium !leading-[1.0625rem]"
          className="flex-1 w-full"
        />
        <BloodDrawField
          className="w-full mt-4"
          checkboxClassName="border-t-0 border-none items-start"
          labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 !text-sm !text-gray-500 !font-medium !leading-[1.0625rem]"
        />
      </div>
      <BillingField
        insuranceField="order.insurance[0]"
        className={contentClassName?.half}
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-500 font-medium leading-[1.0625rem]"
        radioButtonLabelClassName="text-sm text-gray-500 font-medium leading-[1.0625rem]"
      />
      <ComboSelection
        className={classNames("mt-2", contentClassName?.full)}
        containerClassName={classNames("gap-6 overflow-y-auto", contentClassName?.fullContentHalfItem)}
        itemsClassName={contentClassName?.half}
        combos={combos}
        isLoadingPrices={isPending}
        isExemptLabPayment={isExemptLabPayment}
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-500 font-medium leading-[1.0625rem]"
      />
      <ExtraPanelField
        plan={{ combos, performer }}
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 text-sm text-gray-500 font-medium leading-[1.0625rem]"
        className={classNames({ "mt-4": !performer }, contentClassName?.full)}
        containerClassName="!p-0"
      />
      <ICD10CodesField
        field="order.reasonCode"
        label="ICD-10 Codes"
        className={contentClassName?.full}
        labelClassName="w-32 @lg:w-48 @xl:w-52 @2xl:w-60 !text-sm !text-gray-500 !font-medium !leading-[1.0625rem]"
        containerClassName="!p-0"
        showSuggestedPatientConditions
        orgSuggestedConditions="defaultProcedureIcd10"
        validate={(value?: CodeableConcept[]) => {
          const restrictedLabPerformer = getRestrictedLabPerformer(performer?.id as string)
          return (
            (billingType === BILLING_TYPES_CODES.INSURANCE && !value?.length
              ? "Specify one condition at least"
              : undefined) ||
            (restrictedLabPerformer && performer
              ? value?.length && value?.length > restrictedLabPerformer.maxAmount
                ? `You have surpassed the maximum of allowed ICD-10 codes selectable with ${performer.display} (${restrictedLabPerformer.maxAmount})`
                : undefined
              : undefined)
          )
        }}
      />
    </>
  )
}

type Props = {
  configureActions: Record<string, PlanDefinitionActionArrayActionArray>
  practitionersInfo: PractitionerInfo[]
  contentClassName?: { full: string; half: string; fullContentHalfItem: string }
}

export { LabsFormSection }
