import { asReference } from "fhir"
import { useFormikContext } from "formik"
import { SelectButton } from "primereact/selectbutton"
import { classNames } from "primereact/utils"
import { FC, useState } from "react"

import { DateField } from "../../../forms/DateField"
import { QRDropdownField } from "../../../forms/QRDropdownField"
import { useQRsByCanonical } from "../../../hooks"
import { MailTaskData, PlanData } from "../../types"
import "./ReusableQRFieldSwitch.css"

const ReusableQRField: FC<Props> = ({
  patientId,
  taskOutput,
  pickField,
  dateField,
  maxDate,
  minDate,
  horizontal,
  fieldPath,
  labelClassName,
  className,
}) => {
  const { setFieldValue } = useFormikContext<PlanData>()

  const { canonicals, withSubactionValue, someReusableTask } = taskOutput.reduce(
    (acc, { canonical, subactionValue, reusable }, index) => {
      return {
        canonicals: [...acc.canonicals, canonical ?? ""],
        withSubactionValue: [...acc.withSubactionValue, ...(subactionValue ? [index] : [])],
        someReusableTask: acc.someReusableTask || !!reusable,
      }
    },
    {
      canonicals: Array<string>(),
      withSubactionValue: Array<number>(),
      someReusableTask: false,
    },
  )

  const [showQr, setShowQr] = useState(withSubactionValue)

  const toogleShow = (index: number) =>
    setShowQr((val) => {
      const foundAtIndex = val.findIndex((v) => v === index)
      return foundAtIndex === -1 ? val.toSpliced(val.length, 0, index) : val.toSpliced(foundAtIndex, 1)
    })

  const { questionnaireData, isLoading, total, refetch } = useQRsByCanonical(
    patientId,
    canonicals,
    ["completed"],
    someReusableTask,
  )

  const canShowQROptions = !!total

  if (!canShowQROptions)
    return taskOutput.map(({ label, canonical }, index) => (
      <DateField
        key={`${canonical}-${index}`}
        label={label}
        field={dateField}
        maxDate={maxDate}
        placeholder="Select Date"
        validation={(value) => !value && "This date is required"}
        minDate={minDate}
        className={classNames("flex-1 w-full", className)}
        horizontal={horizontal}
        labelClassName={labelClassName}
      />
    ))

  return taskOutput.map(({ label, canonical, reusable, activityIndex }, index) => {
    const qrDropdownVisible = showQr.includes(index)
    const qrOptions = questionnaireData?.[canonical as string]?.qResponse ?? []

    const qrSelectOptions = ["Send new", "Pick"]
    const qrSelectOptionSelected = qrDropdownVisible ? "Pick" : "Send new"

    return (
      <div
        key={`${canonical}-${index}`}
        className={classNames("p-fluid inline-flex w-full space-x-2 items-center", className)}
      >
        {qrDropdownVisible && !!reusable && qrOptions.length ? (
          <QRDropdownField
            field={`${pickField}.${activityIndex}.outcomeReference.0`}
            label={label}
            options={qrOptions}
            onHideSurvey={() => setTimeout(refetch, 100)}
            className="flex-1"
            isLoading={isLoading}
            optionLabel="display"
            horizontal={horizontal}
            handleChange={(qr) =>
              setFieldValue(`${fieldPath}.taskOutput.${index}.subactionValue`, qr && asReference(qr))
            }
            labelClassName={labelClassName}
          />
        ) : (
          <DateField
            label={label}
            field={dateField}
            maxDate={maxDate}
            placeholder="Select Date"
            validation={(value) => !value && "This date is required"}
            minDate={minDate}
            className="flex-1"
            onChange={
              reusable ? () => setFieldValue(`${pickField}.${activityIndex}.outcomeReference.0`, undefined) : undefined
            }
            horizontal={horizontal}
            labelClassName={labelClassName}
          />
        )}
        {!!qrOptions.length && !!reusable && (
          <SelectButton
            type="button"
            title={`${qrSelectOptionSelected} ${label}`}
            value={qrSelectOptionSelected}
            options={qrSelectOptions}
            className="styled-switch"
            style={{ textWrap: "nowrap" }}
            pt={{
              root: {
                className:
                  "grid grid-cols-2 mt-2 !h-[2.25rem] !gap-x-[0.375rem] !rounded-md p-[0.125rem] text-center !bg-[#EEF4F8] shadow-sm shadow-[#0000000D] border border-dashed border[#E5E7EB]",
              },
              button: {
                className:
                  "cursor-pointer !rounded-[0.3125rem] !text-xs !px-[0.5rem] !py-[0.625rem] text-gray-500 !flex line-clamp-1 items-center",
              },
            }}
            onChange={() => {
              toogleShow(index)
              setFieldValue(`${fieldPath}.taskOutput.${index}.subactionValue`, undefined)
            }}
          />
        )}
      </div>
    )
  })
}

type Props = {
  fieldPath: string
  pickField: string
  dateField: string
  patientId: string
  maxDate?: Date
  minDate?: Date
  horizontal?: boolean
  labelClassName?: string
  className?: string
} & Pick<MailTaskData, "taskId" | "taskOutput" | "label">

export { ReusableQRField }
