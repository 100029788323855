import { useQuery } from "@tanstack/react-query"
import { asReference, isEncounter } from "fhir"

import { useClient } from "api"

import { encounterQueryKeys } from "../query-keys"

const useOpenEncounter = (patientId: string) => {
  const { search } = useClient()
  const queryKey = encounterQueryKeys.details(patientId)

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "in-progress",
        _count: "1",
      })

      const { entry } = await search({ endpoint: `Patient/${patientId}/Encounter`, filters, signal })

      return entry?.[0]?.resource && isEncounter(entry[0].resource) ? entry[0].resource : null
    },
    meta: { context: { queryKey, patientId } },
  })

  return {
    openEncounter: data,
    openEncounterRef: data ? asReference(data) : undefined,
    isLoading,
    reloadOpenEncounter: refetch,
  }
}

export { useOpenEncounter }
