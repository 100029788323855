import { InvoiceDetails, InvoiceItemGroupName, InvoiceProvider } from "@chartedhealth/payment-components"
import { FC, Suspense, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { v4 } from "uuid"

import { useChartContext } from "chart-view"
import { LoadingView, ModulesId } from "commons"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { useSignalR } from "signalR"

import { INVOICE_ORDER_TYPE_MAP, INVOICE_PRODUCT_TYPE_MAP } from "../data"

const InvoiceDetailsContainer: FC = () => {
  const { setSearchData, showModule } = useChartContext()
  const { patient } = usePatientContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const { cardReadersEnabled } = useOrganizationContext()

  const { connection, joinGroup, leaveGroup } = useSignalR({ url: window.VITE_APP_SIGNALR_PAYMENT_NOTIFICATION })

  const invoiceId = searchParams.get("invoiceId")

  if (invoiceId && connection) {
    joinGroup(`Invoice/${invoiceId}`)
    connection.on("InvoiceUpdated", () => (providerKey = v4()))
  }

  let providerKey = v4()

  useEffect(() => {
    setSearchData({
      showSearch: false,
    })
    return () => {
      leaveGroup(`Invoice/${invoiceId}`)
    }
  }, [])

  const showPaymentDetails = (paymentId: string) => {
    searchParams.delete("invoiceId")
    searchParams.set("paymentId", paymentId)

    setSearchParams(searchParams)
  }

  if (!invoiceId) {
    return null
  }

  const showOrderDetails = (
    orderId: string,
    orderType: InvoiceItemGroupName,
    productType: InvoiceItemGroupName,
    orderCompleted?: boolean,
  ) => {
    const { module, subview, orderParam } = INVOICE_ORDER_TYPE_MAP[orderType]
    if (module)
      showModule({
        module,
        moduleParams: {
          ...(subview ? (orderCompleted ? { subview: subview.completed } : { subview: subview.default }) : {}),
          ...(orderParam ? { [orderParam]: orderId } : {}),
          ...(module === ModulesId.PLANS && productType ? { ...INVOICE_PRODUCT_TYPE_MAP[productType] } : {}),
        },
      })
  }

  return (
    <div className="relative h-full w-full flex flex-col overflow-auto p-3 bg-white text-sm">
      <Suspense fallback={<LoadingView />}>
        <InvoiceProvider
          key={`${invoiceId}-${providerKey}`}
          invoiceId={invoiceId}
          patient={patient}
          isLabModuleEnabled={true}
          showPaymentDetails={showPaymentDetails}
          showReaders={cardReadersEnabled}
          showOrderDetails={showOrderDetails}
        >
          <InvoiceDetails />
        </InvoiceProvider>
      </Suspense>
    </div>
  )
}

export { InvoiceDetailsContainer }
