import { useQuery } from "@tanstack/react-query"

import { useClient } from "api"
import { sanitizeURL } from "utils"

import { commonsQueryKeys } from "../query-keys"

const useGetSignedUrl = (url: string, enabled = true, onSuccess?: (url: string) => void) => {
  const { getSignedUrl } = useClient()
  const queryKey = commonsQueryKeys.signedWithUrl(url)

  const { data, isLoading, error } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const response = await getSignedUrl(sanitizeURL(url), signal).then((response) => {
        onSuccess?.(response.url)

        return response.url
      })

      return response
    },
    enabled,
    meta: { context: { queryKey, url } },
  })

  return { signedUrl: data, isLoading, error }
}

export { useGetSignedUrl }
