const coverageQueryKeys = {
  all: ["patient/coverages"] as const,
  list: (patientId: string) => [...coverageQueryKeys.all, patientId],
  insuranceProviders: (filterText?: string) => ["insuranceProviders", filterText],
  relatedPerson: {
    all: ["patient/related-person"],
    withPatientID: (patientId: string) => [...coverageQueryKeys.relatedPerson.all, patientId],
  },
  rpLinkeds: (patientId: string, relatedPeople: string[]) => ["patient/linked-coverages", patientId, relatedPeople],
}

export { coverageQueryKeys }
