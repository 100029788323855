import { Form, Formik, FormikProps } from "formik"
import { Button } from "primereact/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilterSlash } from "@fortawesome/pro-regular-svg-icons"

import { DropdownField, ReferenceDropdownField } from "commons"
import { genders } from "data"

import { FilterProps } from "../types"
import { Reference } from "fhir"

const PatientFilters = ({ initialValues, practitioners, onSearch, onClearFilters }: Props) => {
  const renderForm = ({ isSubmitting }: FormikProps<FilterProps>) => (
    <Form className="flex flex-col h-full bg-white">
      <DropdownField
        field="gender"
        label="Biological Sex"
        options={genders}
        horizontal
        className="items-end"
        inputClassName="slashed"
      />
      <ReferenceDropdownField
        label="General practitioner"
        field="practitioner"
        options={practitioners ?? []}
        horizontal
        className="items-end"
        inputClassName="slashed"
        assignDefaultValue={false}
      />

      <div className="flex w-full justify-end pt-3 lg:pt-5 h-12 lg:h-16 bg-white">
        <Button
          type="button"
          label="Clear"
          disabled={isSubmitting || (!initialValues.practitioner && !initialValues.gender)}
          icon={<FontAwesomeIcon icon={faFilterSlash} />}
          onClick={onClearFilters}
          className="p-button-sm mr-3 button-default"
        />
        <Button type="submit" label="Search" loading={isSubmitting} className="p-button-sm button-primary" />
      </div>
    </Form>
  )

  return (
    <Formik initialValues={initialValues} onSubmit={onSearch}>
      {renderForm}
    </Formik>
  )
}

type Props = {
  initialValues: FilterProps
  practitioners?: Reference[]
  onSearch(filters: FilterProps): void
  onClearFilters(): void
}

export { PatientFilters }
