import { sliceEvents, Duration } from "@fullcalendar/core"
import { ViewProps } from "@fullcalendar/core/internal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarXmark } from "@fortawesome/pro-light-svg-icons"
import { Appointment, codeableConceptAsString } from "fhir"

import { AgendaListItem } from "commons"

const AgendaView = (props: ViewProps) => {
  const segs = sliceEvents(props as ViewProps & { nextDayThreshold: Duration }, true) // allDay=true

  return (
    <section className="pl-3 border rounded-md border-solid border-slate-200 shadow-md h-full max-h-[94.5vh] overflow-y-auto">
      <article className="pr-2">
        <div className="overflow-hidden bg-white">
          <ul className="divide-y divide-gray-200 pt-3">
            {segs.map((event) => (
              <AgendaListItem
                key={event.def.defId}
                appointmentStart={event.def.extendedProps.appointment.start}
                appointmentDescription={event.def.title}
                patientName={event.def.extendedProps["patientName"]}
                patientId={event.def.extendedProps["patientId"]}
                appointmentType={codeableConceptAsString(event.def.extendedProps.appointment.appointmentType)}
                color={event.ui.backgroundColor}
                onEdit={() => (props as AgendaViewExtendedProps).edit(event.def.extendedProps.appointment)}
                onUnbook={() => (props as AgendaViewExtendedProps).unbook(event.def.extendedProps.appointment)}
              />
            ))}
          </ul>
        </div>
      </article>
      {!segs?.length && (
        <div className="flex flex-col items-center justify-center grow h-full">
          <FontAwesomeIcon icon={faCalendarXmark} size="3x" className="text-slate-400" />
          <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">No appointments</p>
        </div>
      )}
    </section>
  )
}

type AgendaViewExtendedProps = {
  action: (appointment: Appointment) => void
  edit: (appointment: Appointment) => void
  unbook: (appointment: Appointment) => void
} & ViewProps

export { AgendaView }
