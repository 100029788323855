import { useQuery } from "@tanstack/react-query"
import { DocumentReference, getResources } from "fhir"

import { useClient } from "api"

import { medsQueryKeys } from "../meds_query_keys"

const useMonograph = (monographId: string | undefined) => {
  const { search, getSignedUrl } = useClient()
  const queryKey = medsQueryKeys.monograph(monographId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const filters = new URLSearchParams({
        _id: `${monographId}`,
      })

      const bundle = await search({ endpoint: `DocumentReference`, filters })
      const monograph = getResources(bundle) as DocumentReference[]

      if (!monograph?.[0]) {
        throw new Error("Not found", { cause: { name: "404", message: "Not monograph found" } })
      }
      if (!monograph?.[0].content[0].attachment.url) {
        throw new Error("Not found", { cause: { name: "404", message: "Not monograph url" } })
      }
      const fullUrl = monograph?.[0].content[0].attachment.url?.startsWith("/")
        ? monograph?.[0].content[0].attachment.url.slice(1)
        : monograph?.[0].content[0].attachment.url
      const signedUrl = await getSignedUrl(fullUrl)
      const newMonograph: DocumentReference = {
        ...monograph[0],
        content: [
          {
            attachment: {
              url: signedUrl.url,
              title: monograph[0].content[0].attachment.title,
            },
          },
        ],
      }
      return newMonograph
    },
    enabled: !!monographId,
    meta: { context: { queryKey, monographId } },
  })

  return { monograph: data as DocumentReference, isLoading }
}

export { useMonograph }
