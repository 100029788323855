import { useMutation, useQueryClient } from "@tanstack/react-query"
import { DocumentReference } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { patientsQueryKeys } from "../query-keys"

const useCreatePatientDocument = (onSuccess?: () => void, onSettled?: () => void) => {
  const { create } = useClient()
  const queryClient = useQueryClient()

  const newDocument = async (document: DocumentReference) => create("DocumentReference", document)

  const { mutate: createDocument, isPending } = useMutation({
    mutationFn: newDocument,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (doc) => {
      await queryClient.invalidateQueries({ queryKey: patientsQueryKeys.documents.list(doc.subject?.id as string) })
      displayNotificationSuccess("Document added successfully!")
      // datadogLogs.logger.info(`Document ${doc.id} added successfully!`, { doc })
      onSuccess?.()
    },
    onSettled,
  })

  return { createDocument, isCreating: isPending }
}

export { useCreatePatientDocument }
