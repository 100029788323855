import { faBuilding, faCalendarDays, faPencil, faTrashCan } from "@fortawesome/pro-light-svg-icons"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns/format"
import { parseISO } from "date-fns/parseISO"
import { Coding, Identifier } from "fhir"
import { FormikHelpers, FormikValues, useFormikContext } from "formik"
import { FC, useCallback, useEffect, useMemo, useState } from "react"

import {
  AddFieldArrayItemButton,
  ConfirmDialog,
  DialogFormContainer,
  FormField,
  StackedListContainer,
  StackedListItemProps,
  useCrudReducer,
} from "commons"
import { useReplaceFormContext } from "commons/hooks"
import { IDENTIFIER_CODE, formatsByTypes } from "data"
import { useOrganizationContext } from "organization"

import { SYSTEM_VALUES } from "system-values"
import { useCheckNPIIdentifier } from "../hooks"
import { IdentifierForm } from "./IdentifierForm"
import { getIdentifierValidationSchema, identifierInitialValues, sanitize } from "./validations"

const IdentifierContainer: FC<Props> = ({ field, label, types, avoidReplacement, enabledTypesEdit, hideAdd }) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>()
  const { performerLabsEnabled } = useOrganizationContext()

  const assigners = useMemo(
    () => performerLabsEnabled.flatMap((labFacility) => labFacility.ref),
    [performerLabsEnabled],
  )

  const identifier: Identifier[] | undefined = useMemo(() => {
    const fieldSplit = field.split(".")
    const value =
      fieldSplit.length > 1
        ? values?.[fieldSplit[0] as keyof FormikValues][fieldSplit[1]]
        : values?.[fieldSplit[0] as keyof FormikValues]

    return Array.isArray(value) ? value : undefined
  }, [values])

  const { showSlide, initialValue, deleteIndex, editIndex, editWithIndex, add, reset, setDeleteIndex } = useCrudReducer(
    {
      defaultEntity: { ...identifierInitialValues } as Partial<Identifier>,
    },
  )
  const [showDialog, setShowDialog] = useState(false)

  const addIdentifier = useCallback(
    (data?: Identifier) => {
      const tempIdentifier = { ...data, system: data?.type?.coding?.[0]?.system ?? data?.system }
      const newIdentifier = sanitize(tempIdentifier)
      setFieldValue(field, [
        ...(editIndex !== undefined
          ? identifier?.toSpliced(editIndex, 1, newIdentifier) ?? []
          : [...(identifier ?? []), newIdentifier]),
      ])
      onReset()
    },
    [editIndex, identifier],
  )

  const handleSuccess = useCallback(
    ({
      npiExists,
      formikHelpers,
      identifier: data,
    }: {
      npiExists: boolean
      identifier?: Identifier
      formikHelpers: FormikHelpers<Partial<Identifier>>
    }) => {
      if (npiExists) {
        formikHelpers.setFieldError("value", "This NPI is in use")
        formikHelpers.setSubmitting(false)
        return
      }
      addIdentifier(data)
    },
    [],
  )

  const { checkNPIExists } = useCheckNPIIdentifier({
    onSuccess: handleSuccess,
  })

  const formReplaceContext = useReplaceFormContext<Identifier>()

  const onReset = () => {
    if (avoidReplacement) setShowDialog(false)
    else formReplaceContext?.setShowReplacementContent?.(false)
    reset()
  }

  const onAdd = () => {
    if (avoidReplacement) setShowDialog(true)
    else add()
  }

  const onEdit = (value: Partial<Identifier>, index: number) => {
    if (avoidReplacement) setShowDialog(true)
    editWithIndex(value, index)
  }

  const onSubmit = useCallback(
    (data: Identifier, formikHelpers: FormikHelpers<Partial<Identifier>>) => {
      if (data?.type?.coding?.[0].code === IDENTIFIER_CODE.NPI) {
        checkNPIExists({ identifier: data, formikHelpers })
        return
      }
      addIdentifier(data)
    },
    [addIdentifier],
  )

  const replacementFormProps = useMemo(
    () => ({
      title: label,
      showForm: showSlide,
      useFormik: true,
      initialValue,
      validationSchema: getIdentifierValidationSchema(!!types),
      saveLabel: initialValue.value ? "Save" : "Add",
      children: <IdentifierForm types={types} assigners={assigners} />,
      showCloseIcon: false,
      onCancel: onReset,
      onSubmit: onSubmit,
    }),
    [showSlide, initialValue, types, onSubmit],
  )

  useEffect(() => {
    if (!avoidReplacement) {
      if (showSlide) formReplaceContext?.setReplacementContent?.(replacementFormProps)
      else formReplaceContext?.setShowReplacementContent?.(false)
    }
  }, [replacementFormProps])

  const isOfficialIdentifier = (identifier: Identifier) =>
    identifier.type?.coding?.[0]?.code === IDENTIFIER_CODE.NPI && identifier.use === "official"

  const identifierModelBuilder = useCallback(
    (
      localIdentifier: Identifier,
      index: number,
      setDeleteIndex: (index: number | undefined) => void,
    ): StackedListItemProps => ({
      leftData: [
        {
          lineItems: [
            {
              name: "Value",
              value: localIdentifier.value,
            },
          ],
        },
        {
          lineItems: [
            {
              name: "System",
              value: localIdentifier.system,
            },
          ],
        },
        ...(localIdentifier?.assigner
          ? [
              {
                lineItems: [
                  {
                    name: "Assigner",
                    value:
                      performerLabsEnabled.find(({ ref }) => localIdentifier.assigner?.id === ref.id)?.lab.name ??
                      "unspecified",
                    icon: faBuilding,
                  },
                ],
              },
            ]
          : []),
        ...(localIdentifier.period?.start || localIdentifier.period?.end
          ? [
              {
                lineItems: [
                  {
                    name: "Period",
                    value: `${localIdentifier.period?.start ? format(parseISO(localIdentifier.period?.start), formatsByTypes.LONG_DATETIME) : ""}${
                      localIdentifier.period?.end
                        ? "-" + format(parseISO(localIdentifier.period.end), formatsByTypes.LONG_DATETIME)
                        : ""
                    }`,
                    icon: faCalendarDays,
                  },
                ],
              },
            ]
          : []),
      ],
      menu:
        (!enabledTypesEdit || enabledTypesEdit?.includes(localIdentifier.type?.coding?.[0]?.code as string)) &&
        localIdentifier.system !== SYSTEM_VALUES.B2C
          ? [
              {
                label: "Edit",
                icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
                command: () => {
                  if (identifier?.[index]) {
                    onEdit(identifier[index], index)
                  }
                },
                disabled: isOfficialIdentifier(localIdentifier),
              },
              {
                label: "Delete",
                icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
                command: () => setDeleteIndex(index),
                disabled: isOfficialIdentifier(localIdentifier),
              },
            ]
          : undefined,
    }),
    [identifier, performerLabsEnabled],
  )

  const deleteItem = useCallback(
    (index: number) => {
      const updatedIdentifier = identifier?.toSpliced(index, 1)
      setFieldValue(field, updatedIdentifier)
    },
    [identifier],
  )

  return (
    <>
      <FormField field={field} label={label} className="w-full @container" showInvalidState>
        {!hideAdd && <AddFieldArrayItemButton className="px-2 py-4" onClick={onAdd} />}
        {identifier?.length ? (
          <StackedListContainer
            itemsClassName="px-2 py-4"
            data={identifier}
            itemModelBuilder={(item, index) => identifierModelBuilder(item, index, setDeleteIndex)}
          />
        ) : (
          <div className="flex flex-col items-center justify-center py-5">
            <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
            <p className="text-slate-500 text-xs pt-1">No {label.toLowerCase()} added yet</p>
          </div>
        )}
      </FormField>
      <ConfirmDialog
        confirmText="Are you sure you want to remove this identifier?"
        actionName="Remove"
        visible={deleteIndex !== undefined}
        onConfirm={() => deleteItem(deleteIndex as number)}
        hideDialog={() => setDeleteIndex(undefined)}
      />

      {(!formReplaceContext || !!avoidReplacement) && (
        <DialogFormContainer
          title="Identifier"
          showForm={showDialog}
          onCancel={onReset}
          onSubmit={onSubmit}
          initialValue={initialValue}
          width="42vw"
          useFormik
          validationSchema={getIdentifierValidationSchema(!!types)}
        >
          <IdentifierForm types={types} assigners={assigners} />
        </DialogFormContainer>
      )}
    </>
  )
}

type Props = {
  field: string
  label: string
  avoidReplacement?: boolean
  types?: Coding[]
  enabledTypesEdit?: string[]
  hideAdd?: boolean
}

export { IdentifierContainer }
