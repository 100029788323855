import { SubModule, ModulesId } from "commons/Module"

import { MedicationRequestsDraftContainer } from "../../../medication-requests/components/medications/MedicationRequestsDraftContainer"

class MRDraftSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.MR_DRAFT
    this.parent = ModulesId.MEDICATIONR
    this.display = "Draft Products"
    this.content = <MedicationRequestsDraftContainer />
  }
}

export { MRDraftSubModule }
