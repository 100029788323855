import { format } from "date-fns"
import { codeableConceptAsString } from "fhir"
import pluralize from "pluralize"

import { useChartContext } from "chart-view"
import { KnowledgeListContainer, MenuStyles, ModulesId, SkeletonLoader, StackedListItemProps } from "commons"
import { MedicationRequestData, useMedicationRequestDataBind, useMedicationRequests } from "commons/meds"
import { formatsByTypes } from "data"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import { useMrOrders } from "../../hooks"

const MedicationKnowledge: React.FC = () => {
  const { patientId } = usePatientContext()
  const { showModule } = useChartContext()
  const { medicationRequests, medicationKnowledges, isLoading } = useMedicationRequests({
    patientId,
    category: "nutraceutical",
    statusFilter: ["active"],
  })
  const { serviceRequests, isLoading: isLoadingSR } = useMrOrders(
    patientId,
    medicationRequests?.map((med) => med.id as string),
  )
  const { appModules } = useAppModuleContext()

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests,
    medicationKnowledges,
    serviceRequests,
  })

  if (isLoading || isLoadingSR) {
    return <SkeletonLoader repeats={4} loaderType="one-line" />
  }

  const showOrder = (orderId: string) => {
    showModule({ module: ModulesId.MEDICATIONR, moduleParams: { subview: "orders", order: orderId } })
  }

  return (
    <KnowledgeListContainer
      data={medicationRequestData}
      dataTitle="Products"
      iconDataNotFound={appModules[ModulesId.MEDICATIONR].getIcon()}
      itemModelBuilder={(item) => medicationModel(item, () => showOrder(item.serviceRequest?.id as string))}
    />
  )
}

const medicationModel = (med: MedicationRequestData, showOrder: () => void): StackedListItemProps => {
  const packing = med.medicationKnowledge?.packaging?.type
  const amount =
    med.medicationRequestInfo.dispenseRequest?.quantity?.value ??
    med.medicationRequestInfo.dispenseRequest?.initialFill?.quantity?.value ??
    1
  const date = med.serviceRequest?.occurrence?.dateTime ?? med.medicationRequestInfo.authoredOn
  return {
    leftData: [
      {
        lineItems: [
          { name: "Medication", value: codeableConceptAsString(med.medicationRequestInfo.medication?.CodeableConcept) },
        ],
      },
      {
        lineItems: [
          ...(date ? [{ value: format(new Date(date), formatsByTypes.SHORT_DATE) }] : []),
          ...(packing ? [{ value: `(${amount} ${pluralize(codeableConceptAsString(packing), amount)})` }] : []),
        ],
      },
    ],
    menuStyle: MenuStyles.ExternalAction,
    menu: [
      {
        label: "Show order",
        command: showOrder,
      },
    ],
  }
}

export { MedicationKnowledge }
