import { faVials } from "@fortawesome/pro-regular-svg-icons"

import { AppModule, ModuleRenderSideType, ModulesId } from "commons/Module"

class LabsOrdersModule extends AppModule {
  constructor(workspaceElement: JSX.Element, kpElement: JSX.Element) {
    super()
    this.id = ModulesId.LABSORDER
    this.display = "Labs"
    this.icon = faVials
    this.hideBySetting = true
    this.workspaceElement = workspaceElement
    this.kpElement = kpElement
    this.renderSideType = ModuleRenderSideType.BOTH
  }
}

export { LabsOrdersModule }
