import { useMutation, useQueryClient } from "@tanstack/react-query"

import { displayNotificationError } from "errors"
import { useClient } from "api"
import { registerErrorTrace } from "logger"
import { azureContainer } from "data"

import { commonsQueryKeys } from "../query-keys"
import { CustomError } from "../types"

const useAzureContainer = (containerName: string) => {
  const { uploadFile: clientUploadFile } = useClient()
  const queryClient = useQueryClient()

  const { mutateAsync: uploadFile, isPending: isUploading } = useMutation({
    mutationFn: async (file: File): Promise<string> => {
      const fileUrl = await clientUploadFile(file, containerName)
      return fileUrl
    },
    onError: (error: CustomError, { name, type, size }) => {
      displayNotificationError(registerErrorTrace(error, { file: { name, type, size } }))
    },
    onSuccess: () => {
      switch (containerName) {
        case azureContainer.photos:
          queryClient.invalidateQueries({ queryKey: commonsQueryKeys.signed })
          break
      }
    },
  })

  return { uploadFile, isUploading }
}

export { useAzureContainer }
