import { useMutation } from "@tanstack/react-query"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { DocumentReference } from "fhir"
import { registerErrorTrace } from "logger"
import { sanitizeURL } from "utils"

const usePrintQuestionnaire = (onSuccess: (url: string) => void, onSettled?: () => void) => {
  const { operationRequest, getSignedUrl } = useClient()

  const filters = new URLSearchParams({
    "force-refresh": "true",
  })

  const getPrintedQRUrl = async (carePlanQRId: string) => {
    return operationRequest<DocumentReference>({
      endpoint: "QuestionnaireResponse",
      method: "POST",
      operation: "print",
      id: carePlanQRId,
      filters,
    })
  }

  const { mutate: printQuestionnaire, isPending } = useMutation({
    mutationFn: getPrintedQRUrl,
    onSuccess: async (data) => {
      if (data.content[0].attachment.url) {
        const url = data.content[0].attachment.url.startsWith("/")
          ? data.content[0].attachment.url.slice(1)
          : data.content[0].attachment.url
        const signedUrl = await getSignedUrl(sanitizeURL(url))
        onSuccess(signedUrl.url ?? "")
      }
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSettled,
  })

  return {
    printQuestionnaire,
    isLoadingPrint: isPending,
  }
}

export { usePrintQuestionnaire }
