import { useContext } from "react"

import { LoginContext } from "../context/LoginProvider"

const useLoginContext = () => {
  const context = useContext(LoginContext)

  if (context === undefined) {
    throw new Error("useLoginContext must be used within a LoginProvider")
  }

  return context
}

export { useLoginContext }
