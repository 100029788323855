import { useMutation, useQueryClient } from "@tanstack/react-query"
import { parseISO } from "date-fns"
import { DiagnosticReport } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { mcQueryKeys } from "../query-keys"

const useReleasePlanDR = () => {
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const release = ({ drId }: { drId: string }) =>
    patch<Partial<DiagnosticReport>>("DiagnosticReport", drId, {
      issued: parseISO(new Date().toISOString()),
    })

  const { mutate: releaseDR, isPending: isReleasingDR } = useMutation({
    mutationFn: release,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: mcQueryKeys.all })
      displayNotificationSuccess("Report successfully released to patient!")
      // datadogLogs.logger.info("Report successfully released to patient!")
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { releaseDR, isReleasingDR }
}

export { useReleasePlanDR }
