import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { settingsQueryKeys } from "../../query-keys"

const useHideUnhidePD = (organizationId: string, onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const editPDMutation = async ({ id, op }: { id: string; op: "hide-pd" | "unhide-pd" }) => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "organization",
          value: {
            Reference: { id: organizationId, resourceType: "Organization" },
          },
        },
      ],
    }

    return operationRequest({ endpoint: "PlanDefinition", method: "POST", operation: op, id, parameters })
  }

  const { mutate: hideOrUnhidePd, isPending } = useMutation({
    mutationFn: editPDMutation,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: settingsQueryKeys.labCombos(organizationId as string),
        refetchType: "active",
      })

      displayNotificationSuccess("Updated successfully!")
    },
    onSettled,
  })

  return { hideOrUnhidePd, isUpdating: isPending }
}

export { useHideUnhidePD }
