import { useFormikContext, FormikContextType } from "formik"

const useSafeLabContext = <T>(): FormikContextType<T> | null => {
  try {
    return useFormikContext()
  } catch (error) {
    return null
  }
}

export { useSafeLabContext }
