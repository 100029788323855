import { CodeableConcept, Coding, codeableConceptAsString } from "fhir"
import { FieldArrayRenderProps, FieldValidator } from "formik"
import { FC, useMemo, useState } from "react"

import { useConditions } from "conditions/hooks"
import { OrgDataSettingCode } from "data"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { StackedListItemProps } from "../../components/StackedListItem"
import { useDefaultOrgData } from "../../hooks"
import { GenericFieldArray } from "../GenericFieldArray"
import { ICD10CodesSelection } from "./ICD10CodesSelection"

const ICD10CodesField: FC<Props> = ({
  field,
  label = "ICD-10",
  readOnly,
  className,
  showSuggestedPatientConditions,
  orgSuggestedConditions,
  askForDeleteConfirmation = false,
  containerClassName,
  labelClassName,
  horizontal,
  validate,
}) => {
  const [showSlide, setShowSlide] = useState(false)
  const { currentOrganizationId } = useOrganizationContext()
  const {
    patientId,
    patient: { gender },
  } = usePatientContext()

  const { conditions, isLoading: isLoadingPatientConditions } = useConditions({
    patientId,
    enabled: showSuggestedPatientConditions,
  })
  const { defaultData, isLoading: isLoadingOrgConditions } = useDefaultOrgData(
    currentOrganizationId,
    !!orgSuggestedConditions,
  )

  const orgICD10Codes = useMemo(
    () =>
      orgSuggestedConditions
        ? (defaultData[orgSuggestedConditions]?.[gender ?? "male"] as Coding[] | undefined)?.reduce(
            (prevData, coding) => {
              return { ...prevData, ...{ items: coding.code ? [...prevData.items, coding] : [...prevData.items] } }
            },
            { category: "Organization favorites", items: [] as Coding[] },
          )
        : undefined,
    [orgSuggestedConditions, defaultData, gender],
  )

  const patientConditionsICD10Codes = useMemo(
    () =>
      showSuggestedPatientConditions
        ? conditions?.reduce(
            (prevData, cc) => {
              return { ...prevData, ...{ items: [...prevData.items, ...(cc.code?.coding ?? [])] } }
            },
            { category: "Patient conditions", items: [] as Coding[] },
          )
        : undefined,
    [conditions, showSuggestedPatientConditions],
  )

  const suggestions = useMemo(() => {
    const suggestions = [] as { category: string; items: Coding[] }[]
    if (orgICD10Codes?.items?.length) suggestions.push(orgICD10Codes)
    if (patientConditionsICD10Codes?.items.length) suggestions.push(patientConditionsICD10Codes)
    return suggestions.length ? suggestions : undefined
  }, [orgICD10Codes, patientConditionsICD10Codes])

  return (
    <GenericFieldArray
      field={field}
      label={label}
      confirmDeleteItemText="Are you sure you want to delete this code?"
      emptyDataMessage="No ICD-10 Codes Selected"
      readOnly={readOnly}
      addButtonLabel="Select ICD-10 Codes"
      onAddButtonClick={() => setShowSlide(true)}
      itemModelBuilder={(item: CodeableConcept) => itemModel(item)}
      className={className}
      askDeleteConfirmation={askForDeleteConfirmation}
      containerClassName={containerClassName}
      labelClassName={labelClassName}
      horizontal={horizontal}
    >
      {({ push, remove, form: { getFieldMeta, registerField } }: FieldArrayRenderProps) => {
        const updateCodes = (newCodes: Coding[], deletedCodes: { id: string; index: number }[]) => {
          newCodes.forEach((code) => push({ coding: [code] }))
          deletedCodes.sort((a, b) => b.index - a.index)
          deletedCodes.forEach(({ index }) => remove(index))
        }

        const fieldValue = getFieldMeta(field).value
        const selectedCodes = (fieldValue as CodeableConcept[])?.flatMap((rc) => rc.coding?.[0] ?? []) ?? []
        if (validate) registerField(field, { validate })

        return (
          <>
            {showSlide && (
              <ICD10CodesSelection
                showSlide={showSlide}
                selectedCodes={selectedCodes}
                onHide={({ save, newCodes, deletedCodes }) => {
                  setShowSlide(false)
                  save && newCodes && deletedCodes && updateCodes(newCodes, deletedCodes)
                }}
                suggestions={
                  suggestions
                    ? { data: suggestions, isLoading: isLoadingPatientConditions || isLoadingOrgConditions }
                    : undefined
                }
              />
            )}
          </>
        )
      }}
    </GenericFieldArray>
  )
}

const itemModel = (code: CodeableConcept): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [
        {
          name: "Code",
          value: code.coding?.[0]?.code
            ? `${codeableConceptAsString(code)} - ${code.coding?.[0]?.code}`
            : codeableConceptAsString(code),
        },
      ],
    },
  ],
})

type Props = {
  field: string
  label?: string
  readOnly?: boolean
  className?: string
  askForDeleteConfirmation?: boolean
  showSuggestedPatientConditions?: boolean
  orgSuggestedConditions?: OrgDataSettingCode
  validate?: FieldValidator
  containerClassName?: string
  labelClassName?: string
  horizontal?: boolean
}

export { ICD10CodesField }
