import {
  Account,
  Address,
  ChargeItemDefinition,
  CodeableConcept,
  Coverage,
  Invoice,
  Parameters,
  PaymentReconciliation,
  codeableConceptAsString,
  isCarePlan,
} from "fhir"

import { BILLING_TYPES_CODES } from "data"
import { SYSTEM_VALUES } from "system-values"
import {
  getAddressByType,
  getBasePrice,
  getCommonCode,
  getDiscountPrice,
  getFeePrice,
  getHomeAddress,
  getMoneyCurrencyAlt,
  getTaxPrice,
  isAddressNonContinentalState,
  lineInvoiceTypes,
  strCapitalize,
  sumPrice,
} from "utils"

import {
  ACTION_GROUP_CODES,
  ActionGroupCode,
  CpoeRequest,
  CustomInvoiceData,
  InvoiceItem,
  SummaryParameter,
} from "./types"

const buildAction = (
  actionResource: ChargeItemDefinition | Account | Coverage,
  actionCode: "add-fee" | "add-discount" | "use-reader" | string,
) => ({
  code: [
    {
      coding: [
        {
          code: actionCode,
          system: SYSTEM_VALUES.CPOE_ACTION,
        },
      ],
    },
  ],
  resource: {
    localRef: actionResource.id,
  },
})

const checkIsActionType = ({
  codeableConcept,
  type,
  isNot,
}: {
  codeableConcept?: CodeableConcept[]
  type: ActionGroupCode
  isNot?: ActionGroupCode
}) =>
  codeableConcept?.some(
    (cc) => cc.coding?.find((coding) => coding.system === SYSTEM_VALUES.CPOE_ACTION_GROUP)?.code === type,
  ) &&
  (isNot
    ? !codeableConcept.some(
        (cc) => cc.coding?.find((coding) => coding.system === SYSTEM_VALUES.CPOE_ACTION_GROUP)?.code === isNot,
      )
    : true)

const getSummaryParameter = (summary: Parameters, key: SummaryParameter) => {
  const param = summary.parameter?.find((param) => param.name === key)

  switch (key) {
    case "discounts":
      return param?.part?.reduce(
        (prev, part) => {
          return { ...prev, ...{ [part.name]: part.value?.decimal ?? 0 } }
        },
        {} as Record<string, number>,
      )

    default:
      return param?.value?.decimal ?? 0
  }
}

const getActiveRequests = (requests: CpoeRequest[]) =>
  requests.filter((req) => req.resource.code?.[0].coding?.[0].code === "activate")

enum CPOE_ACTIONS {
  PROCESS = "Process",
  SCHEDULE_ORDER = "Schedule Order",
  SEND_TO_PATIENT = "Send to patient",
  SAVE_DRAFT = "Save (draft)",
  NEXT = "Next",
  SAVE_CLOSE = "Save & Close",
}

const getInvoiceMeta = (invoice: Invoice, paymentReconciliation?: PaymentReconciliation) => {
  const {
    paymentAmount,
    issuer,
    paymentIssuer,
    paymentStatus,
    paymentDate,
    totalGross,
    subject,
    creditCard,
    lineItem,
  } = {
    ...invoice,
    ...paymentReconciliation,
  }
  const price = paymentAmount
    ? `${getMoneyCurrencyAlt(paymentAmount.currency)}${paymentAmount.value ?? 0}`
    : totalGross
      ? `${getMoneyCurrencyAlt(totalGross.currency)}${totalGross.value ?? 0}`
      : "$0"

  const order_issuer = paymentIssuer?.display ?? issuer?.display
  const credit_card =
    creditCard &&
    JSON.stringify({
      card_holder: creditCard.cardHolderName,
      last4Digits: creditCard.last4Digits,
      expiration_month: creditCard.expirationMonth,
      expiration_year: creditCard.expirationYear,
      card_type: creditCard.type,
    })

  const products = lineItem?.reduce((acc, { chargeItem, priceComponent }) => {
    const itemPrice =
      getBasePrice(priceComponent) ??
      getTaxPrice(priceComponent) ??
      getFeePrice(priceComponent) ??
      getDiscountPrice(priceComponent)
    const priceStr =
      `${itemPrice?.factor && itemPrice?.type === lineInvoiceTypes.BASE ? "x" + itemPrice.factor : ""} ${getMoneyCurrencyAlt(itemPrice?.amount?.currency)}${itemPrice?.amount?.value ?? 0}`.replaceAll(
        "-",
        "",
      )
    const product = chargeItem?.Reference?.display ?? codeableConceptAsString(chargeItem?.CodeableConcept)
    return [...acc, `${product} ${priceStr}`]
  }, Array<string>())

  return {
    price,
    order_issuer,
    credit_card,
    payment_status: paymentStatus,
    payment_date: paymentDate,
    order_for: subject?.display,
    products: products?.join("\n"),
  }
}

const getRGActiveItemsInfo = (selectedRequests: CpoeRequest[]) =>
  selectedRequests.reduce(
    (prev, request) => {
      const { type, laboratoryData, medicationData } = request

      switch (true) {
        case type === ACTION_GROUP_CODES.NUTRA:
          return {
            ...prev,
            hasNutraRequest: true,
            hasMedsRequest: true,
            hasPlanBasedRequest: !prev.hasPlanBasedRequest
              ? medicationData?.medicationRequest?.basedOn?.some(isCarePlan) ?? false
              : true,
          }
        case type === ACTION_GROUP_CODES.PROCEDURE:
          return {
            ...prev,
            hasProcedureRequest: true,
            hasPlanBasedRequest: !prev.hasPlanBasedRequest
              ? medicationData?.medicationRequest?.basedOn?.some(isCarePlan) ?? false
              : true,
          }
        case type === ACTION_GROUP_CODES.PHARMA:
          return {
            ...prev,
            hasRXRequest: true,
            hasMedsRequest: true,
            hasPlanBasedRequest: !prev.hasPlanBasedRequest
              ? medicationData?.medicationRequest?.basedOn?.some(isCarePlan) ?? false
              : true,
          }
        case type === ACTION_GROUP_CODES.LAB && laboratoryData?.billingType === BILLING_TYPES_CODES.INSURANCE:
          return {
            ...prev,
            hasInsuranceLabsRequest: true,
            hasLabsRequest: true,
            hasPlanBasedRequest: !prev.hasPlanBasedRequest
              ? laboratoryData?.serviceRequest?.basedOn?.some(isCarePlan) ?? false
              : true,
            insuranceRequests: [...prev.insuranceRequests, request],
          }
        case type === ACTION_GROUP_CODES.LAB:
          return {
            ...prev,
            hasLabsRequest: true,
            hasPlanBasedRequest: !prev.hasPlanBasedRequest
              ? laboratoryData?.serviceRequest?.basedOn?.some(isCarePlan) ?? false
              : true,
            hasBillToPracticeLabRequest: !prev.hasBillToPracticeLabRequest
              ? laboratoryData?.billingType === BILLING_TYPES_CODES.BILL_PRACTICE
              : true,
            hasBillPatientLabRequest: !prev.hasBillPatientLabRequest
              ? laboratoryData?.billingType === BILLING_TYPES_CODES.BILL_PATIENT
              : true,
          }
      }

      return prev
    },
    {
      hasNutraRequest: false,
      hasRXRequest: false,
      hasMedsRequest: false,
      hasProcedureRequest: false,
      hasLabsRequest: false,
      hasInsuranceLabsRequest: false,
      hasPlanBasedRequest: false,
      hasBillToPracticeLabRequest: false,
      hasBillPatientLabRequest: false,
      insuranceRequests: Array<CpoeRequest>(),
    },
  )

const getCheckoutAddressInfo = (
  address?: Address[],
  hasNutraRequest?: boolean,
  hasInsuranceLabsRequest?: boolean,
  hasRXRequest?: boolean,
) => {
  const postalAddress = getAddressByType("postal", address) ?? getHomeAddress(address) ?? address?.[0]
  const physicalOrAnyValidAddress = getAddressByType("physical", address) ?? postalAddress

  const nutrasShippingAddress = isAddressNonContinentalState(postalAddress) ? undefined : postalAddress
  const labsShippingAddress = isAddressNonContinentalState(physicalOrAnyValidAddress)
    ? undefined
    : physicalOrAnyValidAddress

  const missingMedsShippingAddress = !nutrasShippingAddress && !!hasNutraRequest
  const missingLabsShippingAddress = !!hasInsuranceLabsRequest && !labsShippingAddress
  const missingPatientAddress = !!hasRXRequest && !physicalOrAnyValidAddress
  const missingAddresses = missingMedsShippingAddress || missingLabsShippingAddress || missingPatientAddress

  return {
    missingAddresses,
    missingLabsShippingAddress,
    missingMedsShippingAddress,
    missingPatientAddress,
    nutrasShippingAddress,
    labsShippingAddress,
  }
}

const SECTION_KEY_ORDER: { [key: string]: number } = {
  nutraceutical: 0,
  rx: 1,
  "lab-order": 2,
  procedure: 3,
}

const getSectionName = (section: string) => {
  switch (section) {
    case ACTION_GROUP_CODES.NUTRA:
      return "Nutraceuticals"
    case ACTION_GROUP_CODES.PHARMA:
      return "Pharmaceuticals"
    case ACTION_GROUP_CODES.PROCEDURE:
      return "Procedures"
    case ACTION_GROUP_CODES.LAB:
      return "Laboratories"

    default:
      return strCapitalize(section)
  }
}

const getProductCode = (productCodes?: CodeableConcept[]) => {
  return productCodes?.find((cc) => cc.coding?.find((code) => code.system === SYSTEM_VALUES.PRODUCT_TYPE))?.coding?.[0]
    .code
}

const getInvoiceData = (invoice: Invoice) => {
  const initialData: CustomInvoiceData = {
    items: {} as Record<string, InvoiceItem[]>,
    fees: [],
    feesSubTotal: 0,
    shippingMethods: {} as Record<string, InvoiceItem[]>,
    shippingMethodSubtotal: 0,
    taxes: 0,
    itemsSubtotal: 0,
    discount: 0,
    productFeesSubtotal: 0,
    amendments: {} as Record<string, InvoiceItem[]>,
  }

  const itemData =
    invoice.lineItem?.reduce((result, item) => {
      const productType = getProductCode(item.productCode)

      if (item.chargeItem?.Reference) {
        const isAmendment = item.productCode?.some(({ coding }) =>
          coding?.some(({ code }) => ["credit-note", "debit-note"].includes(code as string)),
        )

        if (productType)
          if (isAmendment) {
            result.amendments = {
              ...result.amendments,
              [productType]: [
                ...(result.amendments[productType] ?? []),
                {
                  productType,
                  description: item.chargeItem.Reference.display ?? "",
                  qty: item.priceComponent?.[0].factor,
                  price: item.priceComponent?.[0].amount?.value as number,
                },
              ],
            }
          } else {
            const descriptionUnit = item.priceComponent?.[0].code?.coding?.[0].code
              ? ` (${item.priceComponent?.[0].code?.coding?.[0].code})`
              : ""

            result.items = {
              ...result.items,
              [productType]: [
                ...(result.items[productType] ?? []),
                {
                  productId: getCommonCode({ codes: item.productCode?.[1]?.coding, fallback: "" }),
                  productType,
                  description: `${item.chargeItem.Reference.display ?? ""}${descriptionUnit}`,
                  qty: item.priceComponent?.[0].factor,
                  price: item.priceComponent?.[0].amount?.value as number,
                },
              ],
            }

            result.itemsSubtotal = sumPrice(
              result.itemsSubtotal,
              item.priceComponent?.[0].amount?.value ?? 0,
            ).sum.toNumber()
          }
      }

      if (item.chargeItem?.CodeableConcept?.coding?.some((ci) => ci.system === SYSTEM_VALUES.FEE)) {
        const productType = getProductCode(item.productCode)
        const price = getFeePrice(item.priceComponent)?.amount?.value ?? 0

        if (
          item.chargeItem?.CodeableConcept?.coding?.some((ci) =>
            (
              [
                SYSTEM_VALUES.SHIPPING_METHOD,
                SYSTEM_VALUES.SERVICE_FEE,
                SYSTEM_VALUES.SHIPPING_METHOD_DEFAULT,
              ] as string[]
            ).includes(ci.system ?? ""),
          )
        ) {
          if (productType)
            result.shippingMethods = {
              ...result.shippingMethods,
              ...{
                [productType]: [
                  ...(result.shippingMethods[productType] ?? []),
                  {
                    description: codeableConceptAsString(item?.chargeItem?.CodeableConcept),
                    price,
                    productType,
                  },
                ],
              },
            }
          result.shippingMethodSubtotal = sumPrice(result.shippingMethodSubtotal, price).sum.toNumber()
        } else if (item.chargeItem?.CodeableConcept?.coding?.some((ci) => ci.system === SYSTEM_VALUES.PRODUCT_FEE)) {
          const procedureCode = getCommonCode({ codes: item.chargeItem.CodeableConcept.coding })
          result.productFees = {
            ...(result.productFees ?? {}),
            [procedureCode]: [
              ...(result.productFees?.[procedureCode] ?? []),
              {
                description: `Additional Fee (${codeableConceptAsString(item?.chargeItem?.CodeableConcept)})`,
                price,
                productType,
              },
            ],
          }
          result.productFeesSubtotal = sumPrice(result.productFeesSubtotal, price).sum.toNumber()
        } else {
          result.fees = [
            ...result.fees,
            {
              description: codeableConceptAsString(item?.chargeItem?.CodeableConcept),
              price,
            },
          ]
          result.feesSubTotal = sumPrice(result.feesSubTotal, price).sum.toNumber()
        }
      }

      if (item.chargeItem?.CodeableConcept?.coding?.[0].code === "estimated-taxes") {
        result.taxes = sumPrice(result.taxes, getTaxPrice(item.priceComponent)?.amount?.value ?? 0).sum.toNumber()
      }

      if (item.chargeItem?.CodeableConcept?.coding?.[0].system === SYSTEM_VALUES.DISCOUNT) {
        const price = getDiscountPrice(item.priceComponent)?.amount?.value ?? 0
        result.discount = sumPrice(result.discount, price).sum.toNumber()

        const productType = getProductCode(item.productCode)
        if (productType)
          result.discounts = {
            ...result.discounts,
            ...{
              [productType]: [
                ...(result.discounts?.[productType] ?? []),
                {
                  description: codeableConceptAsString(item?.chargeItem?.CodeableConcept),
                  price,
                  productType,
                },
              ],
            },
          }
      }

      return result
    }, initialData) ?? initialData

  return itemData
}

const defaultCoveragesByType = {
  rx: undefined,
  "lab-order": undefined,
  nutraceutical: undefined,
  procedure: undefined,
}

export {
  CPOE_ACTIONS,
  SECTION_KEY_ORDER,
  buildAction,
  checkIsActionType,
  defaultCoveragesByType,
  getActiveRequests,
  getCheckoutAddressInfo,
  getInvoiceData,
  getInvoiceMeta,
  getRGActiveItemsInfo,
  getSectionName,
  getSummaryParameter,
}
