import { Reference, Location } from "fhir"

import { SYSTEM_VALUES } from "system-values"

const getRoomInitialValues = (organization: Reference, parentLocation: Reference): Location => ({
  status: "active",
  managingOrganization: organization,
  partOf: parentLocation,
  physicalType: {
    coding: [{ code: "ro", display: "Room", system: SYSTEM_VALUES.LOCATION_PHYSICAL_TYPE }],
    text: "Room",
  },
  name: "",
  type: [],
})

export { getRoomInitialValues }
