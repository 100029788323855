import { SubModule, ModulesId } from "commons/Module"

import { PrescriptionsNewContainer } from "../../../eprescribe/components/PrescriptionsNewContainer"

class PrescriptionsNewSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.EPRESCRIBE_NEW
    this.parent = ModulesId.EPRESCRIBE
    this.display = "New Prescription"
    this.content = <PrescriptionsNewContainer />
  }
}

export { PrescriptionsNewSubModule }
