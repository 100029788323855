import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRightLong } from "@fortawesome/pro-light-svg-icons"
import { Link } from "react-router-dom"
import { FC } from "react"

const NotFoundView: FC = () => (
  <div className="flex items-center justify-center w-full h-full">
    <div className="text-center">
      <p className="p-error text-5xl">404</p>
      <h1 className="text-4xl py-2 font-bold">Page not found</h1>
      <p className="text-gray-400 pb-4">Sorry, we couldn't find the page you're looking for</p>
      <Link to="/" className="p-button button-primary">
        <span>
          Go home <FontAwesomeIcon icon={faArrowRightLong} className="pl-1 align-sub" />
        </span>
      </Link>
    </div>
  </div>
)

export { NotFoundView }
