import { Accordion as AccordionContainer, AccordionProps, AccordionTab } from "primereact/accordion"

import "./Accordion.css"

const Accordion = <T,>({ data, headerTemplate, headerClassName, contentTemplate, className, ...props }: Props<T>) => {
  return (
    <AccordionContainer {...props} className={className}>
      {data.map((item, index) => (
        <AccordionTab key={index} header={headerTemplate(item, index)} className={headerClassName}>
          {contentTemplate(item, index)}
        </AccordionTab>
      ))}
    </AccordionContainer>
  )
}

type Props<T> = AccordionProps & {
  data: T[]
  headerClassName?: string
  headerTemplate(item: T, index: number): JSX.Element
  contentTemplate(item: T, index: number): JSX.Element
}

export { Accordion }
