import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Location } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { organizationQueryKeys } from "organization"
import { displayNotificationSuccess } from "utils"

const useCreateRoom = (onSuccess?: () => void, onSettled?: () => void) => {
  const { create, update } = useClient()
  const queryClient = useQueryClient()

  const newRoom = async (room: Location) => (room.id ? update("Location", room.id, room) : create("Location", room))

  const { mutate: createRoom, isPending } = useMutation({
    mutationFn: newRoom,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { id, managingOrganization }) => {
      queryClient.refetchQueries({
        queryKey: managingOrganization?.id
          ? organizationQueryKeys.currentOrganization.current(managingOrganization?.id)
          : organizationQueryKeys.currentOrganization.all,
        type: managingOrganization?.id ? "all" : undefined,
      })
      const message = id ? "Room updated successfully!" : "Room added successfully!"
      displayNotificationSuccess(message)
      // datadogLogs.logger.info(message, { org: managingOrganization?.id })
      onSuccess?.()
    },
    onSettled,
  })

  return { createRoom, isCreating: isPending }
}

export { useCreateRoom }
