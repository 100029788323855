import { useQuery } from "@tanstack/react-query"
import { DetectedIssue, getResources } from "fhir"

import { useClient } from "api"
import { medsQueryKeys } from "../meds_query_keys"

const useDetectedIssue = (filter = "") => {
  const { search } = useClient()
  const queryKey = medsQueryKeys.detectedIssue(filter)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        id: filter,
      })
      const bundle = await search({ endpoint: `DetectedIssue`, filters, signal })

      const detectedIssues = getResources(bundle) as DetectedIssue[]

      return detectedIssues
    },
    meta: { context: { queryKey, filter } },
  })

  return {
    detectedIssues: data ?? [],
    isLoading: isLoading,
  }
}

export { useDetectedIssue }
