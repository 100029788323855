import { ModulesId, SubModule } from "commons/Module"
import { Planconfiguration } from "commons/care-plans"

class MCPlanConfigSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.PLAN_CONFIG
    this.parent = ModulesId.MC
    this.content = <Planconfiguration />
    this.display = "Order Configuration"
  }
}

export { MCPlanConfigSubModule }
