import { useInfiniteQuery } from "@tanstack/react-query"
import { Questionnaire, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { surveysQueryKeys } from "../query-keys"

const useQuestionnaire = () => {
  const { search } = useClient()
  const queryKey = surveysQueryKeys.all

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    QuestionnaireQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _count: "20",
        _page: `${pageParam}`,
        "context:not": "labs",
        status: "active",
      })

      const bundle = await search({ endpoint: `Questionnaire`, filters, signal })

      const questionnaires = getResources(bundle) as Questionnaire[]

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { questionnaires, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey } },
  })

  const { questionnaires, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.questionnaires)
    const count = newData?.length

    return {
      questionnaires: newData,
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    questionnaires,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type QuestionnaireQueryData = { questionnaires: Questionnaire[]; next: number | undefined; total: number }

export { useQuestionnaire }
