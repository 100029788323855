import { Dialog } from "primereact/dialog"
import { FC } from "react"

import { SkeletonLoader } from "commons"
import { LabComboTitle } from "commons/labs"
import { getPDSkuValue } from "commons/utils"
import { getMoneyCurrencyAlt } from "utils"

import { useLabPanels } from "../../../hooks"
import { LabCombo } from "../../../types"

const LabComboDetails: FC<Props> = ({ labCombo, onHide, showPrice, organizationId }) => {
  const { labPanels: tests, isLoadingLabPanels } = useLabPanels(
    organizationId,
    { comboPD: labCombo.combo.id },
    !!labCombo.combo.id,
  )
  return (
    <Dialog
      closable={true}
      header="Laboratory combo details"
      visible={true}
      draggable={false}
      dismissableMask={false}
      style={{ width: "35vw" }}
      onHide={onHide}
    >
      <div className="flex flex-col">
        <div className="flex justify-between text-lg font-semibold text-gray-700 mb-2">
          <LabComboTitle combo={labCombo.combo} />
          {showPrice && (
            <span>
              {getMoneyCurrencyAlt("USD")}
              {(labCombo?.price ?? 0).toFixed(2)}
            </span>
          )}
        </div>
        <div className="flex justify-end font-semibold text-gray-600 mb-4">
          Fee:
          <span className="ml-1">
            {getMoneyCurrencyAlt("USD")}
            {(labCombo?.fee ?? 0).toFixed(2)}
          </span>
        </div>

        <label className="font-semibold">Tests:</label>
        {isLoadingLabPanels ? (
          <SkeletonLoader repeats={2} loaderType="two-lines" />
        ) : (
          <ul className="@container ml-3">
            {tests.map((test, index) => (
              <div key={test.planDefinition.id ?? index} className="flex justify-between items-center">
                <div className="py-2">
                  <div className="text-gray-600 font-semibold text-xs">{test.display}</div>
                  <div className="text-gray-400 text-xs">{`${test.planDefinition.publisher} - ${getPDSkuValue(test.planDefinition.identifier) ?? "no sku value"}`}</div>
                </div>
                <div className="text-gray-600 font-semibold text-xs">
                  {getMoneyCurrencyAlt("USD")}
                  {(test?.price ?? 0).toFixed(2)}
                </div>
              </div>
            ))}
          </ul>
        )}
      </div>
    </Dialog>
  )
}

type Props = {
  labCombo: LabCombo
  showPrice?: boolean
  organizationId: string
  onHide(): void
}

export { LabComboDetails }
