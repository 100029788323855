import { useMutation, useQueryClient } from "@tanstack/react-query"
import { List, ListEntryArray, Parameters } from "fhir"
import pluralize from "pluralize"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { plansQueryKeys } from "../query-keys"

const useResumeCarePlanMRs = (patientId: string, onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const resumeMedicationReq = async ({ mrIds, planId }: { mrIds: string[]; planId: string }) => {
    const list: List = {
      resourceType: "List",
      mode: "working",
      status: "current",
      entry: mrIds.map<ListEntryArray>((id) => ({ item: { id, resourceType: "MedicationRequest" } })),
    }

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "items",
          resource: list,
        },
      ],
    }

    return operationRequest<Parameters>({
      endpoint: "CarePlan",
      method: "POST",
      operation: "reassess-medication",
      id: planId,
      parameters,
    })
  }

  const { mutate: resumeMedicationRequests, isPending } = useMutation({
    mutationFn: resumeMedicationReq,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { mrIds, planId }) => {
      await queryClient.invalidateQueries({ queryKey: plansQueryKeys.details(patientId, planId) })
      const requestLabel = pluralize("request", mrIds.length)
      displayNotificationSuccess(`Medication ${requestLabel} resumed successfully!`)
      // datadogLogs.logger.info(`Medication ${requestLabel} resumed successfully!`, { mrIds })
    },
    onSettled: onSettled,
  })

  return { resumeMedicationRequests, isResuming: isPending }
}

export { useResumeCarePlanMRs }
