import { FC, useEffect, useState } from "react"
import { Dialog } from "primereact/dialog"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWifiSlash } from "@fortawesome/pro-solid-svg-icons"
import { faRefresh } from "@fortawesome/pro-light-svg-icons"

import { Button } from "commons"

import { useCheckNetworkContext } from "../hooks"

const OffLineModal: FC<Props> = ({ reset }) => {
  const { isOnline, retry } = useCheckNetworkContext()

  const [retryInterval, setRetryInterval] = useState(10)
  const [intent, setIntent] = useState(1)
  const [max, setMax] = useState(10)

  useEffect(() => {
    setMax(intent < 3 ? intent * 10 : 30)
    setRetryInterval(intent < 3 ? intent * 10 : 30)
  }, [intent])

  useEffect(() => {
    if (retryInterval === 0) {
      setIntent(intent + 1)
      retry()
    }

    if (retryInterval <= 0) return
    const timeout = setTimeout(() => {
      setRetryInterval(retryInterval - 1)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [retryInterval])

  useEffect(() => {
    return () => {
      reset()
    }
  }, [reset])

  const handleretry = () => {
    retry()
    setIntent(1)
    setRetryInterval(max)
  }

  return (
    <Dialog
      closable={false}
      draggable={false}
      resizable={false}
      visible={!isOnline}
      style={{ width: "30vw" }}
      onHide={() => ({})}
      footer={<></>}
    >
      <div className=" flex flex-col items-center p-2  w-8/9 h-8/9">
        <FontAwesomeIcon icon={faWifiSlash} className="w-12 h-12" />
        <h1 className="text-2xl py-2 font-bold">No internet connection</h1>
        <p className="text-sm text-gray-400 pt-2 pb-4">Please check the internet connection on your device.</p>

        <p className="text-sm text-gray-400 pt-2 pb-4">Retrying in {retryInterval} seconds.</p>

        <Button label="Retry Now" onClick={handleretry} icon={faRefresh} />
      </div>
    </Dialog>
  )
}

type Props = {
  reset(): void
}

export { OffLineModal }
