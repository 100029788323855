import { faExternalLink } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CarePlan, codeableConceptAsString, PlanDefinitionActionArrayActionArray, Procedure, Task } from "fhir"
import { RadioButton } from "primereact/radiobutton"
import { classNames } from "primereact/utils"
import { FC, useEffect, useMemo, useState } from "react"

import { useChartContext } from "chart-view"
import { useAppModuleContext } from "internals"
import { useUpdateTask } from "tasks"
import { getBadgeColor } from "utils"

import { Badge } from "../../../components/Badge"
import { ModulesId } from "../../../Module"
import { CalculatorOutput, CalculatorRecomendations, ProcedureCalculator, ProcedureFormKind } from "../../../procedures"
import { usePlanContext } from "../../hooks"
import { plansQueryKeys } from "../../query-keys"

const CPProcedureDetails: FC<Props> = ({
  carePlan,
  procedure,
  procedureAction,
  candidateTask,
  showProcedureCalculator,
  generatedCalculatorResult,
}) => {
  const { patientId, completedReviews } = usePlanContext()
  const { isModuleActive } = useAppModuleContext()
  const { hasGP } = useChartContext()

  const [pCandidateTask, setpCandidateTask] = useState(candidateTask)
  const [showCalculator, setShowCalculator] = useState(false)
  const [showCandidateOptions, setShowCandidateOptions] = useState(true)

  const { updateTask, isUpdating: isUpdatingTask } = useUpdateTask({
    queryKeysToInvalidate: [plansQueryKeys.details(patientId)],
  })

  useEffect(() => {
    setpCandidateTask(candidateTask)
  }, [candidateTask?.status])

  const allCompletedReviews = useMemo(
    () => pCandidateTask?.dependsOn?.every(({ id }) => completedReviews?.includes(id as string)),
    [pCandidateTask?.dependsOn, completedReviews],
  )

  const procedureActivityIndex = useMemo(
    () =>
      carePlan?.activity?.findIndex(({ outcomeCodeableConcept }) =>
        outcomeCodeableConcept?.[0]?.coding?.some(({ code }) => code === "procedure"),
      ) ?? -1,
    [carePlan.activity],
  )

  const procedureActivity = carePlan.activity?.[procedureActivityIndex]

  const updateCandidate = (isCandidate: boolean) => {
    const updatedTask = { ...(pCandidateTask as Task), ...{ status: isCandidate ? "completed" : "rejected" } }

    setpCandidateTask(updatedTask)
    updateTask(updatedTask)
  }

  const isProcedureModuleActive = isModuleActive(ModulesId.PROCEDURES)
  const disableCandidateOptions = !allCompletedReviews || !!procedure || carePlan.status !== "active" || !hasGP

  useEffect(() => {
    if (procedureActivity?.enabled && pCandidateTask?.status === "completed" && !!generatedCalculatorResult)
      setShowCalculator(true)
  }, [procedureActivity?.enabled, pCandidateTask?.status, generatedCalculatorResult])

  return (
    <div className="flex flex-col flex-1 h-full p-3">
      {!!pCandidateTask && showCandidateOptions && (
        <div className="mb-4 pb-3 flex w-full space-x-10 text-sm mt-4 last:border-none">
          <span className="text-sm text-gray-500 mr-12">{pCandidateTask.description}</span>
          <div key={`is-candidate-${pCandidateTask.id}`} className="flex text-gray-500 items-center">
            <RadioButton
              inputId={`is-candidate-${pCandidateTask.id}`}
              name="Yes"
              value={true}
              radioGroup={`candidate-${pCandidateTask.id}`}
              checked={pCandidateTask.status === "completed"}
              onChange={(e) => updateCandidate(e.value)}
              disabled={disableCandidateOptions || isUpdatingTask}
            />
            <label
              htmlFor={`is-candidate-${pCandidateTask.id}`}
              className={classNames("ml-2", { "cursor-pointer": !disableCandidateOptions })}
            >
              Yes
            </label>
          </div>
          <div key={`not-candidate-${pCandidateTask.id}`} className="flex text-gray-500 items-center">
            <RadioButton
              inputId={`not-candidate-${pCandidateTask.id}`}
              name="No"
              value={false}
              radioGroup={`candidate-${pCandidateTask.id}`}
              checked={pCandidateTask.status === "rejected"}
              onChange={(e) => updateCandidate(e.value)}
              disabled={disableCandidateOptions || isUpdatingTask}
              className="peer"
            />
            <label
              htmlFor={`not-candidate-${pCandidateTask.id}`}
              className={classNames("ml-2", { "cursor-pointer": !disableCandidateOptions })}
            >
              No
            </label>
          </div>
        </div>
      )}

      {procedure ? (
        <div className="flex flex-col gap-6">
          {!!generatedCalculatorResult && (
            <CalculatorRecomendations
              notes={generatedCalculatorResult?.notes}
              recommended={generatedCalculatorResult?.recommended}
              supplements={generatedCalculatorResult?.supplements}
              patientId={patientId}
              planId={carePlan.id}
            />
          )}
          <div className="flex-flex-col">
            <div className="font-medium text-gray-900">Procedure</div>
            <div className="flex justify-between pt-2">
              <div className="flex w-1/2 justify-between">
                <span className="text-sm text-gray-900">{codeableConceptAsString(procedure.code)}</span>
                <Badge className="flex-shrink-0 lowercase" {...getBadgeColor(procedure.status)} />
              </div>
              {procedure?.id && (
                <FontAwesomeIcon
                  icon={faExternalLink}
                  className="cursor-pointer text-gray-500 mr-4"
                  title="Show procedure"
                  onClick={() => showProcedureCalculator(procedure?.id)}
                />
              )}
            </div>
          </div>
        </div>
      ) : isProcedureModuleActive &&
        hasGP &&
        carePlan?.id &&
        carePlan.status === "active" &&
        (pCandidateTask?.status === "completed" || isUpdatingTask) ? (
        <>
          {showCalculator ? (
            <ProcedureCalculator
              carePlanId={carePlan.id as string}
              procedurePdCanonical={procedureAction?.definition?.canonical}
              onFinish={() => {
                setShowCalculator(false)
              }}
              generatedCalculatorResult={generatedCalculatorResult}
              hideCloseButton
              onCurrentFormChanged={(currentFormType) =>
                setShowCandidateOptions(currentFormType === ProcedureFormKind.suggestedMeds)
              }
            />
          ) : (
            <p className="text-sm text-gray-500">
              Awaiting recommendations for this treatment plan. Please answer the required questionnaires and wait for
              laboratory results if applicable.
            </p>
          )}
        </>
      ) : (
        <span className="text-sm text-gray-500 mt-3.5">
          You can't perform a procedure
          {!isProcedureModuleActive
            ? ", practice not allowed to perform procedures or"
            : candidateTask?.status !== "completed"
              ? " when patient isn't a candidate for this"
              : carePlan.status === "revoked"
                ? " on a cancelled"
                : carePlan.status.replace("-", " ")}{" "}
          treatment plan
        </span>
      )}
    </div>
  )
}

type Props = {
  carePlan: CarePlan
  procedureAction?: PlanDefinitionActionArrayActionArray
  procedure?: Procedure
  candidateTask?: Task
  generatedCalculatorResult?: CalculatorOutput
  showProcedureCalculator(cpId?: string): void
  generatedCarePlan?: CarePlan
}

export { CPProcedureDetails }
