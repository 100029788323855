import { useQuery } from "@tanstack/react-query"
import { format, isToday, isYesterday } from "date-fns"
import { Communication, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { formatsByTypes } from "data"

import { communicationQueryKeys } from "../query-keys"

const useUnreadedCommunications = (patientId: string) => {
  const { search } = useClient()
  const queryKey = communicationQueryKeys.unreaded(patientId)

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "in-progress",
        sender: patientId, // In patient-portal change sender to recipient
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Communication`, filters, signal })

      const communications = getResources(bundle) as Communication[]

      return { communications, count: communications.length }
    },
    meta: { context: { queryKey, patientId } },
    refetchInterval: 2000,
    refetchIntervalInBackground: true,
  })

  const { communicationsByDate, communications } = useMemo(() => {
    const newData = data?.communications.reverse() ?? []
    const count = newData?.length

    const initialMap = new Map<string, Communication[]>(
      count ? [[newData[0]?.sent ? getDateLabel(newData[0].sent) : "Today", []]] : [],
    )
    const groupMap = newData.reduce((map, communication) => {
      const dateLabel = communication.sent ? getDateLabel(communication.sent) : "Today"

      const value = map.get(dateLabel)
      if (value) {
        value.push(communication)
      } else {
        map.set(dateLabel, [communication])
      }
      return map
    }, initialMap)

    return {
      communicationsByDate: groupMap
        ? Array.from(groupMap.entries()).map(([date, entries]) => ({
            date,
            communications: entries,
          }))
        : [],
      communications: newData,
      count,
    }
  }, [data])

  return {
    unreadedCommunicationsByDate: communicationsByDate ?? [],
    unreadedCommunications: communications ?? [],
    count: data?.count ?? 0,
    isLoading,
    reloadUnreadCommunications: refetch,
  }
}

const getDateLabel = (dateStr: string) => {
  const date = new Date(dateStr)

  return isToday(date) ? "Today" : isYesterday(date) ? "Yesterday" : format(date, formatsByTypes.ISO_8601_DATE)
}

const useFilterUnreadedComms = (patientId: string, communications: Communication[]) => {
  const { unreadedCount, unreadedCommunications } = useMemo(() => {
    const unreaded = communications.filter((comm) => comm.status === "in-progress" && comm.sender?.id === patientId)
    return { unreadedCount: unreaded.length, unreadedCommunications: unreaded }
  }, [patientId, communications])

  return { unreadedCount, unreadedCommunications }
}

export { useFilterUnreadedComms, useUnreadedCommunications }
