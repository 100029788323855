import { Observation } from "fhir"
import { FC, useId } from "react"

import {
  DataContainerSlideoverForm,
  InfiniteScroll,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  useCrudReducer,
} from "commons"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import { useCreateSocialHistory, useSocialHistory, useUpdateSocialHistory } from "../hooks"
import { SocialHistoryForm } from "./SocialHistoryForm"
import { socialModel } from "./socialHistoryModelBuilder"
import { getInitialValues, sanitize, socialHistoryValidationSchema } from "./validations"

const SocialHistoryContainer: FC = () => {
  const loaderKey = useId()
  const { patientId, patientRef } = usePatientContext()
  const { appSubModules } = useAppModuleContext()

  const { openEncounterRef } = useOpenEncounter(patientId)
  const { reset, add, edit, showSlide, initialValue, isNew } = useCrudReducer({
    defaultEntity: getInitialValues(patientRef, openEncounterRef),
  })

  const { socialHistory, isLoading, hasNextPage, fetchNextPage } = useSocialHistory(patientId)
  const { createSocialHistory } = useCreateSocialHistory(reset)
  const { updateSocialHistory } = useUpdateSocialHistory(reset)

  const onSubmit = (socialHistory: Observation) => {
    const sanitizedHistory = sanitize(socialHistory)
    isNew ? createSocialHistory(sanitizedHistory) : updateSocialHistory(sanitizedHistory)
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  if (isLoading) return loader()

  const deactivate = (socialHistory: Observation) => {
    const sanitizedHistory = sanitize({ ...socialHistory, status: "cancelled" })
    updateSocialHistory(sanitizedHistory)
  }

  return (
    <DataContainerSlideoverForm
      messageDataNotFound="No social history found"
      hasData={!!socialHistory?.length}
      showSlide={showSlide}
      formTitle="Social History"
      formInitialValue={initialValue}
      validationSchema={socialHistoryValidationSchema}
      onSubmit={onSubmit}
      onCancel={reset}
      form={<SocialHistoryForm isEditing={!isNew} />}
      onButtonAddClick={add}
      iconDataNotFound={appSubModules["intake"][ModulesId.SOCIAL_HISTORY].getIcon()}
    >
      <div className="bg-white h-full overflow-auto">
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <StackedListContainer
            data={socialHistory ?? []}
            itemModelBuilder={(item) =>
              socialModel(
                item,
                () => edit(item),
                () => deactivate(item),
              )
            }
          />
        </InfiniteScroll>
      </div>
    </DataContainerSlideoverForm>
  )
}

export { SocialHistoryContainer }
