import { faDiagnoses } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { ConditionsContainer } from "../../../conditions/components/ConditionsContainer"

class ConditionsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.CONDITIONS
    this.display = "Conditions"
    this.parent = ModulesId.INTAKE
    this.icon = faDiagnoses
    this.content = <ConditionsContainer />
  }
}

export { ConditionsSubModule }
