import { faPills } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, ModuleRenderSideType, AppModule } from "commons/Module"

class DosespotModule extends AppModule {
  constructor(workspaceElement: JSX.Element) {
    super()
    this.id = ModulesId.DOSESPOT
    this.display = "SureScripts Rx"
    this.icon = faPills
    this.hideBySetting = true
    this.workspaceElement = workspaceElement
    this.renderSideType = ModuleRenderSideType.WORKSPACE
  }
}

export { DosespotModule }
