import { Reference } from "fhir"

const useLaboratoryLocations = (
  laboratoryLocation: Record<string, string>,
  updateLocationHandler: (location: Reference[] | undefined) => void,
) => {
  const updateLabLocation = (labId?: string) => {
    const locationId = laboratoryLocation[labId as string]
    updateLocationHandler(locationId ? [{ id: locationId, resourceType: "Location" }] : undefined)
  }

  return { updateLabLocation }
}

export { useLaboratoryLocations }
