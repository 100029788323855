import { AccountBETACreditCardArray, Address } from "fhir"

import { creditCardValidationSchema, CreditCardForm } from "account"
import { DialogFormContainer } from "commons"

const OrderCreditCardFormContainer = ({
  creditCard,
  onSubmit,
  onCancel,
  isEditing,
  showForm,
  shippingAddress,
}: Props) => (
  <DialogFormContainer
    showForm={showForm}
    title="Credit card"
    initialValue={creditCard}
    validationSchema={creditCardValidationSchema}
    onSubmit={(cc) => onSubmit(cc as AccountBETACreditCardArray)}
    onCancel={onCancel}
    useFormik
  >
    <CreditCardForm isEditing={isEditing} shippingAddress={shippingAddress} />
  </DialogFormContainer>
)

type Props = {
  isEditing: boolean
  creditCard: AccountBETACreditCardArray
  onSubmit(creditCard: AccountBETACreditCardArray): void
  onCancel(): void
  showForm: boolean
  shippingAddress?: Address
}

export { OrderCreditCardFormContainer }
