import { useQuery } from "@tanstack/react-query"
import { Bundle, getResources, Parameters, Setting, SettingDefinition, SettingItemArray } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { defaultOrgDataSettingCodes, organizationSettingId, OrgDataSettingCode } from "data"

import { commonsQueryKeys } from "../query-keys"

const useDefaultOrgData = (organizationId: string, enabled?: boolean) => {
  const { operationRequest } = useClient()
  const queryKey = commonsQueryKeys.defaultOrgData(organizationId)

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async () => {
      const parameters = {
        resourceType: "Parameters",
        parameter: [
          { name: "code", value: { code: organizationSettingId.defaultOrgdata } },
          { name: "owner", value: { Reference: { id: organizationId, resourceType: "Organization" } } },
        ],
      } as Parameters

      const bundle = await operationRequest<Bundle>({
        endpoint: "Setting",
        method: "POST",
        operation: "resolve",
        parameters,
      })
      const setting = getResources<Setting>(bundle, "Setting")?.[0]
      const definition = getResources<SettingDefinition>(bundle, "SettingDefinition")[0]

      return { setting: setting as Setting | undefined, definition }
    },
    meta: { context: { queryKey, organizationId } },
    enabled,
  })

  const { defaultData } = useMemo(() => {
    let defaultOrgSettings: Record<string, SettingItemArray>

    if (data?.setting?.item) {
      defaultOrgSettings = data.setting.item.reduce(
        (prev, item) => {
          return { ...prev, [item.code?.code as string]: item }
        },
        {} as Record<string, SettingItemArray>,
      )
    } else {
      defaultOrgSettings = (data?.definition?.item ?? []).reduce(
        (prev, item) => ({
          ...prev,
          [item.code?.code as string]: {
            code: item.code,
            label: item.label,
            required: item.required,
            secure: item.secure,
            value: item.default,
          },
        }),
        {},
      )
    }

    const defaultProcedureICD10Codes = JSON.parse(
      defaultOrgSettings[defaultOrgDataSettingCodes.defaultProcedureIcd10]?.value?.string ?? "{}",
    )

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultData: Record<OrgDataSettingCode, any> = { defaultProcedureIcd10: defaultProcedureICD10Codes }

    return { defaultData }
  }, [data])

  return {
    defaultData,
    isLoading,
    refreshDefaultOrgSettings: refetch,
  }
}

export { useDefaultOrgData }
