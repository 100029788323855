const medicationRequestsQueryKeys = {
  orders: (patientId: string, medRequestIds?: string[]) => ["patient-medication-orders", patientId, medRequestIds],
  ordersResources: (patientId: string, statusFilter?: string[], searchText?: string) => [
    "medicationRequests/patient-medication-orders-resources",
    patientId,
    statusFilter,
    searchText,
  ],
  detectedIssue: (filter = "") => ["medicationRequests/detectedIssue", filter],
  monograph: (monographID: string | undefined) => ["medicationRequests/monograph", monographID],
  auditEvents: (organizationId: string, patientId: string, orderId: string) => [
    "patient-medication-order-history",
    organizationId,
    patientId,
    orderId,
  ],
}

export { medicationRequestsQueryKeys }
