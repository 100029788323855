import { PlanDefinitionActionArrayActionArray, Reference } from "fhir"
import { FC } from "react"

import { PractitionerInfo } from "../../../../types"
import { NutrasFormSection } from "../NutrasFormSection"
import { RxFormSection } from "../RxFormSection"

const Medications: FC<Props> = ({ practitionersInfo, openEncounter, requiresNutras, requiresRXs }) => (
  <>
    {requiresRXs && (
      <RxFormSection
        practitionersInfo={practitionersInfo}
        openEncounter={openEncounter}
        contentClassName={{ full: "col-span-6", half: "col-span-3", fullContentHalfItem: "grid grid-cols-6" }}
      />
    )}
    {requiresNutras && (
      <NutrasFormSection
        practitionersInfo={practitionersInfo}
        openEncounter={openEncounter}
        contentClassName={{ full: "col-span-6", half: "col-span-3", fullContentHalfItem: "grid grid-cols-6" }}
      />
    )}
  </>
)

type Props = {
  practitionersInfo: PractitionerInfo[]
  openEncounter?: Reference
  requiresRXs: boolean
  requiresNutras: boolean
  configureActions: Record<string, PlanDefinitionActionArrayActionArray>
  stepId: string
}

export { Medications }
