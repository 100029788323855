import { PlanDefinition } from "fhir"
import { classNames } from "primereact/utils"

import { SignedAzureImage } from "commons"

const LabComboTitle = ({ combo, containerClassName, titleClassName, logoClassName = "w-8 h-8" }: Props) => (
  <div className={classNames("flex items-center gap-1", containerClassName)}>
    {combo?.logo?.[0]?.url && (
      <div className={classNames("rounded-full overflow-hidden", logoClassName)}>
        <SignedAzureImage className={logoClassName} url={combo.logo[0].url} />
      </div>
    )}
    <span className={titleClassName}>{combo?.title ?? "no title"}</span>
  </div>
)

type Props = {
  combo: PlanDefinition
  containerClassName?: string
  titleClassName?: string
  logoClassName?: string
}

export { LabComboTitle }
