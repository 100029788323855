import { KnowledgeListContainer, ModulesId } from "commons"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import { conditionModelBuilder } from "../components/conditionModelBuilder"
import { useConditions } from "../hooks"

const ConditionsKnowledge = () => {
  const { appModules } = useAppModuleContext()
  const { patientId } = usePatientContext()
  const { conditions, isLoading } = useConditions({ patientId, key: "KP" })

  return (
    <KnowledgeListContainer
      isLoading={isLoading}
      dataTitle="conditions"
      data={conditions}
      iconDataNotFound={appModules[ModulesId.CONDITIONS].getIcon()}
      itemModelBuilder={(item) => conditionModelBuilder(item)}
    />
  )
}

export { ConditionsKnowledge }
