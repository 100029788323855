import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Bundle, CarePlan, Reference, getResource } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { plansQueryKeys } from "commons/care-plans"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { mcQueryKeys } from "../query-keys"

const useRunCalculator = (onSuccess: (assessmentId: string) => void) => {
  const { operationRequest, patch, read } = useClient()
  const queryClient = useQueryClient()

  const calculate = async ({
    planId,
    labOrder,
    patientRef,
  }: {
    labOrder: string
    prevLabOrder?: string
    planId?: string
    patientRef: Reference
  }) => {
    const algBundle = await operationRequest<Bundle>({
      endpoint: "ServiceRequest",
      method: "POST",
      operation: "run",
      id: labOrder,
    })
    const assessment = getResource<CarePlan>(algBundle, "CarePlan")

    if (planId && assessment.id) {
      const filter = new URLSearchParams({ _id: planId })
      const oneCPBundle = await read<Bundle>({ endpoint: `Patient/${patientRef.id}/CarePlan`, filters: filter })
      const carePlan = getResource<CarePlan>(oneCPBundle, "CarePlan")

      const mcActivityIndex =
        carePlan?.activity?.findIndex((a) =>
          a.outcomeCodeableConcept?.some(({ coding }) => coding?.some(({ code }) => code === "request-mc")),
        ) ?? -1
      if (carePlan?.activity && mcActivityIndex !== -1) {
        carePlan.activity[mcActivityIndex] = {
          ...carePlan.activity[mcActivityIndex],
          outcomeReference: [
            ...(carePlan.activity[mcActivityIndex].outcomeReference
              ? (carePlan.activity[mcActivityIndex].outcomeReference as Reference[])
              : []),
            {
              id: assessment.id,
              resourceType: "CarePlan",
            } as Reference,
          ],
        }

        await patch("CarePlan", planId, carePlan)
      }
    }

    return assessment.id
  }

  const { mutate: runCalculator, isPending } = useMutation({
    mutationFn: calculate,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (assessmentId, { patientRef }) => {
      await queryClient.invalidateQueries({ queryKey: mcQueryKeys.list(patientRef.id as string) })
      await queryClient.invalidateQueries({
        queryKey: plansQueryKeys.assessments(patientRef.id as string),
        refetchType: "all",
      })
      assessmentId && onSuccess(assessmentId)
      displayNotificationSuccess("Assessment report ready!")
      // datadogLogs.logger.info(`Assessment ${assessmentId} created successfully!`)
    },
  })

  return { runCalculator, isEvaluating: isPending }
}

export { useRunCalculator }
