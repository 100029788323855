const emailQueryKeys = {
  all: ["patient/communicationRequests"] as const,
  list: (patientId: string, textFilter?: string, statusFilter?: string[], statusReasonFilter?: string[]) => [
    "patient/communicationRequests",
    patientId,
    textFilter,
    statusFilter,
    statusReasonFilter,
  ],
}

export { emailQueryKeys }
