import { Timing } from "fhir"

import { unitOfTime } from "data"
import { SYSTEM_VALUES } from "system-values"

import { DispenseIntervalOption } from "./types"

const treatmentFrequencies = [
  { label: "Once / day", value: "oncePerDay" },
  { label: "Twice / day", value: "twicePerDay" },
  { label: "Three times / day", value: "threePerDay" },
  { label: "Four times / day", value: "fourPerDay" },
  { label: "Every morning", value: "everyMorning" },
  { label: "Every night", value: "everyNight" },
  { label: "Once / week", value: "oncePerWeek" },
  { label: "Twice / week", value: "twicePerWeek" },
  { label: "Three times / week", value: "threePerWeek" },
  { label: "Four times / week", value: "fourPerWeek" },
]

const dispenseInterval: DispenseIntervalOption[] = [
  {
    label: "One time",
    value: {
      value: 0,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Monthly",
    value: {
      value: 1,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 2 months",
    value: {
      value: 2,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 3 months",
    value: {
      value: 3,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 4 months",
    value: {
      value: 4,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 5 months",
    value: {
      value: 5,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
  {
    label: "Every 6 months",
    value: {
      value: 6,
      code: unitOfTime[5].code,
      system: unitOfTime[5].system,
      unit: unitOfTime[5].display.toLowerCase(),
    },
  },
]

enum MEDICATIONS_REGULATIONS_CODE {
  MAX_DISPENSE = "max-dispense",
  RESTRICTED_SHIPPING = "shipping-not-allowed",
  MAX_DAYS_SUPPLY = "max-days-supply",
}

const OVERNIGHT_FEE_CODES = ["rush-order", "2-day-shipping", "ups-2-day-free-shipping", "overnight-shipping"]

const dosageTimingRepeats = [
  {
    id: "oncePerDay",
    code: {
      coding: [
        {
          code: "QD",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "twicePerDay",
    code: {
      coding: [
        {
          code: "BID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 2,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "threePerDay",
    code: {
      coding: [
        {
          code: "TID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 3,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "fourPerDay",
    code: {
      coding: [
        {
          code: "QID",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 4,
      period: 1,
      periodUnit: "d",
    },
  },
  {
    id: "everyMorning",
    code: {
      coding: [
        {
          code: "AM",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "d",
      when: ["MORN"],
    },
  },
  {
    id: "everyNight",
    code: {
      coding: [
        {
          code: "BED",
          system: SYSTEM_VALUES.V3_GTSABB,
        },
      ],
    },
    repeat: {
      frequency: 1,
      period: 1,
      periodUnit: "d",
      when: ["NIGHT"],
    },
  },
] as Timing[]

const DOSE_RANGE_REGEX = /\d\d?-?/g

export {
  dispenseInterval,
  dosageTimingRepeats,
  DOSE_RANGE_REGEX,
  MEDICATIONS_REGULATIONS_CODE,
  OVERNIGHT_FEE_CODES,
  treatmentFrequencies,
}
