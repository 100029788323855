import { faExternalLink } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CarePlan, codeableConceptAsString, Coding, Observation, ServiceRequest } from "fhir"
import { Form, Formik, FormikValues } from "formik"
import { FC, useMemo } from "react"

import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { getBadgeColor, getLabOrderIdentifier, getLoincCode } from "utils"

import { Badge } from "../../../components/Badge"
import { Button } from "../../../components/Buttons"
import { getStatus } from "../../../labs"
import { getObservationInitialValue, LabDataInputFieldList, OptionalFieldDefinition } from "../../../procedures"
import { useUpdateAdditionalLabData } from "../../hooks"
import { CPLabResultsDetails } from "./CPLabResultsDetails"

const CPLabsDetails: FC<Props> = ({
  carePlan,
  serviceRequest,
  isOnlySurvey,
  patientId,
  availableExtraOD,
  extraResults,
  extraResultDRid,
  showOrder,
}) => {
  const { loggedInPractitionerRoleRef } = useOrganizationContext()
  const { patientRef } = usePatientContext()
  const labStatus = serviceRequest && getStatus(serviceRequest)

  const { updateLabData, isPending } = useUpdateAdditionalLabData()

  const { initialValues, fields, initialFields } = useMemo(
    () =>
      Object.values(availableExtraOD ?? {}).reduce<{
        initialValues: Record<string, string | number>
        initialFields: OptionalFieldDefinition[]
        fields: OptionalFieldDefinition[]
      }>(
        (acc, od) => {
          const field = getLoincCode(od)
          const label = codeableConceptAsString({ coding: od })
          const value = extraResults?.[field]?.value?.Quantity?.value ?? ""
          return {
            initialValues: { ...acc.initialValues, [field]: value },
            fields: carePlan.status !== "revoked" ? [...acc.fields, { field, label }] : acc.fields,
            initialFields: value !== "" ? [...acc.initialFields, { field, label }] : acc.initialFields,
          }
        },
        { initialValues: {}, fields: [], initialFields: [] },
      ),
    [extraResults, availableExtraOD],
  )

  const canAddResults = !initialFields.length && carePlan.status !== "revoked"

  const onSubmit = async ({ labData }: AditionalLabResult) => {
    const codes = Object.keys(labData)
    const newObs = Object.entries(labData).reduce<Observation[]>((acc, [key, value]) => {
      if (!value || !availableExtraOD) return acc
      return [
        ...acc,
        extraResults?.[key]
          ? {
              ...extraResults[key],
              value: {
                ...extraResults[key].value,
                Quantity: {
                  ...extraResults[key].value?.Quantity,
                  value: value,
                },
              },
            }
          : getObservationInitialValue(
              patientRef,
              availableExtraOD[key] as Coding[],
              value,
              loggedInPractitionerRoleRef,
            ),
      ]
    }, [])
    const oldObs =
      extraResults &&
      Object.values(extraResults).reduce<Observation[]>((acc, o) => {
        const code = getLoincCode(o.code.coding)
        return codes.includes(code) ? acc : [...acc, o]
      }, [])
    await updateLabData({ carePlan, patientRef, newObs, oldObs, drId: extraResultDRid })
  }

  return (
    <div className="p-3">
      <div className="flex justify-between">
        <span className="text-gray-700">
          {serviceRequest?.performer?.[0].display}
          {labStatus?.code === "draft" || !serviceRequest
            ? ""
            : ` (${getLabOrderIdentifier(serviceRequest as ServiceRequest, [])})`}
        </span>
        <div className="flex justify-end">
          <div className="flex w-fit justify-between gap-8">
            <Badge
              {...getBadgeColor(labStatus?.code === "revoked" ? "cancelled" : labStatus?.display ?? "unspecified")}
              className="h-min lowercase"
            />
            {serviceRequest?.id && (
              <FontAwesomeIcon
                icon={faExternalLink}
                className="cursor-pointer text-gray-500 mr-4"
                title="Show order"
                onClick={() => showOrder(serviceRequest.id as string)}
              />
            )}
          </div>
        </div>
      </div>
      {!isOnlySurvey && <CPLabResultsDetails patientId={patientId} labOrderId={serviceRequest?.id} />}
      {(!!fields.length || !!initialFields.length) && (
        <Formik initialValues={{ labData: initialValues } as AditionalLabResult} onSubmit={onSubmit} enableReinitialize>
          {({ values, isSubmitting }) => (
            <Form className="mt-2 border-t pt-3">
              <LabDataInputFieldList
                field="labData"
                label="Additional Lab Data"
                addFieldLabel="Add result"
                fieldDefinitions={canAddResults ? fields : []}
                initialFields={initialFields}
                disabled={!canAddResults}
              />
              {canAddResults && (
                <div className="flex justify-end gap-4">
                  <Button
                    label="Save"
                    type="submit"
                    loading={isSubmitting || isPending}
                    disabled={Object.values(values.labData).every((v) => !v)}
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

type AditionalLabResult = {
  labData: FormikValues
}

type Props = {
  carePlan: CarePlan
  serviceRequest?: ServiceRequest
  isOnlySurvey: boolean
  patientId: string
  showOrder(srId: string): void
  availableExtraOD?: Record<string, Coding[]>
  extraResults?: Record<string, Observation>
  extraResultDRid?: string
}

export { CPLabsDetails }
