import { MenuItem } from "primereact/menuitem"
import { Message } from "primereact/message"
import { classNames } from "primereact/utils"
import React, { startTransition, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import {
  AppModule,
  Button,
  MessageTextType,
  ModulesId,
  SearchWithStatus,
  SimpleBreadCrumb,
  SubModule,
  SubModulesIndexTypes,
} from "commons"
import { isValidPractitioner } from "commons/utils"
import { AppSubModules, useAppModuleContext } from "internals"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { ChartProvider } from "../context"
import { useModulesNavigation } from "../hooks"
import { SearchProps } from "../types"
import "./ChartViewContainer.css"
import { KnowledgePanel } from "./KnowledgePanel"
import { ModuleHub, ModuleWarningMessage } from "./ModuleHub"

const ChartViewContainer: React.FC<Props> = ({ disabledWarningMessages }) => {
  const { patient } = usePatientContext()
  const { organizationPractitionersInfo } = useOrganizationContext()
  const { activeWorkspaceModules, activeDynamicModules } = useAppModuleContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeModule, setActiveModule] = useState<AppModule | undefined>(undefined)
  const [activeSubModule, setActiveSubModule] = useState<SubModule | null>(null)
  const [alertBannerMessage, setAlertBannerMessage] = useState<MessageTextType>(undefined)
  const [searchData, setSearchData] = useState<SearchProps>({ showSearch: false })
  const hasGP = useMemo(
    () => isValidPractitioner(organizationPractitionersInfo, patient?.generalPractitioner?.[0]?.id),
    [organizationPractitionersInfo, patient],
  )

  const closeWorkspacePanel = useCallback(() => {
    const params = new URLSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
    })

    setSearchParams(params)
    setSearchData({ showSearch: false })
  }, [searchParams, setSearchParams])

  useLayoutEffect(() => {
    let module
    const currentView = searchParams.get("view")
    const subview = searchParams.get("subview")

    if (currentView) {
      module =
        activeWorkspaceModules.find(
          (m) =>
            m.getId() === currentView &&
            (disabledWarningMessages[currentView]?.isLoading || !disabledWarningMessages[currentView]?.message),
        ) ??
        activeDynamicModules.find((m) => m.getId() === currentView && !disabledWarningMessages[currentView]?.message)

      if (!module) {
        closeWorkspacePanel()
      }
    }

    if (module?.getId() !== activeModule?.getId()) {
      setSearchData({
        showSearch: false,
      })
      const subModule = subview ? AppSubModules[module?.getId() as SubModulesIndexTypes]?.[subview] ?? null : null
      setActiveSubModule(subModule)
    } else {
      setSearchData((searchData) => ({
        ...searchData,
      }))
    }

    setActiveModule(module)
  }, [activeWorkspaceModules, closeWorkspacePanel, searchParams, activeModule, disabledWarningMessages])

  useEffect(() => {
    switch (activeModule?.getId()) {
      case ModulesId.MEDICATIONR:
      case ModulesId.MC:
      case ModulesId.PLANS:
      case ModulesId.PROCEDURES:
        setAlertBannerMessage(
          !hasGP && (
            <div className="flex flex-col">
              <div>Missing information:</div>
              <div className="text-sm">
                This patient's general practitioner isn't available or doesn't meet security requirements. You are only
                able to see the created resources.
              </div>
            </div>
          ),
        )
        break
      default:
        setAlertBannerMessage(undefined)
        break
    }
  }, [hasGP, setAlertBannerMessage, activeModule])

  const goBack = useCallback(() => {
    const params = new URLSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
      ...(searchParams.has("view") ? { view: searchParams.get("view") as string } : {}),
    })

    setSearchParams(params)
    setSearchData({ showSearch: false })
    setActiveSubModule(null)
  }, [searchParams, setSearchParams])

  const getBreadCrumbItems = useCallback(() => {
    const items: Partial<MenuItem>[] = activeModule
      ? [{ label: activeModule.getDisplay(), command: activeSubModule ? goBack : undefined }]
      : []

    return activeSubModule ? [...items, { label: activeSubModule.getDisplay() }] : items
  }, [activeModule, activeSubModule, goBack])

  const showSearchData = ({ showSearch, ...props }: SearchProps) =>
    setSearchData({ ...props, showSearch: showSearch ?? true })

  const { currentModuleData, currentModuleOrigin, showModule, showSubModule, navigateToOriginModule, clearModuleData } =
    useModulesNavigation(setActiveSubModule)

  const closeModule = (clearData: boolean = true) => {
    if (clearData) clearModuleData()
    setActiveModule(undefined)
    setActiveSubModule(null)
    closeWorkspacePanel()
  }

  return (
    <ChartProvider
      activeSubModule={activeSubModule}
      searchData={searchData}
      hasGP={hasGP}
      setActiveSubModule={setActiveSubModule}
      setAlertBannerMessage={setAlertBannerMessage}
      setSearchData={showSearchData}
      showSubModule={showSubModule}
      showModule={showModule}
      currentModuleData={currentModuleData}
      clearModuleData={clearModuleData}
      navigateToOriginModule={navigateToOriginModule}
      closeModule={closeModule}
    >
      <div className="chart-view-container flex flex-row flex-1 overflow-hidden">
        <KnowledgePanel />
        <div
          className={classNames(
            "flex flex-col flex-1  relative ",
            searchParams.has("kp") && !!activeSubModule ? "w-3/5" : "w-[95%]",
          )}
        >
          {activeModule ? (
            <>
              <div className="flex flex-row justify-between items-center h-16 p-3 pl-[1.4375rem]">
                <SimpleBreadCrumb items={getBreadCrumbItems()} goHome={closeModule} />
                <div className="flex items-center gap-2">
                  {searchData.showSearch && (
                    <SearchWithStatus
                      placeholder={searchData.placeholder ?? "Search"}
                      options={searchData.statusOptions}
                      selectedItems={searchData.selectedStatus}
                      onStatusCheck={(s) => setSearchData({ ...searchData, selectedStatus: s })}
                      onSearch={(filter) => {
                        startTransition(() => {
                          setSearchData({ ...searchData, filter: filter ?? "" })
                        })
                      }}
                    />
                  )}
                  {!!currentModuleOrigin && (
                    <Button
                      label="Go back"
                      className="shrink-0"
                      buttonStyle="outlined"
                      size="sm"
                      onClick={navigateToOriginModule}
                    />
                  )}
                </div>
              </div>
              {alertBannerMessage && (
                <div className="sticky">
                  <Message className="w-full" severity="warn" text={alertBannerMessage} />
                </div>
              )}
              <div className="flex flex-col flex-1 overflow-hidden">{activeModule.renderWorkspace()}</div>
            </>
          ) : (
            <ModuleHub disabledWarningMessages={disabledWarningMessages} />
          )}
        </div>
      </div>
    </ChartProvider>
  )
}

type Props = {
  disabledWarningMessages: Record<string, ModuleWarningMessage>
}

export { ChartViewContainer }
