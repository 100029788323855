import { InputField, CodeableConceptField, EmailField, AddressField, GenderField } from "commons"
import { contactRelationship } from "data"

const PatientContactForm = () => (
  <div className="relative p-fluid grid gap-4">
    <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-3 mb-5">
      <legend>Basic Information</legend>

      <InputField field={`name.given[0]`} label="First Name" />
      <InputField field={`name.family`} label="Last Name" />

      <GenderField field="gender" label="Biological Sex" className="col-span-2" />

      <CodeableConceptField
        field={`relationship[0]`}
        label="Relationship"
        codes={contactRelationship}
        className="col-span-2"
      />

      <EmailField field={`telecom[0].value`} label="Email" className="col-span-2" />
      <InputField
        type="tel"
        mask="+1 (999) 999-9999"
        unmask={true}
        field={`telecom[1].value`}
        label="Phone"
        className="col-span-2"
      />
    </fieldset>

    <fieldset className="relative p-fluid grid grid-cols-2 gap-4 p-3 mb-5">
      <legend>Address</legend>
      <AddressField parentFieldName="address" />
    </fieldset>
  </div>
)

export { PatientContactForm }
