import { useQuery } from "@tanstack/react-query"
import { compareDesc } from "date-fns"
import {
  DiagnosticReport,
  DocumentReference,
  getResource,
  getResources,
  getResourcesByTypeAsIndex,
  humanNameAsString,
  Invoice,
  Money,
  Observation,
  PlanDefinition,
  QuestionnaireResponse,
  ServiceRequest,
  Specimen,
  Task,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { BILLING_TYPES_CODES, ServiceRequestCategory } from "data"
import {
  convertIdentifiersToCodings,
  getBasePrice,
  getCidIdentifier,
  getCommonCode,
  getLabOrderIdentifier,
  getServiceRequestBillingType,
  mergeSort,
  sumPrice,
} from "utils"

import { useCIDQueryFunction } from "../../hooks"
import { PractitionerInfo } from "../../types"
import { LAB_TASK_CODE, revocableOrderStatus, revocableTaskStatus } from "../data"
import { LaboratoryOrder, LaboratoryOrderPanel } from "../types"
import { getStatus } from "../utils"

const labOrderDetailQueryKey = {
  details: (labOrderId: string) => ["laboratoryOrderDetails", labOrderId],
}

const useLaboratoryOrder = (
  organizationId: string,
  laboratoryOrderId: string,
  patientId: string,
  practitionersInfo: PractitionerInfo[],
  isAdminUser?: boolean,
) => {
  const { search, getSignedUrl } = useClient()
  const queryKey = labOrderDetailQueryKey.details(laboratoryOrderId as string)

  const getChargeItemDefinitions = useCIDQueryFunction()

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _query: "lab-order-details",
        _id: laboratoryOrderId,
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/ServiceRequest`, filters, signal })
      const serviceRequests = getResources<ServiceRequest>(bundle, "ServiceRequest")

      const { orderRoot, ordersSplited } = serviceRequests.reduce(
        (prev, order) => {
          const categories = order.category
          if (
            categories?.some(({ coding }) => coding?.[0]?.code === ServiceRequestCategory.LAB_ORDER) &&
            categories?.every(({ coding }) => coding?.[0]?.code !== ServiceRequestCategory.LAB_ORDER_SPLIT)
          ) {
            return { ...prev, orderRoot: order }
          }

          if (categories?.some(({ coding }) => coding?.[0]?.code === ServiceRequestCategory.LAB_ORDER_SPLIT)) {
            return { ...prev, ordersSplited: [...prev.ordersSplited, order] }
          }

          return prev
        },
        { orderRoot: {} as ServiceRequest, ordersSplited: [] as ServiceRequest[] },
      )

      if (!orderRoot) {
        throw new Error("Not found", { cause: { name: "404", message: "Not laboratory order found" } })
      }

      const planDefinitions = getResources<PlanDefinition>(bundle, "PlanDefinition")
      const tasks = getResources<Task>(bundle, "Task")
      const invoices = getResources<Invoice>(bundle, "Invoice")
      const specimens = getResources<Specimen>(bundle, "Specimen")
      const questionnaireResponses = getResources<QuestionnaireResponse>(bundle, "QuestionnaireResponse")
      const codes = convertIdentifiersToCodings(planDefinitions)
      const document = getResource<DocumentReference>(bundle, "DocumentReference")

      const billingType = getServiceRequestBillingType(orderRoot)
      const orderIdentifier = getLabOrderIdentifier(orderRoot, ordersSplited)

      const chargeItemDefinitions = await getChargeItemDefinitions(
        organizationId,
        billingType === BILLING_TYPES_CODES.BILL_PATIENT
          ? { billToPatientCIDs: codes }
          : { billToPracticeOrInsuranceCIDs: codes },
      )

      const diagnosticReports = getResources<DiagnosticReport>(bundle, "DiagnosticReport")
      const sortedDR = mergeSort(diagnosticReports?.filter(({ issued }) => !!issued) ?? [], "issued", (a, b) =>
        compareDesc(new Date(a), new Date(b)),
      )
      const newestDR = sortedDR.filter((dr) => !!dr.presentedForm?.[0]?.url)?.[0]

      const indexedObservations = getResourcesByTypeAsIndex<Observation>(bundle, "Observation")
      const sortedObservations = newestDR?.result?.map(({ id }) => indexedObservations[id ?? ""]) ?? []
      const groupedObservations = sortedObservations.reduce<Record<string, Observation[]>>((prev, observation) => {
        const panelRef = observation.basedOn?.find(({ resourceType, id }) => resourceType === "ServiceRequest" && id)

        if (panelRef?.id) {
          return { ...prev, [panelRef.id]: [...(prev[panelRef.id] ?? []), observation] }
        }

        return prev
      }, {})

      let requisition = ""
      if (document?.content?.[0]?.attachment?.url) {
        const signedUrl = await getSignedUrl(document?.content?.[0]?.attachment?.url)
        requisition = signedUrl.url
      }

      return {
        order: orderRoot,
        orderIdentifier,
        serviceRequests,
        planDefinitions,
        diagnosticReports: sortedDR,
        observations: Object.values(indexedObservations),
        chargeItemDefinitions,
        tasks,
        invoices,
        specimens,
        questionnaireResponses,
        groupedObservations,
        requisition,
        sortedObservations,
        indexedObservations,
        diagnosticReport: newestDR,
      }
    },
    meta: { context: { queryKey, laboratoryOrderId } },
    enabled: !!laboratoryOrderId,
  })

  const laboratoryOrder = useMemo(() => {
    const practs = practitionersInfo.reduce<Record<string, string>>((acc, cur) => {
      if (cur.practitionerRole?.id) {
        return {
          ...acc,
          [cur.practitionerRole.id]: humanNameAsString(cur.practitioner?.name?.[0]) ?? "unspecified name",
        }
      }

      return acc
    }, {})

    const requester = practs[data?.order?.requester?.id as string] ?? "unspecified"

    const defaultLaboratoryPanels = {
      laboratoryPanels: new Array<LaboratoryOrderPanel>(),
      totalPrice: { currency: "USD", value: 0 },
    }

    const indexedQrs = data?.questionnaireResponses.reduce<Record<string, QuestionnaireResponse>>((acc, qr) => {
      return { ...acc, [qr.id as string]: qr }
    }, {})

    const planBased = data?.order?.basedOn?.some((r) => r.resourceType === "CarePlan") ?? false
    const orderLabsTask = data?.tasks.find((task) => task.code?.coding?.[0].code === LAB_TASK_CODE)
    const labOrderStatus = data?.order && getStatus(data?.order)
    const isAdminRevocable =
      !!isAdminUser && !!orderLabsTask && !planBased && revocableOrderStatus.includes(labOrderStatus?.code as string)

    const revocable =
      !planBased &&
      (labOrderStatus?.code === "draft" ||
        (labOrderStatus?.code !== "revoked" && revocableTaskStatus.includes(orderLabsTask?.status as string)))

    const getPanelData = (sr: ServiceRequest, billingType: BILLING_TYPES_CODES) => {
      const panelInstantiateCanonical = sr.instantiatesCanonical?.[0] ?? ""
      const [pdUrl, pdVersion] = panelInstantiateCanonical.split("|")
      const planDefinition = data?.planDefinitions.find((pd) => pd.url === pdUrl && pd.version === pdVersion)
      const isNotRecognizedExtraPanel = sr.category?.some(({ coding }) =>
        coding?.some(({ code }) => code === ServiceRequestCategory.LAB_ORDER_PANEL_EXTRA),
      )

      let cidPrice: Money | undefined = undefined
      if (!isNotRecognizedExtraPanel) {
        const codes = convertIdentifiersToCodings(planDefinition ? [planDefinition] : [])

        const cid =
          billingType === BILLING_TYPES_CODES.BILL_PATIENT
            ? data?.chargeItemDefinitions.billToPatientCIDs?.[getCidIdentifier(getCommonCode({ codes }))]
            : data?.chargeItemDefinitions.billToPracticeOrInsuranceCIDs?.[getCidIdentifier(getCommonCode({ codes }))]
        cidPrice = getBasePrice(cid?.propertyGroup?.[0].priceComponent)?.amount
      }

      const panelQrs = sr.supportingInfo?.flatMap((qr) =>
        indexedQrs?.[qr.id as string] ? indexedQrs?.[qr.id as string] : [],
      )

      let observations: Observation[] = []

      if (sr.id) {
        observations = data?.groupedObservations?.[sr.id] ?? []
      }

      const labPanel: LaboratoryOrderPanel = {
        profile: sr,
        price: cidPrice,
        observations,
        questionnairesResponses: panelQrs,
        planDefinition,
      }

      return {
        labPanel,
        panelPrice: cidPrice,
      }
    }

    const indexedServiceRequests = data?.serviceRequests.reduce<Record<string, ServiceRequest>>((acc, sr) => {
      return { ...acc, [sr.id as string]: sr }
    }, {})
    const comboSr = data?.serviceRequests.find(
      (sr) => sr.category?.[0].coding?.[0].code === ServiceRequestCategory.LAB_ORDER_COMBO,
    )
    const orderBillingType = getServiceRequestBillingType(data?.order)
    const comboData = comboSr ? getPanelData(comboSr, orderBillingType) : undefined
    const uniqueSRs = new Set<string>()
    const totalPrice = { currency: "USD", value: 0 }

    const comboPanels = comboSr?.basedOn
      ?.reduce((acc, { id }) => {
        const sr = indexedServiceRequests?.[id as string]
        if (sr?.id) uniqueSRs.add(sr.id)

        return sr ? [...acc, getPanelData(sr, orderBillingType).labPanel] : acc
      }, new Array<LaboratoryOrderPanel>())
      .toSorted((a, b) => a.planDefinition?.title?.localeCompare(b.planDefinition?.title as string) ?? 1)

    const orderCombo =
      comboData && comboSr && comboPanels
        ? {
            laboratoryCombo: comboSr,
            panels: comboPanels ?? [],
            price: comboData.panelPrice,
            planDefinition: comboData.labPanel.planDefinition,
          }
        : undefined

    if (orderCombo) {
      totalPrice.value = sumPrice(totalPrice.value, orderCombo.price?.value ?? 0).sum.toNumber()
    }

    const { laboratoryPanels: laboratoryPanelsWithResults, totalPrice: totalPriceWithResults } =
      data?.diagnosticReport?.result?.reduce((prev, { id }) => {
        const observation = data?.indexedObservations[id as string]
        const srId = observation.basedOn?.[0]?.id as string
        const alreadyAdded = uniqueSRs.has(srId)

        if (alreadyAdded) return prev
        uniqueSRs.add(srId)

        const sr = indexedServiceRequests?.[srId]

        if (sr && sr.id && sr.category?.[0].coding?.[0].code === ServiceRequestCategory.LAB_ORDER_PANEL) {
          const panelData = getPanelData(sr, orderBillingType)

          return {
            laboratoryPanels: [...prev.laboratoryPanels, panelData.labPanel],
            totalPrice: {
              currency: "USD",
              value: sumPrice(prev.totalPrice.value, panelData.panelPrice?.value ?? 0).sum.toNumber(),
            },
          }
        }
        return prev
      }, defaultLaboratoryPanels) ?? defaultLaboratoryPanels

    const { laboratoryPanels: laboratoryPanelsWithoutResuls, totalPrice: totalPriceWithoutResults } =
      Object.entries(indexedServiceRequests ?? {}).reduce((prev, [srId, sr]) => {
        if (uniqueSRs.has(srId)) {
          return prev
        }
        if (sr.id && sr.category?.[0].coding?.[0].code === ServiceRequestCategory.LAB_ORDER_PANEL) {
          const panelData = getPanelData(sr, orderBillingType)

          return {
            laboratoryPanels: [...prev.laboratoryPanels, panelData.labPanel],
            totalPrice: {
              currency: "USD",
              value: sumPrice(prev.totalPrice.value, panelData.panelPrice?.value ?? 0).sum.toNumber(),
            },
          }
        }
        return prev
      }, defaultLaboratoryPanels) ?? defaultLaboratoryPanels

    const laboratoryPanels = [...laboratoryPanelsWithResults, ...laboratoryPanelsWithoutResuls].toSorted(
      (a, b) => a.planDefinition?.title?.localeCompare(b.planDefinition?.title as string) ?? 1,
    )

    /* Add extra panels price to total price */
    totalPrice.value += sumPrice(totalPriceWithResults.value, totalPriceWithoutResults.value).sum.toNumber()

    const laboratoryOrder: LaboratoryOrder = {
      order: data?.order as ServiceRequest,
      orderIdentifier: data?.orderIdentifier ?? "unavailable ID",
      panels: laboratoryPanels,
      billingType: orderBillingType,
      panelsCount: laboratoryPanels.length ?? 0,
      price: totalPrice,
      requester,
      observations: data?.observations ?? [],
      presentedForm: data?.diagnosticReport?.presentedForm?.[0]?.url ?? "",
      bloodDrawnInOffice: !!data?.order.specimen,
      specimenDate: data?.specimens?.[0]?.receivedTime,
      revocable: isAdminRevocable || revocable,
      combo: orderCombo,
      previousResults: data?.diagnosticReports,
    }

    return laboratoryOrder
  }, [data, practitionersInfo])

  return {
    laboratoryOrder,
    diagnosticReport: data?.diagnosticReports?.[0],
    tasks: data?.tasks ?? [],
    invoice: data?.invoices?.[0],
    requisition: data?.requisition,
    isLoading,
    observations: data?.sortedObservations,
  }
}

export { labOrderDetailQueryKey, useLaboratoryOrder }
