import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AllergyIntolerance } from "fhir"

import {
  AllergiesForm,
  allergyModelBuilder,
  allergyValidationSchema,
  getInitialValues,
  sanitize,
  useAllergies,
  useCreateAllergy,
} from "allergies"
import {
  AddFieldArrayItemButton,
  DialogFormContainer,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  useCrudReducer,
} from "commons"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

const PatientAllergies = () => {
  const { patientId, patientRef } = usePatientContext()
  const { appModules } = useAppModuleContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { showSlide, initialValue, reset, add } = useCrudReducer({
    defaultEntity: getInitialValues(patientRef, openEncounterRef),
  })

  const { allergies, isLoading } = useAllergies(patientId)
  const { createAllergy } = useCreateAllergy(reset)

  const onSubmit = (allergy: AllergyIntolerance) => createAllergy(sanitize(allergy))

  return (
    <div className="flex flex-col">
      <div className="flex flex-col border-t mt-4 pt-4">
        <span className="text-gray-900">Patient allergies</span>
        {isLoading ? (
          <SkeletonLoader repeats={2} loaderType="two-lines" />
        ) : (
          <div className="flex flex-col flex-1 overflow-y-auto bg-white">
            {allergies?.length ? (
              <StackedListContainer data={allergies} itemModelBuilder={(item) => allergyModelBuilder(item)} />
            ) : (
              <div className="text-center m-auto">
                <FontAwesomeIcon icon={appModules[ModulesId.ALLERGY].getIcon()} size="2x" className="text-slate-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No allergies found</h3>
              </div>
            )}
            <AddFieldArrayItemButton label="Add New Allergy" onClick={add} />
          </div>
        )}
        <DialogFormContainer
          title="Add New Allergy"
          showForm={showSlide}
          onSubmit={onSubmit}
          onCancel={reset}
          initialValue={initialValue}
          validationSchema={allergyValidationSchema}
          useFormik
        >
          <AllergiesForm />
        </DialogFormContainer>
      </div>
    </div>
  )
}

export { PatientAllergies }
