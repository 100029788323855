import { faPencil, faPills } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ConfirmDialog } from "primereact/confirmdialog"
import { MenuItem } from "primereact/menuitem"
import { useReducer } from "react"
import { useSearchParams } from "react-router-dom"

import { MedicationOrderListItem, usePrescriptionMrOrder } from "commons/meds"
import { MedicationRequestOrderData } from "commons/types"

const MedicationOrdersList = ({ mrOrderData }: Props) => {
  const [params, setParams] = useSearchParams()
  const { selectedOrder, selectOrder } = useReducerState()

  const { getPrescription, isGettingPrescription } = usePrescriptionMrOrder()

  const goToOrderDetails = (orderId: string) => {
    params.append("order", orderId)
    setParams(params)
  }

  const goToEditOrder = (orderId: string) => {
    params.delete("subview")
    params.append("edit-order", orderId)
    setParams(params)
  }

  const onPrescription = (order: MedicationRequestOrderData) => {
    selectOrder(order)
    getPrescription({ serviceRequestId: order.serviceRequest.id as string })
  }

  const activeActions = (mrOrderData: MedicationRequestOrderData): MenuItem[] => [
    {
      label: "Edit",
      icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
      disabled: !mrOrderData.serviceRequest.id || !mrOrderData.invoices?.length || !mrOrderData.isEditable,
      command: () => goToEditOrder(mrOrderData.serviceRequest.id!),
    },
    {
      label: "Prescription",
      icon: <FontAwesomeIcon icon={faPills} size="sm" className="mr-2" />,
      command: () => onPrescription(mrOrderData),
    },
  ]

  return (
    <>
      <div className="overflow-hidden bg-white">
        <ul className="divide-y divide-gray-200">
          {mrOrderData.map((orderData) => (
            <MedicationOrderListItem
              key={orderData.serviceRequest.id}
              mrOrderData={orderData}
              onItemClicked={() => goToOrderDetails(orderData.serviceRequest.id as string)}
              isLoading={selectedOrder?.serviceRequest.id === orderData.serviceRequest.id && isGettingPrescription}
              dropdownMenuItems={activeActions}
            />
          ))}
        </ul>
      </div>
      <ConfirmDialog />
    </>
  )
}

const initialState: State = {
  showReschedule: false,
  rescheduleDate: new Date(),
  showCancel: false,
}

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type:
      | "select-order"
      | "show-reschedule-dialog"
      | "hide-reschedule-dialog"
      | "update-reschedule-date"
      | "show-prescription"
      | "hide-prescription"
      | "show-cancel-dialog"
      | "hide-cancel-dialog"
      | "update-cancel-reason"
    payload?: string | Date | boolean | MedicationRequestOrderData
  },
) => {
  switch (type) {
    case "select-order":
      return { ...state, selectedOrder: payload as MedicationRequestOrderData }
    case "show-reschedule-dialog":
      return { ...state, showReschedule: true }
    case "hide-reschedule-dialog":
      return { ...state, showReschedule: false }
    case "update-reschedule-date":
      return { ...state, rescheduleDate: payload as Date }
    case "show-cancel-dialog":
      return { ...state, showCancel: true }
    case "hide-cancel-dialog":
      return { ...state, showCancel: false, cancelReason: undefined }
    case "update-cancel-reason":
      return { ...state, cancelReason: payload as string }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ selectedOrder, showReschedule, rescheduleDate, showCancel, cancelReason }, dispatch] = useReducer(
    reducer,
    initialState,
  )

  const selectOrder = (order: MedicationRequestOrderData) => dispatch({ type: "select-order", payload: order })
  const showRescheduleDialog = () => dispatch({ type: "show-reschedule-dialog" })
  const hideRescheduleDialog = () => dispatch({ type: "hide-reschedule-dialog" })
  const updateRescheduleDate = (newDate: Date) => dispatch({ type: "update-reschedule-date", payload: newDate })
  const showCancelDialog = () => dispatch({ type: "show-cancel-dialog" })
  const hideCancelDialog = () => dispatch({ type: "hide-cancel-dialog" })
  const updateCancelReason = (reason: string) => dispatch({ type: "update-cancel-reason", payload: reason })

  return {
    selectedOrder,
    showReschedule,
    showCancel,
    rescheduleDate,
    cancelReason,
    updateCancelReason,
    selectOrder,
    showRescheduleDialog,
    showCancelDialog,
    hideCancelDialog,
    hideRescheduleDialog,
    updateRescheduleDate,
  }
}

type State = {
  selectedOrder?: MedicationRequestOrderData
  showReschedule: boolean
  showCancel: boolean
  rescheduleDate: Date
  cancelReason?: string
}

type Props = {
  mrOrderData: MedicationRequestOrderData[]
  reloadOrders(): void
}

export { MedicationOrdersList }
