import { DialogFormContainer } from "commons"
import { useOrganizationContext } from "organization"

import { useEditPanel } from "../../../hooks"
import { LabPanel, PDParams } from "../../../types"
import { getPanelInitialValues } from "../validations"
import { LabPanelForm } from "./LabPanelForm"

const LabPanelFormContainer = ({ labPanel, onHide }: Props) => {
  const { currentOrganization, performerLabsEnabled, isLoadingLabsFacility } = useOrganizationContext()

  const initialValue = getPanelInitialValues(currentOrganization, labPanel)

  const { editPanel } = useEditPanel(() => {
    onHide()
  })

  const onSubmit = (data: PDParams) => {
    editPanel(data)
  }

  const isEditing = !!initialValue.id

  return (
    <>
      <DialogFormContainer
        onSubmit={onSubmit}
        onCancel={onHide}
        showForm={true}
        title={"Edit test"}
        saveLabel={"Update"}
        initialValue={{
          ...initialValue,
        }}
        useFormik
        isLoadingData={isLoadingLabsFacility}
      >
        <LabPanelForm performerLabsOptions={performerLabsEnabled.map(({ ref }) => ref)} isEditing={isEditing} />
      </DialogFormContainer>
    </>
  )
}

type Props = {
  labPanel?: LabPanel
  onHide(): void
}

export { LabPanelFormContainer }
