import { Dialog } from "primereact/dialog"
import { classNames } from "primereact/utils"

import { LabComboTitle } from "commons/labs"
import { LaboratoryComboTest } from "commons/types"
import { getMoneyCurrencyAlt } from "utils"

const LaboratoryOrderComboDetail = ({ labCombo, showPrice, isInsurance, onHide }: Props) => (
  <Dialog
    closable={true}
    header="Laboratory combo details"
    visible={true}
    draggable={false}
    dismissableMask={false}
    style={{ width: "35vw" }}
    onHide={onHide}
  >
    <div className="flex flex-col">
      <div className="flex justify-between text-lg font-semibold text-gray-700 mb-4">
        <LabComboTitle combo={labCombo.combo} />
        {showPrice && (
          <span className={classNames({ "line-through": isInsurance })}>
            {getMoneyCurrencyAlt(labCombo.price?.currency)}
            {(labCombo?.price?.value ? (labCombo?.price?.value as number) : 0).toFixed(2)}
          </span>
        )}
      </div>

      <label className="font-semibold">Tests:</label>
      <ul className="@container ml-3 grid xl:grid-cols-2 col-auto gap-3">
        {labCombo.laboratoryTests.map((test, index) => (
          <div key={test.planDefinition.id ?? index} className="flex justify-between items-center col-span-1">
            <div className="py-2">
              <div className="text-gray-600 font-semibold text-xs">{test.display}</div>
              <div className="text-gray-400 text-xs">{`${test.planDefinition.publisher} - ${test.planDefinition.identifier?.find((id) => id.system?.includes("sku"))?.value}`}</div>
            </div>
          </div>
        ))}
      </ul>
    </div>
  </Dialog>
)

type Props = {
  labCombo: LaboratoryComboTest
  showPrice?: boolean
  isInsurance?: boolean
  onHide(): void
}

export { LaboratoryOrderComboDetail }
