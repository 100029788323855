import { formatDistance } from "date-fns"
import { getFirstEmail, getFirstPhone, humanNameAsString } from "fhir"
import { classNames } from "primereact/utils"
import { Fragment, useCallback, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import { KpListItem, ModulesId } from "commons"
import { useConsents } from "consent/hooks"
import { useCoverages } from "coverage"
import { formatsByTypes } from "data"
import { useAppModuleContext } from "internals"
import { useOrganizationPractitioners } from "organization"
import { formatDate, getPractitionerInfo } from "utils"

import { PatientAddressItem } from "../components/PatientAddresses"
import { usePatientContacts, usePatientContext, usePatientInvitation } from "../hooks"
import { getEffectivesAddressess } from "../utils"

const PatientInformationKnowledge = () => {
  const { patient, patientId, hasB2CUser } = usePatientContext()
  const [searchParams, setSearchParamas] = useSearchParams()
  const { contactsResume } = usePatientContacts(patient)
  const { coveragesList } = useCoverages(patientId)
  const { consentsList } = useConsents(patientId)
  const { isModuleActive } = useAppModuleContext()
  const { invitation, isLoading } = usePatientInvitation(patient?.id as string)
  const { organizationPractitionersInfo, isLoading: isLoadingPractInfo } = useOrganizationPractitioners({
    organizationId: patient.managingOrganization?.id as string,
  })

  const { address, telecom, generalPractitioner } = patient ?? {}
  const effectiveAddressess = getEffectivesAddressess(address ?? [])

  const patientPractName = useMemo(() => {
    if (generalPractitioner?.[0]?.id && !isLoadingPractInfo)
      return humanNameAsString(
        getPractitionerInfo(generalPractitioner[0].id, organizationPractitionersInfo)?.practitioner.name?.[0],
        "Practitioner isn't available or doesn't meet security requirements",
      )
    else return "Unspecified practitioner"
  }, [generalPractitioner, organizationPractitionersInfo, isLoadingPractInfo])

  const invitationStatus = () => {
    if (hasB2CUser) return "Active"
    if (invitation) {
      switch (invitation.status) {
        case "active":
          return "Sending invitation email"
        case "completed":
          return `Invitation pending to accept. The link expires in ${formatDistance(
            new Date(invitation.occurrence?.dateTime as string),
            new Date(),
          )}.`
      }
    }
    return invitation?.status === "revoked" ? "Error sending email" : "Not invited"
  }

  const navigateToPatientInformationView = useCallback(
    (view?: string, subview?: string) => {
      const params = new URLSearchParams({ kp: ModulesId.PATIENT, view: view || ModulesId.PATIENT })
      if (subview) {
        params.append("subview", subview)
      }
      setSearchParamas(params)
    },
    [setSearchParamas],
  )

  const AddressKPItem =
    effectiveAddressess.length > 0 ? (
      <>
        {effectiveAddressess.map((item, index) => (
          <PatientAddressItem key={index} {...item} className="truncate" isKP />
        ))}
      </>
    ) : (
      "Unspecified address"
    )

  const ConsentKPItem = consentsList ? (
    <>
      {consentsList.map(({ category, date }, index) => (
        <Fragment key={index}>
          <div className="flex flex-row mb-1 items-baseline">
            <div className="flex flex-col truncate">
              <span
                className="text-sm text-gray-500 truncate"
                title={category}
              >{`${date ? formatDate(date, formatsByTypes.LONG_DATE) : "Unspecified date"} - ${category}`}</span>
            </div>
          </div>
          &nbsp;
        </Fragment>
      ))}
    </>
  ) : (
    "Unspecified consents"
  )

  return (
    <div
      className={classNames("grow grid h-full overflow-hidden", {
        "grid-cols-5": !searchParams.has("kp"),
      })}
    >
      <div className={classNames("overflow-auto pt-1")}>
        <div className="border-t border-gray-200 first:border-none px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <KpListItem
              content={patientPractName}
              label="Practitioner"
              onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.PATIENT, "basic-info")}
              externalActionLabel="Edit practitioner"
              isLoading={isLoadingPractInfo}
            />
            <KpListItem
              content={AddressKPItem}
              label="Address"
              onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.PATIENT, "address")}
              externalActionLabel="Edit address"
            />
            <KpListItem
              content={getFirstEmail(telecom)}
              label="Email"
              onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.PATIENT, "telecom")}
              externalActionLabel="Edit email"
            />
            <KpListItem
              content={getFirstPhone(telecom)}
              label="Phone"
              onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.PATIENT, "telecom")}
              externalActionLabel="Edit phone"
            />
            <KpListItem
              content={invitationStatus()}
              label="Status"
              isLoading={isLoading}
              onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.PATIENT, "status")}
              externalActionLabel="View status"
            />
            <KpListItem
              content={contactsResume ?? "Unspecified contacts"}
              label="Contacts"
              grayed={!contactsResume}
              onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.PATIENT, "contact")}
              externalActionLabel="View contacts"
            />
            <KpListItem
              content={coveragesList ?? "Unspecified insurance"}
              grayed={!coveragesList}
              label="Insurance"
              onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.PATIENT, "insurance")}
              externalActionLabel="View insurance"
            />
            <KpListItem
              content={ConsentKPItem}
              grayed={!consentsList}
              label="Consents"
              onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.PATIENT, "consent")}
              externalActionLabel="View consents"
            />
            <KpListItem
              content={""}
              label="Nutraceuticals Orders"
              onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.MEDICATIONR)}
              externalActionLabel="View medications"
            />

            {isModuleActive(ModulesId.EPRESCRIBE) && (
              <KpListItem
                content={""}
                label="Rx Orders"
                onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.EPRESCRIBE)}
                externalActionLabel="View eprescribe"
              />
            )}
            {isModuleActive(ModulesId.LABSORDER) && (
              <KpListItem
                content={""}
                label="Labs Orders"
                onExternalLinkClick={() => navigateToPatientInformationView(ModulesId.LABSORDER)}
                externalActionLabel="View labs"
              />
            )}
          </dl>
        </div>
      </div>
    </div>
  )
}

export { PatientInformationKnowledge }
