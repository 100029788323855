import { Appointment } from "fhir"
import { Sidebar } from "primereact/sidebar"
import { useParams } from "react-router-dom"

import { FormContainer } from "commons"
import { useOrganizationContext } from "organization"
import { useLoginContext } from "security"

import { AppointmentForm } from "./AppointmentForm"
import { appointmentValidationSchema, initialValues } from "./validations"

const AppointmentFormOverlay = ({ appointment, onSubmit, onHide, visible, isEditing, isParticipantInUse }: Props) => {
  const { patientId } = useParams()
  const { loggedInPractitioner } = useLoginContext()
  const { location, roomsRefs } = useOrganizationContext()

  const customSidebarTitlebar = (
    <>
      <h1 className="font-semibold w-full text-left">Appointment</h1>
    </>
  )

  return (
    <Sidebar
      visible={visible}
      position="right"
      style={{ minWidth: "30%" }}
      header={customSidebarTitlebar}
      onHide={onHide}
    >
      <FormContainer
        initialValue={initialValues(appointment, loggedInPractitioner, roomsRefs, location)}
        validationSchema={appointmentValidationSchema(isParticipantInUse)}
        onSubmit={onSubmit}
        onCancel={onHide}
        enableReinitialize
      >
        <AppointmentForm hidePatientField={patientId !== undefined} isReadonlyPatient={isEditing} />
      </FormContainer>
    </Sidebar>
  )
}

type Props = {
  visible?: boolean
  isEditing?: boolean
  appointment: Appointment
  isParticipantInUse?(date: Date, duration: number, participantId: string): boolean
  onHide(): void
  onSubmit(appointment: Appointment): void
}

export { AppointmentFormOverlay }
