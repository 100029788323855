import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Appointment } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { useLoginContext } from "security"
import { displayNotificationSuccess } from "utils"

import { patientAptsQueryKeys, practitionerAptsQueryKeys } from "../query-keys"

const useUnbookAppointment = ({ onSuccess, onSettled }: UnbookAppointmentArgs) => {
  const { update } = useClient()
  const queryClient = useQueryClient()
  const { loggedInPractitionerId } = useLoginContext()

  const unbook = async (appointment: Appointment) =>
    update("Appointment", appointment.id as string, { ...appointment, status: "cancelled" } as Appointment)

  const { mutate: unbookAppointment, isPending: isUnbooking } = useMutation({
    mutationFn: unbook,
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        await queryClient.invalidateQueries({ queryKey: patientAptsQueryKeys.all })
        queryClient.refetchQueries({
          queryKey: practitionerAptsQueryKeys.all(loggedInPractitionerId),
          type: "all",
        })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      queryClient.refetchQueries({ queryKey: patientAptsQueryKeys.all })
      queryClient.refetchQueries({ queryKey: practitionerAptsQueryKeys.all(loggedInPractitionerId), type: "all" })
      onSuccess?.()
      displayNotificationSuccess("The appointment has been unbooked successfully!")
      // datadogLogs.logger.info(`The appointment of ${loggedInPractitionerId} has been unbooked successfully!`)
    },
    onSettled: onSettled,
  })

  return { unbookAppointment, isUnbooking }
}

interface UnbookAppointmentArgs {
  onSuccess?: () => void
  onSettled?: () => void
}

export { useUnbookAppointment }
