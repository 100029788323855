import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatDate } from "date-fns/format"
import { parseISO } from "date-fns/parseISO"
import { Account, Address, codeableConceptAsString, humanNameAsString, isOrganization, isPatient } from "fhir"
import pluralize from "pluralize"
import { FC, useMemo } from "react"

import { useReaders } from "commons/readers"
import { creditCardTypes } from "data"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { getMoneyCurrencyAlt, strCapitalize } from "utils"

import { CustomInvoiceData } from "../types"
import { getSummaryParameter } from "../utils"
import { OrderSummary } from "./OrderSummary"

const InvoicePreviewSection: FC<
  Props & {
    invoiceData?: CustomInvoiceData
    readerAccount?: Account
  }
> = ({
  invoiceData,
  labsShippingAddress,
  nutrasShippingAddress,
  showLabsAddress,
  showMedsAddress,
  readerAccount,
  showProductsSection = false,
  summaryAsCard = false,
}) => {
  const { currentOrganizationCCAccount, currentOrganization, currentOrganizationId } = useOrganizationContext()
  const { patient, preferedCreditCard } = usePatientContext()

  const { readers, locationsByReader } = useReaders(currentOrganizationId)
  const readerInfo = useMemo(() => {
    if (readerAccount) {
      const reader = readers?.find(({ id }) => readerAccount.subject?.[0]?.id === id)
      const locationStr = reader && locationsByReader?.[reader.id ?? ""]?.name
      const typeStr = codeableConceptAsString(readerAccount.type)

      return (
        <div className="flex flex-col">
          <p>{typeStr}</p>
          <p className="overflow-hidden text-ellipsis line-clamp-1 break-words">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
            {`${reader?.deviceName?.[0]?.name} | ${locationStr}`}
          </p>
        </div>
      )
    }
  }, [readerAccount, readers, locationsByReader])

  const { isPracticeInvoice } = useMemo(
    () => ({
      isPracticeInvoice: isOrganization(invoiceData?.invoice?.recipient),
      isPatientInvoice: isPatient(invoiceData?.invoice?.recipient),
    }),
    [invoiceData],
  )

  if (!invoiceData) return null

  const currency = getMoneyCurrencyAlt(invoiceData?.invoice?.totalNet?.currency)
  const summary = invoiceData?.summary
  const paymentCreditCard = isPracticeInvoice ? currentOrganizationCCAccount?.creditCard?.[0] : preferedCreditCard
  const hasLabsRequest = !!invoiceData.items?.laboratory?.length
  const hasMedsRequest = !!invoiceData.items?.nutraceutical?.length || !!invoiceData.items?.medication?.length
  const invoiceItems = Object.entries(invoiceData.items)
  const itemsSubtotal = invoiceData.itemsSubtotal + invoiceData.productFeesSubtotal + invoiceData.shippingMethodSubtotal

  const amendments = Object.entries(invoiceData.amendments)

  return (
    <div className="px-3 first-of-type:border-t-0 relative flex gap-8">
      <section className="flex-1 grow">
        <h3 className="text-sm font-medium inline-flex justify-between w-full items-center space-x-3">
          <span className="text-gray-700">{isPracticeInvoice ? "Bill to Practice" : "Bill to Patient"}</span>{" "}
          <hr className="border flex-1" />
        </h3>
        <div className="pl-2 py-3 flex flex-col text-sm">
          <div className="flex border-b justify-between flex-col">
            <div className="w-1/3 ">Information</div>
            <div className="flex-1 w-full">
              <div className="flex justify-between pb-2 border-b">
                <div className="font-light mr-2">Date</div>
                <div>
                  {invoiceData?.invoice?.date
                    ? formatDate(parseISO(invoiceData.invoice?.date), "MM/dd/yy")
                    : "Unspecified"}
                </div>
              </div>

              <div className="flex justify-between py-2 border-b">
                <div className="font-light mr-2">Name</div>
                <div className="text-right">
                  <div>{isPracticeInvoice ? currentOrganization.name : humanNameAsString(patient.name?.[0])}</div>
                </div>
              </div>

              <AddressItem
                label="Ship medications to"
                show={showMedsAddress && hasMedsRequest}
                address={nutrasShippingAddress}
              />

              {!isPracticeInvoice && (
                <AddressItem
                  label="Ship laboratories to"
                  show={showLabsAddress && hasLabsRequest}
                  address={labsShippingAddress}
                />
              )}

              <div className="flex py-2 justify-between">
                <div className="font-light mr-2 text-nowrap">Payment Method</div>
                <div className="text-right">
                  {readerAccount ? (
                    readerInfo
                  ) : paymentCreditCard ? (
                    <>{`${
                      creditCardTypes.find((cc) => cc.code === paymentCreditCard.type)?.label?.toUpperCase() ??
                      "Unknown"
                    } | ${paymentCreditCard.last4Digits}`}</>
                  ) : (
                    <>N/A</>
                  )}
                </div>
              </div>
            </div>
          </div>

          {!!showProductsSection && !!invoiceItems.length && (
            <>
              <div className="grid grid-cols-4 pt-2 w-full gap-3">
                <span>Product Type</span>
                <span className="col-span-2">Description</span>
                <div className="col-span-1 flex justify-between">
                  <span>Qty</span>
                  <span>Price</span>
                </div>
              </div>
              {invoiceItems.map(([pType, items]) => (
                <div key={pType} className="grid grid-cols-4 gap-3 border-b items-baseline text-sm py-2">
                  <div className="col-span-1 inline-block pt-1 font-light">
                    {pType === "medication" ? "Pharmaceuticals" : pluralize(strCapitalize(pType), 2)}
                  </div>
                  <div className="font-light col-span-3">
                    {items.map(({ description, price, qty, productId }, index) => (
                      <div className="py-2 border-b last-of-type:border-b-0 last-of-type:pb-0" key={`product_${index}`}>
                        <div className="grid grid-cols-3 w-full gap-3 items-center">
                          <span title="Product" className="col-span-2">
                            {description}
                          </span>
                          <div className="col-span-1 flex justify-between">
                            <span title="Quantity">x{qty ?? 1}</span>
                            <span title="Price" className="font-normal">
                              {`${price < 0 ? "-" : ""}${currency}${(price < 0 ? price * -1 : price).toFixed(2)}`}
                            </span>
                          </div>
                        </div>
                        {pType === "procedure" &&
                          invoiceData.productFees?.[productId as string]?.map(({ description, price }, index) => (
                            <div
                              key={`procedure_fee_${index}`}
                              className="grid grid-cols-3 font-light items-center"
                              title="Fee"
                            >
                              <span className="col-span-2">{description}</span>
                              <span className="inline-block text-right font-normal">{currency + price.toFixed(2)}</span>
                            </div>
                          ))}
                      </div>
                    ))}

                    {invoiceData.shippingMethods[pType]?.map(({ description, price }, index) => (
                      <div key={`shipping_${index}`} className="grid grid-cols-3 items-center py-2 last-of-type:pb-0">
                        <span className="col-span-2">{description}</span>
                        <span className="inline-block text-right font-normal">{currency + price.toFixed(2)}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}

          {!!amendments.length && (
            <>
              <div className="w-1/3 pt-2">Amendments</div>
              <div className="grid grid-cols-4 pt-2 w-full gap-3">
                <span></span>
                <span className="col-span-2">Description</span>
                <div className="flex justify-end">
                  <span>Price</span>
                </div>
              </div>
              {amendments.map(([pType, items]) => (
                <div key={pType} className="grid grid-cols-4 gap-3 border-b items-baseline text-sm py-2">
                  <div className="col-span-1 inline-block pt-1 font-light">
                    {pType === "medication" ? "Pharmaceuticals" : pluralize(strCapitalize(pType), 2)}
                  </div>
                  <div className="font-light col-span-3">
                    {items.map(({ description, price }, index) => (
                      <div className="py-2 border-b last-of-type:border-b-0 last-of-type:pb-0" key={`product_${index}`}>
                        <div className="grid grid-cols-3 w-full gap-3">
                          <span title="Product" className="col-span-2">
                            {description}
                          </span>
                          <div className="col-span-1 flex justify-end">
                            <span title="Price" className="font-normal">
                              {`${price < 0 ? "-" : ""}${currency}${(price < 0 ? price * -1 : price).toFixed(2)}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}

          {(!summary || !!invoiceData.discounts) && (
            <div className="border-b py-2 flex justify-between text-sm">
              <span className="inline-block">Subtotal</span>
              <span className="inline-block text-right">{`${itemsSubtotal < 0 ? "-" : ""}${currency}${itemsSubtotal < 0 ? (itemsSubtotal * -1).toFixed(2) : itemsSubtotal.toFixed(2)}`}</span>
            </div>
          )}
          {!!invoiceData.discounts && (
            <div className="border-b py-2 flex justify-between items-baseline">
              <h4 className="text-sm inline-block pt-1 w-1/3">Discounts</h4>
              {Object.entries(invoiceData.discounts).map(([pType, items], index) => (
                <div key={`discount_${index}`} className="flex justify-between flex-1 w-full font-light text-sm">
                  <span>{pType === "medication" ? "Pharmaceuticals" : pluralize(strCapitalize(pType), 2)}</span>
                  {items.map(({ price }, index) => (
                    <span key={`${pType}_${index}`} className="inline-block text-right font-normal">
                      {"-" +
                        currency +
                        (
                          (summary
                            ? (getSummaryParameter(summary, "discounts") as Record<string, number>)[pType] ?? price
                            : price) * -1
                        ).toFixed(2)}
                    </span>
                  ))}
                </div>
              ))}
            </div>
          )}
          {summary && !summaryAsCard && (
            <OrderSummary
              summary={summary}
              hasMedicationItems={hasMedsRequest}
              currency={currency}
              className="py-2 mt-3"
            />
          )}
        </div>
      </section>

      {summary && summaryAsCard && (
        <OrderSummary
          summary={summary}
          hasMedicationItems={hasMedsRequest}
          currency={currency}
          className="p-4 mb-4 min-w-48 border-collapse border rounded-xl shadow-sm h-fit"
          titleClassName="font-semibold"
        />
      )}
    </div>
  )
}

const AddressItem = ({ label, address, show }: { label: string; show?: boolean; address?: Address }) => {
  if (!show) {
    return null
  }

  return (
    <div className="flex justify-between py-2 border-b">
      <div className="font-light mr-2">{label}</div>
      <div className="text-right">
        {address ? (
          <div>
            <div>{address?.line?.[0]}</div>
            {address?.line?.[1] && <div>{address?.line?.[1]}</div>}
            <div>{`${address?.city}, ${address.state} ${address.postalCode}`}</div>
          </div>
        ) : (
          <div>No shipping address</div>
        )}
      </div>
    </div>
  )
}

type Props = {
  nutrasShippingAddress?: Address
  labsShippingAddress?: Address
  showMedsAddress?: boolean
  showLabsAddress?: boolean
  showProductsSection?: boolean
  summaryAsCard?: boolean
}

export { InvoicePreviewSection }
