import { faCalendarDays, faUser, faLayerGroup, faClock } from "@fortawesome/pro-solid-svg-icons"
import { Appointment, codeableConceptAsString, isPatient } from "fhir"

import { StackedListItemProps } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

const appointmentModelBuilder = (appt: Appointment): StackedListItemProps => {
  const patient = appt.participant.find(({ actor }) => isPatient(actor))

  return {
    leftData: [
      {
        lineItems: [{ name: "Description", value: appt.description ?? "No description" }],
      },
      {
        lineItems: [
          {
            name: "Start",
            value: appt.start ? formatDate(new Date(appt.start.toString()), formatsByTypes.LONG_DATE) : "No date",
            icon: faCalendarDays,
          },
          {
            name: "Duration in minutes",
            value: appt.minutesDuration?.toString() ?? "No duration",
            icon: faClock,
          },
          {
            name: "Patient",
            value: patient?.actor?.display ?? "",
            icon: faUser,
          },
          {
            name: "Type",
            value: codeableConceptAsString(appt.appointmentType),
            icon: faLayerGroup,
          },
        ],
      },
    ],
    badge: { text: appt.status },
  }
}

export { appointmentModelBuilder }
