const familyHistoryQueryKeys = {
  all: ["patient/FamilyMemberHistory"] as const,
  history: (patientId: string, encounterDate?: string) => [
    ...familyHistoryQueryKeys.all,
    patientId,
    ...(encounterDate ? [encounterDate] : []),
  ],
}

export { familyHistoryQueryKeys }
