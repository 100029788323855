import { faSearch } from "@fortawesome/pro-solid-svg-icons"
import { Coding } from "fhir"
import { useState } from "react"

import { useValueSet } from "value-set"

import { StackedListItemProps } from "../../components/StackedListItem"
import { getRestrictedLabPerformer, useGetLabPerformer } from "../../labs"
import { SuggestionProps, ValueSetIds } from "../../types"
import { CheckMultipleWithSlideoverFilter } from "../CheckMultipleWithSlideoverFilter"

const ICD10CodesSelection = ({ selectedCodes, showSlide, suggestions, onHide }: Props) => {
  const [searchFilter, setSearchFilter] = useState<string>()
  const { codes, isFetching } = useValueSet({
    valueSetId: ValueSetIds.ICD10,
    filter: searchFilter,
    enabled: !!searchFilter,
  })
  const labPerformer = useGetLabPerformer()
  const maxSelectedItemsAllowed = getRestrictedLabPerformer(labPerformer?.id as string)?.maxAmount ?? undefined

  return (
    <CheckMultipleWithSlideoverFilter
      isVisible={showSlide}
      onHide={() => onHide({ save: false })}
      filterProps={{
        autoFocus: true,
        isLoading: isFetching,
        onTextFilter: setSearchFilter,
        placeholder: "Please type to search",
      }}
      icon={faSearch}
      emptyLabel="No ICD-10 Codes Selected"
      initialCheckedValues={selectedCodes}
      itemModel={itemModel}
      queryProps={{ data: codes, isFetching }}
      onSave={(newItems, deletedItems) => onHide({ save: true, newCodes: newItems, deletedCodes: deletedItems })}
      getItemId={({ code }) => code as string}
      getItemText={({ code }) => `${code}`}
      verifyExistInData
      suggestionProps={suggestions}
      title="Select ICD-10 Codes"
      maxSelectedItemsAllowed={maxSelectedItemsAllowed}
    />
  )
}

const itemModel = (code: Coding): StackedListItemProps => ({
  leftData: [{ lineItems: [{ name: "Code", value: `${code.display} - ${code.code}` }] }],
})

type Props = {
  showSlide: boolean
  selectedCodes: Coding[]
  suggestions?: SuggestionProps<Coding>
  onHide({
    save,
    newCodes,
    deletedCodes,
  }: {
    save?: boolean
    newCodes?: Coding[]
    deletedCodes?: { id: string; index: number }[]
  }): void
}

export { ICD10CodesSelection }
