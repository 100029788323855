import { faChevronDown, faChevronUp, faTrashCan } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { codeableConceptAsString, Coding, Medication, MedicationBatch } from "fhir"
import { FieldArray, FieldArrayRenderProps, FieldProps, useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"
import { useMountEffect } from "primereact/hooks"
import { InputNumber } from "primereact/inputnumber"
import { classNames } from "primereact/utils"
import { FC, useMemo, useState } from "react"

import { AddFieldArrayItemButton, Button, DateField, DropdownField, FormField, InputField, ValueSetIds } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"
import { useValueSet } from "value-set"

import { bodyZonesCodes, InventoryData, PROCEDURE_CONFIG, ProcedureData } from "../../types"
import { BodySitesAndDosageField } from "./BodySitesAndDosageField"

const MedConfigurationItem: FC<Props> = ({
  medIndex,
  expanded,
  isSingleBodySiteSelection,
  configCodes,
  bodySiteActionCode,
  onClick,
  onChangeBodySite,
  onChangeBodyZone,
  handelUpdateDosage,
  isMassage,
}) => {
  const { codes: bodySites } = useValueSet({ valueSetId: ValueSetIds.BODY_SITES })
  const [maxQuantityByBatch, setMaxQuantityByBatch] = useState<Record<string, number>>({})
  const [quantityByLot, setQuantityByLot] = useState<Record<string, number>>({})

  const {
    values: { configurationItem },
    setFieldValue,
  } = useFormikContext<ProcedureData>()

  const medLot = (item: InventoryData) =>
    item ? (
      <div className="flex gap-2">
        <span>L: {item.lotNumber}</span>
        <span>
          E: {item.expirationDate ? formatDate(new Date(item.expirationDate), formatsByTypes.ISO_8601_DATE) : "N/A"}
        </span>
        <span>Q: {item.quantity}</span>
      </div>
    ) : undefined

  const requireMedAdministration = configCodes.includes(PROCEDURE_CONFIG.PELLET)
  const requiresDoseConfiguration = configCodes.includes(PROCEDURE_CONFIG.BODY_SITE_COORDINATE_AND_DOSAGE)

  const dosage = configurationItem[medIndex]?.bodySite?.coding?.find(
    ({ system }) => system === `${bodySiteActionCode?.system}/configure-dosage`,
  )

  const cardTitle = !requireMedAdministration
    ? `Massage ${medIndex + 1}`
    : codeableConceptAsString(configurationItem[medIndex]?.medicationRequest?.medication?.CodeableConcept)

  const onQtyChange = (value: number, batchIndex?: number, lotNumber?: string, lotQuantity?: number) => {
    const meds = (configurationItem[medIndex]?.medicationAdministration?.contained as Medication[]) ?? []
    const updatedMeds = [...meds, ...(meds.length === batchIndex ? [{}] : [])]

    const { totalQty, quantityByLot } = updatedMeds.reduce<{ totalQty: number; quantityByLot: Record<string, number> }>(
      (acc, med, index) => {
        const lot = index === batchIndex && lotNumber ? lotNumber : (med?.batch?.lotNumber as string)
        const qty = index === batchIndex ? value : med?.amount?.numerator?.value ?? 1

        return {
          totalQty: acc.totalQty + qty,
          quantityByLot: !lot
            ? acc.quantityByLot
            : { ...acc.quantityByLot, [lot]: (acc.quantityByLot[lot] ?? 0) + qty },
        }
      },
      { totalQty: 0, quantityByLot: {} },
    )

    const { maxQuantity, updatedQty } = updatedMeds.reduce<{
      maxQuantity: Record<number, number>
      updatedQty?: number
    }>(
      (acc, med, index) => {
        const lot = index === batchIndex && lotNumber ? lotNumber : (med?.batch?.lotNumber as string)
        const qty = index === batchIndex ? value : med?.amount?.numerator?.value ?? 1
        const max = (index === batchIndex ? lotQuantity : undefined) ?? (med?.batch as InventoryData)?.quantity
        const available = max && max - quantityByLot[lot]
        const updatedQty = qty + (index === batchIndex ? Math.min(0, available ?? 0) : 0)
        const batchMax = max && Math.max(Math.min(max - quantityByLot[lot] + qty, max), updatedQty)
        return {
          updatedQty: updatedQty !== qty ? updatedQty : acc.updatedQty,
          maxQuantity: !max || !lot ? acc.maxQuantity : { ...acc.maxQuantity, [index]: batchMax },
        }
      },
      { maxQuantity: {} },
    )

    const dose = totalQty * (configurationItem[medIndex]?.medBaseDose?.value ?? 1)
    setFieldValue(`configurationItem[${medIndex}].medicationRequest.dispenseRequest.quantity.value`, totalQty)
    setFieldValue(
      `configurationItem[${medIndex}].medicationRequest.dispenseRequest.initialFill.quantity.value`,
      totalQty,
    )
    setFieldValue(`configurationItem[${medIndex}].medicationAdministration.dosage.dose.value`, dose)
    setFieldValue(`configurationItem[${medIndex}].medTotalDose.value`, dose)
    updatedQty &&
      setFieldValue(
        `configurationItem[${medIndex}].medicationAdministration.contained[${batchIndex}].amount.numerator.value`,
        updatedQty,
      )
    setMaxQuantityByBatch(maxQuantity)
    setQuantityByLot(quantityByLot)
  }

  const usedBodySitesPerBatch = useMemo(
    () =>
      configurationItem[medIndex]?.medicationAdministration?.contained?.reduce<Record<string, string[]>>(
        (acc: Record<string, string[]>, med, index) => {
          const site = configurationItem[medIndex]?.bodySite?.coding?.[index]?.code
          const batchCode = (med as Medication)?.batch?.lotNumber

          return {
            ...(acc ?? {}),
            [batchCode!]: [...(acc[batchCode!] ?? []), ...(site ? [site] : [])],
          }
        },
        {} as Record<string, string[]>,
      ),
    [configurationItem, medIndex],
  )

  useMountEffect(() => {
    if (!isMassage) {
      onQtyChange(0)
    }
  })

  return (
    <div
      className={classNames(
        "@container flex flex-col p-4 rounded-md border transition-all ease-in-out duration-200",
        expanded ? "cursor-default border-gray-700" : "cursor-pointer border-gray-300",
      )}
      onClick={onClick}
    >
      <div className="flex flex-row justify-between items-baseline space-x-1">
        <div className="flex flex-row gap-2 items-baseline">
          <span
            className={classNames(
              "rounded-full w-2 h-2 self-center shrink-0",
              configurationItem[medIndex]?.bodySite?.coding?.[0]?.code ? "bg-green-500" : "bg-yellow-500",
            )}
          />
          <span className="font-medium text-gray-900">{cardTitle}</span>
        </div>
        <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
      </div>
      <div className={classNames("flex flex-col divide-y divide-gray-200", { hidden: !expanded })}>
        {requireMedAdministration && (
          <FieldArray name={`configurationItem[${medIndex}].medicationAdministration.contained`}>
            {({ name, push, remove, form: { getFieldMeta } }: FieldArrayRenderProps) => {
              const fieldValue = getFieldMeta<Medication[]>(name).value

              const addBatch = () => {
                onQtyChange(1, fieldValue.length)
                if (isSingleBodySiteSelection) {
                  onPelletChange(fieldValue.length)
                  setFieldValue(`configurationItem[${medIndex}].bodySite.coding`, [
                    ...(configurationItem[medIndex].bodySite?.coding ?? []),
                    {},
                  ])
                }
                push({
                  batch: { lotNumber: undefined, expirationDate: undefined },
                  amount: { numerator: { value: 1 } },
                  code: configurationItem[medIndex]?.medicationRequest?.medication?.CodeableConcept,
                  resourceType: "Medication",
                })
              }

              const removeBatch = (batchIndex: number) => {
                onQtyChange(0, batchIndex)
                if (isSingleBodySiteSelection) {
                  onPelletChange(batchIndex)
                  const codes = configurationItem[medIndex].bodySite?.coding?.reduce<Coding[]>(
                    (acc, coding, index) => (index === batchIndex ? acc : [...acc, coding]),
                    [],
                  )
                  setFieldValue(`configurationItem[${medIndex}].bodySite.coding`, codes)
                }
                remove(batchIndex)
              }

              const onPelletChange = (batchIndex: number, siteCoding?: Coding) => {
                const sitesCodes = configurationItem[medIndex].bodySite?.coding?.reduce<string[]>(
                  (acc, { code }, index) =>
                    index === batchIndex
                      ? [...acc, ...(siteCoding?.code ? [siteCoding.code] : [])]
                      : [...acc, code as string],
                  [],
                ) ?? [siteCoding?.code as string]

                setFieldValue(
                  `configurationItem[${medIndex}].bodySite.text`,
                  sitesCodes.length > 1
                    ? `${sitesCodes.length} points selected`
                    : configurationItem[medIndex]?.bodySite?.coding?.[0]?.display ??
                        siteCoding?.display ??
                        configurationItem[0]?.bodySite?.coding?.[0]?.display,
                )
                onChangeBodySite(batchIndex === fieldValue.length ? [...sitesCodes, ""] : sitesCodes)
              }

              return fieldValue.map((_, batchIndex) => {
                const availableBatches = configurationItem[medIndex]?.invData?.reduce<InventoryData[]>(
                  (acc, inv) =>
                    !!quantityByLot[inv.lotNumber] &&
                    inv.quantity <= quantityByLot[inv.lotNumber] &&
                    fieldValue[batchIndex].batch?.lotNumber !== inv.lotNumber
                      ? acc
                      : [...acc, inv],
                  [],
                )

                const usedBodySites =
                  usedBodySitesPerBatch?.[fieldValue[batchIndex]?.batch?.lotNumber ?? ""]?.filter(
                    (bodySite) => bodySite !== configurationItem[medIndex]?.bodySite?.coding?.[batchIndex]?.code,
                  ) || []
                const effectiveBodySites =
                  !!usedBodySites?.length && usedBodySites.length > 0
                    ? bodySites?.filter((site) => !usedBodySites.includes(site.code!))
                    : bodySites

                const handleSelectedBodySiteAvailabilityOnBatchChange = () => {
                  const selectedBodySite = configurationItem[medIndex]?.bodySite?.coding?.[batchIndex]

                  if (
                    selectedBodySite?.code !== undefined &&
                    !!effectiveBodySites?.some((eBodySite) => eBodySite?.code === selectedBodySite?.code)
                  ) {
                    onPelletChange(batchIndex, undefined)
                    setFieldValue(`configurationItem[${medIndex}].bodySite`, {
                      coding: configurationItem[medIndex]?.bodySite?.coding?.with(batchIndex, { code: undefined }),
                    })
                  }
                }

                const isNewBatch =
                  (configurationItem[medIndex]?.medicationAdministration?.contained?.[batchIndex] as Medication)?.batch
                    ?.lotNumber === undefined
                const effectiveBatches = isNewBatch
                  ? availableBatches?.filter(
                      (batch) => (usedBodySitesPerBatch?.[batch.lotNumber!] ?? []).length !== bodySites!.length,
                    )
                  : availableBatches

                return (
                  <div key={batchIndex} className="flex flex-col py-6">
                    <div className="flex flex-col flex-1 -space-y-2 pt-1 relative">
                      {configurationItem[medIndex]?.invData ? (
                        <FormField
                          field={`configurationItem[${medIndex}].medicationAdministration.contained[${batchIndex}].batch`}
                          labelClassName="pl-6"
                          label="Lot number"
                          validation={(value: MedicationBatch) =>
                            (!value || !value.lotNumber) && "Lot number is required"
                          }
                        >
                          {({ field: { name, value, onChange } }: FieldProps) => (
                            <Dropdown
                              id={name}
                              name={name}
                              className="p-inputtext-sm min-h-10"
                              options={effectiveBatches}
                              optionLabel="lotNumber"
                              placeholder="Select a lot number"
                              value={value}
                              onChange={(e) => {
                                //prevents the use of a preselected unavailable body site for a batch
                                handleSelectedBodySiteAvailabilityOnBatchChange()
                                onQtyChange(
                                  fieldValue[batchIndex]?.amount?.numerator?.value ?? 0,
                                  batchIndex,
                                  e.value?.lotNumber,
                                  e.value?.quantity,
                                )
                                onChange(e)
                              }}
                              itemTemplate={medLot}
                              valueTemplate={medLot}
                            />
                          )}
                        </FormField>
                      ) : (
                        <>
                          <InputField
                            label="Lot number"
                            field={`configurationItem[${medIndex}].medicationAdministration.contained[${batchIndex}].batch.lotNumber`}
                            validation={(value) => !value && "Lot number is required"}
                          />
                          <DateField
                            label="Expiration date"
                            field={`configurationItem[${medIndex}].medicationAdministration.contained[${batchIndex}].batch.expirationDate`}
                            stringFormatType="ISO_8601_DATETIME"
                            validation={(value) => !value && "Expiration date is required"}
                            minDate={new Date()}
                          />
                        </>
                      )}

                      <div className="flex flex-wrap flex-col @xs:flex-row gap-x-4">
                        <FormField
                          label="Quantity"
                          field={`configurationItem[${medIndex}].medicationAdministration.contained[${batchIndex}].amount.numerator.value`}
                          className="flex-1 shrink-0 w-full"
                        >
                          {({ field: { name, value, onChange } }: FieldProps) => (
                            <InputNumber
                              id={name}
                              name={name}
                              locale="en-US"
                              showButtons
                              min={1}
                              max={
                                maxQuantityByBatch[batchIndex] ??
                                (fieldValue[batchIndex]?.batch as InventoryData)?.quantity
                              }
                              allowEmpty={false}
                              value={value}
                              onValueChange={onChange}
                              className="input-text-center p-inputtext-sm"
                              inputClassName="min-w-0"
                              onBlur={() => onQtyChange(value, batchIndex)}
                              title={`${
                                maxQuantityByBatch[batchIndex] ??
                                (fieldValue[batchIndex]?.batch as InventoryData)?.quantity
                              }`}
                            />
                          )}
                        </FormField>
                        {isSingleBodySiteSelection && (
                          <FormField
                            field={`configurationItem[${medIndex}].bodySite.coding[${batchIndex}]`}
                            label="Administration site"
                            className="flex-1 shrink-0"
                            validation={(value: Coding) => (!value || !value.code) && "Administration site is required"}
                          >
                            {({ field: { name, value, onChange } }: FieldProps) => (
                              <Dropdown
                                id={name}
                                name={name}
                                className="p-inputtext-sm"
                                options={effectiveBodySites}
                                optionLabel="display"
                                value={value}
                                onChange={(e) => {
                                  onChange(e)
                                  onPelletChange(batchIndex, e.value)
                                }}
                              />
                            )}
                          </FormField>
                        )}
                      </div>
                      <div className="flex items-center justify-between absolute top-4 left-0 w-full h-4">
                        <span className="w-4 font-bold text-[0.65rem] rounded-full bg-red-900 text-white text-center">
                          {batchIndex + 1}
                        </span>
                        {fieldValue.length > 1 && (
                          <Button
                            icon={faTrashCan}
                            buttonStyle="text"
                            className="pr-0"
                            onClick={() => removeBatch(batchIndex)}
                            title="Remove batch"
                          />
                        )}
                      </div>
                    </div>
                    {batchIndex === fieldValue.length - 1 && (
                      <AddFieldArrayItemButton className="h-8 w-8 pl-0" onClick={addBatch} label="Add new batch" />
                    )}
                  </div>
                )
              })
            }}
          </FieldArray>
        )}
      </div>
      {!isSingleBodySiteSelection && (
        <div className="mt-2">
          <DropdownField
            field={`configurationItem[${medIndex}].zone`}
            label="Zone"
            optionLabel="display"
            optionValue=""
            dataKey="code"
            options={bodyZonesCodes}
            handleChange={(e) => onChangeBodyZone(e.value)}
            validation={(value) => !(value as Coding)?.code && "Zone is required"}
          />
          <BodySitesAndDosageField
            field={`configurationItem[${medIndex}].bodySite`}
            modalTitle={cardTitle}
            dosages={requiresDoseConfiguration ? dosage?.code?.split("|") ?? [] : []}
            configurationItem={configurationItem[medIndex]}
            onUpdateDosage={handelUpdateDosage}
          />
        </div>
      )}
    </div>
  )
}

type Props = {
  medIndex: number
  expanded: boolean
  onClick(): void
  onChangeBodySite(bodySiteCode: string[]): void
  onChangeBodyZone(bodyZone: Coding): void
  isSingleBodySiteSelection: boolean
  configCodes: string[]
  bodySiteActionCode?: Coding
  handelUpdateDosage(newDosage: number[], batchSelected: string[]): void
  isMassage: boolean
}

export { MedConfigurationItem }
