import { createElement } from "react"
import { toast } from "react-toastify"

import { CustomError } from "commons"
import { NotificationError } from "./components"

const email_support = window.VITE_APP_SUPPORT_EMAIL

const displayNotificationError = (error: CustomError) => {
  if (error.cause?.name !== "499") {
    toast.error(createElement(NotificationError, { error }), { closeOnClick: false })
  }
}

const getCustomErrorMessage = (error: CustomError) => {
  const cause = error.cause?.name
  if (["400", "405", "406", "413", "414", "422", "429", "500"].includes(cause as string)) {
    return "Internal Error - our team has been notified and is looking into the cause of this error."
  } else if (["401", "403", "407", "423"].includes(cause as string)) {
    return "Authentication Error - It seems we couldn't grant you access to this item. Please log out and try again."
  } else if (["404", "410"].includes(cause as string)) {
    return "Resource Not Found - the target resource could not be found."
  } else if (["402"].includes(cause as string)) {
    return "Payment Failure - There was an error processing your payment. Please verify your card information and try again."
  } else if (["409", "412"].includes(cause as string)) {
    return "Temporarily Unavailable - The resource you are trying to update has been modified.  Please wait a moment and try again."
  } else return "Unknown Error - our team has been notified and is looking into the cause of this error."
}

const copyToClipboard = async (trace: string, onSuccess?: () => void) => {
  navigator.clipboard
    .writeText(trace)
    .then(onSuccess)
    .catch(() => {
      console.error("Failed to copy. Check navigator permits.")
      /* Rejected - text failed to copy to the clipboard */
    })
}
const getDDurl = (errorID: string) =>
  encodeURIComponent(
    `https://us5.datadoghq.com/logs?query=status:error service:ehr -*AbortError @custom_trace:${errorID} &agg_m=count&agg_m_source=base&agg_q=service,env&agg_q_source=base,base&agg_t=count&cols=host,service&event=AgAAAZJDTdC4fdmwjQAAAAAAAAAYAAAAAEFaSkRUa1ZqQUFCTGJmaGFOUXhTRkFBQQAAACQAAAAAMDE5MjQzNGYtMmU3Ny00YjE3LWJmNTMtOTFmYzVlMzc4MWVj&fromUser=true&messageDisplay=inline&panel={"queryString":"env:dev service:ehr","filters":[{"isClicked":true,"source":"tag","path":"env","value":"dev"},{"isClicked":true,"source":"tag","path":"service","value":"ehr"}],"queryId":"a","timeRange":{"from":1727706000000,"to":1727706120000,"live":false}}&refresh_mode=sliding&sort_m=,&sort_m_source=,&sort_t=,&source=monitor_notif&storage=hot&stream_sort=desc&top_n=10,10&top_o=top,top&viz=timeseries&x_missing=true,true&from_ts=1727186202748&to_ts=1727200602748&live=true`,
  )

const getContactToSupportURL = (errorID: string) =>
  `mailto:${email_support}?subject=Error report ID: ${errorID}&body=Please do not delete the information below, it may be useful to solve your problem faster:%0D%0A%0D%0A${getDDurl(errorID)}%0D%0A%0D%0AIf you would like to tell us about your problem, please feel free to comment below %3A%0D%0A%0D%0A`

export { copyToClipboard, displayNotificationError, getContactToSupportURL, getCustomErrorMessage }
