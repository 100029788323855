import { faFolderMedical } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SettingItemArray } from "fhir"
import { Dropdown } from "primereact/dropdown"
import { InputSwitch } from "primereact/inputswitch"

import { catalogVisibility } from "data"

const MedicationCatalogSetting = ({ setting, isDisabled, onChange }: Props) => {
  const visibilityOptions = [
    {
      label: "Practice",
      value: [catalogVisibility.practiceVisibility],
    },
    {
      label: "Patient",
      value: [catalogVisibility.patientVisibility],
    },
    {
      label: "Both",
      value: [catalogVisibility.practiceVisibility, catalogVisibility.patientVisibility],
    },
  ]

  return (
    <div className="flex flex-col border-b last:border-b-0 mt-4">
      <div className="flex items-center flex-1">
        <FontAwesomeIcon icon={faFolderMedical} size="2x" className="w-8 text-gray-400" />
        <span className="font-medium capitalize ml-2">
          {setting.code?.display?.toLocaleLowerCase().replace("enabled", "") || "Unspecified"}
        </span>
      </div>
      <div className="flex items-center justify-between ml-10 mr-10 border-b py-4">
        <div className="flex flex-col">
          <span className="text-gray-700">Enable to order</span>
          <span className="text-sm text-gray-500">Enable to order medications from this catalog</span>
        </div>
        <InputSwitch
          disabled={isDisabled}
          checked={setting.value?.boolean ?? false}
          onChange={(e) => {
            onChange(setting.code?.code as string, {
              ...setting,
              flag: e.value ? [catalogVisibility.practiceVisibility] : undefined,
              value: {
                ...setting.value,
                boolean: e.value,
              },
            })
          }}
        />
      </div>
      <div className="flex items-center justify-between ml-10 mr-10 py-4">
        <div className="flex flex-col">
          <span className="text-gray-700">Visibility</span>
          <span className="text-sm text-gray-500">Set catalog visibility for the patient or practice</span>
        </div>
        <Dropdown
          className="p-inputtext-sm w-60"
          options={visibilityOptions}
          value={setting.flag}
          disabled={!setting.value?.boolean}
          onChange={(e) =>
            onChange(setting.code?.code as string, {
              ...setting,
              flag: e.value,
            })
          }
        />
      </div>
    </div>
  )
}

type Props = {
  setting: SettingItemArray
  isDisabled: boolean
  onChange(code: string, item: SettingItemArray): void
}

export { MedicationCatalogSetting }
