import { useQuery } from "@tanstack/react-query"
import { getResource, InventoryItem } from "fhir"

import { useClient } from "api"
import { useOrganizationContext } from "organization"

import { medsQueryKeys } from "../meds_query_keys"

const useMedicationAvailability = (mkId: string, medQuantity: number) => {
  const { search } = useClient()
  const { location } = useOrganizationContext()
  const queryKey = medsQueryKeys.medAvailability(mkId, medQuantity)

  const { data, isFetching, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        medication: mkId,
        location: location?.id as string,
      })

      const bundle = await search({ endpoint: "InventoryItem", filters, signal })
      const inventory = getResource<InventoryItem>(bundle, "InventoryItem")

      const medAvailability = inventory?.netContent?.value ?? 0

      return {
        medQuantityAvailable: medAvailability >= (medQuantity ?? 1),
        medAvailability,
      }
    },

    meta: { context: { queryKey, mkId } },
  })

  return {
    isLoadingAvailability: isFetching,
    medQuantityAvailable: data?.medQuantityAvailable,
    medAvailability: data?.medAvailability,
    refetchMedAvailability: refetch,
  }
}

export { useMedicationAvailability }
