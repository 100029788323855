import * as Yup from "yup"
import { ValidateOptions } from "yup/lib/types"
import { add } from "date-fns"

import {
  Appointment,
  Practitioner,
  asReference,
  Location,
  Reference,
  AppointmentParticipantArray,
  isLocation,
  isDevice,
} from "fhir"

const INITIAL_VALUES: Appointment = {
  status: "booked",
  start: new Date(),
  end: new Date(),
  description: "",
  participant: [],
  appointmentType: undefined,
  minutesDuration: 15,
}

const initialValues = (
  appointment: Appointment = INITIAL_VALUES,
  practitioner: Practitioner,
  rooms: Reference[],
  location?: Location,
) => {
  const { room, device } = appointment.participant.reduce<{
    room?: AppointmentParticipantArray
    device?: AppointmentParticipantArray
  }>(
    (prev, participant) => {
      if (isLocation(participant.actor)) {
        if (rooms.find(({ id }) => id === participant.actor?.id)) {
          return { ...prev, room: participant }
        }
      }

      if (isDevice(participant.actor)) {
        return { ...prev, device: participant }
      }

      return prev
    },
    {
      room: undefined,
      device: undefined,
    },
  )

  appointment.participant[0] = {
    actor: appointment.participant[0]?.actor,
    status: appointment.participant[0]?.status ?? "tentative",
  }
  appointment.participant[1] = {
    actor: appointment.participant[1]?.actor ?? asReference(practitioner),
    status: appointment.participant[1]?.status ?? "tentative",
  }
  appointment.participant[2] = {
    actor: appointment.participant[2]?.actor ?? (location ? asReference(location) : {}),
    status: appointment.participant[2]?.status ?? "tentative",
  }
  appointment.participant[3] = {
    actor: room?.actor,
    status: room?.status ?? "tentative",
  }
  appointment.participant[4] = {
    actor: device?.actor,
    status: device?.status ?? "tentative",
  }

  return {
    ...appointment,
  } as Appointment
}

const appointmentValidationSchema = (
  isParticipantInUse?: (date: Date, duration: number, participantId: string) => boolean,
) => {
  const getContextNeededInfo = (contextOptions: CustomYupValidateOptions) => {
    const parentContextIndex = contextOptions.from.length - 1
    const start = contextOptions.from?.[parentContextIndex]?.value.start
    const minutesDuration = contextOptions.from?.[parentContextIndex]?.value.minutesDuration
    const patientId = contextOptions.from?.[parentContextIndex]?.value.participant?.[0]?.actor?.id

    return { start, minutesDuration, patientId }
  }

  return Yup.object().shape({
    minutesDuration: Yup.number().required("Must specify appointment duration").min(5, "Minimum duration of 5 minutes"),
    start: Yup.date()
      .typeError("Start date is required")
      .required("Start date is required")
      .test(
        "test-no-overlapping-appointments",
        "Patient has an appointment already booked with in this time frame",
        (_, context) => {
          const { start, minutesDuration, patientId } = getContextNeededInfo(
            context.options as CustomYupValidateOptions,
          )
          if (patientId && isParticipantInUse) {
            return !isParticipantInUse(start ? new Date(start.toString()) : new Date(), minutesDuration ?? 0, patientId)
          }
          return true
        },
      ),
    appointmentType: Yup.object().required("Appointment type is required"),
    description: Yup.string().required("Description is required"),
    participant: Yup.array().of(
      Yup.object().shape({
        actor: Yup.object()
          .test("test-patient", "Patient is required", (value, context) => {
            return context.path === "participant[0].actor" ? value?.id : true
          })
          .test("test-practitioner", "Practitioner is required", (value, context) => {
            return context.path === "participant[1].actor" ? value?.id : true
          })
          .test("test-location", "Location is required", (value, context) => {
            return context.path === "participant[2].actor" ? value?.id : true
          })
          .test("test-room", "This room is reserved by appointment on the same date", (value, context) => {
            if (context.path === "participant[3].actor" && isParticipantInUse) {
              const { start, minutesDuration } = getContextNeededInfo(context.options as CustomYupValidateOptions)

              return value?.id
                ? !isParticipantInUse(start ? new Date(start.toString()) : new Date(), minutesDuration ?? 0, value.id)
                : true
            }

            return true
          })
          .test("test-device", "This device is reserved by appointment on the same date", (value, context) => {
            if (context.path === "participant[4].actor" && isParticipantInUse) {
              const { start, minutesDuration } = getContextNeededInfo(context.options as CustomYupValidateOptions)

              return value?.id
                ? !isParticipantInUse(start ? new Date(start.toString()) : new Date(), minutesDuration ?? 0, value.id)
                : true
            }

            return true
          }),
      }),
    ),
  })
}

const sanitize = ({ ...appointment }: Appointment) => {
  if (appointment.comment === "") {
    delete appointment.comment
  }

  if (appointment.start && appointment.minutesDuration) {
    appointment.end = new Date(add(new Date(appointment.start), { minutes: appointment.minutesDuration }))
  }

  const sanitizedParticipants = appointment.participant.filter(({ actor }) => actor?.id)

  return { ...appointment, participant: sanitizedParticipants.length ? sanitizedParticipants : appointment.participant }
}

type CustomYupValidateOptions = ValidateOptions & {
  from: Array<{ value: { start?: Date; minutesDuration?: number; participant?: Array<{ actor: { id?: string } }> } }>
}

export { appointmentValidationSchema, sanitize, initialValues, INITIAL_VALUES }
