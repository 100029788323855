import { Patient } from "fhir"
import { createContext, PropsWithChildren, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"

import { ModulesId } from "../../Module"
import { Plan, PLAN_SUBVIEWS } from "../types"

const PlanContext = createContext<PlanContextState | undefined>(undefined)
PlanContext.displayName = "PlanContext"

const PlanProvider = ({
  patient,
  plan,
  currentOrganizationId,
  updateCurrentPlan,
  navigateToSubview,
  children,
}: PropsWithChildren<Props>) => {
  const [params] = useSearchParams()
  const chartContext = useChartContext()
  const [completeDisabled, setCompleteDisabled] = useState(false)
  const [completedReviews, setCompletedReviews] = useState<string[]>()
  const [reviewsEnabled, setReviewsEnabled] = useState<boolean>()

  const isPlanView = useMemo(() => params.get("view") === ModulesId.PLANS, [params])

  const returnToListView = () => {
    chartContext.showModule({ module: isPlanView ? ModulesId.PLANS : ModulesId.MC })
  }

  if (!patient?.id) {
    throw new Error("Invalid patient id")
  }

  const handleConfigurePlan = (planToConfig: string) => {
    navigateToSubview(PLAN_SUBVIEWS.CONFIG, planToConfig)
  }

  const handleUpdateCompletedReviews = ({
    newCompleted,
    toRemove = false,
  }: {
    newCompleted: string | string[]
    toRemove?: boolean
  }) => {
    if (toRemove) {
      setCompletedReviews((completed) =>
        completed?.filter((taskId) =>
          Array.isArray(newCompleted) ? !newCompleted.includes(taskId) : taskId !== newCompleted,
        ),
      )
    } else {
      setCompletedReviews((completed) =>
        Array.isArray(newCompleted) ? newCompleted : [...(completed ?? []), newCompleted],
      )
    }
  }

  return (
    <PlanContext.Provider
      value={{
        patientId: patient.id,
        patient,
        plan: plan,
        isPlanView,
        updateCurrentPlan,
        currentOrganizationId: currentOrganizationId as string,
        navigateToSubview,
        handleConfigurePlan,
        setCompleteDisabled,
        completeDisabled,
        completedReviews,
        updateCompletedReviews: handleUpdateCompletedReviews,
        returnToListView,
        reviewsEnabled,
        setReviewsEnabled,
      }}
    >
      {children}
    </PlanContext.Provider>
  )
}

type Props = {
  patient: Patient
  currentOrganizationId?: string
  plan?: Plan
  updateCurrentPlan(plan?: Plan): void
  navigateToSubview(subview?: PLAN_SUBVIEWS, planId?: string): void
}

type PlanContextState = Props & {
  patientId: string
  currentOrganizationId: string
  completeDisabled?: boolean
  completedReviews?: string[]
  isPlanView: boolean
  reviewsEnabled?: boolean
  setReviewsEnabled(status?: boolean): void
  navigateToSubview(subview?: PLAN_SUBVIEWS, planId?: string): void
  handleConfigurePlan(planToConfig: string): void
  setCompleteDisabled(isDisabled: boolean): void
  updateCompletedReviews(_: { newCompleted: string | string[]; toRemove?: boolean }): void
  returnToListView(): void
}

export { PlanContext, PlanProvider }
