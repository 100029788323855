import { Dropdown } from "primereact/dropdown"
import { InputNumber } from "primereact/inputnumber"
import { Message } from "primereact/message"
import { FC, useCallback, useMemo, useState } from "react"

import { ModalDialog } from "commons"

import { getDoseNumberValue } from "../../utils"

const DoseHandlerModal: FC<Props> = ({
  dosages,
  baseDose,
  batchs,
  dosagesBatch,
  doseUnit,
  maxDosage,
  modalTitle,
  onHide,
  onUpdateDosage,
}) => {
  const [currentDosage, setCurrentDosage] = useState([
    ...(dosages ?? []).reduce<number[]>((acc, doseStr) => {
      const doseNum = getDoseNumberValue(doseStr, doseUnit)
      return [...acc, doseNum]
    }, []),
  ])
  const [batchSelected, setBatchSelected] = useState<string[]>(dosagesBatch)

  const { availableDoseUnits, maxUnits } = useMemo(() => {
    const batchDoses = batchs.map((b) => b.quantity * baseDose)
    const availableDoseUnits = currentDosage.reduce<number[]>((acc, dose, index) => {
      const batchIndex = parseInt(batchSelected[index] ?? "1") - 1
      acc[batchIndex] -= dose
      return acc
    }, batchDoses)
    const maxUnits = currentDosage.reduce<number[]>((acc, dose, index) => {
      const batchIndex = parseInt(batchSelected[index] ?? "1") - 1
      return [...acc, availableDoseUnits[batchIndex] + dose]
    }, [])
    return { availableDoseUnits, maxUnits }
  }, [currentDosage, batchSelected, batchs])

  const handleApplyUpdate = useCallback(() => {
    onUpdateDosage(currentDosage, batchSelected)
  }, [currentDosage])

  return (
    <ModalDialog
      onCancel={onHide}
      visible={true}
      showCancel
      cancelLabel="Close"
      title={`${modalTitle} (${maxDosage}${doseUnit})`}
      draggable
      showButtons
      onAccept={handleApplyUpdate}
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 grow p-1">
        {currentDosage?.map((dose, index) => (
          <div key={`${dose}_${index}`} className="p-fluid inline-flex flex-1 space-x-3 min-w-fit items-center">
            <label htmlFor={`${index}input`} className="whitespace-nowrap">
              Point {index + 1}:
            </label>
            <InputNumber
              inputId={`${index}_input`}
              value={dose}
              suffix={` ${doseUnit}`}
              max={maxUnits[index]}
              min={0}
              className="p-inputtext-sm w-14"
              allowEmpty={false}
              onValueChange={({ value }) => setCurrentDosage(currentDosage.toSpliced(index, 1, value ?? 0))}
            />
            <Dropdown
              options={batchs}
              optionLabel="label"
              optionValue="index"
              value={batchSelected[index]}
              className="p-inputtext-sm small-trigger"
              onChange={(e) => setBatchSelected(batchSelected.toSpliced(index, 1, e.value))}
            />
          </div>
        ))}
      </div>

      <Message
        severity={availableDoseUnits ? "info" : "warn"}
        text={
          !availableDoseUnits
            ? `The ${modalTitle} dose has been fully distributed. You can remove points or reduce the
  dose on any point.`
            : `Distributing ${maxDosage}${doseUnit} of ${modalTitle}`
        }
        className="flex flex-1 justify-center self-center"
      />
    </ModalDialog>
  )
}

type Props = {
  dosages: string[]
  batchs: { label: string; quantity: number; index: string }[]
  dosagesBatch: string[]
  baseDose: number
  maxDosage: number
  doseUnit: string
  modalTitle?: string
  onHide(): void
  defaultDoseUnitsToAdd?: number
  onUpdateDosage(updatedDosage: number[], batchSelected: string[]): void
}

export { DoseHandlerModal }
