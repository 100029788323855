import { datadogRum } from "@datadog/browser-rum"

import packageJson from "../../package.json"

const isDevelopment = process.env.NODE_ENV === "development"

const dataDogInit = () => {
  datadogRum.init({
    applicationId: "b6b01c12-3330-4e5c-933f-7f67b38907cb",
    clientToken: "pub7a470640d0bafb85522b6beef8bd65db",
    site: "us5.datadoghq.com",
    service: packageJson.name,
    env: window.VITE_APP_ENVIROMENT_NAME,
    version: packageJson.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    beforeSend: () => {
      return !isDevelopment
    },
  })
}

export { dataDogInit, datadogRum }
