import { useQuery } from "@tanstack/react-query"
import { CodeableConcept, codeableConceptAsString, Condition, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { conditionsQueryKeys } from "../query-keys"

const useActiveConditions = (patientId: string) => {
  const { search } = useClient()
  const queryKey = conditionsQueryKeys.actives(patientId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        "clinical-status": "active",
        _elements: "id,code",
        _count: "50",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Condition`, filters, signal })
      const activeConditions = getResources(bundle) as Condition[]

      return { activeConditions: activeConditions ?? [], total: bundle?.total ?? 0 }
    },
    meta: { context: { queryKey, patientId } },
  })

  const { activeConditions, conditionCodes, count } = useMemo(() => {
    const defaultMap = {
      activeConditions: [] as string[],
      conditionCodes: [] as CodeableConcept[],
    }

    const { activeConditions, conditionCodes } =
      data?.activeConditions.reduce((conditions, condition) => {
        return {
          activeConditions: [...conditions.activeConditions, codeableConceptAsString(condition.code)],
          conditionCodes: condition.code
            ? [...conditions.conditionCodes, condition.code]
            : [...conditions.conditionCodes],
        }
      }, defaultMap) ?? defaultMap

    return { count: data?.activeConditions.length ?? 0, activeConditions, conditionCodes }
  }, [data?.activeConditions])

  return {
    activeConditions,
    conditionCodes,
    conditionsCount: count,
    conditionsLoading: isLoading,
  }
}

export { useActiveConditions }
