import { faCalendarDays } from "@fortawesome/pro-regular-svg-icons"
import { faDoNotEnter, faPencil } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FamilyMemberHistory, codeableConceptAsString } from "fhir"

import { StackedListItemProps } from "commons"
import { formatsByTypes } from "data"
import { formatDate, getGenderIcon, strCapitalize } from "utils"

const familyHistoryModelBuilder = (
  familyHistory: FamilyMemberHistory,
  edit?: () => void,
  remove?: () => void,
): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [
        { name: "Name", value: familyHistory.name ?? "Unspecified" },
        {
          name: "Age",
          value: familyHistory?.age?.Age?.value ? familyHistory?.age?.Age?.value + "yo" : "Unspecified age",
        },
      ],
    },

    {
      lineItems: [
        { name: "Relationship", value: strCapitalize(codeableConceptAsString(familyHistory.relationship)) },
        ...(familyHistory.date
          ? [
              {
                name: "Recorded date",
                value: formatDate(new Date(familyHistory.date), formatsByTypes.LONG_DATETIME),
                icon: faCalendarDays,
              },
            ]
          : []),
        { name: "Biological Sex", icon: getGenderIcon(codeableConceptAsString(familyHistory.sex)) },
      ],
    },
  ],
  badge: familyHistory.deceased?.boolean ? { text: "deceased" } : undefined,
  menu: [
    ...(edit
      ? [
          {
            label: "Edit",
            icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
            command: edit,
          },
        ]
      : []),
    ...(remove
      ? [
          {
            label: "Deactivate",
            icon: <FontAwesomeIcon icon={faDoNotEnter} size="sm" className="mr-2" />,
            command: remove,
          },
        ]
      : []),
  ],
})

export { familyHistoryModelBuilder }
