import { classNames } from "primereact/utils"
import { Observation } from "fhir"

import { Badge } from "commons"

import { TriadData } from "../../types"
import { triads } from "../../data"

const TriadItem = ({ triadItem }: Props) => (
  <div className="flex p-2 rounded-lg">
    <span
      className={classNames(
        "rounded-full border flex items-center justify-center w-8 h-8 text-xs",
        triads[triadItem.id].borderColor,
      )}
    >
      {triadItem.triad.value?.Quantity?.value}
    </span>
    <img src={triads[triadItem.id].img} alt="" className="w-24" />
    <div className="flex flex-1 flex-col ml-14 mr-4 space-y-0.5">
      <div className="grid grid-cols-3">
        <div className="col-start-2 flex place-self-center items-center w-72 gap-1">
          <span className="flex-1 text-center px-2 text-[0.65rem] font-semibold uppercase">Symptoms</span>
          <span className="flex-1 text-center px-2 text-[0.65rem] font-semibold uppercase">Labs</span>
          <span className="flex-1 text-center px-2 text-[0.65rem] font-semibold uppercase">Total</span>
        </div>
      </div>
      {triadItem.organs?.map((o) => <TriadDataItem key={o.id} obs={o.organ} />)}
    </div>
  </div>
)

const TriadDataItem = ({ obs }: { obs: Observation }) => {
  const symptValue = obs.component?.find((c) => c.code?.coding?.[0]?.code === "survey")?.value?.Quantity?.value
  const labValue = obs.component?.find((c) => c.code?.coding?.[0]?.code === "labs")?.value?.Quantity?.value
  const risk = obs.interpretation?.[0]?.text

  return (
    <div className="grid grid-cols-3 rounded-md bg-gray-100">
      <span className="font-medium px-2 p-1">{obs.code.text}</span>
      <div className="flex place-self-center items-center w-72 gap-1">
        <span className="flex-1 text-center font-medium bg-white rounded-lg px-2" title="Symptom score">
          {symptValue}
        </span>
        <span className="flex-1 text-center font-medium bg-white rounded-lg px-2" title="Lab score">
          {labValue}
        </span>
        <span className="flex-1 text-center font-medium bg-white rounded-lg px-2" title="Total">
          {obs.value?.Quantity?.value}
        </span>
      </div>
      <div className="text-right">
        <Badge
          className="mr-4 my-2"
          text={`${risk} risk`}
          colorStyle={risk === "Low" ? "green" : risk === "High" ? "red" : "yellow"}
        />
      </div>
    </div>
  )
}

type Props = {
  triadItem: TriadData
}

export { TriadItem }
