import {
  AutocompleteCodeableConceptField,
  CodeableConceptField,
  DateField,
  DropdownField,
  MultiSelectField,
  TextAreaField,
  ValueSetIds,
} from "commons"
import {
  allergyCategoryCodes,
  allergyClinicalStatusCodes,
  allergyCriticalityCodes,
  allergySeverityCodes,
  allergyTypeCodes,
} from "data"

const AllergiesForm = ({ isEditing }: Props) => (
  <div className="relative p-fluid grid gap-4 mb-3">
    <AutocompleteCodeableConceptField
      field="code"
      label="Code"
      valueSetId={ValueSetIds.ALLERGY_CODE}
      readonly={isEditing}
    />
    <MultiSelectField field="category" label="Category" options={allergyCategoryCodes} />
    <div className="flex justify-between space-x-6">
      <CodeableConceptField
        className="w-full"
        field="clinicalStatus"
        label="Clinical status"
        codes={allergyClinicalStatusCodes}
      />
      <DropdownField className="w-full" field="type" label="Type" options={allergyTypeCodes} />
    </div>
    <div className="flex justify-between space-x-6">
      <DropdownField className="w-full" field="criticality" label="Criticality" options={allergyCriticalityCodes} />
      <DropdownField
        className="w-full"
        field="reaction[0]['severity']"
        label="Severity"
        options={allergySeverityCodes}
      />
    </div>
    <div className="flex justify-between space-x-6">
      <DateField
        className="w-full"
        field="onset['dateTime']"
        label="Onset"
        showTime
        stringFormatType="ISO_8601_DATETIME"
      />
      <DateField
        className="w-full"
        field="lastOccurrence"
        label="Last Occurrence"
        showTime
        stringFormatType="ISO_8601_DATETIME"
      />
    </div>
    <TextAreaField field="reaction[0]['manifestation'][0].text" label="Manifestation" rows={3} />
    <TextAreaField field="note[0].text" label="Note" rows={5} />
  </div>
)

type Props = {
  isEditing?: boolean
}

export { AllergiesForm }
