import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Condition } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { conditionsQueryKeys } from "conditions"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { plansQueryKeys } from "../query-keys"

const useAddCondition = (onSettled: () => void) => {
  const { create, update } = useClient()
  const queryClient = useQueryClient()

  const newCondition = async ({ planId, condition }: { patientId: string; planId: string; condition: Condition }) =>
    condition.id
      ? update("Condition", condition.id as string, condition)
      : create(`CarePlan/${planId}/$add-resource`, condition)

  const { mutate: addCondition, isPending: isAdding } = useMutation({
    mutationFn: newCondition,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { patientId, planId, condition }) => {
      await queryClient.refetchQueries({
        queryKey: plansQueryKeys.manualConditions(patientId, planId),
        type: "all",
      })
      await queryClient.invalidateQueries({
        queryKey: conditionsQueryKeys.list(patientId, "KP"),
        refetchType: "active",
      })
      displayNotificationSuccess(`Condition ${condition.id ? "updated" : "added"} successfully!`)
      // datadogLogs.logger.info(`Manual Condition of plan ${planId} ${condition.id ? "updated" : "added"} successfully!`)
    },
    onSettled,
  })

  return { addCondition, isAdding }
}

export { useAddCondition }
