import { useContext } from "react"

import { AppModuleContext } from "../context/AppModuleProvider"

const useAppModuleContext = () => {
  const context = useContext(AppModuleContext)

  if (context === undefined) {
    throw new Error("useAppModuleContext must be used within a AppModuleProvider")
  }

  return context
}

export { useAppModuleContext }
