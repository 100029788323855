import { faCalendarCheck, faCalendarDays, faPills, faVials } from "@fortawesome/pro-solid-svg-icons"
import { ServiceRequest } from "fhir"
import { format, parseISO } from "date-fns"

import { MenuStyles, StackedListItemProps } from "commons"
import { formatsByTypes, isMedicationOrder } from "data"
import { strCapitalize } from "utils"

const orderModelBuilder = (order: ServiceRequest, showOrder: () => void): StackedListItemProps => {
  const itemsCount =
    order.basedOn?.filter((ref) =>
      isMedicationOrder(order) ? ref.resourceType === "MedicationRequest" : ref.resourceType === "ServiceRequest",
    ) ?? []

  const data = isMedicationOrder(order)
    ? [
        {
          lineItems: [
            {
              name: "Order number",
              value: order.identifier?.[0]?.value ?? "Unspecified number",
            },
          ],
        },
        {
          lineItems: [
            {
              name: "Ocurrence",
              value: format(
                new Date(order?.occurrence?.dateTime ?? (order?.authoredOn as string)),
                formatsByTypes.LONG_DATE,
              ),
              icon: faCalendarDays,
            },
            { name: "Medication Requests", value: `${itemsCount.length}`, icon: faPills },
          ],
        },
      ]
    : [
        {
          lineItems: [{ name: "Laboratory", value: strCapitalize(order.performer?.[0]?.display as string) }],
        },
        {
          lineItems: [
            {
              name: "Authored on",
              value: format(parseISO(order.authoredOn as string), formatsByTypes.LONG_DATE),
              icon: faCalendarDays,
            },
            { name: "Tests", value: `${itemsCount.length}`, icon: faVials },
            ...(order.occurrence?.dateTime
              ? [
                  {
                    name: "Occurrence",
                    icon: faCalendarCheck,
                    value: format(parseISO(order.occurrence?.dateTime as string), formatsByTypes.LONG_DATE),
                  },
                ]
              : []),
          ],
        },
      ]

  return {
    leftData: data,
    menu: [{ command: showOrder, label: "Show order" }],
    menuStyle: MenuStyles.ExternalAction,
    badge: {
      text: order.status === "revoked" ? "cancelled" : order.status,
      colorStyle: order.status === "revoked" ? "red" : order.status === "completed" ? "green" : "blue",
    },
  }
}

export { orderModelBuilder }
