import { organizationSettingId, catalogVisibility } from "data"

import { useSettingQueryFunction } from "./useSettings"

const useVisibleMedicationCatalogs = (currentOrganizationId: string) => {
  const getSetting = useSettingQueryFunction()

  return async () => {
    const setting = await getSetting(currentOrganizationId, organizationSettingId.medicationsCatalogs)

    return (
      setting.items?.reduce<string[]>(
        (acc, item) =>
          item.value?.boolean &&
          item.flag?.some((cc) => cc.coding?.[0]?.code === catalogVisibility.practiceVisibility.coding?.[0]?.code)
            ? [...acc, item.value.Reference?.id as string]
            : acc,
        [],
      ) ?? []
    )
  }
}

export { useVisibleMedicationCatalogs }
