import { useInfiniteQuery } from "@tanstack/react-query"
import {
  getResources,
  humanNameAsString,
  Medication,
  MedicationAdministration,
  MedicationRequest,
  Practitioner,
  PractitionerRole,
  Procedure,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { BatchData, bodyZonesCodes, ConfigurationItem, ProcedureData, ProcedureKind } from "commons/procedures"
import { getProcedureKind } from "commons/procedures/utils"

import { proceduresQueryKeys } from "../query-keys"

const usePatientProcedures = ({
  patientId,
  textFilter,
  statusFilter,
  encounter,
  procedureId,
}: PatientProceduresArgs) => {
  const { search } = useClient()
  const queryKey = proceduresQueryKeys.list(patientId, textFilter, statusFilter, encounter, procedureId)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    PatientProceduresQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-createdAt",
        ...(statusFilter && statusFilter.length > 0 ? { status: statusFilter.join(",") } : {}),
        ...(textFilter ? { "code:text": textFilter } : {}),
        _revinclude: "MedicationAdministration:part-of",
        _include: "MedicationAdministration:request, performer",
        ...(encounter ? { encounter } : {}),
        ...(procedureId ? { _id: procedureId } : {}),
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Procedure`, filters, signal })

      const procedures = getResources<Procedure>(bundle, "Procedure")
      const medicationAdministrations = getResources<MedicationAdministration>(bundle, "MedicationAdministration")
      const medicationRequests = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const practitioners = getResources<Practitioner>(bundle, "Practitioner")
      const practitionerRoles = getResources<PractitionerRole>(bundle, "PractitionerRole")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return {
        procedures,
        medicationAdministrations,
        medicationRequests,
        practitioners,
        practitionerRoles,
        next,
        total: bundle?.total ?? 0,
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  const { proceduresData, count } = useMemo(() => {
    const procedures = data?.pages.flatMap((page) => page.procedures)
    const medicationAdministrations = data?.pages.flatMap((page) => page.medicationAdministrations)
    const medicationRequests = data?.pages.flatMap((page) => page.medicationRequests)
    const practitioners = data?.pages.flatMap((page) => page.practitioners)
    const practitionerRoles = data?.pages.flatMap((page) => page.practitionerRoles)

    const proceduresData = procedures?.reduce((acc, procedure) => {
      const isMassage = getProcedureKind(procedure) === ProcedureKind.massage
      let configItems: ConfigurationItem[] | undefined
      if (isMassage) {
        configItems = procedure.bodySite?.map(
          (cc) =>
            ({
              bodySite: cc,
              zone: cc.coding?.[1],
            }) as ConfigurationItem,
        )
      } else {
        configItems = medicationAdministrations?.reduce((acc, ma) => {
          if (ma.partOf?.[0].id === procedure.id) {
            const mr = medicationRequests?.find((mr) => mr.id === ma.request?.id)
            const bodySite = {
              text: ma.dosage?.site?.text,
              coding: ma.dosage?.site?.coding?.filter(({ code }) => !bodyZonesCodes.find((c) => c.code === code)),
            }
            const medBaseDose = {
              ...ma.dosage?.dose,
              value: ma.dosage?.dose?.value ? ma.dosage.dose.value / (mr?.dispenseRequest?.quantity?.value ?? 1) : 1,
            }
            const batches = (ma?.contained as Medication[])?.reduce<BatchData[]>(
              (acc, med, index) => [
                ...acc,
                {
                  label: `B ${index + 1} (${(med.amount?.numerator?.value ?? 1) * (medBaseDose.value as number)}${medBaseDose.unit ?? "mg"})`,
                  index: `${index + 1}`,
                  quantity: med.amount?.numerator?.value ?? 1,
                },
              ],
              [],
            )

            return [
              ...acc,
              {
                medicationAdministration: ma,
                medicationRequest: mr,
                bodySite,
                zone: ma.dosage?.site?.coding?.find(({ code }) => bodyZonesCodes.find((c) => c.code === code)),
                medBaseDose,
                medTotalDose: ma.dosage?.dose,
                batches,
              } as ConfigurationItem,
            ]
          }
          return acc
        }, [] as ConfigurationItem[])
      }

      const practitioner = practitioners?.find((pract) => pract.id === procedure.performer?.[0].actor.id)
      const practitionerRole = practitionerRoles?.find((practR) => practR.id === procedure.performer?.[0].actor.id)

      const newProcedure: ProcedureData = {
        procedure,
        configurationItem: configItems ?? [],
        performerName: practitionerRole?.practitioner?.display ?? humanNameAsString(practitioner?.name?.[0]),
        deletedMedications: [],
      }

      return [...acc, newProcedure]
    }, new Array<ProcedureData>())

    return {
      proceduresData: proceduresData ?? [],
      count: proceduresData?.length ?? 0,
    }
  }, [data?.pages])

  return {
    proceduresData,
    isLoading,
    count: count ?? 0,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

interface PatientProceduresArgs {
  patientId: string
  textFilter?: string
  statusFilter?: string[]
  encounter?: string
  procedureId?: string
}

type PatientProceduresQueryData = {
  procedures: Procedure[]
  medicationAdministrations: MedicationAdministration[]
  medicationRequests: MedicationRequest[]
  practitioners: Practitioner[]
  practitionerRoles: PractitionerRole[]
  next: number | undefined
  total: number
}

export { usePatientProcedures }
