import { faPills } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { MedicationsStatementContainer } from "../../../medications-statement/components/MedicationsStatementContainer"

class MedicationESubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.MEDICATIONE
    this.display = "Medications Statements"
    this.parent = ModulesId.INTAKE
    this.icon = faPills
    this.content = <MedicationsStatementContainer />
  }
}

export { MedicationESubModule }
