import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Procedure, ResourceObject } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { proceduresQueryKeys } from "../query-keys"

const usePatchProcedure = (
  onSettled?: (data: ResourceObject | undefined, error: CustomError | null, variables: Procedure) => void,
) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const onPatch = async (procedure: Procedure) => patch("Procedure", procedure.id as string, procedure)

  const { mutate: patchProcedure, isPending } = useMutation({
    mutationFn: onPatch,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: proceduresQueryKeys.all })
      displayNotificationSuccess("Procedure updated successfully!")
      // datadogLogs.logger.info("Procedure updated successfully!", { procedure })
    },
    onSettled,
  })

  return { patchProcedure, isDeleting: isPending }
}

export { usePatchProcedure }
