import { useQuery } from "@tanstack/react-query"
import { differenceInDays, isFuture } from "date-fns"
import { Appointment, CarePlan, getResources, getResourcesByTypeAsIndex, PlanDefinition, Task } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { PD_ACTION_DYNAMIC_VALUE } from "data"

import { plansQueryKeys } from "../query-keys"
import { CarePlanHistory, PLAN_ACTION_CODES, TASK_CODES } from "../types"
import { getCPDate } from "../utils"

const useCarePlanHistory = (patientId: string, planId?: string) => {
  const { search } = useClient()
  const queryKey = plansQueryKeys.history(patientId, planId)

  const { data, isLoading, isRefetching, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _id: planId as string,
        _sort: "-createdAt",
        _revinclude: "CarePlan:replaces:CarePlan",
        _include:
          "replaces,outcome-reference:Task,instantiates-canonical:PlanDefinition,PlanDefinition:definition:PlanDefinition",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/CarePlan`, filters, signal })

      const careplan = getResources<CarePlan>(bundle, "CarePlan")
      const planDefinition = getResources<PlanDefinition>(bundle, "PlanDefinition")

      // Handle get Appointment for revincluded CPs
      const filterAppointment = new URLSearchParams({
        _id: careplan.map((cp) => cp.id).join(","),
        _elements: "id",
        _include: "outcome-reference:Appointment",
      })
      const bundleAppointment = await search({
        endpoint: `Patient/${patientId}/CarePlan`,
        filters: filterAppointment,
        signal,
      })
      const indexedAppointment = getResourcesByTypeAsIndex<Appointment>(bundleAppointment, "Appointment")

      const tasks = getResourcesByTypeAsIndex<Task>(bundle, "Task")

      return {
        careplan,
        indexedAppointment,
        planDefinition,
        tasks,
      }
    },

    enabled: !!planId,
    meta: { context: { queryKey, patientId } },
  })

  const { appointmentHistory, nextAppointment, lastActiveAppointment } = useMemo(() => {
    const indexedPD =
      data?.planDefinition.reduce<Record<string, PlanDefinition>>(
        (acc, pd) => ({ ...acc, [`${pd.url}|${pd.version}`]: pd }),
        {},
      ) ?? {}

    const { appointmentHistory, nextAppointment } = data?.careplan?.reduce<CPH>(
      (acc, carePlan) => {
        const appointmentId = carePlan.activity?.find((a) => a.outcomeReference?.[0]?.resourceType === "Appointment")
          ?.outcomeReference?.[0]?.id

        let candidateTask: Task | undefined
        const reviewTasks = carePlan.activity?.reduce((acc, { outcomeReference }) => {
          const reviewT = outcomeReference?.reduce((acc, { id, resourceType }) => {
            if (resourceType === "Task") {
              const task = data.tasks?.[id as string]
              if (task?.code?.coding?.some(({ code }) => code === TASK_CODES.REVIEW))
                return [...acc, ...(task ? [task] : [])]
              else if (task?.code?.coding?.some(({ code }) => code === PLAN_ACTION_CODES.PROCEDURE_CANDIDATE))
                candidateTask = task
            }
            return [...acc]
          }, Array<Task>())

          return [...acc, ...(reviewT ?? [])]
        }, Array<Task>())

        const cphItem = {
          carePlan,
          appointment: data?.indexedAppointment?.[appointmentId as string],
          reviewTasks,
          candidateTask,
        } as CarePlanHistory

        const isFutureAppointment =
          ((cphItem.appointment?.start && isFuture(new Date(cphItem.appointment.start))) ?? false) &&
          carePlan.status === "active"

        return {
          ...acc,
          ...(isFutureAppointment
            ? { nextAppointment: cphItem }
            : { appointmentHistory: [...acc.appointmentHistory, cphItem] }),
        }
      },
      { appointmentHistory: [] },
    ) ?? { appointmentHistory: [] }

    appointmentHistory.sort((a, b) => (getCPDate(a) > getCPDate(b) ? -1 : 1))

    const lastValidAppointment = ["active", "completed"].includes(appointmentHistory?.[0]?.carePlan?.status)
      ? appointmentHistory?.[0]
      : undefined

    const definition = indexedPD[lastValidAppointment?.carePlan?.instantiatesCanonical?.[0] as string]
    const fuAction = definition?.action?.find((a) => a.code?.[0]?.coding?.[0]?.code === "enroll-follow-up-plan")
    let fuToken = fuAction?.definition?.canonical

    /* Logic to check recursive enroll */
    const recursiveExpression = fuAction?.dynamicValue?.find(
      ({ path }) => path === PD_ACTION_DYNAMIC_VALUE.RECURSIVE_ENROLL,
    )?.expression?.expression

    const recursionUntilDays = recursiveExpression ? parseInt(recursiveExpression) : undefined

    if (lastValidAppointment?.appointment?.start && lastValidAppointment?.carePlan?.created && recursionUntilDays) {
      const lastOccurrenceCP = appointmentHistory.find(
        ({ carePlan }) => carePlan.id === lastValidAppointment.carePlan.partOf?.[0]?.id,
      )
      const daysPast = lastOccurrenceCP?.carePlan.created
        ? differenceInDays(lastValidAppointment.appointment?.start, lastOccurrenceCP?.carePlan.created)
        : differenceInDays(lastValidAppointment.appointment?.start, lastValidAppointment?.carePlan?.created)

      if (daysPast < recursionUntilDays) fuToken = lastValidAppointment.carePlan.instantiatesCanonical?.[0]
    }

    const lastActiveAppointment =
      lastValidAppointment?.carePlan?.status === "active"
        ? ({
            ...lastValidAppointment,
            ...(fuToken ? { nextPlanDefinition: indexedPD[fuToken] } : {}),
          } as CarePlanHistory & { nextPlanDefinition?: PlanDefinition })
        : undefined

    return {
      appointmentHistory,
      nextAppointment,
      lastActiveAppointment,
    }
  }, [data?.planDefinition, data?.careplan, data?.indexedAppointment, isRefetching])

  return {
    isLoading,
    appointmentHistory,
    nextAppointment,
    lastActiveAppointment,
    isRefetching,
    refetch,
  }
}

type CPH = {
  appointmentHistory: CarePlanHistory[]
  nextAppointment?: CarePlanHistory
}

export { useCarePlanHistory }
