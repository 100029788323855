import { faAllergies } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { AllergiesContainer } from "../../../allergies/components/AllergiesContainer"

class AllergySubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.ALLERGY
    this.display = "Allergies"
    this.parent = ModulesId.INTAKE
    this.icon = faAllergies
    this.content = <AllergiesContainer />
  }
}

export { AllergySubModule }
