import { faEye, faMemo } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormikContext } from "formik"
import { Dropdown } from "primereact/dropdown"
import { useEffect, useMemo, useState } from "react"

import { ConfirmDialog, FormField, ICD10CodesField, PractitionerRoleDropdownField } from "commons"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"

import { PROCEDURE_CONFIG, ProcedureData, ProcedureTypeData } from "../../types"
import { getProcedureKind } from "../../utils/transformers"
import { ProcedureConfigItems } from "./ProcedureConfigItems"

const ProcedureForm = ({ isEditing, carePlanId, planProcedure, procedureTypes, onEditMed, onEditNotes }: Props) => {
  const [confirmChangeType, setConfirmChangeType] = useState<string>()

  const { currentOrganizationId } = useOrganizationContext()
  const { organizationPractitionersInfo } = useOrganizationPractitioners({
    organizationId: currentOrganizationId,
  })

  const {
    values: {
      procedure,
      procedure: { status, instantiatesCanonical },
      configurationItem,
      deletedMedications,
    },
    setFieldValue,
  } = useFormikContext<ProcedureData>()

  const isMassage = useMemo(
    () =>
      procedureTypes[instantiatesCanonical?.[0] as string]?.action?.[0]?.code?.[0].coding?.[0]?.code ===
      PROCEDURE_CONFIG.BODY_SITE_COORDINATE,
    [instantiatesCanonical],
  )

  const onChangeProcedureType = (typeCanonical: string) => {
    if (
      procedure?.code &&
      getProcedureKind(procedure) !== getProcedureKind({ ...procedure, code: procedureTypes[typeCanonical]?.cc }) &&
      configurationItem.length > 0
    )
      setConfirmChangeType(typeCanonical)
    else changeProcType(typeCanonical)
  }

  const changeProcType = (typeCanonical: string) => {
    setFieldValue("procedure.instantiatesCanonical", procedureTypes[typeCanonical]?.canonical)
    setFieldValue("procedure.code", procedureTypes[typeCanonical]?.cc)
    setFieldValue("procedure.identifier", procedureTypes[typeCanonical]?.identifier)
  }

  const changeTypeConfirmed = () => {
    const delRefs = configurationItem.reduce<{ mrId: string; maId: string }[]>(
      (acc, med) => [
        ...acc,
        { mrId: med?.medicationRequest?.id as string, maId: med?.medicationAdministration?.id as string },
      ],
      [],
    )
    setFieldValue("deletedMedications", [...deletedMedications, ...delRefs])
    setFieldValue("configurationItem", [])
    changeProcType(confirmChangeType as string)
    setConfirmChangeType(undefined)
  }

  useEffect(() => {
    const typeCode = Object.values(procedureTypes).find(
      (p) => p.canonical[0] === instantiatesCanonical?.[0],
    ) as ProcedureTypeData
    setFieldValue("procedure.code", typeCode?.cc)
    setFieldValue("procedure.instantiatesCanonical", typeCode?.canonical)
    setFieldValue("procedure.identifier", typeCode?.identifier)
  }, [procedureTypes, instantiatesCanonical])

  return (
    <div className="flex gap-5">
      <div className="flex flex-col flex-1 gap-5">
        <FormField field="procedure.instantiatesCanonical" label="Type">
          {({ field: { value } }) => (
            <Dropdown
              className="p-inputtext-sm"
              options={!!carePlanId && planProcedure ? [planProcedure] : Object.values(procedureTypes)}
              optionLabel="cc.text"
              optionValue="canonical"
              value={value}
              onChange={(e) => onChangeProcedureType(e.value?.[0])}
              disabled={!!carePlanId || isEditing}
            />
          )}
        </FormField>
        <PractitionerRoleDropdownField
          field="procedure.performer[0].actor"
          label="Performer"
          options={organizationPractitionersInfo ?? []}
          disabled={isEditing && status !== "preparation"}
        />
        <div className="field flex flex-col relative">
          <label className="text-sm font-medium text-gray-700 mb-2">Notes</label>
          <div className="flex justify-between items-center text-sm py-2 border-b cursor-pointer" onClick={onEditNotes}>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faMemo} />
              <span className="">Notes</span>
            </div>
            <FontAwesomeIcon icon={faEye} />
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 gap-5">
        {!isMassage && (
          <div className="rounded-md border border-gray-300 p-4">
            <ICD10CodesField
              label="ICD-10 Codes"
              field="procedure.reasonCode"
              readOnly={isEditing && status !== "preparation"}
              orgSuggestedConditions="defaultProcedureIcd10"
            />
          </div>
        )}
        <div className="rounded-md border border-gray-300 p-4">
          <ProcedureConfigItems
            readOnly={isEditing && status !== "preparation"}
            isMassage={isMassage}
            onEditMed={onEditMed}
          />
        </div>
      </div>
      <ConfirmDialog
        visible={!!confirmChangeType}
        hideDialog={() => setConfirmChangeType(undefined)}
        onConfirm={changeTypeConfirmed}
        confirmText={`By changing procedure type, all configured ${isMassage ? "massages" : "medications"} will be removed`}
      />
    </div>
  )
}

type Props = {
  isEditing: boolean
  carePlanId?: string | null
  planProcedure?: ProcedureTypeData
  procedureTypes: Record<string, ProcedureTypeData>
  onEditMed(medIndex: number): void
  onEditNotes(): void
}

export { ProcedureForm }
