import { faCalendarCheck, faFileCircleCheck, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns-tz/format"
import { DiagnosticReport } from "fhir"
import { classNames } from "primereact/utils"
import { FC, useState } from "react"

import { formatsByTypes } from "data"

import { useShowSignedUrlDocument } from "../../hooks"
import { useReleaseLabResult } from "../hooks/useReleaseLabResult"

const LaboratoryOrderPreviousResults: FC<Props> = ({ orderId, previousResults }) => {
  const [releaseRequested, setReleaseRequested] = useState("")

  const { showDocument } = useShowSignedUrlDocument()
  const { releaseResult, isUpdating } = useReleaseLabResult(orderId, () => setReleaseRequested(""))

  const handleReleaseResult = (drId?: string) => {
    if (drId) {
      releaseResult(drId)
      setReleaseRequested(drId)
    }
  }

  return (
    <div className="flex flex-col gap-4 [&:not(:first-child)]:pt-8 [&:not(:first-child)]:mt-6">
      <span className="text-primary font-semibold text-sm">Activity</span>

      <div className="text-sm rounded-lg border p-4 text-gray-500">
        <ul className="grid grid-cols-2 2xl:grid-cols-3">
          {previousResults.map((dr) => {
            const releasedDate =
              dr.released && format(new Date(dr.released), formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE)
            const isRealeasing = isUpdating && releaseRequested === dr.id

            return (
              <li key={dr.id} className="inline-flex items-center space-x-2">
                <div
                  className="text-sm text-gray-500 underline cursor-pointer hover:text-primary-hover"
                  title="See results"
                  onClick={() => showDocument(dr.presentedForm?.[0]?.url ?? "")}
                >
                  {dr.status === "final" ? "Final" : "Partial"} results received:{" "}
                  {format(new Date(dr.issued as Date), formatsByTypes.SHORT_DATETIME_WITH_TIMEZONE)}
                </div>

                <FontAwesomeIcon
                  icon={isRealeasing ? faSpinner : releasedDate ? faCalendarCheck : faFileCircleCheck}
                  className={classNames("hover:text-primary-hover ml-2 text-gray-500", {
                    "cursor-pointer": !releasedDate,
                  })}
                  title={releasedDate ? `Released on ${releasedDate}` : "Release to Patient"}
                  onClick={!releasedDate ? () => handleReleaseResult(dr.id) : undefined}
                  spin={isRealeasing}
                />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

type Props = {
  orderId: string
  previousResults: DiagnosticReport[]
}

export { LaboratoryOrderPreviousResults }
