import { faLinkHorizontal, faSpinner } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Skeleton } from "primereact/skeleton"
import { ReactNode } from "react"
import { classNames } from "primereact/utils"

const InfoRow = ({ className, title, content, showLink, loading, spinnerLoading }: Props) => (
  <div
    className={classNames(
      "flex w-full items-center text-slate-400 text-sm border-b last:border-0 py-5 first:pt-0",
      className,
    )}
  >
    <p className="w-1/6 font-semibold">{title}</p>
    <div className="w-5/6 whitespace-nowrap text-ellipsis overflow-hidden">
      {loading ? spinnerLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : <Skeleton /> : content}
    </div>
    {showLink && (
      <div>
        <FontAwesomeIcon icon={loading ? faSpinner : faLinkHorizontal} spin={loading} className="cursor-pointer ml-5" />
      </div>
    )}
  </div>
)

type Props = {
  className?: string
  title: string
  content: string | ReactNode
  showLink?: boolean
  loading?: boolean
  spinnerLoading?: boolean
}

export { InfoRow }
