import { faUserMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons"
import { codeableConceptAsString, Observation } from "fhir"
import { FC } from "react"

import { VITAL_CODE, VITAL_ICON_MAP, VitalsKnowledgeItem } from "vitals"

const IntakeObservationsSection: FC<Props> = ({ observations }) => {
  const renderObservationData = (observation: Observation) => {
    const code = observation && codeableConceptAsString(observation.code)
    const value =
      observation?.value?.Quantity && `${observation.value.Quantity.value} ${observation.value.Quantity.unit}`

    return code && value ? (
      <VitalsKnowledgeItem
        label={code}
        itemContent={value}
        icon={VITAL_ICON_MAP[observation.code.coding?.[0]?.code as string] ?? faUserMagnifyingGlass}
        className="py-3"
        lastElementClassName="border-none"
        key={observation.id}
      />
    ) : undefined
  }

  const { vitals, notes } = observations.reduce(
    (acc, ob) => {
      if (ob.category?.some(({ coding }) => coding?.some(({ code }) => code === VITAL_CODE)))
        return { ...acc, vitals: [...acc.vitals, ob] }
      return { ...acc, notes: [...acc.notes, ob] }
    },
    { vitals: [], notes: [] } as { vitals: Observation[]; notes: Observation[] },
  )

  return (
    <>
      {!!notes.length && (
        <div className="flex flex-wrap items-center gap-4 pl-3">{notes.map((obs) => renderObservationData(obs))}</div>
      )}
      {!!vitals.length && (
        <div className="gap-4">
          <h2 className="text-gray-900 font-semibold">Vitals</h2>
          <div className="flex flex-wrap items-center gap-4 pl-3">
            {vitals.map((obs) => renderObservationData(obs))}
          </div>
        </div>
      )}
    </>
  )
}

type Props = {
  observations: Observation[]
}

export { IntakeObservationsSection }
