import { Invoice, MedicationRequest, Money, Organization, Patient, ServiceRequest } from "fhir"
import { createContext, useContext } from "react"

import { MedicationRequestData } from "commons/meds"

import { EDIT_ORDER_STEPS, InvoiceData } from "../types"

const MrOrderContext = createContext<State | undefined>(undefined)
MrOrderContext.displayName = "MrOrderContext"

const useMrOrderContext = () => {
  const context = useContext(MrOrderContext)

  if (!context) throw new Error("useMrOrderContext must be used withing MrOrderContext")

  return context
}

export type State = {
  serviceRequest?: ServiceRequest
  medicationRequestData: MedicationRequestData[]
  invoice?: Invoice
  editedMedicationRequests: Map<string, MedicationRequest>
  addEditedMedicationRequest(_: MedicationRequest): void
  currentStep: EDIT_ORDER_STEPS
  moveStep(_?: { previous: boolean; invoiceData?: InvoiceData }): void
  isLoading: boolean
  medicationRequestDataWithPrice: (MedicationRequestData & { patientPrice?: Money; practicePrice?: Money })[]
  invoiceData?: InvoiceData
  patient: Patient
  currentOrganization: Organization
  isEditable?: boolean
}

export { MrOrderContext, useMrOrderContext }
