import { faLocationDot, faUser } from "@fortawesome/pro-light-svg-icons"

import {
  ConfirmDialog,
  DataContainerSlideoverForm,
  InfiniteScroll,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  StackedListItemProps,
  useCrudReducer,
} from "commons"
import { ReplaceFormProvider } from "commons/context"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"
import { getBadgeColor, getStringAddress, strCapitalize } from "utils"
import { defaultEditRemoveMenu } from "utils-components"

import { useCoverages, useCreateCoverage, useDeleteCoverage } from "../hooks"
import { CoverageData } from "../types"
import { CoverageForm } from "./CoverageForm"
import { coverageValidationSchema, getCoverageInitialValues, sanitizeCoverage } from "./validations"

const CoveragesContainer = () => {
  const { patientId } = usePatientContext()
  const { appSubModules } = useAppModuleContext()

  const { showSlide, initialValue, deleteIndex, add, edit, reset, setDeleteIndex } = useCrudReducer({
    defaultEntity: getCoverageInitialValues(patientId),
  })

  const { coverages, isLoading } = useCoverages(patientId)
  const { createCoverage } = useCreateCoverage(reset)
  const { deleteCoverage, isDeleting } = useDeleteCoverage(() => setDeleteIndex(undefined))

  if (isLoading) return <SkeletonLoader repeats={4} loaderType="two-lines" />

  return (
    <ReplaceFormProvider>
      <DataContainerSlideoverForm
        hasData={!!coverages?.length}
        showSlide={showSlide}
        formTitle={"Insurance"}
        subMessageDataNotFound={false}
        formInitialValue={initialValue}
        validationSchema={coverageValidationSchema}
        onSubmit={(coverage) => createCoverage(sanitizeCoverage(coverage))}
        onCancel={reset}
        form={<CoverageForm />}
        customAddButtonText="Add Insurance Information"
        onButtonAddClick={add}
        iconDataNotFound={appSubModules["patient"][ModulesId.PATIENT_COVERAGES].getIcon()}
      >
        <div className="bg-white h-full overflow-auto">
          <InfiniteScroll>
            <StackedListContainer
              data={coverages ?? []}
              itemModelBuilder={(item) =>
                coverageModelBuilder(
                  item,
                  () => edit(item),
                  () => setDeleteIndex(item.id),
                )
              }
            />
          </InfiniteScroll>
        </div>
        <ConfirmDialog
          confirmText={`Are you sure you want to remove this insurance?`}
          actionName="Remove"
          visible={deleteIndex !== undefined || isDeleting}
          onConfirm={() => deleteCoverage(deleteIndex as string)}
          hideDialog={() => setDeleteIndex(undefined)}
          waitToFinish
          isLoading={isDeleting}
        />
      </DataContainerSlideoverForm>
    </ReplaceFormProvider>
  )
}

const coverageModelBuilder = (
  coverage: CoverageData,
  onEdit: () => void,
  onDelete: () => void,
): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [{ name: "Carrier", value: strCapitalize(coverage.payor?.[0]?.display ?? "") }],
    },
    ...(coverage.payor[0].display
      ? [
          {
            lineItems: [
              {
                name: "Subscriber",
                value: coverage.beneficiary.id === coverage.subscriber?.id ? "Self" : coverage.subscriber?.display,
                icon: faUser,
              },
              {
                name: "Subscriber ID",
                value: `#${coverage.subscriberId}`,
              },
              {
                name: "Payor Address",
                value: getStringAddress(coverage.payor[0].address),
                icon: faLocationDot,
              },
            ],
          },
        ]
      : []),
  ],
  badge: getBadgeColor(coverage.status),
  menu: defaultEditRemoveMenu(onEdit, onDelete),
})

export { CoveragesContainer }
