import { faAddressBook } from "@fortawesome/pro-regular-svg-icons"

import { SubModule, ModulesId } from "commons"

import { PatientContacts } from "../../../patients/components/PatientContacts"

class ContactsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.PATIENT_CONTACTS
    this.display = "Contacts"
    this.parent = ModulesId.PATIENT
    this.icon = faAddressBook
    this.content = <PatientContacts />
  }
}

export { ContactsSubModule }
