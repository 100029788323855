import { AccountBETACreditCardArray, humanNameAsString, isPatient, Organization, Patient } from "fhir"
import * as Yup from "yup"

import { emptyAddress } from "data"
import { getAddressByType, getAddressSchema } from "utils"

import { CreditCardFormData } from "../types"

const getInitialValues = (resource: Patient | Organization): CreditCardFormData => {
  const billingAddress =
    (isPatient(resource) ? getAddressByType("postal", resource.address) : resource.address?.[0]) ?? emptyAddress
  const cardHolderName = isPatient(resource) ? humanNameAsString(resource?.name?.[0]) : resource.name

  return {
    billingAddress,
    cardHolderName,
    expirationYear: undefined,
    expirationMonth: undefined,
    expirationDate: undefined,
    last4Digits: undefined,
    number: undefined,
    type: undefined,
    cvv: undefined,
  }
}

const sanitize = ({
  creditCard,
  isNew = false,
}: {
  creditCard: CreditCardFormData
  isNew?: boolean
}): AccountBETACreditCardArray => {
  if (isNew) {
    delete creditCard.expirationDate
    delete creditCard.number
    delete creditCard.cvv
  }
  return creditCard
}

const creditCardValidationSchema = Yup.object({
  billingAddress: getAddressSchema("billingAddress"),
  cardHolderName: Yup.string().required("Holder name is required"),
})

export { creditCardValidationSchema, getInitialValues, sanitize }
