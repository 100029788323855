import { classNames } from "primereact/utils"

import { triads as triadsData } from "../../data"

const triadEntries = Object.entries(triadsData)

const TriadFilter = ({ disabledTriads, setDisabledTriads, filterTriadsIds }: Props) => {
  const disabledTriadsCount = Object.values(disabledTriads).filter(Boolean).length
  const triads = filterTriadsIds?.length ? triadEntries.filter(([key]) => filterTriadsIds.includes(key)) : triadEntries

  const toggleTriad = (triadId: string) => {
    if (!disabledTriads[triadId] && disabledTriadsCount === triads.length - 1) {
      setDisabledTriads({})
      return
    }
    if (!disabledTriadsCount) {
      setDisabledTriads(Object.fromEntries(triads.map(([key]) => [key, key !== triadId])))
      return
    }
    const newState = { ...disabledTriads, [triadId]: !disabledTriads[triadId] }
    setDisabledTriads(newState)
  }

  return (
    <div className="flex items-center border-b border-gray-200 py-2 px-4  justify-between">
      <div className="flex items-center  gap-6">
        <span className="text-gray-500 font-medium">Filter</span>
        {triads.map(([triadId, { img }], index) => (
          <img
            key={index}
            src={img}
            alt=""
            className={classNames("w-14 cursor-pointer", { "opacity-20": disabledTriads[triadId] })}
            onClick={() => toggleTriad(triadId)}
          />
        ))}
      </div>
    </div>
  )
}

type Props = {
  filterTriadsIds?: string[]
  disabledTriads: Record<string, boolean>
  setDisabledTriads(state: Record<string, boolean>): void
}

export { TriadFilter }
