import { faDiagnoses } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { Patient360Conditions } from "../../../patient360/components/Patient360Conditions"

class Conditions360SubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.CONDITIONS_360
    this.display = "Conditions"
    this.parent = ModulesId.PATIENT_360
    this.icon = faDiagnoses
    this.content = <Patient360Conditions />
  }
}

export { Conditions360SubModule }
