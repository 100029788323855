import { CodeableConcept, Reference, RelatedPerson } from "fhir"
import * as Yup from "yup"

import { ContactPointSystem, emptyAddress, formatsByTypes, insuranceRelationship } from "data"
import { SYSTEM_VALUES } from "system-values"
import { formatDate, getAddressSchema, humanNameSchema, telecomSchema } from "utils"

import { CoverageData } from "../types"

const HIP_TYPE = {
  coding: [
    {
      code: "HIP",
      system: SYSTEM_VALUES.V3_ACTCODE,
      display: "health insurance plan policy",
    },
  ],
  text: "health insurance plan policy",
}

const GROUP_TYPE = {
  coding: [{ code: "group", system: SYSTEM_VALUES.COVERAGE_CLASS, display: "Group" }],
  text: "Group",
}

const getCoverageInitialValues = (patientId: string): CoverageData => ({
  type: HIP_TYPE,
  payor: [{ id: undefined, display: undefined, resourceType: "Organization" }],
  status: "active",
  beneficiary: { id: patientId, resourceType: "Patient" },
  subscriber: { id: patientId, resourceType: "Patient" },
  subscriberId: undefined,
  relationship: {
    text: insuranceRelationship.self.display,
    coding: [insuranceRelationship.self],
  },
  period: {
    start: formatDate(new Date(), formatsByTypes.ISO_8601_DATE),
  },
  relatedPerson: undefined,
  class: [
    {
      type: GROUP_TYPE,
      name: "Group Number",
      value: "",
    },
  ],
})

const sanitizeCoverage = (coverage: CoverageData): CoverageData => {
  const sanitized = { ...coverage }

  delete coverage?.payor?.[0]?.address

  return sanitized
}

const getRelatedPersonInitialValues = (patientId: string): RelatedPerson & { subscriber?: Reference } => ({
  patient: { id: patientId, resourceType: "Patient" },
  relationship: [{ coding: undefined }],
  name: [{ use: "official", given: [""], family: "" }],
  gender: "",
  telecom: [
    { system: ContactPointSystem.email, use: "home", value: "" },
    { system: ContactPointSystem.phone, use: "home", value: "" },
  ],
  address: [emptyAddress],
  subscriber: undefined,
})

const relatedPersonValidationSchema = Yup.object().shape({
  name: Yup.array(humanNameSchema()),
  telecom: Yup.array(telecomSchema([ContactPointSystem.phone])),
  relationship: Yup.array(
    Yup.object().test(
      "empty-coding",
      "Relationship is required",
      (value: CodeableConcept) => value.coding !== undefined,
    ),
  ),
  address: Yup.array().of(getAddressSchema("address[0]")),
})

const coverageValidationSchema = Yup.object().shape({
  type: Yup.object().test("empty-coding", "Type is required", (value: CodeableConcept) => value.coding !== undefined),
  payor: Yup.array().of(
    Yup.object().test("payor", "Carrier is required", (value) => value !== undefined && value?.id !== undefined),
  ),
  subscriberId: Yup.string().required("Subscriber ID is required"),
  period: Yup.object().shape({
    start: Yup.string().required("Period start is required"),
  }),
  subscriber: Yup.object().test("check-person", "Subscriber data is required", (value, context) => {
    const { relationship } = context.parent
    return relationship?.coding?.[0]?.code === insuranceRelationship.self.code || value?.id !== undefined
  }),
  class: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().test("Group", "Group Number is required", (value) => !!value),
    }),
  ),
})

const carrierValidationSchema = Yup.object().shape({
  name: Yup.string().required("Carrier name is required"),
  address: Yup.array().of(getAddressSchema("address[0]")),
})

export {
  carrierValidationSchema,
  coverageValidationSchema,
  getCoverageInitialValues,
  getRelatedPersonInitialValues,
  relatedPersonValidationSchema,
  sanitizeCoverage,
}
