import { faFlaskVial, faInfoCircle, faTrashCan, faVials } from "@fortawesome/pro-regular-svg-icons"
import { faCalendar, faFileInvoiceDollar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns"
import { Checkbox } from "primereact/checkbox"
import { OverlayPanel } from "primereact/overlaypanel"
import { classNames } from "primereact/utils"
import { useMemo, useRef, useState } from "react"

import { Button, CardListItem } from "commons"
import { BILLING_TYPES_CODES, formatsByTypes } from "data"
import { useOrganizationContext } from "organization"
import { formatDate, getMoneyCurrencyAlt, strCapitalize } from "utils"

import { CpoeLaboratoryPanel, CpoeRequest } from "../types"
import "./LaboratoryOrderListItem.css"

const LaboratoryOrderListItem = ({ labRequest, onChange, editable, onDelete, isDeleting, readonly }: Props) => {
  const panelRef = useRef<OverlayPanel>(null)
  const { isExemptLabPayment } = useOrganizationContext()

  const isInsuranceOrder = useMemo(
    () => labRequest.laboratoryData?.billingType === BILLING_TYPES_CODES.INSURANCE,
    [labRequest.laboratoryData?.billingType],
  )
  const orderBillingTypeDisplay = labRequest?.laboratoryData?.billingType?.replace("bill-", "") ?? ""

  const [deletePressed, setDeletePressed] = useState(false)

  const head = (
    <div className="flex justify-between w-full items-center">
      <span className="flex flex-1 items-center gap-2">
        {editable && !readonly && (
          <Checkbox
            name="status"
            checked={labRequest.resource.code?.[0].coding?.[0].code === "activate"}
            onChange={(e) => onChange?.(e.checked ? "activate" : "draft", labRequest.resource.resource?.id)}
          />
        )}
        <div className="flex flex-col">
          <div className="font-semibold text-gray-600 flex line-clamp-2 text-wrap text-ellipsis">
            {labRequest.display}
            <span onClick={(e) => panelRef?.current?.toggle(e)} className="cursor-pointer">
              <FontAwesomeIcon icon={faInfoCircle} size="1x" className="ml-2" title="View panels" />
              <OverlayPanel ref={panelRef}>
                {labRequest.laboratoryData?.panels.map((panel) => (
                  <CpoeLabPanelItem
                    panel={panel}
                    key={panel.profile.id}
                    showPrice={!isExemptLabPayment && !isInsuranceOrder}
                  />
                ))}
              </OverlayPanel>
            </span>
          </div>
          <div title="Tests" className="text-slate-400 flex flex-wrap space-x-1 items-center">
            <span title="Date">
              <FontAwesomeIcon icon={faCalendar} size="1x" className="mr-1" />
              {formatDate(
                parseISO(labRequest.laboratoryData?.serviceRequest.authoredOn as string),
                formatsByTypes.LONG_DATE,
              )}
            </span>
            {!!labRequest.laboratoryData?.combos && (
              <span title="Combos">
                <FontAwesomeIcon icon={faFlaskVial} size="1x" className="mx-1" />
                {labRequest.laboratoryData.combos}
              </span>
            )}
            {!!labRequest.laboratoryData?.panelsCount && (
              <span title="Tests">
                <FontAwesomeIcon icon={faVials} size="1x" className="mx-1" />
                {labRequest.laboratoryData.panelsCount}
              </span>
            )}
            <span title="Bill to">
              <FontAwesomeIcon icon={faFileInvoiceDollar} size="1x" className="mx-1" />
              {strCapitalize(orderBillingTypeDisplay)}
            </span>
          </div>
        </div>
      </span>
      <div className="flex min-w-[35%] max-w-[50%] justify-end">
        {!isExemptLabPayment && !isInsuranceOrder && (
          <div className="font-bold text-gray-600 justify-evenly inline-flex items-center">
            {getMoneyCurrencyAlt(labRequest?.unitPrice?.currency)}
            {(labRequest.quantity * (labRequest?.unitPrice?.value ?? 0)).toFixed(2)}
          </div>
        )}
        <div
          className={classNames("flex justify-end items-center py-1", { "min-w-9 ml-6 xl:ml-9 3xl:ml-12": !readonly })}
        >
          {!!onDelete && !!labRequest.laboratoryData?.carePlanId && !readonly && (
            <Button
              icon={faTrashCan}
              loading={isDeleting && deletePressed}
              buttonStyle="text"
              onClick={() => {
                setDeletePressed(true)
                onDelete(labRequest.resource.resource?.id as string, labRequest)
              }}
              title="Delete"
            />
          )}
        </div>
      </div>
    </div>
  )

  return (
    <CardListItem className={classNames("border-none laboratory-item", { "px-2": !readonly })} contentHeader={head} />
  )
}

type TCpoeLabPanelItem = { panel: CpoeLaboratoryPanel; showPrice?: boolean }

const CpoeLabPanelItem = ({ panel, showPrice = true }: TCpoeLabPanelItem) => (
  <div className="py-1 border-b last:border-none text-sm flex items-center justify-between" key={panel.profile.id}>
    <span> {panel.profile.code?.coding?.[0].display}</span>
    {showPrice && (
      <span className="ml-4">
        {panel.price ? (
          `${panel.price?.value?.toFixed(2)} ${panel.price?.currency}`
        ) : (
          <small className="text-gray-400">No price</small>
        )}
      </span>
    )}
  </div>
)

type Props = {
  labRequest: CpoeRequest
  onChange?(value: string, id?: string): void
  editable: boolean
  onDelete?(itemId: string, item: CpoeRequest): void
  isDeleting?: boolean
  readonly?: boolean
}

export { LaboratoryOrderListItem }
