import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Task } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { tasksQueryKeys } from "../query-keys"

const useUpdateTask = (args?: UpdateTaskArgs) => {
  const { update } = useClient()
  const queryClient = useQueryClient()

  const newTask = async (task: Task) => update("Task", task.id as string, task)

  const { mutate: updateTask, isPending: isUpdating } = useMutation({
    mutationFn: newTask,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: tasksQueryKeys.all,
      })
      args?.queryKeysToInvalidate?.forEach(async (queryKey) => {
        await queryClient.invalidateQueries({
          queryKey,
        })
      })
      displayNotificationSuccess("Task updated successfully!")
      // datadogLogs.logger.info("Task updated successfully!", { task })
    },
    onSettled: args?.hideForm,
  })

  return { updateTask, isUpdating }
}

type UpdateTaskArgs = { hideForm?: () => void; queryKeysToInvalidate?: string[][] }

export { useUpdateTask }
