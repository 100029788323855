import { useQuery } from "@tanstack/react-query"
import { getResources, Organization } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { coverageQueryKeys } from "../query-key"
import { getInsuranceProvidersInfo } from "../utils"

const useInsuranceProviders = (filterText?: string) => {
  const { search } = useClient()
  const queryKey = coverageQueryKeys.insuranceProviders(filterText)

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        type: "insurance-provider",
        active: "true",
        ...(filterText ? { _name: filterText } : {}),
        _count: "20",
      })

      const bundle = await search({ endpoint: "Organization", filters, signal })

      const insuranceProviders = getResources<Organization>(bundle, "Organization")

      return { insuranceProviders }
    },
    enabled: !!filterText,
    meta: { context: { queryKey } },
  })

  const insuranceProvidersInfo = useMemo(
    () => getInsuranceProvidersInfo(data?.insuranceProviders),
    [data?.insuranceProviders],
  )

  return {
    insuranceProviders: insuranceProvidersInfo,
    isLoading,
    refetch,
  }
}

export { useInsuranceProviders }
