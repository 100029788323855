export const communicationRequestStatus = [
  { code: "active", display: "Active" },
  { code: "completed", display: "Completed" },
  { code: "draft", display: "Draft" },
  { code: "stopped", display: "Stopped" },
]

export const communicationStatusReason = [
  { code: "processed", display: "Processed" },
  { code: "dropped", display: "Dropped" },
  { code: "deferred", display: "Deferred" },
  { code: "bounce", display: "Bounce" },
  { code: "delivered", display: "Delivered" },
  { code: "open", display: "Open" },
  { code: "click", display: "Click" },
  { code: "spam", display: "Spam Report" },
  { code: "unsubscribe", display: "Unsubscribe" },
]
