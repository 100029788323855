import { SubModule, ModulesId } from "commons/Module"

import { LaboratoryOrderDetails } from "../../../laboratory-orders/components/LaboratoryOrderDetails"

class LaboratoryOrderDetailsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.LABORATORY_ORDER_DETAILS
    this.parent = ModulesId.LABSORDER 
    this.display = "Order Details"
    this.content = <LaboratoryOrderDetails />
  }
}

export { LaboratoryOrderDetailsSubModule }
