import { Address } from "fhir"

enum ACCOUNT_TYPE {
  CARD_READER_ACCOUNT = "card-reader",
  CREDIT_CARD_HOLDING_ACCOUNT = "credit-card",
}

export type CreditCardFormData = {
  number?: string
  cvv?: string
  type?: string
  last4Digits?: string
  expirationDate?: string
  expirationMonth?: string
  expirationYear?: string
  billingAddress?: Address
  cardHolderName?: string
}

export { ACCOUNT_TYPE }
