import {
  faBuilding,
  faCalendarDays,
  faEnvelope,
  faLocationDot,
  faPencil,
  faPhone,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format, parseISO } from "date-fns"
import { getAddress, getFirstEmail, Practitioner } from "fhir"
import { getFirstPhone } from "fhir/utils"
import { useState } from "react"

import { DataContainerSlideoverForm, FloatingButton, InfoRow } from "commons"
import { formatsByTypes } from "data"
import { usePractitionerOrganizations } from "organization"
import { useUpdatePractitioner } from "practitioner"
import { useLoginContext } from "security"
import { strCapitalize } from "utils"

import { ReplaceFormProvider } from "commons/context"
import { PractitionerForm } from "./PractitionerForm"
import { initialValues, practitionerValidationSchema, sanitize } from "./validations"

const PractitionerInformation = () => {
  const [showForm, setShowForm] = useState(false)
  const toggleForm = () => setShowForm((showing) => !showing)
  const { loggedInPractitioner, loggedInPractitionerId, reloadPractitioner } = useLoginContext()
  const { organizations } = usePractitionerOrganizations(loggedInPractitionerId)
  const { address, telecom, identifier } = loggedInPractitioner ?? {}
  const { updatePractitioner } = useUpdatePractitioner(reloadPractitioner, toggleForm)

  const onSubmit = (practitioner: Practitioner) => {
    updatePractitioner(sanitize(practitioner))
  }

  const email = getFirstEmail(telecom)

  const orgs = !organizations?.length ? (
    <span className="italic">No organizations</span>
  ) : (
    <div className="flex flex-col">
      {organizations.map(({ name, address, telecom }, index) => (
        <div key={`${name}.${index}`} className="flex">
          <div className="font-bold">
            <FontAwesomeIcon icon={faBuilding} size="sm" className="mr-2" />
            <span title="Organization">{name}</span>
          </div>
          <span className="mx-1">|</span>
          <span title="Address">
            <FontAwesomeIcon icon={faLocationDot} size="xs" className="mr-1" />
            {getAddress(address)}
          </span>
          {telecom?.[0]?.value && (
            <span title={strCapitalize(telecom[0].system as string)}>
              <span className="mx-1">|</span>
              <FontAwesomeIcon icon={telecom[0].system === "email" ? faEnvelope : faPhone} size="xs" className="mr-1" />
              {telecom[0].value}
            </span>
          )}
        </div>
      ))}
    </div>
  )

  const ids = !identifier?.length ? (
    <span className="italic">No identifiers</span>
  ) : (
    <div className="flex flex-col">
      {identifier?.map(({ period, value, system }) => (
        <div key={value} className="flex">
          <div className="font-bold">
            <span title="Identifier">{value}</span>
          </div>
          {system && (
            <>
              <span className="mx-1">|</span>
              <span title="System" className="whitespace-nowrap text-ellipsis overflow-hidden max-w-xs">
                {system}
              </span>
            </>
          )}
          {period && (
            <>
              <span className="mx-1">|</span>
              <span title="Period">
                <FontAwesomeIcon icon={faCalendarDays} size="xs" className="mr-1" />
                {period.start && format(parseISO(period?.start), formatsByTypes.LONG_DATE)}
                {period.end && <> - {period?.end && format(parseISO(period?.end), formatsByTypes.LONG_DATE)}</>}
              </span>
            </>
          )}
        </div>
      ))}
    </div>
  )

  return (
    <ReplaceFormProvider>
      <DataContainerSlideoverForm
        formTitle="Edit profile"
        customAddButton={
          <FloatingButton
            icon={faPencil}
            className="fixed bottom-6 right-6"
            onClick={toggleForm}
            label="Edit profile"
          />
        }
        hasData
        showSlide={showForm}
        formInitialValue={initialValues(loggedInPractitioner)}
        validationSchema={practitionerValidationSchema}
        onSubmit={onSubmit}
        onCancel={toggleForm}
        form={<PractitionerForm initialEmail={email} />}
        onButtonAddClick={toggleForm}
      >
        <div className="flex flex-col overflow-y-auto">
          <InfoRow className="pl-3 mt-5" title="Address" content={getAddress(address)} />
          <InfoRow className="pl-3" title="Email" content={email} />
          <InfoRow className="pl-3" title="Telephone" content={getFirstPhone(telecom)} />
          <InfoRow className="pl-3" title="Organizations" content={orgs} />
          <InfoRow className="pl-3" title="Identifiers" content={ids} />
        </div>
      </DataContainerSlideoverForm>
    </ReplaceFormProvider>
  )
}

export { PractitionerInformation }
