import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquareArrowDown, faSpinner } from "@fortawesome/pro-regular-svg-icons"
import { classNames } from "primereact/utils"
import { useEffect, useState } from "react"

import { useGetSignedUrl } from "../hooks"

const FileUrlDownloader = ({ url, className }: Props) => {
  const [downloadTriggered, setDownloadTriggered] = useState(false)
  const fileName = getFileNameFromUrl(url)
  const { signedUrl, isLoading, error } = useGetSignedUrl(url, downloadTriggered, (url) => {
    if (signedUrl || url) {
      // Insert a link that allows the user to download the file
      const link = document.createElement("a")
      link.download = fileName ?? "file.pdf"
      link.href = signedUrl ?? url
      link.target = "_blank"
      document.body.appendChild(link)
      link.click()

      setTimeout(() => {
        document.body.removeChild(link)
      }, 2000)
    }
    setDownloadTriggered(false)
  })

  const triggerDownload = () => {
    setDownloadTriggered(true)
  }

  useEffect(() => {
    if (error) {
      setDownloadTriggered(false)
    }
  }, [error])

  return (
    <span className="cursor-pointer" onClick={triggerDownload}>
      <p className={classNames("overflow-hidden text-ellipsis", className)}>
        {fileName ?? "Download"}{" "}
        {
          <FontAwesomeIcon
            icon={downloadTriggered || isLoading ? faSpinner : faSquareArrowDown}
            spin={isLoading || downloadTriggered}
            size="1x"
          />
        }
      </p>
    </span>
  )
}

const getFileNameFromUrl = (url: string) => {
  return url.split("/").pop()
}

type Props = {
  url: string
  className?: string
}

export { FileUrlDownloader }
