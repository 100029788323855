import { Quantity } from "fhir"

export const VITAL_CODE = "vital-signs"

export type VitalsFormType = {
  height?: number
  weight?: number
  heartRate?: number
  respiratoryRate?: number
  oxygenSaturation?: number
  temperature?: number
  bloodPressureSystolic?: number
  bloodPressureDiastolic?: number
}

export type VitalNames =
  | "temperature"
  | "respiratoryRate"
  | "heartRate"
  | "weight"
  | "height"
  | "bloodPressure"
  | "oxygenSaturation"

export type VitalRecord = { value?: Quantity; date?: string }

export type ColData = {
  height?: VitalRecord[]
  weight?: VitalRecord[]
  heartRate?: VitalRecord[]
  respiratoryRate?: VitalRecord[]
  oxygenSaturation?: VitalRecord[]
  temperature?: VitalRecord[]
  bloodPressureDiastolic?: VitalRecord[]
  bloodPressureSystolic?: VitalRecord[]
}
