import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FC, PropsWithChildren } from "react"

import { EmptyMessage, SkeletonLoader } from "commons"
import { classNames } from "primereact/utils"

const WrapUpSection: FC<PropsWithChildren<Props>> = ({
  sectionTitle,
  subSectionTitle,
  className,
  isLoading,
  isEmpty,
  emptyMesage,
  icon = faSearch,
  children,
}) => (
  <div className="flex flex-col pt-2 last:pb-2">
    {sectionTitle && <span className="text-gray-900 sticky top-0 bg-white z-20">{sectionTitle}</span>}
    {subSectionTitle && (
      <div className="inline-flex space-x-2 items-center sticky top-5 bg-white z-10">
        <span className="text-gray-500 font-light whitespace-nowrap">{subSectionTitle}</span>
        <hr className="bg-gray-100 w-full h-0.5 rounded-full" />
      </div>
    )}
    {isLoading ? (
      <SkeletonLoader repeats={2} loaderType="two-lines" />
    ) : isEmpty ? (
      <EmptyMessage message={emptyMesage} icon={icon} subMessage={false} className="py-2" />
    ) : (
      <div className={classNames("flex flex-col text-sm", className)}>{children}</div>
    )}
  </div>
)

type Props = {
  sectionTitle?: string
  subSectionTitle?: string
  className?: string
  isLoading?: boolean
  isEmpty?: boolean
  emptyMesage?: string
  icon?: IconDefinition
}

export { WrapUpSection }
