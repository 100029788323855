import { useMutation } from "@tanstack/react-query"
import { Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

const useAuthorizeBasisTheorySession = () => {
  const { operationRequest } = useClient()

  const authorizeBT = async ({ patientId, organizationId, nonce }: AuthorizeArgs): Promise<AuthorizeResponse> => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "nonce",
          value: { string: nonce },
        },
      ],
    }

    const responseParameters = await operationRequest<Parameters>({
      endpoint: organizationId ? `Organization/${organizationId}` : `Patient/${patientId}`,
      method: "POST",
      operation: "bt-authorize",
      parameters,
    })

    const container = responseParameters.parameter?.find(({ name }) => name === "container")?.value?.string ?? ""

    return { container }
  }

  const { mutateAsync: authorize, isPending: isAuthorizing } = useMutation({
    mutationFn: authorizeBT,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { authorize, isAuthorizing }
}
type AuthorizeArgs = { nonce: string } & (
  | { patientId: string; organizationId?: never }
  | { patientId?: never; organizationId: string }
)

type AuthorizeResponse = {
  container: string
}

export { useAuthorizeBasisTheorySession }
