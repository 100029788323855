import { Account, ActivityDefinition, Address, ChargeItemDefinition, Coverage, Patient, RequestGroup } from "fhir"
import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useEffect, useMemo, useState } from "react"

import { ActionGroupCode, CpoeRequest } from "../types"
import { getCheckoutAddressInfo, getRGActiveItemsInfo } from "../utils"

const CPOERequestsContext = createContext<(CpoeContext & ContextData) | undefined>(undefined)
CPOERequestsContext.displayName = "CPOERequestsContext"

const CPOERequestsContextProvider: FC<PropsWithChildren<CpoeContext>> = ({
  children,
  requestGroup,
  initialReaderAccount,
  initialCoverageByType,
  actions,
  patient,
  requestDiscounts,
  requestShippingMethods,
  ...props
}) => {
  const [selectedShippingMethods, updateSelectedShippingMethods] = useState(requestShippingMethods)
  const [selectedDiscounts, updateSelectedDiscounts] = useState(requestDiscounts)
  const [isProcessingActions, setIsProcessingActions] = useState(false)
  const [rg, updateRG] = useState(requestGroup)
  const [isProcessEnabled, setIsProcessEnabled] = useState(true)
  const [addressErrorMessage, setAddressErrorMessage] = useState<string>()
  const [readerAccount, setReaderAccount] = useState<Account | undefined>(initialReaderAccount)
  const [selectedRequests, setselectedRequests] = useState(props.initialActiveRequests)
  const [coverageByType, setCoverageByType] = useState(initialCoverageByType)
  const [cpoeActions, setCpoeActions] = useState(actions)

  const updateRequests = (requests: CpoeRequest[]) => {
    setselectedRequests(requests.filter((req) => req.resource.code?.[0].coding?.[0].code === "activate"))
    setCpoeActions(requests)
  }
  const resetCheckout = () => {
    updateRG(requestGroup)
    updateRequests(actions)
  }

  /* Forced update of RG when external update occurs. Ex: PATCHing RG from RX or MRs */
  useEffect(() => {
    if (!rg || rg.meta?.versionId !== requestGroup?.meta?.versionId) {
      resetCheckout()
    }
  }, [rg, resetCheckout])

  const activeRequestsInfo = useMemo(() => getRGActiveItemsInfo(selectedRequests), [selectedRequests])

  const checkoutAddressInfo = useMemo(
    () =>
      getCheckoutAddressInfo(
        patient.address,
        activeRequestsInfo.hasNutraRequest,
        activeRequestsInfo.hasInsuranceLabsRequest,
        activeRequestsInfo.hasRXRequest,
      ),
    [
      patient,
      activeRequestsInfo.hasNutraRequest,
      activeRequestsInfo.hasInsuranceLabsRequest,
      activeRequestsInfo.hasRXRequest,
    ],
  )

  const unmetRequirementsToInvoicePreview = useMemo(
    () => ({
      isInvalid: checkoutAddressInfo.missingAddresses,
      message:
        checkoutAddressInfo.missingAddresses &&
        `Required address for ${checkoutAddressInfo.missingLabsShippingAddress ? "labs" : ""}${checkoutAddressInfo.missingLabsShippingAddress && checkoutAddressInfo.missingMedsShippingAddress ? " and" : ""} ${checkoutAddressInfo.missingMedsShippingAddress ? "nutraceuticals" : ""}${checkoutAddressInfo.missingLabsShippingAddress || checkoutAddressInfo.missingMedsShippingAddress ? " and on" : ""} ${checkoutAddressInfo.missingPatientAddress ? "patient to order pharmaceuticals" : ""} not specified`,
    }),
    [checkoutAddressInfo],
  )

  return (
    <CPOERequestsContext.Provider
      value={{
        ...props,
        patient,
        actions: cpoeActions,
        requestGroup: rg,
        selectedRequests,
        selectedDiscounts,
        selectedShippingMethods,
        updateRequests,
        updateDiscounts: updateSelectedDiscounts,
        updateShippingMethods: updateSelectedShippingMethods,
        isProcessingActions,
        setIsProcessingActions,
        updateRG,
        isProcessEnabled,
        setProcessEnabled: setIsProcessEnabled,
        addressErrorMessage,
        setAddressErrorMessage,
        readerAccount,
        setReaderAccount,
        coverageByType,
        setCoverageByType,
        activeRequestsInfo,
        checkoutAddressInfo,
        unmetRequirementsToInvoicePreview,
        resetCheckout,
      }}
    >
      {children}
    </CPOERequestsContext.Provider>
  )
}

type CpoeContext = {
  initialActiveRequests: CpoeRequest[]
  actions: CpoeRequest[]
  requestGroup?: RequestGroup
  chargeItemDefinitions?: {
    billToPracticeOrInsuranceCIDs: Record<string, ChargeItemDefinition>
    billToPatientCIDs: Record<string, ChargeItemDefinition>
  }
  activityDefinition?: ActivityDefinition
  showShippingMethods: boolean
  showBillingTypes: boolean
  requestShippingMethods?: ChargeItemDefinition[]
  requestDiscounts?: ChargeItemDefinition[]
  isOnlyMedications?: boolean
  isOnlyDfo?: boolean
  isError?: boolean
  patientId: string
  patient: Patient
  initialReaderAccount?: Account
  initialCoverageByType?: Record<ActionGroupCode, Coverage | undefined>
  isFetchingData?: boolean
}

type ContextData = {
  selectedShippingMethods?: ChargeItemDefinition[]
  selectedDiscounts?: ChargeItemDefinition[]
  updateShippingMethods(cids: ChargeItemDefinition[]): void
  updateDiscounts(cids: ChargeItemDefinition[]): void
  isProcessingActions: boolean
  setIsProcessingActions(processing: boolean): void
  updateRG(updatedRG: RequestGroup): void
  isProcessEnabled: boolean
  setProcessEnabled(state: boolean): void
  addressErrorMessage?: string
  setAddressErrorMessage(message?: string): void
  readerAccount?: Account
  setReaderAccount(account: Account | undefined): void
  selectedRequests: CpoeRequest[]
  updateRequests(requests: CpoeRequest[]): void
  coverageByType?: Record<ActionGroupCode, Coverage | undefined>
  setCoverageByType: Dispatch<SetStateAction<Record<ActionGroupCode, Coverage | undefined> | undefined>>
  activeRequestsInfo: {
    hasNutraRequest: boolean
    hasRXRequest: boolean
    hasMedsRequest: boolean
    hasProcedureRequest: boolean
    hasLabsRequest: boolean
    hasInsuranceLabsRequest: boolean
    hasPlanBasedRequest: boolean
    hasBillToPracticeLabRequest: boolean
    hasBillPatientLabRequest: boolean
    insuranceRequests: CpoeRequest[]
  }
  checkoutAddressInfo: {
    missingAddresses: boolean
    missingLabsShippingAddress: boolean
    missingMedsShippingAddress: boolean
    nutrasShippingAddress?: Address
    labsShippingAddress?: Address
  }
  unmetRequirementsToInvoicePreview: {
    isInvalid: boolean
    message: string | false
  }
  resetCheckout(): void
}

export { CPOERequestsContext, CPOERequestsContextProvider }
