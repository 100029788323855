import { faChevronRight, faHomeAlt } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MenuItem } from "primereact/menuitem"

const SimpleBreadCrumb = ({ items, goHome }: Props) => {
  return (
    <nav className="w-full flex justify-between" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div className="h-5 flex items-center">
            <span className="text-gray-400 hover:text-gray-500 cursor-pointer" onClick={goHome}>
              <FontAwesomeIcon icon={faHomeAlt} className="h-5 w-5 flex-shrink-0" />
              <span className="sr-only">Home</span>
            </span>
          </div>
        </li>
        {items.map((item, index) => (
          <li key={index}>
            <div className="flex items-center">
              <span className="flex items-center justify-center h-5 w-5 flex-shrink-0 text-gray-400">
                <FontAwesomeIcon icon={faChevronRight} size="xs" />
              </span>
              <span
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
                onClick={(e) => item.command?.({ originalEvent: e, item })}
              >
                {item.label}
              </span>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

type Props = {
  items: MenuItem[]
  goHome(): void
}

export { SimpleBreadCrumb }
