import { useInfiniteQuery } from "@tanstack/react-query"
import { AuditEvent, getResources, isOrganization, Patient, Reference } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { formatDate } from "utils"

import { auditEventsQueryKeys } from "../query-keys"
import { AuditEventData } from "../types"

const useAuditEvents = (
  organizationId: string,
  filter = "",
  type?: string[],
  dates?: Date[],
  patient?: Reference,
  practitioner?: Reference,
) => {
  const { search } = useClient()
  const queryKey = auditEventsQueryKeys.list(organizationId, filter, type, dates, patient, practitioner)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    AuditEventsDataQuery,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        ...(filter ? { _ilike: filter.includes(" ") ? `"${filter}"` : filter } : {}),
        ...(type ? { subtype: type.join(",") } : {}),
        ...(dates ? { date: `ge${formatDate(dates[0])}` } : {}),
        ...(patient ? { patient: patient.id } : {}),
        ...(practitioner ? { agent: practitioner.id } : {}),
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-createdAt",
        _include: "AuditEvent:patient, AuditEvent:entity",
      })
      dates?.[1] && filters.append("date", `le${formatDate(dates[1])}`)

      const bundle = await search({ endpoint: `Organization/${organizationId}/AuditEvent`, filters, signal })

      const events = getResources<AuditEvent>(bundle, "AuditEvent")
      const patients = getResources<Patient>(bundle, "Patient")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { events, patients, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, organizationId, filter, type, dates, patient, practitioner } },
  })

  const { auditEvents, count } = useMemo(() => {
    const rawEvents = data?.pages.flatMap((page) => page.events)
    const rawPatients = data?.pages.flatMap((page) => page.patients)
    const count = rawEvents?.length ?? 0

    const patientsRecord = rawPatients?.reduce<Record<string, Patient>>((acc, patient) => {
      return { ...acc, [patient?.id as string]: patient }
    }, {})

    const auditEvents = rawEvents?.reduce((acc, event) => {
      const eventParticipants = event.agent.filter((a) => !a.requestor && !isOrganization(a.who))

      const participants = eventParticipants.reduce<Array<Patient>>((acc, eventParticipant) => {
        return [...acc, patientsRecord?.[eventParticipant.who?.id as string] as Record<string, Patient>]
      }, new Array<Patient>())

      return [...acc, { ...event, participants }]
    }, new Array<AuditEventData>())

    return {
      auditEvents,
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    auditEvents,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  }
}

type AuditEventsDataQuery = {
  events: AuditEvent[]
  patients: Patient[]
  next: number | undefined
  total: number
}

export { useAuditEvents }
