import { classNames } from "primereact/utils"

const PelletSelection = ({ gender, ...props }: Props) => {
  return gender === "male" ? (
    <>
      <InsertionPoint x="210" y="410" code="love-handles-left" {...props} />
      <InsertionPoint x="320" y="410" code="love-handles-right" {...props} />
      <InsertionPoint x="680" y="410" code="buttocks-left" {...props} />
      <InsertionPoint x="790" y="410" code="buttocks-right" {...props} />
    </>
  ) : (
    <>
      <InsertionPoint x="255" y="410" code="love-handles-left" {...props} />
      <InsertionPoint x="353" y="410" code="love-handles-right" {...props} />
      <InsertionPoint x="650" y="410" code="buttocks-left" {...props} />
      <InsertionPoint x="740" y="410" code="buttocks-right" {...props} />
    </>
  )
}

const InsertionPoint = ({
  x,
  y,
  code,
  selectedPoint,
  onSelectPoint,
  disabled = !onSelectPoint,
}: InsertionPointProps) => {
  const batchs = selectedPoint?.reduce<number[]>(
    (acc, pointCode, index) => (pointCode === code ? [...acc, index + 1] : acc),
    [],
  )
  return (
    <g
      transform={`translate(${x} ${y})`}
      className={classNames("fill-gray-400", {
        "fill-red-400": selectedPoint?.includes(code),
        "cursor-pointer hover:fill-red-300": !disabled && !selectedPoint?.includes(code),
      })}
      onClick={() => !disabled && onSelectPoint?.(code)}
    >
      <circle cx="0" cy="0" r="12" />
      <circle cx="0" cy="0" r="8" stroke="#fff" strokeWidth="3" />
      {!!batchs?.length && (
        <text x="-20" y="25" className="fill-red-500 font-extrabold z-50">
          {batchs.join(", ")}
        </text>
      )}
    </g>
  )
}

type Props = {
  gender: string
  selectedPoint?: string[]
  onSelectPoint?(point: string): void
  disabled?: boolean
}

type InsertionPointProps = Omit<Props, "gender"> & {
  x: string
  y: string
  code: string
}

export { PelletSelection }
