import { ModulesId, SubModule } from "commons/Module"
import { Planconfiguration } from "commons/care-plans"

class PlanConfigSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.PLAN_CONFIG
    this.parent = ModulesId.PLANS
    this.content = <Planconfiguration />
    this.display = "Configure plan"
  }
}

export { PlanConfigSubModule }
