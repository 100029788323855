import { useMutation, useQueryClient } from "@tanstack/react-query"
import { AccountBETACreditCardArray, ResourceObject } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { organizationQueryKeys } from "organization"
import { displayNotificationSuccess } from "utils"

import { creditCardQueryKeys } from "../query-keys"
import { useAuthorizeBasisTheorySession } from "./useAuthorizeBasisTheorySession"
import { useBasisTheoryInstance } from "./useBasisTheoryInstance"

const useDeleteCreditCard = (onSettled: () => void, onSuccess?: () => void) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()
  const { bt } = useBasisTheoryInstance()
  const { authorize } = useAuthorizeBasisTheorySession()

  const removeCreditCardToken = async ({ tokenId, patientId, organizationId }: ResourceIds & { tokenId: string }) => {
    if (!bt) return

    const { sessionKey, nonce } = await bt.sessions.create()
    await authorize({
      nonce,
      ...(patientId ? { patientId: patientId as string } : { organizationId: organizationId as string }),
    })

    return bt.tokens.delete(tokenId, { apiKey: sessionKey })
  }

  const updatedCreditCard = async ({
    index,
    accountId,
    creditCardList,
    defaultCreditCardId,
    patientId,
    organizationId,
  }: UpdateArgs) => {
    const isDefaultCC = defaultCreditCardId === `${creditCardList[index].type}|${creditCardList[index].last4Digits}`
    const patchOps: {
      op: string
      path: string
      value?: AccountBETACreditCardArray[]
    }[] = [
      {
        op: "replace",
        path: "/creditCard",
        value: [...creditCardList.slice(0, index), ...creditCardList.slice(index + 1)],
      },
    ]

    if (isDefaultCC) {
      patchOps.push({ op: "remove", path: "/defaultCreditCard" })
    }

    await patch("Account", accountId, patchOps as ResourceObject)

    return removeCreditCardToken({
      tokenId: creditCardList[index]?.pciToken as string,
      ...(patientId ? { patientId: patientId as string } : { organizationId: organizationId as string }),
    })
  }

  const { mutate: removeCreditCard, isPending: isDeleting } = useMutation({
    mutationFn: updatedCreditCard,
    onSuccess: async (_, { patientId, organizationId }) => {
      if (patientId) queryClient.refetchQueries({ queryKey: creditCardQueryKeys.withPatientId(patientId), type: "all" })
      if (organizationId)
        await queryClient.invalidateQueries({
          queryKey: organizationQueryKeys.account.current(organizationId),
          type: "all",
        })

      displayNotificationSuccess("Credit card deleted successfully!")
      // datadogLogs.logger.info(`Credit card of ${patientId} in ${organizationId}  deleted successfully!`,
      //    {
      //   defaultCreditCardId,
      // })
      onSuccess?.()
    },
    onSettled,
    onError: async (error: CustomError, { patientId, organizationId }) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        if (patientId)
          queryClient.refetchQueries({ queryKey: creditCardQueryKeys.withPatientId(patientId), type: "all" })
        if (organizationId)
          await queryClient.invalidateQueries({
            queryKey: organizationQueryKeys.account.current(organizationId),
            type: "all",
          })
      }
      displayNotificationError(registerErrorTrace(error))
    },
  })

  return { removeCreditCard, isDeleting }
}

type UpdateArgs = {
  index: number
  accountId: string
  creditCardList: AccountBETACreditCardArray[]
  defaultCreditCardId?: string
} & ResourceIds

type ResourceIds = { patientId: string; organizationId?: never } | { patientId?: never; organizationId: string }

export { useDeleteCreditCard }
