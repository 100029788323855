import { useState } from "react"
import { Encounter } from "fhir"

import { Accordion, EmptyMessage, ModulesId, SkeletonLoader, StackedListItem } from "commons"
import { usePatientContext } from "patients"
import { useAppModuleContext } from "internals"
import { useOrganizationContext } from "organization"

import { usePatientEncounters } from "../hooks"
import { encounterModelBuilder } from "../components/encounterModelBuilder"
import { EncounterWrapUp } from "../components/details/EncounterWrapUp"

const EncountersKnowledge = () => {
  const { appModules } = useAppModuleContext()
  const { currentOrganizationId, isExemptLabPayment } = useOrganizationContext()
  const { patientId } = usePatientContext()
  const { encounters, isLoading } = usePatientEncounters(patientId, "KP")

  const [expandedEncounter, setExpandedEncounter] = useState<number | number[] | undefined>(
    encounters?.findIndex(({ status }) => status === "in-progress"),
  )

  if (isLoading) return <SkeletonLoader repeats={4} loaderType="two-lines" />

  return !encounters?.length ? (
    <EmptyMessage
      icon={appModules[ModulesId.ENCOUNTER].getIcon()}
      itemTitle="encounter"
      subMessage={false}
      message="No encounters found"
    />
  ) : (
    <Accordion
      data={encounters}
      activeIndex={expandedEncounter}
      headerTemplate={(encounter: Encounter) => (
        <StackedListItem modelData={encounterModelBuilder(encounter)} className="py-0 @container" />
      )}
      contentTemplate={(item) => (
        <EncounterWrapUp
          encounter={item}
          organizationId={currentOrganizationId}
          patientId={patientId}
          isExemptLabPayment={isExemptLabPayment}
        />
      )}
      onTabChange={(e) => setExpandedEncounter(e.index)}
      className="expand-as-card grow overflow-y-auto"
    />
  )
}

export { EncountersKnowledge }
