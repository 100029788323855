import { faBooksMedical } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, AppModule, ModuleRenderSideType } from "commons/Module"

class PlansModule extends AppModule {
  constructor(workspaceElement: JSX.Element) {
    super()
    this.id = ModulesId.PLANS
    this.display = "Treatment Plans"
    this.icon = faBooksMedical
    this.hideBySetting = true
    this.workspaceElement = workspaceElement
    this.renderSideType = ModuleRenderSideType.WORKSPACE
  }
}

export { PlansModule }
