import { ResourceObject } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

import { plansQueryKeys } from "../query-keys"
import { CustomError } from "../../types"

const useUpdatePlanStatus = (patientId: string, onSuccess?: () => void, onSettled?: () => void) => {
  const queryClient = useQueryClient()
  const { patch } = useClient()

  const completeFn = async ({
    planId,
    status,
    resourceType = "CarePlan",
  }: {
    planId: string
    status: string
    resourceType?: "CarePlan" | "ServiceRequest"
  }) => patch(resourceType, planId, { status } as ResourceObject)

  const { mutate: updatePlanStatus, isPending } = useMutation({
    mutationFn: completeFn,
    onError: (error: CustomError, planId) => {
      displayNotificationError(registerErrorTrace(error, { planId }))
    },
    onSuccess: async (_, { planId, resourceType }) => {
      if (resourceType === "CarePlan") {
        await queryClient.invalidateQueries({ queryKey: plansQueryKeys.history(patientId, planId), refetchType: "all" })
        await queryClient.invalidateQueries({
          queryKey: plansQueryKeys.details(patientId, planId),
          refetchType: "active",
        })
      }
      await queryClient.invalidateQueries({ queryKey: ["patient/mc-plan", patientId], refetchType: "active" })
      onSuccess?.()
    },
    onSettled,
  })

  return { updatePlanStatus, isUpdating: isPending }
}

export { useUpdatePlanStatus }
