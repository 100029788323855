import { SYSTEM_VALUES } from "system-values"

const patientDocumentsCategoryOptions = [
  {
    label: "Medication Summary Document",
    value: { code: "56445-0", system: SYSTEM_VALUES.LOINC, display: "Medication Summary Document" },
  },
  {
    label: "Personal health monitoring report Document",
    value: {
      code: "53576-5",
      system: SYSTEM_VALUES.LOINC,
      display: "Personal health monitoring report Document",
    },
  },
  {
    label: "Plan of care note",
    value: { code: "56447-6", system: SYSTEM_VALUES.LOINC, display: "Plan of care note" },
  },
  {
    label: "Diagnostic imaging study",
    value: { code: "18748-4", system: SYSTEM_VALUES.LOINC, display: "Diagnostic imaging study" },
  },
  {
    label: "Surgical operation note",
    value: { code: "11504-8", system: SYSTEM_VALUES.LOINC, display: "Surgical operation note" },
  },
  { label: "Referral note", value: { code: "57133-1", system: SYSTEM_VALUES.LOINC, display: "Referral note" } },
  {
    label: "Lab results",
    value: {
      code: "lab-results",
      system: SYSTEM_VALUES.PATIENT_DOCUMENT_CATEGORY,
      display: "Lab results",
    },
  },
  {
    label: "Imaging results",
    value: {
      code: "imaging-results",
      system: SYSTEM_VALUES.PATIENT_DOCUMENT_CATEGORY,
      display: "Imaging results",
    },
  },
  {
    label: "Diet log",
    value: {
      code: "diet-log",
      system: SYSTEM_VALUES.PATIENT_DOCUMENT_CATEGORY,
      display: "Diet log",
    },
  },
  {
    label: "Consent form",
    value: {
      code: "consent-form",
      system: SYSTEM_VALUES.PATIENT_DOCUMENT_CATEGORY,
      display: "Consent form",
    },
  },
  {
    label: "General documents",
    value: {
      code: "general-docs",
      system: SYSTEM_VALUES.PATIENT_DOCUMENT_CATEGORY,
      display: "General documents",
    },
  },
]

export { patientDocumentsCategoryOptions }
