import { Appointment, Encounter, Reference, asReference } from "fhir"
import { FC, useCallback, useState } from "react"
import { ConfirmDialog } from "primereact/confirmdialog"
import { classNames } from "primereact/utils"

import { ActionProps, DialogFormContainer, SplitButton } from "commons"
import {
  appointmentValidationSchema,
  INITIAL_VALUES,
  AppointmentForm,
  sanitize,
  useCreateAppointment,
} from "appointments"
import { useLoginContext } from "security"
import { appointmentTypesCodes } from "data"

import { useFinishEncounter } from "../hooks"

const EncounterFinishButton: FC<Props> = ({
  encounter,
  patientId,
  patientRef,
  onEncounterFinished,
  showStopAction,
  buttonClassName,
  onStopClicked,
}) => {
  const { loggedInPractitioner } = useLoginContext()

  const [showAppointmentForm, setShowAppointmentForm] = useState(false)
  const [finishConfirmation, setFinishConfirmation] = useState(false)

  const closeFinishConfirmation = () => {
    setFinishConfirmation(false)
    setShowAppointmentForm(false)
  }

  const { finishEncounter, isFinishing } = useFinishEncounter(patientId, onEncounterFinished, closeFinishConfirmation)

  const onFinish = useCallback(() => {
    if (encounter) {
      finishEncounter(encounter)
    }
  }, [finishEncounter, encounter])

  const actions: ActionProps[] = [
    ...(showStopAction
      ? [
          {
            label: "Stop & Schedule",
            description: "Finish current encounter and schedule a new follow up appointment",
            onSelectClick: () => setShowAppointmentForm(true),
          },
        ]
      : []),
    ...(onStopClicked
      ? [
          {
            label: "Stop Encounter",
            description: "See details and stop current encounter",
            onSelectClick: onStopClicked,
          },
        ]
      : [
          {
            label: "Finish Encounter",
            description: "Finish current encounter",
            onSelectClick: () => (!showStopAction ? setFinishConfirmation(true) : onFinish()),
          },
        ]),
  ]
  const { createAppointment, isAdding } = useCreateAppointment(onFinish)
  const onSubmit = (appointment: Appointment) => createAppointment(sanitize(appointment))

  return (
    <div className="relative">
      <SplitButton
        actions={actions}
        autoUpdateMainAction
        loading={isFinishing}
        className={buttonClassName}
        buttonClassName="pl-5"
      />
      {!isFinishing && !isAdding && (
        <span
          className={classNames(
            "rounded-full h-2 w-2 absolute left-2 bg-red-500",
            buttonClassName?.includes("button-banner") ? "top-3.5" : "top-3",
          )}
        />
      )}
      <DialogFormContainer
        onSubmit={onSubmit}
        onCancel={() => setShowAppointmentForm(false)}
        showForm={showAppointmentForm}
        saveLabel="Accept"
        title="Follow Up Encounter"
        initialValue={{
          ...INITIAL_VALUES,
          appointmentType: { coding: [appointmentTypesCodes[2]], text: appointmentTypesCodes[2].display },
          participant: [
            { actor: patientRef, status: "tentative" },
            { actor: asReference(loggedInPractitioner), status: "tentative" },
          ],
        }}
        validationSchema={appointmentValidationSchema()}
        useFormik
      >
        <AppointmentForm hidePatientField hideExtraParticipantFields />
      </DialogFormContainer>
      <ConfirmDialog
        header="Confirmation"
        acceptLabel="Finish"
        rejectLabel="Close"
        rejectClassName="button-default p-button-sm"
        acceptClassName="button-primary p-button-sm"
        onHide={closeFinishConfirmation}
        message="Are you sure you want to finish the open encounter?"
        accept={onFinish}
        visible={finishConfirmation || (isFinishing && !showAppointmentForm && !showStopAction)}
        acceptIcon={isFinishing ? "pi pi-spin pi-spinner" : undefined}
        reject={closeFinishConfirmation}
      />
    </div>
  )
}

type Props = {
  patientId: string
  patientRef: Reference
  encounter: Encounter
  onEncounterFinished?(): void
  showStopAction?: boolean
  buttonClassName?: string
  onStopClicked?(): void
}

export { EncounterFinishButton }
