const allergiesQueryKeys = {
  all: ["patient/allergies"] as const,
  list: (patientId: string, key?: string, encounter?: string) => [
    ...allergiesQueryKeys.all,
    patientId,
    ...(key ? [key] : []),
    ...(encounter ? [encounter] : []),
  ],
  actives: (patientId: string) => ["activeAllergies", patientId],
}

export { allergiesQueryKeys }
