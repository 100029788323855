import { humanNameAsString, Practitioner } from "fhir"
import { createContext, FC, ReactNode } from "react"

import { useLoggedInPractitioner } from "practitioner"

const LoginContext = createContext<State | undefined>(undefined)
LoginContext.displayName = "LoginContext"

const LoginProvider: FC<Props> = ({ children }) => {
  const { loggedInPractitioner, hasDosespotSetup, reloadPractitioner, isLoading, error } = useLoggedInPractitioner()

  if (error) {
    throw error
  }

  const loggedInPractitionerName = humanNameAsString(loggedInPractitioner.name?.[0])

  return (
    <LoginContext.Provider
      value={{
        loggedInPractitioner,
        loggedInPractitionerId: loggedInPractitioner.id ?? "",
        hasDosespotSetup,
        reloadPractitioner,
        isLoading,
        loggedInPractitionerName,
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}

type State = {
  loggedInPractitioner: Practitioner
  loggedInPractitionerId: string
  hasDosespotSetup: boolean
  reloadPractitioner(): void
  isLoading: boolean
  loggedInPractitionerName: string
}

type Props = {
  children: ReactNode
}

export { LoginProvider, LoginContext }
