import { Bundle, isOperationOutcome, isResourceObject, Parameters, ResourceObject } from "fhir"
import { v4 } from "uuid"

import { CustomError } from "commons"
import { registerErrorTrace } from "logger"
import { User } from "security"
import { isAbortError, IsNetworkError } from "utils"

class Client {
  private xAudit: string
  private token: string

  public apiUrl: string

  constructor(user?: User, apiUrl?: string) {
    this.apiUrl = apiUrl ? apiUrl : window.VITE_APP_FHIR_SERVER

    this.token = user?.token ?? ""

    this.xAudit = btoa(
      JSON.stringify({
        email: user?.email ?? "unspecified user email",
        name: user?.name ?? "unspecified user name",
        resource: user?.linkedResource,
        client: "ehr",
      }),
    )
  }

  private request = async (
    endpoint: string,
    { method, body, headers: customHeaders, ...customConfig }: RequestInit,
  ) => {
    const config = {
      method,
      body,
      headers: {
        Authorization: this.token,
        ...(body ? { "Content-Type": "application/json" } : {}),
        "Cache-Control": "max-age=0, no-cache, must-revalidate, proxy-revalidate",
        "x-audit": this.xAudit,
        ...customHeaders,
      },
      ...customConfig,
    }

    const response = await fetch(`${this.apiUrl}/${endpoint}`, config).catch((reason): Promise<Response> => {
      return IsNetworkError(reason.message)
        ? Promise.resolve(new Response("{}", { status: 499 }))
        : method === "GET" && isAbortError(reason)
          ? Promise.resolve(new Response("{}", { status: 202 }))
          : Promise.resolve(reason)
    })

    const data = await response.json().catch(() => ({}))

    if (response.ok) {
      return data
    }

    switch (response.status) {
      case 401:
        if (data?.message?.includes("JWT is expired")) {
          throw registerErrorTrace(
            new Error("Forbidden", { cause: { name: "403", message: "Token expired" } }) as CustomError,
          )
        }

        throw new Error("Unauthorized", { cause: { name: "401", message: "Unauthorized" } })
      case 402:
        if (isOperationOutcome(data)) {
          const message =
            data.text?.div ?? data.issue?.[0].details?.text ?? data.issue[0].diagnostics ?? "Something went wrong"
          throw registerErrorTrace(new Error("Payment required", { cause: { name: "402", message } }) as CustomError)
        }

        throw registerErrorTrace(
          new Error("Not supported", {
            cause: { name: "not-supported", message: `Invalid response: ${response.statusText} ${response.status}` },
          }) as CustomError,
        )
      case 403:
        throw registerErrorTrace(
          new Error("Forbidden", { cause: { name: "403", message: "Forbidden" } }) as CustomError,
        )
      case 499:
        throw registerErrorTrace(
          new Error("NetworkError", { cause: { name: "499", message: "NetworkError" } }) as CustomError,
        )
      case 409:
        throw registerErrorTrace(
          new Error("Conflict", { cause: { name: "409", message: "Resource version conflict" } }) as CustomError,
        )
      case 412:
        throw registerErrorTrace(
          new Error("Precondition failed", { cause: { name: "412", message: "Precondition Failed" } }) as CustomError,
        )
      default:
        if (isOperationOutcome(data)) {
          let message =
            data.text?.div ?? data.issue?.[0].details?.text ?? data.issue[0].diagnostics ?? "Something went wrong"
          // Delete response body data from error message for security
          message = message.split("Response body")[0]

          let code: string
          switch (data.issue?.[0].code) {
            case "conflict":
              code = "409"
              break
            case "precondition-failed":
              code = "412"
              break

            default:
              code = "500"
          }
          throw registerErrorTrace(
            new Error("Internal server error", { cause: { name: code ?? "500", message } }) as CustomError,
          )
        }

        throw registerErrorTrace(
          new Error("Not supported", {
            cause: { name: "not-supported", message: `Invalid response: ${response.statusText} ${response.status}` },
          }) as CustomError,
        )
    }
  }

  operationRequest = async <T extends ResourceObject>(
    endpoint: string,
    method: "GET" | "POST" | "PATCH" | "PUT" = "GET",
    id?: string,
    parameters?: Parameters,
    filters?: URLSearchParams,
    operation?: string,
    signal?: AbortSignal,
  ) => {
    const data = await this.request(
      `${endpoint}${id ? `/${id}` : ""}${operation !== "" ? `${endpoint || id ? "/" : ""}$${operation}` : ""}${
        filters ? `?${filters}` : ""
      }`,
      {
        method: method,
        body: JSON.stringify(parameters),
        ...(method === "GET" ? { signal } : {}),
        headers:
          ["PUT"].includes(method) && !!parameters?.meta?.versionId
            ? { "If-Match": parameters.meta.versionId }
            : undefined,
      },
    )

    return data as T
  }

  read = async <T extends ResourceObject>(
    endpoint: string,
    id?: string,
    filters?: URLSearchParams,
    operation?: string,
    signal?: AbortSignal,
  ) => {
    const data = await this.request(
      `${endpoint}${id ? `/${id}` : ""}${operation ? `/$${operation}` : ""}${filters ? `?${filters}` : ""}`,
      {
        method: "GET",
        signal,
      },
    )

    return data as T
  }

  search = async (endpoint: string, filters?: URLSearchParams, operation?: string, signal?: AbortSignal) => {
    const data = await this.request(`${endpoint}${operation ? `/$${operation}` : ""}${filters ? `?${filters}` : ""}`, {
      method: "GET",
      signal,
    })

    return data as Bundle
  }

  create = async <T extends ResourceObject>(endpoint: string, resource: T) => {
    const data = await this.request(`${endpoint}`, {
      method: "POST",
      body: JSON.stringify(resource),
    })

    return data as T
  }

  update = async <T extends ResourceObject>(endpoint: string, id: string, resource: T) => {
    const data = await this.request(`${endpoint}/${id}`, {
      method: "PUT",
      body: JSON.stringify(resource),
      headers: resource.meta?.versionId ? { "If-Match": resource.meta.versionId } : undefined,
    })

    return data as T
  }

  patch = async <T extends ResourceObject>(endpoint: string, id: string, resource: Partial<T>) => {
    const data = await this.request(`${endpoint}/${id}`, {
      method: "PATCH",
      body: JSON.stringify(resource),
      headers: resource.meta?.versionId ? { "If-Match": resource.meta.versionId } : undefined,
    })

    return data as T
  }

  remove = async <T extends ResourceObject>(endpoint: string, id: string) => {
    const data = await this.request(`${endpoint}/${id}`, { method: "DELETE" })

    return data as T
  }

  transaction = async <T extends ResourceObject>(bundle: Bundle) => {
    const body = {
      ...bundle,
      entry: bundle.entry?.map((entry) =>
        (entry.request?.method === "PUT" || entry.request?.method === "PATCH") &&
        isResourceObject(entry.resource) &&
        entry.resource.meta?.versionId
          ? { ...entry, request: { ...entry.request, ifMatch: entry.resource.meta?.versionId } }
          : entry,
      ),
    }

    const data = await this.request("", {
      method: "POST",
      body: JSON.stringify(body),
    })

    return data as T
  }

  getSignedUrl = async (fileUrl: string, signal?: AbortSignal): Promise<SignedUrl> => {
    const response = await fetch(`${this.apiUrl}/${fileUrl}`, {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: this.token, "x-audit": this.xAudit },
      signal,
    })

    const data = await response.json().catch(() => ({}))

    if (response.ok) {
      return { url: data.url }
    }

    switch (response.status) {
      case 401:
        if (data?.message?.includes("JWT is expired")) {
          throw new Error("Forbidden", { cause: { name: "403", message: "Token expired" } })
        }

        throw new Error("Unauthorized", { cause: { name: "401", message: "Unauthorized" } })
      default:
        throw new Error("Not supported", {
          cause: { name: "not-supported", message: `Invalid response: ${response.statusText} ${response.status}` },
        })
    }
  }

  uploadFile = async (file: File, containerName: string): Promise<string> => {
    const filename = `${file.name}${v4()}`
    const response = await fetch(`${this.apiUrl}/azure/storage/${containerName}`, {
      method: "POST",
      body: JSON.stringify({ blob: filename }),
      headers: { Authorization: this.token, "Content-Type": "application/json", "x-audit": this.xAudit },
    })

    const data = await response.json()

    await fetch(`${data.url}`, {
      method: "PUT",
      body: file,
      headers: { "x-ms-blob-type": "BlockBlob", "x-audit": this.xAudit },
    })

    const fileUrl = `azure/storage/${containerName}/${filename}`

    return fileUrl
  }
}

type SignedUrl = { url: string }

export { Client }
