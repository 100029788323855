import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Condition } from "fhir"

import {
  AddFieldArrayItemButton,
  DialogFormContainer,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  useCrudReducer,
} from "commons"
import {
  ConditionForm,
  conditionModelBuilder,
  conditionValidationSchema,
  getInitialValues,
  sanitize,
  useConditions,
  useCreateCondition,
} from "conditions"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

const PatientConditions = () => {
  const { patientId, patientRef } = usePatientContext()
  const { appModules } = useAppModuleContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { showSlide, initialValue, reset, add } = useCrudReducer({
    defaultEntity: getInitialValues(patientRef, openEncounterRef),
  })

  const { conditions, isLoading } = useConditions({ patientId })
  const { createCondition } = useCreateCondition(reset)

  const onSubmit = (condition: Condition) => createCondition(sanitize(condition))

  return (
    <div className="flex flex-col">
      <div className="flex flex-col border-t mt-6 pt-4">
        <span className="text-gray-900">Patient conditions</span>
        {isLoading ? (
          <SkeletonLoader repeats={2} loaderType="two-lines" />
        ) : (
          <div className="flex flex-col flex-1 overflow-y-auto bg-white">
            {conditions?.length ? (
              <StackedListContainer data={conditions} itemModelBuilder={(item) => conditionModelBuilder(item)} />
            ) : (
              <div className="text-center m-auto">
                <FontAwesomeIcon
                  icon={appModules[ModulesId.CONDITIONS].getIcon()}
                  size="2x"
                  className="text-slate-400"
                />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No conditions found</h3>
              </div>
            )}
            <AddFieldArrayItemButton label="Add New Condition" onClick={add} />
          </div>
        )}
        <DialogFormContainer
          title="Add New Condition"
          showForm={showSlide}
          onSubmit={onSubmit}
          onCancel={reset}
          initialValue={initialValue}
          validationSchema={conditionValidationSchema}
          useFormik
        >
          <ConditionForm />
        </DialogFormContainer>
      </div>
    </div>
  )
}

export { PatientConditions }
