import { IconDefinition } from "@fortawesome/pro-light-svg-icons"

import { VitalColData, getVitalName, useVitals } from "vitals"
import { vitalSignCodes } from "data"

import { WrapUpSection } from "./WrapUpSection"

const VitalsSection = ({ patientId, encounter, icon }: Props) => {
  const { vitals, isLoading, totalData } = useVitals(patientId, encounter)

  return (
    <WrapUpSection
      subSectionTitle="Vitals"
      isLoading={isLoading}
      isEmpty={!totalData}
      icon={icon}
      emptyMesage="No vitals gathered in this encounter"
      className="divide-y"
    >
      {Object.keys(vitalSignCodes).flatMap((key, index) => {
        const vitalValue = vitals?.[key as keyof VitalColData]
        const vitalQty = vitalValue?.[0]?.value

        return (
          vitalQty?.value && (
            <div className="flex justify-between py-2 w-1/2 self-center" key={`${key}_${index}`}>
              <span>{getVitalName(key as keyof VitalColData)}</span>
              <span>
                {vitalQty?.value} {vitalQty?.unit}
              </span>
            </div>
          )
        )
      })}
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounter: string
  icon: IconDefinition
}

export { VitalsSection }
