import { Account, AccountBETACreditCardArray, asReference, Patient, Reference } from "fhir"
import { createContext, PropsWithChildren } from "react"

const PatientContext = createContext<PatientContextState | undefined>(undefined)
PatientContext.displayName = "PatientContext"

const PatientContextProvider = ({
  patient,
  hasB2CUser,
  refreshPatientData,
  children,
  ...accountProps
}: PropsWithChildren<Props>) => {
  if (!patient?.id) {
    throw new Error("Invalid patient id")
  }

  const data = {
    patientId: patient.id,
    patient,
    hasB2CUser,
    patientRef: asReference(patient),
    refreshPatientData,
    ...accountProps,
  }

  return <PatientContext.Provider value={data}>{children}</PatientContext.Provider>
}

type Props = {
  patient: Patient
  hasB2CUser?: boolean
  account?: Account
  creditCards: AccountBETACreditCardArray[]
  preferedCreditCard?: AccountBETACreditCardArray
  refreshPatientData(): void
}

type PatientContextState = Props & {
  patientId: string
  patientRef: Reference
}

export { PatientContext, PatientContextProvider }
