import groupBy from "lodash/groupBy"

import { PractitionerInfo } from "commons/types"

const getGroupedPractitioners = (practitionersInfo: PractitionerInfo[]) => {
  const groupedPractitioners = groupBy(
    practitionersInfo,
    ({ practitioner }) => practitioner.name?.[0]?.family?.[0].toUpperCase() ?? "other",
  )

  const practitionerGroups = Object.entries(groupedPractitioners)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .map(([key, items = []]) => ({
      key,
      name: key,
      items,
    }))

  return practitionerGroups
}

export { getGroupedPractitioners }
