import { Appointment, Bundle, BundleEntryArray } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

import { plansQueryKeys } from "../query-keys"
import { PlanData } from "../types"
import { CustomError } from "../../types"

const useUpdatePlan = (onSuccess?: () => void, onSettled?: (error: CustomError | null) => void) => {
  const { transaction } = useClient()
  const queryClient = useQueryClient()

  const updateFn = async (data: PlanData) => {
    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: [
        {
          request: {
            method: "PUT",
            url: `CarePlan/${data.carePlan.id}`,
          },
          resource: { ...data.carePlan, resourceType: "CarePlan" },
        },
        ...(data.appointment?.id
          ? [
              {
                request: {
                  method: "PATCH",
                  url: `Appointment/${data.appointment.id}`,
                },
                resource: {
                  start: data.appointment?.start,
                  resourceType: "Appointment",
                  meta: data.appointment?.meta,
                } as Partial<Appointment>,
              },
            ]
          : []),
      ],
    }

    const tasks =
      (data.mailTasks &&
        Object.values(data.mailTasks).reduce<Array<BundleEntryArray>>(
          (acc, t) => [
            ...acc,
            {
              request: {
                method: "PATCH",
                url: `Task/${t.taskId}`,
              },
              resource: { restriction: t.restriction, resourceType: "Task" },
            },
          ],
          [],
        )) ??
      []
    bundle.entry?.push(...tasks)

    return transaction(bundle)
  }

  const { mutate: updatePlan, isPending: isUpdating } = useMutation({
    mutationFn: updateFn,
    onError: async (error: CustomError, { carePlan }, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        await queryClient.invalidateQueries({
          queryKey: plansQueryKeys.list(carePlan?.subject?.id, carePlan?.id),
        })
      }
      displayNotificationError(registerErrorTrace(error, context ?? carePlan))
    },
    onSuccess: async (_, { carePlan }) => {
      await queryClient.invalidateQueries({
        queryKey: plansQueryKeys.list(carePlan?.subject?.id, carePlan?.id),
      })
      onSuccess?.()
    },
    onSettled: (_data, error) => {
      onSettled?.(error)
    },
  })

  return { updatePlan, isUpdating }
}

export { useUpdatePlan }
