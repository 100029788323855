const patientAptsQueryKeys = {
  all: ["patient/appointments"] as const,
  list: (patientId: string) => [...patientAptsQueryKeys.all, patientId],
  scheduled: (patientId: string) => ["scheduledAppointment", patientId],
}

const practitionerAptsQueryKeys = {
  all: (loggedInPractitionerId: string) => ["appointments", loggedInPractitionerId],
}

export { patientAptsQueryKeys, practitionerAptsQueryKeys }
