import { useQuery } from "@tanstack/react-query"
import { AllergyIntolerance, codeableConceptAsString, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { allergiesQueryKeys } from "../query-keys"

const useActiveAllergies = (patientId: string) => {
  const { search } = useClient()
  const queryKey = allergiesQueryKeys.actives(patientId)
  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        "clinical-status": "active",
        type: "allergy",
        _elements: "id,code",
        _count: "50",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/AllergyIntolerance`, filters, signal })
      const activeAllergies = getResources(bundle) as AllergyIntolerance[]

      return { activeAllergies, total: bundle?.total ?? 0 }
    },
    meta: { context: { queryKey, patientId } },
  })

  const { activeAllergies, count } = useMemo(() => {
    const activeAllergies = data?.activeAllergies.map((allergy) => codeableConceptAsString(allergy.code))

    const count = data?.activeAllergies.length

    return { activeAllergies, count }
  }, [data?.activeAllergies])

  return {
    activeAllergies: activeAllergies ?? [],
    allergiesCount: count,
    allergiesLoading: isLoading,
  }
}

export { useActiveAllergies }
