import { faClipboardMedical } from "@fortawesome/pro-regular-svg-icons"

import { ModuleRenderSideType, AppModule, ModulesId } from "commons/Module"

class SummaryModule extends AppModule {
  constructor(kpElement: JSX.Element) {
    super()
    this.id = ModulesId.SUMMARY
    this.display = "Summary"
    this.hideBySetting = true
    this.icon = faClipboardMedical
    this.kpElement = kpElement
    this.renderSideType = ModuleRenderSideType.KP
  }
}

export { SummaryModule }
