import { useInfiniteQuery } from "@tanstack/react-query"
import { Consent, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { consentsQueryKeys } from "../query-keys"

const useConsents = (patientId: string) => {
  const { search } = useClient()

  const queryKey = consentsQueryKeys.list(patientId)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    ConsentsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-createdAt",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Consent`, filters, signal })

      const consents = getResources<Consent>(bundle, "Consent")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { consents, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  const { consents } = useMemo(() => {
    const consents = data?.pages.flatMap((page) => page.consents)

    return {
      consents,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  const consentsList =
    consents &&
    consents.reduce(
      (acc, consent) => {
        const createdAt = consent.meta?.createdAt && new Date(consent.meta.createdAt)
        const category =
          consent.category?.[0].coding?.[0].display ?? consent.category?.[0].coding?.[0].code ?? "No category"

        if (!acc[category]) {
          acc[category] = { category: category, date: createdAt }
        }

        return acc
      },
      {} as { [key: string]: { category: string; date: Date | undefined } },
    )

  return {
    consents,
    consentsList: Array.from(Object.values(consentsList ?? [])),
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type ConsentsQueryData = {
  consents: Consent[]
  next: number | undefined
  total: number
}

export { useConsents }
