import { ClientProvider } from "@chartedhealth/payment-components"
import { useCallback, useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import { useBasisTheoryInstance } from "account"
import { useClient } from "api"
import { useChartContext } from "chart-view"
import { ModulesId } from "commons"
import { useAppModuleContext } from "internals"
import { datadogLogs } from "logger"
import { useOrganizationContext } from "organization"
import { useAuth } from "security"

import { InvoiceListContainer } from "./InvoiceListContainer"

import "@chartedhealth/payment-components/dist/index.css"

const InvoiceContainer = () => {
  const { user } = useAuth()
  const { search, getSignedUrl, operationRequest, read, patch } = useClient()
  const [searchParams] = useSearchParams()
  const { isAdmin } = useOrganizationContext()
  const { appSubModules } = useAppModuleContext()
  const { setActiveSubModule, activeSubModule } = useChartContext()
  const { bt } = useBasisTheoryInstance()

  const loggedInUserResource = useMemo(() => ({ ...user?.linkedResource, isAdmin }), [isAdmin, user?.linkedResource])

  useEffect(() => {
    let subModule = null

    if (searchParams.get("invoiceId")) {
      subModule = appSubModules["invoice"][ModulesId.INVOICE_DETAILS]
    } else if (searchParams.get("paymentId")) {
      subModule = appSubModules["invoice"][ModulesId.INVOICE_PAYMENT_DETAILS]
    }

    setActiveSubModule(subModule)
  }, [appSubModules, setActiveSubModule, searchParams])

  const renderView = useCallback(() => {
    if (activeSubModule) {
      return (
        <ClientProvider
          apm={datadogLogs.logger}
          bt={bt}
          loggedInUserResource={loggedInUserResource}
          search={search}
          getSignedUrl={getSignedUrl}
          operationRequest={operationRequest}
          read={read}
          patch={patch}
        >
          {activeSubModule.renderContent()}
        </ClientProvider>
      )
    }

    return <InvoiceListContainer />
  }, [activeSubModule, bt, getSignedUrl, loggedInUserResource, operationRequest, patch, read, search])

  return renderView()
}

export { InvoiceContainer }
