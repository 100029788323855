enum ChatStatus {
  READ = "read",
  UNREAD = "unread",
}

const chatStatusOptions = [
  { value: ChatStatus.READ, display: "Read" },
  { value: ChatStatus.UNREAD, display: "Unread" },
]

export { ChatStatus, chatStatusOptions }
