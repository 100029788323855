import { ResourceObject } from "fhir"
import { FormikValues } from "formik"
import { classNames } from "primereact/utils"
import { Key } from "react"

import { CommonProps, StackedListItem, StackedListItemProps, StackedListProps } from "./StackedListItem"

const StackedListContainer = <T extends ResourceObject | FormikValues>({
  data,
  itemModelBuilder,
  rowHover = true,
  itemPadding,
  itemsClassName,
  className,
  withoutDivider,
  itemProps,
  keyGenerator,
}: Props<T>) => {
  return (
    <ul
      className={classNames("@container divide-gray-200", className, {
        "space-y-4": withoutDivider,
        "divide-y": !withoutDivider,
      })}
    >
      {data?.map((item, index) => (
        <StackedListItem
          key={keyGenerator?.(item) ?? item?.id ?? index}
          modelData={itemModelBuilder(item, index)}
          rowHover={rowHover}
          itemPadding={itemPadding}
          className={typeof itemsClassName === "function" ? itemsClassName(item) : itemsClassName}
          {...itemProps}
        />
      ))}
    </ul>
  )
}

type Props<T> = CommonProps & {
  data: T[]
  itemsClassName?: string | ((item: T) => string)
  itemModelBuilder(item: T, itemIndex: number): StackedListItemProps
  className?: string
  withoutDivider?: boolean
  itemProps?: Omit<StackedListProps, "modelData">
  keyGenerator?: (item: T) => Key | undefined
}

export { StackedListContainer }
