const medicationStatementQueryKeys = {
  all: ["patient/medications"] as const,
  list: (patientId: string, encounter?: string) => [
    ...medicationStatementQueryKeys.all,
    patientId,
    ...(encounter ? [encounter] : []),
  ],
}

export { medicationStatementQueryKeys }
