import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"

import { organizationSettingId } from "data"

import { useSettingQueryFunction } from "./useSettings"
import { settingsQueryKeys } from "../../query-keys"

const useEnabledLabFacilities = (currentOrganizationId: string) => {
  const getSetting = useSettingQueryFunction()
  const queryKey = settingsQueryKeys.labFacilities(currentOrganizationId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const setting = await getSetting(currentOrganizationId, organizationSettingId.labFacilities)

      return setting
    },
  })

  const { labs, labLocation } = useMemo(
    () =>
      data?.items?.reduce<{ labs: string[]; labLocation: Record<string, string> }>(
        (acc, item) =>
          item.value?.boolean && item.value.Reference?.id
            ? {
                labs: [...acc.labs, item.value.Reference?.id as string],
                labLocation: {
                  ...acc.labLocation,
                  [item.value.Reference.id]: item.value.uri?.replace("Location/", "") ?? "",
                },
              }
            : acc,
        { labs: [], labLocation: {} },
      ) ?? { labs: [], labLocation: {} },
    [data],
  )

  return { labs, labLocation, isLoading }
}

export { useEnabledLabFacilities }
