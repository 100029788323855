import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Communication } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { messagesQueryKeys } from "messages"
import { useOrganizationContext } from "organization"

import { communicationQueryKeys } from "../query-keys"

const useSendCommunication = () => {
  const { create } = useClient()
  const queryClient = useQueryClient()
  const { currentOrganizationId } = useOrganizationContext()

  const newCommunication = async (message: Communication) => {
    return create("Communication", message)
  }

  const { mutate: sendCommunication, isPending } = useMutation({
    mutationFn: newCommunication,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: communicationQueryKeys.all })
      queryClient.refetchQueries({
        queryKey: messagesQueryKeys.orgPatients.all(currentOrganizationId),
        type: "all",
      })
    },
  })

  return { sendCommunication, isPending }
}
export { useSendCommunication }
