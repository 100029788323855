import { appointmentMinutsDuration, appointmentTypesCodes } from "data"
import {
  InputField,
  DateField,
  TextAreaField,
  CodeableConceptField,
  DropdownField,
  AutoCompletePatientField,
  ReferenceDropdownField,
} from "commons"
import { useOrganizationContext } from "organization"

const AppointmentForm = ({ hidePatientField, isReadonlyPatient, hideExtraParticipantFields }: Props) => {
  const { roomsRefs, devicesRefs } = useOrganizationContext()

  return (
    <div className="relative p-fluid grid gap-4 mb-3 p-2">
      {!hidePatientField && (
        <AutoCompletePatientField field="participant[0].actor" label="Patient" isReadonly={isReadonlyPatient} />
      )}
      <InputField field="description" label="Description" />
      <CodeableConceptField field="appointmentType" label="Appointment Type" codes={appointmentTypesCodes} />
      <DateField field="start" label="Start Date" showTime />
      <DropdownField field="minutesDuration" label="Duration(in minutes)" options={appointmentMinutsDuration} />
      {!hideExtraParticipantFields && (
        <>
          <ReferenceDropdownField
            field="participant[3].actor"
            label="Room"
            options={roomsRefs}
            className=" w-full"
            isClearable
          />
          <ReferenceDropdownField
            field="participant[4].actor"
            label="Device"
            options={devicesRefs}
            className=" w-full"
            isClearable
          />
        </>
      )}
      <TextAreaField field="comment" label="Comment" rows={5} />
    </div>
  )
}

type Props = {
  hidePatientField?: boolean
  isReadonlyPatient?: boolean
  hideExtraParticipantFields?: boolean
}

export { AppointmentForm }
