import { useMutation, useQueryClient } from "@tanstack/react-query"
import { isAfter } from "date-fns/isAfter"
import { parseISO } from "date-fns/parseISO"
import { Encounter } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { formatsByTypes } from "data"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess, formatDate } from "utils"

import { encounterQueryKeys } from "../query-keys"

const useFinishEncounter = (patientId: string, onSucess?: () => void, onSettled?: () => void) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const finishEncounter = async (openEncounter: Encounter) => {
    const start = parseISO(openEncounter.period?.start ?? new Date().toISOString())
    const encounter: Partial<Encounter> = {
      status: "finished",
      period: {
        ...openEncounter.period,
        end: formatDate(start && isAfter(new Date(), start) ? new Date() : start, formatsByTypes.ISO_8601_DATETIME),
      },
      meta: openEncounter.meta,
    }

    if (!openEncounter.id) {
      throw new Error("Internal error", { cause: { name: "500", message: "Error trying finish encounter no id" } })
    }

    return patch("Encounter", openEncounter.id, encounter)
  }

  const { mutate, isPending } = useMutation({
    mutationFn: finishEncounter,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: encounterQueryKeys.list(patientId, "KP") })
      await queryClient.invalidateQueries({ queryKey: encounterQueryKeys.details(patientId), refetchType: "all" })
      displayNotificationSuccess("Encounter finished successfully!")
      // datadogLogs.logger.info("Encounter finished successfully!", { data })
      onSucess?.()
    },
    onSettled,
  })

  return { finishEncounter: mutate, isFinishing: isPending }
}

export { useFinishEncounter }
