import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Observation } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

import { socialHistoryQueryKeys } from "../query-keys"

const useUpdateSocialHistory = (hideForm: () => void) => {
  const { update } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const newSocialHistory = async (socialHistory: Observation) =>
    update("Observation", socialHistory.id as string, socialHistory)

  const { mutate: updateSocialHistory, isPending: isUpdating } = useMutation({
    mutationFn: newSocialHistory,
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        await queryClient.invalidateQueries({
          queryKey: socialHistoryQueryKeys.withPatientId(patientId),
          refetchType: "all",
        })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: socialHistoryQueryKeys.withPatientId(patientId),
        refetchType: "all",
      })
      displayNotificationSuccess("Social history updated successfully!")
      // datadogLogs.logger.info("Social history updated successfully!", { data })
    },
    onSettled: hideForm,
  })

  return { updateSocialHistory, isUpdating }
}

export { useUpdateSocialHistory }
