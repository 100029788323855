import { toDataURL, QRCodeToDataURLOptions } from "qrcode"

const useQrCodeGenerator = () => {
  const options: QRCodeToDataURLOptions = {
    width: 400,
  }
  let imageUrl: string | undefined

  const getQrImage = (qrUrl: string) => {
    toDataURL(qrUrl, options, (error, url) => {
      if (error) {
        throw new Error("Something went wrong generating the QR code")
      }

      imageUrl = url
    })

    return imageUrl
  }

  return { getQrImage }
}

export { useQrCodeGenerator }
