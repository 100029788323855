import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Location } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { organizationQueryKeys } from "organization"
import { displayNotificationSuccess } from "utils"

const useUpdateLocation = (onSuccess?: () => void, onSettled?: () => void) => {
  const { update } = useClient()
  const queryClient = useQueryClient()

  const updateFn = async (location: Location) => update("Location", location.id as string, location)

  const { mutate: updateLocation, isPending } = useMutation({
    mutationFn: updateFn,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { managingOrganization }) => {
      queryClient.refetchQueries({
        queryKey: managingOrganization?.id
          ? organizationQueryKeys.currentOrganization.current(managingOrganization.id)
          : organizationQueryKeys.currentOrganization.all,
        type: managingOrganization?.id ? "all" : undefined,
      })
      displayNotificationSuccess("Hours of operation updated successfully!")
      // datadogLogs.logger.info(`Hours of operation on ${managingOrganization?.display} updated successfully!`)
      onSuccess?.()
    },
    onSettled,
  })

  return { updateLocation, isUpdating: isPending }
}

export { useUpdateLocation }
