import { faFileImport, faVials } from "@fortawesome/pro-regular-svg-icons"
import { faCalendarDays } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { parseISO } from "date-fns"
import { Bundle, BundleEntryArray, DiagnosticReport, Observation, codeableConceptAsString, isObservation } from "fhir"
import { Checkbox } from "primereact/checkbox"
import { Column } from "primereact/column"
import { DataTable, DataTableSelectionMultipleChangeEvent } from "primereact/datatable"
import { useEffect, useId, useMemo, useState } from "react"

import { EmptyMessage, FooterActions, InfiniteScroll, SkeletonColumn, SkeletonLoader } from "commons"
import { formatsByTypes } from "data"
import { usePatientContext } from "patients"
import { formatDate } from "utils"

import { usePatientObservations, usePreviewPatientInfo, useTransferPatientInfo } from "../hooks"
import { Patient360ImportDataDialog } from "./Patient360ImportDataDialog"

const Patient360LabsObservations = () => {
  const loaderKey = useId()
  const { patientId, patient } = usePatientContext()
  const { observationsEntries, isLoading, hasNextPage, fetchNextPage, reloadObservations } = usePatientObservations({
    patientId,
    category: "laboratory",
  })
  const [showPreviewDialog, setShowPreviewDialog] = useState(false)
  const [previewObservationsBundle, setPreviewObservationsBundle] = useState<Bundle>()
  const [previewDRWithObsMap, setPreviewDRWithObsMap] = useState<DRWithObservationsMap>({})
  const previewObservationsWithDR = useMemo(
    () =>
      Object.values(previewDRWithObsMap).reduce(
        (acc, { diagnosticReportEntry, observationsEntries }) => [
          ...acc,
          ...observationsEntries.map((entry) => ({
            ...(entry.resource as Observation),
            diagnosticReport: diagnosticReportEntry.resource as DiagnosticReport,
          })),
        ],
        [] as ObservationWithDiagnosticReport[],
      ),
    [previewDRWithObsMap],
  )
  const hasPreviewObservations = previewObservationsWithDR.length > 0

  const [selectedDiagnosticReportIds, setSelectedDiagnosticReportIds] = useState<string[]>([])

  const selectedObservationsWithDR = useMemo(
    () =>
      previewObservationsWithDR.filter(({ diagnosticReport }) =>
        selectedDiagnosticReportIds.includes(diagnosticReport.identifier?.[0]?.value ?? ""),
      ),
    [selectedDiagnosticReportIds, previewObservationsWithDR],
  )

  const { previewPatientInfo: previewPatientLabs, isLoadingPreview } = usePreviewPatientInfo("labs", (bundle) => {
    setPreviewObservationsBundle(bundle)
    setPreviewDRWithObsMap(getGroupedObservationWithDiagnosticReport(bundle))
    setShowPreviewDialog(true)
  })
  const { transferPatient, isTransfering } = useTransferPatientInfo(() => {
    reloadObservations?.()
    setShowPreviewDialog(false)
  })

  const handlePreview = () => {
    previewPatientLabs({ patId: patientId })
  }

  const handleTransfer = () => {
    transferPatient({
      ...(previewObservationsBundle as Bundle),
      entry: selectedDiagnosticReportIds.reduce(
        (acc, drId) => [
          ...acc,
          previewDRWithObsMap[drId].diagnosticReportEntry,
          ...previewDRWithObsMap[drId].observationsEntries,
        ],
        [] as BundleEntryArray[],
      ),
    })
  }

  const handleClosePreviewDialog = () => {
    setShowPreviewDialog(false)
  }

  const handleSelectionChange = (e: DataTableSelectionMultipleChangeEvent<ObservationWithDiagnosticReport[]>) => {
    const observationsEntries: ObservationWithDiagnosticReport[] = e.value
    if (observationsEntries.length) {
      setSelectedDiagnosticReportIds(Object.keys(previewDRWithObsMap))
      return
    }
    setSelectedDiagnosticReportIds([])
  }

  const handleDRSelectionChange = (id: string, checked: boolean) => {
    if (checked) {
      setSelectedDiagnosticReportIds((prev) => [...prev, id])
      return
    }
    setSelectedDiagnosticReportIds((prev) => prev.filter((drId) => drId !== id))
  }

  useEffect(() => {
    setSelectedDiagnosticReportIds(Object.keys(previewDRWithObsMap))
  }, [previewDRWithObsMap])

  const columnsDef: SkeletonColumn[] = [
    { header: "Code", headerStyle: { minWidth: "5rem" } },
    { header: "Value", headerStyle: { minWidth: "8rem" } },
  ]

  const hasUsableAddress = useMemo(() => {
    return patient.address?.some(({ use }) => !!use) ?? false
  }, [patient.address])

  if (isLoading) {
    return <SkeletonLoader repeats={4} containerClassName="pl-5 py-3" loaderType="table" columns={columnsDef} />
  }

  const loader = () => (
    <SkeletonLoader
      key={loaderKey}
      repeats={4}
      containerClassName="pl-5"
      loaderType="table"
      columns={columnsDef.map(({ headerStyle, body }) => ({
        header: undefined,
        headerStyle: headerStyle,
        headerClass: "hidden",
        body: body,
      }))}
    />
  )

  const addOptions = [
    {
      icon: faFileImport,
      label: "Import labs",
      command: () => handlePreview(),
      loading: isLoadingPreview,
      disabled: !hasUsableAddress,
    },
  ]

  return (
    <>
      {!observationsEntries?.length ? (
        <div className="h-full w-full flex items-center justify-center">
          <EmptyMessage
            icon={faVials}
            message={`No labs found`}
            subMessage={
              !hasUsableAddress ? "Get started by linking addresses to search" : "Get started by importing patient labs"
            }
            action={handlePreview}
            actionText="Import labs"
            actionIcon={faFileImport}
            isLoading={isLoadingPreview}
            disabled={!hasUsableAddress}
          />
        </div>
      ) : (
        <>
          <div className="h-full overflow-auto">
            <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
              <ObservationsTable observationsEntries={observationsEntries ?? []} />
            </InfiniteScroll>
          </div>
          {!showPreviewDialog && <FooterActions actions={addOptions} />}
        </>
      )}
      <Patient360ImportDataDialog
        showDialog={showPreviewDialog}
        header="Labs Preview"
        isLoading={isTransfering}
        actionDisabled={isTransfering || !hasPreviewObservations}
        handleDialogAction={handleTransfer}
        handleCloseDialog={handleClosePreviewDialog}
        totalRecords={previewObservationsWithDR.length}
      >
        <ObservationsWithDRTable
          previewObservationsWithDR={previewObservationsWithDR}
          selectedObservationsWithDR={selectedObservationsWithDR}
          selectedDiagnosticReportIds={selectedDiagnosticReportIds}
          onSelectionChange={handleSelectionChange}
          onDRSelectionChange={handleDRSelectionChange}
        />
      </Patient360ImportDataDialog>
    </>
  )
}

const ObservationsTable = ({ observationsEntries }: { observationsEntries: BundleEntryArray[] }) => {
  const valueBodyTemplate = ({ value }: Observation) => {
    const hasQuantity = value?.Quantity?.value && value.Quantity.unit

    if (hasQuantity) {
      const quantity = value?.Quantity
      return quantity ? `${quantity?.value} ${quantity?.unit}` : ""
    }

    return value?.string ?? value?.integer ?? "unspecified"
  }

  return (
    <DataTable
      value={observationsEntries}
      dataKey="resource.identifier.0.value"
      size="small"
      responsiveLayout="scroll"
      className="w-full h-full overflow-y-auto"
    >
      <Column
        field="resource.code"
        header="Code"
        body={({ resource: { code } }) => (
          <p title="Code" className="font-bold mr-2">
            {codeableConceptAsString(code)}
          </p>
        )}
        headerStyle={{ minWidth: "8rem" }}
      />
      <Column
        field="resource.value.Quantity"
        header="Value"
        body={(rowData) => valueBodyTemplate(rowData.resource)}
        headerStyle={{ minWidth: "8rem" }}
      />
    </DataTable>
  )
}

const ObservationsWithDRTable = ({
  previewObservationsWithDR,
  selectedObservationsWithDR,
  selectedDiagnosticReportIds,
  onSelectionChange,
  onDRSelectionChange,
}: {
  previewObservationsWithDR: ObservationWithDiagnosticReport[]
  selectedObservationsWithDR: ObservationWithDiagnosticReport[]
  selectedDiagnosticReportIds: string[]
  onSelectionChange: (e: DataTableSelectionMultipleChangeEvent<ObservationWithDiagnosticReport[]>) => void
  onDRSelectionChange: (id: string, checked: boolean) => void
}) => {
  const valueBodyTemplate = ({ value }: ObservationWithDiagnosticReport) => {
    const hasQuantity = value?.Quantity?.value && value.Quantity.unit

    if (hasQuantity) {
      const quantity = value?.Quantity
      return quantity ? `${quantity?.value} ${quantity?.unit}` : ""
    }

    return value?.string ?? value?.integer ?? "unspecified"
  }

  const headerTemplate = ({ diagnosticReport }: ObservationWithDiagnosticReport) => {
    const drIdentifier = diagnosticReport.identifier?.[0]?.value ?? ""
    return (
      <div className="flex items-center">
        <Checkbox
          className="w-10"
          checked={selectedDiagnosticReportIds.includes(drIdentifier)}
          onChange={({ checked }) => {
            onDRSelectionChange(drIdentifier, checked ?? false)
          }}
        />
        <div className="flex flex-col">
          <div className="font-bold">{codeableConceptAsString(diagnosticReport.code)}</div>
          {diagnosticReport.issued && (
            <div className="text-sm">
              <FontAwesomeIcon icon={faCalendarDays} className="mr-1.5 text-gray-400" />
              {formatDate(parseISO(diagnosticReport.issued as unknown as string), formatsByTypes.LONG_DATETIME)}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <DataTable
      value={previewObservationsWithDR}
      dataKey="identifier.0.value"
      size="small"
      selectionMode="multiple"
      rowGroupMode="subheader"
      groupRowsBy="diagnosticReport"
      rowGroupHeaderTemplate={headerTemplate}
      selection={selectedObservationsWithDR}
      onSelectionChange={onSelectionChange}
      className="w-full h-full overflow-y-auto"
    >
      <Column
        selectionMode="multiple"
        headerStyle={{ minWidth: "1rem", width: "40px" }}
        bodyStyle={{ visibility: "hidden" }}
      />
      <Column
        header="Group"
        style={{ minWidth: "200px" }}
        bodyStyle={{ backgroundColor: "white", color: "black" }}
      ></Column>
      <Column
        field="code"
        header="Code"
        body={({ code }) => (
          <p title="Code" className="font-semibold mr-2">
            {codeableConceptAsString(code)}
          </p>
        )}
        headerStyle={{ minWidth: "12rem" }}
        bodyStyle={{ backgroundColor: "white", color: "black" }}
      />
      <Column
        field="value.Quantity"
        header="Value"
        body={valueBodyTemplate}
        headerStyle={{ minWidth: "8rem" }}
        bodyStyle={{ backgroundColor: "white", color: "black" }}
      />
    </DataTable>
  )
}

const getGroupedObservationWithDiagnosticReport = (
  bundle: Bundle,
): {
  [key in string]: {
    diagnosticReportEntry: BundleEntryArray
    observationsEntries: BundleEntryArray[]
  }
} => {
  const initialEntriesValues = {
    diagnosticReportEntries: [] as BundleEntryArray[],
    observationsEntries: [] as BundleEntryArray[],
  }

  const { diagnosticReportEntries, observationsEntries } =
    bundle.entry?.reduce((acc, entry) => {
      if (isObservation(entry.resource)) {
        return { ...acc, observationsEntries: [...acc.observationsEntries, entry] }
      }
      return { ...acc, diagnosticReportEntries: [...acc.diagnosticReportEntries, entry] }
    }, initialEntriesValues) ?? initialEntriesValues

  const observationsEntriesMap = observationsEntries.reduce(
    (acc, entry) => ({ ...acc, [entry.fullUrl ?? ""]: entry }),
    {} as Record<string, BundleEntryArray>,
  )

  return diagnosticReportEntries.reduce((acc, entry) => {
    const observations =
      (entry.resource as DiagnosticReport).result?.reduce((acc, reference) => {
        if (reference?.uri && observationsEntriesMap[reference?.uri]) {
          return [...acc, observationsEntriesMap[reference?.uri]]
        }
        return acc
      }, [] as BundleEntryArray[]) ?? []

    return {
      ...acc,
      [(entry.resource as DiagnosticReport).identifier?.[0]?.value ?? ""]: {
        diagnosticReportEntry: entry,
        observationsEntries: observations,
      },
    }
  }, {} as DRWithObservationsMap)
}

type DRWithObservationsMap = {
  [key in string]: {
    diagnosticReportEntry: BundleEntryArray
    observationsEntries: BundleEntryArray[]
  }
}

type ObservationWithDiagnosticReport = Observation & { diagnosticReport: DiagnosticReport }

export { Patient360LabsObservations }
