import { useMutation, useQueryClient } from "@tanstack/react-query"
import { CommunicationRequest } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { emailQueryKeys } from "../query-keys"

const useResendCommunicationRequest = (onSuccess?: () => void) => {
  const { create } = useClient()
  const queryClient = useQueryClient()

  const newCommunicationRequest = async (cr: CommunicationRequest) => {
    return create("CommunicationRequest", cr)
  }

  const { mutate: resendCR, isPending: isResending } = useMutation({
    mutationFn: newCommunicationRequest,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: emailQueryKeys.all })
      displayNotificationSuccess("Mail queued successfully!")
      // datadogLogs.logger.info("Mail queued successfully!", { data })
      onSuccess?.()
    },
  })

  return { resendCR, isResending }
}
export { useResendCommunicationRequest }
