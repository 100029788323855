import { useUnreadMessages } from "messages"
import { usePatientContext } from "patients"

import { ChatContainer } from "./ChatContainer"
import "./MessagesContainer.css"

const MessagesContainer = () => {
  const { patient, patientId, patientRef } = usePatientContext()
  const { messages } = useUnreadMessages(patient.id as string)

  return (
    <div className="h-full">
      <ChatContainer
        patient={patient}
        patientId={patientId}
        patientRef={patientRef}
        unreadMessages={messages}
        unreadedCount={messages.length}
      />
    </div>
  )
}

export { MessagesContainer }
