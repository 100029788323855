import { useInfiniteQuery } from "@tanstack/react-query"
import { getResources, Patient, Reference } from "fhir"
import groupBy from "lodash/groupBy"

import { useClient } from "api"
import { useOrganizationContext } from "organization"

import { patientsQueryKeys } from "../query-keys"

const usePatients = (filter = "", gender?: string, practitioner?: Reference) => {
  const { search } = useClient()
  const { currentOrganizationId } = useOrganizationContext()
  const queryKey = patientsQueryKeys.list(currentOrganizationId, filter, gender, practitioner)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    PatientsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        ...(filter ? { termsearch: filter } : {}),
        ...(gender ? { _gender: gender } : {}),
        ...(practitioner ? { "general-practitioner": practitioner.id } : {}),
        "active:not": "false",
        _count: "20",
        _elements: "id,identifier,name,gender,address,photo,birthDate,active,telecom",
        _sort: "given",
        _page: `${pageParam}`,
      })

      const bundle = await search({ endpoint: `Organization/${currentOrganizationId}/Patient`, filters, signal })

      const patients = getResources(bundle) as Patient[]

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { patients, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, filter, gender, practitioner } },
  })

  const patients = data?.pages?.flatMap(({ patients }) => patients) ?? []
  const count = patients?.length
  const groupedPatients = groupBy(patients, ({ name }) => name?.[0]?.family?.[0].toUpperCase() ?? "other")

  const patientGroups = Object.entries(groupedPatients)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .map(([key, items = []]) => ({
      key,
      name: key,
      items,
    }))

  return {
    patientGroups,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type PatientsQueryData = { patients: Patient[]; next: number | undefined; total: number }

export type PatientData = {
  patients: Patient[]
}

export { usePatients }
