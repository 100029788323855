import { faMars, faVenus, faVenusMars } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { codeableConceptAsString } from "fhir"
import pluralize from "pluralize"
import { classNames } from "primereact/utils"
import { useState } from "react"

import { CardSelectionItem } from "commons"
import { LabComboTitle } from "commons/labs"
import { LaboratoryComboTest } from "commons/types"
import { COMBO_PROMO_CODE } from "data"
import { getMoneyCurrencyAlt } from "utils"

import { LaboratoryOrderComboDetail } from "./LaboratoryOrderComboDetail"

const ComboItem = ({ labCombo, selected, selectCombo, showPrice = true, isInsurance = false }: Props) => {
  const [showDetails, setShowDetails] = useState(false)
  const promo = labCombo.combo?.topic?.find((topic) => topic.coding?.[0]?.code === COMBO_PROMO_CODE)?.coding?.[0]
  const isPromoCombo = !!promo
  const isShowable = !(isInsurance && isPromoCombo)
  const gender = labCombo.combo.useContext?.find(({ code }) => code.code === "gender")?.value?.CodeableConcept

  return (
    <>
      {isShowable && (
        <div className="relative overflow-hidden p-0.5">
          {isPromoCombo && (
            <div className="absolute left-0 top-0 h-16 w-16">
              <div className="absolute flex items-center justify-center -left-7 top-2 w-24 h-5 transform -rotate-45 bg-red-500 bg-gradient-to-r from-red-400 to-red-600 shadow-lg  text-center text-white py-1 text-sm z-10">
                {promo.display}
              </div>
            </div>
          )}
          <CardSelectionItem
            mainText={labCombo.combo.title as string}
            mainData={<LabComboTitle combo={labCombo.combo} />}
            extraDetails={
              <div className="flex space-x-1">
                <span title={codeableConceptAsString(gender)} className="text-sm text-gray-400">
                  <FontAwesomeIcon
                    icon={gender ? (gender?.coding?.[0]?.code === "male" ? faMars : faVenus) : faVenusMars}
                    className="text-xs mr-1"
                  />
                </span>
                <span title="Tests" className="text-sm text-gray-400">
                  {labCombo.laboratoryTests.length} {pluralize("test", labCombo.laboratoryTests.length)}
                </span>
              </div>
            }
            rightData={
              showPrice ? (
                <span
                  className={classNames({ "line-through": isInsurance })}
                >{`${getMoneyCurrencyAlt(labCombo.price?.currency)} ${(labCombo?.price?.value ?? 0).toFixed(2)}`}</span>
              ) : undefined
            }
            onSelect={() => selectCombo(labCombo)}
            selected={selected}
            showSelectedMark={false}
            onShowDetails={() => setShowDetails(true)}
            clickableArea="card"
          />
          {showDetails && (
            <LaboratoryOrderComboDetail
              labCombo={labCombo}
              showPrice={showPrice}
              isInsurance={isInsurance}
              onHide={() => setShowDetails(false)}
            />
          )}
        </div>
      )}
    </>
  )
}

type Props = {
  labCombo: LaboratoryComboTest
  selected: boolean
  selectCombo(combo: LaboratoryComboTest): void
  showPrice?: boolean
  isInsurance?: boolean
}

export default ComboItem
