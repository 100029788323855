import { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { FC } from "react"
import { useSearchParams } from "react-router-dom"

import { StackedListContainer } from "commons"
import { surveyModelBuilder, useQuestionnaireResponses } from "surveys"

import { WrapUpSection } from "./WrapUpSection"
/* TODO: See how register encounter info when assign a Q or fill a QR */
const QuestionnairesSection: FC<Props> = ({ patientId, encounterId, icon }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { questionnaireData, isLoading, total } = useQuestionnaireResponses({
    patientId,
    key: "WK",
    status: ["active", "completed"],
    encounter: encounterId,
  })

  const showSelectedQR = (id: string) => {
    const params = new URLSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
      view: "survey",
      qrId: id,
    })
    setSearchParams(params)
  }

  return (
    <WrapUpSection
      sectionTitle="Questionnaires"
      isLoading={isLoading}
      isEmpty={!total}
      icon={icon}
      emptyMesage="No questionnaires assigned in this encounter"
      className="w-10/12 self-end z-50"
    >
      <StackedListContainer
        data={questionnaireData}
        itemModelBuilder={(item) =>
          surveyModelBuilder({
            item,
            onClick: item.qResponse?.id ? () => showSelectedQR(item.qResponse?.id as string) : undefined,
            showAsExternal: true,
            showMenuActions: false,
          })
        }
      />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounterId: string
  icon: IconDefinition
}

export { QuestionnairesSection }
