import { labOrderDetailQueryKey } from "commons/labs"

const labOrdersQueryKeys = {
  all: ["patient/laboratoryOrders"] as const,
  withPatientID: (patientId?: string) => [...labOrdersQueryKeys.all, patientId],
  list: (patientId: string, statusFilter: string[], searchText?: string, encounter?: string) => [
    ...labOrdersQueryKeys.all,
    patientId,
    statusFilter,
    searchText,
    encounter,
  ],
  observations: (patientId: string, searchText?: string) => ["patient/lab-order-observations", searchText, patientId],
  catalogs: (laboratoryId?: string) => ["patient/laboratoryCatalogs", laboratoryId],
  comboTests: (organizationId: string, catalogAuthorId: string, billingType?: string) => [
    "laboratory/combotests",
    organizationId,
    catalogAuthorId,
    billingType,
  ],
  ...labOrderDetailQueryKey,
}

export { labOrdersQueryKeys }
