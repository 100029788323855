import { Bundle, Observation } from "fhir"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { v4 } from "uuid"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { CustomError } from "commons"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"
import { usePatientContext } from "patients"
import { useOpenEncounter } from "encounter"

import { sanitize } from "../components/validations"
import { vitalsQueryKeys } from "../query-keys"

const useCreateVitals = (onSettled?: () => void) => {
  const { patientId } = usePatientContext()
  const { transaction } = useClient()
  const queryClient = useQueryClient()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const newVital = async (vitals: Observation[]) => {
    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: vitals.map((resource) => {
        const observation = sanitize(resource)

        return {
          fullUrl: `urn:uuid:${v4()}`,
          request: {
            method: "POST",
            url: "Observation",
          },
          resource: {
            ...observation,
            ...(openEncounterRef?.id ? { encounter: openEncounterRef } : {}),
            resourceType: "Observation",
          },
        }
      }),
    }

    return transaction(bundle)
  }

  const { mutate, isPending } = useMutation({
    mutationFn: newVital,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: vitalsQueryKeys.list(patientId), refetchType: "all" })
      await queryClient.invalidateQueries({ queryKey: vitalsQueryKeys.knowledge(patientId) })
      displayNotificationSuccess("Vital sign created successfully!")
    },
    onSettled: onSettled,
  })

  return { createVitals: mutate, isCreating: isPending }
}

export { useCreateVitals }
