import { faPeopleRoof } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { Patient360FamilyHistory } from "../../../patient360/components/Patient360FamilyHistory"

class FamilyHistory360SubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.FAMILY_HISTORY_360
    this.display = "Family History"
    this.parent = ModulesId.PATIENT_360
    this.icon = faPeopleRoof
    this.content = <Patient360FamilyHistory />
  }
}

export { FamilyHistory360SubModule }
