import {
  faCakeCandles,
  faCircleUser,
  faEnvelope,
  faLocationDot,
  faPhone,
  faSpinner,
  faUser,
} from "@fortawesome/pro-solid-svg-icons"
/* import { faPen } from "@fortawesome/pro-light-svg-icons" */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format, parseISO } from "date-fns"
import { Address, Patient, getFirstEmail, getFirstPhone, humanNameAsString } from "fhir"
import { classNames } from "primereact/utils"
import { useMemo, useState } from "react"

import { AddFieldArrayItemButton, EmptyMessage } from "commons"
import { emptyAddress, formatsByTypes } from "data"
import { AddressForm, usePatchPatient } from "patients"
import { calculateAge, getStringAddress } from "utils"

const HgPatientLinkAddress = ({ patient }: Props) => {
  const { name, gender, birthDate, telecom, address } = patient
  const [editingContext, setEditingContext] = useState<"create" | IndexedAddress | undefined>(undefined)
  const restoreEditingContext = () => setEditingContext(undefined)

  const { isPatching, patchPatient, context } = usePatchPatient()

  const updateAddress = (patient: Partial<Patient>) => {
    let index = 0

    if (typeof editingContext === "string") {
      index = patient.address?.length ? patient.address.length - 1 : 0
    } else {
      if (editingContext) {
        index = editingContext.index
      }
    }

    patchPatient({
      patientId: patient.id ?? "",
      patientData: { address: patient.address, meta: patient.meta },
      updatedAddressIndex: index,
    })
    restoreEditingContext()
  }

  const handleAddNewAddress = () => setEditingContext("create")
  /* const handleEditAddress = (address: Address, index: number) => setEditingContext({ address, index }) */
  const isAddressToEdit = !!editingContext && editingContext !== "create"

  const { patientAddress, networkAddress } = useMemo(() => {
    const initialVal = { patientAddress: [], networkAddress: [] } as {
      patientAddress: Array<Address>
      networkAddress: Array<Address>
    }
    return (
      address?.reduce((acc, a) => {
        if (a.use === "temp") {
          return { ...acc, networkAddress: [...acc.networkAddress, a] }
        } else return { ...acc, patientAddress: [...acc.patientAddress, a] }
      }, initialVal) ?? initialVal
    )
  }, [address])

  return (
    <div className="flex flex-row h-full w-full">
      <div className="flex flex-col h-full w-1/3 min-w-[12rem] border-r">
        <div className="items-baseline flex justify-start bg-slate-100 text-lg font-bold px-1">
          <FontAwesomeIcon icon={faUser} size="1x" className="mr-1" />
          <span className="text-lg font-bold">{humanNameAsString(name?.[0])}</span>
        </div>

        <div className="text-zinc-500 font-normal text-sm tracking-tight pt-2.5 flex flex-col gap-2">
          <span>
            <FontAwesomeIcon icon={faCircleUser} className="text-sm" />
            <span className="px-1" title="Age">
              {calculateAge(birthDate)}
            </span>

            <span className="capitalize px-1" title="Biological Sex">
              {gender}
            </span>
          </span>
          {birthDate && (
            <span title="Birthdate" className="whitespace-nowrap">
              <FontAwesomeIcon icon={faCakeCandles} className="text-sm" />
              <span className="px-1" title="Birth date">
                {format(parseISO(birthDate), formatsByTypes.LONG_DATE)}
              </span>
            </span>
          )}
          {telecom && (
            <>
              <span title="Email" className="whitespace-nowrap">
                <FontAwesomeIcon icon={faEnvelope} className="text-sm" />
                <span className="px-1" title="Email">
                  {getFirstEmail(telecom)}
                </span>
              </span>
              <span title="Phone" className="whitespace-nowrap">
                <FontAwesomeIcon icon={faPhone} className="text-sm" />
                <span className="px-1" title="Phone">
                  {getFirstPhone(telecom)}
                </span>
              </span>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col h-full grow gap-4 overflow-y-auto">
        <div className="flex justify-start bg-slate-100 text-lg font-bold pl-2 w-full">Search addresses</div>
        {editingContext ? (
          <AddressForm
            patient={patient}
            handleUpdateAddress={updateAddress}
            initialValues={isAddressToEdit ? editingContext.address : { ...emptyAddress, use: "temp" }}
            actionLabel={isAddressToEdit ? "Update" : "Add"}
            handleCancel={restoreEditingContext}
            isUpdating={isPatching}
            addressIndex={isAddressToEdit ? editingContext.index : undefined}
            isNoEffectiveAddress
          />
        ) : address?.length ? (
          <>
            {patientAddress.map((item, index) => (
              <div
                key={item.id ?? index}
                className={classNames("flex pl-3 items-center w-full justify-between", {
                  "italic transition-all duration-500 ease-in-out bg-slate-50 animate-pulse":
                    isPatching && context?.updatedAddressIndex === index,
                })}
              >
                <span>
                  <FontAwesomeIcon icon={faLocationDot} size="sm" className="mr-1" />
                  {getStringAddress(item)}
                </span>
                {
                  isPatching && context?.updatedAddressIndex === index && (
                    <FontAwesomeIcon icon={faSpinner} size="sm" width="2rem" className="mx-1" spin />
                  ) /* : (
                      <FontAwesomeIcon
                        icon={faPen}
                        size="sm"
                        width="2rem"
                        className="mx-1 cursor-pointer hover:text-primary-hover ease-in transition-colors"
                        onClick={() => handleEditAddress(item, index)}
                      />
                    ) */
                }
              </div>
            ))}
            {!!networkAddress?.length && (
              <>
                <div className="flex justify-start bg-slate-100 text-lg font-bold pl-2 w-full">
                  Other addresses from network
                </div>
                {networkAddress.map((item, index) => (
                  <div key={item.id ?? index} className="flex pl-3 items-center w-full justify-between">
                    <span>
                      <FontAwesomeIcon icon={faLocationDot} size="sm" className="mr-1" />
                      {getStringAddress(item)}
                    </span>
                  </div>
                ))}
              </>
            )}
            <AddFieldArrayItemButton className="border-none pl-2 mt-3" onClick={handleAddNewAddress} />
          </>
        ) : (
          <div className="h-full w-full flex items-center justify-center">
            <EmptyMessage
              icon={faLocationDot}
              message="No address added yet"
              subMessage="Get started by adding a new address to enhance the search on the network"
              action={handleAddNewAddress}
              actionText="Add new address"
            />
          </div>
        )}
      </div>
    </div>
  )
}

type Props = {
  patient: Patient
}

type IndexedAddress = {
  index: number
  address: Address
}

export { HgPatientLinkAddress }
