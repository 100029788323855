// NPI Validation algorithm as per https://www.cms.gov/Regulations-and-Guidance/Administrative-Simplification/NationalProvIdentStand/Downloads/NPIcheckdigit.pdf
function isValidNPIValue(npi: string) {
  // Ensure the NPI is 10 digits long
  if (!/^\d{10}$/.test(npi)) {
    return false
  }

  const digits = Array.from(npi).map(Number)

  // The last digit is the check digit
  const checkDigit = digits.pop() // Remove the check digit

  // Step 1: Double the value of alternate digits, starting from the rightmost digit
  const sum = digits.reverse().reduce((total, digit, index) => {
    // Double every second digit
    if (index % 2 === 0) {
      digit *= 2
      // If the product is 2 digits (i.e., >= 10), sum its digits (e.g., 16 -> 1 + 6 = 7)
      if (digit > 9) {
        digit -= 9
      }
    }
    return total + digit
  }, 0)

  // Step 2: Add the constant 24 as per NPI requirement
  const adjustedSum = sum + 24

  // Step 3: Calculate the check digit by subtracting the sum from the next higher number ending in zero
  const calculatedCheckDigit = (10 - (adjustedSum % 10)) % 10

  // Compare the calculated check digit with the provided check digit
  return calculatedCheckDigit === checkDigit
}

export { isValidNPIValue }
