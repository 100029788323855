import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MedicationKnowledge, Reference } from "fhir"
import { useMemo } from "react"

import { MedicationKnowledgeDetails, SkeletonLoader, useChargeItemDefinitions } from "commons"
import { MedicationCatalogListFilters, useMedCatalogReducerState, useMedicationKnowledge } from "commons/meds"
import { MEDICATION_CATALOG } from "data"
import { useOrganizationContext } from "organization"
import { getCommonCode, getPriceByCode } from "utils"

import { MedicationKnowledgeCard } from "./MedicationKnowledgeCard"

const MedicationKnowledgeList = ({ onAddMK, addedMedCodes, isAdding, additionFailed }: Props) => {
  const { currentOrganizationId, nutraCatalogs } = useOrganizationContext()
  const { selectedCatalog, searchFilter, selectedMK, setSelectedCatalog, setSearchFilter, setSelectedMK } =
    useMedCatalogReducerState(nutraCatalogs?.[0]?.id)

  const {
    medicationKnowledge,
    medicationKnowledgeCodes,
    isLoading: isLoadingMKs,
  } = useMedicationKnowledge({
    filter: searchFilter,
    catalogId: selectedCatalog,
    category: MEDICATION_CATALOG.NUTRA,
  })
  const { chargeItemDefinitions, isLoading: isLoadingPrice } = useChargeItemDefinitions({
    organizationId: currentOrganizationId,
    codes: {
      billToPracticeOrInsuranceCIDs: medicationKnowledgeCodes,
    },
  })
  const medicationKnowledgeWithPrice = useMemo(
    () =>
      medicationKnowledge.filter((mk) => {
        const mkPrice = getPriceByCode({
          chargeItemDefinitions: chargeItemDefinitions?.billToPracticeOrInsuranceCIDs ?? {},
          medCoding: mk.code?.coding,
        })
        return !!mkPrice
      }),
    [medicationKnowledge, chargeItemDefinitions?.billToPracticeOrInsuranceCIDs],
  )

  const onSelectMK = (mk: MedicationKnowledge, unitsCount: number) => {
    const mkCatalog = nutraCatalogs.find((c) => c.id === mk.catalogHeader?.[0].id)
    onAddMK(mk, unitsCount, mkCatalog?.author?.[0] as Reference)
  }

  const isLoading = isLoadingMKs || isLoadingPrice
  const containerLayoutClass =
    "grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-2 2xl:gap-3 overflow-auto px-1 pt-5 pb-2"

  const ignoredMedCodes = addedMedCodes ?? []

  return (
    <div className="flex flex-col overflow-auto h-full relative p-3">
      <MedicationCatalogListFilters
        isLoading={isLoading}
        catalogs={nutraCatalogs}
        catalog={selectedCatalog}
        setSearchFilter={setSearchFilter}
        setSelectedCatalog={setSelectedCatalog}
      />
      {isLoading ? (
        <div className={containerLayoutClass}>
          <SkeletonLoader repeats={20} loaderType="mkCard" />
        </div>
      ) : !medicationKnowledgeWithPrice.length || !nutraCatalogs.length ? (
        <div className="flex flex-col items-center justify-center h-full">
          <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
          <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">
            {!nutraCatalogs.length
              ? "A selected catalog is necessary to be able to search for products."
              : "No products found"}
          </p>
        </div>
      ) : (
        <div className={containerLayoutClass}>
          {medicationKnowledgeWithPrice.map((mk) => {
            const mkPrice = getPriceByCode({
              chargeItemDefinitions: chargeItemDefinitions?.billToPracticeOrInsuranceCIDs ?? {},
              medCoding: mk.code?.coding,
            })
            const code = getCommonCode({ codes: mk.code?.coding, fallback: "" })
            return (
              <MedicationKnowledgeCard
                key={mk.id}
                medicationKnowledge={mk}
                unitPrice={mkPrice}
                onAdd={onSelectMK}
                isAdding={isAdding}
                additionFailed={additionFailed}
                isLoadingPrice={isLoadingPrice}
                isAlreadyAdded={!!code && ignoredMedCodes.includes(code)}
                blockAdd={!isLoadingPrice && !mkPrice}
                onClick={() => setSelectedMK(mk)}
              />
            )
          })}
        </div>
      )}
      <MedicationKnowledgeDetails selectedMK={selectedMK} onHide={() => setSelectedMK(undefined)} />
    </div>
  )
}

type Props = {
  onAddMK(mk: MedicationKnowledge, unitsCount: number, catalogAuthor: Reference): void
  addedMedCodes?: string[]
  isAdding?: boolean
  additionFailed?: boolean
}

export { MedicationKnowledgeList }
