import { CodeableConcept } from "fhir"
import { useFormikContext } from "formik"
import { useEffect } from "react"

import {
  BloodDrawField,
  ICD10CodesField,
  PractitionerInfo,
  PractitionerRoleDropdownField,
  ReferenceDropdownField,
} from "commons"
import { LaboratoryOrder, getRestrictedLabPerformer, useAvailableLabs, useLaboratoryLocations } from "commons/labs"
import { BILLING_TYPES_CODES } from "data"
import { useOrganizationContext } from "organization"

import { BillingDropdownField } from "./BillingDropdownField"
import { LaboratoryOrderCombos } from "./LaboratoryOrderCombos"
import { LaboratoryOrderPanels } from "./LaboratoryOrderPanels"

const LaboratoryOrderForm = ({ practitioners }: Props) => {
  const {
    values: {
      order: { performer, requester },
      specimenDate,
      billingType,
    },
    setFieldTouched,
    setFieldValue,
  } = useFormikContext<LaboratoryOrder>()

  useEffect(() => {
    setFieldTouched("order.reasonCode", true)
  }, [billingType, setFieldTouched])

  useEffect(() => {
    if (specimenDate) {
      setFieldValue("bloodDrawnInOffice", true)
    }
  }, [specimenDate, setFieldValue])

  const { laboratoryLocation, organizationAnIdentifier, performerLabsEnabled } = useOrganizationContext()
  const { updateLabLocation } = useLaboratoryLocations(laboratoryLocation, (locationRefs) =>
    setFieldValue("order.locationReference", locationRefs),
  )
  const { availableLabs } = useAvailableLabs(
    practitioners ?? [],
    performerLabsEnabled,
    requester,
    organizationAnIdentifier,
  )

  return (
    <div className="relative p-fluid grid lg:grid-cols-2 gap-4 lg:gap-5 mb-3 items-start">
      <div className="flex flex-col grow left-col p-3 gap-3 h-min justify-stretch sticky top-0">
        <PractitionerRoleDropdownField
          field="order.requester"
          label="Practitioner"
          options={practitioners ?? []}
          className="flex-1"
          useFilter={false}
        />
        <BillingDropdownField field="order.insurance[0]" label="Insurance" />
        <ICD10CodesField
          field="order.reasonCode"
          label="ICD-10 Codes"
          showSuggestedPatientConditions
          orgSuggestedConditions="defaultProcedureIcd10"
          validate={(value?: CodeableConcept[]) => {
            const restrictedLabPerformer = getRestrictedLabPerformer(performer?.[0]?.id as string)
            return (
              (billingType === BILLING_TYPES_CODES.INSURANCE && !value?.length
                ? "Specify one condition at least"
                : undefined) ||
              (restrictedLabPerformer && performer && performer.length > 0
                ? value?.length && value?.length > restrictedLabPerformer.maxAmount
                  ? `You have surpassed the maximum of allowed ICD-10 codes selectable with ${performer[0].display} (${restrictedLabPerformer.maxAmount})`
                  : undefined
                : undefined)
            )
          }}
          className="border-2 rounded-lg p-3 min-h-max"
        />
      </div>
      <div className="flex flex-col grow right-col border-2 rounded-lg p-3 gap-3 min-h-max">
        <ReferenceDropdownField
          field="order.performer[0]"
          placeholder="Select Laboratory"
          options={availableLabs?.map(({ ref }) => ref)}
          className="flex-1"
          showFilter={false}
          handleChange={(newPerRef) => updateLabLocation(newPerRef.id)}
          assignDefaultValue={false}
        />

        <BloodDrawField />

        <div className="border-b border-gray-300 pb-6">
          <div className="font-medium text-gray-900 text-lg mb-4">Combos</div>
          {performer?.[0]?.id && requester ? (
            <LaboratoryOrderCombos />
          ) : (
            <p className="text-slate-500 text-sm pl-2">No combos found</p>
          )}
        </div>
        <LaboratoryOrderPanels field="panels" label="Additional Tests" />
      </div>
    </div>
  )
}

type Props = {
  isEditing?: boolean
  practitioners?: PractitionerInfo[]
  labLocations: Record<string, string>
}

export { LaboratoryOrderForm }
