import { useQuery } from "@tanstack/react-query"
import { asReference, getResources, humanNameAsString, Practitioner, PractitionerRole, Reference } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { PractitionerInfo } from "commons"
import { PRACTITIONER_ROLE } from "data"
import { SYSTEM_VALUES } from "system-values"
import { isPRPractitioner } from "utils"

import { organizationQueryKeys } from "../query-keys"

const useOrganizationPractitioners = ({
  organizationId,
  filterByIdentifier,
  onlyActivePracts = true,
}: OrganizationPractitionersArgs) => {
  const { search } = useClient()
  const queryKey = organizationQueryKeys.practitioners(organizationId, onlyActivePracts)

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _include: "PractitionerRole:practitioner:Practitioner",
        ...(onlyActivePracts ? { "practitioner:Practitioner.active:not": "false" } : {}),
        "active:not": "false",
        role: PRACTITIONER_ROLE.PRACTITIONER,
      })

      const bundle = await search({ endpoint: `Organization/${organizationId}/PractitionerRole`, filters, signal })
      const practitioners = getResources<Practitioner>(bundle, "Practitioner")
      const practRoles = getResources<PractitionerRole>(bundle, "PractitionerRole")

      return { practitioners, practRoles, total: bundle?.total ?? 0 }
    },
    enabled: !!organizationId,
    meta: { context: { queryKey, organizationId } },
  })

  const { organizationPractitionersInfo, organizationPractitionerRefs } = useMemo(() => {
    const { organizationPractitionersInfo, organizationPractitionerRefs } =
      data?.practitioners.reduce<Reducer>(
        (prev, practitioner) => {
          const practitionerRoles = data?.practRoles.filter((pr) => pr.practitioner?.id === practitioner?.id)

          const hasDosespotSetup =
            practitioner.identifier?.some(
              (identifier) => identifier.system === SYSTEM_VALUES.PRACTITIONER_DS_IDENTIFIER,
            ) ?? false

          const practitionerRole = practitionerRoles.find(isPRPractitioner)
          const practitionerRoleRef: Reference | undefined = practitionerRole
            ? {
                ...asReference(practitionerRole),
                display: humanNameAsString(practitioner.name?.[0], "no name"),
              }
            : undefined

          return filterByIdentifier && !practitioner.identifier?.find((p) => p.value?.includes(filterByIdentifier))
            ? prev
            : {
                organizationPractitionersInfo: [
                  ...prev.organizationPractitionersInfo,
                  {
                    practitioner,
                    practitionerRole,
                    practitionerRoleRef,
                    practitionerRoles: practitionerRoles,
                    hasDosespotSetup,
                  },
                ],
                organizationPractitionerRefs: practitionerRole
                  ? [...prev.organizationPractitionerRefs, asReference(practitioner)]
                  : prev.organizationPractitionerRefs,
              }
        },
        { organizationPractitionersInfo: [], organizationPractitionerRefs: [] },
      ) ?? {}

    return { organizationPractitionersInfo, organizationPractitionerRefs }
  }, [data?.practRoles, data?.practitioners, filterByIdentifier])

  return {
    organizationPractitionersInfo: organizationPractitionersInfo ?? [],
    organizationPractitionerRefs: organizationPractitionerRefs ?? [],
    total: data?.total,
    isLoading,
    refetch,
  }
}

interface OrganizationPractitionersArgs {
  organizationId: string
  filterByIdentifier?: string
  onlyActivePracts?: boolean
}

type Reducer = {
  organizationPractitionersInfo: PractitionerInfo[]
  organizationPractitionerRefs: Reference[]
}

export { useOrganizationPractitioners }
