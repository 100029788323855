import { faTasks } from "@fortawesome/pro-regular-svg-icons"

import { AppModule, ModuleRenderSideType, ModulesId } from "commons/Module"

class EncounterModule extends AppModule {
  constructor(workSpaceElement: JSX.Element, kpElement: JSX.Element) {
    super()
    this.id = ModulesId.ENCOUNTER
    this.display = "Encounter"
    this.icon = faTasks
    this.hideBySetting = true
    this.kpElement = kpElement
    this.workspaceElement = workSpaceElement
    this.renderSideType = ModuleRenderSideType.DYNAMIC_WORKSPACE_WITH_KP
  }
}

export { EncounterModule }
