import { faCreditCard } from "@fortawesome/pro-regular-svg-icons"

import { SubModule, ModulesId } from "commons/Module"

import { CreditCardContainer } from "../../../account/components/CreditCardContainer"

class CreditCardsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.CREDIT_CARDS
    this.display = "Credit Cards"
    this.parent = ModulesId.PATIENT
    this.icon = faCreditCard
    this.content = <CreditCardContainer />
  }
}

export { CreditCardsSubModule }
