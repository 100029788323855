import { IconDefinition } from "@fortawesome/pro-light-svg-icons"

import { StackedListContainer } from "commons"
import { usePatientObservations } from "patient360"
import { labObservationModelBuilder } from "commons/labs"
import { useLabs } from "laboratory"

import { WrapUpSection } from "./WrapUpSection"

const IntakeLabSection = ({ patientId, icon, encounterId }: Props) => {
  const { observations, isLoading, total } = usePatientObservations({
    patientId,
    category: "laboratory",
    encounter: encounterId,
    hasQuantity: true,
    loadCount: 100,
  })

  const { labs, isLoading: isLoadingEnteredLabs, total: totalEnteredLabs } = useLabs(patientId, encounterId)

  return (
    <WrapUpSection
      subSectionTitle="Imported Labs"
      isLoading={isLoading || isLoadingEnteredLabs}
      isEmpty={!total && !totalEnteredLabs}
      icon={icon}
      emptyMesage="No labs imported during this encounter"
      className="w-3/4 self-end"
    >
      <StackedListContainer
        data={[...(labs?.flatMap(({ results }) => results) ?? []), ...observations]}
        itemModelBuilder={labObservationModelBuilder}
      />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounterId: string
  icon: IconDefinition
}

export { IntakeLabSection }
