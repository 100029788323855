import { CodeableConcept, Reference } from "fhir"
import * as Yup from "yup"

import { ConfigurationItem, ProcedureKind } from "../types"
import { getProcedureKind } from "./transformers"

const procedureValidationSchema = Yup.object().shape({
  procedure: Yup.object().shape({
    instantiatesCanonical: Yup.array().required("Type is required"),
    code: Yup.object().test(
      "test-type",
      "Type is required",
      (value) => (value as CodeableConcept)?.coding?.[0]?.code !== undefined,
    ),
    reasonCode: Yup.array(),
    performer: Yup.array().of(
      Yup.object().shape({
        actor: Yup.object().test(
          "test-actor-performer",
          "Performer is required",
          (value: Reference) => value?.id !== undefined,
        ),
      }),
    ),
  }),
  configurationItem: Yup.array()
    .of(
      Yup.object().test(
        "test-batch",
        "Configure administration sites",
        (value) => !!(value as ConfigurationItem | undefined)?.bodySite?.coding?.[0]?.code,
      ),
    )
    .test("test-meds", (value, context) => {
      const proc = context.parent.procedure
      const msg = `At least one ${getProcedureKind(proc) === ProcedureKind.massage ? "massage" : "medication"} is required`
      return !!value?.length || new Yup.ValidationError(msg, value, context.path)
    }),
})

const procedureTypeValidation = Yup.object().shape({
  procedure: Yup.object().shape({
    instantiatesCanonical: Yup.array().required("Type is required"),
  }),
})

const configItemValidationSchema = Yup.object().shape({
  configurationItem: Yup.array()
    .of(
      Yup.object().test(
        "test-batch",
        "Configure administration sites",
        (value) => !!(value as ConfigurationItem | undefined)?.bodySite?.coding?.[0]?.code,
      ),
    )
    .test("test-meds", (value, context) => {
      const proc = context.parent.procedure
      const msg = `At least one ${getProcedureKind(proc) === ProcedureKind.massage ? "massage" : "medication"} is required`
      return !!value?.length || new Yup.ValidationError(msg, value, context.path)
    }),
})

export { configItemValidationSchema, procedureTypeValidation, procedureValidationSchema }
