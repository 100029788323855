import { ModulesId, SubModule } from "commons/Module"
import { PlanDetails } from "commons/care-plans"

class PlanDetailsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.PLAN_DETAILS
    this.parent = ModulesId.PLANS
    this.content = <PlanDetails />
  }
}

export { PlanDetailsSubModule }
