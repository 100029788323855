import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Bundle, BundleEntryArray, ResourceObject } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { ProcedureData } from "commons/procedures"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { ordersQueryKeys } from "orders"
import { displayNotificationSuccess } from "utils"

import { proceduresQueryKeys } from "../query-keys"

const useDeleteProcedure = (
  onSettled?: (data: ResourceObject | undefined, error: CustomError | null, variables: ProcedureData) => void,
) => {
  const { transaction } = useClient()
  const queryClient = useQueryClient()

  const delProcedure = async ({ procedure, configurationItem }: ProcedureData) => {
    const bundle: Bundle = {
      resourceType: "Bundle",
      type: "transaction",
      entry: [],
    }

    const medData = configurationItem.reduce<Array<BundleEntryArray>>(
      (acc, { medicationRequest, medicationAdministration }) => {
        if (medicationAdministration?.id)
          acc.push({
            request: {
              method: "DELETE",
              url: `MedicationAdministration/${medicationAdministration.id}`,
            },
          })
        if (medicationRequest?.id)
          acc.push({
            request: {
              method: "DELETE",
              url: `MedicationRequest/${medicationRequest.id}`,
            },
          })
        return acc
      },
      [],
    )
    bundle.entry?.push(...medData)
    bundle.entry?.push({
      request: {
        method: "DELETE",
        url: `Procedure/${procedure.id}`,
      },
    })

    return transaction(bundle)
  }

  const { mutate: deleteProcedure, isPending } = useMutation({
    mutationFn: delProcedure,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { procedure }) => {
      await queryClient.invalidateQueries({ queryKey: proceduresQueryKeys.all })
      queryClient.refetchQueries({
        queryKey: ordersQueryKeys.count.withPatientId(procedure.subject.id as string),
        type: procedure.subject.id ? "all" : undefined,
      })
      displayNotificationSuccess("Procedure discarded successfully!")
      // datadogLogs.logger.info("Procedure discarded successfully!", { procedure })
    },
    onSettled,
  })

  return { deleteProcedure, isDeleting: isPending }
}

export { useDeleteProcedure }
