import { faDiagnoses, faFileImport } from "@fortawesome/pro-regular-svg-icons"
import { Bundle, BundleEntryArray, Condition, codeableConceptAsString } from "fhir"
import { Column } from "primereact/column"
import { DataTableSelectionMultipleChangeEvent } from "primereact/datatable"
import { FC, useEffect, useId, useMemo, useState } from "react"

import { EmptyMessage, FooterActions, InfiniteScroll, SkeletonColumn, SkeletonLoader } from "commons"
import { usePatientContext } from "patients"

import { usePatientConditions, usePreviewPatientInfo, useTransferPatientInfo } from "../hooks"
import { ObservationsTable } from "./ObservationsTable"
import { Patient360ImportDataDialog } from "./Patient360ImportDataDialog"

const Patient360Conditions: FC = () => {
  const loaderKey = useId()
  const { patientId, patient } = usePatientContext()
  const { conditionsEntries, isLoading, hasNextPage, fetchNextPage, reloadObservations } =
    usePatientConditions(patientId)
  const [showPreviewDialog, setShowPreviewDialog] = useState(false)
  const [previewConditionsBundle, setPreviewConditionsBundle] = useState<Bundle>()
  const previewConditionsEntries = useMemo(() => previewConditionsBundle?.entry ?? [], [previewConditionsBundle])
  const hasPreviewObservations = previewConditionsEntries.length > 0

  const [selectedEntries, setSelectedEntries] = useState<BundleEntryArray[]>([])

  const handleSelectionChange = (e: DataTableSelectionMultipleChangeEvent<BundleEntryArray[]>) => {
    const observationsEntries: BundleEntryArray[] = e.value
    setSelectedEntries(observationsEntries)
  }

  const { previewPatientInfo: previewConditions, isLoadingPreview } = usePreviewPatientInfo("conditions", (bundle) => {
    setPreviewConditionsBundle(bundle)
    setShowPreviewDialog(true)
  })
  const { transferPatient, isTransfering } = useTransferPatientInfo(() => {
    reloadObservations?.()
    setShowPreviewDialog(false)
  })

  const handlePreview = () => {
    previewConditions({ patId: patientId })
  }

  const handleTransfer = () => {
    transferPatient({ ...(previewConditionsBundle as Bundle), entry: selectedEntries })
  }

  const handleClosePreviewDialog = () => {
    setShowPreviewDialog(false)
  }

  useEffect(() => {
    setSelectedEntries(previewConditionsEntries)
  }, [previewConditionsEntries])

  const columnsDef: SkeletonColumn[] = [
    { header: "Code", headerStyle: { minWidth: "5rem" } },
    { header: "Status", headerStyle: { minWidth: "8rem" } },
  ]

  const hasUsableAddress = useMemo(() => {
    return patient.address?.some(({ use }) => !!use) ?? false
  }, [patient.address])
  if (isLoading) {
    return <SkeletonLoader repeats={4} containerClassName="pl-5 py-3" loaderType="table" columns={columnsDef} />
  }

  const loader = () => (
    <SkeletonLoader
      key={loaderKey}
      repeats={4}
      containerClassName="pl-5"
      loaderType="table"
      columns={columnsDef.map(({ headerStyle, body }) => ({
        header: undefined,
        headerStyle: headerStyle,
        headerClass: "hidden",
        body: body,
      }))}
    />
  )
  const valueBodyTemplate = ({ clinicalStatus }: Condition) => {
    return codeableConceptAsString(clinicalStatus)
  }

  const addOptions = [
    {
      icon: faFileImport,
      label: "Import conditions",
      command: () => handlePreview(),
      loading: isLoadingPreview,
      disabled: !hasUsableAddress,
    },
  ]

  return (
    <>
      {!conditionsEntries?.length ? (
        <div className="h-full w-full flex items-center justify-center">
          <EmptyMessage
            icon={faDiagnoses}
            message="No conditions found"
            subMessage={
              !hasUsableAddress
                ? "Get started by linking addresses to search"
                : "Get started by importing patient conditions"
            }
            action={handlePreview}
            actionText="Import conditions"
            actionIcon={faFileImport}
            isLoading={isLoadingPreview}
            disabled={!hasUsableAddress}
          />
        </div>
      ) : (
        <>
          <div className="h-full overflow-auto">
            <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
              <ObservationsTable
                tableDataKey="resource.identifier.0.value"
                observationsEntries={conditionsEntries ?? []}
                onSelectionChange={handleSelectionChange}
              >
                <Column
                  field="resource.code"
                  header="Code"
                  body={({ resource: { code } }) => (
                    <p title="Code" className="font-bold mr-2">
                      {codeableConceptAsString(code)}
                    </p>
                  )}
                  headerStyle={{ minWidth: "8rem" }}
                />
                <Column
                  field="resource.clinicalStatus"
                  header="Status"
                  body={(rowData) => valueBodyTemplate(rowData.resource)}
                  headerStyle={{ minWidth: "8rem" }}
                />
              </ObservationsTable>
            </InfiniteScroll>
          </div>
          {!showPreviewDialog && <FooterActions actions={addOptions} />}
        </>
      )}

      <Patient360ImportDataDialog
        handleCloseDialog={handleClosePreviewDialog}
        header="Conditions Preview"
        handleDialogAction={handleTransfer}
        showDialog={showPreviewDialog}
        actionDisabled={isTransfering || !hasPreviewObservations}
        isLoading={isTransfering}
        totalRecords={previewConditionsEntries.length}
      >
        <ObservationsTable
          observationsEntries={previewConditionsEntries}
          selectedEntries={selectedEntries}
          onSelectionChange={handleSelectionChange}
          tableDataKey="resource.identifier.0.value"
        >
          <Column
            field="resource.code"
            header="Code"
            body={({ resource: { code } }) => (
              <p title="Code" className="font-bold mr-2">
                {codeableConceptAsString(code)}
              </p>
            )}
            headerStyle={{ minWidth: "8rem" }}
          />
          <Column
            field="resource.clinicalStatus"
            header="Status"
            body={(rowData) => valueBodyTemplate(rowData.resource)}
            headerStyle={{ minWidth: "8rem" }}
          />
        </ObservationsTable>
      </Patient360ImportDataDialog>
    </>
  )
}

export { Patient360Conditions }
