import { faAllergies } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { Patient360Allergies } from "../../../patient360/components/Patient360Allergies"

class Allergies360SubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.ALLERGIES_360
    this.display = "Allergies"
    this.parent = ModulesId.PATIENT_360
    this.icon = faAllergies
    this.content = <Patient360Allergies />
  }
}

export { Allergies360SubModule }
