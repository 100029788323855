import { useFormikContext } from "formik"
import { useCallback, useEffect, useMemo, useState } from "react"

import { SkeletonLoader } from "commons"
import { LaboratoryOrder, LaboratoryOrderPanel } from "commons/labs"
import { LaboratoryComboTest } from "commons/types"
import { BILLING_TYPES_CODES } from "data"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { useLaboratoryOrderCombos } from "../hooks"
import ComboItem from "./ComboItem"
import { RemovePanelsDialog } from "./RemovePanelsDialog"
import { getSrData } from "./validations"

const LaboratoryOrderCombos = () => {
  const [panelsToRemove, setPanelsToRemove] = useState<LaboratoryOrderPanel[]>([])

  const { currentOrganizationId, isExemptLabPayment } = useOrganizationContext()
  const {
    patient: { gender },
  } = usePatientContext()

  const {
    values: {
      order: { performer, subject, requester },
      panels,
      billingType,
      combo,
      deletedPanels,
    },
    setFieldValue,
  } = useFormikContext<LaboratoryOrder>()

  const { laboratoryCombos, isLoading } = useLaboratoryOrderCombos(
    currentOrganizationId,
    performer?.[0].id as string,
    gender?.includes("male") ? gender : undefined,
    billingType as BILLING_TYPES_CODES,
  )

  const activeComboTest = useMemo(
    () =>
      laboratoryCombos.find(
        ({ combo: c }) => `${c.url}|${c.version}` === combo?.laboratoryCombo?.instantiatesCanonical?.[0],
      ),
    [laboratoryCombos, combo],
  )

  const selectCombo = (labComboTest: LaboratoryComboTest) => {
    if (
      combo?.laboratoryCombo.instantiatesCanonical?.[0] === `${labComboTest.combo.url}|${labComboTest.combo.version}`
    ) {
      setFieldValue("combo", undefined)
      if (combo.laboratoryCombo.id) {
        setFieldValue("deletedPanels", [...(deletedPanels ?? []), ...combo.panels.filter((p) => p.profile.id)])
        setFieldValue("deletedComboId", combo.laboratoryCombo.id)
      }
    } else {
      const panelsToRemove = labComboTest.laboratoryTests.reduce((acc, test) => {
        const skuIdentifier = test.planDefinition?.identifier?.find((id) => id.system?.includes("sku"))
        const includedPanel = panels.find((panel) => {
          const skuCode = panel.profile?.code?.coding?.find((p) => p.system?.includes("sku"))
          return skuIdentifier?.value === skuCode?.code
        })
        if (includedPanel) return [...acc, includedPanel]
        return acc
      }, new Array<LaboratoryOrderPanel>())

      if (panelsToRemove.length) {
        setPanelsToRemove(panelsToRemove)
      } else {
        setComboData(labComboTest)
      }
    }
  }

  const removeDuplicatedPanels = useCallback(() => {
    const remainingPanels = panels.flatMap((panel) =>
      panelsToRemove.find((p) => p.profile.instantiatesCanonical?.[0] === panel.profile.instantiatesCanonical?.[0])
        ? []
        : panel,
    )

    setFieldValue("panels", remainingPanels)
    setFieldValue("deletedPanels", [
      ...(deletedPanels ?? []),
      ...panelsToRemove.filter((p) => p.profile.id !== undefined),
    ])
    !!activeComboTest && setComboData(activeComboTest)
    setPanelsToRemove([])
  }, [activeComboTest])

  const setComboData = (labComboTest: LaboratoryComboTest) => {
    const srCombo = getSrData({
      patient: subject,
      performer: performer ?? [],
      planDefinition: labComboTest.combo,
      practitionerRole: requester,
      isCombo: true,
    })

    const comboPanels = labComboTest.laboratoryTests.map((test) => ({
      profile: getSrData({
        patient: subject,
        performer: performer ?? [],
        planDefinition: test.planDefinition,
        practitionerRole: requester,
      }),
      price: test.price,
      questionnaires: test.questionnaires,
    }))

    setFieldValue("combo", { laboratoryCombo: srCombo, panels: comboPanels, price: labComboTest.price })
  }

  const isInsurance = billingType === BILLING_TYPES_CODES.INSURANCE

  useEffect(() => {
    if (!isExemptLabPayment && activeComboTest && activeComboTest.price !== combo?.price) {
      setComboData(activeComboTest)
    }
  }, [activeComboTest?.price, combo?.price, isExemptLabPayment])

  return (
    <>
      <>
        <div className="flex flex-col gap-4">
          {isLoading ? (
            <SkeletonLoader key={"combos"} repeats={2} loaderType="two-lines" />
          ) : laboratoryCombos?.length ? (
            laboratoryCombos.map((labComboTest) => (
              <ComboItem
                key={labComboTest.combo.id}
                labCombo={labComboTest}
                selectCombo={selectCombo}
                selected={
                  combo?.laboratoryCombo?.instantiatesCanonical?.[0] ===
                  `${labComboTest.combo.url}|${labComboTest.combo.version}`
                }
                showPrice={!isExemptLabPayment}
                isInsurance={isInsurance}
              />
            ))
          ) : (
            <p className="text-slate-500 text-sm pl-2">No combos found</p>
          )}
          {!!panelsToRemove?.length && (
            <RemovePanelsDialog
              panels={panelsToRemove}
              onCancel={() => setPanelsToRemove([])}
              onRemove={removeDuplicatedPanels}
            />
          )}
        </div>
      </>
    </>
  )
}

export { LaboratoryOrderCombos }
