import { faClipboardListCheck } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, AppModule } from "commons/Module"

class SurveyModule extends AppModule {
  constructor(workspaceElement: JSX.Element, kpElement: JSX.Element) {
    super()
    this.id = ModulesId.SURVEY
    this.display = "Questionnaires"
    this.hideBySetting = true
    this.icon = faClipboardListCheck
    this.kpElement = kpElement
    this.workspaceElement = workspaceElement
  }
}

export { SurveyModule }
