import { faExternalLink } from "@fortawesome/pro-light-svg-icons"
import { faCalculator, faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Procedure } from "fhir"
import { useMountEffect } from "primereact/hooks"
import { Tooltip } from "primereact/tooltip"
import { useEffect, useId, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import {
  Button,
  ConfirmDialog,
  EmptyMessage,
  FooterActions,
  InfiniteScroll,
  ModulesId,
  SkeletonLoader,
  StackedListContainer,
  useCrudReducer,
  useDefaultOrgData,
} from "commons"
import { getInitialValues, ProcedureData, ProcedureFormContainer, procedureStatus } from "commons/procedures"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { usePatientContext } from "patients"
import { getPatientDefaultPractitioner } from "utils"

import { useDeleteProcedure, usePatientProcedures } from "../hooks"
import { proceduresItemModel } from "./procedureModelBuilder"
import { ProcedureNotesContainer } from "./ProcedureNotesContainer"

const ProceduresContainer = () => {
  const loaderKey = useId()
  const { patientId, patient } = usePatientContext()
  const { currentOrganizationId, loggedInPractitionerRole, isAdmin } = useOrganizationContext()
  const { appModules, appSubModules } = useAppModuleContext()
  const { organizationPractitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })
  const {
    defaultData: { defaultProcedureIcd10 },
  } = useDefaultOrgData(currentOrganizationId)
  const { openEncounterRef } = useOpenEncounter(patientId)
  const defaultRequester = getPatientDefaultPractitioner(
    organizationPractitionersInfo,
    patient,
    isAdmin ? {} : loggedInPractitionerRole,
  )

  const {
    searchData,
    activeSubModule,
    hasGP,
    setSearchData,
    showModule,
    showSubModule,
    setActiveSubModule,
    currentModuleData,
  } = useChartContext<ProcedureData>()
  const [searchParams, setSearchParams] = useSearchParams()
  const planId = searchParams.get("planId")
  const procedureId = searchParams.get("procedureId")

  const {
    initialValue,
    showSlide: showForm,
    isNew,
    removeItem,
    state: { calcProcedure },
    hardReset: reset,
    add,
    edit,
    remove,
    dispatch,
  } = useCrudReducer({
    defaultEntity:
      currentModuleData?.form?.initialState ??
      getInitialValues(patient, defaultProcedureIcd10[patient.gender ?? ""], defaultRequester, openEncounterRef),
    extraState: { calcProcedure: false },
    extraStateReducer: (state, { type, payload }) =>
      type === "calcProc" ? { ...state, calcProcedure: payload as boolean } : state,
  })

  const [procedureNoteEdit, handleEditNote] = useState<Procedure>()

  const showCalc = (showCalc: boolean) => {
    dispatch({ type: "calcProc", payload: showCalc })
    add()
  }

  useMountEffect(() => {
    if (!procedureId) {
      setSearchData({
        placeholder: "Search procedures",
        selectedStatus: ["preparation", "in-progress", "completed"],
        statusOptions: procedureStatus,
      })
    }
  })

  const { proceduresData, isLoading, hasNextPage, fetchNextPage, count } = usePatientProcedures({
    patientId,
    textFilter: searchData.filter,
    statusFilter: searchData.selectedStatus,
  })

  const { deleteProcedure, isDeleting } = useDeleteProcedure(() => remove(undefined))

  const addOptions = useMemo(
    () => [
      {
        label: "Create new procedure",
        icon: faPlus,
        style: "default",
        command: () => showCalc(false),
      },
      {
        label: "Calculate new procedure",
        disabled: !patient.gender,
        icon: faCalculator,
        command: () => showCalc(true),
      },
    ],
    [patient.gender, showCalc],
  )

  const onCancel = () => {
    reset()
    searchParams.delete("planId")
    setSearchParams(searchParams)
  }

  const gotoPI = () => showModule({ module: ModulesId.PATIENT })

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const showDetails = (procedureId: string) => {
    showSubModule({ subModule: appSubModules.procedures[ModulesId.PROCEDURES_DETAILS], subModulesData: procedureId })
    setActiveSubModule(appSubModules.procedures[ModulesId.PROCEDURES_DETAILS])
  }

  useEffect(() => {
    const procedureId = searchParams.get("procedureId")
    if (procedureId) setActiveSubModule(appSubModules.procedures[ModulesId.PROCEDURES_DETAILS])
    else setActiveSubModule(null)
  }, [searchParams, appSubModules])

  if (activeSubModule) return activeSubModule.renderContent()

  if (procedureNoteEdit)
    return <ProcedureNotesContainer procedure={procedureNoteEdit} handleClose={() => handleEditNote(undefined)} />

  return planId || showForm ? (
    <ProcedureFormContainer
      isEditing={!isNew}
      practitionersInfo={organizationPractitionersInfo}
      defaultRequester={defaultRequester}
      calcProcedure={(calcProcedure || !!planId) && !currentModuleData}
      carePlanId={planId}
      onCancel={onCancel}
      initialValues={initialValue}
    />
  ) : isLoading ? (
    loader()
  ) : !count ? (
    <EmptyMessage
      icon={appModules[ModulesId.PROCEDURES].getIcon()}
      itemTitle="Procedure"
      subMessage={hasGP && "Get started by creating a new procedure"}
      customEmptyAddButton={
        <div className="flex items-baseline gap-4">
          <div className="flex flex-col gap-2">
            <div id="emptyCalculateProcedure">
              <Button
                label="Calculate procedure"
                onClick={() => showCalc(true)}
                icon={faCalculator}
                disabled={!patient.gender || !hasGP}
              />
            </div>
            {!patient.gender && (
              <span
                className="space-x-1 text-xs text-primary hover:text-primary-hover font-medium cursor-pointer"
                onClick={gotoPI}
              >
                <span>Go to change gender</span>
                <FontAwesomeIcon icon={faExternalLink} />
              </span>
            )}
          </div>
          <Button
            label="Create new procedure"
            buttonStyle="default"
            disabled={!hasGP}
            onClick={() => showCalc(false)}
            icon={faPlus}
          />
          {!patient.gender && (
            <Tooltip target="#emptyCalculateProcedure" content="Patient gender is unspecified" position="top" />
          )}
        </div>
      }
    />
  ) : (
    <>
      <div className="h-full overflow-auto">
        <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
          <StackedListContainer
            data={proceduresData}
            itemModelBuilder={(item) =>
              proceduresItemModel(
                item,
                () => showDetails(item.procedure.id as string),
                () => edit(item),
                () => remove(item),
                () => handleEditNote(item.procedure),
              )
            }
          />
        </InfiniteScroll>
      </div>
      <FooterActions actions={addOptions} />
      <ConfirmDialog
        confirmText="Are you sure you want to remove this procedure"
        actionName="Remove"
        visible={removeItem !== undefined || isDeleting}
        isLoading={isDeleting}
        onConfirm={() => deleteProcedure(removeItem as ProcedureData)}
        hideDialog={() => remove(undefined)}
      />
    </>
  )
}

export { ProceduresContainer }
