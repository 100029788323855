import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { faCheckDouble, faCircleInfo, faLocationDot, faPhone } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TabPanel } from "primereact/tabview"
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { ModulesId, VerticalTabView } from "commons"
import { useAppModuleContext } from "internals"
import { useOrganizationContext } from "organization"

import { usePatientContext } from "../hooks"
import { PatientAddresses } from "./PatientAddresses"
import { BasicInformationForm } from "./form/BasicInformationForm"
import { StatusForm } from "./form/StatusForm"
import { TelecomForm } from "./form/TelecomForm"

const PatientInformation = () => {
  const { patient } = usePatientContext()
  const { currentOrganizationId, enabledConsents } = useOrganizationContext()
  const { appSubModules } = useAppModuleContext()
  const [params, setParams] = useSearchParams()
  const [selectedView, setSelectedView] = useState(0)

  const views = [
    "basic-info",
    "address",
    "telecom",
    "status",
    "contact",
    "insurance",
    "consent",
    "credit-card",
    "documents",
  ]

  useEffect(() => {
    const subView = params.get("subview")
    const viewIndex = views.findIndex((view) => view === subView)
    setSelectedView(viewIndex !== -1 ? viewIndex : 0)
  }, [params])

  const handlePanelChange = (index: number) => {
    params.set("subview", views[index] ?? "basic-info")
    setParams(params)
  }

  return (
    <VerticalTabView selectedPanel={selectedView} panelChange={handlePanelChange}>
      <TabPanel headerTemplate={<HeaderTemplate title="Basic information" icon={faCircleInfo} />}>
        <BasicInformationForm patient={patient} currentOrganizationId={currentOrganizationId} />
      </TabPanel>
      <TabPanel headerTemplate={<HeaderTemplate title="Address" icon={faLocationDot} />}>
        <PatientAddresses />
      </TabPanel>
      <TabPanel headerTemplate={<HeaderTemplate title="Telecom" icon={faPhone} />}>
        <TelecomForm patient={patient} />
      </TabPanel>
      <TabPanel headerTemplate={<HeaderTemplate title="Status" icon={faCheckDouble} />}>
        <StatusForm patient={patient} />
      </TabPanel>
      <TabPanel
        headerTemplate={
          <HeaderTemplate
            title={appSubModules["patient"][ModulesId.PATIENT_CONTACTS].getDisplay()}
            icon={appSubModules["patient"][ModulesId.PATIENT_CONTACTS].getIcon()}
          />
        }
      >
        <span className="pl-2">{appSubModules["patient"][ModulesId.PATIENT_CONTACTS].getDisplay()}</span>
        {appSubModules["patient"][ModulesId.PATIENT_CONTACTS].renderContent()}
      </TabPanel>
      <TabPanel
        headerTemplate={
          <HeaderTemplate
            title={appSubModules["patient"][ModulesId.PATIENT_COVERAGES].getDisplay()}
            icon={appSubModules["patient"][ModulesId.PATIENT_COVERAGES].getIcon()}
          />
        }
      >
        <span className="pl-2">{appSubModules["patient"][ModulesId.PATIENT_COVERAGES].getDisplay()}</span>
        {appSubModules["patient"][ModulesId.PATIENT_COVERAGES].renderContent()}
      </TabPanel>
      <TabPanel
        visible={!!enabledConsents.length}
        headerTemplate={
          <HeaderTemplate
            title={appSubModules["patient"][ModulesId.CONSENT].getDisplay()}
            icon={appSubModules["patient"][ModulesId.CONSENT].getIcon()}
          />
        }
      >
        <span className="pl-2">{appSubModules["patient"][ModulesId.CONSENT].getDisplay()}</span>
        {appSubModules["patient"][ModulesId.CONSENT].renderContent()}
      </TabPanel>
      <TabPanel
        headerTemplate={
          <HeaderTemplate
            title={appSubModules["patient"][ModulesId.CREDIT_CARDS].getDisplay()}
            icon={appSubModules["patient"][ModulesId.CREDIT_CARDS].getIcon()}
          />
        }
      >
        <span className="pl-2">{appSubModules["patient"][ModulesId.CREDIT_CARDS].getDisplay()}</span>
        {appSubModules["patient"][ModulesId.CREDIT_CARDS].renderContent()}
      </TabPanel>
      <TabPanel
        headerTemplate={
          <HeaderTemplate
            title={appSubModules["patient"][ModulesId.PATIENT_DOCUMENTS].getDisplay()}
            icon={appSubModules["patient"][ModulesId.PATIENT_DOCUMENTS].getIcon()}
          />
        }
      >
        <span className="pl-2">{appSubModules["patient"][ModulesId.PATIENT_DOCUMENTS].getDisplay()}</span>
        {appSubModules["patient"][ModulesId.PATIENT_DOCUMENTS].renderContent()}
      </TabPanel>
    </VerticalTabView>
  )
}

const HeaderTemplate = ({ title, icon }: { title: string; icon: IconDefinition }) => {
  return (
    <div className="flex items-center">
      <FontAwesomeIcon icon={icon} />
      <span className="ml-2">{title}</span>
    </div>
  )
}

export { PatientInformation }
