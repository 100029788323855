import { useQuery } from "@tanstack/react-query"
import { Account, getResources } from "fhir"

import { ACCOUNT_TYPE, useDecryptAccount } from "account"
import { useClient } from "api"

import { organizationQueryKeys } from "../query-keys"

const useOrganizationAccount = (organizationId?: string) => {
  const { search } = useClient()
  const { decrypt } = useDecryptAccount()
  const queryKey = organizationQueryKeys.account.current(organizationId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        status: "active",
      })

      const bundle = await search({ endpoint: `Organization/${organizationId}/Account`, filters, signal })

      const organizationAccounts = getResources(bundle) as Account[]

      let decryptedAccount: Account | undefined
      /* Find CC related Account for decrypt */
      const ccHolderAccount = organizationAccounts.find(
        (account) => !account.type?.coding?.some(({ code }) => code === ACCOUNT_TYPE.CARD_READER_ACCOUNT),
      )
      if (ccHolderAccount) {
        decryptedAccount = await decrypt({ accountId: ccHolderAccount.id ?? "" })
      }

      return {
        accounts: [...organizationAccounts.filter(({ id }) => decryptedAccount?.id !== id)],
        creditCardAccount: decryptedAccount,
      }
    },
    enabled: !!organizationId,
    throwOnError: true,
    meta: { context: { queryKey, organizationId } },
  })

  return {
    organizationReaderAccounts: data?.accounts,
    organizationCCAccount: data?.creditCardAccount,
    isLoading,
  }
}

export { useOrganizationAccount }
