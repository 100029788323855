import { format, parseISO } from "date-fns"
import { FC, useMemo } from "react"

import { BILLING_TYPES_CODES, billingTypes, formatsByTypes } from "data"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { getMoneyCurrencyAlt } from "utils"

import { SkeletonLoader } from "../../../components/SkeletonLoader"
import { LaboratoryOrderResults, getStatus, useLaboratoryOrder } from "../../../labs"
import { getOrderDate } from "../../../meds"

const CPLabResultsDetails: FC<Props> = ({ patientId, labOrderId }) => {
  const { currentOrganizationId, isExemptLabPayment, isAdmin } = useOrganizationContext()
  const { organizationPractitionersInfo, isLoading } = useOrganizationPractitioners({
    organizationId: currentOrganizationId,
  })

  const { laboratoryOrder, isLoading: isLoadingLabResults } = useLaboratoryOrder(
    currentOrganizationId,
    labOrderId as string,
    patientId,
    organizationPractitionersInfo,
    isAdmin,
  )

  const isInsuranceLab = useMemo(
    () => laboratoryOrder.billingType === BILLING_TYPES_CODES.INSURANCE,
    [laboratoryOrder.billingType],
  )
  const labOrderStatus = getStatus(laboratoryOrder.order)
  const isDraftOrder = labOrderStatus?.code === "draft"

  return (
    <div className="p-3">
      {isLoading || isLoadingLabResults ? (
        <SkeletonLoader loaderType="min-card" repeats={1} />
      ) : (
        <div className="flex flex-col gap-6">
          <div className="grid grid-cols-2 text-sm gap-3 gap-x-5">
            <div className="flex gap-1">
              <label className="text-gray-900">Requester:</label>
              <span className="text-gray-400">{laboratoryOrder.requester}</span>
            </div>
            <div className="flex gap-1">
              <label className="text-gray-900">Date:</label>
              <span className="text-gray-400">{getOrderDate(laboratoryOrder.order)}</span>
            </div>
            {!isDraftOrder && (
              <div className="flex gap-1">
                <label className="text-gray-900">Lab Reference ID:</label>
                <span className="text-gray-400">{laboratoryOrder.orderIdentifier}</span>
              </div>
            )}
            <div className="flex gap-1">
              <label className="text-gray-900">Status:</label>
              <span className="text-gray-400 capitalize">
                {labOrderStatus?.code === "revoked" ? "cancelled" : labOrderStatus?.display ?? "unspecified"}
              </span>
            </div>
            <div className="flex gap-1">
              <label className="text-gray-900">Billing type:</label>
              <span className="text-gray-400">
                {billingTypes.find(({ code }) => code === laboratoryOrder.billingType)?.display}
              </span>
            </div>
            {!isExemptLabPayment && !isInsuranceLab && (
              <div className="flex gap-1">
                <label className="text-gray-900">Price:</label>
                <span className="text-gray-400">{`${getMoneyCurrencyAlt(
                  laboratoryOrder.price?.currency,
                )}${laboratoryOrder.price?.value?.toFixed(2)}`}</span>
              </div>
            )}
            {laboratoryOrder.specimenDate && (
              <div className="flex gap-1">
                <label className="text-gray-900">Blood drawn in the office on:</label>
                <span className="text-gray-400">
                  {format(parseISO(laboratoryOrder.specimenDate), formatsByTypes.LONG_DATETIME_WITH_TIMEZONE)}
                </span>
              </div>
            )}
            {laboratoryOrder.order?.insurance?.[0]?.display && (
              <div className="flex gap-1">
                <label className="text-gray-900">Insurance:</label>
                <span className="text-gray-400">{laboratoryOrder.order.insurance[0].display}</span>
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <h3 className="text-gray-500 font-normal text-sm pb-2 mb-2 border-b">Results</h3>
            <LaboratoryOrderResults
              panels={laboratoryOrder.panels}
              combo={laboratoryOrder.combo}
              previousResults={laboratoryOrder.previousResults}
              isOrgExemptLabPayment={isExemptLabPayment}
              isInsuranceLab={laboratoryOrder.billingType === BILLING_TYPES_CODES.INSURANCE}
              orderId={laboratoryOrder.order?.id as string}
            />
          </div>
        </div>
      )}
    </div>
  )
}

type Props = { patientId: string; labOrderId?: string }

export { CPLabResultsDetails }
