import { faCalendarDays, faDoNotEnter, faPencil } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { codeableConceptAsString, Condition } from "fhir"

import { StackedListItemProps } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

const conditionModelBuilder = (condition: Condition, edit?: () => void, remove?: () => void): StackedListItemProps => {
  const status = condition.clinicalStatus && codeableConceptAsString(condition.clinicalStatus).toLowerCase()
  return {
    leftData: [
      {
        lineItems: [{ name: "Code", value: codeableConceptAsString(condition.code) }],
      },
      {
        lineItems: [
          ...(condition.recordedDate
            ? [
                {
                  name: "Recorded date",
                  value: formatDate(new Date(condition.recordedDate), formatsByTypes.LONG_DATETIME),
                  icon: faCalendarDays,
                },
              ]
            : []),
        ],
      },
    ],
    badge: status
      ? {
          text: status,
          colorStyle: ["active", "recurrence"].includes(status)
            ? "red"
            : ["relapse", "remission"].includes(status)
              ? "yellow"
              : status === "resolved"
                ? "green"
                : "gray",
        }
      : undefined,
    menu: [
      ...(edit
        ? [
            {
              label: "Edit",
              icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
              command: edit,
            },
          ]
        : []),
      ...(remove
        ? [
            {
              label: "Deactivate",
              icon: <FontAwesomeIcon icon={faDoNotEnter} size="sm" className="mr-2" />,
              command: remove,
            },
          ]
        : []),
    ],
  }
}

export { conditionModelBuilder }
