import { faHouseCrack } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { CoveragesContainer } from "../../../coverage/components/CoveragesContainer"

class CoverageSubmodule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.PATIENT_COVERAGES
    this.display = "Insurance"
    this.parent = ModulesId.PATIENT
    this.icon = faHouseCrack
    this.content = <CoveragesContainer />
  }
}

export { CoverageSubmodule }
