import { sub } from "date-fns"
import { Practitioner } from "fhir"
import * as Yup from "yup"

import { ContactPointSystem, emptyAddress } from "data"
import { getAddressSchema, humanNameSchema, telecomSchema } from "utils"

const INITIAL_VALUES: Partial<Practitioner> = {
  name: [{ use: "official", given: ["", ""], family: "", suffix: [""], prefix: [""] }],
  active: true,
  gender: "",
  birthDate: "",
  telecom: [
    { system: ContactPointSystem.email, use: "home", value: "" },
    { system: ContactPointSystem.phone, use: "home", value: "" },
  ],
  address: [emptyAddress],
}

const initialValues = (practitioner: Partial<Practitioner> = INITIAL_VALUES) => {
  const [name = { use: "official", given: ["", ""], family: "", suffix: [""], prefix: [""] }] = practitioner.name ?? []
  return {
    ...INITIAL_VALUES,
    ...practitioner,
    name: [name],
  } as Practitioner
}

const sanitize = ({ ...practitioner }: Practitioner) => {
  if (!practitioner.photo?.length) {
    delete practitioner.photo
  }

  if (practitioner.name?.[0].given) {
    practitioner.name[0].given = practitioner.name[0].given.filter((value) => value && value !== "")
  }

  if (practitioner.name?.[0].suffix) {
    practitioner.name[0].suffix = practitioner.name[0].suffix.filter((value) => value && value !== "")
  }

  if (practitioner.name?.[0].prefix) {
    practitioner.name[0].prefix = practitioner.name[0].prefix.filter((value) => value && value !== "")
  }

  return practitioner
}
const minDateTime = sub(new Date(), {
  minutes: 1,
})

const practitionerValidationSchema = Yup.object().shape({
  birthDate: Yup.date()
    .max(minDateTime, "Practitioner birthdate should be before now")
    .required("Birthdate is required"),
  gender: Yup.string().oneOf(["male", "female"], "Invalid value").required("Biological Sex is required"),
  name: Yup.array(humanNameSchema()).min(1, "At least one name is required"),
  telecom: Yup.array(telecomSchema()),
  address: Yup.array(getAddressSchema("address[0]")),
})

const identifierInitialValues = {
  value: "",
  system: "",
}

export {
  humanNameSchema,
  identifierInitialValues,
  initialValues,
  practitionerValidationSchema,
  sanitize,
  telecomSchema,
}
