import { faHeartPulse } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, AppModule, ModuleRenderSideType } from "commons/Module"

class VitalsModule extends AppModule {
  constructor(kpElement: JSX.Element) {
    super()
    this.id = ModulesId.VITALS
    this.display = "Vitals"
    this.parent = ModulesId.INTAKE
    this.hideBySetting = true
    this.icon = faHeartPulse
    this.kpElement = kpElement
    this.renderSideType = ModuleRenderSideType.KP
  }
}

export { VitalsModule }
