import { SubModule, ModulesId } from "commons/Module"

import { MedicationOrderDetails } from "../../../medication-requests/components/MedicationOrderDetails"

class MedicationOrderDetailsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.MEDICATIONR
    this.parent = ModulesId.MEDICATION_ORDER_DETAILS
    this.display = "Order Details"
    this.content = <MedicationOrderDetails />
  }
}

export { MedicationOrderDetailsSubModule }
