import { faMedkit } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, ModuleRenderSideType, AppModule } from "commons/Module"

class IntakeModule extends AppModule {
  constructor(workspaceElement: JSX.Element) {
    super()
    this.id = ModulesId.INTAKE
    this.display = "Intake"
    this.icon = faMedkit
    this.hideBySetting = true
    this.workspaceElement = workspaceElement
    this.renderSideType = ModuleRenderSideType.WORKSPACE
  }
}

export { IntakeModule }
