import { KnowledgeListContainer, ModulesId } from "commons"
import { usePatientContext } from "patients"
import { useAppModuleContext } from "internals"

import { useAllergies } from "../hooks"
import { allergyModelBuilder } from "../components/allergyModelBuilder"

const AllergiesKnowledge = () => {
  const { appModules } = useAppModuleContext()
  const { patientId } = usePatientContext()
  const { allergies, isLoading } = useAllergies(patientId, "KP")

  return (
    <KnowledgeListContainer
      isLoading={isLoading}
      dataTitle="allergy"
      data={allergies}
      iconDataNotFound={appModules[ModulesId.ALLERGY].getIcon()}
      itemModelBuilder={(item) => allergyModelBuilder(item)}
    />
  )
}

export { AllergiesKnowledge }
