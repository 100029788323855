import { CarePlan, Condition } from "fhir"

import { ConditionForm, conditionModelBuilder, conditionValidationSchema, getInitialValues, sanitize } from "conditions"
import { useOpenEncounter } from "encounter"
import { usePatientContext } from "patients"

import { AddFieldArrayItemButton } from "../../../../components/Buttons"
import { SkeletonLoader } from "../../../../components/SkeletonLoader"
import { StackedListContainer } from "../../../../components/StackedListContainer"
import { DialogFormContainer } from "../../../../forms"
import { useCrudReducer } from "../../../../hooks"
import { useAddCondition, useCPManualCondition } from "../../../hooks"

const ManualConditions = ({ carePlan }: Props) => {
  const { patientId, patientRef } = usePatientContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { showSlide, initialValue, isNew, reset, add, edit } = useCrudReducer({
    defaultEntity: getInitialValues(patientRef, openEncounterRef),
  })

  const { conditions, isLoading } = useCPManualCondition(patientId, carePlan.id as string)
  const { addCondition } = useAddCondition(reset)

  const onSubmit = (condition: Condition) =>
    addCondition({ patientId, planId: carePlan.id as string, condition: sanitize(condition) })

  const isActiveCP = carePlan.status === "active"

  return (
    (isActiveCP || !!conditions?.length) && (
      <div className="flex flex-col border-t gap-4 pt-4 first:border-0 first:pt-0">
        <span className="text-gray-900">Chief Complaints </span>
        {isLoading ? (
          <SkeletonLoader repeats={2} loaderType="two-lines" />
        ) : (
          <div className="flex flex-col flex-1 overflow-y-auto bg-white">
            {!!conditions?.length && (
              <StackedListContainer
                data={conditions}
                itemModelBuilder={(item) => conditionModelBuilder(item, isActiveCP ? () => edit(item) : undefined)}
              />
            )}
            {isActiveCP && <AddFieldArrayItemButton label="Add Chief Complaint" onClick={add} />}
          </div>
        )}
        <DialogFormContainer
          title={`${isNew ? "Add New" : "Edit"} Condition`}
          showForm={showSlide}
          onSubmit={onSubmit}
          onCancel={reset}
          initialValue={initialValue}
          validationSchema={conditionValidationSchema}
          useFormik
        >
          <ConditionForm />
        </DialogFormContainer>
      </div>
    )
  )
}

type Props = {
  carePlan: CarePlan
}

export { ManualConditions }
