import { useMutation, useQueryClient } from "@tanstack/react-query"
import { MedicationStatement } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

import { medicationStatementQueryKeys } from "../query-keys"

const useCreateMedicationStatement = (onSettled: () => void) => {
  const { create } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const newMedication = async (medication: MedicationStatement) => create("MedicationStatement", medication)

  const { mutate: createMedication, isPending: isAdding } = useMutation({
    mutationFn: newMedication,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: medicationStatementQueryKeys.list(patientId),
        refetchType: "all",
      })
      displayNotificationSuccess("Medication Statement created successfully!")
      // datadogLogs.logger.info(`Medication  Statement ${medication.id} created successfully!`, { medication })
    },
    onSettled,
  })

  return { createMedication, isAdding }
}

export { useCreateMedicationStatement }
