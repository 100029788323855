import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { useAppModuleContext } from "modules"

import { Button } from "../../../../components/Buttons"
import { ModulesId } from "../../../../Module"

const RxRecomendations = ({ planId }: Props) => {
  const { showModule } = useChartContext()
  const { isModuleActive } = useAppModuleContext()
  const [searchParams] = useSearchParams()

  const addRx = () => {
    showModule({
      module: ModulesId.EPRESCRIBE,
      moduleParams: {
        subview: ModulesId.EPRESCRIBE_DRAFT,
        ...(isModuleActive(ModulesId.INTAKE) && !["allergies", "conditions"].includes(searchParams.get("kp") as string)
          ? { kp: "allergies" }
          : {}),
      },
      keepOrigin: true,
      moduleData: planId ? { extraData: { planId } } : undefined,
    })
  }

  return (
    isModuleActive(ModulesId.EPRESCRIBE) && (
      <div className="flex flex-col gap-3 py-6 grow">
        <div className="font-medium">
          <span>Recommended Rx</span>
        </div>
        <p className="text-sm">
          If you would like to request some Rxs as part of this care plan, please click the button below and continue
          there
        </p>
        <div className="flex items-center justify-end text-sm">
          <Button buttonStyle="default" label="Create Prescription" onClick={addRx} className="w-fit" />
        </div>
      </div>
    )
  )
}

type Props = {
  planId?: string
}

export { RxRecomendations }
