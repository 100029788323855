import { useMutation } from "@tanstack/react-query"
import { Bundle, Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { usePatientContext } from "patients"

import { EditOrderData } from "../types"

const useUpdateMrOrder = () => {
  const { operationRequest } = useClient()
  const { patientId } = usePatientContext()

  const parameters = ({ orderId, medicationRequests, transient }: EditOrderData): Parameters => ({
    resourceType: "Parameters",
    parameter: [
      { name: "order", value: { Reference: { resourceType: "ServiceRequest", id: orderId } } },
      { name: "transient", value: { boolean: transient } },
      {
        name: "edited-meds",
        part: medicationRequests.map((mr) => ({
          name: "med",
          resource: mr,
        })),
      },
    ],
  })

  const update = async (data: EditOrderData) =>
    operationRequest<Bundle>({
      endpoint: `Patient/${patientId}/cpoe`,
      method: "POST",
      operation: "edit",
      parameters: parameters(data),
    })

  const { mutate: updateOrder, isPending: isUpdating } = useMutation({
    mutationFn: update,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    // onSettled: (_, error, { transient, orderId }) => {
    //   if (!error && !transient) datadogLogs.logger.info(`Order ${orderId} updated successfully!`)
    // },
  })

  return { updateOrder, isUpdating }
}

export { useUpdateMrOrder }
