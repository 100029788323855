import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { settingsQueryKeys } from "../../query-keys"
import { PDParams } from "../../types"

const useEditCombo = (onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const editComboMutation = async ({ organization, title, fee, id, gender }: PDParams) => {
    // const panelsEntry = panels.reduce((acc, ref) => {
    //   return [...acc, { item: ref }]
    // }, Array<ListEntryArray>())

    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "organization",
          value: {
            Reference: organization,
          },
        },
        ...(gender
          ? [
              {
                name: "gender",
                value: {
                  string: gender,
                },
              },
            ]
          : []),
        // {
        //   name: "panels",
        //   resource: {
        //     resourceType: "List",
        //     mode: "Working",
        //     status: "Current",
        //     entry: panelsEntry,
        //   },
        // },
        {
          name: "title",
          value: {
            string: title,
          },
        },
        {
          name: "fee",
          value: {
            decimal: fee ?? 0,
          },
        },
      ],
    }

    return operationRequest({
      endpoint: "PlanDefinition",
      method: "PATCH",
      operation: "edit-lab-pd",
      id,
      parameters,
    })
  }

  const { mutate: editCombo, isPending } = useMutation({
    mutationFn: editComboMutation,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { organization }) => {
      await queryClient.invalidateQueries({
        queryKey: settingsQueryKeys.labCombos(organization.id as string),
        refetchType: "all",
      })

      displayNotificationSuccess("Lab combo updated successfully!")
      // datadogLogs.logger.info(`Lab combo on ${organization.id} updated successfully!`)
    },
    onSettled,
  })

  return { editCombo, isUpdating: isPending }
}

export { useEditCombo }
