import { Reference, Task } from "fhir"
import { Button } from "primereact/button"
import { useId } from "react"
import { useSearchParams } from "react-router-dom"

import { InfiniteScroll, SearchWithFilters, SkeletonLoader, StackedListContainer, ValueSetIds } from "commons"
import { taskStatusCodes } from "data"
import { useValueSet } from "value-set"

import { useTasks } from "../hooks"
import { TaskEmptyState } from "./TaskEmptyState"
import { taskModelBuilder } from "./taskModelBuilder"

const TaskList = ({ organizationId, practitioners, onAdd, onEditTask }: Props) => {
  const loaderKey = useId()
  const [params, setParams] = useSearchParams()
  const { codes: taskCodes } = useValueSet({ valueSetId: ValueSetIds.TASK_CODE })

  const status = params.get("status") ?? undefined
  const code = params.get("code") ?? undefined
  const assignedTo = params.get("asignedto") ?? undefined
  const searchText = params.get("search") ?? undefined

  const { tasks, isLoading, isFetchingNextPage, count, total, hasNextPage, fetchNextPage } = useTasks(
    organizationId,
    searchText,
    code,
    assignedTo,
    status,
  )

  const setFilters = (searchText?: string, code?: string[], assignedTo?: string, status?: string[]) => {
    searchText ? params.set("search", searchText) : params.delete("search")
    code ? params.set("code", code.map((code) => code).join(",")) : params.delete("code")
    assignedTo ? params.set("asignedto", assignedTo) : params.delete("asignedto")
    status ? params.set("status", status.map((status) => status).join(",")) : params.delete("status")
    setParams(params)
  }

  const goToTaskDetails = (taskId: string) => {
    params.append("id", taskId)
    setParams(params)
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  return (
    <>
      <div className="px-6 pt-3 border-b drop-shadow">
        <h6 className="font-medium text-lg">Tasks</h6>
        <p className="text-sm text-gray-500">
          Showing {count} tasks of {total} found
        </p>

        <div className="flex py-4 w-full h-16 justify-between">
          <SearchWithFilters
            isLoading={isLoading || isFetchingNextPage}
            initialFilters={{
              code: code ? code.split(",") : undefined,
              assignedTo,
              status: status ? status.split(",") : undefined,
            }}
            filtersData={[
              {
                label: "Code",
                field: "code",
                data: taskCodes ?? [],
                type: "multiselect",
              },
              {
                label: "Assigned to",
                field: "assignedTo",
                data: practitioners ?? [],
                type: "select",
              },
              {
                label: "Status",
                field: "status",
                data: taskStatusCodes,
                type: "multiselect",
              },
            ]}
            onFilter={(filters) => setFilters(filters?.searchText, filters?.code, filters?.assignedTo, filters?.status)}
          />
          <Button label="Create New Task" className="button-primary text-xs" onClick={onAdd} />
        </div>
      </div>

      {isLoading ? (
        loader()
      ) : !tasks?.length ? (
        <TaskEmptyState onAdd={onAdd} />
      ) : (
        <div className="flex flex-col overflow-auto grow">
          <InfiniteScroll
            loadMore={() => {
              fetchNextPage?.()
            }}
            hasMore={hasNextPage}
            loader={loader()}
            initialLoad={false}
          >
            <StackedListContainer
              data={tasks}
              itemModelBuilder={(item) =>
                taskModelBuilder(
                  item,
                  () => goToTaskDetails(item.id as string),
                  () => onEditTask(item),
                )
              }
            />
          </InfiniteScroll>
        </div>
      )}
    </>
  )
}

type Props = {
  organizationId: string
  practitioners: Reference[]
  onAdd(): void
  onEditTask(task: Task): void
}

export { TaskList }
