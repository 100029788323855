import { FC, useEffect, useRef } from "react"
import { FallbackProps } from "react-error-boundary"

import { useCheckNetworkContext } from "check-network"
import { CustomError } from "commons"

import { ForbiddenErrorView } from "./ForbiddenErrorView"
import { InternalServerErrorView } from "./InternalServerErrorView"
import { NetworkError } from "./NetworkError"
import { NotFoundErrorView } from "./NotFoundErrorView"
import { UnauthorizedErrorView } from "./UnauthorizedErrorView"

const ErrorContainer: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const { isOnline } = useCheckNetworkContext()
  const isOnlineRef = useRef(isOnline)
  const code = isOnlineRef.current && isOnline ? (error as CustomError).cause?.name : "499"

  useEffect(() => {
    if (!isOnlineRef.current && isOnline) {
      resetErrorBoundary()
      window.location.reload()
    }

    isOnlineRef.current = isOnline
  }, [isOnline, resetErrorBoundary])

  switch (code) {
    case "401":
      return <UnauthorizedErrorView error={error as CustomError} />
    case "403":
      return <ForbiddenErrorView error={error as CustomError} />
    case "404":
      return <NotFoundErrorView error={error as CustomError} resetErrorBoundary={resetErrorBoundary} />
    case "499":
      return <NetworkError />
    default:
      return <InternalServerErrorView error={error as CustomError} />
  }
}

export { ErrorContainer }
