import { useQuery } from "@tanstack/react-query"
import { asReference, getResources, Organization } from "fhir"
import { useEffect } from "react"

import { useClient } from "api"
import { LaboratoryFacility } from "commons/types"
import { AN_PRACTICE_REQUIREMENTS_CODE, PRACTITIONER_AN_NON_REQUIRED_CODE } from "data"

import { organizationQueryKeys } from "../query-keys"

const useDiagnosticLaboratories = (
  currentOrganizationId: string,
  filter?: string,
  onSettled?: (data?: LaboratoryFacility[], error?: Error | null) => void,
) => {
  const { search } = useClient()
  const queryKey = organizationQueryKeys.diagnosticLabs(filter)

  const { data, isLoading, isError, error } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        ...(filter ? { _content: filter.includes(" ") ? `"${filter}"` : filter } : {}),
        _count: "100",
        _sort: "name",
        _page: "1",
        type: "facility-provider",
        active: "true",
      })

      const bundle = await search({ endpoint: "Organization", filters, signal })

      const diagnosticLabs = getResources(bundle) as Organization[]

      /* TODO: Remove filter hack when testing AM lab ends */
      return diagnosticLabs.reduce<LaboratoryFacility[]>((prev, lab) => {
        const identifiersByOrg = lab.identifier?.filter(
          ({ system }) => system === "http://chartedhealth.com/system/hack/remember/removeme",
        )

        if (!identifiersByOrg?.length || identifiersByOrg.some(({ value }) => value === currentOrganizationId)) {
          const initialValues = { isRequiredANByOrg: false, isRequiredAN: true }

          const { isRequiredANByOrg, isRequiredAN } =
            lab.type?.reduce((prev, { coding }) => {
              if (coding?.[0].code === AN_PRACTICE_REQUIREMENTS_CODE) {
                return { ...prev, isRequiredANByOrg: true }
              }

              if (coding?.[0].code === PRACTITIONER_AN_NON_REQUIRED_CODE) {
                return { ...prev, isRequiredAN: false }
              }

              return prev
            }, initialValues) ?? initialValues

          return [...prev, { lab, ref: asReference(lab), isRequiredANByOrg, isRequiredAN }]
        }

        return prev
      }, [])
    },
    meta: { context: { queryKey, filter } },
  })

  useEffect(() => {
    onSettled?.(data, error)
  }, [data, error])

  return { diagnosticLabs: data, isLoading, isError }
}

export { useDiagnosticLaboratories }
