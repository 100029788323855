import { Encounter } from "fhir"
import { FC } from "react"

import { ModulesId } from "commons"
import { useAppModuleContext } from "internals"

import { AllergiesSection } from "./AllergiesSection"
import { ConditionsSection } from "./ConditionsSection"
import { FamilyHistorySection } from "./FamilyHistorySection"
import { IntakeLabSection } from "./IntakeLabSection"
import { IntakeMedicationsSection } from "./IntakeMedicationsSection"
import { LabsSection } from "./LabsSection"
import { MedsSection } from "./MedsSection"
import { NutrasSection } from "./NutrasSection"
import { PlansSection } from "./PlansSection"
import { ProceduresSection } from "./ProceduresSection"
import { QuestionnairesSection } from "./QuestionnairesSection"
import { SocialHistorySection } from "./SocialHistorySection"
import { VitalsSection } from "./VitalsSection"
import { WrapUpSection } from "./WrapUpSection"

const EncounterWrapUp: FC<Props> = ({ encounter, patientId, organizationId, isExemptLabPayment }) => {
  const { appSubModules, appModules, isModuleActive } = useAppModuleContext()

  return (
    <div className="flex flex-col divide-y px-2">
      {isModuleActive(ModulesId.INTAKE) && (
        <WrapUpSection sectionTitle="Intake">
          <VitalsSection
            patientId={patientId}
            icon={appSubModules[ModulesId.INTAKE]["vitals"]?.getIcon()}
            encounter={encounter.id as string}
          />
          <AllergiesSection
            encounterId={encounter.id as string}
            patientId={patientId}
            icon={appModules[ModulesId.ALLERGY]?.getIcon()}
          />
          <ConditionsSection
            encounterId={encounter.id as string}
            patientId={patientId}
            icon={appModules[ModulesId.CONDITIONS]?.getIcon()}
          />
          <FamilyHistorySection
            encounterId={encounter.id as string}
            patientId={patientId}
            icon={appSubModules[ModulesId.INTAKE][ModulesId.FAMILY_HISTORY].getIcon()}
          />
          <SocialHistorySection
            patientId={patientId}
            encounterId={encounter.id as string}
            icon={appSubModules[ModulesId.INTAKE][ModulesId.SOCIAL_HISTORY].getIcon()}
          />
          <IntakeMedicationsSection
            patientId={patientId}
            encounterId={encounter.id as string}
            icon={appSubModules[ModulesId.INTAKE][ModulesId.MEDICATIONE].getIcon()}
          />
          <IntakeLabSection
            patientId={patientId}
            encounterId={encounter.id as string}
            icon={appModules[ModulesId.LABSORDER]?.getIcon()}
          />
        </WrapUpSection>
      )}
      {isModuleActive(ModulesId.LABSORDER) && (
        <LabsSection
          encounterId={encounter.id as string}
          patientId={patientId}
          organizationId={organizationId}
          isExemptLabPayment={isExemptLabPayment}
          icon={appModules[ModulesId.LABSORDER]?.getIcon()}
        />
      )}
      {isModuleActive(ModulesId.SURVEY) && (
        <QuestionnairesSection
          patientId={patientId}
          encounterId={encounter.id as string}
          icon={appModules[ModulesId.SURVEY]?.getIcon()}
        />
      )}
      {isModuleActive(ModulesId.PROCEDURES) && (
        <ProceduresSection
          encounter={encounter.id as string}
          patientId={patientId}
          icon={appModules[ModulesId.PROCEDURES]?.getIcon()}
        />
      )}
      {isModuleActive(ModulesId.EPRESCRIBE) && (
        <MedsSection
          encounterId={encounter.id as string}
          patientId={patientId}
          icon={appModules[ModulesId.EPRESCRIBE]?.getIcon()}
        />
      )}
      {isModuleActive(ModulesId.MEDICATIONR) && (
        <NutrasSection
          encounterId={encounter.id as string}
          patientId={patientId}
          organizationId={organizationId}
          icon={appModules[ModulesId.MEDICATIONR]?.getIcon()}
        />
      )}
      {isModuleActive(ModulesId.PLANS) && (
        <PlansSection
          encounter={encounter.id as string}
          patientId={patientId}
          icon={appModules[ModulesId.PLANS]?.getIcon()}
        />
      )}
    </div>
  )
}

type Props = {
  encounter: Encounter
  patientId: string
  organizationId: string
  isExemptLabPayment?: boolean
}

export { EncounterWrapUp }
