const TextEllipsis = ({ text, charNumber }: Props) => {
  return text.length <= charNumber ? <span>{text}</span> : <span title={text}>{text.substring(0, charNumber)}...</span>
}

type Props = {
  text: string
  charNumber: number
}

export { TextEllipsis }
