import { faPills, faSearch, faShoppingCart } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MedicationKnowledge, MedicationRequest, Reference } from "fhir"
import pluralize from "pluralize"
import { Button } from "primereact/button"
import { ConfirmDialog } from "primereact/confirmdialog"
import { Sidebar } from "primereact/sidebar"
import { classNames } from "primereact/utils"
import { useEffect, useReducer, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { FloatingButton, MedicationKnowledgeDetails, SkeletonLoader, useChargeItemDefinitions } from "commons"
import {
  getMRInitialValues,
  MedicationRequestData,
  MedicationRequestInfo,
  medsQueryKeys,
  sanitizeMR,
  useDraftMedicationRequests,
  useMedicationRequestDataBind,
} from "commons/meds"
import { useOpenEncounter } from "encounter"
import { CPOE_STEPS, useCPOEContext, useExtrasPrices, useUpdateAllCpoeRequests } from "orders"
import { useOrganizationContext, useOrganizationPractitioners } from "organization"
import { usePatientContext } from "patients"
import { getFeePrice, sumPrice } from "utils"

import { discardMR } from "../../data"
import { useCreateMedicationRequest, useDeleteMedicationRequest, useUpdateMedicationRequest } from "../../hooks"
import { MedicationKnowledgeList } from "./medication-knowledge/MedicationKnowledgeList"
import { MedicationRequestCardForm } from "./MedicationRequestCardForm"

const MedicationRequestsDraftContainer = () => {
  const cpoeCtx = useCPOEContext()
  const { patientId, patient } = usePatientContext()
  const {
    loggedInPractitionerRole,
    isStripeSetupComplete,
    showStripeWarning,
    currentOrganizationId,
    hasRootStripeType,
    nutraCatalogs,
  } = useOrganizationContext()
  const [params, setParams] = useSearchParams()
  const mrId = params.get("mrId") as string

  const [selectedMK, setSelectedMK] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()
  const { organizationPractitionersInfo } = useOrganizationPractitioners({ organizationId: currentOrganizationId })

  const { openEncounterRef } = useOpenEncounter(patientId)

  const { showForm, itemActionClicked, onActionCliked, toogleForm } = useReducerState()

  const {
    isLoading: isLoadingMR,
    medicationRequests,
    medicationKnowledges,
    medCodes,
    reloadMedications,
  } = useDraftMedicationRequests({ patientId, category: "nutraceutical", mrId, filterCarePlanOnes: true })

  const { chargeItemDefinitions } = useChargeItemDefinitions({
    organizationId: currentOrganizationId,
    codes: {
      billToPracticeOrInsuranceCIDs: medCodes,
    },
  })

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests,
    medicationKnowledges,
    medicationsCIDs: chargeItemDefinitions?.billToPracticeOrInsuranceCIDs ?? {},
  })

  const [medicationCreationFailed, setMedicationCreationFailed] = useState(false)
  const { createMedicationReq, isAdding } = useCreateMedicationRequest({
    hideForm: () => onActionCliked(""),
    onError: () => setMedicationCreationFailed(true),
    queryKey: medsQueryKeys.draft.list(patientId, "nutraceutical"),
  })

  const { updateMedicationRequest, isUpdating } = useUpdateMedicationRequest(
    () => {
      toogleForm(false)
      onActionCliked("")
    },
    medsQueryKeys.draft.list(patientId, "nutraceutical"),
  )

  const { deleteMedicationRequest, isDeleting } = useDeleteMedicationRequest(
    () => {
      onActionCliked("")
      if (mrId) {
        params.delete("mrId")
        setParams(params)
      }
    },
    medsQueryKeys.draft.list(patientId, "nutraceutical"),
  )

  const { updateAllCpoeRequests, isUpdatingAllRequests } = useUpdateAllCpoeRequests(patientId, () =>
    cpoeCtx.showCpoeOverlay(CPOE_STEPS.FINISH),
  )

  const onAddMK = (mk: MedicationKnowledge, units: number, catalogAuthor: Reference) => {
    const newMR = getMRInitialValues(
      mk,
      units,
      patient,
      loggedInPractitionerRole,
      organizationPractitionersInfo,
      catalogAuthor,
      openEncounterRef,
    )
    if (newMR.dispenseRequest?.dispenseInterval?.value) newMR.dispenseRequest.numberOfRepeatsAllowed = undefined
    createMedicationReq(sanitizeMR(newMR, true))
  }
  const onUpdateMR = (mr: MedicationRequestInfo) => {
    const updatedMR = sanitizeMR({ ...mr, encounter: openEncounterRef })
    onActionCliked(updatedMR.id as string)
    updateMedicationRequest(updatedMR)
  }

  const onDeleteMR = (mrId: string) => {
    discardMR(mrId, () => {
      deleteMedicationRequest(mrId)
      onActionCliked(mrId)
    })
  }

  useEffect(() => {
    if (medicationRequests.length === 0 && !isLoadingMR) toogleForm(true)
    if (cpoeCtx.ordersCount >= 0) reloadMedications()
  }, [isLoadingMR, cpoeCtx.ordersCount])

  const loader = (
    <SkeletonLoader
      repeats={2}
      containerClassName="p-5 grid grid-cols-1 xl:grid-cols-2 gap-2 xl:gap-4 overflow-auto"
      skeletonItemClassName="border border-slate-300 rounded-lg shadow-md"
      loaderType="list"
      skeletonShape="rectangle"
      skeletonSize="12rem"
      extraLine
    />
  )

  const emptyMessage = (
    <div className="flex flex-col gap-2 items-center justify-center h-full">
      <FontAwesomeIcon icon={faPills} size="3x" className="text-slate-400" />
      <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">Add wellness products and usage instructions</p>
      <Button
        label="Search products"
        icon={<FontAwesomeIcon icon={faSearch} className="mr-1" />}
        className="p-button-rounded button-default"
        onClick={() => toogleForm(true)}
      />
    </div>
  )

  return (
    <div className="flex flex-col h-full p-3 min-w-[50%]">
      <div className="flex flex-row overflow-hidden h-full gap-2">
        <div className={classNames("flex flex-col h-full overflow-auto w-2/3", { "lg:w-4/5": !params.has("kp") })}>
          {isLoadingMR ? (
            loader
          ) : !medicationRequestData.length ? (
            emptyMessage
          ) : (
            <div
              className={classNames("grid overflow-auto grid-cols-1 gap-2 2xl:gap-3", {
                "xl:grid-cols-2": !params.has("kp"),
              })}
            >
              {medicationRequestData.map((medication) => (
                <MedicationRequestCardForm
                  key={medication.medicationRequestInfo.id}
                  medicationData={medication}
                  isSaving={isUpdating && itemActionClicked === medication.medicationRequestInfo.id}
                  isDeleting={isDeleting && itemActionClicked === medication.medicationRequestInfo.id}
                  onDelete={onDeleteMR}
                  onSave={onUpdateMR}
                  onSelectMK={() =>
                    setSelectedMK({ mk: medication.medicationKnowledge, mr: medication.medicationRequestInfo })
                  }
                />
              ))}
            </div>
          )}
        </div>

        {!mrId && (
          <div
            className={classNames("bg-white w-1/3 flex justify-end min-w-[10rem]", { "lg:w-1/5 ": !params.has("kp") })}
          >
            <div className="flex flex-col min-w-[98%] max-h-64 h-full shadow-lg border border-slate-300 rounded-lg p-2">
              <h3 className="font-bold text-xl w-full mb-4 pl-3">Summary</h3>
              <div className="flex flex-col h-full px-2 gap-3 text-sm">
                {<SummaryInfo medicationRequestData={medicationRequestData} toogleForm={toogleForm} />}
              </div>
              <Button
                icon={<FontAwesomeIcon icon={faShoppingCart} className="mr-1" />}
                className="button-primary p-button-sm self-center m-1 h-10"
                label="Begin Checkout"
                loading={isUpdatingAllRequests}
                disabled={medicationRequestData.length === 0}
                onClick={() => {
                  if (isStripeSetupComplete || hasRootStripeType) {
                    updateAllCpoeRequests(medicationRequests)
                  } else showStripeWarning()
                }}
              />
            </div>
          </div>
        )}
      </div>

      {medicationRequests.length > 0 && !showForm && !mrId && (
        <FloatingButton
          icon={faSearch}
          className="p-button-rounded fixed bottom-6 right-6 add-button button-primary"
          onClick={() => toogleForm(true)}
          label="Search Products"
        />
      )}

      <Sidebar
        visible={showForm}
        position="right"
        style={{ height: "calc(100% - 2.5rem)" }}
        onHide={() => toogleForm(false)}
        modal={true}
        header={
          <div className="w-full flex items-center justify-between">
            <h6 className="text-md font-semibold bg-white">Products</h6>
            {!!nutraCatalogs.length && (
              <Button
                className="p-button-sm p-button-outlined text-xs"
                label="Continue"
                disabled={isAdding}
                onClick={() => toogleForm(false)}
              />
            )}
          </div>
        }
        showCloseIcon={!nutraCatalogs.length}
        className="absolute bottom-0 border-t-0 overflow-y-scroll min-w-max lg:min-w-[50%]"
        dismissable={!isAdding}
      >
        <MedicationKnowledgeList
          onAddMK={onAddMK}
          isAdding={isAdding}
          addedMedCodes={Object.keys(medicationKnowledges ?? {})}
          additionFailed={medicationCreationFailed}
        />
      </Sidebar>

      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.mk}
        mr={selectedMK?.mr}
        onHide={() => setSelectedMK(undefined)}
      />

      <ConfirmDialog />
    </div>
  )
}

const initialState = {
  showForm: false,
  itemActionClicked: "",
  medAdded: false,
}

const reducer = (
  state: typeof initialState,
  {
    type,
    payload,
  }: {
    type: "reset" | "toogle-form" | "action-cliked"
    payload: Array<string> | number | string | boolean
  },
) => {
  switch (type) {
    case "reset":
      return { ...initialState }

    case "action-cliked":
      return { ...state, itemActionClicked: payload as string }
    case "toogle-form":
      return {
        ...state,
        showForm: payload as boolean,
        medAdded: false,
      }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ showForm, itemActionClicked }, dispatch] = useReducer(reducer, initialState)

  const reset = () => {
    dispatch({ type: "reset", payload: "" })
  }

  const toogleForm = (showForm: boolean) => {
    dispatch({ type: "toogle-form", payload: showForm })
  }

  const onActionCliked = (medicationId: string) => {
    dispatch({ type: "action-cliked", payload: medicationId })
  }

  return {
    showForm,
    itemActionClicked,
    toogleForm,
    reset,
    onActionCliked,
  }
}

const SummaryInfo = ({
  medicationRequestData,
  toogleForm,
}: {
  medicationRequestData: MedicationRequestData[]
  toogleForm: (show: boolean) => void
}) => {
  const itemList = medicationRequestData.map(({ medicationRequestInfo: { resourceType, id } }) => ({
    resourceType: resourceType ?? "",
    id: id ?? "",
  }))
  const { medsShippingMethods } = useExtrasPrices(itemList)

  const freeShipping = medsShippingMethods?.some(
    ({ propertyGroup }) => getFeePrice(propertyGroup?.[0]?.priceComponent)?.amount?.value === 0,
  )
  const { startPrice, prescriptions } = medicationRequestData?.reduce(
    (prev, med) => {
      const startPrice = med.productPrice?.value ?? 0

      return {
        startPrice: sumPrice(prev.startPrice, startPrice).sum.toNumber(),
        prescriptions: medicationRequestData.length ?? 0,
      }
    },
    { startPrice: 0, prescriptions: 0 },
  ) ?? { startPrice: 0, prescriptions: 0 }

  return (
    <>
      {!prescriptions ? (
        <div>
          Add products{" "}
          <span
            className="cursor-pointer font-semibold text-primary underline hover:no-underline"
            onClick={() => toogleForm(true)}
          >
            here
          </span>
        </div>
      ) : (
        freeShipping && (
          <div title="Products" className="pt-3 text-slate-500 text-sm h-10">
            These products qualify for free shipping
          </div>
        )
      )}

      <div className="flex justify-between w-full">
        <label className="font-semibold">Subtotal</label>
        <span className="font-bold ml-1">${startPrice.toFixed(2)}</span>
      </div>
      <div className="font-normal -mt-2 text-gray-500 text-xs">
        ({`${prescriptions} ${pluralize("product", prescriptions)}`})
      </div>
    </>
  )
}

export { MedicationRequestsDraftContainer }
