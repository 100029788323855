const messagesQueryKeys = {
  orgPatients: {
    all: (currentOrganizationId: string) => ["patients", currentOrganizationId],
    list: (currentOrganizationId: string, filter: string) => [
      ...messagesQueryKeys.orgPatients.all(currentOrganizationId),
      filter,
    ],
  },
  unread: ["unread-messages"],
}

export { messagesQueryKeys }
