const laboratoryQueryKeys = {
  all: ["patient/labs"] as const,
  list: (patientId: string, encounter?: string) => [
    ...laboratoryQueryKeys.all,
    patientId,
    ...(encounter ? [encounter] : []),
  ],
}

export { laboratoryQueryKeys }
