import { faComments } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, AppModule, ModuleRenderSideType } from "commons/Module"

class CommunicationModule extends AppModule {
  constructor(workspaceElement: JSX.Element) {
    super()
    this.id = ModulesId.COMMUNICATION
    this.display = "Chat"
    this.icon = faComments
    this.hideBySetting = true
    this.workspaceElement = workspaceElement
    this.renderSideType = ModuleRenderSideType.WORKSPACE
  }
}

export { CommunicationModule }
