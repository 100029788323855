import { faFlask, faLocationDot, faTruck } from "@fortawesome/pro-regular-svg-icons"

import { Address, Patient } from "fhir"
import { getAddressByType, getAddressByTypeIndex, getHomeAddress, getStringAddress } from "utils"

const UNSPECIFIED_ADDRESS = "Unspecified address"

const getEffectivesAddressess = (address: Address[]) =>
  [
    {
      use: "home",
      display: getStringAddress(getHomeAddress(address)),
      index: getAddressByTypeIndex({ addressType: undefined, address }),
      icon: faLocationDot,
    },
    {
      use: "postal",
      display: getStringAddress(getAddressByType("postal", address)),
      index: getAddressByTypeIndex({ addressType: "postal", address }),
      icon: faTruck,
    },
    {
      use: "physical",
      display: getStringAddress(getAddressByType("physical", address)),
      index: getAddressByTypeIndex({ addressType: "physical", address }),
      icon: faFlask,
    },
  ].filter((item) => item.display !== UNSPECIFIED_ADDRESS)

const getArrangedPatientAddress = (patient: Patient, address: Address, editIndex?: number) => {
  let newAddress = patient.address ?? []
  const effectiveAddressType = address?.type === "home" ? undefined : address?.type
  const effectiveEditIndex =
    editIndex ?? (getAddressByTypeIndex({ addressType: effectiveAddressType, address: newAddress }) as number)

  if (effectiveEditIndex === -1) newAddress = [...newAddress, address]
  else {
    const isEditWithDifferentType = effectiveAddressType !== newAddress[effectiveEditIndex as number].type
    const swapIndex = getAddressByTypeIndex({ addressType: effectiveAddressType, address: newAddress }) as number

    newAddress.splice(effectiveEditIndex, 1, address)
    if (isEditWithDifferentType && swapIndex !== -1) {
      newAddress.splice(swapIndex, 1)
    }
  }

  return newAddress
}

export { getArrangedPatientAddress, getEffectivesAddressess, UNSPECIFIED_ADDRESS }
