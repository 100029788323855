import { FC } from "react"
import { IconDefinition } from "@fortawesome/pro-light-svg-icons"

import { StackedListContainer } from "commons"
import { medicationModel, useMedicationsStatement } from "medications-statement"

import { WrapUpSection } from "./WrapUpSection"

const IntakeMedicationsSection: FC<Props> = ({ patientId, encounterId, icon }) => {
  const { medications, isLoading, total } = useMedicationsStatement(patientId, encounterId)

  return (
    <WrapUpSection
      subSectionTitle="Medications Statements"
      isLoading={isLoading}
      isEmpty={!total}
      icon={icon}
      emptyMesage="No medications registered during this encounter"
      className="w-3/4 self-end"
    >
      <StackedListContainer data={medications ?? []} itemModelBuilder={(item) => medicationModel(item)} />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounterId: string
  icon: IconDefinition
}

export { IntakeMedicationsSection }
