import { faVials } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { Patient360LabsObservations } from "../../../patient360/components/Patient360LabsObservations"

class Labs360SubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.LABS_360
    this.display = "Labs"
    this.parent = ModulesId.PATIENT_360
    this.icon = faVials
    this.content = <Patient360LabsObservations />
  }
}

export { Labs360SubModule }
