import { FC } from "react"

import { AddressField, BirthdateField, GenderField, InputField } from "commons"
import { SmartyAddressVerificationProvider } from "commons/context"
import { IdentifierContainer } from "identifier"
import { useOrganizationContext } from "organization"

import { ProfileEmailField } from "./ProfileEmailField"
import { ProfileImageField } from "./ProfileImageField"

const PractitionerForm: FC<Props> = ({ initialEmail }: Props) => {
  const { isAdmin } = useOrganizationContext()
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between mb-5">
        <div className="flex flex-col gap-4 w-1/2">
          <InputField field="name[0].given[0]" label="First Name" />
          <InputField field="name[0].given[1]" label="Middle Name" />
          <InputField field="name[0].family" label="Last Name" className="col-span-2" />
          <InputField field="name[0].prefix[0]" label="Prefix" />
          <InputField field="name[0].suffix[0]" label="Sufix" />
        </div>
        <ProfileImageField />
      </div>

      <GenderField field="gender" label="Biological Sex" />
      <BirthdateField field="birthDate" label="Date of Birth" className="col-span-2" />

      <fieldset className="flex flex-col p-fluid gap-4">
        <legend>Address</legend>
        <SmartyAddressVerificationProvider>
          <AddressField parentFieldName="address[0]" showTypeUseField={false} />
        </SmartyAddressVerificationProvider>
      </fieldset>

      <fieldset className="flex flex-col p-fluid gap-4">
        <legend>Telecom</legend>
        <ProfileEmailField label="Email Address" email={initialEmail} />
        <InputField type="tel" mask="+1 (999) 999-9999" unmask={true} field="telecom[1].value" label="Phone" />
      </fieldset>

      <IdentifierContainer field="identifier" label="Identifiers" hideAdd={!isAdmin} />
    </div>
  )
}

type Props = {
  initialEmail: string
}

export { PractitionerForm }
