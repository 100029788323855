import { faCalendarDays } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Appointment, codeableConceptAsString, humanNameAsString } from "fhir"
import { useId } from "react"

import {
  AgendaListItem,
  ConfirmDialog,
  DataContainerSlideoverForm,
  InfiniteScroll,
  ModulesId,
  SkeletonLoader,
  useCrudReducer,
} from "commons"
import { useAppModuleContext } from "internals"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { useLoginContext } from "security"

import { useCreateAppointment, usePatientAppointments, useUnbookAppointment, useUpdateAppointment } from "../hooks"
import { AppointmentForm } from "./AppointmentForm"
import { appointmentValidationSchema, INITIAL_VALUES, initialValues, sanitize } from "./validations"

const PatientAppointments = () => {
  const { patient, patientId } = usePatientContext()
  const { location, roomsRefs } = useOrganizationContext()
  const { appModules } = useAppModuleContext()
  const { loggedInPractitioner } = useLoginContext()
  const { patientRef } = usePatientContext()

  const {
    initialValue,
    showSlide,
    slideContent,
    isNew,
    reset,
    add,
    edit,
    deleteIndex,
    setDeleteIndex,
    selectItem,
    selectedItem,
  } = useCrudReducer({
    defaultEntity: initialValues(
      { ...INITIAL_VALUES, participant: [{ actor: patientRef, status: "tentative" }] },
      loggedInPractitioner,
      roomsRefs,
      location,
    ),
  })

  const getEventColorByType = (type: string) =>
    ({
      "Check-up": "#22C55E",
      Emergency: "#F59E0B",
      "Follow-up": "#3B82F6",
      Routine: "#2DD4BF",
      "Walk-in": "#A855F7",
    })[type]

  const loaderKey = useId()

  const { appointments, isLoading, hasNextPage, fetchNextPage, count } = usePatientAppointments(patientId as string)

  const { createAppointment } = useCreateAppointment(reset)
  const { updateAppointment } = useUpdateAppointment(reset, reset)
  const { unbookAppointment, isUnbooking } = useUnbookAppointment({
    onSettled: () => {
      reset()
      setDeleteIndex()
    },
  })

  const unbook = (appointment: Appointment) => {
    setDeleteIndex(1)
    selectItem(appointment)
  }

  const onSubmit = (appointment: Appointment) =>
    isNew ? createAppointment(sanitize(appointment)) : updateAppointment(sanitize(appointment))

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="list" />

  if (isLoading) return loader()

  if (!appointments?.length) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <FontAwesomeIcon icon={faCalendarDays} size="2x" className="text-slate-400" />
        <h3 className="mt-2 text-sm font-semibold text-gray-900">No appointments found</h3>
      </div>
    )
  }

  return (
    <DataContainerSlideoverForm
      hasData={count > 0}
      showSlide={showSlide}
      slideContent={slideContent}
      formTitle="Appointment"
      iconDataNotFound={appModules[ModulesId.CALENDAR].getIcon()}
      formInitialValue={initialValue}
      validationSchema={appointmentValidationSchema()}
      onSubmit={onSubmit}
      onCancel={reset}
      form={<AppointmentForm hidePatientField isReadonlyPatient={!isNew} />}
      onButtonAddClick={add}
    >
      <div className="overflow-auto h-full">
        <InfiniteScroll loadMore={() => fetchNextPage()} hasMore={hasNextPage} loader={loader()}>
          <div className="overflow-hidden bg-white">
            <ul className="divide-y divide-gray-200">
              {appointments.map((item) => (
                <AgendaListItem
                  key={item.id}
                  appointmentStart={item.start}
                  appointmentDescription={item.description}
                  patientName={patient?.name ? humanNameAsString(patient?.name[0]) : ""}
                  patientAvatar={patient?.photo?.[0]?.url}
                  appointmentType={codeableConceptAsString(item.appointmentType)}
                  patientId={patientId}
                  appointmentStatus={item.status}
                  color={getEventColorByType(codeableConceptAsString(item.appointmentType))}
                  onEdit={() => edit(item)}
                  onUnbook={() => {
                    unbook(item)
                  }}
                />
              ))}
            </ul>
          </div>
        </InfiniteScroll>
      </div>
      <ConfirmDialog
        confirmText={`Are you sure you want to unbook "${selectedItem?.description}"`}
        actionName="Unbook"
        visible={deleteIndex !== undefined || isUnbooking}
        onConfirm={() => unbookAppointment(selectedItem as Appointment)}
        isLoading={isUnbooking}
        hideDialog={() => {
          setDeleteIndex()
        }}
      />
    </DataContainerSlideoverForm>
  )
}

export { PatientAppointments }
