import { faFileContract } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { ConsentsContainer } from "../../../consent/components/ConsentsContainer"

class ConsentSubmodule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.CONSENT
    this.display = "Consents"
    this.parent = ModulesId.PATIENT
    this.icon = faFileContract
    this.content = <ConsentsContainer />
  }
}

export { ConsentSubmodule }
