const surveysQueryKeys = {
  all: ["questionnaires"],
  url: (qrId?: string) => ["qr-url", qrId],
  inProgress: {
    all: ["in-progress-qrs"] as const,
    list: (patientId: string, key?: string, status?: string[], searchText?: string, encounter?: string) => [
      ...surveysQueryKeys.inProgress.all,
      patientId,
      key,
      status,
      searchText,
      encounter,
    ],
  },
  completed: {
    all: ["completed-qrs"] as const,
    list: (patientId: string, key?: string, status?: string[], searchText?: string, encounter?: string) => [
      ...surveysQueryKeys.completed.all,
      patientId,
      key,
      status,
      searchText,
      encounter,
    ],
  },
}

export { surveysQueryKeys }
