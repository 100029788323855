import { faFileInvoiceDollar } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { PaymentDetailsContainer } from "../../../invoices/components/PaymentDetailsContainer"

class PaymentDetailsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.INVOICE_PAYMENT_DETAILS
    this.parent = ModulesId.INVOICE
    this.display = "Payment details"
    this.icon = faFileInvoiceDollar
    this.content = <PaymentDetailsContainer />
  }
}

export { PaymentDetailsSubModule }
