import { useMsal } from "@azure/msal-react"
import { BrowserUtils, InteractionStatus } from "@azure/msal-browser"
import { useEffect } from "react"

const Logout: React.FC = () => {
  const { instance, inProgress } = useMsal()

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        onRedirectNavigate: () => !BrowserUtils.isInIframe(),
      })
    }
  }, [instance, inProgress])

  return null
}

export { Logout }
