import { useQuery } from "@tanstack/react-query"
import { Parameters } from "fhir"

import { useClient } from "api"

import { dosespotQueryKeys } from "../query-keys"

const useDosespotUrl = (patientId: string, practitionerRoleId: string) => {
  const { operationRequest } = useClient()

  const queryKey = dosespotQueryKeys.url(patientId, practitionerRoleId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async () => {
      const parameters: Parameters = {
        resourceType: "Parameters",
        parameter: [
          {
            name: "patient",
            value: { Reference: { id: patientId } },
          },
          {
            name: "practitioner",
            value: { Reference: { id: practitionerRoleId } },
          },
        ],
      }
      const result = await operationRequest({
        endpoint: `dosespot/Patient`,
        method: "POST",
        operation: "prescribe-uri",
        parameters,
      })

      return result as string
    },

    meta: { context: { queryKey } },
  })

  return { dosespotUrl: data, isLoading }
}

export { useDosespotUrl }
