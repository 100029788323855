import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { faPencil } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Practitioner } from "fhir"
import { FormikValues } from "formik"
import { FC, useCallback, useEffect, useMemo, useState } from "react"

import {
  DialogFormContainer,
  GroupedList,
  PractitionerInfo,
  SearchWithFilters,
  StackedListItem,
  useCrudReducer,
  ValueSetIds,
} from "commons"
import { ReplaceFormProvider } from "commons/context"
import { genders, IDENTIFIER_CODE } from "data"
import { IdentifierContainer } from "identifier"
import { useOrganizationContext } from "organization"
import { useValueSet } from "value-set"

import { getPractitionerModel } from "./getPractitionerModel"
import { usePatchPractitionerIdentifiers } from "./hooks"
import { getGroupedPractitioners } from "./transformers"

const PractitionersContainer: FC = () => {
  const { organizationPractitionersInfo: practitioners, isAdmin, refetchPractsInfo } = useOrganizationContext()
  const { codes } = useValueSet({ valueSetId: ValueSetIds.PRACTITIONER_IDENTIFIERS, enabled: true })
  const practIdentifierTypes = codes?.filter((code) => code.code === IDENTIFIER_CODE.AN)

  const [currentPractitioners, setCurrentPractitioners] = useState(practitioners)
  const [filters, setFilters] = useState<FormikValues & { searchText?: string }>({
    gender: undefined,
    email: undefined,
  })
  const {
    edit,
    initialValue,
    showSlide: showForm,
    reset,
  } = useCrudReducer({ defaultEntity: { identifier: [] } as Partial<Practitioner> })

  const itemMenu = (practInfo: PractitionerInfo) => {
    return isAdmin
      ? [
          {
            label: "Manage Identifiers",
            icon: <FontAwesomeIcon icon={faPencil} size="sm" />,
            command: () => edit({ id: practInfo.practitioner.id, identifier: practInfo.practitioner.identifier }),
          },
        ]
      : []
  }

  const search = (searchText?: string) => {
    if (!searchText) setCurrentPractitioners(practitioners)
    else {
      setCurrentPractitioners(
        practitioners.filter(
          ({ practitioner }) =>
            practitioner.name?.[0]?.family?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
            practitioner.name?.[0]?.given?.join(" ").toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
            practitioner.telecom?.some(
              ({ system, value }) =>
                system === "email" && value?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()),
            ),
        ),
      )
    }
  }
  const onFilter = useCallback(
    ({ email, gender, searchText }: FormikValues & { searchText?: string }) => {
      if (searchText) {
        search(searchText)
      } else {
        if (!email && !gender) setCurrentPractitioners(practitioners)
        else {
          setCurrentPractitioners(
            practitioners.filter(
              ({ practitioner }) =>
                (gender ? practitioner.gender === gender : true) &&
                (email
                  ? practitioner.telecom?.some(({ system, value }) => system === "email" && value?.includes(email))
                  : true),
            ),
          )
        }
      }
      setFilters({ email, gender, searchText })
    },
    [practitioners],
  )

  const { patchPractitioner } = usePatchPractitionerIdentifiers(refetchPractsInfo, reset)

  const handleSubmit = (data: Partial<Practitioner>) => {
    patchPractitioner(data)
  }

  useEffect(() => {
    onFilter(filters)
  }, [practitioners])

  const practitionerGroups = useMemo(() => getGroupedPractitioners(currentPractitioners), [currentPractitioners])

  return (
    <>
      <div className="flex flex-col p-2 mb-3">
        <SearchWithFilters
          onTextFilter={search}
          filtersData={[
            {
              label: "Email",
              field: "email",
              type: "text",
              textType: "email",
            },
            {
              label: "Biological Sex",
              field: "gender",
              data: genders,
              type: "select",
              optionValue: "code",
              optionLabel: "label",
            },
          ]}
          isLoading={false}
          onFilter={onFilter}
          initialFilters={filters}
          showClearButton
        />
        <p className="text-sm text-gray-500 mt-1">Showing {practitioners.length} active practitioners</p>
      </div>

      <div className="flex overflow-y-auto grow w-full">
        <GroupedList
          className="grow"
          groups={practitionerGroups}
          renderItem={(practitionerInfo) => (
            <StackedListItem modelData={getPractitionerModel({ practitionerInfo, menu: itemMenu })} />
          )}
          renderEmptyState={() => (
            <div className="flex flex-col items-center m-auto pt-10">
              <FontAwesomeIcon icon={faSearch} size="2x" className="text-slate-500" />
              <p className="text-slate-400 pt-3">No results found, please change filters and try again</p>
            </div>
          )}
        />
      </div>
      <ReplaceFormProvider>
        <DialogFormContainer
          title="Manage Identifiers"
          showForm={showForm}
          onSubmit={handleSubmit}
          onCancel={reset}
          initialValue={initialValue}
          useFormik
        >
          <IdentifierContainer
            field="identifier"
            label="Identifiers"
            types={practIdentifierTypes}
            enabledTypesEdit={[IDENTIFIER_CODE.AN]}
          />
        </DialogFormContainer>
      </ReplaceFormProvider>
    </>
  )
}

export { PractitionersContainer }
