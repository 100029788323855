import { useContext } from "react"

import { PlanContext } from "../context/PlanProvider"

const usePlanContext = () => {
  const context = useContext(PlanContext)

  if (context === undefined) {
    throw new Error("usePlanContext must be used within a PlanContextProvider")
  }

  return context
}

export { usePlanContext }
