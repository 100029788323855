import { getUnixTime } from "date-fns/getUnixTime"

const INTERCOM_EMAIL_DOMAIN_BLACK_LIST = ["divineslns.com", "level6.co"]
const ROOT_ORGS_DOMAIN = {
  "chartedhealth.com": { company_id: "chartedhealth", name: "ChartedHealth" },
  "evexias.com": { company_id: "evexias-health-solutions", name: "Evexias Health Solutions" },
} as Record<string, Company>
const ROOT_DOMAIN_KEYS = {
  evexias: window.VITE_APP_INTERCOM_KEY,
  metaboliccode: window.VITE_APP_MC_INTERCOM_KEY,
  chartedhealth: window.VITE_APP_INTERCOM_KEY,
}

const getDomainRegex = (domain?: string) => new RegExp(`([.])(${domain})([.])`)

function getIntercomKey(url?: string) {
  Object.entries(ROOT_DOMAIN_KEYS).forEach(([domain, key]) => {
    if (getDomainRegex(domain).test(url ?? window.location.href)) {
      return key
    }
  })

  return ROOT_DOMAIN_KEYS.chartedhealth
}

const intercomBlackList = (email?: string) =>
  INTERCOM_EMAIL_DOMAIN_BLACK_LIST.includes(email?.split("@")?.[1] as string)

const intercomInit = ({
  userName,
  userId,
  email,
  userHash,
  sessionReplayLink,
  createdAt,
  orgUrl,
}: {
  userName: string
  userId: string
  email: string
  userHash?: string
  sessionReplayLink?: string
  createdAt?: string
  orgUrl?: string
}) => {
  window.intercomSettings.app_id = getIntercomKey(orgUrl)

  if (!intercomBlackList(email))
    window.Intercom(
      "boot", //Options
      {
        api_base: window.intercomSettings.api_base,
        app_id: window.intercomSettings.app_id,
        name: userName,
        user_id: userId,
        email,
        user_hash: userHash,
        ...(sessionReplayLink ? { session_replay_url: sessionReplayLink } : {}),
        ...(createdAt ? { created_at: new Date(createdAt).getTime() } : {}),
      },
    )
}

const intercomUpdate = ({
  company,
  avatar,
  email,
  user_id,
  practitioner_id,
  name,
  gender,
  user_role,
  accessible_modules,
}: {
  user_id: string
  email: string
  practitioner_id?: string
  name?: string
  company?: Company
  avatar?: Avatar
  gender?: string
  user_role?: string
  accessible_modules?: string
}) => {
  if (window.Intercom && !intercomBlackList(email)) {
    const rootCompany = ROOT_ORGS_DOMAIN[email.split("@")[1]]
    window.Intercom("update", {
      user_id,
      email,
      company: rootCompany ?? company,
      avatar,
      name,
      practitioner_id,
      gender,
      user_role,
      accessible_modules,
    })
  }
}

const intercomTrackEvent = ({ event, metadata, email }: IntercomEvent) => {
  if (window.Intercom && !intercomBlackList(email)) {
    const currentTimestamp = getUnixTime(new Date())

    window.Intercom("trackEvent", event, { ...metadata, registered_at: currentTimestamp })
  }
}

type Company = {
  company_id: string
  name?: string
  created_at?: number
}

type Avatar = {
  type: "avatar"
  image_url: string
}

type IntercomEvent = {
  user_id: string
  email: string
  event: "order-placed" | "user-invited" | "session-started"
  metadata?: { [key: string]: string | number | RichLink | undefined }
}

type RichLink = { url: string; value: string }

export { intercomInit, intercomTrackEvent, intercomUpdate }
