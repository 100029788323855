import { TextAreaField, AutocompleteCodeableConceptField, DateField, ValueSetIds } from "commons"

const SocialHistoryForm = ({ isEditing }: Props) => (
  <div className="relative p-fluid grid gap-4 mb-3">
    <AutocompleteCodeableConceptField field="code" label="Code" valueSetId={ValueSetIds.ICD10} readonly={isEditing} />
    <DateField
      field="effective.dateTime"
      label="Effective"
      stringFormatType="ISO_8601_DATETIME"
      showTime
      className="grow"
    />
    <TextAreaField field="note[0].text" label="Note" rows={5} />
  </div>
)

type Props = {
  isEditing?: boolean
}

export { SocialHistoryForm }
