import { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { FC } from "react"
import { useSearchParams } from "react-router-dom"

import { StackedListContainer } from "commons"
import { PLAN_SUBVIEWS, planItemModel, useCarePlans } from "commons/care-plans"

import { WrapUpSection } from "./WrapUpSection"

const PlansSection: FC<Props> = ({ patientId, encounter, icon }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { plans, isLoading, total } = useCarePlans({ patientId, encounter })

  const showPlanDetails = (id: string) => {
    const params = new URLSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
      view: "plans",
      subview: PLAN_SUBVIEWS.DETAILS,
      planId: id,
    })
    setSearchParams(params)
  }

  return (
    <WrapUpSection
      isEmpty={!total}
      icon={icon}
      emptyMesage="No treatment plans activated during this encounter"
      className="w-10/12 self-end z-50"
      sectionTitle="Treatment Plans"
      isLoading={isLoading}
    >
      <StackedListContainer
        data={plans}
        itemModelBuilder={(item) =>
          planItemModel({
            plan: item,
            isLoading: false,
            viewPlan: () => showPlanDetails(item.carePlan.id as string),
            contextLabel: "Treatment Plans",
            showConfig: false,
          })
        }
      />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounter: string
  icon: IconDefinition
}

export { PlansSection }
