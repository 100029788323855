import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { codeableConceptAsString } from "fhir"
import { Checkbox } from "primereact/checkbox"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { FC } from "react"

import { type Supplement } from "../../../types"
import { getDosageTiming, getSupplementId } from "../../../utils"

const MedSupplement: FC<Props> = ({
  suplement,
  selected,
  onChecked,
  disabled,
  inUse,
  triads,
  className = "rounded-md bg-gray-100 p-2",
}) => {
  const morning = getDosageTiming(suplement.mr, "MORN")
  const noon = getDosageTiming(suplement.mr, "NOON")
  const cv = getDosageTiming(suplement.mr, "CV")
  const afternoon = getDosageTiming(suplement.mr, "AFT")
  const hs = getDosageTiming(suplement.mr, "HS")

  const hasTimingDosage = !!morning || !!noon || !!cv || !!afternoon || !!hs
  const dosageInstructions = suplement.mk.administrationGuidelines?.[0]?.dosage?.flatMap(
    ({ dosage }) => dosage?.[0]?.text ?? "",
  )

  const warning =
    !suplement.mk.id || !suplement.price
      ? `${codeableConceptAsString(
          suplement.mr.medication?.CodeableConcept,
        )}, is currently unavailable in our inventory`
      : inUse
        ? "There is an active order with this medication"
        : undefined

  return (
    <>
      <div
        className={classNames(
          "grid grid-cols-12",
          disabled ? "text-gray-500 cursor-default" : "cursor-pointer",
          className,
        )}
      >
        <div
          className={classNames(
            "col-span-5 flex gap-2 items-center font-medium text-sm",
            disabled ? "cursor-default" : "cursor-pointer",
          )}
          onClick={() => !disabled && onChecked(!selected)}
        >
          <div id={`_${getSupplementId(suplement)}`} className="flex items-center gap-2">
            {warning ? (
              <FontAwesomeIcon icon={faTriangleExclamation} className="mr-1.5" />
            ) : (
              <Checkbox
                checked={selected}
                disabled={disabled}
                className={disabled ? "cursor-default" : "cursor-pointer"}
              />
            )}
            {suplement.triads?.map((triad) => (
              <img key={`${triad}Img`} src={triads?.[triad].img} alt="" className="w-3" />
            ))}
            {codeableConceptAsString(suplement.mr.medication?.CodeableConcept)}
          </div>
        </div>

        <div className="col-span-6 grid grid-cols-5 gap-2">
          {hasTimingDosage || !dosageInstructions?.length ? (
            <>
              <div className={colStyle}>{morning && morning.text}</div>
              <div className={colStyle}>{noon && noon.text}</div>
              <div className={colStyle}>{afternoon && afternoon.text}</div>
              <div className={colStyle}>{cv && cv.text}</div>
              <div className={colStyle}>{hs && hs.text}</div>
            </>
          ) : (
            <>
              {dosageInstructions?.map((text, index) => (
                <p
                  className="col-span-full text-sm line-clamp-1 text-ellipsis"
                  key={`${getSupplementId(suplement)}_${index}`}
                >
                  {text}
                </p>
              ))}
            </>
          )}
        </div>

        <div className="flex items-center justify-end text-xs">
          {suplement.price ? `$${suplement.price.value?.toFixed(2)}` : "No price"}
        </div>
      </div>
      <Tooltip
        target={`#_${getSupplementId(suplement)}`}
        position="left"
        event="hover"
        className="text-xs p-0 m-0"
        content={warning}
      />
    </>
  )
}

const colStyle = "flex items-center justify-center rounded-md bg-white font-medium text-xs"

export { MedSupplement }

type Props = {
  suplement: Supplement
  onChecked: (checked: boolean) => void
  selected: boolean
  disabled: boolean
  inUse: boolean
  triads?: Record<string, { img: string; borderColor: string; bgColor: string }>
  className?: string
}
