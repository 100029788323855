import { ErrorMessage, Field, FieldProps, FormikValues } from "formik"
import { classNames } from "primereact/utils"
import { ReactNode } from "react"

const FormField = <T extends FormikValues | string | number>({
  field,
  label,
  children,
  className,
  horizontal,
  containerClassName,
  showInvalidState,
  headerButtons,
  labelClassName = "text-sm font-medium text-gray-700",
  labelAlign = "items-center",
  validation,
}: FormFieldProps<T>) => (
  <Field name={field} validate={validation}>
    {(props: FieldProps<T>) => (
      <div
        className={classNames(
          "field relative",
          horizontal ? "inline-flex justify-between horizontal" : "flex flex-col",
          className,
        )}
      >
        {label && (
          <div className={classNames("flex justify-between mb-2", { "mr-3 mb-0 mt-2 ": horizontal }, labelAlign)}>
            <label
              htmlFor={props.field.name}
              className={labelClassName}
              title={typeof label === "string" ? label : undefined}
            >
              {label}
            </label>
            {headerButtons}
          </div>
        )}
        <div className={classNames("flex flex-col w-full", { "flex-1": horizontal })}>
          <div
            className={classNames("flex flex-col flex-1", containerClassName, {
              "ring-1 ring-red-500 rounded-md overflow-hidden":
                showInvalidState && props.meta.error && props.meta.touched,
            })}
          >
            {typeof children === "function" ? children(props) : children}
          </div>
          <div className="flex items-start p-error h-4">
            <ErrorMessage name={field}>{(msg) => <small id={`errorMessage.${field}`}>{msg}</small>}</ErrorMessage>
          </div>
        </div>
      </div>
    )}
  </Field>
)

export type FormFieldProps<T = FormikValues> = {
  field: string
  label?: string | ReactNode
  className?: string
  horizontal?: boolean
  containerClassName?: string
  showInvalidState?: boolean
  children: ReactNode | ((props: FieldProps) => ReactNode)
  headerButtons?: ReactNode | ReactNode[]
  validation?(value: T): void
  labelClassName?: string
  labelAlign?: "items-center" | "items-start"
}

export type FormFieldBaseProps<T = FormikValues> = Omit<FormFieldProps<T>, "children" | "showInvalidState">

export { FormField }
