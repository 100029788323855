import { useFormikContext } from "formik"
import { useEffect, useMemo, useState } from "react"
import ContentEditable, { ContentEditableEvent } from "react-contenteditable"

import { populateTemplate } from "utils"

import { procedureNoteHtmlTemplate } from "../../data"
import { useProcedureNotePrefillData } from "../../hooks"
import { ProcedureData } from "../../types"

const ProcedureNotes = () => {
  const {
    values: {
      procedure: { note },
    },
    setFieldValue,
  } = useFormikContext<ProcedureData>()
  const procedureNotePrefillData = useProcedureNotePrefillData()
  const prefilledHtmlTemplate = useMemo(
    () => populateTemplate(procedureNoteHtmlTemplate, procedureNotePrefillData),
    [procedureNotePrefillData],
  )
  const [noteHtml, setNoteHtml] = useState(note?.[0]?.text || prefilledHtmlTemplate)

  const handleNoteChange = (event: ContentEditableEvent) => {
    setNoteHtml(event.target.value)
    setFieldValue("procedure.note[0].text", event.target.value)
  }

  useEffect(() => {
    if (!note?.[0]?.text) {
      setNoteHtml(prefilledHtmlTemplate)
    }
  }, [prefilledHtmlTemplate, note])

  return (
    <ContentEditable
      className="h-auto p-2 focus-visible:outline-none border-2 border-dashed"
      html={noteHtml}
      onChange={handleNoteChange}
    />
  )
}

export { ProcedureNotes }
