import { Reference } from "fhir"

const auditEventsQueryKeys = {
  list: (
    organizationID: string,
    filter = "",
    type?: string[],
    dates?: Date[],
    patient?: Reference,
    practitioner?: Reference,
  ) => ["organization/audit-events", organizationID, filter, type, dates, patient, practitioner],
  details: (organizationId: string, eventId: string) => ["audit-event", organizationId, eventId],
}

export { auditEventsQueryKeys }
