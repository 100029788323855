import { useChartContext } from "chart-view"
import { KnowledgeListContainer, ModulesId } from "commons"
import { useAppModuleContext } from "internals"
import { usePatientContext } from "patients"

import { surveyModelBuilder } from "../components/surveyModelBuilder"
import { useQuestionnaireResponses } from "../hooks"

const SurveysKnowledge = () => {
  const { appModules } = useAppModuleContext()
  const { patientId } = usePatientContext()
  const { questionnaireData, isLoading } = useQuestionnaireResponses({ patientId, key: "KP" })
  const { showModule } = useChartContext()

  const navigateToDetail = (id?: string) => {
    if (!id) return
    showModule({ module: ModulesId.SURVEY, moduleParams: { qrId: id } })
  }
  return (
    <KnowledgeListContainer
      isLoading={isLoading}
      dataTitle="questionnaires"
      data={questionnaireData}
      iconDataNotFound={appModules[ModulesId.SURVEY].getIcon()}
      itemModelBuilder={(item) =>
        surveyModelBuilder({ item, onClick: () => navigateToDetail(item.qResponse?.id), showAsExternal: true })
      }
    />
  )
}

export { SurveysKnowledge }
