import { PrimeReactProvider } from "primereact/api"
import React from "react"
import { createRoot } from "react-dom/client"
import { ToastContainer } from "react-toastify"
import { twMerge } from "tailwind-merge"

import { NetworkContextProvider } from "check-network"
import { dataDogInit } from "datadog"
import { init as loggerInit } from "logger"

import "primeicons/primeicons.css"

import "./main.css"

import "primereact/resources/themes/lara-light-blue/theme.css"

import "./prime-react-custom-theme.css"

import "react-toastify/dist/ReactToastify.css"

import { App } from "./App"

loggerInit()
dataDogInit() // declare enviroment

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PrimeReactProvider
      value={{
        pt: {},
        ptOptions: {
          mergeSections: true,
          mergeProps: true,
          classNameMergeFunction: twMerge,
        },
      }}
    >
      <NetworkContextProvider>
        <App />
        <ToastContainer autoClose={10000} theme={"light"} position={"bottom-left"} />
      </NetworkContextProvider>
    </PrimeReactProvider>
  </React.StrictMode>,
)
