import { faPills } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { Patient360MedicationStatements } from "../../../patient360/components/Patient360MedicationStatements"

class MedicationStatements360SubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.MEDICATIONE
    this.display = "Medication Statements"
    this.parent = ModulesId.INTAKE
    this.icon = faPills
    this.content = <Patient360MedicationStatements />
  }
}

export { MedicationStatements360SubModule }
