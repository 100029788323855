import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Device } from "fhir"

import { useClient } from "api"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { organizationQueryKeys } from "organization"
import { displayNotificationSuccess } from "utils"

import { CustomError } from "../../types"
import { readerQueryKeys } from "../query-keys"

const useCreateUpdateDevice = ({ onSuccess, onSettled }: CreateUpdateDeviceArgs) => {
  const { create, update } = useClient()
  const queryClient = useQueryClient()

  const newDevice = async (device: Device) =>
    device.id ? update("Device", device.id, device) : create("Device", device)

  const { mutate: createOrUpdateDevice, isPending } = useMutation({
    mutationFn: newDevice,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (device, { id, owner }) => {
      await queryClient.invalidateQueries({ queryKey: organizationQueryKeys.currentOrganization.all })
      await queryClient.invalidateQueries({ queryKey: readerQueryKeys.all(owner?.id as string) })

      const notificationLabel = device.identifier?.some(({ system }) => system?.includes("reader"))
        ? "Reader"
        : "Device"

      displayNotificationSuccess(`${notificationLabel} ${id ? "updated" : "added"} successfully!`)
      // datadogLogs.logger.info(`${notificationLabel} ${id ? "updated" : "added"} successfully!`, { device })
      onSuccess?.(device)
    },
    onSettled,
  })

  return { createOrUpdateDevice, isPending }
}

interface CreateUpdateDeviceArgs {
  onSuccess?: (device: Device) => void
  onSettled?: () => void
}

export { useCreateUpdateDevice }
