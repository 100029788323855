import {
  ActivityDefinitionParticipantArray,
  ChargeItem,
  CodeableConcept,
  Invoice,
  MedicationAdministration,
  MedicationRequest,
  MedicationRequestDispenseRequest,
  Money,
  Parameters,
  Procedure,
  RequestGroupActionArrayActionArray,
  ServiceRequest,
} from "fhir"

import { SYSTEM_VALUES } from "system-values"

export type CpoeRequest = {
  resource: RequestGroupActionArrayActionArray
  display: string
  type: ActionGroupCode
  unitPrice: Money
  quantity: number
  medicationData?: CpoeMedicationRequest
  laboratoryData?: CpoeLaboratoryRequest
  procedureData?: CpoeProcedureRequest
}

export type CpoeMedicationRequest = {
  medicationRequest?: MedicationRequest
  maxDaysSupplyLimit?: number
  manufacturer: string
  dispenseRequest?: MedicationRequestDispenseRequest
  isProcedure: boolean
  isMedication: boolean
  isDfo?: boolean
  doseForm?: string
  packaging?: string
}

export type CpoeLaboratoryRequest = {
  serviceRequest: ServiceRequest
  panels: CpoeLaboratoryPanel[]
  panelsCount: number
  billingType: string
  combos?: number
  carePlanId?: string
}

export type CpoeLaboratoryPanel = {
  profile: ServiceRequest
  price?: Money
}

export type CpoeProcedureRequest = {
  medicationRequests: MedicationRequest[]
  medicationAdministrations: MedicationAdministration[]
  procedure: Procedure
  medsData: Array<MedData>
}

export type MedData = { id?: string; code?: CodeableConcept; price?: Money }

export type InvoiceItem = {
  productId?: string
  productType?: string
  description: string
  qty?: number
  price: number
}

export type CustomInvoiceData = {
  invoice?: Invoice
  items: Record<string, InvoiceItem[]>
  fees: InvoiceItem[]
  feesSubTotal: number
  shippingMethods: Record<string, InvoiceItem[]>
  shippingMethodSubtotal: number
  taxes: number
  itemsSubtotal: number
  discount: number
  discounts?: Record<string, InvoiceItem[]>
  productFees?: Record<string, InvoiceItem[]>
  productFeesSubtotal: number
  summary?: Parameters
  chargeItems?: ChargeItem[]
  amendments: Record<string, InvoiceItem[]>
}

export type ActionGroupCode = "rx" | "procedure" | "lab-order" | "nutraceutical"

export const CpoeActionGroupTypes: { [key: string]: { code: ActionGroupCode; display: string } } = {
  nutraceutical: { code: "nutraceutical", display: "Nutraceutical" },
  rx: { code: "rx", display: "Rx" },
  lab: { code: "lab-order", display: "Laboratory" },
  procedure: { code: "procedure", display: "Procedure" },
}

export type SummaryParameter =
  | "subtotal"
  | "taxes"
  | "discounts-total"
  | "discounts"
  | "total"
  | "credit-note"
  | "debit-note"

export enum CPOE_STEPS {
  SUMMARY = "summary",
  FINISH = "checkout-config",
}

export enum ACTION_GROUP_CODES {
  PHARMA = "rx",
  LAB = "lab-order",
  PROCEDURE = "procedure",
  NUTRA = "nutraceutical",
}

export const PaymentDevicePerformer: ActivityDefinitionParticipantArray = {
  role: {
    text: "Payment Device",
    coding: [{ system: SYSTEM_VALUES.EXTERNAL_AGENT, code: "payment-device", display: "Payment Device" }],
  },
  type: "payment-device",
}

export const RG_BILLING_ACTION_CODES = {
  PHARMA: "billing-type-rx",
  NUTRA: "billing-type-nutraceutical",
}

export type InvoiceData = { invoice: Invoice; chargeItems: ChargeItem[]; summary: Parameters }
