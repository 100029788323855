import { useInfiniteQuery } from "@tanstack/react-query"
import { DocumentReference, getResources, ServiceRequest } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { getStatus } from "commons/labs"

import { patientsQueryKeys } from "../query-keys"

const usePatientDocuments = (patientId: string) => {
  const { search } = useClient()
  const queryKey = patientsQueryKeys.documents.list(patientId)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    PatientDocumentsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-date",
        _include: "related:ServiceRequest",
        "status:not": "entered-in-error",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/DocumentReference`, filters, signal })

      const documents = getResources<DocumentReference>(bundle, "DocumentReference")
      const orders = getResources<ServiceRequest>(bundle, "ServiceRequest")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { documents, orders, next, total: bundle?.total ?? 0 }
    },
    getNextPageParam: (lastPage) => lastPage.next,
    initialPageParam: 1,
    meta: { context: { queryKey, patientId } },
  })

  const { documents, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.documents)
    const count = newData?.length
    const newOrders = data?.pages
      .flatMap((page) => page.orders)
      ?.reduce(
        (acc, order) => {
          if (order?.category?.some(({ coding }) => coding?.some(({ code }) => code === "lab-order"))) {
            return { ...acc, [order.id as string]: order }
          }
          return { ...acc }
        },
        {} as Record<string, ServiceRequest>,
      )

    const docsToShow = newData?.reduce((acc, doc) => {
      const docRelatedToLabOrder = newOrders?.[doc.context?.related?.[0]?.id as string]

      if (docRelatedToLabOrder && getStatus(docRelatedToLabOrder)?.code !== "cancelled") {
        return [...acc, doc]
      }
      return [...acc, ...(!docRelatedToLabOrder ? [doc] : [])]
    }, Array<DocumentReference>())

    return { documents: docsToShow ?? [], count }
  }, [data?.pages])

  return {
    documents,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type PatientDocumentsQueryData = {
  documents: DocumentReference[]
  next: number | undefined
  total: number
  orders?: ServiceRequest[]
}

export { usePatientDocuments }
