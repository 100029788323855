import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FamilyMemberHistory } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { familyHistoryQueryKeys } from "../query-keys"

const useCreateFamilyHistory = (hideForm: () => void, patientId: string) => {
  const { create } = useClient()
  const queryClient = useQueryClient()

  const newFamilyHistory = async (familyHistory: FamilyMemberHistory) => create("FamilyMemberHistory", familyHistory)

  const { mutate: createFamilyHistory, isPending: isAdding } = useMutation({
    mutationFn: newFamilyHistory,
    onSuccess: async () => {
      queryClient.refetchQueries({ queryKey: familyHistoryQueryKeys.history(patientId), type: "all" })
      displayNotificationSuccess("Family history created successfully!")
      // datadogLogs.logger.info(`Family history ${data.id} created successfully!`, { data })
    },
    onSettled: hideForm,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return { createFamilyHistory, isAdding }
}

export { useCreateFamilyHistory }
