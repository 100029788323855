import { faCalendarClock, faCalendarDay, faCalendarDays } from "@fortawesome/pro-regular-svg-icons"
import { faUser, faUserDoctor } from "@fortawesome/pro-solid-svg-icons"
import { format } from "date-fns"
import { FC } from "react"

import { formatsByTypes } from "data"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { Badge } from "../../components/Badge"
import { EmptyMessage } from "../../components/EmptyMessage"
import { SkeletonLoader } from "../../components/SkeletonLoader"
import { StackedListContainer } from "../../components/StackedListContainer"
import { StackedListItemProps } from "../../components/StackedListItem"
import { useRescheduleAuditEvents } from "../hooks"
import { RescheduleEventData } from "../types"

const RescheduleHistory: FC<Props> = ({ orderId }) => {
  const { currentOrganizationId } = useOrganizationContext()
  const { patientId } = usePatientContext()
  const { auditEvents, isLoading } = useRescheduleAuditEvents(currentOrganizationId, patientId, orderId)

  return isLoading ? (
    <SkeletonLoader loaderType="two-lines" extraLine repeats={3} />
  ) : auditEvents.length ? (
    <StackedListContainer
      data={auditEvents}
      className="h-full w-full overflow-y-auto"
      itemModelBuilder={modelBuilder}
    />
  ) : (
    <EmptyMessage
      icon={faCalendarClock}
      message="No rescheduled order"
      subMessage={false}
      className="h-full content-center"
    />
  )
}

const modelBuilder = (auditEvent: RescheduleEventData): StackedListItemProps => ({
  leftData: [
    {
      lineItems: auditEvent.participants.map((p) =>
        p.requestor
          ? { name: "Requestor", component: <Badge text={p.name} colorStyle="blue" /> }
          : {
              name: p.type,
              value: p.name,
              icon: p.type === "Practitioner" ? faUserDoctor : p.type === "Patient" ? faUser : undefined,
            },
      ),
    },
    {
      lineItems: [
        {
          name: "Reschedule date",
          value: format(auditEvent.recorded, formatsByTypes.ISO_8601_DATE),
          icon: faCalendarDays,
        },
        {
          name: "Rescheduled from",
          value: format(auditEvent.from, formatsByTypes.ISO_8601_DATE),
          icon: faCalendarClock,
        },
        {
          name: "Rescheduled to",
          value: format(auditEvent.to, formatsByTypes.ISO_8601_DATE),
          icon: faCalendarDay,
        },
      ],
    },
  ],
})

type Props = {
  orderId: string
}

export { RescheduleHistory }
