import { createPlugin } from "@fullcalendar/core"
import { ViewProps } from "@fullcalendar/core/internal"
import DayGridPlugin from "@fullcalendar/daygrid"
import FullCalendar from "@fullcalendar/react"
import TimeGridPlugin from "@fullcalendar/timegrid"
import { add, areIntervalsOverlapping, isSameDay } from "date-fns"
import { Appointment } from "fhir"
import { Button } from "primereact/button"
import { createRef, FC, useCallback, useEffect, useReducer } from "react"

import {
  AppointmentFormOverlay,
  INITIAL_VALUES,
  sanitize,
  useCreateAppointment,
  usePractitionerAppointments,
  useUnbookAppointment,
  useUpdateAppointment,
} from "appointments"
import { ConfirmDialog, LoadingView } from "commons"

import { useEvents } from "../hooks/useEvents"
import { AgendaView } from "./AgendaView"
import { AppointmentCalendar } from "./AppointmentCalendar"
import "./CalendarView.css"

const CalendarView: FC = () => {
  const calendarRef = createRef<FullCalendar>()
  const { appointments, isLoading } = usePractitionerAppointments()

  const {
    initialValues,
    isNew,
    showOverlayForm,
    confirmUnbookItem,
    agendaDate,
    reset,
    add: addAppointmentAction,
    edit,
    selectDate,
    unbookItem,
  } = useReducerState()
  const events = useEvents(appointments ?? [])

  useEffect(() => {
    if (agendaDate) {
      calendarRef.current?.getApi().gotoDate(agendaDate)
      calendarRef.current?.getApi().changeView("agenda")
    }
  }, [agendaDate, calendarRef])

  const { createAppointment } = useCreateAppointment(reset)
  const { updateAppointment } = useUpdateAppointment(reset)
  const { unbookAppointment } = useUnbookAppointment({ onSuccess: reset })

  const getCurrentParticipantDate = useCallback(
    (date: Range, participantId: string) =>
      appointments?.filter(
        (a) =>
          a.appointment.participant.find((i) => i.actor?.id === participantId) &&
          a.appointment.start &&
          areIntervalsOverlapping(
            date,
            {
              start: a.appointment.start,
              end: add(a.appointment.start, { minutes: a.appointment.minutesDuration ?? 0 }),
            },
            { inclusive: true },
          ),
      ),
    [appointments],
  )

  const isParticipantInUse = (date: Date, duration: number, participantId: string) =>
    !!getCurrentParticipantDate({ start: date, end: add(date, { minutes: duration }) }, participantId)?.length

  const editAppointment = (appointment: Appointment) => edit(appointment)

  const getCurrentAppointmentsDate = (date: Date) =>
    events.filter((ev) => ev.start && isSameDay(new Date(ev.start.toString()), date))

  const onSubmit = (appointment: Appointment) => {
    isNew ? createAppointment(sanitize(appointment)) : updateAppointment(sanitize(appointment))
  }
  const onUnbook = (appointment: Appointment) => unbookAppointment(appointment)

  // passes props to AgendaPlugin
  class MorePropsToView {
    transform(viewProps: ViewProps) {
      return {
        ...viewProps,
        action: editAppointment,
        edit: editAppointment,
        unbook: unbookItem,
      }
    }
  }

  const AgendaPlugin = createPlugin({
    name: "AgendaView",
    views: {
      agenda: AgendaView,
    },
    viewPropsTransformers: [MorePropsToView],
  })

  if (isLoading) {
    return <LoadingView />
  }

  return (
    <div className="p-2 bg-white h-full flex">
      <div className="flex-1 pl-2 pt-2">
        <FullCalendar
          ref={calendarRef}
          plugins={[DayGridPlugin, TimeGridPlugin, AgendaPlugin]}
          initialView="agenda"
          events={events}
          headerToolbar={{
            left: "title",
            right: "prev,today,next dayGridMonth timeGridWeek agenda",
          }}
          buttonText={{ month: "Month", week: "Week", agenda: "Agenda" }}
          height="100%"
          eventClick={(evt) => edit(evt.event._def.extendedProps.appointment)}
          eventMouseEnter={(mouseEnterInfo) => {
            const el = mouseEnterInfo?.el
            el.setAttribute("title", mouseEnterInfo?.event?.title)
          }}
        />
      </div>
      <div className="flex flex-col pl-3 flex-none w-2/6 md:w-[35%] 2xl:w-1/4">
        <AppointmentCalendar selectDate={selectDate} currentDateAppointments={getCurrentAppointmentsDate} />
        <Button
          label="Create appointment"
          className="block outline-none ring-0 w-full text-white p-3 mt-4 button-primary"
          onClick={addAppointmentAction}
        />

        <AppointmentFormOverlay
          visible={showOverlayForm}
          isEditing={!isNew}
          isParticipantInUse={isParticipantInUse}
          appointment={initialValues}
          onHide={reset}
          onSubmit={onSubmit}
        />

        <ConfirmDialog
          confirmText={`Are you sure you want to unbook "${confirmUnbookItem?.description}"`}
          actionName="Unbook"
          visible={confirmUnbookItem !== undefined}
          onConfirm={() => onUnbook(confirmUnbookItem as Appointment)}
          hideDialog={reset}
        />
      </div>
    </div>
  )
}

const initialState: State = {
  showOverlayForm: false,
  initialValues: INITIAL_VALUES,
  isNew: false,
  selectedDate: new Date(),
  confirmUnbookItem: undefined,
  agendaDate: undefined,
}

const reducer = (
  state: State,
  { type, payload }: { type: "reset" | "add" | "edit" | "unbook" | "selectDate"; payload?: Date | Appointment },
) => {
  switch (type) {
    case "reset":
      return {
        ...initialState,
        initialValues: { ...initialState.initialValues, start: state.selectedDate },
        selectedDate: state.selectedDate,
      }
    case "add":
      return { ...state, showOverlayForm: true, isNew: true, agendaDate: undefined, confirmUnbookItem: undefined }
    case "edit":
      return {
        ...state,
        showOverlayForm: true,
        initialValues: payload as Appointment,
        isNew: false,
        agendaDate: undefined,
      }
    case "unbook":
      return { ...state, confirmUnbookItem: payload as Appointment }
    case "selectDate":
      return {
        ...state,
        initialValues: { ...state.initialValues, start: payload as Date },
        selectedDate: payload as Date,
        confirmUnbookItem: undefined,
        agendaDate: payload as Date,
      }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ initialValues, isNew, showOverlayForm, agendaDate, confirmUnbookItem }, dispatch] = useReducer(
    reducer,
    initialState,
  )

  const reset = () => {
    dispatch({ type: "reset" })
  }

  const add = () => {
    dispatch({ type: "add" })
  }

  const edit = (appointment: Appointment) => {
    dispatch({ type: "edit", payload: appointment })
  }

  const unbookItem = (appointment: Appointment) => {
    dispatch({ type: "unbook", payload: appointment })
  }

  const selectDate = (selectedDate: Date) => {
    dispatch({ type: "selectDate", payload: selectedDate })
  }

  return {
    initialValues,
    isNew,
    showOverlayForm,
    confirmUnbookItem,
    reset,
    add,
    edit,
    selectDate,
    unbookItem,
    agendaDate,
  }
}

type State = {
  showOverlayForm: boolean
  initialValues: Appointment
  isNew: boolean
  selectedDate: Date
  confirmUnbookItem?: Appointment
  agendaDate?: Date
}

type Range = {
  start: Date
  end: Date
}

export { CalendarView }
