import { ModulesId, SubModule } from "commons/Module"

import { MCDetailsContainer } from "../../../mc/components/details/MCDetailsContainer"

class MCDetailsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.MC_DETAILS
    this.parent = ModulesId.MC
    this.display = "Details"
    this.content = <MCDetailsContainer />
  }
}

export { MCDetailsSubModule }
