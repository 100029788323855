import { ModulesId, SubModule } from "commons/Module"
import { CheckoutConfig } from "orders"

class ConfigSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.CHECKOUT_CONFIG
    this.parent = ModulesId.CHECKOUT
    this.content = <CheckoutConfig />
    this.display = "Config"
  }
}

export { ConfigSubModule }
