const ordersQueryKeys = {
  all: ["RequestGroup"] as const,
  list: (patientId?: string) => [...ordersQueryKeys.all, patientId],
  count: {
    all: ["RequestGroupCount"] as const,
    withPatientId: (patientId: string) => [...ordersQueryKeys.count.all, patientId],
  },
  discounts: ["discounts"],
  extrasPrices: (itemList: { resourceType: string; id: string }[]) => [
    "extras-prices",
    ...(itemList.length ? itemList : []),
  ],
}

export { ordersQueryKeys }
