import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Patient } from "fhir"
import { useEffect, useId, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import {
  Button,
  FooterActions,
  InfiniteScroll,
  ModulesId,
  SkeletonLoader,
  Slideover,
  StackedListContainer,
} from "commons"
import { useOpenEncounter } from "encounter"
import { useAppModuleContext } from "internals"
import { usePrintQuestionnaire } from "mc/hooks"
import { usePatientContext } from "patients"
import { openLinkInNewTab } from "utils"

import { SurveyStatus, surveyStatusCodes } from "../data"
import { useAssignQuestionnaire, useQuestionnaireResponses } from "../hooks"
import { QuestionnaireList } from "./QuestionnaireList"
import { surveyModelBuilder } from "./surveyModelBuilder"

const PatientSurveys = () => {
  const loaderKey = useId()
  const { patientId, patient } = usePatientContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const { activeSubModule, setActiveSubModule, setSearchData, searchData } = useChartContext()
  const { appSubModules, appModules } = useAppModuleContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const [showQuestionnaireList, showQuestionnaires] = useState(false)

  const { questionnaireData, isLoading, hasNextPage, fetchNextPage } = useQuestionnaireResponses({
    patientId,
    key: "WK",
    status: searchData.selectedStatus,
    searchText: searchData.filter,
  })

  const { assignQuestionnaire, isAssigning } = useAssignQuestionnaire()

  useEffect(() => {
    let subModule = null
    const subviewParam = searchParams.get("qrId")

    if (subviewParam) subModule = appSubModules["survey"][ModulesId.SURVEY_DETAILS]

    setActiveSubModule(subModule)
  }, [searchParams, appSubModules, setActiveSubModule])

  const isAssignedQ = (qId: string) => {
    return questionnaireData
      ? questionnaireData.filter(
          ({ questionnaire, qResponse }) => questionnaire.id === qId && qResponse?.status === "in-progress",
        ).length > 0
      : false
  }

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const showSelectedQR = (id: string) => {
    searchParams.set("qrId", id)
    setSearchParams(searchParams)
  }

  const { printQuestionnaire, isLoadingPrint } = usePrintQuestionnaire(openLinkInNewTab, () => setScopedPrintItem(""))

  const printQR = (carePlanQRId: string) => {
    setScopedPrintItem(carePlanQRId)
    printQuestionnaire(carePlanQRId)
  }
  const [scopedPrintItem, setScopedPrintItem] = useState("")

  useEffect(() => {
    if (!activeSubModule) {
      setSearchData({
        placeholder: "Search questionnaires",
        selectedStatus: [SurveyStatus.progress, SurveyStatus.completed],
        statusOptions: surveyStatusCodes,
      })
    }
  }, [activeSubModule])

  if (activeSubModule) {
    return activeSubModule.renderContent()
  }

  const addOptions = [
    {
      label: "Assign a New Questionnaire",
      command: () => showQuestionnaires(true),
    },
  ]

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      {isLoading ? (
        loader()
      ) : (
        <>
          {!questionnaireData?.length ? (
            <div className="flex flex-col h-full items-center justify-center">
              <FontAwesomeIcon icon={appModules[ModulesId.SURVEY].getIcon()} size="2x" className="text-slate-400" />
              <h3 className="mt-2 text-sm font-semibold text-gray-900">No questionnaires found</h3>
              <p className="mt-1 text-sm text-gray-500">Get started by assigning a new questionnaire</p>
              <div className="mt-6">
                <Button icon={faPlus} label="Assign a questionnaire" onClick={() => showQuestionnaires(true)} />
              </div>
            </div>
          ) : (
            <>
              <div className="bg-white h-full overflow-auto mr-1">
                <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
                  <StackedListContainer
                    data={questionnaireData}
                    itemModelBuilder={(item) =>
                      surveyModelBuilder({
                        item,
                        onClick: item.qResponse?.id ? () => showSelectedQR(item.qResponse?.id as string) : undefined,
                        showAsExternal: false,
                        showMenuActions: true,
                        print: printQR,
                        loading: isLoadingPrint && scopedPrintItem === item.qResponse?.id,
                      })
                    }
                  />
                </InfiniteScroll>
              </div>
              {!showQuestionnaireList && <FooterActions actions={addOptions} />}
            </>
          )}
          <Slideover
            showSlide={showQuestionnaireList}
            onHide={() => showQuestionnaires(false)}
            title="Available Questionnaires"
            showCancel
            cancelLabel="Close"
            showCloseIcon
          >
            <QuestionnaireList
              onAssign={(questionnaire) =>
                assignQuestionnaire({ questionnaire, patient: patient as Patient, openEncounter: openEncounterRef })
              }
              onClose={() => showQuestionnaires(false)}
              checkQAssigned={isAssignedQ}
              isAssigning={isAssigning}
            />
          </Slideover>
        </>
      )}
    </div>
  )
}

export { PatientSurveys }
