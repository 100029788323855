import { Observation, Reference } from "fhir"
import * as Yup from "yup"

import { vitalSignCodes, vitalSignUnits } from "data"
import { SYSTEM_VALUES } from "system-values"

import { VITAL_CODE, VitalNames, VitalsFormType } from "../types"

const initialValues: VitalsFormType = {
  height: undefined,
  weight: undefined,
  heartRate: undefined,
  respiratoryRate: undefined,
  oxygenSaturation: undefined,
  temperature: undefined,
  bloodPressureSystolic: undefined,
  bloodPressureDiastolic: undefined,
}

const getDefaultVitalValues = (
  patient: Reference,
  practitionerRoleRef?: Reference,
  encounterRef?: Reference,
): Observation => ({
  code: { coding: undefined },
  status: "preliminary",
  category: [
    {
      coding: [
        {
          code: VITAL_CODE,
          system: SYSTEM_VALUES.OBSERVATION_CATEGORY,
          display: "Vital Signs",
        },
      ],
    },
  ],
  effective: { dateTime: undefined },
  subject: patient,
  performer: practitionerRoleRef ? [practitionerRoleRef] : undefined,
  value: { Quantity: { unit: undefined, value: undefined } },
  encounter: encounterRef,
})

const sanitize = ({ ...observation }: Observation) => {
  observation.effective = { dateTime: new Date().toISOString() }

  if (!observation.performer?.length) delete observation.performer
  if (!observation.value?.Quantity?.unit || !observation.value?.Quantity?.value) delete observation.value

  return observation
}

const getVital = (
  vital: VitalNames,
  value: number,
  patient: Reference,
  practitionerRoleRef?: Reference,
  encounterRef?: Reference,
): Observation => {
  return sanitize({
    ...getDefaultVitalValues(patient, practitionerRoleRef, encounterRef),
    ...{
      code: vitalSignCodes[vital],
      value: { Quantity: { unit: vitalSignUnits[vital], value } },
    },
  })
}

const getVitals = (
  values: VitalsFormType,
  patient: Reference,
  practitionerRoleRef?: Reference,
  encounterRef?: Reference,
) => {
  const { bloodPressureDiastolic, bloodPressureSystolic, ...restVitals } = values

  const vitals = Object.entries(restVitals).reduce<Observation[]>((vitals, [vitalCode, value]) => {
    if (!isNaN(value)) {
      const vital = getVital(vitalCode as VitalNames, value, patient, practitionerRoleRef, encounterRef)

      return [...vitals, vital]
    }

    return vitals
  }, [])

  if (bloodPressureSystolic !== undefined && bloodPressureDiastolic !== undefined) {
    const preassureVital: Observation = {
      ...getDefaultVitalValues(patient, practitionerRoleRef, encounterRef),
      ...{
        code: vitalSignCodes["bloodPressure"],
        component: [
          {
            code: vitalSignCodes["bloodPressureSystolic"],
            value: {
              Quantity: {
                unit: vitalSignUnits["bloodPressureSystolic"],
                value: bloodPressureSystolic,
              },
            },
          },
          {
            code: vitalSignCodes["bloodPressureDiastolic"],
            value: {
              Quantity: {
                unit: vitalSignUnits["bloodPressureDiastolic"],
                value: bloodPressureDiastolic,
              },
            },
          },
        ],
      },
    }

    vitals.push(preassureVital)
  }

  return vitals
}

const vitalCodes = [
  "height",
  "weight",
  "heartRate",
  "respiratoryRate",
  "oxygenSaturation",
  "temperature",
  "bloodPressureSystolic",
  "bloodPressureDiastolic",
]

const MIN_FEET = 3
const MAX_FEET = 8
const MIN_INCH = 1
const MAX_INCH = 11

const getConstrainsRelations = (): [string, string][] => {
  const constrains: [string, string][] = []

  for (let i = 0; i < vitalCodes.length; i++) {
    for (let j = i; j < vitalCodes.length; j++) {
      if (i === j) continue

      constrains.push([`${vitalCodes[i]}`, `${vitalCodes[j]}`])
    }
  }

  return constrains
}
const validationSchema = Yup.object().shape(
  {
    height: Yup.number()
      .nullable()
      .positive("Height must be a positive number")
      .test("test-height", "", (value, context) => {
        if (!value) return true

        const valueString = value.toString()
        const integerPart = valueString.split(".")[0] ?? ""
        const decimalPart = valueString.split(".")[1] ?? ""

        const integerValue = integerPart ? parseInt(integerPart) : 0
        const decimalValue = decimalPart ? parseInt(decimalPart) : 0

        const commonErrorText = "Invalid height. Please enter a value between "
        const feetRangeText = `${MIN_FEET} and ${MAX_FEET} feet`
        const inchesRangeText = `${MIN_INCH} and ${MAX_INCH} inches`

        const isValidFeet = integerValue >= MIN_FEET && integerValue <= MAX_FEET
        const isValidInches = decimalValue >= MIN_INCH && decimalValue <= MAX_INCH

        if (decimalPart.startsWith("0") && decimalPart.length > 1) {
          return context.createError({
            message: "Invalid height. Wrong inches value format, leading zero is not allowed",
          })
        }

        if (!isValidFeet) {
          return context.createError({ message: commonErrorText + feetRangeText })
        }

        if (!isValidInches) {
          return context.createError({ message: commonErrorText + inchesRangeText })
        }

        return true
      })
      .when(
        vitalCodes.filter((code) => code !== "height"),
        {
          is: (
            weight: number,
            heartRate: number,
            respiratoryRate: number,
            oxygenSaturation: number,
            temperature: number,
            bloodPressureSystolic: number,
            bloodPressureDiastolic: number,
          ) => {
            return (
              !weight &&
              !heartRate &&
              !respiratoryRate &&
              !oxygenSaturation &&
              !temperature &&
              !bloodPressureSystolic &&
              !bloodPressureDiastolic
            )
          },
          then: Yup.number().nullable().required("At least one vital is required"),
        },
      ),
    weight: Yup.number()
      .nullable()
      .positive("Weight must be a positive number")
      .when(
        vitalCodes.filter((code) => code !== "weight"),
        {
          is: (
            height: number,
            heartRate: number,
            respiratoryRate: number,
            oxygenSaturation: number,
            temperature: number,
            bloodPressureSystolic: number,
            bloodPressureDiastolic: number,
          ) => {
            return (
              !height &&
              !heartRate &&
              !respiratoryRate &&
              !oxygenSaturation &&
              !temperature &&
              !bloodPressureSystolic &&
              !bloodPressureDiastolic
            )
          },
          then: Yup.number().nullable().required("At least one vital is required"),
        },
      ),
    heartRate: Yup.number()
      .nullable()
      .positive("Heart Rate must be a positive number")
      .when(
        vitalCodes.filter((code) => code !== "heartRate"),
        {
          is: (
            height: number,
            weight: number,
            respiratoryRate: number,
            oxygenSaturation: number,
            temperature: number,
            bloodPressureSystolic: number,
            bloodPressureDiastolic: number,
          ) => {
            return (
              !weight &&
              !height &&
              !respiratoryRate &&
              !oxygenSaturation &&
              !temperature &&
              !bloodPressureSystolic &&
              !bloodPressureDiastolic
            )
          },
          then: Yup.number().nullable().required("At least one vital is required"),
        },
      ),
    respiratoryRate: Yup.number()
      .nullable()
      .positive("Respiratory Rate must be a positive number")
      .when(
        vitalCodes.filter((code) => code !== "respiratoryRate"),
        {
          is: (
            height: number,
            weight: number,
            heartRate: number,
            oxygenSaturation: number,
            temperature: number,
            bloodPressureSystolic: number,
            bloodPressureDiastolic: number,
          ) => {
            return (
              !weight &&
              !height &&
              !heartRate &&
              !oxygenSaturation &&
              !temperature &&
              !bloodPressureSystolic &&
              !bloodPressureDiastolic
            )
          },
          then: Yup.number().nullable().required("At least one vital is required"),
        },
      ),
    oxygenSaturation: Yup.number()
      .nullable()
      .positive("Oxygen Saturation must be a positive number")
      .when(
        vitalCodes.filter((code) => code !== "oxygenSaturation"),
        {
          is: (
            height: number,
            weight: number,
            heartRate: number,
            respiratoryRate: number,
            temperature: number,
            bloodPressureSystolic: number,
            bloodPressureDiastolic: number,
          ) => {
            return (
              !weight &&
              !height &&
              !heartRate &&
              !respiratoryRate &&
              !temperature &&
              !bloodPressureSystolic &&
              !bloodPressureDiastolic
            )
          },
          then: Yup.number().nullable().required("At least one vital is required"),
        },
      ),
    temperature: Yup.number()
      .nullable()
      .positive("Temperature must be a positive number")
      .when(
        vitalCodes.filter((code) => code !== "temperature"),
        {
          is: (
            height: number,
            weight: number,
            heartRate: number,
            respiratoryRate: number,
            oxygenSaturation: number,
            bloodPressureSystolic: number,
            bloodPressureDiastolic: number,
          ) => {
            return (
              !weight &&
              !height &&
              !heartRate &&
              !respiratoryRate &&
              !oxygenSaturation &&
              !bloodPressureSystolic &&
              !bloodPressureDiastolic
            )
          },
          then: Yup.number().nullable().required("At least one vital is required"),
        },
      ),
    bloodPressureSystolic: Yup.number()
      .nullable()
      .positive("Blood Pressure Systolic must be a positive number")
      .when(
        vitalCodes.filter((code) => code !== "bloodPressureSystolic"),
        {
          is: (
            height: number,
            weight: number,
            heartRate: number,
            respiratoryRate: number,
            oxygenSaturation: number,
            temperature: number,
            bloodPressureDiastolic: number,
          ) => {
            return (
              !weight &&
              !height &&
              !heartRate &&
              !respiratoryRate &&
              !oxygenSaturation &&
              !temperature &&
              !bloodPressureDiastolic
            )
          },
          then: Yup.number().nullable().required("At least one vital is required"),
        },
      )
      .when(["bloodPressureDiastolic"], {
        is: (bloodPressureDiastolic: number) => {
          return !!bloodPressureDiastolic
        },
        then: Yup.number().nullable().required("Blood Pressure Systolic is required"),
      }),
    bloodPressureDiastolic: Yup.number()
      .nullable()
      .positive("Blood Pressure Diastolic must be a positive number")
      .when(
        vitalCodes.filter((code) => code !== "bloodPressureDiastolic"),
        {
          is: (
            height: number,
            weight: number,
            heartRate: number,
            respiratoryRate: number,
            oxygenSaturation: number,
            temperature: number,
            bloodPressureSystolic: number,
          ) => {
            return (
              !weight &&
              !height &&
              !heartRate &&
              !respiratoryRate &&
              !oxygenSaturation &&
              !temperature &&
              !bloodPressureSystolic
            )
          },
          then: Yup.number().nullable().required("At least one vital is required"),
        },
      )
      .when(["bloodPressureSystolic"], {
        is: (bloodPressureSystolic: number) => {
          return !!bloodPressureSystolic
        },
        then: Yup.number().nullable().required("Blood Pressure Diastolic is required"),
      }),
  },
  getConstrainsRelations(),
)

export { getDefaultVitalValues, getVital, getVitals, initialValues, sanitize, validationSchema }
