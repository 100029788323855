import { FC, PropsWithChildren } from "react"
import { Sidebar } from "primereact/sidebar"
import { Button as PButton } from "primereact/button"
import { classNames } from "primereact/utils"

import { Button } from "./Buttons"

const Slideover: FC<PropsWithChildren<SlideoverProps>> = ({
  showSlide,
  onHide,
  width = "35%",
  height = "calc(100% - 9.5rem)",
  position = "right",
  showCloseIcon = false,
  dismissable = false,
  title,
  children,
  className,
  childrenContainerClassName = "px-4 sm:px-6 space-y-6 pb-6 pt-1",
  showCancel,
  showAccept,
  showButtons,
  cancelLabel = "Close",
  acceptLabel = "Accept",
  acceptLoading,
  acceptDisabled,
  onAccept,
}) => (
  <Sidebar
    visible={showSlide}
    position={position}
    style={{ minWidth: width, height }}
    onHide={onHide}
    className={classNames("absolute bottom-0", className)}
    maskClassName="slideover"
    dismissable={dismissable}
  >
    {title || showCancel || showAccept || showButtons || showCloseIcon ? (
      <div className="bg-white divide-gray-200 divide-y flex flex-col h-full">
        <div className="flex flex-1 flex-col overflow-hidden">
          <div className={classNames("flex items-center", title ? "justify-between" : "justify-end py-2")}>
            {title && (
              <div className="px-4 sm:px-6 py-6">
                <h2 className="text-lg font-semibold leading-6 text-gray-900">{title}</h2>
              </div>
            )}
            {showCloseIcon && (
              <PButton
                icon="pi pi-times"
                className="bg-white hover:bg-gray-200 border-0 text-gray-500 rounded-full w-8 h-8 mr-4 focus:primary-ring-shadow"
                onClick={onHide}
              />
            )}
          </div>
          <div className="flex flex-1 flex-col overflow-y-auto">
            <div className={childrenContainerClassName}>{children}</div>
          </div>
        </div>
        {(showButtons || showCancel || showAccept) && (
          <div className="flex flex-shrink-0 justify-end gap-3 px-4 py-4">
            {(showButtons || showCancel) && (
              <Button label={cancelLabel} buttonStyle="default" size="lg" onClick={onHide} />
            )}
            {(showButtons || showAccept) && (
              <Button
                label={acceptLabel}
                size="lg"
                loading={acceptLoading}
                disabled={acceptDisabled}
                onClick={onAccept}
              />
            )}
          </div>
        )}
      </div>
    ) : (
      children
    )}
  </Sidebar>
)

export type SlideoverProps = {
  showSlide: boolean
  onHide(): void
  width?: string
  height?: string
  position?: "top" | "bottom" | "left" | "right" | undefined
  showCloseIcon?: boolean
  dismissable?: boolean
  title?: string
  className?: string
  childrenContainerClassName?: string
  showCancel?: boolean
  showAccept?: boolean
  showButtons?: boolean
  cancelLabel?: string
  acceptLabel?: string
  acceptLoading?: boolean
  acceptDisabled?: boolean
  onAccept?(): void
}

export { Slideover }
