import { useContext } from "react"

import { NetworkContext } from "../context/NetworkContext"

const useCheckNetworkContext = () => {
  const context = useContext(NetworkContext)

  if (context === undefined) {
    throw new Error("useCheckNetworkContext must be used within a NetworkContextProvider")
  }

  return context
}

export { useCheckNetworkContext }
