import { faEdit } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Practitioner } from "fhir"
import { FormikHelpers, useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { useEffect, useMemo, useState } from "react"

import { EmailField } from "commons/forms/EmailField"
import { useReplaceFormContext, useUpdateUserEmail } from "commons/hooks"
import { useLoginContext } from "security"

const ProfileEmailField = ({ label, email, className, horizontal, floatLabel }: Props) => {
  const { loggedInPractitionerId } = useLoginContext()
  const [showForm, setShowForm] = useState(false)
  const {
    initialValues: { telecom },
  } = useFormikContext<Partial<Practitioner>>()
  const formReplaceContext = useReplaceFormContext<Partial<Practitioner>>()

  const { updateEmail, isPending } = useUpdateUserEmail(undefined, () => {
    onCancel()
  })

  const onSubmit = ({ telecom }: Partial<Practitioner>, formikHelpers: FormikHelpers<Partial<Practitioner>>) => {
    updateEmail({
      resourceId: loggedInPractitionerId,
      resourceType: "Practitioner",
      email: telecom?.[0]?.value as string,
    })

    formikHelpers.setSubmitting(isPending)
  }
  const onCancel = () => setShowForm(false)

  const replacementFormProps = useMemo(
    () => ({
      title: label,
      showForm: showForm,
      useFormik: true,
      initialValue: { telecom },
      saveLabel: "Update",
      children: (
        <EmailField
          className="py-3"
          field="telecom[0].value"
          validateRules
          initialValue={telecom?.[0].value}
          validateDuplicate
        />
      ),
      showCloseIcon: false,
      onCancel,
      onSubmit: onSubmit,
    }),
    [showForm, telecom, onSubmit],
  )

  useEffect(() => {
    if (showForm) formReplaceContext?.setReplacementContent?.(replacementFormProps)
    else formReplaceContext?.setShowReplacementContent?.(false)
  }, [replacementFormProps])

  return (
    <div
      className={classNames(
        "field relative",
        {
          "inline-flex justify-between": horizontal,
          "float-label relative block mt-1": floatLabel,
          "flex flex-col": !horizontal && !floatLabel,
        },
        className,
      )}
    >
      {label && !floatLabel && (
        <label className={classNames("text-sm font-medium text-gray-700 mb-2", { "mr-3 mb-0 mt-2": horizontal })}>
          {label}
        </label>
      )}

      <div className="flex  items-center border-solid border-2 border-slate-200/90 rounded-md p-2.5 text-xs ">
        <span className="mr-2 opacity-60 flex flex-grow ">{email}</span>

        <div className="hover:text-gray-500 text-primary cursor-pointer">
          <button type="button" onClick={() => setShowForm(true)}>
            <span>Edit email </span>
            <FontAwesomeIcon icon={faEdit} className="text-base ml-1" />
          </button>
        </div>
      </div>

      {label && floatLabel && (
        <label className="text-sm text-gray-400 left-3 top-2 absolute transition-all ease-in-out">{label}</label>
      )}
    </div>
  )
}

type Props = {
  label?: string
  email?: string
  className?: string
  horizontal?: boolean
  floatLabel?: boolean
}

export { ProfileEmailField }
