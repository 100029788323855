const consentsQueryKeys = {
  all: ["patient/consents"] as const,
  list: (patientId: string) => [...consentsQueryKeys.all, patientId],
  availables: (patientId: string, gender?: string) => [
    "patient/available-consents",
    patientId,
    ...(gender ? [gender] : []),
  ],
}

export { consentsQueryKeys }
