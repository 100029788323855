import { faPills } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"

import { MedicationOrderListItem, usePrescriptionMrOrder } from "commons/meds"
import { MedicationRequestOrderData } from "commons/types"

const MedicationOrderHistoryList = ({ mrOrderData }: Props) => {
  const [params, setParams] = useSearchParams()
  const [selectedOrder, setSelectedOrder] = useState<MedicationRequestOrderData | undefined>()
  const { getPrescription, isGettingPrescription } = usePrescriptionMrOrder()

  const goToOrderDetails = (orderId: string) => {
    params.append("order", orderId)
    setParams(params)
  }

  const onPrescription = (order: MedicationRequestOrderData) => {
    setSelectedOrder(order)
    getPrescription({ serviceRequestId: order.serviceRequest.id as string })
  }

  const activeActions = (mrOrderData: MedicationRequestOrderData) => [
    {
      label: "Prescription",
      icon: <FontAwesomeIcon icon={faPills} size="sm" className="mr-2" />,
      command: () => onPrescription(mrOrderData),
    },
  ]

  return (
    <div className="overflow-hidden bg-white">
      <ul className="divide-y divide-gray-200">
        {mrOrderData.map((orderData) => (
          <MedicationOrderListItem
            key={orderData.serviceRequest.id}
            mrOrderData={orderData}
            onItemClicked={() => goToOrderDetails(orderData.serviceRequest.id as string)}
            isLoading={selectedOrder?.serviceRequest.id === orderData.serviceRequest.id && isGettingPrescription}
            dropdownMenuItems={activeActions}
          />
        ))}
      </ul>
    </div>
  )
}

type Props = {
  mrOrderData: MedicationRequestOrderData[]
}

export { MedicationOrderHistoryList }
