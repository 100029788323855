import { UserProfileMenu } from "commons"
import { OrganizationSelection } from "organization"

import bg from "assets/bg.webp"

const Home: React.FC = () => {
  document.title = "Electronic Health Record"
  const link = document.querySelector<HTMLLinkElement>("link[rel~='icon']")
  if (link) link.href = "/favicon.ico"
  document.body.removeAttribute("style")

  return (
    <div className="flex items-center justify-center w-full h-full bg-white">
      <img alt="bg" src={bg} className="object-cover h-full w-full" />
      <div className="flex h-auto w-auto items-center cursor-pointer absolute top-5 right-5 shadow-md rounded-full">
        <UserProfileMenu />
      </div>
      <div className="flex flex-col justify-center absolute bg-white min-w-[25%] xl:min-w-[30%] rounded-xl shadow-sm shadow-gray-600">
        <div className="font-bold bg-gray-50 rounded-t-xl h-16 flex items-center justify-center">
          Organization selection
        </div>
        <div className="px-20 pb-16 pt-10">
          <div className="mt-8 xl:mt-14 w-full">
            <OrganizationSelection />
          </div>
        </div>
        <div className="flex mb-3 justify-center">
          <a
            title="Policy & Terms"
            href="https://www.chartedhealth.com/156-2/"
            rel="noreferrer"
            target="_blank"
            className="text-blue-400 font-light hover:text-blue-500/90 hover:underline"
          >
            Privacy Policy & Terms of usage
          </a>
        </div>
      </div>
    </div>
  )
}

export { Home }
