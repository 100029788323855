import { FC, useEffect } from "react"

import { useAppModuleContext } from "internals"
import { useMCReportUrl } from "mc/hooks"
import { openLinkInNewTab } from "utils"

import { ModulesId } from "../../../Module"
import { Button } from "../../../components/Buttons"
import { EmptyMessage } from "../../../components/EmptyMessage"
import { SkeletonLoader } from "../../../components/SkeletonLoader"
import { StackedListContainer } from "../../../components/StackedListContainer"
import { useCarePlanAssessments, usePlanContext } from "../../hooks"
import { assessmentItemModel } from "./assessmentItemModel"

const CPAssessmentsDetails: FC<Props> = ({
  patientId,
  mcAssessments,
  showAssessment,
  applyAssessment,
  disabled,
  isGeneratingAssessment,
  isGeneratingAssessmentReport,
  isLoading,
}) => {
  const { appModules } = useAppModuleContext()
  const { assessments, isLoading: isLoadingAssessments } = useCarePlanAssessments(patientId, mcAssessments)
  const { setCompleteDisabled } = usePlanContext()

  const { getReportUrl, isLoadingUrl } = useMCReportUrl(openLinkInNewTab)

  const downloadResult = (carePlanId: string) => {
    getReportUrl({ carePlanId })
  }

  useEffect(() => {
    if (assessments?.length && !assessments?.every(({ assessment }) => assessment.status === "completed")) {
      setCompleteDisabled(true)
    }
  }, [assessments])

  if (isLoading || isLoadingAssessments) return <SkeletonLoader repeats={3} loaderType="two-lines" />
  return (
    <div className="flex flex-col p-3 space-y-3">
      {assessments?.length ? (
        <div className="space-y-4">
          <StackedListContainer
            data={assessments ?? []}
            itemModelBuilder={(item) =>
              assessmentItemModel(
                item,
                () => showAssessment(item.assessment.id as string),
                downloadResult,
                isLoadingUrl,
              )
            }
          />
          {!!applyAssessment && (
            <div className="pl-1 flex flex-col gap-1 items-end">
              <Button
                icon={appModules[ModulesId.MC].getIcon()}
                label={
                  isGeneratingAssessment
                    ? "Generating new assessment..."
                    : isGeneratingAssessmentReport
                      ? "Generating new assessment report..."
                      : "Generate new assessment"
                }
                onClick={applyAssessment}
                disabled={disabled}
                className="max-w-fit"
                loading={isGeneratingAssessment || isGeneratingAssessmentReport}
              />
              {isGeneratingAssessmentReport && (
                <span className="text-xs text-gray-400 font-medium pt-1 pl-[0.4rem] -indent-[0.4rem] text-right">
                  &#42;Please wait a few minutes while the new assessment report is being generated. You will be
                  redirected shortly. <br />
                  Due to the heavy load of operations it goes into these, this process may take some time.
                </span>
              )}
            </div>
          )}
        </div>
      ) : (
        <EmptyMessage
          message="No assessments created yet"
          subMessage={disabled && "Start by answering questionaries to generate new assessments"}
          icon={appModules[ModulesId.MC].getIcon()}
          actionText={applyAssessment ? "Generate new assessment" : undefined}
          action={applyAssessment}
          disabled={disabled}
          isLoading={isGeneratingAssessment}
        />
      )}
    </div>
  )
}

type Props = {
  patientId: string
  mcAssessments?: string[]
  disabled?: boolean
  isLoading?: boolean
  isGeneratingAssessment?: boolean
  isGeneratingAssessmentReport?: boolean
  showAssessment(planId: string): void
  applyAssessment?(): void
}

export { CPAssessmentsDetails }
