import { faFilePdf } from "@fortawesome/pro-solid-svg-icons"
import { useState } from "react"

import { Button, useShowSignedUrlDocument } from "commons"

import { TriadData } from "../../types"
import { hasObservationValue } from "../../utils"
import { TriadLabDetails } from "./TriadLabDetails"
import { TriadFilter } from "./TriadFilter"

const LabsView = ({ triadsData, labsResultPDFInfo }: Props) => {
  const [disabledTriads, setDisabledTriads] = useState<Record<string, boolean>>({})
  const { showDocument: showResults, loading: loadingResults } = useShowSignedUrlDocument()
  const triadsWithValues = triadsData.filter(({ organs }) =>
    organs.some(({ labData }) => labData.some(hasObservationValue)),
  )
  const filterTriadsIds = triadsWithValues.map(({ id }) => id)

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      {labsResultPDFInfo.hasAttachedPDF && (
        <div className="flex justify-end ml-48 mr-6 mt-4">
          <Button
            onClick={() => showResults(labsResultPDFInfo.url)}
            label="Download results"
            icon={faFilePdf}
            disabled={loadingResults}
            loading={loadingResults}
          />
        </div>
      )}
      <TriadFilter {...{ disabledTriads, setDisabledTriads }} filterTriadsIds={filterTriadsIds} />
      <div className="flex flex-col overflow-y-auto gap-4 py-4">
        {triadsWithValues.map((triad) => !disabledTriads[triad.id] && <TriadLabDetails key={triad.id} triad={triad} />)}
      </div>
    </div>
  )
}

type Props = {
  triadsData: TriadData[]
  labsResultPDFInfo: {
    hasAttachedPDF: boolean
    url: string | undefined
  }
}

export { LabsView }
