import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Condition } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { conditionsQueryKeys } from "../query-keys"

const useUpdateCondition = (hideForm: () => void) => {
  const { update } = useClient()
  const queryClient = useQueryClient()

  const newCondition = async (condition: Condition) => update("Condition", condition.id as string, condition)

  const { mutate: updateCondition, isPending: isUpdating } = useMutation({
    mutationFn: newCondition,
    onError: async (error: CustomError, context) => {
      if (!!error.cause?.name && ["409", "412"].includes(error.cause.name)) {
        // Conflict error or precondition failed error are thrown when the resource has been modified by someone
        queryClient.refetchQueries({ queryKey: conditionsQueryKeys.all })
      }
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { subject }) => {
      queryClient.refetchQueries({
        queryKey: subject.id ? conditionsQueryKeys.list(subject.id) : conditionsQueryKeys.all,
        type: subject.id ? "all" : undefined,
      })
      displayNotificationSuccess("Condition updated successfully!")
      // datadogLogs.logger.info(`Condition on ${subject.id} updated successfully!`)
    },
    onSettled: hideForm,
  })

  return { updateCondition, isUpdating }
}

export { useUpdateCondition }
