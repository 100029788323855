import { useQuery } from "@tanstack/react-query"
import { Composition, getResources } from "fhir"

import { useClient } from "api"

import { proceduresQueryKeys } from "../query-keys"

const useProcedureCatalogs = () => {
  const { search } = useClient()
  const queryKey = proceduresQueryKeys.catalogs

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        type: "catalog",
        category: "medication,procedure",
        "category:not": "valid-for-individual-use",
      })

      const bundle = await search({ endpoint: `Composition`, filters, signal })

      const catalogs = getResources<Composition>(bundle)

      return { catalogs, count: catalogs.length }
    },

    meta: { context: { queryKey } },
  })

  return {
    catalogs: data?.catalogs ?? [],
    count: data?.count ?? 0,
    isLoading,
  }
}

export { useProcedureCatalogs }
