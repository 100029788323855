const socialHistoryQueryKeys = {
  all: ["patient/social-history"],
  withPatientId: (patientId: string, encounter?: string) => [
    ...socialHistoryQueryKeys.all,
    patientId,
    ...(encounter ? [encounter] : []),
  ],
}

export { socialHistoryQueryKeys }
