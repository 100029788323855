import { faFileInvoiceDollar } from "@fortawesome/pro-regular-svg-icons"

import { AppModule, ModulesId } from "commons/Module"

class InvoiceModule extends AppModule {
  constructor(workspaceElement: JSX.Element, kpElement: JSX.Element) {
    super()
    this.id = ModulesId.INVOICE
    this.display = "Invoices"
    this.displayForKP = "Issued Invoices"
    this.icon = faFileInvoiceDollar
    this.kpElement = kpElement
    this.workspaceElement = workspaceElement
  }
}

export { InvoiceModule }
