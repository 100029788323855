import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Parameters, asReference } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { MEDICATION_CATALOG, MEDICATION_PRODUCT_TYPE } from "data"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { useOrganizationContext } from "organization"
import { SYSTEM_VALUES } from "system-values"
import { displayNotificationSuccess } from "utils"

import { settingsQueryKeys } from "../../query-keys"
import { MedParams } from "../../types"

const useEditMK = (onSettled?: () => void) => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()
  const { currentOrganization /*location*/ } = useOrganizationContext()

  const editMKMutation = async ({ fee, sku /*inventory, mk*/ }: MedParams) => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "organization",
          value: {
            Reference: asReference(currentOrganization),
          },
        },
        {
          name: "fee",
          value: {
            decimal: fee ?? 0,
          },
        },
        {
          name: "sku",
          value: {
            Coding: {
              code: sku ?? "",
              system: SYSTEM_VALUES.SKU,
            },
          },
        },
      ],
    }

    // const params: Parameters = {
    //   resourceType: "Parameters",
    //   parameter: [
    //     {
    //       name: "medication",
    //       value: {
    //         Reference: asReference(mk),
    //       },
    //     },
    //     {
    //       name: "amount",
    //       value: {
    //         decimal: inventory ?? 0,
    //       },
    //     },
    //   ],
    // }

    // await operationRequest({
    //   endpoint: `Location/${location?.id}`,
    //   method: "POST",
    //   operation: "update-inventory-item",
    //   parameters: params,
    // })

    return operationRequest({
      endpoint: "ChargeItemDefinition",
      method: "POST",
      operation: "set-product-prices",
      parameters,
    })
  }

  const { mutate: editMK, isPending } = useMutation({
    mutationFn: editMKMutation,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, newMk) => {
      const category =
        newMk?.mk?.productType?.[0]?.coding?.[0]?.code === MEDICATION_PRODUCT_TYPE.RX
          ? MEDICATION_CATALOG.RX
          : MEDICATION_CATALOG.NUTRA

      await queryClient.invalidateQueries({
        queryKey: settingsQueryKeys.meds({ organizationId: currentOrganization.id as string, category }),
      })

      displayNotificationSuccess("Medication updated successfully!")
    },
    onSettled,
  })

  return { editMK, isUpdating: isPending }
}

export { useEditMK }
