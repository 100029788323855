import { SelectButton } from "primereact/selectbutton"
import { startTransition, useCallback, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { ModulesId, SearchWithStatus } from "commons"
import { PLAN_SUBVIEWS, Plan, PlanContext, PlanProvider } from "commons/care-plans"
import { useAppModuleContext } from "internals"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { planStatusOptions } from "../utils"
import { MCContainerData } from "./MCContainerData"
import { PlanListContainer } from "./PlanListContainer"

const MCContainer = () => {
  const { currentOrganizationId } = useOrganizationContext()
  const { appSubModules } = useAppModuleContext()
  const { patient } = usePatientContext()
  const { setActiveSubModule, activeSubModule } = useChartContext()
  const [params, setParams] = useSearchParams()

  const [selectedPlan, setSelectedPlan] = useState<Plan>()
  const [searchText, setSearchText] = useState("")
  const [statusFilter, setStatusFilter] = useState(["active", "draft", "completed"])

  const updateSubview = useCallback(
    (subviewId?: PLAN_SUBVIEWS, planId?: string) => {
      if (!params.get("context")) params.append("context", PlanContext.MC_ORDERS)
      if (params.get("subview") || params.get("planId")) {
        params.delete("subview")
        params.delete("planId")
      }
      if (subviewId) {
        params.append("subview", subviewId)
      } else {
        params.delete("subview")
        setSelectedPlan(undefined)
      }

      if (planId) {
        params.append("planId", planId)
      } else {
        params.delete("planId")
      }

      setParams(params)
    },
    [params, setParams],
  )

  const activeContent = useMemo(() => params.get("context") ?? PlanContext.MC_ORDERS, [params])

  useEffect(() => {
    let subview = null
    const subviewParam = params.get("subview")

    if (params.get("view") === ModulesId.MC) {
      if (subviewParam) {
        subview = appSubModules["mc"][subviewParam]

        if (!subview) {
          setSelectedPlan(undefined)
        }
      }
    }
    const mcPlanId = params.get("mcPlanId")
    if (mcPlanId || subview) setActiveSubModule(mcPlanId ? appSubModules["mc"][ModulesId.MC_DETAILS] : subview)
    else setActiveSubModule(null)
  }, [params, setActiveSubModule, appSubModules, updateSubview])

  const onUpdateContent = useCallback(
    (content: PlanContext) => {
      if (params.get("context") !== content)
        setParams({
          ...params,
          ...(params.has("kp") ? { kp: params.get("kp") as string } : {}),
          view: ModulesId.MC,
          context: content,
        })
    },
    [params],
  )

  if (activeSubModule)
    return (
      <PlanProvider
        patient={patient}
        currentOrganizationId={currentOrganizationId}
        plan={selectedPlan}
        updateCurrentPlan={(plan) => setSelectedPlan(plan)}
        navigateToSubview={updateSubview}
      >
        {activeSubModule.renderContent()}
      </PlanProvider>
    )

  return (
    <>
      {params.get("context") !== PlanContext.MC_ASSESSMENTS && (
        <div className="inline-flex justify-between border-gray-100 border-b-2 h-16 w-full p-3">
          <SelectButton
            value={activeContent}
            options={[
              { value: PlanContext.MC_ORDERS, name: "Orders" },
              { value: PlanContext.MC, name: "Assessments" },
            ]}
            optionLabel="name"
            optionValue="value"
            allowEmpty={false}
            onChange={(e) => onUpdateContent(e.value)}
          />

          <SearchWithStatus
            placeholder={`Search ${activeContent === PlanContext.MC ? "Assessments" : "Orders"}`}
            options={activeContent === PlanContext.MC_ORDERS ? planStatusOptions(true) : planStatusOptions()}
            selectedItems={statusFilter}
            onStatusCheck={setStatusFilter}
            onSearch={(filter) => {
              startTransition(() => {
                setSearchText(filter ?? "")
              })
            }}
          />
        </div>
      )}
      {activeContent !== PlanContext.MC_ORDERS ? (
        <MCContainerData searchText={searchText} statusFilter={statusFilter} />
      ) : (
        <PlanProvider
          patient={patient}
          currentOrganizationId={currentOrganizationId}
          plan={selectedPlan}
          updateCurrentPlan={(plan) => setSelectedPlan(plan)}
          navigateToSubview={updateSubview}
        >
          <PlanListContainer searchText={searchText} statusFilter={statusFilter} />
        </PlanProvider>
      )}
    </>
  )
}

export { MCContainer }
