import { useState, useEffect, useCallback } from "react"
import { Chart } from "primereact/chart"
import { classNames } from "primereact/utils"

import { formatDate, strCapitalize } from "utils"

import { VitalRecord, ColData } from "../types"

const VitalChart = ({ vitalRecords, vitalName, relatedVitalRecords, relatedVitalName, className }: Props) => {
  const [chartData, setChartData] = useState({})
  const [chartOptions, setChartOptions] = useState({})

  const getVitalValues = useCallback((vitalRecords?: VitalRecord[]) => {
    return vitalRecords?.reduce((acc, vital) => {
      const val = vital?.value?.value

      return [...acc, val ?? 0]
    }, [] as number[])
  }, [])

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement)

    const datasets = [
      {
        label: strCapitalize(vitalName ?? ""),
        tension: 0.2,
        data: getVitalValues(vitalRecords),
        borderColor: documentStyle.getPropertyValue("--gray-600"),
        backgroundColor: documentStyle.getPropertyValue("--gray-600"),
      },
    ]
    if (relatedVitalRecords)
      datasets.push({
        label: strCapitalize(relatedVitalName ?? ""),
        tension: 0.2,
        data: getVitalValues(relatedVitalRecords),
        borderColor: documentStyle.getPropertyValue("--gray-600"),
        backgroundColor: documentStyle.getPropertyValue("--gray-600"),
      })

    const data = {
      labels: vitalRecords?.map((item) => item?.date && formatDate(new Date(item.date), "MM/dd/yyyy")),
      datasets: datasets,
    }
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 16 / 9,
      plugins: { legend: false },
    }

    setChartData(data)
    setChartOptions(options)
  }, [vitalRecords, relatedVitalRecords, vitalName, relatedVitalName, getVitalValues])

  return <Chart type="line" data={chartData} options={chartOptions} className={classNames("flex grow", className)} />
}

type Props = {
  vitalRecords?: VitalRecord[]
  vitalName: keyof ColData
  relatedVitalName?: keyof ColData
  relatedVitalRecords?: VitalRecord[]
  className?: string
}

export { VitalChart }
