import { useInfiniteQuery } from "@tanstack/react-query"
import { parseISO } from "date-fns"
import { DiagnosticReport, Observation, ServiceRequest, codeableConceptAsString, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

import { labOrdersQueryKeys } from "../query-keys"

const useLabObservations = (patientId: string, searchText?: string) => {
  const { search } = useClient()
  const queryKey = labOrdersQueryKeys.observations(patientId, searchText)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<
    LabObservationsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _category: "LAB",
        _count: "20",
        _include: "result, based-on",
        _page: `${pageParam}`,
        _sort: "-date",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/DiagnosticReport`, filters, signal })

      const drs = getResources<DiagnosticReport>(bundle, "DiagnosticReport")
      const observations = getResources<Observation>(bundle, "Observation")
      const orders = getResources<ServiceRequest>(bundle, "ServiceRequest")
      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { diagnosticReports: drs, observations, orders, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  const filterObservations = (observations: Observation[], searchText?: string) => {
    if (searchText) {
      const filteredObservations = observations.flatMap((ob) => {
        if (codeableConceptAsString(ob.code).toLocaleLowerCase().includes(searchText.toLocaleLowerCase())) {
          return ob
        }
        return []
      })
      return filteredObservations
    }

    return observations
  }

  const { observations, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.observations) ?? []
    const orders = data?.pages.flatMap((page) => page.orders)
    const drs = data?.pages.flatMap((page) => page.diagnosticReports)

    const count = newData?.length

    const filteredObservations = filterObservations(newData ?? [], searchText)
    let currentOrder: ServiceRequest | undefined
    let observationDr: DiagnosticReport | undefined

    const obs = filteredObservations
      .sort((a, b) => ((a.effective?.dateTime ?? 0) < (b.effective?.dateTime ?? 0) ? 1 : -1))
      .reduce<Record<string, { observations: Observation[]; order?: string }>>((acc, cur) => {
        if (cur.effective?.dateTime) {
          const date = formatDate(parseISO(cur.effective?.dateTime), formatsByTypes.LONG_DATE)
          observationDr = drs?.find(({ result }) => result?.some(({ id }) => id === cur.id))
          currentOrder =
            currentOrder && currentOrder.id === observationDr?.basedOn?.[0]?.id
              ? currentOrder
              : orders?.find(({ id }) => observationDr?.basedOn?.[0]?.id === id)
          const groupKey = `${currentOrder?.performer?.[0]?.display ?? "Imported Lab Results"} - ${date}`
          const group = acc[groupKey]

          if (observationDr?.status === "final" && cur.value?.Quantity) {
            return {
              ...acc,
              [groupKey]: { observations: [...(group?.observations ?? []), cur], order: currentOrder?.id },
            }
          }
        }
        return acc
      }, {})

    return {
      observations: obs,
      count,
    }
  }, [data?.pages, searchText])

  return {
    observations: Array.from(Object.entries(observations)).map(([groupKey, items]) => ({
      key: groupKey,
      name: groupKey,
      items: items.observations,
      order: items.order,
    })),
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type LabObservationsQueryData = {
  diagnosticReports: DiagnosticReport[]
  observations: Observation[]
  orders: ServiceRequest[]
  next: number | undefined
  total: number
}

export { useLabObservations }
