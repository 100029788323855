import { AppSubModulesTypes, ModulesId } from "commons"

import { CreditCardsSubModule } from "./modules/account/CreditCardsSubModule"
import { AllergySubModule } from "./modules/allergy/AllergySubModule"
import { ConfigSubModule } from "./modules/checkout/ConfigSubModule"
import { ConditionsSubModule } from "./modules/condition/ConditionsSubModule"
import { CoverageSubmodule } from "./modules/coverage/CoverageSubmodule"
import { PrescriptionsNewSubModule } from "./modules/eprescribe/PrescriptionNewSubmodule"
import { PrescriptionOrderDetailsSubModule } from "./modules/eprescribe/PrescriptionOrderDetailsSubModule"
import { PrescriptionOrderEditSubModule } from "./modules/eprescribe/PrescriptionOrderEditSubModule"
import { PrescriptionsDraftSubModule } from "./modules/eprescribe/PrescriptionsDraftSubmodule"
import { FamilyHistorySubModule } from "./modules/family-history/FamilyHistorySubModule"
import { SocialHistorySubModule } from "./modules/family-history/SocialHistorySubModule"
import { InvoiceDetailsSubModule } from "./modules/invoice/InvoiceDetailsSubModule"
import { PaymentDetailsSubModule } from "./modules/invoice/PaymentDetailsSubModule"
import { LaboratoryOrderDetailsSubModule } from "./modules/laboratory/LaboratoryOrderDetailSubmodule"
import { LabsSubModule } from "./modules/laboratory/LabsSubModule"
import { MCDetailsSubModule } from "./modules/mc/MCDetailsSubModule"
import { MCPlanConfigSubModule } from "./modules/mc/MCPlanConfigSubModule"
import { MCPlanDetailsSubModule } from "./modules/mc/MCPlanDetailsSubModule"
import { MCPlanEnrollSubModule } from "./modules/mc/MCPlanEnrollSubModule"
import { MRDraftSubModule } from "./modules/medication/MRDraftSubModule"
import { MRRescheduleSubModule } from "./modules/medication/MRRescheduleSubModule"
import { MedicationESubModule } from "./modules/medication/MedicationESubModule"
import { MedicationOrderDetailsSubModule } from "./modules/medication/MedicationOrderDetailsSubModule"
import { ConsentSubmodule } from "./modules/patient/ConsentSubmodule"
import { ContactsSubModule } from "./modules/patient/ContactsSubModule"
import { DocumentsSubModule } from "./modules/patient/DocumentsSubModule"
import { Allergies360SubModule } from "./modules/patient360/Allergies360Submodule"
import { Conditions360SubModule } from "./modules/patient360/Conditions360Submodule"
import { FamilyHistory360SubModule } from "./modules/patient360/FamilyHistory360Submodule"
import { Labs360SubModule } from "./modules/patient360/Labs360SubModule"
import { MedicationStatements360SubModule } from "./modules/patient360/MedicationStatements360Submodule"
import { SocialHistory360SubModule } from "./modules/patient360/SocialHistory360Submodule"
import { Vitals360SubModule } from "./modules/patient360/Vitals360Submodule"
import { PlanConfigSubModule } from "./modules/plan/PlanConfigSubModule"
import { PlanDetailsSubModule } from "./modules/plan/PlanDetailsSubModule"
import { PlanEnrollSubModule } from "./modules/plan/PlanEnrollSubModule"
import { ProcedureDetailsSubModule } from "./modules/procedure/ProcedureDetailsSubModule"
import { SurveyDetailsSubModule } from "./modules/survey/SurveyDetailsSubModule"
import { VitalsSubModule } from "./modules/vital/VitalsSubModule"

const AppSubModules: AppSubModulesTypes = {
  intake: {
    [ModulesId.VITALS]: new VitalsSubModule(),
    [ModulesId.LABS]: new LabsSubModule(),
    [ModulesId.CONDITIONS]: new ConditionsSubModule(),
    [ModulesId.MEDICATIONE]: new MedicationESubModule(),
    [ModulesId.ALLERGY]: new AllergySubModule(),
    [ModulesId.FAMILY_HISTORY]: new FamilyHistorySubModule(),
    [ModulesId.SOCIAL_HISTORY]: new SocialHistorySubModule(),
  },
  invoice: {
    [ModulesId.INVOICE_DETAILS]: new InvoiceDetailsSubModule(),
    [ModulesId.INVOICE_PAYMENT_DETAILS]: new PaymentDetailsSubModule(),
  },
  medicationRequest: {
    [ModulesId.MR_DRAFT]: new MRDraftSubModule(),
    [ModulesId.MR_RESCHEDULE]: new MRRescheduleSubModule(),
    [ModulesId.MEDICATION_ORDER_DETAILS]: new MedicationOrderDetailsSubModule(),
  },
  patient: {
    [ModulesId.CREDIT_CARDS]: new CreditCardsSubModule(),
    [ModulesId.PATIENT_CONTACTS]: new ContactsSubModule(),
    [ModulesId.PATIENT_COVERAGES]: new CoverageSubmodule(),
    [ModulesId.CONSENT]: new ConsentSubmodule(),
    [ModulesId.PATIENT_DOCUMENTS]: new DocumentsSubModule(),
  },
  survey: {
    [ModulesId.SURVEY_DETAILS]: new SurveyDetailsSubModule(),
  },
  patient360: {
    [ModulesId.LABS_360]: new Labs360SubModule(),
    [ModulesId.VITALS_360]: new Vitals360SubModule(),
    [ModulesId.CONDITIONS_360]: new Conditions360SubModule(),
    [ModulesId.ALLERGIES_360]: new Allergies360SubModule(),
    [ModulesId.MEDICATION_STATEMENTS_360]: new MedicationStatements360SubModule(),
    [ModulesId.SOCIAL_HISTORY_360]: new SocialHistory360SubModule(),
    [ModulesId.FAMILY_HISTORY_360]: new FamilyHistory360SubModule(),
  },
  mc: {
    [ModulesId.MC_DETAILS]: new MCDetailsSubModule(),
    [ModulesId.PLAN_DETAILS]: new MCPlanDetailsSubModule(),
    [ModulesId.PLAN_CONFIG]: new MCPlanConfigSubModule(),
    [ModulesId.PLAN_ENROLL]: new MCPlanEnrollSubModule(),
  },
  plan: {
    [ModulesId.PLAN_DETAILS]: new PlanDetailsSubModule(),
    [ModulesId.PLAN_CONFIG]: new PlanConfigSubModule(),
    [ModulesId.PLAN_ENROLL]: new PlanEnrollSubModule(),
  },
  eprescribe: {
    [ModulesId.EPRESCRIBE_DRAFT]: new PrescriptionsDraftSubModule(),
    [ModulesId.EPRESCRIBE_ORDER]: new PrescriptionOrderDetailsSubModule(),
    [ModulesId.EPRESCRIBE_NEW]: new PrescriptionsNewSubModule(),
    [ModulesId.EPRESCRIBE_ORDER_EDIT]: new PrescriptionOrderEditSubModule(),
  },
  laboratory: {
    [ModulesId.LABORATORY_ORDER_DETAILS]: new LaboratoryOrderDetailsSubModule(),
  },
  checkout: {
    [ModulesId.CHECKOUT_CONFIG]: new ConfigSubModule(),
  },
  procedures: {
    [ModulesId.PROCEDURES_DETAILS]: new ProcedureDetailsSubModule(),
  },
}

export { AppSubModules }
