import { faVials } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { LabsContainer } from "../../../laboratory/components/LabsContainer"

class LabsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.LABS
    this.display = "Lab"
    this.parent = ModulesId.INTAKE
    this.icon = faVials
    this.content = <LabsContainer />
  }
}

export { LabsSubModule }
