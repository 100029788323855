import { IconDefinition } from "@fortawesome/pro-light-svg-icons"

import { StackedListContainer } from "commons"
import { conditionModelBuilder, useConditions } from "conditions"

import { WrapUpSection } from "./WrapUpSection"

const ConditionsSection = ({ patientId, encounterId, icon }: Props) => {
  const { conditions, isLoading, total } = useConditions({
    patientId,
    key: "KP",
    enabled: true,
    encounter: encounterId,
  })

  return (
    <WrapUpSection
      subSectionTitle="Conditions"
      isLoading={isLoading}
      isEmpty={!total}
      emptyMesage="No conditions added during this encounter"
      icon={icon}
      className="w-3/4 self-end"
    >
      <StackedListContainer data={conditions ?? []} itemModelBuilder={(item) => conditionModelBuilder(item)} />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounterId: string
  icon: IconDefinition
}

export { ConditionsSection }
