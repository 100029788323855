import { faClipboardPrescription } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, ModuleRenderSideType, AppModule } from "commons/Module"

class EPrescribeModule extends AppModule {
  constructor(workspaceElement: JSX.Element) {
    super()
    this.id = ModulesId.EPRESCRIBE
    this.display = "Rx"
    this.icon = faClipboardPrescription
    this.hideBySetting = true
    this.workspaceElement = workspaceElement
    this.renderSideType = ModuleRenderSideType.WORKSPACE
  }
}

export { EPrescribeModule }
