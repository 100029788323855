import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CodeableConcept, Coding, codeableConceptAsString } from "fhir"
import { ErrorMessage, Field, FieldProps } from "formik"
import { AutoComplete } from "primereact/autocomplete"
import { classNames } from "primereact/utils"
import { FC, useDeferredValue, useState } from "react"

import { useValueSet } from "value-set"

import { ValueSetIds } from "../types"

const AutocompleteCodeableConceptField: FC<Props> = ({
  field,
  label,
  className,
  valueSetId,
  readonly,
  disabled,
  multiple,
  placeholder,
  validation,
}) => {
  const [filter, setFilter] = useState<string | undefined>()
  const deferredFilter = useDeferredValue(filter)
  const { refetch } = useValueSet({ valueSetId, filter: deferredFilter, enabled: false })
  const [suggestionList, setSuggestionList] = useState<Coding[]>([])

  const itemTemplate = (item: CodeableConcept) => (
    <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{codeableConceptAsString(item)}</span>
  )

  const itemCodeTemplate = (item: Coding) => (
    <span className="whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{`${item.display} - ${item.code}`}</span>
  )

  const searchCodes = async () => {
    const { data: codes } = await refetch()
    setSuggestionList([...(codes ?? [])])
  }

  return (
    <Field name={field} validate={validation}>
      {({ field: { name, value, onChange }, form: { setFieldValue }, meta: { touched, error } }: FieldProps) => {
        return (
          <div className={classNames("field flex flex-col relative", className)}>
            {label && (
              <label htmlFor={name} className="text-sm font-medium text-gray-700 mb-2">
                {label}
              </label>
            )}
            <span className="left-icon absolute inset-y-0 left-0 flex items-center pl-3 pt-4 z-10">
              <FontAwesomeIcon className="text-slate-400 bg-white" icon={faSearch} title="Type to search" />
            </span>
            <AutoComplete
              id={name}
              name={name}
              field="display"
              disabled={disabled}
              itemTemplate={itemCodeTemplate}
              readOnly={readonly}
              suggestions={suggestionList}
              delay={400}
              minLength={3}
              multiple={multiple}
              selectedItemTemplate={multiple && itemTemplate}
              completeMethod={searchCodes}
              onBlur={() => {
                if (!multiple && !(value as CodeableConcept)?.coding?.[0]?.code)
                  setFieldValue(name, { coding: undefined, text: undefined })
              }}
              onChange={(e) => {
                multiple
                  ? onChange(e)
                  : setFieldValue(name, { coding: [e.value], text: e.value.display } as CodeableConcept)
                if (typeof e.value !== "string") return
                setFilter(e.value.trim())
              }}
              onSelect={(e) => {
                const newCodeableConcept = { coding: [e.value], text: e.value?.display } as CodeableConcept
                multiple
                  ? setFieldValue(name, [...(value ?? []), newCodeableConcept])
                  : setFieldValue(name, newCodeableConcept)
              }}
              value={multiple ? value : value?.coding?.[0]}
              className={classNames("p-inputtext-sm h-10", { "p-invalid": touched && error })}
              placeholder={placeholder}
              appendTo="self"
              panelClassName="w-full"
            />

            <div className="flex items-start p-error h-2 mt-1">
              <ErrorMessage name={name}>{(msg) => <small>{msg}</small>}</ErrorMessage>
            </div>
          </div>
        )
      }}
    </Field>
  )
}

type Props = {
  field: string
  label?: string
  className?: string
  readonly?: boolean
  disabled?: boolean
  multiple?: boolean
  valueSetId: ValueSetIds
  validation?(code: CodeableConcept): void
  placeholder?: string
}

export { AutocompleteCodeableConceptField }
