import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Address, Patient, ResourceObject } from "fhir"

import { useClient } from "api"
import { CustomError, sanitizeAddress } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

import { patientsQueryKeys } from "../query-keys"
import { getArrangedPatientAddress } from "../utils"

const useUpdatePatientShippingAddress = (
  onSuccess?: () => void,
  onSettled?: (data: ResourceObject | undefined, error: CustomError | null) => void,
) => {
  const { patch } = useClient()
  const queryClient = useQueryClient()

  const updatedPatient = async ({ address, patient }: { address: Address; patient: Patient }) => {
    address.use = "home"
    const newAddress = getArrangedPatientAddress(patient, address)

    return patch(
      "Patient",
      patient.id as string,
      { ...sanitizeAddress({ ...patient, address: newAddress }), meta: patient.meta } as ResourceObject,
    )
  }

  const { mutate: updateShippingAddress, isPending: isUpdating } = useMutation({
    mutationFn: updatedPatient,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { patient }) => {
      queryClient.refetchQueries({
        queryKey: patientsQueryKeys.details(patient.id),
        type: "all",
      })
      onSuccess?.()
    },
    onSettled,
  })

  return { updateShippingAddress, isUpdating }
}

export { useUpdatePatientShippingAddress }
