import { useQuery } from "@tanstack/react-query"
import { isLabOrder, isMedicationOrder } from "data"
import {
  Appointment,
  getResource,
  getResources,
  humanNameAsString,
  Invoice,
  MedicationRequest,
  Patient,
  Practitioner,
  Questionnaire,
  QuestionnaireResponse,
  ServiceRequest,
  Task,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { useOrganizationContext } from "organization"

import { tasksQueryKeys } from "../query-keys"
import { TaskData } from "../types"

const useTask = (taskId: string) => {
  const { currentOrganizationId } = useOrganizationContext()
  const { search } = useClient()
  const queryKey = tasksQueryKeys.details(taskId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _id: taskId,
        _include:
          "Task:focus:Invoice, Task:focus:ServiceRequest, Task:focus:Appointment, Task:focus:QuestionnaireResponse, Task:subject:Patient, Task:subject:Practitioner, Task:depends-on, Task:based-on, ServiceRequest:based-on, QuestionnaireResponse:questionnaire:Questionnaire",
      })

      const bundle = await search({
        endpoint: `Organization/${currentOrganizationId}/Task`,
        filters,
        signal,
      })
      const tasks = getResources<Task>(bundle, "Task")
      const patients = getResources<Patient>(bundle, "Patient")
      const practitioners = getResources<Practitioner>(bundle, "Practitioner")
      const orders = getResources<ServiceRequest>(bundle, "ServiceRequest")
      const invoices = getResources<Invoice>(bundle, "Invoice")
      const medicationRequests = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const qResponse = getResource<QuestionnaireResponse>(bundle, "QuestionnaireResponse")
      const questionnaire = getResource<Questionnaire>(bundle, "Questionnaire")
      const appointment = getResource<Appointment>(bundle, "Appointment")

      if (!tasks.length) {
        throw new Error("Not found", { cause: { name: "404", message: "Not task found" } })
      }

      const order = orders.find((sr) => isMedicationOrder(sr) || isLabOrder(sr))

      return {
        task: tasks?.find((t) => t.id === taskId),
        patient: patients?.[0],
        practitioner: practitioners?.[0],
        order: order,
        serviceRequests: orders.filter((sr) => sr.id !== order?.id),
        invoice: invoices?.[0],
        dependsOn: tasks?.filter((t) => t.id !== taskId),
        medicationRequests,
        questionnaireData: { qResponse, questionnaire },
        appointment,
      }
    },
    meta: { context: { queryKey, taskId } },
  })

  const { taskData } = useMemo(() => {
    const tasks =
      data?.dependsOn?.reduce((acc, task) => {
        const name = humanNameAsString(data?.patient?.name?.[0])

        const asignedTo =
          task.for?.resourceType === "Patient" && task.for?.id === data?.patient?.id
            ? { id: data?.patient?.id as string, name }
            : { id: task.for?.id as string, name: task.for?.display as string }

        return [...acc, { ...task, asignedTo }]
      }, new Array<TaskData>()) ?? []

    return {
      taskData: { ...data, dependsOn: tasks },
    }
  }, [data])

  return { taskData, isLoading }
}

export { useTask }
