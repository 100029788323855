import { faSearch } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFormikContext } from "formik"
import { classNames } from "primereact/utils"
import { FC, useEffect } from "react"

import { SkeletonLoader } from "../../../components/SkeletonLoader"
import { StackedListItem, StackedListItemProps } from "../../../components/StackedListItem"
import { PanelItemDisplay, PlanData } from "../../types"
import { PanelModelBuilderArgs } from "../../utils"
import { ComboPanelItem } from "./ComboPanelItem"

const ExtraPanelList: FC<Props> = ({
  panelItems,
  isLoading,
  error,
  onDelete,
  modelBuilder,
  showPrice,
  isReadonlyItem,
  isInsurance,
  className,
}) => {
  const {
    values: { combo },
    setFieldValue,
  } = useFormikContext<PlanData>()

  // Handle save Questionnaire canonicals of the selected test panels to allow show them on plan activation
  useEffect(() => {
    if (panelItems?.length) {
      const qCanonicals = panelItems.reduce((acc, pItem) => {
        const newQCanonicalIndex = pItem.planDefinition.action?.findIndex(({ definition }) =>
          definition?.canonical?.includes("Questionnaire"),
        )

        if (newQCanonicalIndex && newQCanonicalIndex !== -1) {
          const canonical = pItem.planDefinition.action?.[newQCanonicalIndex].definition?.canonical
          return [...acc, ...(canonical ? [canonical] : [])]
        }
        return acc
      }, Array<string>())

      setFieldValue("questionnairesCanonicals", qCanonicals)
    }
  }, [panelItems, setFieldValue])

  if (isLoading) return <SkeletonLoader loaderType="one-line" repeats={2} />

  return (
    <>
      {panelItems?.length ? (
        <ul className={classNames("@container divide-y divide-gray-200 p-3", className)}>
          {panelItems.map((item, index: number) => {
            const isReadonly = !item.isCombo && (isReadonlyItem?.(item.planDefinition.id as string) ?? false)
            return item.isCombo ? (
              <ComboPanelItem
                key={index}
                isInsurance={isInsurance ?? false}
                combo={item}
                showPrice={showPrice}
                onDelete={onDelete}
              />
            ) : (
              <StackedListItem
                key={index}
                modelData={modelBuilder({ item, readOnly: isReadonly, onDelete, showPrice, isInsurance })}
                className={classNames("py-4 !pl-0 first:!pt-0 last:pb-0", { "pl-4": !!combo, "pr-4": isReadonly })}
                contentClassName="flex flex-1 justify-between @md:items-center"
              />
            )
          })}
        </ul>
      ) : (
        <div
          className={classNames("flex flex-col items-center justify-center w-full", {
            "border border-red-600 rounded-lg": error,
          })}
        >
          <FontAwesomeIcon icon={faSearch} size="lg" className="text-slate-500" />
          <p className="text-slate-500 text-xs pt-1">{`No panels added yet`}</p>
        </div>
      )}
      {typeof error === "string" && (
        <small id={`errorMessage.panels`} className="p-error h-4">
          {error}
        </small>
      )}
    </>
  )
}

type Props = {
  isLoading?: boolean
  panelItems?: PanelItemDisplay[]
  onDelete: (item: PanelItemDisplay) => void
  error?: boolean | string
  modelBuilder({
    item,
    readOnly,
    onDelete,
    onDetails,
    showPrice,
    isInsurance,
  }: PanelModelBuilderArgs): StackedListItemProps
  showPrice?: boolean
  isReadonlyItem?(itemId: string): boolean
  isInsurance?: boolean
  className?: string
}

export { ExtraPanelList }
