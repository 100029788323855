import { classNames } from "primereact/utils"
import { FC } from "react"

import defaultUser from "assets/default-user.png"
import { getDefaultAvatar, hslToHex } from "utils"

import { SignedAzureImage } from "./SignedAzureImage"

const AvatarImage: FC<Props> = ({ photoUrl, name, className, loadingClassName, staticDefaultUser, onImageLoaded }) => {
  const bg = document.body.style.getPropertyValue("--color-photo-bg-color")

  const defaultAvatarSource = staticDefaultUser ? defaultUser : getDefaultAvatar(name, bg ? hslToHex(bg) : "7ec26b")
  const fallbackImage = <img src={defaultAvatarSource} className={className} alt="" />

  if (!photoUrl) {
    onImageLoaded?.(defaultAvatarSource)
    return fallbackImage
  }

  return (
    <SignedAzureImage
      url={photoUrl}
      imageClassName={classNames("object-cover object-top", className)}
      loadingClassName={loadingClassName}
      fallbackIconNode={fallbackImage}
      alt=""
      onImageLoaded={onImageLoaded}
    />
  )
}

type Props = {
  photoUrl?: string
  name: string
  className?: string
  loadingClassName?: string
  staticDefaultUser?: boolean
  onImageLoaded?(resolvedUrl: string): void
}

export { AvatarImage }
