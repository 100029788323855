import { Button } from "primereact/button"
import { FC } from "react"

const NotificationConfirm: FC<Props> = ({ header, message, onConfirm }) => (
  <div className="flex flex-col">
    <p className="font-bold">{header}</p>
    <p>{message}</p>
    <span className="w-full flex justify-end">
      <Button className="p-button-sm p-button-outlined" label="OK" onClick={onConfirm} />
    </span>
  </div>
)

type Props = { header: string; message: string; onConfirm(): void }

export { NotificationConfirm }
