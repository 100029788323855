import { faEnvelopes } from "@fortawesome/pro-regular-svg-icons"

import { AppModule, ModulesId, ModuleRenderSideType } from "commons/Module"

class EmailsModule extends AppModule {
  constructor(workspaceElement: JSX.Element) {
    super()
    this.id = ModulesId.EMAIL
    this.display = "Emails"
    this.icon = faEnvelopes
    this.hideBySetting = true
    this.workspaceElement = workspaceElement
    this.renderSideType = ModuleRenderSideType.WORKSPACE
  }
}

export { EmailsModule }
