import { InputField, AddressField } from "commons"

const CarrierForm = () => (
  <>
    <InputField field="name" label="Name" />
    <AddressField parentFieldName="address[0]" showTypeUseField={false} />
  </>
)

export { CarrierForm }
