import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons"
import { classNames } from "primereact/utils"

import { SubModule } from "../Module"

const CardMenu = ({ menuItems, itemCommand }: CardMenuProps) => {
  return (
    <div>
      {menuItems.map((item) => (
        <CardMenuItem key={item.getId()} {...{ item, command: () => itemCommand?.(item) }} />
      ))}
    </div>
  )
}

const CardMenuItem = ({ item, showItemMenu = false, command }: CardMenuItemProps) => {
  const description = item.getDescription()

  return (
    <div
      className="border-slate-200 border flex rounded-md shadow-sm last:mb-10 hover:bg-gray-50 cursor-pointer h-20 mb-5"
      onClick={() => command?.()}
    >
      <div
        className={classNames(
          "flex justify-center items-center w-20 h-full text-primary bg-slate-100 font-bold rounded-tl-md rounded-bl-md",
        )}
      >
        <FontAwesomeIcon icon={item.getIcon()} size="2x" />
      </div>
      <div className="pl-4 flex-1 flex flex-col justify-center">
        <span className="block text-gray-800 font-[600] text-sm">{item.getDisplay()}</span>
        {description && <span className="block text-gray-500 text-xs">{item.getDescription()}</span>}
      </div>
      {showItemMenu && (
        <div className="flex pr-5 items-center text-gray-400">
          <FontAwesomeIcon icon={faEllipsisVertical} size="2x" />
        </div>
      )}
    </div>
  )
}

type CardMenuProps = {
  menuItems: SubModule[]
  itemCommand?(item?: SubModule): void
}

export type CardMenuItemProps = {
  item: SubModule
  showItemMenu?: boolean
  className?: string
  command?(): void
}

export { CardMenu, CardMenuItem }
