import { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { MedicationKnowledge, MedicationRequest } from "fhir"
import { FC, useState } from "react"

import { MedicationKnowledgeDetails, useChargeItemDefinitions } from "commons"
import { useMedicationRequestDataBind, useMedicationRequests } from "commons/meds"
import { MedicationRequestListItem } from "medication-requests"

import { WrapUpSection } from "./WrapUpSection"

const NutrasSection: FC<Props> = ({ organizationId, patientId, encounterId, icon }) => {
  const [selectedMK, setSelectedMK] = useState<{ mk?: MedicationKnowledge; mr?: MedicationRequest }>()
  const { isLoading, medicationRequests, medicationKnowledges, medCodes, medicationDispenses, total } =
    useMedicationRequests({
      patientId,
      category: "nutraceutical",
      statusFilter: ["active", "draft"],
      perPage: 100,
      page: 1,
      encounter: encounterId,
    })
  const { chargeItemDefinitions } = useChargeItemDefinitions({
    organizationId,
    codes: {
      billToPracticeOrInsuranceCIDs: medCodes,
    },
  })

  const { medicationRequestData } = useMedicationRequestDataBind({
    medicationRequests,
    medicationKnowledges,
    medicationsCIDs: chargeItemDefinitions?.billToPracticeOrInsuranceCIDs ?? {},
    medicationDispenses,
  })

  return (
    <WrapUpSection
      sectionTitle="Nutraceuticals"
      isLoading={isLoading}
      isEmpty={!total}
      icon={icon}
      emptyMesage="No nutraceuticals orders placed during this encounter"
      className="@md:w-[80%] w-11/12 self-end pr-3"
    >
      {medicationRequestData.map((medication) => (
        <MedicationRequestListItem
          key={`${medication.medicationRequestInfo.id}-${medication.medicationRequestInfo.status}`}
          medicationData={medication}
          onSelectMK={(mk) => setSelectedMK({ mk, mr: medication.medicationRequestInfo })}
          showActions={false}
        />
      ))}
      <MedicationKnowledgeDetails
        selectedMK={selectedMK?.mk}
        onHide={() => setSelectedMK(undefined)}
        mr={selectedMK?.mr}
      />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounterId: string
  organizationId: string
  icon: IconDefinition
}

export { NutrasSection }
