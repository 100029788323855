import { HubConnection } from "@microsoft/signalr"
import { checkScreenSharingCapability } from "@opentok/client"
import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useEffect, useState } from "react"

import { PatientStatus } from "communication/data"
import { TPatientStatus } from "communication/types"
import { usePatientContext } from "patients"
import { useSignalR } from "signalR"

const VideoCallContext = createContext({} as TVideoCallContext)

const VideoCallProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isInterviewStarted, setIsInterviewStarted] = useState(false)
  const [destroyedSession, setDestroyedSession] = useState<string | undefined>(undefined)
  const [warningBeforeUnload, setWarningBeforeUnload] = useState(false)
  const [rejectCall, setRejectCall] = useState<string | undefined>(undefined)
  const [patientStatus, setPatientStatus] = useState<TPatientStatus>(PatientStatus.disconnected)
  const browserNotSupportedPIP = !("pictureInPictureElement" in document) || !document.pictureInPictureEnabled
  const [browserNotSupportedShareScreen, setBrowserNotSupportedShareScreen] = useState(false)

  const { patientId: patientIdVC } = usePatientContext()
  const { connection } = useSignalR({ url: `${window.VITE_APP_SIGNALR_CALL_NOTIFICATION}?role=practitioner` })

  const handleDestroyedVCEvent = () =>
    connection?.on("FinishVideoCall", (data: { sessionId: string }) => {
      setDestroyedSession((prevId) => (prevId !== data.sessionId ? data.sessionId : prevId))
    })

  const handleRejectVCEvent = () =>
    connection?.on("RejectVideoCall", (data: { sessionId: string }) => {
      setRejectCall((prevId) => (prevId !== data.sessionId ? data.sessionId : prevId))
    })

  const handlePatientStatus = () =>
    connection?.on("ReceivePatientStatus", (data: { patientId: string; patientStatus: TPatientStatus }) => {
      patientIdVC === data.patientId &&
        setPatientStatus((prev) => (prev !== data.patientStatus ? data.patientStatus : prev))
    })

  useEffect(() => {
    handlePatientStatus()
    handleDestroyedVCEvent()
    handleRejectVCEvent()
  }, [connection])

  useEffect(() => {
    checkScreenSharingCapability((response) =>
      setBrowserNotSupportedShareScreen(!response.supported || response.extensionRegistered === false),
    )
  }, [])

  const data = {
    destroyedSession,
    setDestroyedSession,
    connection,
    browserNotSupportedPIP,
    setWarningBeforeUnload,
    warningBeforeUnload,
    browserNotSupportedShareScreen,
    patientStatus,
    rejectCall,
    setRejectCall,
    isInterviewStarted,
    setIsInterviewStarted,
  }

  return <VideoCallContext.Provider value={data}>{children}</VideoCallContext.Provider>
}

type TVideoCallContext = {
  setIsInterviewStarted: Dispatch<SetStateAction<boolean>>
  isInterviewStarted: boolean
  connection?: HubConnection
  setDestroyedSession: Dispatch<SetStateAction<string | undefined>>
  destroyedSession?: string
  browserNotSupportedPIP: boolean
  setWarningBeforeUnload: Dispatch<SetStateAction<boolean>>
  warningBeforeUnload: boolean
  browserNotSupportedShareScreen: boolean
  patientStatus: TPatientStatus
  setRejectCall: Dispatch<SetStateAction<string | undefined>>
  rejectCall?: string
}

export { VideoCallContext, VideoCallProvider }
