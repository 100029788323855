import { asReference, ChargeItemDefinition, MedicationKnowledge, Organization } from "fhir"
import { useMemo } from "react"

import { getBasePrice, getCidIdentifier, getCommonCode } from "utils"

import { Supplement } from "../types"

const useSupplementDataBind = ({
  supplements: meds,
  medicationsCIDs,
  mksBySku,
  performerByCatalog,
}: {
  supplements: Partial<Supplement>[]
  mksBySku?: Record<string, MedicationKnowledge>
  medicationsCIDs?: Record<string, ChargeItemDefinition>
  performerByCatalog?: Record<string, Organization>
}) => {
  const supplements = useMemo(
    () =>
      (meds ?? []).reduce((acc, { mr, mk, ...props }) => {
        if (mr && mk) {
          const sku = getCommonCode({ codes: mk.code?.coding, fallback: "" })
          const chargeItemDefinition = medicationsCIDs?.[getCidIdentifier(sku, mr.dispenseRequest?.quantity?.value)]
          const finalMK = mksBySku?.[sku] ? { ...mksBySku?.[sku], ...mk } : mk
          const performer = performerByCatalog?.[finalMK.catalogHeader?.[0]?.id as string]
          return [
            ...acc,
            {
              ...props,
              mr: {
                ...mr,
                dispenseRequest: {
                  ...(mr.dispenseRequest ?? {}),
                  ...(performer?.id ? { performer: asReference(performer) } : {}),
                },
              },
              mk: finalMK,
              price: getBasePrice(chargeItemDefinition?.propertyGroup?.[0]?.priceComponent)?.amount,
            },
          ]
        }
        return acc
      }, Array<Supplement>()),
    [meds, medicationsCIDs, mksBySku],
  )

  return { supplements }
}

export { useSupplementDataBind }
