import { humanNameAsString, getFirstEmail, getFirstPhone, Patient } from "fhir"
import { MenuItem } from "primereact/menuitem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMobile, faEnvelope, faMars, faVenus } from "@fortawesome/pro-light-svg-icons"

import { AvatarImage, AvatarListItem } from "commons"
import { calculateAge, strCapitalize } from "utils"

import { getEffectivesAddressess } from "../utils"

const PatientListItem = ({
  patient: { telecom, name, photo, birthDate, gender, address },
  dropdownItems,
  onClick,
}: Props) => {
  const phone = getFirstPhone(telecom)
  const effectiveAddresses = getEffectivesAddressess(address ?? [])

  const details = (
    <>
      <span>
        <span title="Age">{calculateAge(birthDate)}</span>
        {gender && (
          <FontAwesomeIcon icon={gender === "male" ? faMars : faVenus} title={strCapitalize(gender)} className="pl-1" />
        )}
      </span>
      <span title="Email" className="pl-2">
        <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
        <span>{getFirstEmail(telecom)}</span>
      </span>
      {phone && (
        <span title="Phone" className="pl-2">
          <FontAwesomeIcon icon={faMobile} /> {phone}
        </span>
      )}
      {address &&
        effectiveAddresses.map(({ use, display, icon }, index) => (
          <span key={index} title={`${strCapitalize(use)} Address`} className="pl-2">
            <FontAwesomeIcon icon={icon} /> {display}
          </span>
        ))}
    </>
  )

  return (
    <AvatarListItem
      avatarImg={
        <AvatarImage
          name={humanNameAsString(name?.[0])}
          photoUrl={photo?.[0]?.url}
          className="h-10 w-10 rounded-full"
        />
      }
      header={<span className="capitalize">{humanNameAsString(name?.[0])}</span>}
      subHeader={details}
      dropdownMenuItems={dropdownItems}
      onContentClick={onClick}
    />
  )
}

type Props = {
  patient: Patient
  dropdownItems?: MenuItem[]
  onClick(): void
}

export { PatientListItem }
