import { faCapsules } from "@fortawesome/pro-regular-svg-icons"

import { AppModule, ModulesId } from "commons/Module"

class MedicationRequestModule extends AppModule {
  constructor(workspaceElement: JSX.Element, kpElement: JSX.Element) {
    super()
    this.id = ModulesId.MEDICATIONR
    this.display = "Nutraceuticals"
    this.icon = faCapsules
    this.hideBySetting = true
    this.workspaceElement = workspaceElement
    this.kpElement = kpElement
  }
}

export { MedicationRequestModule }
