const patient360QueryKeys = {
  cwSearch: (patientId: string) => ["patient/cw-search", patientId],
  cwSetup: (patientId: string) => ["patient/cw-setup", patientId],
  allergies: (patientId: string) => ["patient/allergies360", patientId],
  conditions: (patientId: string) => ["patient/conditions360", patientId],
  familyHistory: (patientId: string) => ["patient/familyhistory360", patientId],
  medicationsStatement: (patientId: string) => ["patient/medicationstatements360", patientId],
  observations: {
    all: ["patient/observation"] as const,
    list: (patientId: string, category: string, lastUpdated?: string, hasQuantity?: boolean) => [
      patient360QueryKeys.observations.all,
      patientId,
      category,
      lastUpdated,
      hasQuantity,
    ],
  },
}

export { patient360QueryKeys }
