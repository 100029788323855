import { useInfiniteQuery } from "@tanstack/react-query"
import { Encounter, getResources } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { encounterQueryKeys } from "../query-keys"

const usePatientEncounters = (patientId: string, key?: "KP") => {
  const { search } = useClient()
  const queryKey = encounterQueryKeys.list(patientId, key)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    EncountersQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        _elements: "id,period,status,participant,type,reasonCode",
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-date",
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/Encounter`, filters, signal })

      const encounters = getResources(bundle) as Encounter[]

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { encounters, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  const { encounters, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.encounters)
    const count = newData?.length

    return {
      encounters: newData,
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    encounters,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadEncounters: refetch,
  }
}

type EncountersQueryData = {
  encounters: Encounter[]
  next: number | undefined
  total: number
}

export { usePatientEncounters }
