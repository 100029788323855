import { IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { FC } from "react"

import { StackedListContainer } from "commons"
import { proceduresItemModel, usePatientProcedures } from "procedures"

import { WrapUpSection } from "./WrapUpSection"

const ProceduresSection: FC<Props> = ({ patientId, encounter, icon }) => {
  const { proceduresData, isLoading, total } = usePatientProcedures({ patientId, encounter }) 

  return (
    <WrapUpSection
      isEmpty={!total}
      icon={icon}
      emptyMesage="No procedures activated during this encounter"
      className="w-10/12 self-end z-50"
      sectionTitle="Procedures"
      isLoading={isLoading}
    >
      <StackedListContainer data={proceduresData} itemModelBuilder={(item) => proceduresItemModel(item)} />
    </WrapUpSection>
  )
}

type Props = {
  patientId: string
  encounter: string
  icon: IconDefinition
}

export { ProceduresSection }
