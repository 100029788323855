import { useContext } from "react"

import { PatientContext } from "../context/PatientProvider"

const usePatientContext = () => {
  const context = useContext(PatientContext)

  if (context === undefined) {
    throw new Error("usePatientContext must be used within a PatientContextProvider")
  }

  return context
}

export { usePatientContext }
