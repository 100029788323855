import { useQuery } from "@tanstack/react-query"
import { isBefore } from "date-fns/isBefore"
import {
  Invoice,
  MedicationDispense,
  MedicationKnowledge,
  MedicationRequest,
  Provenance,
  ServiceRequest,
  Task,
  getResources,
} from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { getBillingTypeCode, getCommonCode, getMedCodes } from "utils"

import { medsQueryKeys } from "../meds_query_keys"
import { TrackingData } from "../types"
import { hasInvalidMedicationDispense } from "../utils"

const useMrOrderDetails = (patientId: string, orderId: string) => {
  const { search } = useClient()
  const queryKey = medsQueryKeys.orderDetails.details(orderId)

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = new URLSearchParams({
        _query: "medication-order-details",
        _id: orderId,
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/ServiceRequest`, filters, signal })

      const serviceRequest = getResources<ServiceRequest>(bundle, "ServiceRequest")
      const medicationRequests = getResources<MedicationRequest>(bundle, "MedicationRequest")
      const medicationKnowledges = getResources<MedicationKnowledge>(bundle, "MedicationKnowledge")
      const tasks = getResources<Task>(bundle, "Task")
      const invoices = getResources<Invoice>(bundle, "Invoice")
      const medicationDispenses = getResources<MedicationDispense>(bundle, "MedicationDispense")
      const provenances = getResources<Provenance>(bundle, "Provenance")

      return {
        serviceRequest: serviceRequest?.[0],
        tasks,
        medicationRequests,
        medicationKnowledges,
        invoices,
        medicationDispenses,
        provenances,
        history: serviceRequest.slice(1),
      }
    },
    throwOnError: true,
    meta: { context: { queryKey, orderId } },
  })

  const {
    invoice,
    invoices,
    sortedTasks: tasks,
  } = useMemo(() => {
    const dispenseTasksByFocusedResource = data?.tasks?.reduce(
      (acc, task) => {
        if (task.code?.coding?.some(({ code }) => code === "dispense-medications") && task.focus?.id) {
          return { ...acc, [task.focus.id]: task }
        }
        return { ...acc }
      },
      {} as Record<string, Task>,
    )

    // Find dispense task for the order or for the replaced(after edit) order
    const taskSR = dispenseTasksByFocusedResource?.[data?.serviceRequest.id as string]
    const replacedTaskSR = dispenseTasksByFocusedResource?.[data?.serviceRequest.replaces?.[0]?.id as string]

    const taskInvoice = data?.tasks?.find(
      (task) => task.id === (taskSR?.dependsOn?.[0]?.id ?? replacedTaskSR?.dependsOn?.[0]?.id),
    )
    const invoice = data?.invoices?.find((inv) => inv.id === taskInvoice?.focus?.id)
    const invoices = data?.invoices.toSorted((a, b) => (isBefore(a.date ?? "", b.date ?? "") ? -1 : 1))
    const sortedTasks = data?.tasks.toSorted((a, b) =>
      isBefore(a.executionPeriod?.start ?? a.authoredOn ?? "", b.executionPeriod?.start ?? b.authoredOn ?? "") ? -1 : 1,
    )

    return { invoice, invoices, sortedTasks }
  }, [data?.invoices, data?.tasks])

  const { missingInfoMessages, medCodes, medicationKnowledges, billingTypeCode } = useMemo(() => {
    const taskDescriptions =
      invoice?.status !== "balanced"
        ? data?.tasks.reduce((acc, task) => {
            if (
              ["complete-shipping-address", "complete-shipping-method", "complete-cc"].includes(
                task.code?.coding?.[0].code ?? "",
              ) &&
              task.status === "ready"
            )
              return [...acc, task.description as string]
            return acc
          }, new Array<string>())
        : undefined

    const medCodes = getMedCodes({ meds: data?.medicationRequests, withQty: true })
    const medicationKnowledges = data?.medicationKnowledges.reduce(
      (acc, mk) => {
        const code = getCommonCode({ codes: mk.code?.coding })
        return { ...acc, [code]: mk }
      },
      {} as Record<string, MedicationKnowledge>,
    )

    const billingTypeCode = getBillingTypeCode(data?.medicationRequests?.[0])

    return { missingInfoMessages: taskDescriptions, medCodes, medicationKnowledges, billingTypeCode, invoice }
  }, [invoice, data?.tasks, data?.medicationRequests, data?.medicationKnowledges])

  const { medProvenances, dispenseTrackCodes, hasInvalidMD, sortedDispenses } = useMemo(() => {
    const sortedDispenses = data?.medicationDispenses.toSorted((a, b) =>
      isBefore(a.whenPrepared ?? "", b.whenPrepared ?? "") ? -1 : 1,
    )
    const dispenseTrackCodes =
      sortedDispenses?.reduce((acc, md) => {
        if (md.identifier?.[0]?.value) {
          const trackinCode = md.identifier?.[0]?.value
          const medication = md.medication?.CodeableConcept?.text as string
          const mrId = md?.authorizingPrescription?.[0]?.id

          return [
            ...acc,
            {
              identifier: trackinCode,
              datePrepared: md.whenPrepared as string,
              dateShipped: md.whenHandedOver,
              medications: [medication],
              status: md.status,
              mrId: mrId,
            },
          ]
        }
        return acc
      }, Array<TrackingData>()) ?? []

    const medProvenances =
      data?.provenances.reduce<Record<string, ProvenanceWithTracking[]>>((acc, provenance) => {
        const md = data?.medicationDispenses.find((md) => md.eventHistory?.some((ref) => ref.id === provenance.id))
        const trackingCode = md?.identifier?.[0]?.value ?? undefined
        const mrId = md?.authorizingPrescription?.[0]?.id
        return mrId ? { ...acc, [mrId]: [...(acc[mrId] ?? []), { provenance, trackingCode }] } : acc
      }, {}) ?? {}

    const hasInvalidMD = hasInvalidMedicationDispense(data?.medicationDispenses)

    return { medProvenances, dispenseTrackCodes, hasInvalidMD, sortedDispenses }
  }, [data?.provenances, data?.medicationDispenses])

  const isEditable = useMemo(
    () => hasInvalidMD && !data?.serviceRequest.replaces?.length && data?.serviceRequest.status === "completed",
    [data?.serviceRequest],
  )

  return {
    serviceRequest: data?.serviceRequest,
    tasks,
    medicationRequests: data?.medicationRequests,
    medicationKnowledges,
    invoice,
    invoices,
    medicationDispenses: sortedDispenses,
    dispenseTrackCodes,
    provenances: medProvenances,
    missingInfoMessages,
    medCodes,
    isLoading,
    billingTypeCode,
    isEditable,
    history: data?.history,
  }
}
type ProvenanceWithTracking = {
  provenance: Provenance
  trackingCode?: string
}
export { useMrOrderDetails }
