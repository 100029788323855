import { useInfiniteQuery } from "@tanstack/react-query"
import { getResources, humanNameAsString, Patient, Task } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { tasksQueryKeys } from "../query-keys"
import { TaskData } from "../types"

const useTasks = (organizationId: string, filter = "", code?: string, assignedTo?: string, status?: string) => {
  const { search } = useClient()
  const queryKey = tasksQueryKeys.list(organizationId, filter, code, assignedTo, status)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<TasksQueryData, Error>({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        ...(filter ? { _content: filter.includes(" ") ? `"${filter}"` : filter } : {}),
        ...(code ? { _code: code } : {}),
        ...(assignedTo ? { _subject: assignedTo } : {}),
        ...(status ? { _status: status } : {}),
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-authored-on",
        _include: "Task:patient",
      })

      const bundle = await search({ endpoint: `Organization/${organizationId}/Task`, filters, signal })

      const tasks = getResources<Task>(bundle, "Task")
      const patients = getResources<Patient>(bundle, "Patient")

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { tasks, patients, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, organizationId, filter, code, assignedTo, status } },
  })

  const { tasks, count } = useMemo(() => {
    const rawTasks = data?.pages.flatMap((page) => page.tasks)
    const rawPatients = data?.pages.flatMap((page) => page.patients)

    const patients = rawPatients?.reduce<Record<string, Patient>>((acc, patient) => {
      return { ...acc, [patient?.id as string]: patient }
    }, {})

    const tasks = rawTasks?.reduce((acc, task) => {
      const name = humanNameAsString(patients?.[task.for?.id as string]?.name?.[0])

      const asignedTo =
        task.for?.resourceType === "Patient"
          ? { id: task.for?.id as string, name }
          : { id: task.for?.id as string, name: task.for?.display as string }

      return [...acc, { ...task, asignedTo }]
    }, new Array<TaskData>())

    const count = tasks?.length ?? 0

    return {
      tasks: tasks,
      count,
    }
  }, [data?.pages])

  return {
    tasks,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }
}

type TasksQueryData = { tasks: Task[]; patients: Patient[]; next: number | undefined; total: number }

export { useTasks }
