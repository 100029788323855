import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Patient, Reference } from "fhir"
import { Form, Formik, FormikProps } from "formik"
import { useEffect } from "react"

import { BirthdateField, Button, GenderField, InputField, ReferenceDropdownField } from "commons"
import { isValidPractitioner } from "commons/utils"
import { useOrganizationPractitioners } from "organization"

import { usePatchPatient } from "../../hooks"
import { sanitizePatientName } from "../validations"

const BasicInformationForm = ({ patient, currentOrganizationId }: Props) => {
  const { organizationPractitionerRefs, organizationPractitionersInfo } = useOrganizationPractitioners({
    organizationId: currentOrganizationId,
    onlyActivePracts: false,
  })
  const { patchPatient, isPatching } = usePatchPatient()

  const initialValues: Partial<Patient> = {
    name: patient.name,
    birthDate: patient.birthDate,
    gender: patient.gender,
    generalPractitioner: patient.generalPractitioner,
  }

  useEffect(() => {
    if (initialValues.generalPractitioner?.[0]) {
      if (!organizationPractitionerRefs.some(({ id }) => initialValues.generalPractitioner?.[0]?.id === id)) {
        initialValues.generalPractitioner = []
      }
    }
  }, [organizationPractitionerRefs])

  const handleSubmit = (patientData: Partial<Patient>) => {
    patchPatient({
      patientId: patient.id as string,
      patientData: { ...sanitizePatientName(patientData), meta: patient.meta },
    })
  }

  const renderForm = ({ values }: FormikProps<Patient>) => (
    <Form className="flex flex-1 flex-col overflow-hidden divide-y">
      <fieldset className="relative p-fluid flex flex-1 flex-col overflow-y-auto grow px-3">
        <legend>Basic Information</legend>
        <InputField
          field="name[0].given[0]"
          label="First Name"
          validation={(value) => (!value ? "First Name is required" : undefined)}
        />
        <InputField
          field="name[0].family"
          label="Last Name"
          validation={(value) => (!value ? "Last Name is required" : undefined)}
        />
        <BirthdateField field="birthDate" label="Birthdate" />
        <GenderField field="gender" label="Biological Sex" />
        <ReferenceDropdownField
          field="generalPractitioner[0]"
          options={organizationPractitionerRefs}
          validation={(value) =>
            !value?.id
              ? "Practitioner is required"
              : !isValidPractitioner(organizationPractitionersInfo, value.id)
                ? "Invalid Practitioner"
                : undefined
          }
          label="Practitioner"
          assignDefaultValue={false}
          optionDisabled={(option?: Reference) => !isValidPractitioner(organizationPractitionersInfo, option?.id)}
        />
        {!values.generalPractitioner?.[0]?.id ? (
          <div className="text-sm text-red-400">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span className="ml-1">Missing general practitioner</span>
          </div>
        ) : (
          !isValidPractitioner(organizationPractitionersInfo, values.generalPractitioner?.[0]?.id) && (
            <div className="text-sm text-red-400">
              <FontAwesomeIcon icon={faExclamationTriangle} />
              <span className="ml-1">General practitioner isn't available or doesn't meet security requirements</span>
            </div>
          )
        )}
      </fieldset>
      <div className="flex flex-shrink-0 justify-end gap-3 px-4 py-4">
        <Button type="submit" label="Update" size="lg" loading={isPatching} />
      </div>
    </Form>
  )

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnMount enableReinitialize>
      {renderForm}
    </Formik>
  )
}

type Props = {
  patient: Patient
  currentOrganizationId: string
}

export { BasicInformationForm }
