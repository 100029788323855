import { Coding, Observation, Reference, ServiceRequest } from "fhir"
import { ConfirmDialog } from "primereact/confirmdialog"
import { Dropdown } from "primereact/dropdown"
import { Tooltip } from "primereact/tooltip"
import { FC, useCallback, useEffect, useId, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import {
  Button,
  DataContainerForm,
  GroupedList,
  InfiniteScroll,
  ModulesId,
  SkeletonLoader,
  SplitButton,
  StackedListItem,
  useCrudReducer,
} from "commons"
import { PlanContext, useUpdatePlanStatus } from "commons/care-plans"
import {
  DRData,
  QuestionnaireAndLabDataForm,
  getObservationInitialValue,
  useProcedureCalculator,
} from "commons/procedures"
import { useAppModuleContext } from "internals"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"
import { displayNotificationSuccess, openLinkInNewTab, strCapitalize } from "utils"

import {
  useMCAssessmentsByStatus,
  useMCPlanDefinitions,
  useMCReportUrl,
  usePrintQuestionnaire,
  useReleasePlanDR,
  useRunCalculator,
  useSavePlanLabs,
} from "../hooks"
import { CarePlanWithDR } from "../types"
import { assessmentItemModel } from "./assessmentItemModel"

const MCContainerData: FC<Props> = ({ searchText, statusFilter }) => {
  const loaderKey = useId()
  const { hasGP, showModule } = useChartContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const { patientId, patientRef, patient } = usePatientContext()
  const { loggedInPractitionerRoleRef, organizationPractitionersInfo } = useOrganizationContext()
  const { appModules } = useAppModuleContext()

  const { groupedCarePlans, isLoading, hasNextPage, fetchNextPage } = useMCAssessmentsByStatus(
    patientId,
    searchText,
    statusFilter,
  )
  const { planDefinitions } = useMCPlanDefinitions(patient.gender)
  const { releaseDR, isReleasingDR } = useReleasePlanDR()
  const { getReportUrl, isLoadingUrl } = useMCReportUrl(openLinkInNewTab)
  const { printQuestionnaire, isLoadingPrint } = usePrintQuestionnaire(openLinkInNewTab, () =>
    setSelectedPlanQRId(undefined),
  )

  useEffect(() => {
    if (searchParams.get("context") === PlanContext.MC_ASSESSMENTS) {
      handleAdd()
    }
  }, [])

  const planId = searchParams.get("planId") ?? undefined
  const algorithmId = searchParams.get("algorithmId")
  let planQrId = searchParams.get("planQrId") ?? ""

  const [selectedPDid, setSelectedPDid] = useState<string>()
  const [selectedPlanId, setSelectedPlanId] = useState<string>()
  const [selectedPlanQRId, setSelectedPlanQRId] = useState<string>()
  const [selectedDRId, setSelectedDRId] = useState<string>()

  useEffect(() => {
    if (planId) handleAdd()
    if (algorithmId) setSelectedPDid(algorithmId)
  }, [planId, algorithmId])

  const validPatientGpPractRoleRef = useMemo(
    () =>
      organizationPractitionersInfo.find(({ practitioner }) => practitioner.id === patient.generalPractitioner?.[0]?.id)
        ?.practitionerRoleRef,
    [organizationPractitionersInfo, patient.generalPractitioner?.[0]?.id],
  )

  const {
    isLoading: isLoadingFormData,
    questionnaire,
    questionnaireResponses,
    observationRequestData,
    observationData,
    runAlgorithmCode,
    icd10,
    performers,
    reloadData,
  } = useProcedureCalculator(patientId, selectedPDid)

  const {
    isNew,
    showSlide,
    initialValue,
    state: { runCalculate },
    add,
    reset,
    dispatch,
    edit,
  } = useCrudReducer({
    defaultEntity: {
      labData: {},
      qrId: planQrId,
      performer: performers?.[0],
      selectedDR: undefined as DRData | undefined,
      order: undefined as ServiceRequest | undefined,
    },
    extraState: { runCalculate: false },
    extraStateReducer: (state, { type, payload }) => {
      if (type === "calculate") return { ...state, runCalculate: payload as boolean }

      return state
    },
  })

  const setRunCalculate = (val: boolean) => dispatch({ type: "calculate", payload: val })

  const resetForm = (showMC = true) => {
    reset()
    setSelectedPDid(undefined)
    if (showMC) showModule({ module: ModulesId.MC, moduleParams: { context: "mc" } })
  }

  const { runCalculator, isEvaluating } = useRunCalculator((assessmentId) => {
    resetForm(false)
    showModule({ module: ModulesId.MC, moduleParams: { mcPlanId: assessmentId } })
  })

  const { savePlanLabs, isPending: isSaving } = useSavePlanLabs((sr) => {
    if (runCalculate) runCalculator({ labOrder: sr.id as string, planId, patientRef })
    else resetForm()
  })

  const loader = () => <SkeletonLoader key={loaderKey} repeats={4} loaderType="two-lines" />

  const handleAdd = useCallback(() => {
    if (searchParams.get("context") !== PlanContext.MC_ASSESSMENTS)
      showModule({ module: ModulesId.MC, moduleParams: { context: PlanContext.MC_ASSESSMENTS } })
    add()
  }, [showModule])

  const handleEdit = useCallback(() => {
    if (searchParams.get("context") !== PlanContext.MC_ASSESSMENTS)
      showModule({ module: ModulesId.MC, moduleParams: { context: PlanContext.MC_ASSESSMENTS } })
    setSelectedPDid(`${patient.gender}-mc`)
  }, [showModule, patient.gender])

  const showPlan = (planId: string) => {
    searchParams.set("mcPlanId", planId)
    setSearchParams(searchParams)
  }

  const releasePlanDR = (drId: string) => {
    releaseDR({ drId })
    setSelectedDRId(drId)
  }

  const downloadResult = (carePlanId: string) => {
    getReportUrl({ carePlanId })
    setSelectedPlanId(carePlanId)
  }

  const printQR = (carePlanQRId: string) => {
    printQuestionnaire(carePlanQRId)
    setSelectedPlanQRId(carePlanQRId)
  }

  const { updatePlanStatus, isUpdating } = useUpdatePlanStatus(patientId, undefined, () => setSelectedPlanId(undefined))

  const archivePlan = (resourceId: string, resourceType: "CarePlan" | "ServiceRequest") => {
    updatePlanStatus({ planId: resourceId, status: "entered-in-error", resourceType })
    setSelectedPlanId(resourceId)
  }

  const onSubmit = (
    qrId: string,
    labData: { [K: string]: string },
    selectedDR: DRData | undefined,
    order: ServiceRequest | undefined,
    performer?: Reference,
  ) => {
    const obs = Object.entries(labData).reduce<Array<Observation>>((acc, [key, value]) => {
      if (!value) return acc

      const obsData = selectedDR?.observations.data

      const observationInitialValue =
        obsData?.[key] && `${obsData?.[key].value?.Quantity?.value ?? obsData?.[key].value?.string}`

      if (observationInitialValue === value && selectedDR) {
        return [...acc, ...(obsData?.[key] ? [obsData[key]] : [])]
      }

      if (observationRequestData[key]?.code?.coding) {
        return [
          ...acc,
          getObservationInitialValue(
            patientRef,
            observationRequestData[key].code.coding as Coding[],
            value,
            performer ?? loggedInPractitionerRoleRef,
          ),
        ]
      }

      return acc
    }, Array<Observation>())

    if (validPatientGpPractRoleRef) {
      savePlanLabs(
        {
          qrId,
          observations: obs,
          performer,
          patientRef,
          requester: validPatientGpPractRoleRef,
          runAlgorithmCode,
          icd10,
          saveDraft: !runCalculate,
          dr: selectedDR?.dr,
          order,
        },
        {
          onSettled: runCalculate
            ? (_, error) => {
                if (!error)
                  displayNotificationSuccess(
                    "Generating report. This could take a while. Please wait a few seconds.",
                    10000,
                  )
              }
            : undefined,
        },
      )
    }
  }

  const customEmptyAddButton = (
    <div className="flex items-baseline gap-4 hover:animate-none hover:transition-none justify-center">
      <Button
        label="Generate New Report"
        onClick={handleAdd}
        icon={appModules[ModulesId.MC].getIcon()}
        className="btnNewMC"
      />
      <Tooltip
        target=".btnNewMC"
        content="Use Existing Labs and Questionnaire to Generate a New Report"
        position="bottom"
      />
    </div>
  )

  const prevDRResults = useMemo(
    () =>
      isNew
        ? groupedCarePlans
            .flatMap(({ items }) => items)
            .reduce((acc, data) => {
              return [...acc, ...(data.results ? [...data.results] : [])]
            }, Array<DRData>())
        : [],
    [isNew, groupedCarePlans],
  )

  return isLoading ? (
    loader()
  ) : (
    <>
      <DataContainerForm
        hasData={groupedCarePlans.length > 0}
        showForm={showSlide}
        formTitle="Assessment"
        iconDataNotFound={appModules[ModulesId.MC].getIcon()}
        formInitialValue={initialValue}
        onButtonAddClick={handleAdd}
        customAddButtonText="Generate New Report"
        customAddIcon={appModules[ModulesId.MC].getIcon()}
        onSubmit={({ qrId, labData, performer, selectedDR, order }) =>
          onSubmit(qrId, labData, selectedDR, order, performer)
        }
        onCancel={resetForm}
        disableSave={isLoadingFormData || !selectedPDid || !validPatientGpPractRoleRef}
        customEmptyAddButton={customEmptyAddButton}
        disabledAddButton={!hasGP || !validPatientGpPractRoleRef}
        customSaveButton={
          <SplitButton
            size="lg"
            type="submit"
            loading={isUpdating || isEvaluating || isSaving}
            autoUpdateMainAction
            actions={[
              {
                label: isEvaluating ? "Generating Report" : "Generate Report",
                description: `Save current configuration and generate report`,
                onSelectClick: () => {
                  setRunCalculate(true)
                },
                disabled: !selectedPDid || !validPatientGpPractRoleRef,
                disabledReason: !selectedPDid
                  ? "Select a valid type"
                  : "Patient's general practitioner hasn't a valid role for the current organization.",
              },
              {
                label: "Save Draft",
                description: "Save current configuration as draft",
              },
            ]}
          />
        }
        form={
          <>
            <div className="flex flex-col">
              <label className="text-sm font-medium text-gray-700 mb-2">Type</label>
              <Dropdown
                options={planDefinitions}
                optionLabel="title"
                optionValue="id"
                value={selectedPDid}
                onChange={(e) => {
                  setSelectedPDid(e.value)
                }}
                className="p-inputtext-sm"
                disabled={isSaving || isEvaluating}
              />
            </div>
            {selectedPDid && (
              <QuestionnaireAndLabDataForm
                {...{
                  isLoading: isLoadingFormData,
                  questionnaire,
                  questionnaireResponses,
                  observationRequestData: Object.values(observationRequestData),
                  observationData: [...observationData, ...prevDRResults],
                  performers,
                  selectedQR: questionnaireResponses?.[planQrId as string],
                  reloadData,
                  isProcessingUpdates: isSaving || isEvaluating,
                }}
              />
            )}
          </>
        }
      >
        <div className="h-full overflow-auto">
          <InfiniteScroll hasMore={hasNextPage} loadMore={() => fetchNextPage()} loader={loader()}>
            <GroupedList
              className="grow"
              groups={groupedCarePlans}
              collapsible
              renderItem={(item: CarePlanWithDR) => {
                const isReleasing = isReleasingDR && selectedDRId === item.diagnosticReport?.id
                const isDownloadingReport = isLoadingUrl && selectedPlanId === item.plan?.id
                const isPrintingQR = isLoadingPrint && selectedPlanQRId === item.qrId
                const isArchiving = isUpdating && selectedPlanId === item.plan?.id

                return (
                  <StackedListItem
                    modelData={assessmentItemModel(
                      item,
                      () => showPlan(item.plan?.id as string),
                      releasePlanDR,
                      downloadResult,
                      printQR,
                      archivePlan,
                      isReleasing || isDownloadingReport || isPrintingQR || isArchiving,
                      hasGP
                        ? (data) => {
                            handleEdit()
                            planQrId = data.qrId
                            edit(data)
                          }
                        : undefined,
                    )}
                  />
                )
              }}
              renderDivider={(key) => (
                <div className="sticky -top-1 z-10 bg-white pt-4">
                  <div className="border-t">
                    <div className="text-sm text-gray-600 font-semibold bg-white w-fit pr-1 -translate-y-3 translate-x-3">
                      {strCapitalize(key)}
                    </div>
                  </div>
                </div>
              )}
            />
          </InfiniteScroll>
        </div>
        <ConfirmDialog />
      </DataContainerForm>
    </>
  )
}

type Props = {
  searchText?: string
  statusFilter?: string[]
}

export { MCContainerData }
