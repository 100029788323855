import { CodeableConcept } from "fhir"

import { consentGender } from "data"

const isValidGenderConsent = (flags?: CodeableConcept[], gender?: string) =>
  !flags ||
  flags.some(
    ({ coding }) =>
      !gender ||
      coding?.every(
        ({ code }) =>
          ![consentGender.male.coding?.[0].code, consentGender.female.coding?.[0]?.code].includes(code as string),
      ) ||
      coding?.some(
        ({ code }) =>
          (gender === "male" && code === consentGender.male.coding?.[0].code) ||
          (gender === "female" && code === consentGender.female.coding?.[0].code),
      ),
  )

export { isValidGenderConsent }
