import { faHospitalUser } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, ModuleRenderSideType, AppModule } from "commons/Module"

class Patient360Module extends AppModule {
  constructor(workspaceElement: JSX.Element) {
    super()
    this.id = ModulesId.PATIENT_360
    this.display = "Patient360"
    this.icon = faHospitalUser
    this.hideBySetting = true
    this.workspaceElement = workspaceElement
    this.renderSideType = ModuleRenderSideType.WORKSPACE
  }
}

export { Patient360Module }
