import { BundleEntryArray } from "fhir"
import { Column } from "primereact/column"
import { DataTable, DataTableSelectionMultipleChangeEvent } from "primereact/datatable"
import { PropsWithChildren } from "react"

const ObservationsTable = ({
  observationsEntries,
  selectedEntries,
  children,
  tableDataKey,
  onSelectionChange,
}: PropsWithChildren<Props>) => (
  <DataTable
    value={observationsEntries}
    selection={selectedEntries ?? []}
    editMode="row"
    dataKey={tableDataKey}
    size="small"
    selectionMode="multiple"
    onSelectionChange={onSelectionChange}
    className="w-full h-full overflow-y-auto"
  >
    {selectedEntries && <Column selectionMode="multiple" headerStyle={{ minWidth: "3rem" }} />}
    {children}
  </DataTable>
)

type Props = {
  tableDataKey: string
  observationsEntries: BundleEntryArray[]
  selectedEntries?: BundleEntryArray[]
  onSelectionChange: (e: DataTableSelectionMultipleChangeEvent<BundleEntryArray[]>) => void
}

export { ObservationsTable }
