import { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useChartContext } from "chart-view"
import { ModulesId } from "commons"
import { PLAN_SUBVIEWS, Plan, PlanProvider } from "commons/care-plans"
import { useAppModuleContext } from "internals"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { PlanListContainer } from "./PlanListContainer"

const PlansContainer = () => {
  const { currentOrganizationId: organizationId } = useOrganizationContext()
  const { appSubModules } = useAppModuleContext()
  const { patient } = usePatientContext()
  const { setActiveSubModule, activeSubModule, setSearchData } = useChartContext()
  const [params, setParams] = useSearchParams()

  const [selectedPlan, setSelectedPlan] = useState<Plan>()

  const updateSubview = useCallback(
    (subviewId?: PLAN_SUBVIEWS, planId?: string) => {
      if (params.get("subview") || params.get("planId")) {
        params.delete("subview")
        params.delete("planId")
      }
      if (subviewId) {
        params.append("subview", subviewId)
      } else {
        params.delete("subview")
        setSelectedPlan(undefined)
      }

      if (planId) {
        params.append("planId", planId)
      } else {
        params.delete("planId")
      }
      setSearchData({ showSearch: false })

      setParams(params)
    },
    [params, setParams, setSearchData],
  )

  useEffect(() => {
    let subview = null
    const subviewParam = params.get("subview")
    if (params.get("view") === ModulesId.PLANS) {
      if (subviewParam) {
        subview = appSubModules["plan"][subviewParam]

        if (!subview) {
          setSelectedPlan(undefined)
          updateSubview()
        }
      }
    }

    setActiveSubModule(subview)
  }, [params, setActiveSubModule, appSubModules])

  return (
    <PlanProvider
      patient={patient}
      currentOrganizationId={organizationId}
      plan={selectedPlan}
      updateCurrentPlan={(plan) => setSelectedPlan(plan)}
      navigateToSubview={updateSubview}
    >
      {activeSubModule ? activeSubModule.renderContent() : <PlanListContainer />}
    </PlanProvider>
  )
}

export { PlansContainer }
