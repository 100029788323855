import { useQuery } from "@tanstack/react-query"

import { PAYMENT_METHODS, organizationSettingId } from "data"

import { useSettingQueryFunction } from "./useSettings"
import { settingsQueryKeys } from "../../query-keys"

const useEnabledPaymentMethods = (currentOrganizationId: string) => {
  const getSetting = useSettingQueryFunction()

  const queryKey = settingsQueryKeys.paymentMethods(currentOrganizationId)

  const { data, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: async () => {
      const paymentMethodsVisibility = await getSetting(currentOrganizationId, organizationSettingId.paymentMethods)

      return (
        paymentMethodsVisibility.items?.reduce<string[]>(
          (acc, item) =>
            item.value?.boolean && item.code ? [...acc, item.code.code?.replace("-enabled", "") as string] : acc,
          [PAYMENT_METHODS.CREDIT_CARD],
        ) ?? [PAYMENT_METHODS.CREDIT_CARD]
      )
    },

    meta: { context: { queryKey } },
  })

  return { enabledPaymentMethods: data, isLoadingPaymentMethods: isLoading || isFetching }
}

export { useEnabledPaymentMethods }
