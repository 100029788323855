import { classNames } from "primereact/utils"
import { DetailedHTMLProps, InputHTMLAttributes } from "react"

const Input = ({ type = "text", className, ...props }: Props) => (
  <input
    type={type}
    className={classNames(
      "placeholder:text-slate-400 block w-full text-sm border border-solid border-gray-300 rounded-md",
      className,
    )}
    {...props}
  />
)

type Props = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export { Input }
