import { useMutation } from "@tanstack/react-query"
import { Bundle, Parameters } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

type ParamName =
  | "labs"
  | "vitals"
  | "conditions"
  | "social-history"
  | "family-history"
  | "allergies"
  | "medication-statements"

const usePreviewPatientInfo = (paramName: ParamName, onSuccess: (data: Bundle) => void) => {
  const { operationRequest } = useClient()

  const preview = async ({ patId }: { patId: string }): Promise<Bundle> => {
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "labs",
          value: {
            boolean: paramName === "labs",
          },
        },
        {
          name: "vitals",
          value: {
            boolean: paramName === "vitals",
          },
        },
        {
          name: "conditions",
          value: {
            boolean: paramName === "conditions",
          },
        },
        {
          name: "social-history",
          value: {
            boolean: paramName === "social-history",
          },
        },
        {
          name: "family-history",
          value: {
            boolean: paramName === "family-history",
          },
        },
        {
          name: "allergies",
          value: {
            boolean: paramName === "allergies",
          },
        },
        {
          name: "medication-statements",
          value: {
            boolean: paramName === "medication-statements",
          },
        },
      ],
    }

    return operationRequest({ endpoint: "Patient", method: "POST", operation: "search-info", id: patId, parameters })
  }

  const { mutate: previewPatientInfo, isPending } = useMutation({
    mutationFn: preview,
    onSuccess: (bundle) => {
      onSuccess(bundle)
    },
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
  })

  return {
    previewPatientInfo,
    isLoadingPreview: isPending,
  }
}

export { usePreviewPatientInfo }
