import { useFavicon } from "primereact/hooks"

import { useOrganizationContext } from "organization"

const ApplySettings = () => {
  const { branding, currentOrganization } = useOrganizationContext()

  useFavicon(branding.iconLogo ?? "", "icon")

  const hslHoverValues = (hslColor: string) => {
    const values = hslColor
      .replace("%", "")
      .split(" ")
      .map((v) => parseInt(v))
    return [values[0], values[1] - 7, values[2] > 20 ? values[2] - 7 : values[2] - 3]
  }

  const applyBrandingColor = (colorName: string, brandingColor?: string) => {
    if (brandingColor && new RegExp(/^\d+deg \d+% \d+%$/g).test(brandingColor)) {
      const hoverHsl = hslHoverValues(brandingColor)
      document.body.style.setProperty(`--color-${colorName}`, brandingColor as string)
      document.body.style.setProperty(`--color-${colorName}-hover`, `${hoverHsl[0]}deg ${hoverHsl[1]}% ${hoverHsl[2]}%`)
    }
  }

  applyBrandingColor("primary", branding.primaryColor)
  applyBrandingColor("secondary", branding.secondaryColor)
  applyBrandingColor("photo-bg-color", branding.photoBgColor)

  if (currentOrganization.name) document.title = currentOrganization.name

  return null
}

export { ApplySettings }
