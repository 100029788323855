import { useQuery } from "@tanstack/react-query"
import { ValueSet } from "fhir"

import { useClient } from "api"
import { ValueSetIds } from "commons"

import { valueSetQueryKeys } from "../query-keys"

const useValueSet = ({valueSetId, filter, enabled}: ValueSetArgs) => {
  filter = filter?.trim()

  const { read } = useClient()
  const queryKey = valueSetQueryKeys.list(valueSetId, filter)

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const filters = filter ? new URLSearchParams({ filter, count: "30" }) : undefined
      const { expansion } = await read<ValueSet>({
        endpoint: "ValueSet",
        id: valueSetId,
        filters,
        operation: "expand",
        signal,
      })
      return expansion?.contains?.map(({ code, system, display }) => ({ code, system, display })) ?? []
    },
    enabled,
    meta: { context: { queryKey, valueSetId, filter } },
  })

  return { codes: data, isLoading, isFetching, refetch }
}

interface ValueSetArgs {
  valueSetId: ValueSetIds, filter?: string, enabled?: boolean
}

export { useValueSet }
