import { faHeartPulse } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { Patient360VitalsObservations } from "../../../patient360/components/Patient360VitalsObservations"

class Vitals360SubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.VITALS_360
    this.display = "Vitals"
    this.parent = ModulesId.PATIENT_360
    this.icon = faHeartPulse
    this.content = <Patient360VitalsObservations />
  }
}

export { Vitals360SubModule }
