import { FC } from "react"
import { useSearchParams } from "react-router-dom"

import { usePatientContext } from "patients"
import { Button, SkeletonLoader, StackedListItem } from "commons"
import { useOrganizationContext } from "organization"

import { useOpenEncounter } from "../hooks"
import { EncounterWrapUp } from "./details/EncounterWrapUp"
import { EncounterFinishButton } from "./EncounterFinishButton"
import { encounterModelBuilder } from "./encounterModelBuilder"

const OpenEncounter: FC = () => {
  const { currentOrganizationId, isExemptLabPayment } = useOrganizationContext()
  const { patientId, patientRef } = usePatientContext()
  const { openEncounter, isLoading } = useOpenEncounter(patientId)
  const [searchParams, setSearchParams] = useSearchParams()

  if (isLoading) return <SkeletonLoader repeats={2} loaderType="two-lines" />

  const closeWrapUp = () => {
    const params = new URLSearchParams({
      ...(searchParams.has("kp") ? { kp: searchParams.get("kp") as string } : {}),
    })
    setSearchParams(params)
  }

  if (!openEncounter && !isLoading) closeWrapUp()

  return openEncounter ? (
    <div className="flex flex-col overflow-hidden h-full space-y-4 relative">
      <StackedListItem modelData={encounterModelBuilder(openEncounter)} className="py-0 @container" />
      <div className="flex flex-col grow overflow-y-auto">
        <EncounterWrapUp
          encounter={openEncounter}
          patientId={patientId}
          organizationId={currentOrganizationId}
          isExemptLabPayment={isExemptLabPayment}
        />
      </div>
      <div className="flex flex-shrink-0 justify-end gap-3 px-4 py-4 bg-white border-t border-gray-200 h-fit">
        <Button label="Resume" buttonStyle="default" onClick={closeWrapUp} />
        <EncounterFinishButton
          encounter={openEncounter}
          patientId={patientId}
          patientRef={patientRef}
          showStopAction
          onEncounterFinished={closeWrapUp}
        />
      </div>
    </div>
  ) : null
}

export { OpenEncounter }
