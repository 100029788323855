import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query"
import { BundleEntryArray } from "fhir"
import { useEffect, useMemo } from "react"

import { useClient } from "api"
import { medicationStatementQueryKeys } from "medications-statement"

import { patient360QueryKeys } from "../query-keys"

const usePatientMedicationStatements = (patientId: string) => {
  const { search } = useClient()
  const queryClient = useQueryClient()
  const queryKey = patient360QueryKeys.medicationsStatement(patientId)

  const { data, isLoading, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    PatientMedicationStatementsQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ pageParam = 1, signal }) => {
      const filters = new URLSearchParams({
        "identifier:text": "patient360",
        _count: "20",
        _page: `${pageParam}`,
      })

      const bundle = await search({ endpoint: `Patient/${patientId}/MedicationStatement`, filters, signal })

      const medicationStatementsEntries = bundle.entry ?? []

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { medicationStatementsEntries, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: medicationStatementQueryKeys.all })
  }, [data])

  const { medicationStatementsEntries, count } = useMemo(() => {
    let count = 0
    const newData = data?.pages.reduce<{
      medicationStatementsEntries: BundleEntryArray[]
      next: number | undefined
      total: number
    }>(
      (prev, current) => {
        const newObservations = prev?.medicationStatementsEntries.concat(current.medicationStatementsEntries)
        count += current.medicationStatementsEntries.length

        return { medicationStatementsEntries: newObservations, next: current.next, total: current.total }
      },
      { medicationStatementsEntries: [], next: 0, total: 0 },
    )

    return {
      medicationStatementsEntries: newData?.medicationStatementsEntries ?? [],
      count,
    }
  }, [data?.pages])

  if (isError) {
    throw error
  }

  return {
    medicationStatementsEntries,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadObservations: refetch,
  }
}

type PatientMedicationStatementsQueryData = {
  medicationStatementsEntries: BundleEntryArray[]
  next: number | undefined
  total: number
}

export { usePatientMedicationStatements }
