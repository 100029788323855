import { SelectButton } from "primereact/selectbutton"

import { SectionContainer } from "commons"

import { prescriptionViewOptions } from "../data"
import { Contents } from "../types"

const PrescriptionsRefillsContainer = ({ activeContent, onUpdateContent }: Props) => (
  <SectionContainer>
    <div className="h-12 my-3">
      <SelectButton
        value={activeContent}
        options={prescriptionViewOptions}
        optionLabel="name"
        optionValue="value"
        allowEmpty={false}
        onChange={(e) => {
          onUpdateContent(e.value as Contents)
        }}
      />
    </div>
    <h2 className="py-6">Not implemented yet</h2>
  </SectionContainer>
)

type Props = {
  activeContent: Contents
  onUpdateContent(activeContent: Contents): void
}

export { PrescriptionsRefillsContainer }
