import { SettingItemArray } from "fhir"
import { Dropdown } from "primereact/dropdown"
import { InputSwitch } from "primereact/inputswitch"

import { consentGender } from "data"

const ConsentsSetting = ({ setting, isDisabled, onChange }: Props) => {
  const consentGenderOptions = [
    {
      label: consentGender.male.text,
      value: [consentGender.male],
    },
    {
      label: consentGender.female.text,
      value: [consentGender.female],
    },
    {
      label: "None",
      value: [{}],
    },
  ]

  return (
    <div className="flex flex-col border-b last:border-b-0 mt-4">
      <div className="flex items-center justify-between flex-1">
        <div>
          <span className="font-medium capitalize ml-2">
            {setting.code?.display?.toLocaleLowerCase().replace("enabled", "") || "Unspecified"}
          </span>
        </div>
        <div className="items-center mr-10">
          <InputSwitch
            disabled={isDisabled}
            className="button-primary"
            checked={setting.value?.boolean ?? false}
            onChange={(e) => {
              onChange(setting.code?.code as string, { ...setting, value: { ...setting.value, boolean: e.value } })
            }}
          />
        </div>
      </div>
      <div className="flex items-center justify-between ml-10 mr-10 py-4">
        <div className="flex flex-col">
          <span className="text-gray-700">Gender</span>
          <span className="text-sm text-gray-500">Set gender definition for consent</span>
        </div>
        <Dropdown
          className="p-inputtext-sm w-60"
          options={consentGenderOptions}
          value={setting.flag ?? consentGenderOptions[2].value}
          disabled={!setting.value?.boolean}
          onChange={(e) => {
            onChange(setting.code?.code as string, {
              ...setting,
              flag: e.value?.[0]?.coding?.[0]?.code ? e.value : undefined,
            })
          }}
        />
      </div>
    </div>
  )
}

type Props = {
  setting: SettingItemArray
  isDisabled: boolean
  onChange(code: string, item: SettingItemArray): void
}

export { ConsentsSetting }
