import { ModulesId, SubModule } from "commons/Module"
import { PlanEnrollment } from "commons/care-plans"

class PlanEnrollSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.PLAN_ENROLL
    this.parent = ModulesId.PLANS
    this.content = <PlanEnrollment />
    this.display = "Enrollment"
  }
}

export { PlanEnrollSubModule }
