import pluralize from "pluralize"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"

import { LaboratoryOrderPanel } from "commons/labs"

import { LaboratoryOrderPanelItem } from "./LaboratoryOrderPanelItem"

const RemovePanelsDialog = ({ panels, onRemove, onCancel }: Props) => (
  <Dialog
    header="Confirm panel deletion"
    modal
    visible={true}
    onHide={onCancel}
    breakpoints={{ "960px": "75vw" }}
    style={{ width: "35vw" }}
    footer={
      <div className="pt-2">
        <Button label="Cancel" className="button-default text-sm" onClick={onCancel} />
        <Button label="Remove" className="button-primary text-sm" onClick={onRemove} />
      </div>
    }
  >
    <div className="flex flex-col gap-2">
      <p className="font-semibold">The following {pluralize("panel", panels.length)} will be removed from order</p>
      {panels.map((panel, index) => (
        <LaboratoryOrderPanelItem key={panel.profile.id ?? index} panel={panel} isReadonly />
      ))}
    </div>
  </Dialog>
)

type Props = {
  panels: LaboratoryOrderPanel[]
  onCancel(): void
  onRemove(): void
}

export { RemovePanelsDialog }
