import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Condition } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { displayNotificationSuccess } from "utils"

import { conditionsQueryKeys } from "../query-keys"

const useCreateCondition = (onSettled: () => void) => {
  const { create } = useClient()
  const queryClient = useQueryClient()

  const newCondition = async (condition: Condition) => create("Condition", condition)

  const { mutate: createCondition, isPending: isAdding } = useMutation({
    mutationFn: newCondition,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { subject }) => {
      queryClient.refetchQueries({
        queryKey: subject.id ? conditionsQueryKeys.list(subject.id) : conditionsQueryKeys.all,
        type: subject.id ? "all" : undefined,
      })
      displayNotificationSuccess("Condition added successfully!")
      // datadogLogs.logger.info(`Condition of ${subject.id} added successfully!`)
    },
    onSettled,
  })

  return { createCondition, isAdding }
}

export { useCreateCondition }
