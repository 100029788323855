import { Coding, Medication, MedicationAdministration, PlanDefinitionActionArrayActionArray, Reference } from "fhir"

import { SYSTEM_VALUES } from "system-values"

import { ProcedureData, ProcedureKind } from "../types"
import { getProcedureKind } from "./transformers"

const sanitizeProcedureData = (
  procedure: ProcedureData,
  recorder: Reference,
  requester: Reference,
  performerPR: Reference,
  planId: string | null,
): ProcedureData => {
  const performer = procedure.procedure.performer?.[0].actor
  const isMassageProcedure = getProcedureKind(procedure.procedure) === ProcedureKind.massage

  if (!isMassageProcedure) {
    procedure.configurationItem = procedure.configurationItem.map((med) => {
      const meds = (med.medicationAdministration?.contained as Medication[])?.reduce<Medication[]>(
        (acc, ma) => [
          ...acc,
          {
            ...ma,
            batch: {
              lotNumber: ma?.batch?.lotNumber,
              expirationDate: ma?.batch?.expirationDate,
            },
          },
        ],
        [],
      )
      return {
        medicationAdministration: {
          ...med.medicationAdministration,
          dosage: {
            ...med.medicationAdministration?.dosage,
            ...{
              site: { ...med.bodySite, coding: [...(med.bodySite?.coding ?? []), ...(med.zone ? [med.zone] : [])] },
            },
          },
          performer: [{ actor: performer }],
          contained: meds,
        } as MedicationAdministration,
        ...(med.medicationRequest
          ? {
              medicationRequest: {
                ...med.medicationRequest,
                performer: performerPR,
                requester,
                recorder,
              },
            }
          : {}),
      }
    })
  } else {
    procedure.procedure.bodySite = procedure.configurationItem.map(({ bodySite, zone }) => ({
      ...bodySite,
      coding: [...(bodySite?.coding ?? []), ...(zone ? [zone] : [])],
    }))
  }

  if (planId) procedure.procedure.basedOn = [{ id: planId, resourceType: "CarePlan" }]

  return procedure
}

const getInvCode = (coding?: Coding[]) =>
  coding?.find(({ system }) => system === SYSTEM_VALUES.MYEVEXIAS_MEDICATION)?.code as string

const getConfigCode = (action: PlanDefinitionActionArrayActionArray) =>
  action.code?.find(({ coding }) => coding?.some(({ system }) => system === SYSTEM_VALUES.PROCEDURE_CONFIGURATION))
    ?.coding?.[0]?.code

export { getConfigCode, getInvCode, sanitizeProcedureData }
