import { faCalendarDays, faFilePdf, faPencil } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MenuItem } from "primereact/menuitem"

import { MenuStyles, QuestionnaireData, StackedListItemProps } from "commons"
import { formatsByTypes } from "data"
import { formatDate } from "utils"

const surveyModelBuilder = ({
  item,
  onClick,
  showAsExternal,
  showMenuActions,
  print,
  loading,
}: SurveyModelBuilderArgs): StackedListItemProps => {
  const getMenuItems = () => {
    const menuItems: MenuItem[] = [
      ...(item?.qResponse?.status === "in-progress" && onClick
        ? [
            {
              icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
              label: " Edit questionnaire",
              command: onClick,
            },
          ]
        : []),
      {
        icon: <FontAwesomeIcon icon={faFilePdf} size="sm" className="mr-2" />,
        label: "Download answers",
        disabled: !item.qResponse?.id || item?.qResponse?.status === "in-progress",
        command: () => print?.(item.qResponse?.id ?? ""),
      },
    ]
    return menuItems
  }
  return {
    leftData: [
      {
        lineItems: [
          { name: "Questionnaire", value: item.questionnaire.title },
          { name: "Version", value: item.questionnaire.version },
        ],
      },
      ...(item.qResponse?.authored
        ? [
            {
              lineItems: [
                {
                  name: "Authored",
                  value: formatDate(new Date(item.qResponse.authored.split(" ")[0]), formatsByTypes.LONG_DATE),
                  icon: faCalendarDays,
                },
              ],
            },
          ]
        : []),
    ],
    ...(showAsExternal
      ? {
          menu: [
            {
              label: "Show details",
              command: onClick,
            },
          ],
          menuStyle: MenuStyles.ExternalAction,
        }
      : {}),
    ...(showMenuActions
      ? {
          menu: getMenuItems(),
        }
      : {}),
    onClick: !showAsExternal && item?.qResponse?.status !== "in-progress" ? onClick : undefined,
    badge: item.qResponse?.status
      ? { text: item.qResponse.status, colorStyle: item.qResponse.status === "in-progress" ? "yellow" : "green" }
      : undefined,
    isLoading: loading,
  }
}

interface SurveyModelBuilderArgs {
  item: QuestionnaireData
  onClick?: () => void
  showAsExternal?: boolean
  showMenuActions?: boolean
  print?: (qrId: string) => void
  loading?: boolean
}

export { surveyModelBuilder }
