import { Calendar, CalendarDateTemplateEvent } from "primereact/calendar"
import { Badge } from "primereact/badge"
import { Tooltip } from "primereact/tooltip"
import { EventInput } from "@fullcalendar/core"
import { useState } from "react"

const AppointmentCalendar = ({ selectDate, currentDateAppointments }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date())

  const dateTemplate = (date: CalendarDateTemplateEvent) => {
    const dateStr = `${date?.year}-${date?.month + 1}-${date?.day}`
    const appointments = currentDateAppointments(new Date(date?.year, date?.month, date?.day))
    const count = appointments.length

    return count > 0 ? (
      <>
        <span className={`w-full h-full grid content-center justify-center date_${dateStr}`}>
          {date?.day}
          <Tooltip
            target={`.date_${dateStr}`}
            content={`${count} ${count !== 1 ? "appointments" : "appointment"}`}
            position="top"
            event="hover"
            className="text-xs p-0 m-0"
          />
        </span>
        <Badge severity="info" className={`absolute right-0.5 top-0.5 date_${dateStr}`} />
      </>
    ) : (
      <>{date?.day}</>
    )
  }

  return (
    <Calendar
      value={selectedDate}
      className="custom-appointment-calendar"
      panelClassName="border-none border-0"
      onChange={(e) => {
        selectDate(e.value as Date)
        setSelectedDate(e.value as Date)
      }}
      inline
      dateTemplate={dateTemplate}
    />
  )
}

type Props = {
  selectDate(date: Date): void
  currentDateAppointments(date: Date): Array<EventInput>
}
export { AppointmentCalendar }
