import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { codeableConceptAsString, getFirstPhone, humanNameAsString, PatientContactArray } from "fhir"
import { faEnvelope, faMobile } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGenderless, faMars, faPencil, faTrashCan, faVenus } from "@fortawesome/pro-solid-svg-icons"

import {
  ConfirmDialog,
  DataContainerSlideoverForm,
  StackedListContainer,
  StackedListItemProps,
  useCrudReducer,
  ModulesId,
} from "commons"
import { strCapitalize } from "utils"
import { useAppModuleContext } from "internals"

import { PatientContactForm } from "./PatientContactForm"
import { sanitizeContact, CONTACT_INITIAL_VALUES, contactValidationSchema } from "./validations"
import { usePatchPatient, usePatientContext } from "../hooks"

const PatientContacts = () => {
  const {
    patient: { contact },
    patient,
  } = usePatientContext()
  const { appSubModules } = useAppModuleContext()

  const { isNew, showSlide, initialValue, deleteIndex, editIndex, add, editWithIndex, reset, setDeleteIndex } =
    useCrudReducer({
      defaultEntity: CONTACT_INITIAL_VALUES,
    })

  const { patchPatient } = usePatchPatient(reset)

  const onSubmit = (contact: PatientContactArray) => {
    let newContacts = patient.contact ?? []
    if (isNew) newContacts = [...newContacts, sanitizeContact(contact)]
    else newContacts.splice(editIndex as number, 1, sanitizeContact(contact))

    patchPatient({ patientId: patient.id as string, patientData: { contact: newContacts, meta: patient.meta } })
  }

  const onDelete = () => {
    const newContacts = [...(patient.contact ?? [])]
    newContacts.splice(deleteIndex as number, 1)
    patchPatient({ patientId: patient.id as string, patientData: { contact: newContacts, meta: patient.meta } })
  }

  return (
    <DataContainerSlideoverForm
      messageDataNotFound="No contacts found"
      subMessageDataNotFound={false}
      hasData={!!contact?.length}
      showSlide={showSlide}
      formTitle="Contact"
      formInitialValue={initialValue}
      validationSchema={contactValidationSchema("address")}
      onSubmit={onSubmit}
      onCancel={reset}
      form={<PatientContactForm />}
      customAddButtonText="Add Contact"
      onButtonAddClick={add}
      iconDataNotFound={appSubModules["patient"][ModulesId.PATIENT_CONTACTS].getIcon()}
    >
      <div className="bg-white h-full overflow-auto">
        <StackedListContainer
          data={contact ?? []}
          itemModelBuilder={(item, index) =>
            contactsModel(
              item,
              () => editWithIndex(item, index),
              () => setDeleteIndex(index),
            )
          }
        />
      </div>
      <ConfirmDialog
        confirmText="Are you sure you want to remove this contact?"
        actionName="Remove"
        visible={deleteIndex !== undefined}
        onConfirm={onDelete}
        hideDialog={() => setDeleteIndex(undefined)}
      />
    </DataContainerSlideoverForm>
  )
}

const getGenderIcon = (gender: string): IconDefinition => {
  switch (gender) {
    case "female":
      return faVenus
    case "male":
      return faMars
    default:
      return faGenderless
  }
}

const contactsModel = (
  contact: PatientContactArray,
  onEdit: () => void,
  onDelete: () => void,
): StackedListItemProps => ({
  leftData: [
    {
      lineItems: [
        { name: "Name", value: humanNameAsString(contact.name) },
        { name: "Relationship", value: codeableConceptAsString(contact.relationship?.[0]) },
      ],
    },
    {
      lineItems: [
        ...(contact.telecom?.[0]
          ? [
              {
                name: strCapitalize(contact.telecom[0].system as string),
                value: contact.telecom[0].value,
                icon: faEnvelope,
              },
            ]
          : []),
        ...(contact.telecom?.[1]
          ? [
              {
                name: strCapitalize(contact.telecom[1].system as string),
                value: getFirstPhone(contact.telecom),
                icon: faMobile,
              },
            ]
          : []),
        {
          name: strCapitalize(contact.gender as string),
          icon: getGenderIcon(contact.gender as string),
        },
      ],
    },
  ],
  menu: [
    {
      label: "Edit",
      icon: <FontAwesomeIcon icon={faPencil} size="sm" className="mr-2" />,
      command: onEdit,
    },
    {
      label: "Delete",
      icon: <FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-2" />,
      command: onDelete,
    },
  ],
})

export { PatientContacts }
