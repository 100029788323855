import { Coding } from "fhir"

const proceduresQueryKeys = {
  list: (patientId: string) => ["patient/procedures", patientId],
  algInventory: (medCodes: Coding[]) => ["algInventory", medCodes],
  medInventory: (catalogId?: string) => ["medicationInventory", catalogId],
  calculator: (patientId: string, pdId?: string) => ["procedureCalculator", patientId, ...(pdId ? [pdId] : [])],
  catalogs: ["pelletCatalogs"],
  pd: {
    all: ["procedurePDFromPlan"] as const,
    pdAndQr: (carePlanId?: string | null) => [...proceduresQueryKeys.pd.all, ...(carePlanId ? [carePlanId] : [])],
  },
  definitions: (pdCanonical?: string) => ["procedurePlanDefinitions", ...(pdCanonical ? [pdCanonical] : [])],
}

export { proceduresQueryKeys }
