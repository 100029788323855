import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useClient } from "api"
import { CustomError } from "commons"
import { medsQueryKeys } from "commons/meds"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { ordersQueryKeys } from "orders"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

const useDeleteMedicationRequest = () => {
  const { remove } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const delMR = async (medicationRequestId: string) => remove("MedicationRequest", medicationRequestId)

  const { mutate: deleteMedicationRequest, isPending: isDeleting } = useMutation({
    mutationFn: delMR,
    onError: (error: CustomError, medicationRequestId) => {
      displayNotificationError(registerErrorTrace(error, { medicationRequestId }))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: medsQueryKeys.medicationRequestList(patientId, "medication", ["active"]),
        refetchType: "all",
      })
      queryClient.refetchQueries({ queryKey: medsQueryKeys.draft.list(patientId, "medication") })
      queryClient.refetchQueries({ queryKey: ordersQueryKeys.count.withPatientId(patientId), type: "all" })
      await queryClient.invalidateQueries({
        queryKey: ordersQueryKeys.list(patientId),
        refetchType: "all",
      })
      displayNotificationSuccess("Prescription discarded successfully!")
      // datadogLogs.logger.info("Prescription discarded successfully!", { patientId })
    },
  })

  return { deleteMedicationRequest, isDeleting }
}

export { useDeleteMedicationRequest }
