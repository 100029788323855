import { useSuspenseQuery } from "@tanstack/react-query"
import { Bundle, getResources, Parameters, Setting, SettingDefinition, SettingItemArray } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"
import { organizationSettingId } from "data"

import { settingsQueryKeys } from "../../query-keys"
import { ThemeDefinition } from "../../types"

const useBranding = (organizationId: string) => {
  const { operationRequest } = useClient()
  const queryKey = settingsQueryKeys.currentBranding(organizationId)

  const { data } = useSuspenseQuery({
    queryKey,
    queryFn: async () => {
      const parameters = {
        resourceType: "Parameters",
        parameter: [
          { name: "code", value: { code: organizationSettingId.branding } },
          { name: "owner", value: { Reference: { id: organizationId, resourceType: "Organization" } } },
        ],
      } as Parameters

      const bundle = await operationRequest<Bundle>({
        endpoint: "Setting",
        method: "POST",
        operation: "resolve",
        parameters,
      })
      const setting = getResources<Setting>(bundle, "Setting")?.[0]
      const definition = getResources<SettingDefinition>(bundle, "SettingDefinition")[0]

      return { setting: setting as Setting | undefined, definition }
    },
    meta: { context: { queryKey, organizationId } },
  })

  const { branding } = useMemo(() => {
    let brandingSettings: Record<string, SettingItemArray>

    if (data?.setting?.item) {
      brandingSettings = data.setting.item.reduce(
        (prev, item) => {
          return { ...prev, [item.code?.code as string]: item }
        },
        {} as Record<string, SettingItemArray>,
      )
    } else {
      brandingSettings = (data?.definition?.item ?? []).reduce(
        (prev, item) => ({
          ...prev,
          [item.code?.code as string]: {
            code: item.code,
            label: item.label,
            required: item.required,
            secure: item.secure,
            value: item.default,
          },
        }),
        {},
      )
    }

    const branding: ThemeDefinition & { ehrUrl: string | undefined } = {
      primaryColor: brandingSettings["primary-color"]?.value?.string,
      secondaryColor: brandingSettings["secondary-color"]?.value?.string,
      photoBgColor: brandingSettings["photo-bg-color"]?.value?.string,
      iconLogo: brandingSettings["icon-logo"]?.value?.uri,
      banner: brandingSettings["banner"]?.value?.uri,
      ehrUrl: brandingSettings["ehr-url"]?.value?.string,
    }

    return { branding }
  }, [data])

  return {
    branding,
  }
}

export { useBranding }
