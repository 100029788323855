import { useInfiniteQuery } from "@tanstack/react-query"
import { getResources, Invoice } from "fhir"
import { useMemo } from "react"

import { useClient } from "api"

import { invoicesQueryKeys } from "../query-keys"

const useInvoices = ({ patientId, statusFilter = [], searchText, key = "WK" }: InvoicesArgs) => {
  const { search } = useClient()

  const queryKey = invoicesQueryKeys.list(patientId, key, statusFilter, searchText)

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, refetch } = useInfiniteQuery<
    InvoicesQueryData,
    Error
  >({
    queryKey,
    queryFn: async ({ signal, pageParam = 1 }) => {
      const filters = new URLSearchParams({
        ...(searchText ? { "identifier:text": searchText } : {}),
        ...(statusFilter.length > 0 ? { status: statusFilter.join(",") } : {}),
        _count: "20",
        _page: `${pageParam}`,
        _sort: "-date",
      })
      const bundle = await search({ endpoint: `Patient/${patientId}/Invoice`, filters, signal })
      const invoices = getResources(bundle) as Invoice[]

      const next = bundle.link?.find(({ relation }) => relation === "next") ? (pageParam as number) + 1 : undefined

      return { invoices, next, total: bundle?.total ?? 0 }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next,
    meta: { context: { queryKey, patientId } },
  })
  const { requests: invoices, count } = useMemo(() => {
    const newData = data?.pages.flatMap((page) => page.invoices)
    const count = newData?.length
    return {
      requests: newData,
      count,
    }
  }, [data?.pages])

  return {
    invoices,
    isLoading,
    count,
    total: data?.pages?.[0]?.total ?? 0,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    reloadInvoices: refetch,
  }
}

interface InvoicesArgs {
  patientId: string
  statusFilter?: string[]
  searchText?: string
  key?: string
}

type InvoicesQueryData = {
  invoices: Invoice[]
  next: number | undefined
  total: number
}

export { useInvoices }
