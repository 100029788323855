import { useContext } from "react"

import { CPOEContext } from "../context/CPOEProvider"

const useCPOEContext = () => {
  const context = useContext(CPOEContext)

  if (context === undefined) {
    throw new Error("Couldn't create CPOE context")
  }

  return context
}

export { useCPOEContext }
