import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Observation } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"
import { usePatientContext } from "patients"
import { displayNotificationSuccess } from "utils"

import { socialHistoryQueryKeys } from "../query-keys"

const useCreateSocialHistory = (hideForm: () => void) => {
  const { create } = useClient()
  const queryClient = useQueryClient()
  const { patientId } = usePatientContext()

  const newSocialHistory = async (socialHistory: Observation) => create("Observation", socialHistory)

  const { mutate: createSocialHistory, isPending: isAdding } = useMutation({
    mutationFn: newSocialHistory,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: socialHistoryQueryKeys.withPatientId(patientId),
        refetchType: "all",
      })
      displayNotificationSuccess("Social history created successfully!")
      // const { id } = data
      // datadogLogs.logger.info(`Social history ${id} created successfully!`, { data })
    },
    onSettled: hideForm,
  })

  return { createSocialHistory, isAdding }
}

export { useCreateSocialHistory }
