import { asReference, CodeableConcept, Encounter, Patient } from "fhir"
import * as Yup from "yup"

import { SYSTEM_VALUES } from "system-values"

const getInitialValues = (patient: Patient): Encounter => ({
  status: "in-progress",
  type: [{ coding: undefined }],
  class: {
    code: "AMB",
    system: SYSTEM_VALUES.V3_ACTENCOUNTERCODE,
    display: "Ambulatory",
  },
  period: {
    start: new Date().toISOString(),
  },
  subject: asReference(patient),
})

const sanitize = ({ ...encounter }: Encounter) => {
  if (encounter.type?.length === 0 || encounter.type?.[0].coding === undefined) encounter.type = undefined

  return encounter
}

const validationSchema = Yup.object().shape({
  period: Yup.object().shape({
    start: Yup.string().required("Select start date"),
  }),
  type: Yup.array()
    .of(
      Yup.object().test("test-first-type", "Type is required", (value: CodeableConcept, context) => {
        return context?.path === "type[0]" ? value !== undefined && value?.coding !== undefined : true
      }),
    )
    .defined(),
})

export { getInitialValues, sanitize, validationSchema }
