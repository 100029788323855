import { Dosage, MedicationKnowledge, MedicationKnowledgeAdministrationGuidelinesArray } from "fhir"
import { createContext, useContext } from "react"

const PrescriptionSigContext = createContext<State>({
  medRecommendedDosage: undefined,
  getAdministrationGuideline: undefined,
})

PrescriptionSigContext.displayName = "PrescriptionSigContext"

const usePrescriptionSigContext = () => {
  const context = useContext(PrescriptionSigContext)

  if (!context) throw new Error("usePrescriptionSigContext must be used withing PrescriptionSigContext")

  return context
}

export type State = {
  medRecommendedDosage?: Record<string, Dosage[]>
  getAdministrationGuideline?: (
    mk: MedicationKnowledge,
    medRecommendedDosage?: Record<string, Dosage[]>,
  ) => MedicationKnowledgeAdministrationGuidelinesArray | undefined
}

export { PrescriptionSigContext, usePrescriptionSigContext }
