import { useState } from "react"
import { getAddress, Organization } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBuilding, faLocationDot } from "@fortawesome/pro-regular-svg-icons"
import { Dropdown } from "primereact/dropdown"
import { useNavigate } from "react-router-dom"
import { Button } from "primereact/button"

import { useLoginContext } from "security"

import { usePractitionerOrganizations } from "../hooks"

const OrganizationSelection = () => {
  const { loggedInPractitionerId } = useLoginContext()
  const { organizations } = usePractitionerOrganizations(loggedInPractitionerId)
  const [organizationId, setOrganizationId] = useState(organizations?.[0]?.id)

  const navigate = useNavigate()

  const orgTemplate = (org: Organization) => (
    <div className="text-sm">
      <div className="font-bold">
        <FontAwesomeIcon icon={faBuilding} className="fa-fw mr-1" />
        <span title="Organization" className="text-base">
          {org.name}
        </span>
      </div>
      <div title="Address">
        <FontAwesomeIcon icon={faLocationDot} className="fa-fw mr-1" />
        {getAddress(org.address)}
      </div>
    </div>
  )

  const navToPatient = (orgId: string) => navigate(`/orgs/${orgId}/patients`)

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full text-gray-400 text-sm">Please select your organization</div>
      <div className="flex mt-4 w-full">
        <Dropdown
          className="w-full"
          name="currentOrg"
          options={organizations}
          optionLabel="name"
          optionValue="id"
          value={organizationId}
          itemTemplate={orgTemplate}
          filterBy="name"
          resetFilterOnHide
          showFilterClear
          filter
          filterInputAutoFocus
          onChange={(e) => {
            if (e.value) {
              setOrganizationId(e.value)
            }
          }}
        />
      </div>
      <Button
        className="w-full button-primary text-center mt-10"
        label="Accept"
        onClick={organizationId ? () => navToPatient(organizationId) : undefined}
        disabled={!organizationId}
      />
    </div>
  )
}

export { OrganizationSelection }
