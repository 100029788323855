import { SelectButton } from "primereact/selectbutton"
import { startTransition, useReducer } from "react"

import { useChartContext } from "chart-view"
import { SearchWithStatus, useCrudReducer } from "commons"
import { medicationRequestStatus } from "data"
import { useOpenEncounter } from "encounter"
import { usePatientContext } from "patients"

import { prescriptionViewOptions } from "../data"
import { useUpdateMedicationRequest } from "../hooks"
import { Contents, MedicationRequestFormData } from "../types"
import { sanitize } from "../utils/formatters"
import { PrescriptionFormContainer } from "./form"
import { PrescriptionList } from "./prescriptions/PrescriptionList"

const PrescriptionsContainer = ({ activeContent, onUpdateContent }: Props) => {
  const { patientId } = usePatientContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { statusFilter, searchFilter, updateFilters, updateSearchText } = useReducerState()
  const chartContext = useChartContext()
  const {
    initialValue,
    showSlide: showForm,
    edit: editMR,
    reset,
  } = useCrudReducer({ defaultEntity: {} as MedicationRequestFormData })

  const { updateMedicationRequest } = useUpdateMedicationRequest(reset)

  const edit = (mr: MedicationRequestFormData) => {
    editMR(mr)
    chartContext.setSearchData({ showSearch: false })
  }

  const handleCancel = () => {
    reset()
  }

  const handleSubmit = (values: MedicationRequestFormData) => {
    updateMedicationRequest(sanitize({ mr: { ...values, encounter: openEncounterRef } }))
  }

  return showForm ? (
    <PrescriptionFormContainer initialValues={initialValue} isEditing onCancel={handleCancel} onSubmit={handleSubmit} />
  ) : (
    <>
      <div className="inline-flex justify-between h-12 w-full my-3 px-2">
        <SelectButton
          value={activeContent}
          options={prescriptionViewOptions}
          optionLabel="name"
          optionValue="value"
          allowEmpty={false}
          onChange={(e) => {
            onUpdateContent(e.value as Contents)
          }}
        />
        <SearchWithStatus
          placeholder="Search prescriptions"
          options={medicationRequestStatus}
          selectedItems={statusFilter}
          onStatusCheck={updateFilters}
          onSearch={(filter) => {
            startTransition(() => {
              updateSearchText(filter ?? "")
            })
          }}
        />
      </div>
      <PrescriptionList searchFilter={searchFilter} statusFilter={statusFilter} onEdit={edit} />
    </>
  )
}

type State = {
  statusFilter: Array<string>
  searchFilter?: string
}

const initialState = {
  statusFilter: ["active", "draft", "on-hold", "completed", "stopped"],
  searchFilter: undefined,
} as State

const reducer = (
  state: State,
  {
    type,
    payload,
  }: {
    type: "active-filters" | "update-search-filter"
    payload: Array<string> | string | undefined
  },
) => {
  switch (type) {
    case "active-filters":
      return {
        ...state,
        statusFilter: payload as string[],
      }
    case "update-search-filter":
      return {
        ...state,
        searchFilter: payload as string,
      }
    default:
      return state
  }
}

const useReducerState = () => {
  const [{ statusFilter, searchFilter }, dispatch] = useReducer(reducer, initialState)

  const updateFilters = (filters: string[]) => {
    dispatch({
      type: "active-filters",
      payload: filters,
    })
  }

  const updateSearchText = (filter: string) => {
    dispatch({ type: "update-search-filter", payload: filter })
  }

  return {
    statusFilter,
    searchFilter,
    updateFilters,
    updateSearchText,
  }
}

type Props = {
  activeContent: Contents
  onUpdateContent(activeContent: Contents): void
}

export { PrescriptionsContainer }
