import * as Yup from "yup"

import { PractitionerInfo } from "commons"
import { dispenseInterval, DispenseIntervalOption, MEDICATIONS_REGULATIONS_CODE } from "commons/meds"
import { Duration, MedicationKnowledge, Reference } from "fhir"
import { isValidNPIValue } from "identifier"
import { SYSTEM_VALUES } from "system-values"
import { unitToDays } from "utils"

import { getMKDispenseLimit } from "./transformers"

const exceedsSupplyDurationLimit = ({ durationDays, daysLimit }: { daysLimit?: number; durationDays?: number }) =>
  durationDays && daysLimit && durationDays > daysLimit

const prescriptionValidationSchema = (practitionersInfo: PractitionerInfo[]) =>
  Yup.object().shape({
    medicationField: Yup.object().typeError("Medication is required").required("Medication is required"),
    dispenseRequest: Yup.object().shape({
      quantity: Yup.object()
        .shape({ value: Yup.number().nullable().required("Quantity is required") })
        .test("test-max-dispense-limits", (formValue, context) => {
          const medicationDispenseLimit = getMKDispenseLimit(
            context?.options?.context?.medicationField as MedicationKnowledge,
          )

          const medicationDispenseQty = formValue?.value

          if (!medicationDispenseLimit || !medicationDispenseQty) return true

          return medicationDispenseQty > medicationDispenseLimit
            ? context.createError({
                message: `Dispense quantity limit (${medicationDispenseLimit}) surpassed`,
                path: "dispenseRequest.quantity.value",
              })
            : true
        }),
      shippingAddress: Yup.object()
        .nullable()
        .required("Shipping address is required")
        .test(
          "test-not-using-restricted-states",
          (context) => `Invalid address selected: This prescription cannot be shipped to ${context.value?.state}.`,
          (value, context) => {
            const restrictedStates = (context?.options?.context?.medicationField as MedicationKnowledge)?.regulatory
              ?.find(
                (regulation) =>
                  regulation?.code?.coding?.[0]?.code === MEDICATIONS_REGULATIONS_CODE.RESTRICTED_SHIPPING,
              )
              ?.regulatoryCharacteristic?.flatMap((characteristic) => characteristic?.value?.string)
            const valueState = value?.state

            if (!valueState || !restrictedStates) return true

            const isRestrictedState = restrictedStates.includes(valueState)

            return !isRestrictedState
          },
        ),
      dispenseInterval: Yup.object()
        .required("Dispense interval is required")
        .test("test-max-days-supply", "Max supply duration limit in days exceeded", (formValue, context) => {
          const medDispenseInterval: Duration | undefined = formValue

          if (!medDispenseInterval?.value) return true

          const maxDaysSupply = (context?.options?.context?.medicationField as MedicationKnowledge)?.regulatory?.find(
            (regulations) => regulations?.code?.coding?.[0]?.code === MEDICATIONS_REGULATIONS_CODE.MAX_DAYS_SUPPLY,
          )?.regulatoryCharacteristic?.[0]?.value?.quantity
          const specifiedDurationInDays = medDispenseInterval.value * unitToDays(medDispenseInterval.code)

          const supplyDurationExceeded = exceedsSupplyDurationLimit({
            durationDays: specifiedDurationInDays,
            daysLimit: maxDaysSupply?.value,
          })

          if (supplyDurationExceeded) return false

          const medDispenseQty = context.parent?.quantity?.value
          const discardAfter = (
            context?.options?.context?.medicationField as MedicationKnowledge
          )?.drugCharacteristic?.find(({ type }) => type?.coding?.[0]?.code === "discard-after")?.value?.Quantity
          const discardAfterDays =
            discardAfter?.value && discardAfter.value * unitToDays(discardAfter.code) * medDispenseQty

          const supplyLimitExceededByQuantity = exceedsSupplyDurationLimit({
            durationDays: discardAfterDays,
            daysLimit: maxDaysSupply?.value,
          })

          if (supplyLimitExceededByQuantity) {
            return context.createError({
              message: `⚠️ Quantity exceeds ${maxDaysSupply?.value} ${maxDaysSupply?.unit?.toLowerCase() ?? "days"} supply`,
              path: "dispenseRequest.quantity.value",
            })
          }

          return true
        }),
      numberOfRepeatsAllowed: Yup.number().when("dispenseInterval", {
        is: (val: DispenseIntervalOption) => val !== dispenseInterval[0],
        then: (schema) => schema.nullable().required("At least one refill is required"),
      }),
      performer: Yup.object().nullable().required("Pharmacy is required"),
    }),
    prescriptionQuantity: Yup.object().required("Unit is required"),
    requester: Yup.object()
      .test(
        "test-practitioner-systems",
        "This practitioner doesn't have a valid Lifefile ID",
        (value: Reference, context) => {
          const selectedPractInfo = practitionersInfo.find(
            ({ practitionerRoleRef }) => practitionerRoleRef?.id === value?.id,
          )
          if (!selectedPractInfo) {
            return context.createError({ message: "Prescriber is required" })
          }

          const selectedPractRoleSystems = selectedPractInfo?.practitionerRole?.identifier?.map(({ system }) => system)

          return Boolean(selectedPractRoleSystems?.includes(SYSTEM_VALUES.LIFEFILE_PRACTITIONER))
        },
      )
      .test(
        "test-practitioner-valid-npi",
        "This practitioner doesn't have a valid NPI identifier",
        (value: Reference, context) => {
          const selectedPractInfo = practitionersInfo.find(
            ({ practitionerRoleRef }) => practitionerRoleRef?.id === value?.id,
          )
          if (!selectedPractInfo) {
            return context.createError({ message: "Prescriber is required" })
          }

          const selectedPractNPIIdentifier = selectedPractInfo?.practitioner?.identifier?.find(
            ({ system }) => system === SYSTEM_VALUES.NPI,
          )
          if (!selectedPractNPIIdentifier || !selectedPractNPIIdentifier?.value) {
            return context.createError({ message: "This practitioner doesn't have a NPI identifier on file" })
          }

          return isValidNPIValue(selectedPractNPIIdentifier.value)
        },
      ),
    dosages: Yup.array().min(1, "At least one dosage is required").required("Dosage is required"),
  })

const medicationIngredientValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required("Name is required"),
    unit: Yup.string().required("Unit is required"),
    value: Yup.number().typeError("Must be number").positive("Must be positive").required("Value is required"),
  })
  .nullable()
  .optional()

const medicationValidationSchema = Yup.object().shape({
  code: Yup.object().shape({ text: Yup.string().required("Name is required") }),
  form: Yup.object().shape({
    coding: Yup.array().required("Dose form is required"),
  }),
  ingredient: Yup.array().test({
    message: "At least one ingredient is required",
    test: (arr) => (arr?.length ?? 0) > 0,
  }),
  amount: Yup.object().shape({
    numerator: Yup.object().shape({
      value: Yup.number().required("Amount is required"),
      unit: Yup.string().required("Unit is required"),
    }),
  }),
  newIngredient: medicationIngredientValidationSchema,
})

export { exceedsSupplyDurationLimit, medicationValidationSchema, prescriptionValidationSchema }
