import triad1 from "./images/triad1.svg"
import triad2 from "./images/triad2.svg"
import triad3 from "./images/triad3.svg"
import triad4 from "./images/triad4.svg"
import triad5 from "./images/triad5.svg"

const triads: Record<string, { img: string; borderColor: string; bgColor: string }> = {
  triad1: { img: triad1, borderColor: "border-orange-400", bgColor: "bg-[#e7cdc0]" },
  triad2: { img: triad2, borderColor: "border-cyan-600", bgColor: "bg-[#c5d6e8]" },
  triad3: { img: triad3, borderColor: "border-green-700", bgColor: "bg-[#c5cfaa]" },
  triad4: { img: triad4, borderColor: "border-red-500", bgColor: "bg-[#dfb9b8]" },
  triad5: { img: triad5, borderColor: "border-purple-900", bgColor: "bg-[#d3c6da]" },
}

export { triads }
