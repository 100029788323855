import { useContext } from "react"

import { CPOERequestsContext } from "../context/CPOERequestsProvider"

const useCPOERequestsContext = () => {
  const context = useContext(CPOERequestsContext)

  if (context === undefined) {
    throw new Error("Couldn't create CPOE Requests context")
  }

  return context
}

export const defaultCoveragesByType = {
  rx: undefined,
  "lab-order": undefined,
  nutraceutical: undefined,
  procedure: undefined,
}

export { useCPOERequestsContext }
