import { MedicationRequest, codeableConceptAsString } from "fhir"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDays } from "@fortawesome/pro-solid-svg-icons"
import { classNames } from "primereact/utils"
import { Chip } from "primereact/chip"
import { parseISO } from "date-fns"

import { formatDate } from "utils"
import { ListItem } from "commons"
import { formatsByTypes } from "data"

const MedicationRequestListItem = ({ medicationRequest, onClick }: Props) => {
  const getStatusColor = (status: string) => {
    return {
      "is-success": status === "active",
    }
  }

  const head = (
    <>
      <p title="Medication">{codeableConceptAsString(medicationRequest?.medication?.CodeableConcept)}</p>
      {medicationRequest.status && (
        <span title="Status">
          <Chip
            label={medicationRequest.status}
            className={classNames("ml-2 custom-chip", getStatusColor(medicationRequest.status))}
          />
        </span>
      )}
    </>
  )

  const details = (
    <div className="inline-flex items-center">
      {medicationRequest.authoredOn && (
        <span title="Authored on">
          <FontAwesomeIcon icon={faCalendarDays} className="mr-1.5 text-gray-400" />
          {formatDate(parseISO(medicationRequest.authoredOn), formatsByTypes.LONG_DATETIME)}
        </span>
      )}
    </div>
  )

  return <ListItem header={head} subHeader={details} onContentClick={onClick} />
}

type Props = {
  medicationRequest: MedicationRequest
  onClick?(): void
}

export { MedicationRequestListItem }
