import {
  codeableConceptAsString,
  Coding,
  ObservationDefinition,
  Questionnaire,
  QuestionnaireResponse,
  Reference,
} from "fhir"
import { useFormikContext } from "formik"
import { FC, useEffect, useMemo, useState } from "react"

import { useOpenEncounter } from "encounter"
import { usePatientContext } from "patients"
import { useAssignQuestionnaire } from "surveys"
import { getLoincCode } from "utils"

import { SkeletonLoader } from "../../../components/SkeletonLoader"
import { QRDropdownField } from "../../../forms/QRDropdownField"
import { DRData, ObsData } from "../../types"
import { DRDropdown } from "./DRDropdown"
import { LabDataInputFieldList, OptionalFieldDefinition } from "./LabDataInputFieldList"

const QuestionnaireAndLabDataForm: FC<Props> = ({
  isLoading,
  questionnaire,
  questionnaireResponses,
  observationRequestData,
  observationData,
  selectedQR,
  performers,
  requiredOD,
  isProcessingUpdates,
  reloadData,
}) => {
  const { patient, patientId } = usePatientContext()
  const { openEncounterRef } = useOpenEncounter(patientId)

  const { assignQuestionnaire } = useAssignQuestionnaire(reloadData)
  const assignSurvey = () =>
    assignQuestionnaire({ patient, questionnaire: questionnaire as Questionnaire, openEncounter: openEncounterRef })
  const {
    values: { selectedDR },
    setFieldValue,
  } = useFormikContext<{ performer: Reference; selectedDR?: DRData; qrId?: string }>()
  const filteredQRs = Object.values(questionnaireResponses).filter(({ status }) => status !== "entered-in-error")

  const mapObservationData = (observations?: ObsData) =>
    Object.values(observations?.data ?? {}).reduce<OptionalFieldDefinition[]>(
      (acc, obs) =>
        getLoincCode(obs.code.coding) !== "no code"
          ? [
              ...acc,
              {
                field: getLoincCode(obs.code.coding),
                label: codeableConceptAsString(obs.code),
              },
            ]
          : acc,
      [],
    )

  const selectedObservations = useMemo(() => selectedDR?.observations, [selectedDR])

  const [selectedResults, setSelectedResults] = useState<OptionalFieldDefinition[] | undefined>(
    selectedObservations ? mapObservationData(selectedObservations) : undefined,
  )
  useEffect(() => {
    if (selectedObservations?.values) {
      let values = {}
      Object.entries(selectedObservations.values).forEach(([key, value]) => {
        values = { ...values, ...(key && value ? { [key]: value } : {}) }
      })

      setFieldValue("labData", values)
    }
  }, [selectedObservations?.values])

  const filteredDRData = useMemo(
    () =>
      performers
        ? [
            ...observationData,
            ...(selectedDR && !observationData.some((obsData) => obsData.dr.id === selectedDR.dr.id)
              ? [selectedDR]
              : []),
          ].filter(
            (data) =>
              performers.some((p) => data.dr.performer?.[0]?.id === p.id) ||
              (data.observations.data &&
                performers.some((p) => Object.values(data.observations.data)?.[0]?.performer?.[0]?.id === p.id)),
          )
        : observationData,
    [selectedDR, observationData, performers],
  )

  const onChangeDR = (drData?: DRData) => {
    setFieldValue(
      "performer",
      performers?.find(
        (p) =>
          drData?.dr?.performer?.[0]?.id === p.id ||
          Object.values(drData?.observations?.data ?? {})?.[0]?.performer?.[0]?.id === p.id,
      ) ?? {},
    )
    setSelectedResults(drData ? mapObservationData(drData.observations) : undefined)
  }

  return (
    <div className="flex flex-col gap-6">
      <QRDropdownField
        field="qrId"
        questionnaire={questionnaire}
        options={selectedQR ? [selectedQR] : filteredQRs}
        onAssignSurvey={assignSurvey}
        onHideSurvey={reloadData}
        validation={(qrId) =>
          typeof qrId !== "string" || questionnaireResponses[qrId]?.status !== "completed"
            ? "Completed questionnaire is required"
            : undefined
        }
        optionLabel="title"
        optionValue="id"
        isLoading={isLoading}
        disabled={isProcessingUpdates}
      />
      {isLoading ? (
        <SkeletonLoader loaderType="one-line" repeats={2} />
      ) : (
        observationRequestData.length > 0 && (
          <div className="flex flex-col gap-6">
            {filteredDRData.length > 0 && (
              <DRDropdown
                options={filteredDRData}
                disabled={isProcessingUpdates}
                loading={isLoading}
                onChangeDR={onChangeDR}
              />
            )}
            <LabDataInputFieldList
              field="labData"
              label="Lab Data"
              addFieldLabel="Add result"
              fieldDefinitions={observationRequestData.map((od) => ({
                field: getLoincCode(od.code.coding),
                label: `${codeableConceptAsString(od.code)} - (${getLoincCode(od.code.coding)})`,
              }))}
              initialFields={selectedResults}
              performers={performers}
              requiredFields={requiredOD?.map((c) => ({ field: c.code as string, label: c.display as string }))}
              disabled={isProcessingUpdates}
            />
          </div>
        )
      )}
    </div>
  )
}

type Props = {
  isLoading: boolean
  questionnaire?: Questionnaire
  questionnaireResponses: Record<string, QuestionnaireResponse>
  observationRequestData: ObservationDefinition[]
  observationData: DRData[]
  selectedQR?: QuestionnaireResponse
  performers?: Reference[]
  requiredOD?: Coding[]
  isProcessingUpdates?: boolean
  reloadData(): void
}

export { QuestionnaireAndLabDataForm }
