import { faHistory } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { Patient360SocialHistoryObservations } from "../../../patient360/components/Patient360SocialHistoryObservations"

class SocialHistory360SubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.SOCIAL_HISTORY_360
    this.display = "Social History"
    this.parent = ModulesId.PATIENT_360
    this.icon = faHistory
    this.content = <Patient360SocialHistoryObservations />
  }
}

export { SocialHistory360SubModule }
