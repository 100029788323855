import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputTextarea } from "primereact/inputtextarea"
import { useState } from "react"

import { ConfirmDialog } from "commons"
import { getStatus, LaboratoryOrder } from "commons/labs"

const LabOrderCancelConfirmation = ({ laboratoryOrder, isCancelling, onCancel, onHide }: Props) => {
  const [cancelReason, setCancelReason] = useState<string>()

  const labOrderStatus = getStatus(laboratoryOrder.order)

  return ["draft", "on-hold"].includes(labOrderStatus?.code as string) ? (
    <ConfirmDialog
      confirmText="Are you sure you want to discard this lab"
      actionName="Discard lab"
      visible={true}
      isLoading={isCancelling}
      onConfirm={() => onCancel({ labOrder: laboratoryOrder, revokeReason: "" })}
      hideDialog={onHide}
      waitToFinish
    />
  ) : (
    <Dialog
      closable={true}
      header="Cancel lab"
      visible={true}
      draggable={false}
      dismissableMask={false}
      style={{ width: "35vw" }}
      onHide={onHide}
      footer={
        <div className="mt-2">
          <Button
            label="Cancel lab"
            className="button-primary"
            disabled={!cancelReason}
            onClick={() => onCancel({ labOrder: laboratoryOrder, revokeReason: cancelReason as string })}
            loading={isCancelling}
          />
        </div>
      }
    >
      <label>Please provide a reason</label>
      <InputTextarea
        className="w-full mt-2 resize-none"
        rows={3}
        value={cancelReason}
        onChange={(e) => setCancelReason(e.target.value)}
      />
    </Dialog>
  )
}

type Props = {
  laboratoryOrder: LaboratoryOrder
  onHide(): void
  isCancelling: boolean
  onCancel: ({ labOrder, revokeReason }: { labOrder: LaboratoryOrder; revokeReason: string }) => void
}

export { LabOrderCancelConfirmation }
