import { Reference } from "fhir"

import { DateField, DropdownField, ReferenceDropdownField, TextAreaField } from "commons"
import { taskPriorityCodes } from "data"

const TaskForm = ({ practitioners }: Props) => (
  <div className="relative p-fluid grid gap-4 mb-3">
    <ReferenceDropdownField
      field="for"
      options={practitioners ?? []}
      assignDefaultValue={false}
      label="Assign to"
      className="flex-1"
    />
    <TextAreaField field="description" label="Description" rows={1} className="flex-1" />
    <DropdownField
      field="priority"
      options={taskPriorityCodes}
      label="Priority"
      className="flex-1"
      optionLabel="display"
    />
    <DateField className="w-full" field="restriction.period.end" label="Date" />
    <TextAreaField field="note[0].text" label="Note" rows={5} className="flex-1" />
  </div>
)

type Props = {
  practitioners?: Reference[]
}

export { TaskForm }
