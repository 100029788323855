import { faCapsules, faFlask, faFolderSearch, faMemo, IconDefinition } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { TabPanel, TabView } from "primereact/tabview"
import { FC, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { SkeletonLoader } from "commons"
import { TabPanelItem } from "commons/types"
import { useOrganizationContext } from "organization"
import { usePatientContext } from "patients"

import { usePatientPlan } from "../../hooks"
import { MC_DETAILS_TAB } from "../../types"
import { LabsView } from "./LabsView"
import { NotesView } from "./NotesView"
import { NutraView } from "./NutraView"
import { Overview } from "./Overview"
import { QuestionnaireView } from "./QuestionnaireView"

const MCDetailsContainer = () => {
  const [searchParams] = useSearchParams()
  const planId = searchParams.get("mcPlanId")
  const { patientId } = usePatientContext()
  const { currentOrganizationId } = useOrganizationContext()
  const {
    triadsData,
    vitalityIndex,
    vitality,
    isLoading,
    isOnlySurvey,
    supplements,
    notes,
    questionnaireResponseId,
    labsResultPDFInfo,
  } = usePatientPlan(currentOrganizationId, patientId, planId as string)

  const [selectedView, setSelectedView] = useState(0)

  const tabPanelItems: TabPanelItem[] = [
    {
      id: MC_DETAILS_TAB.OVERVIEW,
      visible: true,
      header: { icon: faFolderSearch, title: MC_DETAILS_TAB.OVERVIEW },
      content: <Overview {...{ triadsData, vitality, vitalityIndex }} />,
    },
    {
      id: MC_DETAILS_TAB.LABS,
      visible: !isOnlySurvey,
      header: { icon: faFlask, title: MC_DETAILS_TAB.LABS },
      content: <LabsView triadsData={triadsData} labsResultPDFInfo={labsResultPDFInfo} />,
    },
    {
      id: MC_DETAILS_TAB.NUTRACEUTICALS,
      visible: true,
      header: { icon: faCapsules, title: MC_DETAILS_TAB.NUTRACEUTICALS },
      content: <NutraView supplements={supplements} />,
    },
    {
      id: MC_DETAILS_TAB.NOTES,
      visible: true,
      header: { icon: faMemo, title: MC_DETAILS_TAB.NOTES },
      content: <NotesView notes={notes} />,
    },
    {
      id: MC_DETAILS_TAB.QUESTIONNAIRES,
      visible: true,
      header: { icon: faMemo, title: MC_DETAILS_TAB.QUESTIONNAIRES },
      content: <QuestionnaireView qrId={questionnaireResponseId} />,
    },
  ]

  return isLoading ? (
    <SkeletonLoader repeats={4} loaderType="two-lines" />
  ) : (
    <div className="flex flex-col grow overflow-hidden h-full flex-1">
      <TabView
        className="mt-3 sticky-header flex flex-col flex-1 h-full"
        panelContainerClassName="h-full pb-0"
        activeIndex={selectedView}
        onTabChange={(e) => setSelectedView(e.index)}
      >
        {tabPanelItems.map(({ id, header: { icon, title }, visible, content }) => (
          <TabPanel
            key={id}
            header={<TabPanelHeader icon={icon} title={title} />}
            visible={visible}
            contentClassName="overflow-y-auto h-full pb-16"
          >
            {content}
          </TabPanel>
        ))}
      </TabView>
    </div>
  )
}

const TabPanelHeader: FC<{ icon: IconDefinition; title: string }> = ({ icon, title }) => (
  <div className="flex items-center text-primary gap-1">
    <FontAwesomeIcon icon={icon} />
    <span className="text-sm">{title}</span>
  </div>
)

export { MCDetailsContainer }
