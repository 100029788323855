import { ModulesId, SubModule } from "commons/Module"
import { PlanDetails } from "commons/care-plans"

class MCPlanDetailsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.PLAN_DETAILS
    this.parent = ModulesId.MC
    this.content = <PlanDetails />
    this.display = "Order Details"
  }
}

export { MCPlanDetailsSubModule }
