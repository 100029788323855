import { useMutation, useQueryClient } from "@tanstack/react-query"
import { Bundle, Coding, Consent, Patient, isConsent } from "fhir"

import { useClient } from "api"
import { CustomError } from "commons"
import { displayNotificationError } from "errors"
import { registerErrorTrace } from "logger"

import { consentsQueryKeys } from "../query-keys"

const useCreateConsent = () => {
  const { operationRequest } = useClient()
  const queryClient = useQueryClient()

  const createPatientConsents = async ({
    patient,
    consentCategories,
  }: {
    patient: Patient
    consentCategories: Coding[]
  }) => {
    const promises = consentCategories.map((coding) =>
      operationRequest<Bundle>({
        endpoint: "Patient",
        method: "POST",
        operation: "new-consent",
        id: patient.id,
        parameters: {
          resourceType: "Parameters",
          parameter: [
            {
              name: "category",
              value: {
                Coding: coding,
              },
            },
          ],
        },
      }),
    )

    const bundles = await Promise.all(promises)

    const consents = bundles.reduce<Consent[]>((prev, { entry }) => {
      if (entry?.[0]?.resource && isConsent(entry[0].resource)) {
        const consent = entry[0].resource

        return [...prev, consent]
      }

      return prev
    }, [])

    return consents
  }

  const { mutate: createConsents, isPending: isCreatingConsents } = useMutation({
    mutationFn: createPatientConsents,
    onError: (error: CustomError, context) => {
      displayNotificationError(registerErrorTrace(error, context))
    },
    onSuccess: async (_, { patient }) => {
      queryClient.refetchQueries({ queryKey: consentsQueryKeys.list(patient.id as string), type: "all" })
      queryClient.refetchQueries({ queryKey: consentsQueryKeys.availables(patient.id as string), type: "all" })
    },
  })

  return { createConsents, isCreatingConsents }
}

export { useCreateConsent }
