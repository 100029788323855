import { MenuItem } from "primereact/menuitem"
import { Menu } from "primereact/menu"
import { classNames } from "primereact/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useId, useRef } from "react"
import { faExternalLink, faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons"

const AvatarListItem = ({
  avatarImg,
  header,
  subHeader,
  dropdownMenuItems,
  actionItem,
  actionsClassName,
  onContentClick,
  externalAction,
  itemPadding = true,
  rowHover = true,
}: Props) => {
  const menu = useRef<Menu>(null)
  const menuTrigger = useRef<HTMLSpanElement>(null)
  const menuId = useId()

  // Preference order dropdown, action, external-link
  const renderLinks = () => {
    if (dropdownMenuItems) {
      return (
        <div>
          <span
            className="mr-2 flex items-center justify-center h-8 w-8 font-bold cursor-pointer text-slate-400 text-2xl rounded-full hover:bg-slate-100 overflow-hidden"
            aria-controls={menuId}
            aria-haspopup
            onClick={(event) => {
              menu.current && menu.current.toggle(event)
            }}
            ref={menuTrigger}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </span>
          <Menu
            model={dropdownMenuItems}
            className="w-max"
            popup
            ref={menu}
            id={menuId}
            style={{ fontSize: "small" }}
          />
        </div>
      )
    }

    if (actionItem) {
      return (
        <span
          title={actionItem.label}
          className={classNames(
            "item-actions flex justify-center items-center h-8 w-8 cursor-pointer text-slate-400 font-semibold rounded-full hover:bg-slate-100 mr-1",
            actionsClassName,
          )}
          onClick={(e) => !actionItem.disabled && actionItem.command?.({ originalEvent: e, item: actionItem })}
        >
          {actionItem.icon ?? actionItem.label}
        </span>
      )
    }

    if (externalAction) {
      return (
        <span
          className="inline-flex h-1/2 items-center justify-center text-slate-400 mr-2 cursor-pointer text-sm"
          title={externalAction}
          onClick={onContentClick}
        >
          <FontAwesomeIcon icon={faExternalLink} />
        </span>
      )
    }
  }

  return (
    <li className="bg-white grow">
      <div
        className={classNames(
          "relative flex items-center space-x-3 py-5 pr-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 ",
          { "hover:bg-gray-50": rowHover, "px-6": itemPadding, "cursor-pointer": onContentClick },
        )}
        onClick={(event) => {
          if (menuTrigger.current?.contains(event.target as Node)) return
          onContentClick?.()
        }}
      >
        <div className="flex-shrink-0">{avatarImg}</div>
        <div className="min-w-0 flex-1">
          <div className="focus:outline-none">
            <div className="text-sm font-medium text-gray-900 mb-1 capitalize">{header}</div>
            <div className="truncate text-xs text-gray-500 flex gap-2 divide-x divide-gray-400">{subHeader}</div>
          </div>
        </div>
        {renderLinks()}
      </div>
    </li>
  )
}

type Props = {
  header: string | JSX.Element
  avatarImg?: JSX.Element
  subHeader?: string | JSX.Element
  dropdownMenuItems?: MenuItem[]
  actionItem?: MenuItem
  onContentClick?(): void
  actionsClassName?: string
  externalAction?: string
  itemPadding?: boolean
  rowHover?: boolean
}

export { AvatarListItem }
