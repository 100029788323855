import * as Yup from "yup"

import { CodeableConcept, Observation, Reference } from "fhir"

const getInitialValues = (patientRef: Reference, encounterRef?: Reference): Observation => ({
  category: [{ coding: [{ code: "social-history", display: "Social History" }], text: "Social History" }],
  code: { coding: undefined },
  status: "final",
  subject: patientRef,
  encounter: encounterRef,
  effective: { dateTime: undefined },
  note: [
    {
      text: "",
      time: new Date().toISOString(),
    },
  ],
})

const socialHistoryValidationSchema = Yup.object().shape({
  code: Yup.object().test(
    "test-codeable-concept",
    "Code is required",
    (value: CodeableConcept | undefined) => value?.coding !== undefined,
  ),
})

const sanitize = ({ ...observation }: Observation) => {
  if (!observation.note?.[0]?.text) {
    delete observation.note
  }

  if (!observation.effective?.dateTime) {
    delete observation.effective
  }

  if (!observation.encounter) {
    delete observation.encounter
  }

  return observation
}

export { getInitialValues, socialHistoryValidationSchema, sanitize }
