import { faFileInvoiceDollar } from "@fortawesome/pro-regular-svg-icons"

import { ModulesId, SubModule } from "commons/Module"

import { InvoiceDetailsContainer } from "../../../invoices/components/InvoiceDetailsContainer"

class InvoiceDetailsSubModule extends SubModule {
  constructor() {
    super()
    this.id = ModulesId.INVOICE_DETAILS
    this.parent = ModulesId.INVOICE
    this.display = "Invoice details"
    this.icon = faFileInvoiceDollar
    this.content = <InvoiceDetailsContainer />
  }
}

export { InvoiceDetailsSubModule }
