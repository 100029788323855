import { classNames } from "primereact/utils"

import { useOrganizationContext } from "organization"
import chIconLogo from "assets/chIconLogo.webp"

import { AzureImage } from "./AzureImage"

const AppIconLogo = ({ className }: Props) => {
  const { branding, currentOrganization } = useOrganizationContext()

  const fallbackLogo = <img src={chIconLogo} alt="app logo" className="w-full h-full" />

  return (
    <div title={currentOrganization.name ?? "unspecified name"} className={classNames("h-full", className)}>
      <AzureImage
        url={branding.iconLogo as string}
        imageClassName="w-full h-full object-contain"
        fallbackIconNode={fallbackLogo}
      />
    </div>
  )
}

type Props = {
  className?: string
}

export { AppIconLogo }
