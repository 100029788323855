import { MedicationRequest } from "fhir"
import { confirmDialog } from "primereact/confirmdialog"

import { MedicationRequestInfo } from "../types"
import { getRenewedMR } from "../utils/transformers"

const discardMR = (medReqId: string, onAcceptCliked: (mrId: string) => void) => {
  confirmDialog({
    message: "Are you sure you want to delete this prescription?",
    header: "Confirmation",
    acceptLabel: "Delete",
    rejectLabel: "Cancel",
    rejectClassName: "button-default p-button-sm",
    acceptClassName: "button-primary p-button-sm",
    accept: () => onAcceptCliked(medReqId),
  })
}

const renewMR = (medicationRequest: MedicationRequestInfo, onAcceptCliked: (mr: MedicationRequest) => void) => {
  confirmDialog({
    message: "Are you sure you want to refill this prescription?",
    header: "Confirmation",
    acceptLabel: "Refill",
    rejectLabel: "Cancel",
    rejectClassName: "button-default p-button-sm",
    acceptClassName: "button-primary p-button-sm",
    accept: () => {
      onAcceptCliked(getRenewedMR(medicationRequest))
    },
  })
}

const getStatusClass = (status: string) => {
  switch (status) {
    case "active":
      return "is-success"
    case "stopped":
      return "is-danger"
    case "completed":
      return "is-info"
    case "cancelled":
      return "is-warning"
    case "draft":
      return "is-warning"
    default:
      return ""
  }
}

export { discardMR, getStatusClass, renewMR }
